// https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/hooks/use-slate.tsx

import { createContext, useContext } from 'react';
import {
  WqDocument,
  WqNode,
  WqNodeInline,
  WqNodeBlock,
  WqNodeText,
  WqTextLeaf
} from '@wordquest/lib-iso/domain/wordquest/dom.js';
import Word from '@wordquest/lib-iso/domain/wordquest/word/word';
import Glossary from '@wordquest/lib-iso/domain/wordquest/glossary';
import { Quote } from '@wordquest/lib-iso/domain/wordquest/quote/quote';
import { Author } from '@wordquest/lib-iso/domain/wordquest/author/author';

// mimic this https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/hooks/use-slate.tsx
/**
 * A React context for sharing the context
 * to support progressive loading or renderer change, re-renders whenever changes occur
 */

// assume we always render in react now
type ReactRichTextContextType = {
  wqDom: WqDocument;
  wordById: Record<string, Word>;
  glossaryById: Record<string, Glossary>;
  quoteById: Record<string, Quote>;
  authorById: Record<string, Author>;
  quoteProps?: Record<string, any>;
  authorProps?: Record<string, any>;
  glossaryProps?: Record<string, any>;
  defaultRenderElement?: any;
  defaultRenderLeaf?: any;
};

export const defaultRichTextContext = {
  wqDom: {
    children: []
  },
  wordById: {},
  glossaryById: {},
  quoteById: {},
  authorById: {},
  quoteProps: {},
  authorProps: {},
  glossaryProps: {}
};

export const RichTextContext = createContext<ReactRichTextContextType | null>(
  defaultRichTextContext
);

/**
 * Get the current editor object from the React context.
 */

export const useRichText = () => {
  const context = useContext(RichTextContext);

  if (!context) {
    throw new Error(
      'The `useRichText` hook must be used inside the <RichTextProvider> component\'s context.'
    );
  }

  return context;
};
