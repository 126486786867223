import _ from 'lodash';
import { WebVTT, VTTCue } from '@wordquest/videojs-vtt.js';
import {
  fromEventPattern,
  interval,
  fromEvent,
  of,
  pipe,
  Observable,
  zip,
  from
} from 'rxjs';
import {
  map,
  filter,
  takeUntil,
  flatMap,
  toArray,
  tap,
  shareReplay,
  bufferCount,
  distinct
} from 'rxjs/operators';
import logger from '~/app/logger';

const timestampPattern = /<[\/]*\d\d:\d\d:[^<]+>/;

// up to client to decide
export const SPEAKER_UNKNOWN = 'unknown';

// overhead at rich text mount so better bulk
// ref implemenation at vtt.js https://github.com/videojs/vtt.js/blob/master/lib/process/parse-content.js
// but we now we only need <v>

// default implementation is like
// <span title="Roger Bingham">We are in New York City</span>

// we need: easy to group by speaker
// decouple cue nodes structure vs representations

// Many interesting references although unpopular https://github.com/VoctroLabs/vtt-utils/blob/master/src/vtt-utils.js#L521
// get text without speaker
// get video friendly short cues

export const parseVttMeta = () => {
  const speakers = [];

  // combineCuesWithSpeakers({
  //   cues
  // });

  return {
    speakers
  };
};

// paragraphBySpeaker =
// text got speaker tag removed already
export type VTTCueWithSpeaker = VTTCue & { speaker: string };

// might parse multiple times
// match new lines between, 2 \n means the end
export const parseVTTCueWithSpeaker = (cue): VTTCueWithSpeaker => {
  const match = cue.text.match(/<v ([^>]+)>+(.+\n*).+$/);
  if (match && match.length > 1) {
    cue.speaker = match[1];
    cue.text = match[2];
  } else {
    cue.speaker = SPEAKER_UNKNOWN;
  }

  return cue;
};

// consecutive speaker still possible
// reuse VTT Cue structure
// transcript friendly cue structure

// ideally observable to support progressive loading
// but simplify to load array first
export const combineCuesWithSpeakers = ({
  cues,
  durationLimit = 15,
  wordCountLimit = 3,
  sentencesLimit = 3
}: {
  cues: VTTCueWithSpeaker[];
  durationLimit: number;
  wordCountLimit: number;
  sentencesLimit: number;
}): VTTCueWithSpeaker[] => {
  // TODO reduce

  const cuesByGroup = _.groupBy(cues, (cue) => {
    const timeBucket = (cue.startTime / durationLimit).toFixed(0);

    // speaker tag might not be avaiable. in such case generically unknown speker
    return [cue.speaker || SPEAKER_UNKNOWN, timeBucket].join('-');
  });

  return _.flatMap(cuesByGroup, (cues) => {
    const [cue] = cues;

    return _.merge(cue, {
      endTime: _.last(cues).endTime,
      text: cues.map((c) => c.text).join(' ')
    });
  });
};

// assume starts with cue w/ timestamp
// need a sliding window for rxjs style
// https://stackoverflow.com/questions/15256557/trouble-implementing-a-sliding-window-in-rx

export const postProcessCues = ({ isVttCaptionsFormat = false }) =>
  isVttCaptionsFormat ? combineCuesWithTimestamps : _.identity;

export const isContainTimestampTags = (raw) =>
  raw.search(timestampPattern) !== -1;

export const combineCuesWithTimestamps = (cues: Observable) =>
  cues.pipe(
    bufferCount(3, 2),
    map((cues) => {
      if (cues[1]) {
        return _.merge(cues[1], {
          text: (cues[1].text || '').replace(/\s*\n\s*\n/g, '\n'),
          startTime: cues[0].startTime
        });
      }
    }),
    filter((cue) => {
      const text = _.get(cue, 'text');

      return _.isString(text) && !text.match(/^\s*\n/);
    })
  );

export const extractVttTextTags = (_text: string) => {
  const text = _text
    .replace(new RegExp(timestampPattern, 'g'), '')
    .replace(/<[\/]*c>/g, '');

  return {
    text
  };
};

export const parseForCues = (
  window = global.window,
  _vtt
): Observable<VTTCue> => {
  logger.debug('parseForCues');
  // fix incorrect linebreak for auto youtube-gen
  const vtt = _vtt.replace('0%\n\n', '0%\n').replace(/\r/g, '');
  if (!window) {
    console.log('window is required for webvtt');

    return;
  }
  // potentially: convertCueToDOMTree
  const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
  // no event
  const cues = fromEventPattern((handler) => {
    parser.oncue = handler;
  });

  // instead of mozilla version due to this
  // https://github.com/videojs/vtt.js/pull/32
  // TODO not proper release mgmt, roll our own

  // TODO avoid keep subscribe
  // change the observable & decouple
  //  parse one scan many times
  // while memory buffer
  const error = fromEventPattern((handler) => {
    parser.onparsingerror = handler;
  });
  const flushed = fromEventPattern((handler) => {
    parser.onflush = handler;
  });

  const allCues = cues.pipe(
    takeUntil(flushed),
    // take in processor so client can decide, esp if post-processed
    shareReplay()
  );
  // we use hack of share, proper way is to define callback based subscriber
  allCues.subscribe();
  parser.parse(vtt);
  parser.flush();

  // start parsing only when subscribed
  return allCues;
};
