export default {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#F5F5F5'
        }
      }
    }
  },
  v2: {
    main: '#113284',
    header: {
      mobile: '64px',
      desktop: '120px'
    },
    font: {
      color: '#ffffff',
      btitle: {
        size: '45px'
      },
      title: {
        size: '34px'
      },
      p: {
        size: '16px'
      }
    }
  },
  typography: {
    fontFamily:
      '"Noto Sans CJK TC", "Roboto", "Helvetica", "Arial", sans-serif',
    useNextVariants: true,
    fontColor: '#000000',
    fontSize: 16
  },
  palette: {
    background: {
      main: '#ffffff'
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: '#000000'
    },
    primary: {
      light: '#4f5bb5',
      main: '#113285',
      dark: '#000e57',
      contrastText: '#ffffff'
    },
    // secondary: {
    //   light: '#ff5970',
    //   main: '#cb1b45',
    //   dark: '#93001f',
    //   contrastText: '#ffffff'
    // },
    secondary: {
      light: '#ffce2c',
      main: '#ffce2c',
      dark: '#ffce2c',
      contrastText: '#000000'
    },
    white: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff'
    },
    ignore: {
      main: '#e0e0e0'
    },
    alert: {
      light: 'red',
      main: 'red',
      dark: 'red'
    }
  }
};
