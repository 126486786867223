import _ from 'lodash';
import rootLogger from '~/app/logger';
import { IS_DEV } from '~/env';
import { of, from, EMPTY, Observable } from 'rxjs';
import { map, tap, flatMap, toArray, filter, first } from 'rxjs/operators';
import { loadDbRefs, writeBatchMergeUnion } from './firestore';
import { loadWithCustomCacheBulk, CacheNamespace } from './cache';

export const FILESYSTEM_JSON_CACHE_FOLDER =
  process.env.FILESYSTEM_JSON_CACHE_FOLDER || '.cache-json-tmp';

export const FILESYSTEM_JSON_SNAPSHOT_FOLDER =
  process.env.FILESYSTEM_JSON_SNAPSHOT_FOLDER || '.cache-json-snapshot';

export const createFilesystemCacheFileName = (
  namespace,
  cacheHashKey,
  cacheFolder = FILESYSTEM_JSON_CACHE_FOLDER
) => `${cacheFolder}/${namespace}/${cacheHashKey}.json`;

const logger = rootLogger.child({ module: 'cache-filesystem-json' });

export const importAsResultByKey = (keys: string[], createFilePathWithKey) =>
  from(keys).pipe(
    flatMap(async (key) => {
      if (!IS_DEV) {
        return null;
      }
      if (process.env.CACHE_IS_OVERRIDE_SNAPSHOT === 'true') {
        return null;
      }
      logger.debug('load cache', keys);
      const result = {};
      try {
        // when this is used as module, create .cache under node_modules make no sense
        const cacheRoot = process.env.CACHE_ROOT || '../../';
        const cacheFilePath = `${cacheRoot}/${createFilePathWithKey(key)}`;
        logger.debug('cache filesystem check', cacheFilePath);
        // TODO ignore at build
        const loadedCacheFile = await import(
          /* webpackIgnore: true */
          cacheFilePath
        );
        if (loadedCacheFile && loadedCacheFile.default) {
          logger.debug('cache filesystem HIT');
          _.merge(result, loadedCacheFile.default);

          return result;
        }
        logger.debug('cache filesystem MISS');
      } catch (err) {
        logger.debug('err', err);
      }

      return null;
    }),
    toArray(),
    map((results) => _.zipObject(keys, results))
  );

export const getCacheFolderWithNamespace = (namespace) =>
  _.includes([CacheNamespace.CONTENTFUL, CacheNamespace.LISTENNOTES], namespace)
    ? FILESYSTEM_JSON_SNAPSHOT_FOLDER
    : FILESYSTEM_JSON_CACHE_FOLDER;

// make use of require / file loader by webpack
export default (
  namespace: string,
  keys: string[],
  loadFn: (keys: string[]) => Observable<Record<string, any>>,
  mergeCache
) => {
  // for now we decide cachefolder path by namespace
  const cacheFolder = getCacheFolderWithNamespace(namespace);
  const createFilePathWithKey = (key) =>
    createFilesystemCacheFileName(namespace, key, cacheFolder);

  const checkIsCacheHit = (keys) =>
    importAsResultByKey(keys, createFilePathWithKey).pipe(
      map((resultByKey) => _.mapValues(resultByKey, (result) => !!result))
    );
  // consider use multiple file for prd/test
  const loadCacheFn = (keys) =>
    importAsResultByKey(keys, createFilePathWithKey);

  return loadWithCustomCacheBulk(
    keys,
    loadFn,
    checkIsCacheHit,
    loadCacheFn,
    mergeCache
  );
};
