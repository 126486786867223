import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';

const StyledVerifiedBadge = styled.div`
  font-weight: 700;
  color: #d4c183;
`;

export const VerifiedBadge = () => (
  <StyledVerifiedBadge>
    <FormattedMessage id="common.verified" />
  </StyledVerifiedBadge>
);

export default VerifiedBadge;
