import React from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ city, countryCode }) => (
  <span>
    {city}
    ,&nbsp;
    {countryCode ? (
      <FormattedMessage id={`common.country.${countryCode}`} />
    ) : (
      'Global'
    )}
  </span>
);
