// https://github.com/contentful/contentful.js/blob/17b81306088ade0470d4b2e2c0480eb9dc29953f/ADVANCED.md#using-es6-import
import _ from 'lodash';
// eslint-disable-next-line
import logger from '~/app/logger';
import { lazyLoadFactory } from '~/adapters/lazy-load';
import { Locale } from '@wordquest/locales';
import { invokeWithPagingThrottle } from '~/app/rxjs-paging';
import { from } from 'rxjs';
import { flatMap } from 'rxjs/operators';

// TODO remove after refactor completed
export enum CfTypes {
  Course = 'course',
  CoursePlan = 'coursePlan',
  CourseIntro = 'courseIntro',
  CourseLesson = 'courseLesson',
  CourseSection = 'courseSection',
  RichTextSection = 'richTextSection',
  BlogPost = 'blogPost',
  Author = 'author',
  AuthorNote = 'authorNote',
  QuestItemRating = 'questItemRating',
  QuestItemCheckin = 'questItemCheckin',
  QuestItemMc = 'questItemMc',
  QuestItemMcJa = 'questItemMcJa',
  QuestItemOpenQa = 'questItemOpenQa',
  QuestItemDictation = 'questItemDictation',
  QuestItemGeneral = 'questItemGeneral',
  Quote = 'quote',
  Glossary = 'glossary',
  SmartUserBlock = 'smartUserBlock',

  // TODO before we rename
  LanguageRequirement = 'languageSkill',
  LanguageProficiency = 'languageProficiency',
  LocationCity = 'locationCity',
  Qualification = 'qualification'
}

export type RichTextSection = {
  key: string;
  title: string;
  descriptionRich: string;
};

export const loadContentfulClient = lazyLoadFactory((cfParams) => {
  // support legacy API for now
  const isPreview = !!cfParams || _.get(cfParams, 'isPreview');
  const space = (process.env.CONTENTFUL_SPACE_ID ||
    process.env.REACT_APP_CONTENTFUL_SPACE_ID) as string;
  const accessToken = (process.env.CONTENTFUL_READ_TOKEN ||
    process.env.REACT_APP_CONTENTFUL_READ_TOKEN) as string;
  logger.debug(
    `init client: Contentful isPreview:${isPreview} accessToken`,
    accessToken,
    space
  );
  const params = {
    space,
    accessToken,
    timeout: 5 * 60 * 1000
  };
  if (isPreview) {
    _.merge(params, {
      accessToken: process.env.REACT_APP_PREVIEW_READ_TOKEN,
      host: 'preview.contentful.com'
    });
  }
  // eslint-disable-next-line
  const contentful = require('contentful');

  return contentful.createClient(params);
});

// TODO <T>
export const loadContentfulEntry = (
  id: string,
  contentType: CfTypes,
  mapper: () => any
) =>
  from(
    loadContentfulClient().getEntries({
      'sys.id': id,
      content_type: contentType,
      include: 10,
      limit: 1
    })
  ).pipe(flatMap((res) => res.items.map(mapper)));

// contentful limit (pageSize)
// The number of updates per response. Max is 100, default is 100
export const loadContentfulEntriesPaging = ({
  contentType,
  limit = 100,
  pageSize = 100,
  include = 5,
  ...rest
}) =>
  invokeWithPagingThrottle(
    ({ next, pageSize }) =>
      from(
        loadContentfulClient().getEntries({
          content_type: contentType,
          // up to material
          include,
          skip: next || 0,
          limit: pageSize,
          ...rest
        })
      ),
    ({ skip, items }) => ({
      next: items.length <= 0 ? null : skip + items.length
    }),
    ({ items }) => from(items),
    0,
    limit,
    pageSize,
    100
  );

export const syncContentfulEntries = (params) =>
  loadContentfulClient().sync({ type: 'Entry', initial: true, ...params });

export const getCfFieldWithKeyLocale = (fields, key, cfLocale = 'en-US') =>
  _.get(fields, `${key}.${cfLocale}`) || _.get(fields, key);
