// not available at build api
import { Author } from '~/domain/wordquest/author/author';
import { asTags } from '~/app/tag/tag-cf';
import { markedWithDefault } from '~/app/markdown-html-mapper';
import { asWqDom } from '~/app/contentful/rich-text-wq-dom-mapper';
import _ from 'lodash';
import { asLanguageProficiency } from '~/domain/wordquest/language/language-proficiency-cf';
import { asLocationCity } from '~/app/location/location-city-cf';
import { asQualification } from '~/app/qualification/qualification-cf';
// we flatten Author vs Teacher as not to have multiple description, no duplicate keys, reduce level & avoid data unsync

export const asAuthor = (cfEntity): Author => {
  if (!cfEntity || !cfEntity.fields) {
    console.log('empty cfEntity', cfEntity);

    return {};
  }
  const { sys, fields } = cfEntity;
  const {
    semanticKey,
    isHidden,
    shortName,
    videoTemplate,
    origin,
    currentLocation,
    timeZoneGmt,
    studentsCount = 0,
    isStudentsCountVerified = false,
    lessonSessionsCount = 0,
    isLessonSessionsCountVerified,
    languagesTeaching = [],
    languagesOther = [],
    academicQualifications = [],
    lessonMethods = [],
    workExperiences = [],
    name,
    tagline,
    description,
    descriptionRich,
    tags,
    avatar,
    externalProfileLinks
  } = fields;
  const descriptionHtml = markedWithDefault(description || '');
  const avatarUrl = avatar.fields.file.url;
  const tagKeys = asTags(tags);

  return {
    name,
    tagKeys,
    origin: asLocationCity(origin),
    currentLocation: asLocationCity(currentLocation),
    studentsCount: parseInt(studentsCount, 10),
    academicQualifications: academicQualifications.map(asQualification),
    lessonMethods,
    workExperiences: workExperiences.map(asQualification),
    isStudentsCountVerified,
    timeZoneGmt,
    lessonSessionsCount: parseInt(lessonSessionsCount, 10),
    isLessonSessionsCountVerified: isLessonSessionsCountVerified === true,
    tagline: tagline || '',
    shortName: shortName || '',
    languagesTeaching: languagesTeaching.map(asLanguageProficiency),
    languagesOther: languagesOther.map(asLanguageProficiency),
    isHidden: isHidden === true,
    // for legacy support
    description: descriptionHtml,
    externalProfileLinksHtml: markedWithDefault(externalProfileLinks || ''),
    descriptionRich: asWqDom(descriptionRich),
    avatarUrl,
    videoTemplateUrl: _.get(videoTemplate, 'fields.file.url') || '',
    semanticKey
  };
};
