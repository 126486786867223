/** Generic platforms types to cater for
 * - profile sources (e.g. firebase auth)
 * - integrating platforms, e.g. integrations at dialogflow
 */
import _ from 'lodash';

export enum Platform {
  // messenger, parital profile + dialogflow
  Fbm = 'fbm',
  Facebook = 'facebook',
  // line,
  Line = 'line',
  // TODO confirm if we need this:
  // LineChat = 'linechat',
  Firebase = 'firebase',

  EmailCustomerIO = 'email-customerio'
}
