import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark';
import Translate from '@material-ui/icons/Translate';
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import { FormattedMessage } from 'react-intl';
import WordWithFurigana from './word-with-furigana';

const styles = (theme) => ({
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary
  },
  headline: {
    // fontWeight: 'bold',
  },
  definitions: {
    marginBottom: 12
  },
  translations: {
    paddingLeft: 40
  },
  card: {
    marginBottom: 12
  }
});

const StyledWordCardSection = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;
const StyledSubheading = styled.span`
  background-color: #f7f7f7;
  font-weight: 200;
  color: #aaaaaa;
  padding: 0.2rem;
  & svg {
    font-size: 0.8rem;
  }
`;
// padding-left: 40px;
const StyledTranslations = styled.div``;

// TODO i18n
const displayDictionary = (source) => {
  if (source === 'jmdict') {
    return '日英字典';
  }

  return '日中字典';
};

const WordCard = (props) => {
  const {
    classes,
    word,
    definitions,
    translation,
    toggleIsWordInDeck,
    isInDeck,
    onClickCardContent
  } = props;
  const doOnClickCardContent = onClickCardContent || (() => {});
  const isShowDeckAction = isInDeck !== undefined;

  return (
    <Card className={classes.card}>
      <CardHeader
        action={
          isShowDeckAction ? (
            <IconButton onClick={() => toggleIsWordInDeck(word, !isInDeck)}>
              {isInDeck ? <Bookmark /> : <BookmarkBorder />}
            </IconButton>
          ) : null
        }
        title={
          <WordWithFurigana
            word={word}
            onClick={() => doOnClickCardContent()}
          />
        }
        subheader=""
      />
      <CardContent onClick={() => doOnClickCardContent()}>
        {translation ? (
          <StyledWordCardSection>
            <Typography gutterBottom variant="subheading">
              <StyledSubheading>
                <Translate />
                &nbsp;Google翻譯
              </StyledSubheading>
            </Typography>
            <StyledTranslations>{translation}</StyledTranslations>
          </StyledWordCardSection>
        ) : null}

        {definitions.map((definition) => (
          <StyledWordCardSection>
            <Typography gutterBottom variant="subheading">
              <StyledSubheading>
                <CollectionsBookmark />
                <FormattedMessage
                  id={`common.dictionary.en.${definition.source}`}
                />
              </StyledSubheading>
            </Typography>

            <Typography component="ol">
              <li key={definition.pos} className={classes.definitions}>
                {definition.gloss}
              </li>
            </Typography>
          </StyledWordCardSection>
        ))}
      </CardContent>
    </Card>
  );
};
export default withStyles(styles)(WordCard);
