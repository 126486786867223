import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import TranslateRounded from '@material-ui/icons/TranslateRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleTabs from '@wordquest/ui-components/layout/simple-tabs';
import {
  appendScriptInBody,
  MountOnce
} from '@wordquest/ui-components/page-util';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { BottomSticky } from '@wordquest/ui-components/layout/common';
import WordCardContainer from '@wordquest/ui-components/word/word-card-container';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import InfoIcon from '@material-ui/icons/Info';
import EdmSubscriptionForm from '~/components/edm-subscription-form';
import MediaPage from '@wordquest/ui-components/layout/media-page';
import { useRootStore } from '~/stores/root';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import Grid from '@material-ui/core/Grid';
import Footer from '@wordquest/ui-components/layout/footer';

export default observer(() => {
  const rootStore = useRootStore();
  const {
    uiStateStore: { app, currentWord },
    eventStore
  } = rootStore;
  const toggleIsDrawerOpened = (isDrawerOpened) => (event) => {
    if (isDrawerOpened) {
      rootStore.eventStore.trackEvent({
        event: 'Word-DefinitionsViewed',
        properties: { word: currentWord }
      });
    }
    app.isDrawerOpened = _.isBoolean(isDrawerOpened)
      ? isDrawerOpened
      : !app.isDrawerOpened;
  };

  return currentWord ? (
    !app.isDrawerOpened ? (
      <BottomSticky>
        <Grid container justify="center">
          <Grid item xs={12} lg={6}>
            <WordCardContainer
              key={currentWord.id}
              type="bar"
              word={currentWord}
              onClickCardContent={toggleIsDrawerOpened(true)}
              isShowDefinitions
              isShowTranslations
              trackEvent={(event) => eventStore.trackEvent(event)}
            />
          </Grid>
        </Grid>
      </BottomSticky>
    ) : (
      <SwipeableDrawer
        anchor="bottom"
        disableSwipeToOpen={false}
        open={app.isDrawerOpened}
        onClose={toggleIsDrawerOpened(false)}
        onOpen={toggleIsDrawerOpened(true)}
      >
        <div tabIndex={0} role="button">
          <WordCardContainer
            key={currentWord.id}
            // onClickCardContent={this.toggleIsDrawerOpened(false)}
            word={currentWord}
            isShowDefinitions
            isShowTranslations
            trackEvent={(event) => eventStore.trackEvent(event)}
          />
        </div>
      </SwipeableDrawer>
    )
  ) : null;
});
