import _ from 'lodash';
import React from 'react';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import { resolveSupportedLocale } from '@wordquest/lib-iso/intl';

export const SUPPORTED_UI_LOCALES = _.intersection(
  [Locale.EN, Locale.ZH_TW, Locale.JA],
  SUPPORTED_LOCALES
);

export const asJoinedComponents = (
  locale = Locale.EN,
  components = [],
  divider
) => {
  let _divider = ', ';
  if (locale === Locale.ZH_TW) {
    _divider = '， ';
  }

  const effectiveDivider = divider || _divider;

  return components.reduce(
    (prev, curr) => [prev, prev ? effectiveDivider : '', curr],
    ''
  );
};

export const resolveUserLocale = (query) => {
  // ip / browser detection
  const browserLocale = _.get(global || {}, 'window.navigator.language');
  // browserLocale table, fallback is EN https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages
  const search = _.get(global || {}, 'window.location.search');
  const urlParams = new URLSearchParams(search);
  const cachedLocale = localStorage.getItem('user.locale');

  const effectiveLocale = resolveSupportedLocale(
    _.get(query, 'locale') ||
      urlParams.get('locale') ||
      cachedLocale ||
      browserLocale ||
      Locale.EN
  );

  return effectiveLocale;
};
