/**
 * Similar to @wordquest/ui-components/react-intl-config.js
 * This moule has no awareness for "current locale"
 * => useful at backend multi-locale processing
 *
 * Explicit messages loading is required, to avoid async at downstream
 */
import IntlMessageFormat from 'intl-messageformat';
import { createResolveLocale } from '@formatjs/intl-utils';
import _ from 'lodash';
import {
  SUPPORTED_LOCALES,
  Locale,
  MessagesModule,
  mapMessagesModuleAsPath
} from '@wordquest/locales';
import {
  setMonth,
  startOfMonth,
  setDay,
  format as _formatDateFns
} from 'date-fns';
import rootLogger from './app/logger';

const logger = rootLogger.child({ module: 'i18n' });

// Note: global.Intl should be always there given our node target

const FALLBACK_LOCALE = Locale.EN;

// for exporting keys
export type MessageKey = string;
// TODO keyof the cache;

export const cachedMessagesByLocale = new Map<Locale, object>(
  // @ts-ignore
  SUPPORTED_LOCALES.map((locale) => [locale, {}])
);

export const cachedIntlMessagesByLocale = new Map<Locale, Map>(
  // @ts-ignore
  SUPPORTED_LOCALES.map((locale) => [locale, new Map()])
);

const mergeMapObj = (map, key, obj = {}) => {
  // simple workaround to avoid perf issue now, we use obj instead of map
  // https://stackoverflow.com/questions/32000865/simplest-way-to-merge-es6-maps-sets
  const original = map.get(key);
  map.set(key, _.merge(obj, original || {}));

  return map;
};

export const loadAndAddMessageWithLocaleSubModules = async (
  locale: Locale,
  messagesModule: MessagesModule
) => {
  const _messages = await loadMessagesWithLocaleModule(
    locale,
    mapMessagesModuleAsPath(messagesModule)
  );
  logger.debug(
    'loadAndAddMessageWithLocaleSubModules messages length',
    _.keys(_messages).length
  );
  addMessagesWithLocale(locale, _messages);
};

// not used by ui-main
export const loadAndAddMessagesWithLocaleModule = async (
  locale: Locale,
  messagesModule: MessagesModule
) => {
  await loadAndAddMessageWithLocaleSubModules(locale, messagesModule);
  if (messagesModule === MessagesModule.Common) {
    await loadAndAddMessageWithLocaleSubModules(
      locale,
      MessagesModule.CommonNames
    );
  }
};

export const addMessagesWithLocale = (locale: Locale, messages) => {
  mergeMapObj(cachedMessagesByLocale, locale, messages);
};

export const loadMessagesWithLocaleModule = async (
  locale: Locale,
  messageModulePath: string
) => {
  logger.debug('loadMessagesWithLocaleModule', locale, messageModulePath);
  // TODO group those messages either here (conditions by filename) or at json build
  // fx cannot be use inside import()

  // webpack syntax limitaitons https://github.com/wordquest/wordquest/issues/630
  // we might use folder structure to hack
  if (locale === Locale.ZH_TW) {
    return import(
      /* webpackMode: "eager" */
      /* webpackPreload: true */
      /* webpackChunkName: 'wq-locale-message-zh-TW-[request]' */
      /* webpackInclude: /\.json$/ */
      `@wordquest/locales-weblate/messages/${messageModulePath}/zh-TW.json`
    );
  }
  if (locale === Locale.EN) {
    return import(
      /* webpackMode: "lazy-once" */
      /* webpackPreload: true */
      /* webpackChunkName: 'wq-locale-message-en-[request]' */
      /* webpackInclude: /\.json$/ */
      `@wordquest/locales-weblate/messages/${messageModulePath}/en.json`
    );
  }

  // duplicating zh-TW/en
  return import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: 'wq-locale-message-all-[request]' */
    /* webpackInclude: /\.json$/ */
    `@wordquest/locales-weblate/messages/${messageModulePath}/${locale}.json`
  );
};

export const getOrCreateCachedMessageWithLocale = (
  locale: Locale,
  key: MessageKey
) => {
  const cache = cachedIntlMessagesByLocale.get(locale);
  const hit = cache.has(key);
  if (hit) {
    return cache.get(key);
  }
  // we use exact locale for resolution https://github.com/yahoo/intl-messageformat#intlmessageformat-constructor
  // Note locale data != messages of locale
  const message = cachedMessagesByLocale.get(locale)[key];
  if (!message) {
    return;
  }
  const intlMessage = new IntlMessageFormat(message, locale as string);
  cache.set(key, intlMessage);

  return intlMessage;
};

export const formatWithLocale =
  (locale: Locale = Locale.EN) =>
  (key: MessageKey, values: any) => {
    const message = getOrCreateCachedMessageWithLocale(locale, key);
    if (!message) {
      logger.trace('formatWithLocale missing', locale, key);

      // silent fail for misisng key
      return key;
    }

    return message.format(values);
  };

// en-GB might not be available in some platforms
export const toUTCString = (date) => {
  if (!_.isDate(date)) {
    return '';
  }

  return date.toLocaleString('en-US', { timeZone: 'UTC' });
};

// downstream modules should bind their own with default locale
// shorthand now
// @deprecated
export const format = formatWithLocale(FALLBACK_LOCALE);

// convenient hack refactor together later
export const formatDateFns = _formatDateFns;

export const formatDate = (date, locale = FALLBACK_LOCALE, options = {}) => {
  if (!_.isDate(date)) {
    // silent fail
    return '';
  }

  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatAsTargetGmt = (
  date,
  targetGmt,
  locale = FALLBACK_LOCALE
) => {
  if (!_.isDate(date)) {
    // silent fail
    return '';
  }
  const utc = date.getTime();

  const clientGmtOffset = date.getTimezoneOffset() / 60;
  const target = new Date(utc + (clientGmtOffset + targetGmt) * 3600000);
  // console.log('utc', utc, target, target.toLocaleString(), target.toUTCString());
  const formatted = formatDate(target, locale, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
    // timeZoneName: 'short'
    // dateStyle: 'full',
    // timeStyle: 'long'
  });

  return `${formatted} GMT${targetGmt > 0 ? '+' : ''}${targetGmt}`;
};

export const formatDateAsParts = (date, locale = FALLBACK_LOCALE, options) => {
  const formatter = new Intl.DateTimeFormat(locale, options);

  return formatter.formatToParts(date);
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
export const formatDateAsMonth = (date, locale = FALLBACK_LOCALE) =>
  (
    _.first(
      formatDateAsParts(date, locale, {
        month: 'long'
      })
    ) || {}
  ).value;

export const formatDateAsYear = (date, locale = FALLBACK_LOCALE) =>
  (
    _.first(
      formatDateAsParts(date, locale, {
        year: 'numeric'
      })
    ) || {}
  ).value;

export const formatDateAsWeekday = (date, locale = FALLBACK_LOCALE) =>
  (
    _.first(
      formatDateAsParts(date, locale, {
        weekday: 'short'
      })
    ) || {}
  ).value;
const resolveLocaleWithIntl = createResolveLocale(() => Locale.EN);
/**
* Get a supported locale
* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation

*  new Intl.Locale not available at nodejs
*  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Locale
* IntlMessageFormat.resolvedOptions is browser dependent https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/supportedLocalesOf
* https://github.com/formatjs/formatjs/blob/99760a86ddba9e3004f0d858aa84d0f2dcc41273/packages/intl-messageformat/README.md#locale-resolution
*/
export const resolveSupportedLocale = (sourceLocaleString: string) => {
  // add some custom lang distance magic here if we wish
  if (_.toLower(sourceLocaleString) === _.toLower('zh-CN')) {
    return Locale.ZH_TW;
  }
  // supported by browser but we prefer parent
  // manual locale resolution until we find some good packages
  // if we wish, add some language distance magic here.
  const { locale, nu } = resolveLocaleWithIntl(
    SUPPORTED_LOCALES,
    [sourceLocaleString],
    {
      // https://github.com/formatjs/formatjs/blob/master/packages/intl-utils/src/resolve-locale.ts#L30
      matcher: 'lookup'
    },
    [],
    {}
  );

  return locale || Locale.EN;
};

export const getMonthDates = () => {
  const date = new Date();

  return _.range(0, 11).map((m) => setMonth(date, m));
};

export const getWeekdayDates = () => {
  const date = new Date();

  return _.range(0, 6).map((m) => setDay(date, m, { weekStartsOn: 1 }));
};
