import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Person from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';

export const StyledLoginButton = styled.div`
  > .MuiButton-text {
    padding: 0rem;
  }
`;

// Login butotn or Avatar when loggedin
// profile.photoURL is load async after id, force re-render
export default ({
  children,
  profile,
  isLoggedIn = false,
  isShowAvatar = true,
  toggleIsAuthDialogOpen,
  toggleLogout
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledLoginButton>
      {isLoggedIn && profile ? (
        <>
          <Button onClick={handleClick}>
            {isShowAvatar ? (
              <Avatar
                style={{ cursor: 'pointer' }}
                src={profile.photoURL}
                size={20}
              />
            ) : (
              <>
                <Person />
                <Hidden mdDown>{profile.name || profile.email || ''}</Hidden>
              </>
            )}
          </Button>
          <Menu
            id="menu-avatar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              {profile.displayName || profile.name}
              {' / '}
              {profile.email}
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleIsAuthDialogOpen(true);
              }}
            >
              <FormattedMessage id="common.login.profile.with.settings" />
            </MenuItem>
            <MenuItem onClick={() => toggleLogout()}>
              <FormattedMessage id="common.logout" />
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          onClick={() => {
            toggleIsAuthDialogOpen(true);
          }}
        >
          {children}
        </Button>
      )}
    </StyledLoginButton>
  );
};
