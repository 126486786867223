import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window.analytics) {
  const segmentId = process.env.REACT_APP_SEGMENT_ID;
  console.log('segmentId', segmentId);
  if (process.env.NODE_ENV === 'production') {
    window.analytics.load(segmentId);
    // window.analytics.page();
    // done inside ui-state store
    // fallback identify in case not triggered
    // window.analytics.identify();
  } else {
    // note: dry run segment at dev without loading segment id is also fine
    window.analytics.load(segmentId, { integrations: { Sentry: false } });
    // Fullstory. Supposed disabled for non-white list domain anyway
    window['_fs_ready'] = function () {
      if (window.FS) {
        // https://help.fullstory.com/hc/en-us/articles/360020623314-FS-shutdown-and-FS-restart
        window.FS.disableConsole();
        window.FS.shutdown();
      }
    };
  }
}

// conditional render vs hydate
const isMarkupPresent = document.getElementById('root').hasChildNodes();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
