import _ from 'lodash';
import React from 'react';
import { Locale, MessagesModule } from '@wordquest/locales';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import Icon from '@material-ui/core/Icon';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Language from '@material-ui/icons/Language';
import { SUPPORTED_UI_LOCALES } from '~/intl-util';

const StyledLanguageDropdown = styled.div`
  .MuiSvgIcon-root {
    font-size: 1.2rem;
    margin-left: -0.3rem;
  }
`;
class LanguageDropdown extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { toggleLocaleWithLazyLoad, isWhiteTheme, classes } = this.props;

    return (
      <StyledLanguageDropdown>
        <Button
          aria-owns={anchorEl && 'menu-appbar'}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
          className={classes.dropdown}
        >
          <Language />
          <FormattedMessage id="common.language.dropdown" />
          <ArrowDropDown />
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {_.map(SUPPORTED_UI_LOCALES, (locale) => (
            <MenuItem
              key={locale}
              onClick={() => toggleLocaleWithLazyLoad(locale)}
              className={classes.dropdown}
            >
              <FormattedMessage id={`common.language.native.${locale}`} />
            </MenuItem>
          ))}
        </Menu>
      </StyledLanguageDropdown>
    );
  }
}

export default LanguageDropdown;
