import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getTagI18nKey } from '@wordquest/locales';
import Chip from '@material-ui/core/Chip';
import { IS_AGENT } from '@wordquest/lib-iso/env';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const StyledChip = styled(Chip)`
  cursor: pointer;
  font-size: 0.9rem;
  & span {
    text-decoration: none;
    ${({ color }) =>
      color !== 'primary'
        ? `
        color: #0F4991;
      `
        : ''}
    border-color: #00000012;
  }
  ${({ color }) =>
    color !== 'primary'
      ? `
      background-color: white !important;
    `
      : ''}
  margin: 0 0.5rem 0.5rem 0;
`;

// When allTags metadata not specified, show all tags=>watch for i18n issue
// allTags: keys of all allowed tags, already filtered
// default = show all, i.e. if limit is not set, never show more button
export default ({
  tagKeys = [],
  allTags = [],
  limit = 0,
  color = 'primary',
  variant = 'outlined',
  onClick,
  onDelete,
  getCustomizedTagI18nKey = getTagI18nKey,
  withLink = (props) => _.identity
}) => {
  // donensure stable order
  const tagKeysPublic = _.isEmpty(allTags)
    ? tagKeys
    : _.intersection(tagKeys, allTags);
  const [isShownAllTags, toggleShownAllTags] = useState(
    limit === 0 && !IS_AGENT
  );

  const toggleIsShownAllTags = () => {
    toggleShownAllTags(!isShownAllTags);
  };
  const tagKeysShown = tagKeysPublic.slice(
    0,
    isShownAllTags ? tagKeysPublic.length : limit
  );

  // injected is useful for potentially highlight active tags
  return (
    <div>
      {tagKeysShown.map((tagKey) =>
        withLink({
          tagKey
        })(
          <StyledChip
            variant={variant}
            color={color}
            key={tagKey}
            onClick={onClick && (() => onClick(tagKey))}
            onDelete={onDelete && (() => onDelete(tagKey))}
            label={<FormattedMessage id={getCustomizedTagI18nKey(tagKey)} />}
          />
        )
      )}
      {!isShownAllTags && tagKeysShown.length < tagKeysPublic.length && (
        <Button
          className="chip-button"
          onClick={() => {
            toggleIsShownAllTags();
          }}
        >
          <FormattedMessage id="landing.section7.more" />
          <ArrowDropDown />
        </Button>
      )}
    </div>
  );
};
