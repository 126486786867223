import React from 'react';
import { Paper, Avatar, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormatQuoteSharpIcon from '@material-ui/icons/FormatQuoteSharp';
import styled from 'styled-components';
import { media } from '~/style-utils';

const StyledTestimonialCard = styled(Paper)`
  padding: 3rem 2rem;
  border-radius: 1rem;

  & .MuiAvatar-root {
    width: 55%;
    height: auto;
  }

  ${media.phone`
    padding: 3rem 1rem;
    & .MuiAvatar-root {
      width: 80%;
      height: auto;    
    }
    & > div > :last-child {
      margin-top: 1rem;
    }
    & svg {
      margin-right: 0.5rem;
    }
  `}
`;

const StyledTestimonial = styled(Typography)``;

const StyledUserInfo = withStyles((theme) => ({
  root: {
    color: theme.v2.main,
    marginTop: '1rem'
  }
}))(Typography);

const TestimonialCard = ({
  avatarUrl,
  userName,
  userTitle,
  descriptionRichText
}) => (
  <StyledTestimonialCard elevation={5}>
    <Grid container justify="center">
      <Grid item sm={3} xs={12} container justify="center" alignItems="center">
        <Avatar
          alt={userName}
          src={
            avatarUrl ??
            'https://strapi.perapera.ai/uploads/Screenshot_from_2021_10_26_22_27_29_de266e0df0.png'
          }
        />
      </Grid>
      <Grid item sm={9} xs={12} container justify="space-evenly">
        <Grid item>
          <FormatQuoteSharpIcon />
        </Grid>
        <Grid item xs={10} container direction="column" justify="space-between">
          <StyledTestimonial>{descriptionRichText}</StyledTestimonial>
          <StyledUserInfo>
            {userName} {userTitle}
          </StyledUserInfo>
        </Grid>
      </Grid>
    </Grid>
  </StyledTestimonialCard>
);

export default TestimonialCard;
