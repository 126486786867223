import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import asThumbnailUrlSafe from '~/domain/url-util';
import { mapYoutubeLocaleAsLocale } from '~/adapters/youtube-locale';
/**
 * Abstraction for videos
 * - from various sources / platform (e.g. home-grown / 3rd party, youtube / vimeo / gcs )
 *
 * Useful at
 * - display at UI
 * - show in chatbot messages
 *
 * Related but decoupled:
 * - player
 * - transcription
 *
 *
 * Video is platform specific, therefore we are using {platform}-{id} as identifer) convention
 * (although not using getter for more flexible conversion ／ easier fixture construction)
 * we won't aggregate same video on different platforms into same object/document
 * even for the case of videos having same subtitles, we could duplicate the subtitles across them
 * we will use version of ES doc directly if necessary
 */

export enum VideoPlatform {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Podcast = 'podcast',
  // e.g. gcs url
  FileUri = 'fileuri'
}

export type VideoMeta = {
  isShortListed: boolean;
  isAnalyzed: boolean;
  isSourcedByCurator: boolean;
  isHiddenByCurator: boolean;
  analyedAt: Date;
  subtitlesCreditByLocale: Record<Locale, string>;
};

const videoUrlStrategies = {
  [VideoPlatform.Vimeo]: (video) =>
    `https://vimeo.com/${video.videoPlatformMeta[VideoPlatform.Vimeo].id}`,
  [VideoPlatform.Youtube]: (video) =>
    `https://www.youtube.com/watch?v=${
      video.videoPlatformMeta[VideoPlatform.Youtube].id
    }`,
  // TODO by platform metadata by need
  [VideoPlatform.Podcast]: (video) =>
    video.videoPlatformMeta[VideoPlatform.Podcast].audioUrl,
  [VideoPlatform.FileUri]: (video) =>
    video.videoPlatformMeta[VideoPlatform.FileUri].url
};

export type VideoThumbnail = {
  url: string;
};

export default class Video {
  constructor(
    public id: string,
    public title: string,
    public thumbnails: Record<string, VideoThumbnail>,
    public platform: VideoPlatform,
    public publishedAt: Date,
    // private playback: VideoPlayback,
    public language: Locale,
    public description: string,
    public languagesSecondary: Locale[],
    public tags: string[],
    // potentially decouple video status & video itself given criteria changes frequently
    public videoMeta: VideoMeta,
    // Video could have multiple thumbnail, use the format of Youtube
    //
    // .thembnailUrl will get the first one as default  (getter)
    // thumbnails

    // e.g. platform publishedAt ／ GuideCategories
    public videoPlatformMeta: Record<
      VideoPlatform,
      {
        id: string;
      }
    >
  ) {}

  get url() {
    return videoUrlStrategies[this.platform](this);
  }

  get thumbnailUrl() {
    // assume all videoe got all resolutions
    return (
      this.thumbnails &&
      (_.get(this, 'thumbnails.high.url') ||
        _.get(this, 'thumbnails.default.url'))
    );
  }

  get thumbnailUrlSafe() {
    return asThumbnailUrlSafe(this.thumbnailUrl);
  }

  get thumbnailUrlSafeMaxRes() {
    return asThumbnailUrlSafe(_.get(this.thumbnails, 'maxres.url'));
  }

  // deprecated, move away yt from domain
  get primaryLocale() {
    // 98% Youtube detection is correct
    // let airtable settings override 2%
    // however in the past we set video.language default as en which is wrong
    return (
      this.language ||
      mapYoutubeLocaleAsLocale(
        _.get(
          this.videoPlatformMeta[VideoPlatform.Youtube],
          'defaultAudioLanguage'
        )
      ) ||
      Locale.EN
    );
  }

  static create(doc: object): Video {
    const {
      id,
      title = '',
      thumbnails = [],
      platform,
      publishedAt,
      language,
      description,
      languagesSecondary,
      tags = [],
      videoMeta = {},
      videoPlatformMeta = {}
    } = doc;

    return _.defaultsDeep(
      new Video(
        id,
        title,
        thumbnails,
        platform,
        publishedAt,
        language,
        description,
        languagesSecondary,
        tags,
        videoMeta,
        videoPlatformMeta
      ),
      doc
    );
  }
}
