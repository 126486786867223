import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createUrlWithSearch } from '@wordquest/lib-iso/app/url-context';
import { getSubtitlesI18nTag } from '@wordquest/lib-iso/domain/wordquest/video/video-subtitles-i18n-tag';

// separated file as webpack failed to tree-shake
export default ({ title, description, course }) => (
  <Helmet>
    <title>{`PeraPera - ${title}`}</title>
    <link rel="canonical" href={createUrlWithSearch(`course/${course.key}`)} />
    <meta
      property="og:url"
      content={createUrlWithSearch(`course/${course.key}`)}
    />
    <meta property="og:title" content={title || course.title} />
    <meta
      property="og:description"
      content={`${description || course.descriptionShort}`}
    />
    <meta property="og:image" content={`https:${course.heroImageUrl}`} />
    <meta property="og:type" content="video" />
  </Helmet>
);
