import React, { useCallback } from 'react';

import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import {
  media,
  mediaMui,
  isSizeUp,
  isWindowMuiBreakpointUp
} from '~/style-utils';
import styled from 'styled-components';
import { CourseUserStatus } from '@wordquest/lib-iso/domain/wordquest/course/course-user-status';

const StyledCTA = styled.div`
  width: 100%;
  overflow-x: hidden;
  > button {
    padding: 8px 0px;
    font-size: 1rem;
    width: 100%;
  }
`;

export const scrollToFrom = ({
  ctaTargetRef,
  scrollTo,
  trackEvent = _.identity
}) => {
  const scrollTarget = _.get(ctaTargetRef, 'current');
  if (scrollTarget) {
    scrollTo({
      y: scrollTarget.offsetTop - (isWindowMuiBreakpointUp('lg') ? 70 : 70),
      smooth: true
    });
  }
  trackEvent();
};

// use by buttons in ccourse-intro too
export const createOnClickCTA = ({
  courseKey,
  goNextLesson,
  trackEvent,
  courseUserStatus
}) => {
  const scrollToForm = ({ ctaTargetRef, scrollTo }) => {
    // https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
    const scrollTarget = _.get(ctaTargetRef, 'current');
    if (scrollTarget) {
      scrollTo({
        y: scrollTarget.offsetTop - (isWindowMuiBreakpointUp('lg') ? 70 : 70),
        smooth: true
      });
      // we shoudl take care top bar offset at desktop
      // scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // will need to find parent ref

    // unreliable # link causing refresh
    // https://github.com/ReactTraining/react-router/issues/394
    trackEvent({ event: 'Course-FormCTA', properties: { courseKey } });
  };

  if (courseUserStatus === CourseUserStatus.PaidActive) {
    return () => {
      goNextLesson();
    };
  }

  return scrollToForm;
};

export const CTALabel: React.FunctionComponent<{
  paidLabel: React.FunctionComponent;
  courseUserStatus: CourseUserStatus;
}> = React.memo(({ paidLabel, courseUserStatus }) => {
  if (courseUserStatus === CourseUserStatus.PaidActive) {
    return <FormattedMessage id="common.course.form.goto-lesson" />;
  }
  if (courseUserStatus === CourseUserStatus.PaidPending) {
    return <FormattedMessage id="common.course.pending" />;
  }
  if (courseUserStatus === CourseUserStatus.Trial) {
    return <FormattedMessage id="common.course.form.register.from.trial" />;
  }
  if (paidLabel) {
    return paidLabel;
  }

  return <FormattedMessage id="common.course.form.register" />;
});

export default ({ courseUserStatus, onClickCTA, paidLabel = null }) => {
  const isDisabled = courseUserStatus === CourseUserStatus.PaidPending;

  return (
    <StyledCTA>
      <Button
        disabled={isDisabled}
        onClick={onClickCTA}
        size="large"
        variant="contained"
        color="primary"
      >
        <CTALabel paidLabel={paidLabel} courseUserStatus={courseUserStatus} />
      </Button>
    </StyledCTA>
  );
};
