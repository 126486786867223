import _ from 'lodash';
import Course from '~/domain/wordquest/course/course';
import CourseIntro from '~/domain/wordquest/course/course-intro';

import Material from '~/domain/wordquest/course/material';
import Lesson from '~/domain/wordquest/course/lesson';
import {
  fieldAsDate,
  FsOp,
  loadDbRefs,
  asValidFirestoreDocumentObj
} from '~/adapters/firestore';
import {
  DataEvent,
  createDataEvent,
  CourseUpsertedDataEvent,
  CourseIntroUpsertedDataEvent,
  LessonUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';
import {
  interval,
  of,
  zip,
  from,
  EMPTY,
  combineLatest,
  race,
  Observable
} from 'rxjs';
import { map, catchError, flatMap, toArray, tap, take } from 'rxjs/operators';
import rootLogger from '~/app/logger';
import { parseIfStringify } from '~/utils';

const logger = rootLogger.child({ module: 'course-repo' });

export const mapMaterialAsDocument = (material: Material = {}) =>
  _.merge(_.toPlainObject(material), {
    contentRichText: JSON.stringify(material.contentRichText || {}),
    contentRichTextAnalyzed: JSON.stringify(
      material.contentRichTextAnalyzed || {}
    )
  });

export const mapLessonAsDocument = (lesson: Lesson = {}) =>
  _.merge(_.omit(_.toPlainObject(lesson), ['remarks']), {
    material: !_.isEmpty(lesson.material)
      ? mapMaterialAsDocument(lesson.material)
      : null,
    remarksRichText: JSON.stringify(lesson.remarksRichText || {}),
    remarksRichTextAnalyzed: JSON.stringify(
      lesson.remarksRichTextAnalyzed || {}
    )
  });

export const mapCourseIntroAsDocument = (courseIntro: CourseIntro = {}) =>
  _.merge(_.omit(_.toPlainObject(courseIntro), []));

export const mapCourseAsDocument = (course: Course) =>
  _.merge(
    _.toPlainObject(
      _.merge(course, {
        coursePlans: (course.coursePlans || []).map((coursePlan) =>
          _.merge(coursePlan, {
            descriptionRichText: _.isObject(coursePlan.descriptionRichText)
              ? JSON.stringify(coursePlan.descriptionRichText || {})
              : coursePlan.descriptionRichText
          })
        )
      })
    ),
    {
      descriptionRichText: JSON.stringify(course.descriptionRichText || {})
    }
  );

// https://github.com/wordquest/wordquest/issues/628
export const mapFsDocPartialAsLesson = (data) => ({
  ...data,
  updatedAt: data.updatedAt ? fieldAsDate(data.updatedAt) : undefined,
  readyAt: data.readyAt ? fieldAsDate(data.readyAt) : undefined
});

export const mapFsAsCourse = (d) => {
  const data = d.data();
  const courseSections = data.courseSections.map((courseSection) =>
    _.merge(courseSection, {
      lessons: courseSection.lessons.map(mapFsDocPartialAsLesson)
    })
  );
  const coursePlans = (data.coursePlans || []).map((coursePlan) =>
    _.merge(coursePlan, {
      descriptionRichText: parseIfStringify(
        _.get(coursePlan, 'descriptionRichText')
      )
    })
  );

  // TODO refactor with lesson?
  return Course.create({
    id: d.ref.id,
    ...data,
    courseSections,
    coursePlans,
    descriptionRichText: parseIfStringify(data.descriptionRichText)
  });
};

export const mapFsAsLesson = (d) => {
  const data = d.data();

  return _.merge(mapFsDocPartialAsLesson(data), {
    id: d.ref.id,
    material: _.merge(data.material, {
      contentRichText: parseIfStringify(
        _.get(data, 'material.contentRichText')
      ),
      contentRichTextAnalyzed: parseIfStringify(
        _.get(data, 'material.contentRichTextAnalyzed')
      )
    }),
    remarksRichText: parseIfStringify(data.remarksRichText),
    remarksRichTextAnalyzed: parseIfStringify(data.remarksRichTextAnalyzed)
  });
};

export const queryCourseWithId = (id) =>
  loadDbRefs().pipe(
    flatMap((dbRefs) => dbRefs.courses.doc(id).get()),
    map(mapFsAsCourse)
  );

export const queryLessonWithKey = (key) =>
  loadDbRefs().pipe(
    flatMap((dbRefs) => dbRefs.lessons.where('key', '==', key).get()),
    flatMap((s) => s.docs),
    take(1),
    map(mapFsAsLesson)
  );

export const queryCourseWithKey = (key) =>
  loadDbRefs().pipe(
    flatMap((dbRefs) => dbRefs.courses.where('key', '==', key).get()),
    flatMap((s) => s.docs),
    take(1),
    map(mapFsAsCourse)
  );

// to support course:lesson 1:M, we will always need 2 queries to query by key
// separate for perf, while we need the title in course
export const queryLessonsWithLessonIds = (lessonIds = []) =>
  loadDbRefs().pipe(
    flatMap((dbRefs) =>
      dbRefs.lessons.where('id', 'in', _.take(lessonIds, 10)).get()
    ),
    flatMap((s) => s.docs),
    map(mapFsAsLesson),
    toArray()
  );

// since semanticKey can be changed by editor, use contentful id as id
export const createCourseWriteOpAsync = (
  event: CourseUpsertedDataEvent
): Observable<FsOp> => {
  const { course } = event.properties;

  return loadDbRefs().pipe(
    flatMap((dbRefs) => {
      if (course.id) {
        return from(dbRefs.courses.doc(course.id).get());
      }
    }),
    tap((doc) =>
      logger.debug('course doc', doc.ref.path, mapCourseAsDocument(course))
    ),
    map(
      (doc) =>
        [
          'set',
          doc.ref,
          asValidFirestoreDocumentObj(mapCourseAsDocument(course))
        ] as FsOp
    ),
    take(1)
  );
};

export const createCourseIntroWriteOpAsync = (
  event: CourseIntroUpsertedDataEvent
): Observable<FsOp> => {
  const { id, courseIntro } = event.properties;

  return loadDbRefs().pipe(
    flatMap((dbRefs) => {
      if (id) {
        return dbRefs.courseIntros.doc(id).get();
      }

      return EMPTY;
    }),
    map(
      (doc) =>
        [
          'set',
          doc.ref,
          asValidFirestoreDocumentObj(mapCourseIntroAsDocument(courseIntro))
        ] as FsOp
    ),
    take(1)
  );
};

export const createLessonWriteOpAsync = (
  event: LessonUpsertedDataEvent
): Observable<FsOp> => {
  const { lesson } = event.properties;

  return loadDbRefs().pipe(
    flatMap((dbRefs) => {
      if (lesson.id) {
        return from(dbRefs.lessons.doc(lesson.id).get());
      }
    }),
    map(
      (doc) =>
        [
          'set',
          doc.ref,
          asValidFirestoreDocumentObj(mapLessonAsDocument(lesson))
        ] as FsOp
    ),
    take(1)
  );
};
