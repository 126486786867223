// import _ from 'lodash';
import { syncHistoryWithStore, RouterStore } from 'mobx-react-router';
import { useLocalStore, Provider } from 'mobx-react'; // 6.x or mobx-react-lite@1.4.0
import React from 'react';
import { createBrowserHistory as createHistory } from 'history';
import UserStore from './domain/user';
import WordStore from './domain/word';
import ArticleStore from './domain/article';
import VideoStore from './domain/video';
import TipStore from './domain/tip';
import EventStore from './domain/event';
import CourseStore from './domain/course';
import UiStateStore from './ui/ui-state';
import PageVideoStore from './ui/page-video';
import PageSupportStore from './ui/page-support';
import PageSearchStore from './ui/page-search';
import PageReadStore from './ui/page-read';
import PageCourseStore from './ui/page-course';

export const createRouterStoreWithHistory = () => {
  const browserHistory = createHistory();
  const routerStore = new RouterStore();
  const history = syncHistoryWithStore(browserHistory, routerStore);

  return {
    routerStore,
    history
  };
};

const createStore = (source) => ({
  rootStore: new RootStore(source.routerStore, source.storeConfig)
});

export const storeContext = React.createContext<RootStore | null>(null);

export const StoreProvider = ({ routerStore, storeConfig, children }) => {
  const store = useLocalStore(createStore, { routerStore, storeConfig });

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useRootStore = (): RootStore => {
  const store: { rootStore: RootStore } = React.useContext(storeContext);
  // not possible if current component in mobxstore observer
  if (!store || !store.rootStore) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store.rootStore;
};

export const useUiConfig = () => {
  const rootStore = useRootStore();

  // useObserver
  return rootStore.uiStateStore.uiConfig;
};

// workaround
export const MobxReactProvider = ({ children }) => {
  const rootStore = useRootStore();

  return <Provider rootStore={rootStore}>{children}</Provider>;
};

// https://github.com/wordquest/wordquest/issues/600
// TODO use factory when necessary for easier change at test
export default class RootStore {
  // inject rootStore to other store so easy to reference other stores
  // also consolidate store constructions here.
  // https://mobx.js.org/best/store.html
  // Note this create circular dependency, and at test we better always use rootStore
  //
  constructor(routerStore: RouterStore, _storeConfig) {
    const storeConfig = Object.assign(
      {
        isWordStoreEnabled: true,
        isArticleStoreEnabled: true,
        isVideoStoreEnabled: true,
        isCourseStoreEnabled: true,
        isPageSearchStoreEnabled: true,
        isPageSupportStore: true
      },
      _storeConfig
    );
    this.routerStore = routerStore;
    this.userStore = new UserStore(this);
    this.wordStore = new WordStore(this);
    this.tipStore = new TipStore(this);
    this.eventStore = new EventStore(this);
    this.uiStateStore = new UiStateStore(this, routerStore);
    // requires uiStateStore.uiConfig
    if (storeConfig.isArticleStoreEnabled) {
      this.articleStore = new ArticleStore(this);
      this.pageReadStore = new PageReadStore(this);
    }
    this.videoStore = new VideoStore(this);

    this.pageVideoStore = new PageVideoStore(this);

    if (storeConfig.isCourseStoreEnabled) {
      this.courseStore = new CourseStore(this);
      this.pageCourseStore = new PageCourseStore(this);
    }
    if (storeConfig.isPageSearchStoreEnabled) {
      this.pageSearchStore = new PageSearchStore(this, routerStore);
    }
    if (storeConfig.isPageSupportStore) {
      this.pageSupportStore = new PageSupportStore(this, routerStore);
    }
  }
}
