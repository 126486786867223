import React from 'react';
import _ from 'lodash';
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  Scrollbar,
  Controller
} from 'swiper';
// seems still unstable and marked as WIP
// https://github.com/nolimits4web/swiper/issues/3762
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwipeableCards from './swipeable-cards';
import { MEDIA_SIZES, isSizeUp } from '~/style-utils';
// Required for treeshake & custom build
// Not working at jest https://github.com/wordquest/wordquest/issues/21, we workaround first
// http://idangero.us/swiper/api/#custom-build

if (SwiperCore) {
  // TODO potentially dynamic import too
  SwiperCore.use([Navigation, Pagination, Scrollbar, Controller]);
}

// Swiper width/height (in px). Parameter allows to force Swiper width. Useful only if you initialize Swiper when it is hidden.
// Setting this parameter will make Swiper not responsive. But can be useful when running under Node.js (e.g. for testing with libs like Jest, and for SSR)
function createSwiper(
  swiperKey = 'default',
  containerRef,
  maxSlidesPerView = 3.1,
  spaceBetween = 200,
  centeredSlides = true,
  withAutoPlayDelay,
  height,
  width
) {
  const options = {
    speed: 500,
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   hide: true
    // },

    // https://stackoverflow.com/questions/43770106/swiper-slider-not-working-unless-page-is-resized
    // given dynamic slides?
    observer: true,
    observeParents: true,
    observeSlideChildren: true,

    centeredSlides,
    spaceBetween,
    grabCursor: true,
    navigation: {
      nextEl: `.swiper-custom-next swiper-${swiperKey}`,
      prevEl: `.swiper-custom-prev swiper-${swiperKey}`
    },
    autoplay: withAutoPlayDelay
      ? {
          delay: withAutoPlayDelay
        }
      : {},
    breakpoints: {
      [MEDIA_SIZES.phone[0]]: {
        centeredSlides: false,
        slidesPerView: 1.05,
        spaceBetween: 10
      },

      [MEDIA_SIZES.tablet[1]]: {
        centeredSlides: false,
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      [MEDIA_SIZES.desktop[0]]: {
        slidesPerView: maxSlidesPerView,
        centeredSlides: false,
        spaceBetween: 20
      }
    }
  };
  if (height) {
    options.height = height;
  }
  if (width) {
    options.width = width;
  }

  return new SwiperCore(containerRef, options);
}

// use Virtual Slides for performance sake
export default class SwipeableCardsContainer extends React.Component {
  state = {
    swiper: null
  };

  componentDidMount() {
    const { children } = this.props;
    // make this lazy,not ideal place to init if props is empty
    // Swiper support dynamic content update, DOM is there but actual slide need .update() to support
    // this.setState({swiper });
    // TODO
    if (_.size(children) !== 0) {
      this.initSwiper();
    }
  }

  // slides DOM of children will need to be rendered before swiper init /update
  // hack to do at here
  componentDidUpdate() {
    const {
      state: { swiper },
      props: { children }
    } = this;
    // if(this.state.swiper){
    //   console.log('update');
    //   this.state.swiper.update();
    // }
    //
    //
    // https://stackoverflow.com/questions/30528348/setstate-inside-of-componentdidupdate
    if (children.length <= 0) {
      return;
    }
    if (!swiper) {
      this.initSwiper();
    } else {
      swiper.update();
    }

    // will become multiple if multiple elements matching the selector
    if (_.isArray(swiper)) {
      throw new Error('Multiple swipers');
    }
  }

  componentWillUnmount() {
    const {
      state: { swiper },
      props
    } = this;
    if (!swiper) {
      return;
    }
    swiper.destroy();
  }

  initSwiper() {
    const {
      swiperKey,
      maxSlidesPerView,
      spaceBetween,
      centeredSlides,
      withAutoPlayDelay,
      width,
      height
    } = this.props;
    const swiper = createSwiper(
      swiperKey,
      this.containerRef,
      maxSlidesPerView,
      spaceBetween,
      centeredSlides,
      withAutoPlayDelay,
      width,
      height
    );
    this.setState({ swiper });
    const { initSwiperCallback } = this.props;
    if (_.isFunction(initSwiperCallback)) {
      initSwiperCallback(swiper);
    }
  }

  // native nav buttons are not working
  render() {
    const {
      swiperKey,
      children,
      isShownNavButtons = true,
      navButtonsSizeUp,
      maxSlidesPerView
    } = this.props;
    const { swiper } = this.state;
    const navButtons = isShownNavButtons && children.length > maxSlidesPerView;

    return (
      <SwipeableCards
        swiperKey={swiperKey}
        activeIndex={(swiper || {}).activeIndex}
        onClickNavPrev={() => {
          if (swiper) {
            swiper.slidePrev();
          }
        }}
        onClickNavNext={() => {
          if (swiper) {
            swiper.slideNext();
          }
        }}
        containerRef={(el) => (this.containerRef = el)}
        navButtons={navButtons}
        navButtonsSizeUp={navButtonsSizeUp}
      >
        {children}
      </SwipeableCards>
    );
  }
}
