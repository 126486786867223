import logger from '@wordquest/lib-iso/app/logger';
import _ from 'lodash';
import { from } from 'rxjs';
import { timeout } from 'rxjs/operators';
import {
  createCheckoutSession,
  CreateStripeSessionParams,
  redirectToCheckout
} from './stripe';

const REACT_APP_COURSE_FORM_SUBMISSION_ENDPOINT =
  process.env.REACT_APP_COURSE_FORM_SUBMISSION_ENDPOINT ??
  'https://carbwb72s1.execute-api.ap-southeast-1.amazonaws.com/prd/course-form-submission';

const mapCourseFormDataAsParams = (
  courseFormData
): CreateStripeSessionParams => ({
  priceId: courseFormData['price-id'],
  subscriptionTrialDays: courseFormData['subscription-trial-days'],
  customerEmail: courseFormData['profile-email'],
  courseKey: courseFormData['course-key'],
  userLocale: courseFormData['user-locale'],
  metadata: {
    courseKey: courseFormData['course-key'],
    courseTitle: courseFormData['course-title'],
    planTitle: courseFormData['plan-title'],
    ticketType: courseFormData['ticket-type'],
    customerName: courseFormData['profile-name'],
    customerEmail: courseFormData['profile-email'],
    occupation: courseFormData['profile-occupation'],
    utmSource: courseFormData['utm-source'],
    utmMedium: courseFormData['utm-medium'],
    utmCampaign: courseFormData['utm-campaign'],
    utmTerm: courseFormData['utm-term'],
    utmContent: courseFormData['utm-content']
  }
});

export const handleCourseFormWithTimeout =
  (timeoutMs: number) => async (courseFormData: Record<string, unknown>) =>
    from(handleCourseFormSubmit(courseFormData))
      .pipe(timeout(timeoutMs))
      .toPromise();

export const handleCourseFormSubmit = async (
  courseFormData: Record<string, unknown>
) => {
  logger.debug('course form submitted data', courseFormData);

  if (!_.isEmpty(courseFormData)) {
    const params = mapCourseFormDataAsParams(courseFormData);
    logger.debug('params', params);

    const sessionResponse = await createCheckoutSession(params);
    const { sessionId, userId } = sessionResponse;

    courseFormData['stripe-session-id'] = sessionId;
    courseFormData['user-id'] = userId;

    const camelCaselizedCourseFormData = _.mapKeys(
      courseFormData,
      (value, key) => _.camelCase(key)
    );

    const result = await handleSubmission(camelCaselizedCourseFormData);
    logger.debug('result', result);

    return redirectToCheckout(sessionId);
  }
};

export const handleSubmission = (courseFormData) =>
  fetch(REACT_APP_COURSE_FORM_SUBMISSION_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(courseFormData)
  });
