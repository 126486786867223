import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import FilePlayer from 'react-player/lib/players/FilePlayer';
import Glossary from '@wordquest/lib-iso/domain/wordquest/glossary';
import { AuthorNote, Tip } from '@wordquest/lib-iso/domain/wordquest/tip/tip';
import {
  WqNodeType,
  WqNodeTypeInline,
  WqNodeTypeBlock,
  WqDocument
} from '@wordquest/lib-iso/domain/wordquest/dom';
import { Locale } from '@wordquest/locales';
import { withRouterLink } from '~/with-link';
import marked from 'marked';
import AuthorCard from '~/author/author-card';
import AuthorNoteCard from './author-note';
import styled from 'styled-components';
import logger from '@wordquest/lib-iso/app/logger';
import Quote from './quote';
// import Element from './rich-text-element';
import Text, { DefaultLeaf, defaultRenderLeaf } from './rich-text-text';
import { defaultRichTextContext, RichTextContext } from './use-rich-text';
import Children from './rich-text-children';
import {
  RenderLeafProps,
  RenderElementProps,
  RenderLinkProps,
  RenderAudioProps,
  RenderSmartUserBlockProps
} from './rich-text';
import ExpandableGlossary from './expandable-glossary';
import { PictureWithCaption } from '~/image/picture-with-caption';
import SmartUserBlock from '~/layout/smart-user-block';

// legacy urlText
export const renderLink = (props: RenderLinkProps) => {
  const { children, attributes } = props;
  const { url }: { url: string } = attributes;

  // TODO backward compat urlText
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      {children}
    </a>
  );
};

// ignore performance first and replace with prismjs to mark decorations / marks in future

const StyledAuthorCard = styled.div`
  & p {
    /* font-size: 1.3rem; */
  }
`;

export const renderMarkdown = (
  props: RenderElementProps,
  context = defaultRichTextContext
) => {
  const { attributes, children } = props;
  const { id } = attributes;
  const markdown = _.get(children, 'props.node.children.0.text');
  if (!markdown) {
    return <></>;
  }
  const __html = marked(markdown);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html
      }}
    />
  );
};

export const renderAuthor = (
  props: RenderElementProps,
  context = defaultRichTextContext
) => {
  const { attributes } = props;
  const { id } = attributes;
  const {
    isShowAuthorLink = false,
    isShowAuthorTags = true,
    isShowAuthorSocialProfile = true
  } = context;
  const { withLink = withRouterLink, authorById } = context;
  const author = _.get(authorById, id);
  if (_.isEmpty(author)) {
    return <></>;
  }

  return (
    <StyledAuthorCard>
      <AuthorCard
        withLink={(props) =>
          withLink({
            href: `/author/${author.key}`
          })
        }
        isShowAuthorDescription
        isShowAuthorTags={isShowAuthorTags}
        isShowAuthorSocialProfile={isShowAuthorSocialProfile}
        isShowAuthorLink={isShowAuthorLink}
        key={author.semanticKey}
        author={author}
      />
    </StyledAuthorCard>
  );
};

export const renderAuthorNote = (
  props: RenderElementProps,
  context = defaultRichTextContext
) => {
  const { attributes } = props;
  const { id } = attributes;

  const {
    isShowAuthorLink = false,
    isShowAuthorTags = true,
    isShowAuthorSocialProfile = true,
    withLink = withRouterLink,
    authorById,
    tipById,
    ...contextProps
  } = context;

  const tip: Tip = _.get(tipById, id);
  const author = _.get(authorById, id) || _.get(tip, 'author');
  const authorNote = _.get(tip, 'properties.authorNote');
  if (_.isEmpty(authorNote)) {
    return <></>;
  }
  const { key, descriptionRichText } = authorNote;

  return (
    <div style={{ paddingBottom: '2rem' }}>
      <AuthorNoteCard
        author={author}
        descriptionRichText={descriptionRichText}
      />
    </div>
  );
};

export const renderQuote = (
  props: RenderElementProps,
  context = defaultRichTextContext
) => {
  const { attributes } = props;
  const { id } = attributes;
  const {
    quoteById,
    withLink = withRouterLink,
    quoteProps,
    ...contextProps
  } = context;
  const quote = quoteById[id];

  if (!quote) {
    return <></>;
  }

  return (
    <div style={{ paddingBottom: '2rem' }}>
      <Quote
        isShowWatchAnchor={false}
        isShowTimestamp
        withLink={withLink}
        video={quote.video}
        author={quote.author}
        original={quote.original}
        {...quoteProps}
        speakerTagKey={quote.figureTagKey}
        translation={quote.translationByLocale[Locale.ZH_TW]}
        explanationRichText={quote.explanationRichTextByLocale[Locale.ZH_TW]}
      />
    </div>
  );
};

export const renderPicture = (props: RenderElementProps, context) => {
  const { attributes } = props;

  const { imageStyle = {} } = context;
  const { height, width } = attributes;

  const aspectRatio = height && width ? width / height : 1.0;

  return (
    <PictureWithCaption
      {...attributes}
      aspectRatio={aspectRatio}
      style={imageStyle}
    />
  );
};

export const renderEmbedly = (props: RenderElementProps) => {
  const { attributes } = props;
  const { url } = attributes;

  return (
    <a
      href={url}
      className="embedly-card"
      data-card-width="100%"
      data-card-controls="1"
    >
      Embedded content:
      {url}
    </a>
  );
};

export const renderSmartUserBlock = (
  props: RenderSmartUserBlockProps,
  context = {}
) => {
  const { attributes } = props;
  const { userAttributeKey, type } = attributes;
  const { profile } = context;
  if (type === 'gdoc') {
    const url = _.get(profile, userAttributeKey);

    // TODO handle mobile
    return <SmartUserBlock url={url} type={type} />;
  }

  return <></>;
};

// Note we can't differentiate audio vs video for mp4 from contentful
// TODO could be using WathcVideo instead
export const renderVideo = (props, context) => {
  const { attributes } = props;
  const { url } = attributes;
  const { videoStyle } = context;
  const playerProps = {
    style: {
      margin: 'auto'
    }
  };
  // inline style unable to override height /width as FIlePlayer props
  if (!_.isEmpty(videoStyle)) {
    // allow full override
    playerProps.style = videoStyle;
  }
  playerProps.height = (videoStyle || {}).height || '50%';
  playerProps.width = (videoStyle || {}).width || '100%';

  return (
    <FilePlayer
      {...playerProps}
      playing={false}
      controls
      config={{
        file: {}
      }}
      url={url}
    />
  );
};

export const createPlayerListeners = (key, onStateUpdate = _.noop) => ({
  onReady: () => onStateUpdate({ isReady: true }),
  onProgress: onStateUpdate,
  onPause: () => onStateUpdate({ playing: false }, key),
  onPlay: () => onStateUpdate({ playing: true, key }),
  onStart: () => onStateUpdate({ isStarted: true }),
  onDuration: (durationInSeconds) => onStateUpdate({ durationInSeconds })
});

// currentKey is to ensure rerender with playing state while delay the init with key
export const renderAudio = (props: RenderAudioProps, context) => {
  const { attributes } = props;
  const { url } = attributes;
  const { audioHeight, playbackProps } = context;

  // might not be sufficient if multiple of same url
  const key = encodeURIComponent(url);

  const { onStateUpdate, currentAudioKey, currentAudioPlaying } =
    playbackProps || {};

  const playerListeners = createPlayerListeners(key, onStateUpdate);

  return (
    <FilePlayer
      width="100%"
      height={audioHeight || '50px'}
      playing={
        (!currentAudioKey || currentAudioKey === key) && currentAudioPlaying
      }
      controls
      config={{
        file: {
          forceAudio: true
        }
      }}
      url={url}
      {...playerListeners}
    />
  );
};

// const asWordComponent = (domNode:HTMLElement, {
//   linkedWordOnClickHandler,
//   getDifficultyColorByWordId = () => '#fcd966'
// }) => {
//   const attributes = getDomAttributeByCamelKey(domNode);
//   const { matchedWordId } = attributes;
//   return React.createElement(StyledMatchedWord, {
//   // might do toggle
//     onClick: () => linkedWordOnClickHandler(matchedWordId),
//     // tabIndex: index,
//     underlineColor: getDifficultyColorByWordId(matchedWordId),
//     role: 'button'
//     // key: `word${matchedWordId}-${index}`
//   }, textDomAsReact(domNode, {}));
// };

const StyledWord = styled.b`
  cursor: pointer;
  border-bottom: 2px solid #fcd966;
  /* TODO create difficulty based palette */

  font-weight: normal;
  padding-left: 1px;
  padding-right: 1px;
`;

export const renderWord = (props: RenderElementProps, context) => {
  const { attributes, children, renderLeaf, renderElement } = props;
  const { wordById = {}, onWordClick = _.noop } = context;

  const { id, matchedWordId } = attributes;
  const word: Word = wordById[id || matchedWordId];

  if (!word) {
    return <span>{children}</span>;
  }

  return (
    <StyledWord
      onClick={(event) => {
        onWordClick(event, word);
      }}
    >
      {(
        <Text
          text={word.text}
          // use default
          renderLeaf={renderLeaf}
        />
      ) || children}
    </StyledWord>
  );
};

// TODO put at indiviudal file once grow
// TODo props vs attributes
export const renderGlossary = (props: RenderElementProps, context) => {
  const { attributes, children, renderLeaf, renderElement } = props;
  const { glossaryById = {}, glossaryProps = {} } = context;

  const { id, matchedGlossaryId } = attributes;
  const glossary: Glossary = glossaryById[id || matchedGlossaryId];

  if (!glossary) {
    return <span>{children}</span>;
  }

  // TODO glossary.title is used because that is only available here.
  // workaround for those detailsHtml
  // note the recusive nature of render
  return (
    <ExpandableGlossary
      glossary={glossary}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      {...glossaryProps}
      detailsComponent={
        glossary.detailsRichText && (
          <Children
            node={glossary.detailsRichText}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        )
      }
    >
      {(
        <Text
          text={glossary.title}
          // use default
          renderLeaf={renderLeaf}
        />
      ) || children}
    </ExpandableGlossary>
  );
};

export const renderAsPlainText = ({ children, renderLeaf }) =>
  (renderLeaf || defaultRenderLeaf)(_.get(children, 'props.node.children.0')) ||
  children;

// Copied from contentful https://github.com/contentful/rich-text/blob/master/packages/rich-text-react-renderer/src/index.tsx
// use defaultRenderLeaf
export const RENDER_STRATEGY_BY_NODE_TYPE = {
  [WqNodeTypeBlock.Document]: ({ attributes, children }, context) => (
    <div>{children}</div>
  ),
  [WqNodeTypeBlock.Div]: ({ attributes, children }, context) => (
    <div>{children}</div>
  ),

  [WqNodeTypeBlock.Paragraph]: ({ attributes, children }, context = {}) => {
    const paragraphProps = _.defaultsDeep(
      { gutterBottom: true },
      context.paragraphProps //eslint-disable-line
    );

    return (
      <Typography
        variant="body1"
        component="p"
        {...paragraphProps}
        {...attributes}
      >
        {children}
      </Typography>
    );
  },
  [WqNodeTypeBlock.Heading1]: (props, context) => (
    <Typography variant="h1" component="h1">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.Heading2]: (props, context) => (
    <Typography variant="h2" component="h2">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.Heading3]: (props, context) => (
    <Typography variant="h3" component="h3">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.Heading4]: (props, context) => (
    <Typography variant="h4" component="h4">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.Heading5]: (props, context) => (
    <Typography variant="h3" component="h5">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.Heading6]: (props, context) => (
    <Typography variant="h3" component="h6">
      {renderAsPlainText(props)}
    </Typography>
  ),
  [WqNodeTypeBlock.UlList]: ({ attributes, children }, context) => (
    <ul>{children}</ul>
  ),
  [WqNodeTypeBlock.OlList]: ({ attributes, children }, context) => (
    <ol>{children}</ol>
  ),
  [WqNodeTypeBlock.ListHeader]: ({ attributes, children }, context) => (
    <li key={_.get(attributes, 'key')}>{children}</li>
  ),
  [WqNodeTypeBlock.ListItem]: ({ attributes, children }, context) => (
    <li key={_.get(attributes, 'key')}>{children}</li>
  ),
  [WqNodeTypeBlock.Hr]: () => <hr />,
  // [WqNodeTypeBlock.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node as Inline),
  // [WqNodeTypeBlock.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node as Inline),
  [WqNodeTypeBlock.AuthorNote]: renderAuthorNote,
  [WqNodeTypeBlock.Quote]: renderQuote,
  [WqNodeTypeBlock.BlockQuote]: ({ attributes, children }, context) => (
    <blockquote>{children}</blockquote>
  ),
  [WqNodeTypeInline.Link]: renderLink,
  [WqNodeTypeInline.Word]: renderWord,
  [WqNodeTypeInline.Glossary]: renderGlossary,
  [WqNodeTypeBlock.Markdown]: renderMarkdown,
  // TODO
  [WqNodeTypeBlock.Author]: renderAuthor,

  // TODO no use case for now
  // [WqNodeTypeBlock.Course]: renderCourse,
  // [WqNodeTypeBlock.Lesson]: renderLesson,
  [WqNodeTypeBlock.SmartUserBlock]: renderSmartUserBlock,
  // TODO option to turn off embedly
  [WqNodeTypeBlock.EmbededLink]: renderEmbedly,
  [WqNodeTypeInline.Image]: renderPicture,

  [WqNodeTypeInline.Video]: renderVideo,

  [WqNodeTypeInline.Audio]: renderAudio
};

// factory to allow customization in composition manner
export const createRenderElement = (
  renderStrategyByNodeType = {},
  renderLeaf
) => {
  const doRenderElement = (
    props: RenderElementProps,
    context = defaultRichTextContext
  ) => {
    const { children, element, attributes } = props;
    // logging circular children will freeze
    // https://github.com/ianstormtaylor/slate/blob/master/docs/walkthroughs/03-defining-custom-elements.md
    // children is the ready-to-render version
    // > And see that props.children reference? Slate will automatically render all of the children of a block for you, and then pass them to you just like any other React component would, via props.children. That way you don't have to muck around with rendering the proper text nodes or anything like that. You must render the children as the lowest leaf in your component.
    // TODO

    const effectiveRenderStrategyByNodeType = _.defaultsDeep(
      {},
      renderStrategyByNodeType,
      RENDER_STRATEGY_BY_NODE_TYPE
    );

    const renderStrategy =
      effectiveRenderStrategyByNodeType[element.type as WqNodeType];
    if (!renderStrategy) {
      logger.debug(
        'renderStrategy missing',
        renderStrategy,
        element.type,
        JSON.stringify(children.props.node),
        effectiveRenderStrategyByNodeType
      );
    }

    // TODO use default inline /block per type
    // Document (div) is probably better then default as paragraph should not contains children as div
    const doRenderStrategy =
      renderStrategy ||
      effectiveRenderStrategyByNodeType[WqNodeTypeBlock.Document];

    return doRenderStrategy({ attributes, children }, context);
  };

  // make things easier when children can use renderElement too

  return (props: RenderElementProps, context = defaultRichTextContext) =>
    doRenderElement(
      _.merge(props, {
        renderElement: doRenderElement,
        renderLeaf
      }),
      context
    );
};

// leaf is part of Text, avoid render <Text/> again

export const defaultRenderElement = createRenderElement(
  RENDER_STRATEGY_BY_NODE_TYPE,
  defaultRenderLeaf
);

// const RICHTEXT_TO_ON_CHANGE = new WeakMap<Editor, () => void>();

// Different from Slate, wqDom / editor is NOT a mutable singleton

export const RichTextContainer = (props) => {
  const { wqDom, children, onChange, renderData = {}, ...rest } = props;
  const [key, setKey] = useState(0);

  const {
    wordById = {},
    glossaryById = {},
    authorById = {},
    quoteById = {},
    ...data
  } = renderData;

  const context: { wqDom: wqDom } = useMemo(
    () => ({
      // wqDom.children = value;
      wqDom,
      defaultRenderElement,
      defaultRenderLeaf,
      wordById,
      glossaryById,
      authorById,
      quoteById,
      ...data,
      ...rest
    }),
    [...Object.values(props)]
  );
  // update whenerver params change

  // we ignore the manual hook to trigger onchange first
  // https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/plugin/with-react.ts#L178
  // // copy this key-context from slate for perf
  // const onContextChange = useCallback(() => {
  //   onChange(wqDom.children);
  //   setKey(key + 1);
  // }, [key, onChange]);
  // //
  // RICHTEXT_TO_ON_CHANGE.set(wqDom, onContextChange);
  //
  // useEffect(() => () => {
  //   RICHTEXT_TO_ON_CHANGE.set(wqDom, () => {});
  // }, []);

  // we use one single context
  return (
    <RichTextContext.Provider value={context}>
      {children}
    </RichTextContext.Provider>
  );
};
