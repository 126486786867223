import _ from 'lodash';
import React from 'react';
import {
  createLanguageMultipleSelect,
  createFilterMultiSelect
} from '~/filters';

import styled from 'styled-components';
import { observer, action, inject } from 'mobx-react';
import LanguageSelect from '~/select/languages';

import { Locale } from '@wordquest/locales';
import { FormattedMessage } from 'react-intl';

// TODO type inside iso
export const GOAL_OPTIONS = [
  'business',
  'professions',
  'job',
  'exam',
  'study'
].map((key) => ({ key }));

export default ({
  goalKeys,
  locale,
  isRenderAsChip = false,
  setGoalLocaleKey,
  isShowInputLabel = false
}) => {
  const fieldKey = `goals-${locale}`;

  return createFilterMultiSelect({
    fieldKey,
    isShowInputLabel,
    isRenderAsChip,
    isShrinkInputLabel: true,
    filterByField: {
      [fieldKey]: goalKeys.map((key) => ({ key })) || []
    },
    getOptionKey: (option) => option.key,
    i18nTitle: (
      <FormattedMessage
        id="common.profile.target.goals"
        values={{
          language: <FormattedMessage id={`common.language.${locale}`} />
        }}
      />
    ),
    options: GOAL_OPTIONS,
    getOptionLabel: (option) =>
      option.key ? (
        <FormattedMessage id={`common.profile.target.goal.${option.key}`} />
      ) : (
        <FormattedMessage id="common.user.profile.goal.select" />
      ),
    setFilterByField: (fieldKey, options, value) => {
      const goalLocale = (fieldKey || '').replace('goals-', '');
      setGoalLocaleKey(goalLocale, _.isArray(value) ? value : [value]);
    }
  });
};
