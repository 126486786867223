/**
 * We use furigana for most UI
 * but it some contexts where furigana is not supported, i.e. chatbot we use okurigana
 *
 */
// <rp> is for fallback https://developer.mozilla.org/en-US/docs/Web/HTML/Element/rp
import _ from 'lodash';
import fastDiff from 'fast-diff';

// Our own furigana implementation as to support jmdict information
// however given kuromoji 1.x support decoupled analyzer, we could share the same interface in future
// also provide react-based implementation instead of injecting html
const RP_PARENTHESIS = ['(', ')'];
export const furiganaAsRubyText = (text, furigana) =>
  `${text}<rp>${RP_PARENTHESIS[0]}</rp><rt>${furigana}</rt><rp>${RP_PARENTHESIS[1]}</rp>`;

export const furiganaAsRubyTags = (
  text: string,
  pairs: Array<furiganaIndexPair>
): string => {
  let rubyContent = '';
  for (let i = 0; i < text.length; i += 1) {
    const matchingPair = _.find(pairs, { index: i });
    if (matchingPair) {
      rubyContent += furiganaAsRubyText(
        text.slice(i, i + matchingPair.kanjiLength),
        matchingPair.kana
      );
      i += matchingPair.kanjiLength - 1;
    } else {
      rubyContent += text[i];
    }
  }

  return `<ruby>${rubyContent}</ruby>`;
};

export function findIndexPairsOfFurigana(
  kanjiText: string,
  kanaText: string
): Array<furiganaIndexPair> {
  // we're not dealing w/ paragraph so this is safe
  // 3rd param as suggested edit location (cursor position)
  const diffs = fastDiff(kanjiText, kanaText);
  // INSERT always followed
  const removed = diffs
    .map((diff, index) => diff.concat(index))
    .filter((diff) => diff[0] === -1);

  return removed.reduce(
    (acc, diff, index) => {
      const [, text, idx] = diff;
      // TODO inefficient, see how to give by diff algo
      const [pairs, totalLength] = acc;

      if (diffs[idx + 1]) {
        return [
          pairs.concat({
            index: kanjiText.indexOf(text, totalLength),
            kanjiLength: text.length,
            kana: diffs[idx + 1][1]
          }),
          totalLength + text.length
        ];
      }

      return [pairs, totalLength];
    },
    [[], 0]
  )[0];
}
