import _ from 'lodash';
import { WqDocument } from '~/domain/wordquest/dom';

export enum MaterialType {
  Video = 'video',
  Note = 'note',
  Podcast = 'podcast',
  QuestReference = 'quest-reference'
}

export default class Material {
  constructor(
    public id: string,
    private key: string,
    private type: MaterialType,
    private title: string,
    private url: URL,
    private contentRichText: WqDocument,
    private contentRichTextAnalyzed: WqDocument,
    private publishedAt: Date,
    private heroImageUrl: URL,
    private meta: Object // locale: Locale, // private publisher: string // private tags: string[]
  ) {}

  static create(doc: object): Material {
    const {
      id,
      key = '',
      type = '',
      title = '',
      url = '',
      contentRichText,
      publishedAt,
      heroImageUrl,
      meta = {}
      // descriptionShort,
      // descriptionRichText,
      // tags = [],
      // courseMeta
    } = doc;

    return _.defaultsDeep(
      new Material(
        id,
        key,
        type,
        title,
        url,
        publishedAt,
        contentRichText,
        heroImageUrl,
        meta
        // descriptionShort,
        // descriptionRichText,
      ),
      doc
    );
  }
}

// export class MaterialMeta {
//   constructor({
//     id, source, sourceMeta, title, description
//   }:object) {
//     this.id = id;
//     this.source = source;
//     this.sourceMeta = sourceMeta;
//     this.title = title;
//     this.description = description;
//   }
//   // Platform specific attributes
//   sourceMeta:{
//     views: number,
//
//   }
//   id: string;
//   source: string;
//   title: string;
// }
