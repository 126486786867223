import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Translate from '@material-ui/icons/Translate';
import { withStyles } from '@material-ui/core/styles';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import Bookmark from '@material-ui/icons/Bookmark';
import Grid from '@material-ui/core/Grid';
import WordWithFurigana from './word-with-furigana';

const StyledDefinition = styled.div`
  color: gray;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }
`;

const StyledJlptLevel = styled.div`
  color: black;
`;

const styles = (theme) => ({
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary
  },
  headline: {
    // fontWeight: 'bold',
  },
  definitions: {
    marginBottom: 12
  },
  translations: {
    paddingLeft: 40
  },
  furigana: {
    minHeight: '1.5em'
  },
  chip: {
    color: 'white'
  },
  chipColor: {},
  chipColorIgnored: {
    backgroundColor: theme.palette.ignore.main
  },
  chipColorAlert: {
    backgroundColor: theme.palette.alert.main
  }
});

const WordBar = (props) => {
  const {
    classes,
    word,
    translation,
    definitions,
    toggleIsWordInDeck,
    isInDeck,
    onClickCardContent,
    userDifficultyColor
  } = props;
  const jlptLevel = _.toUpper(word.getJlptLevel());
  const isShowDeckAction = isInDeck !== undefined;

  // Chip only supports primary/secondary
  // ugly workaround
  let chipColorClasses = classes.chipColor;
  if (userDifficultyColor === 'ignore') {
    chipColorClasses = classes.chipColorIgnored;
  } else if (userDifficultyColor === 'alert') {
    chipColorClasses = classes.chipColorAlert;
  }

  const definition = _.first(definitions) || {};

  return (
    <Card key={word.id} className={classes.card}>
      <CardHeader
        action={[
          isShowDeckAction ? (
            <IconButton onClick={() => toggleIsWordInDeck(word, !isInDeck)}>
              {isInDeck ? <Bookmark /> : <BookmarkBorder />}
            </IconButton>
          ) : null
        ]}
        title={[
          <Grid
            container
            onClick={() => onClickCardContent()}
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography gutterBottom variant="body1">
                <WordWithFurigana word={word} />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {jlptLevel ? (
                <Chip
                  label={jlptLevel}
                  color={userDifficultyColor}
                  className={`${classes.chip} ${chipColorClasses}`}
                />
              ) : null}
            </Grid>

            <Grid container item xs={7} alignItems="center">
              {
                <StyledDefinition>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {translation ? (
                      <>
                        <Translate />
                        {translation}
                      </>
                    ) : (
                      definition.gloss
                    )}
                  </Typography>
                </StyledDefinition>
              }
            </Grid>
          </Grid>
        ]}
        subheader=""
      />

      {/**
      <CardContent>
          <Typography className={classes.translations} component="div" />
          </CardContent>
          * */}
    </Card>
  );
};
export default withStyles(styles)(WordBar);
