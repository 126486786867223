// Abstraction over config for youtube, vimeo html players

// Same as the key in https://github.com/CookPete/react-player
export enum VideoPlayerType {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Facebook = 'facebook',
  // custom type not supported by react-player
  AudioThumbnail = 'audioThumbnai',
  File = 'file'
}

// Supports these controls
// https://developers.google.com/youtube/iframe_api_reference#Playback_controls
// ensuring the controls were only rendered when video playback was ready

/**
 * At UI, we try to decouple state & controls of playlist whenever possible
 * exception to show buttons like "next video", which are injected from parent components
 *
 * Helpful in multiple components depending on the playback state
 * e.g. showing subtitles of different locale at once
 *
 * For Youtube, controls of playlist & video are mixed into the player
 */

// Assume only 1:1 player (but 1:M other componbents e.g. subtitles)
export type VideoPlayerControls = {
  playing: boolean;
  controls: boolean;
};

export type VideoPlayback = {
  playedSeconds: number;
  isSeeking: false;
};
