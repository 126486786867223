/* eslint-disable no-underscore-dangle */

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import wqThemeOptions from '~/theme';
// A theme with custom primary and secondary color.
// It's optional.
// https://material-ui.com/customization/themes/
const theme = responsiveFontSizes(createMuiTheme(wqThemeOptions));
function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map()
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
