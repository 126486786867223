import React from 'react';
import qs from 'qs';

export default ({ formId, title, params }) => {
  const props = params || {
    hideTitle: 0,
    alignLeft: 0
  };

  return (
    <iframe
      src={`https://tally.so/embed/${formId}?${qs.stringify(props)}`}
      width="100%"
      height="800"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      {...params}
    />
  );
};
