import _ from 'lodash';
import React from 'react';
import {
  createLanguageMultipleSelect,
  createFilterMultiSelect
} from '~/filters';
import {
  mapKeyAsProficiencyOption,
  PROFICIENCY_LEVEL_BY_LOCALE,
  PROFICIENCY_OPTIONS_BY_LOCALE,
  PROFICIENCY_REFERENCE_BY_LOCALE,
  asLanguageProficiencyI18nKey
} from '@wordquest/lib-iso/domain/wordquest/ql/difficulty-scale';
import styled from 'styled-components';
import { observer, action, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Locale } from '@wordquest/locales';
import { FormControl } from '@material-ui/core';

export default ({
  locale,
  proficienyKey,
  setProficiencyKey,
  setProficiencyLevel,
  isShowInputLabel = false,
  isRenderAsChip = false
}) => (
  <FormControl>
    {createFilterMultiSelect({
      multiple: false,
      i18nTitleKey: 'proficiency',
      fieldKey: `proficiency-${locale}`,
      isShowInputLabel,
      filterByField: {
        [`proficiency-${locale}`]: { key: proficienyKey }
      },
      isShrinkInputLabel: true,
      isRenderAsChip,
      setFilterByField: (fieldKey, options, value) => {
        const option = mapKeyAsProficiencyOption(locale, value);
        setProficiencyLevel(option.min);
      },
      getOptionLabel: ({ key }) =>
        !_.isEmpty(key) ? (
          <FormattedMessage
            id={asLanguageProficiencyI18nKey({ locale, proficiency: key })}
          />
        ) : (
          <FormattedMessage id="common.user.profile.proficiency.select" />
        ),
      options:
        PROFICIENCY_OPTIONS_BY_LOCALE[locale] ||
        PROFICIENCY_OPTIONS_BY_LOCALE[Locale.EN],
      getOptionKey: ({ key }) => key
    })}
  </FormControl>
);
