import {
  getAudiogramFilename,
  getAudiogramUrl
} from '~/app/audiogram/audiogram';
import {
  createText,
  createAudioMessage,
  createVideoMessage,
  asLineText,
  createQuickReply
} from '~/adapters/message-mappers';

// TODO unsure if audiogram create success
export const asAudiogramOrAudioMessage = ({
  key,
  videoTemplateUrl,
  avatarUrl,
  audio
}) => {
  if (!audio) {
    return;
  }
  const fileName = getAudiogramFilename(key, audio);

  return {
    type: 'audiogram',
    videoTemplateUrl: `https:${videoTemplateUrl}`,
    avatarUrl: `https:${avatarUrl}`,
    audioUrl: audio.url,
    previewImageUrl: getAudiogramUrl(fileName, 'jpg')
  };
};
