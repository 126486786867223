/**
 * Use typescript enum over constant for easier refactor
 * - No magic destructuring shorthand
 *
 * In reality, 1-1 mapping against different locale system is not possible
 *
 * Still, for simplicity here now we use simple 1-1 mapping
 * use the key same as WQ Locale
 *
 * In future, we need to create exhausted locale list by system &
 * separated resolution mappings
 */

/**
 * The PeraPera locales, picked on our need
 *
 * https://paper.dropbox.com/doc/PeraPera-Locales-GiFX0f2Q5pDeQHUgE1LS7
 *
 * Key= snake case of value
 * TODO make key actual value for others locales
 * Value= based on BCP-47 https://tools.ietf.org/html/bcp47
 * where region code is in https=//en.wikipedia.org/wiki/ISO_3166-1
 * They should be fully compatbile with Js Intl Api(https=//developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl)
 *
 * First value = default
 */
export enum Locale {
  EN = 'en',
  ZH_TW = 'zh-TW',
  YUE = 'yue',
  ZH_CN = 'zh-CN',
  VI = 'vi',
  JA = 'ja',
  KO = 'ko',
  TH = 'th',
  MS = 'ms',
  ID = 'id',
  DE = 'de',
  FR = 'fr',
  ES_419 = 'es-419',
  ES_ES = 'es-ES',
  PL = 'pl',
  PT_PT = 'pt-PT',
  PT_BR = 'pt-BR'
}

// https=://cloud.google.com/natural-language/docs/languages
export enum GsyntaxLocale {
  EN = 'en',
  ZH_TW = 'zh-Hant',
  JA = 'ja',
  KO = 'ko'
}

// Using ISO 639-1 https=//en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// with variant e.g. zh-TW marked below
// [Reference](https=//cloud.google.com/translate/docs/languages)
// Simplified Chinese may be specified either by zh-CN or zh.
// TODO consider rename all keys lower case
export enum GTranslateLocale {
  AF = 'af',
  SQ = 'sq',
  AM = 'am',
  AR = 'ar',
  HY = 'hy',
  AZ = 'az',
  EU = 'eu',
  BE = 'be',
  BN = 'bn',
  BS = 'bs',
  BG = 'bg',
  CA = 'ca',
  CEB = 'ceb',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  CO = 'co',
  HR = 'hr',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  EN = 'en',
  EO = 'eo',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  FY = 'fy',
  GL = 'gl',
  KA = 'ka',
  DE = 'de',
  EL = 'el',
  GU = 'gu',
  HT = 'ht',
  HA = 'ha',
  HAW = 'haw',
  IW = 'iw',
  HI = 'hi',
  HMN = 'hmn',
  HU = 'hu',
  IS = 'is',
  IG = 'ig',
  ID = 'id',
  GA = 'ga',
  IT = 'it',
  JA = 'ja',
  JW = 'jw',
  KN = 'kn',
  KK = 'kk',
  KM = 'km',
  KO = 'ko',
  KU = 'ku',
  KY = 'ky',
  LO = 'lo',
  LA = 'la',
  LV = 'lv',
  LT = 'lt',
  LB = 'lb',
  MK = 'mk',
  MG = 'mg',
  MS = 'ms',
  ML = 'ml',
  MT = 'mt',
  MI = 'mi',
  MR = 'mr',
  MN = 'mn',
  MY = 'my',
  NE = 'ne',
  NO = 'no',
  NY = 'ny',
  PS = 'ps',
  FA = 'fa',
  PL = 'pl',
  PT = 'pt',
  PA = 'pa',
  RO = 'ro',
  RU = 'ru',
  SM = 'sm',
  GD = 'gd',
  SR = 'sr',
  ST = 'st',
  SN = 'sn',
  SD = 'sd',
  SI = 'si',
  SK = 'sk',
  SL = 'sl',
  SO = 'so',
  ES = 'es',
  SU = 'su',
  SW = 'sw',
  SV = 'sv',
  TL = 'tl',
  TG = 'tg',
  TA = 'ta',
  TE = 'te',
  TH = 'th',
  TR = 'tr',
  UK = 'uk',
  UR = 'ur',
  UZ = 'uz',
  VI = 'vi',
  CY = 'cy',
  XH = 'xh',
  YI = 'yi',
  YO = 'yo',
  ZU = 'zu'
}

// https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales/
export enum FbmLocale {
  EN_US = 'en_US',
  CA_ES = 'ca_ES',
  CS_CZ = 'cs_CZ',
  CX_PH = 'cx_PH',
  CY_GB = 'cy_GB',
  DA_DK = 'da_DK',
  DE_DE = 'de_DE',
  EU_ES = 'eu_ES',
  EN_UD = 'en_UD',
  ES_LA = 'es_LA',
  ES_ES = 'es_ES',
  GN_PY = 'gn_PY',
  FI_FI = 'fi_FI',
  FR_FR = 'fr_FR',
  GL_ES = 'gl_ES',
  HU_HU = 'hu_HU',
  IT_IT = 'it_IT',
  JA_JP = 'ja_JP',
  KO_KR = 'ko_KR',
  NB_NO = 'nb_NO',
  NN_NO = 'nn_NO',
  NL_NL = 'nl_NL',
  FY_NL = 'fy_NL',
  PL_PL = 'pl_PL',
  PT_BR = 'pt_BR',
  PT_PT = 'pt_PT',
  RO_RO = 'ro_RO',
  RU_RU = 'ru_RU',
  SK_SK = 'sk_SK',
  SL_SI = 'sl_SI',
  SV_SE = 'sv_SE',
  TH_TH = 'th_TH',
  TR_TR = 'tr_TR',
  KU_TR = 'ku_TR',
  ZH_CN = 'zh_CN',
  ZH_HK = 'zh_HK',
  ZH_TW = 'zh_TW',
  AF_ZA = 'af_ZA',
  SQ_AL = 'sq_AL',
  HY_AM = 'hy_AM',
  AZ_AZ = 'az_AZ',
  BE_BY = 'be_BY',
  BN_IN = 'bn_IN',
  BS_BA = 'bs_BA',
  BG_BG = 'bg_BG',
  HR_HR = 'hr_HR',
  NL_BE = 'nl_BE',
  EN_GB = 'en_GB',
  ET_EE = 'et_EE',
  FO_FO = 'fo_FO',
  FR_CA = 'fr_CA',
  KA_GE = 'ka_GE',
  EL_GR = 'el_GR',
  GU_IN = 'gu_IN',
  HI_IN = 'hi_IN',
  IS_IS = 'is_IS',
  ID_ID = 'id_ID',
  GA_IE = 'ga_IE',
  JV_ID = 'jv_ID',
  KN_IN = 'kn_IN',
  KK_KZ = 'kk_KZ',
  LV_LV = 'lv_LV',
  LT_LT = 'lt_LT',
  MK_MK = 'mk_MK',
  MG_MG = 'mg_MG',
  MS_MY = 'ms_MY',
  MT_MT = 'mt_MT',
  MR_IN = 'mr_IN',
  MN_MN = 'mn_MN',
  NE_NP = 'ne_NP',
  PA_IN = 'pa_IN',
  SR_RS = 'sr_RS',
  SO_SO = 'so_SO',
  SW_KE = 'sw_KE',
  TL_PH = 'tl_PH',
  TA_IN = 'ta_IN',
  TE_IN = 'te_IN',
  ML_IN = 'ml_IN',
  UK_UA = 'uk_UA',
  UZ_UZ = 'uz_UZ',
  VI_VN = 'vi_VN',
  KM_KH = 'km_KH',
  TG_TJ = 'tg_TJ',
  AR_AR = 'ar_AR',
  HE_IL = 'he_IL',
  UR_PK = 'ur_PK',
  FA_IR = 'fa_IR',
  PS_AF = 'ps_AF',
  MY_MM = 'my_MM',
  QZ_MM = 'qz_MM',
  OR_IN = 'or_IN',
  SI_LK = 'si_LK',
  RW_RW = 'rw_RW',
  CB_IQ = 'cb_IQ',
  HA_NG = 'ha_NG',
  JA_KS = 'ja_KS',
  BR_FR = 'br_FR',
  TZ_MA = 'tz_MA',
  CO_FR = 'co_FR',
  AS_IN = 'as_IN',
  FF_NG = 'ff_NG',
  SC_IT = 'sc_IT',
  SZ_PL = 'sz_PL'
}

// https://dialogflow.com/docs/reference/language
export enum DialogFlowLocale {
  ZH_HK = 'zh-HK',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  DA = 'da',
  NL = 'nl',
  EN = 'en',
  EN_AU = 'en-AU',
  EN_CA = 'en-CA',
  EN_GB = 'en-GB',
  EN_IN = 'en-IN',
  EN_US = 'en-US',
  FR = 'fr',
  FR_CA = 'fr-CA',
  FR_FR = 'fr-FR',
  DE = 'de',
  HI = 'hi',
  ID = 'id',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  PT_BR = 'pt-BR',
  RU = 'ru',
  ES = 'es',
  ES_419 = 'es-419',
  ES_ES = 'es-ES',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  UK = 'uk'
}

// check against youtube.js
export enum YoutubeLocale {
  EN = 'en',
  ZH_TW = 'zh-TW',
  ZH_CN = 'zh-CN',
  VI = 'vi',
  JA = 'ja',
  KO = 'ko',
  TH = 'th',
  MS = 'ms',
  ID = 'id',
  DE = 'de',
  FR = 'fr',
  ES_419 = 'es-419',
  ES_ES = 'es',
  PL = 'pl',
  PT_PT = 'pt-PT',
  PT_BR = 'pt'
}

// audio language
export enum YoutubeDefaultLocale {
  EN = 'en',
  ZH_TW = 'zh-Hant',
  ZH_CN = 'zh-Hans',
  ES_ES = 'es',
  ES_419 = 'es-419',
  VI = 'vi',
  JA = 'ja',
  KO = 'ko',
  TH = 'th',
  MS = 'ms',
  ID = 'id',
  DE = 'de',
  FR = 'fr'
}

// https://developers.google.com/youtube/v3/docs/i18nRegions/list#usage
export enum YoutubeRegion {
  US = 'US',
  JP = 'JP',
  KR = 'KR',
  VN = 'VN',
  HK = 'HK',
  SG = 'SG',
  TW = 'TW'
}

export enum AmaraLocale {
  EN = 'en',
  JA = 'ja',
  ZH_TW = 'zh-TW'
}

export enum FirebaseUiLocale {
  EN = 'en',
  ZH_TW = 'zh_tW'
}

// Key = PeraPera Locale
export enum TransifexLocale {
  EN = 'en',
  ZH_TW = 'zh_TW',
  ZH_CN = 'zh_CN',
  VI = 'vi',
  JA = 'ja',
  KO = 'ko',
  TH = 'th',
  MS = 'ms',
  ID = 'id',
  DE = 'de',
  FR = 'fr',
  ES_419 = 'es_419',
  ES_ES = 'es_ES',
  PL = 'pl',
  PT_PT = 'pt_PT',
  PT_BR = 'pt_BR'
}

// Note lower case of sublocale
export enum OxfordLocale {
  EN_GB = 'en-gb',
  EN = 'en'
}

// Supported Locales
// = Available in landing + Avaiable as video subtitlesr
export const BASE_LOCALE = Locale.EN;

export const SUPPORTED_LOCALES = [
  Locale.EN,
  Locale.ZH_TW,
  Locale.ZH_CN,
  Locale.VI,
  Locale.JA,
  Locale.KO,
  Locale.DE,
  Locale.FR,
  Locale.ES_ES,
  Locale.TH,
  Locale.MS,
  Locale.ID
];

export const TARGET_LOCALES = [
  Locale.EN,
  Locale.KO,
  Locale.JA,
  Locale.DE,
  Locale.ZH_TW,
  Locale.FR,
  Locale.VI,
  Locale.YUE
];

export const TRANSLATION_SERVICES_LOCALES = Object.values(Locale);

export enum MessagesModule {
  Article = 'article',
  // Blog = 'blog',
  Chat = 'chat',
  Common = 'common',
  CommonNames = 'common.names',
  Landing = 'landing',
  Video = 'video',
  Tag = 'tag',
  Quest = 'quest'
}

// TODO replace with lib impl
// https://github.com/wooorm/iso-639-3/blob/master/to-1.json
export const ISO_639_3_AS_1 = {
  aar: 'aa',
  abk: 'ab',
  afr: 'af',
  aka: 'ak',
  amh: 'am',
  ara: 'ar',
  arg: 'an',
  asm: 'as',
  ava: 'av',
  ave: 'ae',
  aym: 'ay',
  aze: 'az',
  bak: 'ba',
  bam: 'bm',
  bel: 'be',
  ben: 'bn',
  bis: 'bi',
  bod: 'bo',
  bos: 'bs',
  bre: 'br',
  bul: 'bg',
  cat: 'ca',
  ces: 'cs',
  cha: 'ch',
  che: 'ce',
  chu: 'cu',
  chv: 'cv',
  cor: 'kw',
  cos: 'co',
  cre: 'cr',
  cym: 'cy',
  dan: 'da',
  deu: 'de',
  div: 'dv',
  dzo: 'dz',
  ell: 'el',
  eng: 'en',
  epo: 'eo',
  est: 'et',
  eus: 'eu',
  ewe: 'ee',
  fao: 'fo',
  fas: 'fa',
  fij: 'fj',
  fin: 'fi',
  fra: 'fr',
  fry: 'fy',
  ful: 'ff',
  gla: 'gd',
  gle: 'ga',
  glg: 'gl',
  glv: 'gv',
  grn: 'gn',
  guj: 'gu',
  hat: 'ht',
  hau: 'ha',
  hbs: 'sh',
  heb: 'he',
  her: 'hz',
  hin: 'hi',
  hmo: 'ho',
  hrv: 'hr',
  hun: 'hu',
  hye: 'hy',
  ibo: 'ig',
  ido: 'io',
  iii: 'ii',
  iku: 'iu',
  ile: 'ie',
  ina: 'ia',
  ind: 'id',
  ipk: 'ik',
  isl: 'is',
  ita: 'it',
  jav: 'jv',
  jpn: 'ja',
  kal: 'kl',
  kan: 'kn',
  kas: 'ks',
  kat: 'ka',
  kau: 'kr',
  kaz: 'kk',
  khm: 'km',
  kik: 'ki',
  kin: 'rw',
  kir: 'ky',
  kom: 'kv',
  kon: 'kg',
  kor: 'ko',
  kua: 'kj',
  kur: 'ku',
  lao: 'lo',
  lat: 'la',
  lav: 'lv',
  lim: 'li',
  lin: 'ln',
  lit: 'lt',
  ltz: 'lb',
  lub: 'lu',
  lug: 'lg',
  mah: 'mh',
  mal: 'ml',
  mar: 'mr',
  mkd: 'mk',
  mlg: 'mg',
  mlt: 'mt',
  mon: 'mn',
  mri: 'mi',
  msa: 'ms',
  mya: 'my',
  nau: 'na',
  nav: 'nv',
  nbl: 'nr',
  nde: 'nd',
  ndo: 'ng',
  nep: 'ne',
  nld: 'nl',
  nno: 'nn',
  nob: 'nb',
  nor: 'no',
  nya: 'ny',
  oci: 'oc',
  oji: 'oj',
  ori: 'or',
  orm: 'om',
  oss: 'os',
  pan: 'pa',
  pli: 'pi',
  pol: 'pl',
  por: 'pt',
  pus: 'ps',
  que: 'qu',
  roh: 'rm',
  ron: 'ro',
  run: 'rn',
  rus: 'ru',
  sag: 'sg',
  san: 'sa',
  sin: 'si',
  slk: 'sk',
  slv: 'sl',
  sme: 'se',
  smo: 'sm',
  sna: 'sn',
  snd: 'sd',
  som: 'so',
  sot: 'st',
  spa: 'es',
  sqi: 'sq',
  srd: 'sc',
  srp: 'sr',
  ssw: 'ss',
  sun: 'su',
  swa: 'sw',
  swe: 'sv',
  tah: 'ty',
  tam: 'ta',
  tat: 'tt',
  tel: 'te',
  tgk: 'tg',
  tgl: 'tl',
  tha: 'th',
  tir: 'ti',
  ton: 'to',
  tsn: 'tn',
  tso: 'ts',
  tuk: 'tk',
  tur: 'tr',
  twi: 'tw',
  uig: 'ug',
  ukr: 'uk',
  urd: 'ur',
  uzb: 'uz',
  ven: 've',
  vie: 'vi',
  vol: 'vo',
  wln: 'wa',
  wol: 'wo',
  xho: 'xh',
  yid: 'yi',
  yor: 'yo',
  zha: 'za',
  zho: 'zh',
  zul: 'zu'
};

// TODO simplify & merge

// not using fs to avoid deps
export const mapMessagesModuleAsPath = (moduleName) =>
  `${moduleName.replace('.', '/')}`;

export const getTagI18nKey = (tagKey) => `${MessagesModule.Tag}.${tagKey}`;

// not always for perf
export const getTagI18nKeyWithLanguage = (tagKey) => {
  if (Object.values(Locale).includes(tagKey)) {
    // ensure loaded common.names module
    return `common.language.${tagKey}`;
  }

  return getTagI18nKey(tagKey);
};

export const asLocaleWeeklyEqualValue = (locale = '') =>
  locale.replace('-', '_').toLowerCase();
