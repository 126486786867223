/**
 * Avoid mixed content error esp under Chat webview
 * https://developers.google.com/web/fundamentals/security/prevent-mixed-content/what-is-mixed-* content
 * Do it conditionally as https version might not be available
 *
 * Better solution: proxy those assets
 */
// jest error
const asThumbnailUrlSafe = (url) => {
  const IS_FORCE_HTTPS_ASSET =
    (!!process.env.IS_FORCE_HTTPS_ASSET &&
      process.env.IS_FORCE_HTTPS_ASSET.toLowerCase() === 'true') ||
    (!!global.window && global.window.location.href.startsWith('https'));

  let effectiveUrl = url || '';
  // always add as protocol-less won't work at html seo meta & we checked for https
  if (effectiveUrl.startsWith('//')) {
    effectiveUrl = effectiveUrl.replace('//', 'http://');
  }

  if (IS_FORCE_HTTPS_ASSET) {
    return effectiveUrl.replace('http:', 'https:');
  }

  return effectiveUrl;
};

export default asThumbnailUrlSafe;
