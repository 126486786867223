import _ from 'lodash';
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledButton = withStyles((theme) => ({
  root: {
    '& svg': {
      color: theme.v2.font.color
    },
    '& span': {
      color: `${theme.v2.font.color} !important`
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '3em'
    },
    backgroundColor: theme.v2.main,
    padding: theme.spacing(1, 4),
    '&:hover': {
      backgroundColor: theme.v2.main
    }
  }
}))(Button);

export const BlueWhiteButton = withStyles((theme) => ({
  root: {
    '& svg': {
      color: theme.v2.font.color
    },
    '& span': {
      color: `${theme.v2.font.color} !important`
    },
    backgroundColor: theme.v2.main,
    '&:hover': {
      backgroundColor: theme.v2.main
    }
  }
}))(StyledButton);

export const WhiteBlueButton = withStyles((theme) => ({
  root: {
    '& svg': {
      color: theme.v2.main
    },
    '& span': {
      color: `${theme.v2.main} !important`
    },
    backgroundColor: theme.v2.font.color,
    '&:hover': {
      backgroundColor: theme.v2.font.color
    }
  }
}))(StyledButton);
