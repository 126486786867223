import _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import {
  delay,
  tap,
  expand,
  concatMap,
  take,
  catchError
} from 'rxjs/operators';
import rootLogger from './logger';

const logger = rootLogger.child({ module: 'rxjs-paging' });

// next cursor = either token or offset

export const invokeWithPagingRateLimit = (
  invoke: ({ next, pageSize }) => Observable,
  mapResponseAsPagingParams: () => { next },
  mapResponseAsResults = () => Observable,
  init,
  k = 1000,
  pageSize = 10,
  throttleIntervalInMs = 1000
) =>
  invoke({ next: init, pageSize }).pipe(
    // throttle(val => interval(30 * 1000)),
    expand((res, i) => {
      const { next } = mapResponseAsPagingParams(res);
      logger.debug(
        `paging: next ${next} with delay ${
          i * throttleIntervalInMs
        } k:${k} pageSize:${pageSize}`
      );

      // logger.debug(res);
      // next shouldn't be 0 anyway
      return !_.isNil(next)
        ? invoke({ next, pageSize }).pipe(delay(i * throttleIntervalInMs))
        : EMPTY;
    }),
    take(k / pageSize + 1),
    tap(logger.trace.bind(logger)),
    concatMap(mapResponseAsResults),
    take(k),
    catchError((err) => {
      logger.debug('invokeWithPagingRateLimit error', err);
      logger.error(err);

      return EMPTY;
    })
  );

// TODO deprecate as throttle is misleading (it will discard)
export const invokeWithPagingThrottle = invokeWithPagingRateLimit;
