// segment
import _ from 'lodash';
// some force hidden
import Profile from '~/domain/wordquest/profile/profile';
import rawTags from '@wordquest/lib-static/dist/tags-raw.json';
import { Locale, TARGET_LOCALES } from '@wordquest/locales';
import { Segment, segmentAsKey, keyAsSegment } from './segment';

export const findSegmentFromTags = (tagKeys: string[]) =>
  _.uniq(
    _.flatMap(
      tagKeys,
      (key) =>
        (_.find(asTagsWithGeneratedSegmentKeys(rawTags), { key }) || {})
          .segmentKeys || []
    )
  ).map((segmentKey) => keyAsSegment(segmentKey));

export const generateSegmentsWithLocales = (partialSegment) =>
  _.map(TARGET_LOCALES, (locale) =>
    _.merge({}, partialSegment, { locale })
  ).filter((s) => s.type && s.semanticKey);

export const matchSegmentKeysWithCategory = ({ category, key }) => {
  let partialSegments = [];
  if (_.includes(['Profession', 'Conference', 'Work'], category)) {
    partialSegments = partialSegments.concat({
      type: 'goal',
      semanticKey: 'profession'
    });
  } else if (category === 'Language') {
    // types = types.concat('exam');
    // TODO or match generic proficiency
  }

  return _.flatMap(partialSegments, (s) => generateSegmentsWithLocales(s))
    .filter(Boolean)
    .map(segmentAsKey);
};

export const asTagsWithGeneratedSegmentKeys = (tags) =>
  _.uniq(
    _.map(tags, (t) => {
      const categorySegmentKeys = matchSegmentKeysWithCategory(t);

      return _.merge(t, {
        segmentKeys: _.uniq(_.concat(t.segmentKeys, categorySegmentKeys))
      });
    })
  );
