// Directly to FS not ES
import _ from 'lodash';
import {
  loadDbRefs,
  asValidFirestoreDocumentObj,
  FsOp,
  writeBatchMergeUnion,
  loadDbRefsAsync
} from '~/adapters/firestore';
import { from, of, EMPTY } from 'rxjs';
import {
  map,
  filter,
  flatMap,
  toArray,
  distinct,
  delay,
  takeUntil,
  tap,
  mergeMap
} from 'rxjs/operators';
import {
  Entity,
  Action,
  ProfileAction,
  ENTITIES,
  ENTITY_META,
  MessageAction,
  UserAction
} from '~/domain/wordquest/entity';
import Profile from '~/domain/wordquest/profile/profile';
import {
  UserEvent,
  createUserEvent,
  ProfileTraitsUpdatedUserEvent
} from '~/domain/wordquest/event/user-event';
import { asPartialDoc } from '~/domain/wordquest/profile/mappers';
import { logUserEvents } from './user-logger';
import {
  mapProfileUpdatedUserEventAsPartialProfile,
  mapChatConfigByPlatformAsPartialProfile
} from './user-event-profile-mapper';
import logger from '~/app/logger';
import { Platform } from '~/domain/wordquest/platform';

// TODO  sync firestore profile vs firebase auth profile
export const mapProfileAsDoc = (profile: Profile) =>
  asValidFirestoreDocumentObj(_.toPlainObject(profile));

export const asProfile = (doc: object) => Profile.create(doc);

export const queryUsersRef = () =>
  loadDbRefs().pipe(map((dbRefs) => dbRefs.users));

export const queryUserRefWithId = (userId: string) =>
  loadDbRefs().pipe(map((dbRefs) => dbRefs.userById(userId)));

// QuerySnapshot vs QueryDocumentSnapshot
export const resolveDocs = (s) => {
  if (s.docs) {
    return s.docs.map((doc) => doc.data && doc.data());
  }
  if (s.data) {
    return s.data();
  }

  return s;
};

// TODO refactor query docs/single
export const selectSnapshotUserWithEmailFirst = (docs) => {
  if (docs && !_.isArray(docs)) {
    return docs;
  }
  const userWithEmail = _.find(docs, (doc) => !!doc.email);
  if (userWithEmail) {
    return userWithEmail;
  }

  return _.first(docs);
};

export const defaultSelectSnapshotUser = (docs) => _.first(docs);

export const mapSnapshotAsProfile = (s) => {
  if (!s) {
    return;
  }
  const docs = resolveDocs(s);
  const doc = selectSnapshotUserWithEmailFirst(docs);
  if (!doc) {
    return;
  }

  return asProfile(doc);
};

export const getAsProfile = (ref) =>
  from(ref.get()).pipe(map(mapSnapshotAsProfile));

export const queryUserRefPrimaryWithLineId = (lineId: string) =>
  queryUsersRef().pipe(map((userRef) => userRef.where('idLine', '==', lineId)));

export const queryUserRefPrimaryWithStripeCustomerId = (
  stripeCustomerId: string
) =>
  queryUsersRef().pipe(
    map((userRef) => userRef.where('stripeCustomerId', '==', stripeCustomerId))
  );

export const queryUserRefPrimaryWithEmail = (email: string) =>
  queryUsersRef().pipe(map((userRef) => userRef.where('email', '==', email)));

export const queryUserProfileWithEmail = (userEmail: string) =>
  queryUserRefPrimaryWithEmail(userEmail)
    .pipe(mergeMap(getAsProfile))
    .toPromise();

export const upsertProfileDocAsFsOperation = (
  dbRefs,
  profile: Profile
): FsOp => {
  const userRef = dbRefs.userById(profile.id);
  const doc = asPartialDoc(profile);

  return ['update', userRef, doc];
};

export const upsertUserProfile = async (profile: Profile): Promise<any> => {
  logger.debug('upsertUserProfile profile %s', profile.id);
  // Such loading is unncessary except for logging purpose
  const userRef = await queryUserRefWithId(profile.id).toPromise();
  const userSnapshot = await userRef.get();
  // TODO distinguish upsert vs traits updated
  logUserEvents(profile, ProfileAction.TraitsUpdated);
  if (!userSnapshot.exists) {
    logger.debug('Profile not exists %s', profile.id);
  }
  logger.info(mapProfileAsDoc(profile));
  await userSnapshot.ref.set(mapProfileAsDoc(profile), { merge: true });
};

// user must exists
export const createUserEvents = (events: UserEvent[]) =>
  from(loadDbRefsAsync()).pipe(
    flatMap((dbRefs) =>
      from(events).pipe(
        map((userEvent) => mapUserEventAsFSOperation(dbRefs, userEvent)),
        tap(([op, ref, event]) =>
          logger.debug('mapUserEventAsFSOperation', op, event)
        ),
        toArray(),
        map((operations) => writeBatchMergeUnion(operations))
      )
    )
  );

export const loadProfileWithId = async (userId: string): Profile => {
  const userRef = await queryUserRefWithId(userId).toPromise();
  const userSnapshot = await userRef.get();
  const doc = userSnapshot.data();

  if (doc) {
    return asProfile(doc);
  }
};

export const loadAndEnrichUserProfile = async (
  profile: Profile
): Promise<Profile> => {
  const savedProfile = await loadProfileWithId(profile.id);
  if (!savedProfile) {
    return profile;
  }
  const enrichedProfile = savedProfile.mergeProfile(profile);

  // logger.debug('loadAndEnrichedProfile', enrichedProfile);
  return enrichedProfile;
};

export const mapUserEventAsFSOperation = (
  dbRefs: object,
  event: UserEvent<Entity, Action, object>
): FsOp => {
  logger.debug('mapUserEventAsFSOperation', event);

  if (
    event.entity === Entity.Profile &&
    _.includes(_.values(ProfileAction), event.action)
  ) {
    const profile = mapProfileUpdatedUserEventAsPartialProfile(event);

    return upsertProfileDocAsFsOperation(dbRefs, profile);
  }
  // avoid resent / persist another event but same path as chatConfigupdated
  // Not in actual use due to short-live nature
  if (
    event.entity === Entity.Message &&
    event.action === MessageAction.SentLine
  ) {
    const updateEvent = createUserEvent<ProfileTraitsUpdatedUserEvent>({
      action: ProfileAction.TraitsUpdated,
      properties: {
        ...mapChatConfigByPlatformAsPartialProfile({
          [Platform.Line]: {
            replyToken: _.get(event, 'replyToken')
          }
        })
      }
    });
    const profile = mapProfileUpdatedUserEventAsPartialProfile(event);

    return upsertProfileDocAsFsOperation(dbRefs, profile);
  }

  const userEventsRef = dbRefs
    .userEvents(event.userId, event.entity, ENTITY_META[event.entity].VERSION)
    .doc();

  return ['set', userEventsRef, event];
};

export const writeUserEventToFs = (userEvent, filterEvent = (e) => of(e)) =>
  from(loadDbRefsAsync()).pipe(
    tap((dbRefs) =>
      logger.debug('writeUserEventToFs', _.omit(userEvent, 'userIdToken'))
    ),
    flatMap((dbRefs) =>
      of(userEvent).pipe(
        filter(
          // filter supported entities
          (userEvent) =>
            userEvent && _.includes(_.values(Entity), userEvent.entity)
        ),
        flatMap(() => filterEvent(userEvent)),
        map((userEvent) => mapUserEventAsFSOperation(dbRefs, userEvent)),
        tap(([op, ref, event]) =>
          logger.info('writeUserEventToFs filtered', op, event)
        ),
        // always single event now
        flatMap((operation) => writeBatchMergeUnion([operation]))
      )
    )
  );

// order by actionAt default
export const loadUserEventsByEntity = (
  userId: string,
  entities = _.keys(ENTITY_META) as Entity[],
  queryModifier = _.identity
) =>
  from(entities).pipe(
    flatMap((entity) =>
      loadDbRefs().pipe(
        flatMap((dbRefs) =>
          queryModifier(
            dbRefs.userEvents(userId, entity, ENTITY_META[entity].VERSION)
            // .orderBy('actionAt', 'desc')
          ).get()
        ),
        map((s) => [
          entity,
          s.docs.map((doc) =>
            createUserEvent(
              _.merge(
                {
                  userId,
                  entity
                },
                doc.data()
              )
            )
          )
        ])
      )
    ),
    // tap(results => logger.debug('entity', loadDbRefsAsync().userEvents(userId, entity, version)getUserEventsRef(getUserRef(dbRefs, userId), 'word', 'v2').path)),
    toArray(),
    map((pairs) => _.fromPairs(pairs))
  );

export const loadEventsWithProfileEntityFilter = (
  profile: Profile,
  entity: Entity,
  filterFn = (x) => true,
  keyFn = _.identity
) =>
  loadUserEventsByEntity(profile.id, [entity]).pipe(
    flatMap((eventByEntity) => eventByEntity[entity]),
    filter((event) => filterFn(event)),
    map(keyFn),
    distinct()
  );
