import _ from 'lodash';
import logger from '~/app/logger';
import { of, from, EMPTY, Observable } from 'rxjs';
import { map, tap, flatMap, toArray, filter } from 'rxjs/operators';
import { loadDbRefs, writeBatchMergeUnion } from './firestore';
import { loadWithCustomCacheBulk } from './cache';

export const loadCacheDbRef = (_namespace) =>
  loadDbRefs().pipe(
    map((dbRefs) => {
      const namespace = _.camelCase(`cache-${_namespace}`);

      return dbRefs[namespace];
    })
  );

export default (
  _namespace: string,
  keys: string[],
  loadFn: (keys: string[]) => Observable<Record<string, any>>
) =>
  loadCacheDbRef(_namespace).pipe(
    flatMap((cacheDbRef) => {
      const cacheDocRefs = from(keys).pipe(
        flatMap((key) =>
          of(key).pipe(
            flatMap((key) => cacheDbRef.doc(key).get()),
            map((doc) => [key, doc])
          )
        )
      );

      const checkIsCacheHit = (keys: string[]) =>
        cacheDocRefs.pipe(
          map(([key, doc]) => [key, doc.exists]),
          toArray(),
          map((pairs) => _.fromPairs(pairs))
        );
      const loadCacheFn = (cachedKeys: string[]) =>
        cacheDocRefs.pipe(
          filter(([key]) => _.includes(cachedKeys, key)),
          map(([key, doc]) => [key, JSON.parse(doc.data().data)]),
          toArray(),
          map((pairs) => _.fromPairs(pairs))
        );

      // cache key is driven by actual result keys, not query keys
      const mergeCache = (resultByKey) => {
        logger.debug('mergeCache firestore', _.toPairs(resultByKey));

        return from(_.toPairs(resultByKey)).pipe(
          tap(([key, result]) => logger.trace(`cache key:${key}`)),
          // do not cache empty results
          filter(([key, result]) => result !== null),
          map(([key, result]) => [
            'set',
            cacheDbRef.doc(key),
            { data: JSON.stringify(result) }
          ]),
          toArray(),
          flatMap((ops) => writeBatchMergeUnion(ops))
        );
      };

      return loadWithCustomCacheBulk(
        keys,
        loadFn,
        checkIsCacheHit,
        loadCacheFn,
        mergeCache
      );
    })
  );
