import _ from 'lodash';
import { observable, action } from 'mobx';
import { from } from 'rxjs';
import { map, tap, first, toArray } from 'rxjs/operators';
import { Locale } from '@wordquest/locales';
import Word from '@wordquest/lib-iso/domain/wordquest/word/word';
import { loadWithIdsWithMobxCache } from '~/services/mobx-cache';
import { queryQuotesWithLocaleContext } from '@wordquest/lib-iso/app/quote/quote-repo';
import { Quote } from '@wordquest/lib-iso/domain/wordquest/quote/quote';

import logger from '@wordquest/lib-iso/app/logger';
import RootStore from '~/stores/root';
import { authorNoteAsTip } from '@wordquest/lib-iso/domain/wordquest/tip/tip';

const asKeyById = (entites) => _.keyBy(entites, 'id');

const queryQuoteWithLocaleIdsAsTipById = (authorNoteIds) =>
  queryQuotesWithLocaleIdsAsQuoteById(authorNoteIds).pipe(
    map((quoteById) =>
      _.mapValues(quoteById, (q) => authorNoteAsTip(q.authorNote))
    )
  );
const queryQuotesWithLocaleIdsAsQuoteById = (quoteIds) =>
  from(
    queryQuotesWithLocaleContext(Locale.EN, {
      filter: {
        includeIds: quoteIds
      }
    })
  ).pipe(map(asKeyById), first());

export default class TipStore {
  constructor(rootStore: RootStore) {
    /* eslint-disable-line */
  }

  quoteById = observable.map({});

  tipById = observable.map({});

  lazyLoadQuoteWithContext = action(
    (context): Promise<Quote[]> =>
      from(queryQuotesWithLocaleContext(Locale.EN, context))
        .pipe(
          tap((quotes) => {
            this.quoteById.merge(asKeyById(quotes));
          })
        )
        .toPromise()
  );

  lazyLoadQuotesWithIds = action(
    (quoteIds: string[]): Promise<Quote[]> =>
      loadWithIdsWithMobxCache(
        quoteIds,
        queryQuotesWithLocaleIdsAsQuoteById,
        this.quoteById,
        true
      ).toPromise()
  );

  lazyLoadTipsWithIds = action(
    (tipIds: string[]): Promise<Quote[]> =>
      loadWithIdsWithMobxCache(
        tipIds,
        queryQuoteWithLocaleIdsAsTipById,
        this.tipById,
        true
      ).toPromise()
  );
}
