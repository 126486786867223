import React from 'react';
import { timer } from 'rxjs';
import PleaseWait from './please-wait';
import LoaderGeneral from '~/loader-general';

export enum FormStatus {
  IsSubmitted = 'isSubmitted',
  WaitTooLong = 'waitTooLong',
  ErrorCaught = 'errorCaught',
  Default = 'default'
}

export const formInitialStatus = {
  status: FormStatus.Default
};

export const formStatusReducer = (
  state: typeof formInitialStatus,
  action: { type: FormStatus }
): typeof formInitialStatus => {
  switch (action?.type) {
    case FormStatus.IsSubmitted:
      return {
        status: FormStatus.IsSubmitted
      };
    case FormStatus.WaitTooLong:
      if (state.status === FormStatus.IsSubmitted) {
        return {
          status: FormStatus.WaitTooLong
        };
      }

      return state;
    case FormStatus.ErrorCaught:
      return {
        status: FormStatus.ErrorCaught
      };
    default:
      return {
        status: FormStatus.Default
      };
  }
};

const WAIT_TOO_LONG_MS = 15 * 1000;
export const setupWaitTooLongTimer =
  (dispatch) =>
  (timeLimitInMS = WAIT_TOO_LONG_MS) =>
    timer(timeLimitInMS).subscribe(() => {
      dispatch({ type: FormStatus.WaitTooLong });
    });

export const baseComponentStrategies = {
  [FormStatus.IsSubmitted]: <LoaderGeneral />,
  [FormStatus.WaitTooLong]: <PleaseWait />
};
