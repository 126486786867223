// import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import { useMobxProviderStores } from '~/page-util';
import AuthAvatar from './auth-avatar';
// import { withRouterLink } from './with-link';

// TODO this has ui-main deps. make upper observable instead
export default observer(({ children }) => {
  const { rootStore } = useMobxProviderStores();
  const { isAuthDialogLoaded, onLogout } = rootStore.uiStateStore.app;
  const { profile = {} } = rootStore.userStore.user;

  return (
    isAuthDialogLoaded && (
      <div>
        <AuthAvatar
          isShowAvatar={!!profile.photoURL}
          isLoggedIn={!!profile.id}
          profile={profile}
          toggleLogout={() => onLogout()}
          toggleIsAuthDialogOpen={(args) => {
            rootStore.uiStateStore.toggleIsAuthDialogOpen(args);
          }}
        >
          {children}
        </AuthAvatar>
      </div>
    )
  );
});
