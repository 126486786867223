/*
 * model after Segment, segment event should be fully compatabile
 * https://segment.com/docs/spec/track/
 */
import _ from 'lodash';
import {
  Entity,
  Action,
  WordAction,
  ArticleAction,
  VERSION_META
} from '~/domain/wordquest/entity';

// temp list of schema
export enum AppEventName {
  LambdaHandleStarted = 'lambdaHandleStart',
  LambdaHandleCompleted = 'LambdaHandleCompleted'
}

export type AppEvent<P> = {
  userId: string;
  userIdToken: string;
  type: string;
  event: string | AppEventName;
  actionAt: Date;
  properties: P;
  timestamp: Date;
  receivedAt: Date;
};

export function createAppEvent<
  E extends Entity,
  A extends Action,
  P extends object
>(
  doc: {
    userId: string;
    entity: E;
    action: A;
    actionAt?: Date;
    properties: P;
  } & object
): AppEvent<P> {
  const { entity, action } = doc;

  return Object.assign(_.omit(doc, ['entity', 'action']), {
    event: _.join([entity, action], '-'),
    actionAt: doc?.actionAt || new Date()
  }) as AppEvent<P>;
}
