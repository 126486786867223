import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  media,
  mediaMui,
  isSizeUp
} from '@wordquest/ui-components/style-utils';
import SelectableTags from '@wordquest/ui-components/tag/selectable-tags';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LanguageSelect from '@wordquest/ui-components/select/languages';
import GoalSelect from '@wordquest/ui-components/select/goals';
import ProficiencySelect from '@wordquest/ui-components/select/proficiency';
import { createFilterMultiSelect } from '@wordquest/ui-components/filters';
import { toJS } from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';
import isEqual from 'react-fast-compare'; //eslint-disable-line
import RootStore, { useRootStore } from '~/stores/root';
import Box from '@material-ui/core/Box';
import { mapLevelAsProficiencyOption } from '@wordquest/lib-iso/domain/wordquest/ql/difficulty-scale';
import { DEFAULT_ROLE_OPTIONS } from '@wordquest/ui-components/select/roles';

const StyledFilterSelections = styled.div`
  /* flex-direction: row; */
  > div > div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0rem;
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
  }
  ${media.desktop`
    margin: 0 auto;
    > div > div {
      padding: 1rem;
    }

  `}
`;

const StyledSelection = styled.div`
  display: inline-flex;
  flex-flow: row;
  vertical-align: middle;
  align-items: center;
  > div {
    vertical-align: bottom;
    /* padding-left: 1em;
      padding-right: 1em; */
  }
`;

const StyledTags = styled.div`
  font-size: 1rem;
`;

const ProficiencyGoalsOfLocale = React.memo<{
  locale: Locale;
  proficiencyByLocale: Record<Locale, number>;
  goalsByLocale: Record<Locale, string[]>;
}>(
  ({ locale, proficiencyByLocale, goalsByLocale }) => (
    <Typography variant="h5" component="div">
      <FormattedMessage
        id="common.user.profile.describe.language-details"
        values={{
          locale: <FormattedMessage id={`common.language.${locale}`} />,
          proficiency: (
            <StyledSelection>
              <ProficiencySelect
                setProficiencyKey={(key) => {
                  _.merge(proficiencyByLocale, {
                    [locale]: key
                  });
                }}
                locale={locale}
                proficienyKey={
                  (
                    mapLevelAsProficiencyOption(
                      locale,
                      proficiencyByLocale[locale] || 0
                    ) || {}
                  ).key
                }
              />
            </StyledSelection>
          ),
          goals: (
            <StyledSelection>
              <GoalSelect
                setGoalLocaleKey={(locale, keys) => {
                  _.merge(goalsByLocale, {
                    [locale]: (keys || []).filter(Boolean)
                  });
                }}
                locale={locale}
                goalKeys={goalsByLocale[locale] || ['']}
              />
            </StyledSelection>
          )
        }}
      />
    </Typography>
  ),
  isEqual
);

// empty option seems more clear than input label
export const RoleSelection = React.memo(({ role, onRoleUpdate }) => (
  <StyledSelection>
    <FormControl>
      <InputLabel htmlFor="select-jobrole" />
      {createFilterMultiSelect({
        multiple: false,
        i18nTitleKey: 'Role',
        fieldKey: 'role',
        filterByField: {
          role: { key: role }
        },
        // isShrinkInputLabel: true,
        isShowInputLabel: false,
        isRenderAsChip: false,
        setFilterByField: (fieldKey, options, value) => {
          onRoleUpdate(value);
        },
        getOptionLabel: ({ key }) =>
          key ? (
            <FormattedMessage id={`common.user.profile.role.${key}`} />
          ) : (
            <FormattedMessage id="common.user.profile.role.select" />
          ),
        options: DEFAULT_ROLE_OPTIONS,
        getOptionKey: ({ key }) => key
      })}
    </FormControl>
  </StyledSelection>
));

export default observer((props) => {
  const rootStore: RootStore = useRootStore();
  const {
    eventStore,
    videoStore: { videoTagKeys },
    pageVideoStore: {
      currentVideoRef,
      tagsOfSegmentShownByLocale,
      videoTagFiltersActiveKeys,
      watchUiConfig,
      currentVideosByGroup,
      tagsOfSegments
    },
    userStore: {
      user: { profile }
    }
  } = rootStore;

  const { isShowTags = true, isShowRolePhrase = false } = props;
  const onRoleUpdate = useCallback(
    (role) => {
      profile.role = role;
    },
    [profile]
  );

  const languagesSelection = (
    <StyledSelection>
      <LanguageSelect
        activeLocales={toJS(profile.activeLocales)}
        isShowInputLabel={false}
        isRenderAsChip={false}
        setActiveLocales={(activeLocales) => {
          profile.activeLocales.replace(activeLocales);
        }}
      />
    </StyledSelection>
  );

  return (
    <div>
      <StyledFilterSelections>
        {isShowRolePhrase && (
          <Typography variant="h5" component="div">
            <FormattedMessage
              id="common.user.profile.describe.role-languages"
              values={{
                role: (
                  <RoleSelection
                    role={profile.role}
                    onRoleUpdate={onRoleUpdate}
                  />
                ),
                languages: (
                  <StyledSelection>
                    <LanguageSelect
                      activeLocales={_.omit(
                        toJS(profile.activeLocales),
                        Locale.ZH_TW
                      )}
                      isShowInputLabel={false}
                      isRenderAsChip={false}
                      setActiveLocales={(activeLocales) => {
                        profile.activeLocales.replace(activeLocales);
                      }}
                    />
                  </StyledSelection>
                )
              }}
            />
          </Typography>
        )}
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sm={4}
            container
            display="flex"
            alignItems="center"
          >
            <Grid item xs={4}>
              <FormattedMessage id="common.user.profile.describe.languages" />
            </Grid>
            <Grid item xs={8}>
              <Box
                borderRight={0}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <StyledSelection>
                  <LanguageSelect
                    activeLocales={toJS(profile.activeLocales)}
                    isShowInputLabel={false}
                    isRenderAsChip
                    setActiveLocales={(activeLocales) => {
                      profile.activeLocales.replace(activeLocales);
                    }}
                  />
                </StyledSelection>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            container
            display="flex"
            alignItems="center"
          >
            <Grid item xs={4}>
              <FormattedMessage id="common.user.profile.describe.proficiency" />
            </Grid>
            <Grid item xs={8}>
              <Box
                borderRight={0}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                {profile.activeLocales.map((locale) => {
                  const proficiencyByLocale = profile.proficiencyByLocale;

                  return (
                    <StyledSelection>
                      <ProficiencySelect
                        setProficiencyLevel={(level) => {
                          _.merge(proficiencyByLocale, {
                            [locale]: level
                          });
                        }}
                        isRenderAsChip
                        locale={locale}
                        proficienyKey={
                          (
                            mapLevelAsProficiencyOption(
                              locale,
                              proficiencyByLocale[locale] || 0
                            ) || {}
                          ).key
                        }
                      />
                    </StyledSelection>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            container
            display="flex"
            alignItems="center"
          >
            <Grid item xs={4}>
              <FormattedMessage id="common.user.profile.describe.goals" />
            </Grid>
            <Grid item xs={8}>
              <Box
                borderRight={0}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                {profile.activeLocales.map((locale) => (
                  <StyledSelection>
                    <GoalSelect
                      isRenderAsChip
                      setGoalLocaleKey={(locale, keys) => {
                        _.merge(profile.goalsByLocale, {
                          [locale]: (keys || []).filter(Boolean)
                        });
                      }}
                      locale={locale}
                      goalKeys={toJS(profile.goalsByLocale)[locale] || ['']}
                    />
                  </StyledSelection>
                ))}
              </Box>
            </Grid>
          </Grid>
          {profile.activeLocales.map((locale) => {
            const tagKeys = tagsOfSegmentShownByLocale[locale].map(
              (t) => t.key
            );

            return (
              isShowTags && (
                <StyledTags>
                  <SelectableTags
                    tagKeys={tagKeys}
                    activeKeys={videoTagFiltersActiveKeys}
                    onClick={(tagKey) => {
                      watchUiConfig.isFilterTagsDirty = true;
                      videoTagFiltersActiveKeys.unshift(tagKey);
                    }}
                    onDelete={(tagKey) => {
                      watchUiConfig.isFilterTagsDirty = true;
                      videoTagFiltersActiveKeys.replace(
                        videoTagFiltersActiveKeys.filter((k) => k !== tagKey)
                      );
                    }}
                    entity="video"
                    limit={15}
                  />
                </StyledTags>
              )
            );
          })}
        </Grid>
      </StyledFilterSelections>
      {isShowTags && (
        <StyledTags>
          <SelectableTags
            tagKeys={_.difference(
              videoTagKeys,
              tagsOfSegments.map((t) => t.key)
            )}
            activeKeys={videoTagFiltersActiveKeys}
            onClick={(tagKey) => {
              videoTagFiltersActiveKeys.unshift(tagKey);
            }}
            onDelete={(tagKey) => {
              videoTagFiltersActiveKeys.replace(
                videoTagFiltersActiveKeys.filter((k) => k !== tagKey)
              );
            }}
            entity="video"
            limit={5}
          />
        </StyledTags>
      )}
    </div>
  );
});
