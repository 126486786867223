import _ from 'lodash';
import { format, subDays } from 'date-fns';
import fetch from 'isomorphic-fetch';
import { concatQueryFilterObject } from '~/app/repo-es-util';
import { Locale } from '@wordquest/locales';
import {
  VIDEO_YT_CATEGORY_ID,
  VIDEO_YT_TAGS_KEY,
  VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY,
  VIDEO_YT_DEFAULT_LOCALE_KEY,
  VIDEO_YT_CHANNEL_ID,
  VIDEO_YT_VIEWS
} from '../video/video-yt';
import logger from '~/app/logger';
import { createPotentialYtDefaultLocales } from '~/adapters/youtube-locale';
import { createVideoMultiMatchWithTerms } from '../video/video-repo';

export enum COVID_ENDPOINT {
  CURRENT_ESRI = 'https://services9.arcgis.com/N9p5hsImWXAccRNI/arcgis/rest/services/Nc2JKvYFoAEOFCG5JSI6/FeatureServer/2/query?f=json&where=Recovered%3C%3E0&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outFields=*&orderByFields=Recovered%20desc&resultOffset=0&resultRecordCount=250&cacheHint=true',
  CURRENT = 'https://square-limit-2cbf.perapera.workers.dev/https://covid2019-api.herokuapp.com/current_list',
  TS_CONFIRMED = 'https://square-limit-2cbf.perapera.workers.dev/https://covid2019-api.herokuapp.com/timeseries/confirmed',
  TS_DEATHS = 'https://square-limit-2cbf.perapera.workers.dev/https://covid2019-api.herokuapp.com/timeseries/deaths'
}

export const COUNTRY_NAME_TO_CODE = {
  Others: 'Others',
  China: 'CN',
  'Taiwan*': 'TW',
  'Hong Kong': 'HK',
  Japan: 'JP',
  'Korea, South': 'KR',
  Australia: 'AU',
  Canada: 'CA',
  US: 'US',
  'United Kingdom': 'GB',
  Thailand: 'TH',
  Ireland: 'IE',
  Palestine: 'PS',
  Singapore: 'SG',
  Slovenia: 'SI',
  Belarus: 'BY',
  'Saudi Arabia': 'SA',
  Latvia: 'LV',
  Serbia: 'RS',
  'Vatican City': 'VA',
  'South Africa': 'ZA',
  'Costa Rica': 'CR',
  Italy: 'IT',
  Iran: 'IR',
  France: 'FR',
  Germany: 'DE',
  Spain: 'ES',
  Switzerland: 'CH',
  Kuwait: 'KW',
  Norway: 'NO',
  Bahrain: 'BH',
  Malaysia: 'MY',
  Netherlands: 'NL',
  Sweden: 'SE',
  Iraq: 'IQ',
  Austria: 'AT',
  India: 'IN',
  'United Arab Emirates': 'AE',
  Iceland: 'IS',
  Belgium: 'BE',
  Vietnam: 'VN',
  'San Marino': 'SM',
  Oman: 'OM',
  Israel: 'IL',
  Lebanon: 'LB',
  Algeria: 'DZ',
  Macau: 'MO',
  Croatia: 'HR',
  Denmark: 'DK',
  Ecuador: 'EC',
  Greece: 'GR',
  Qatar: 'QA',
  'Czech Republic': 'CZ',
  Finland: 'FI',
  Pakistan: 'PK',
  Mexico: 'MX',
  Portugal: 'PT',
  Brazil: 'BR',
  Romania: 'RO',
  Senegal: 'SN',
  Philippine: 'PH',
  Russia: 'RU',
  Georgia: 'GE',
  'New Zealand': 'NZ',
  Azerbaijan: 'AZ',
  'Saint Barthelemy': 'BL',
  Egypt: 'EG',
  Estonia: 'EE',
  Indonesia: 'ID',
  Hungary: 'HU',
  Nepal: 'NP',
  Cambodia: 'KH',
  'Sri Lanka': 'LK',
  Afghanistan: 'AF',
  Nigeria: 'NG',
  Luxembourg: 'LU',
  Monaco: 'MC',
  Armenia: 'AM',
  'Dominican Republic': 'DO',
  Andorra: 'AD',
  Saudi_Arabia: 'SA',
  Argentina: 'AR',
  Chile: 'CL',
  Jordan: 'JO',
  Ukraine: 'UA',
  'Faroe Islands': 'FO',
  Gibraltar: 'GI',
  Liechtenstein: 'LI',
  Poland: 'PL',
  Tunisia: 'TN'
};

// avoid circular  deps
export const mapCovidCountryNameAsCode = (countryName = '') =>
  COUNTRY_NAME_TO_CODE[countryName.replace(/_/g, ' ')];

export const COMMON_TAB_KEY = 'COMMON';

const calculateTotalAllCountry = (countryPairs) =>
  _.fromPairs(
    ['confirmed', 'deaths', 'recovered'].map((key) => [
      key,
      _.sum(countryPairs.map(([countryName, stats]) => stats[key]))
    ])
  );

const sumStatsOfCountry = (countryRecords, dates) =>
  _.reduce(
    countryRecords.map((r) => _.pick(r, dates)),
    (result, record) => {
      _.forEach(dates, (date) => {
        if (date in record) {
          // ensure data unavilable won't show as 0
          result[date] = (result[date] || 0) + parseInt(record[date], 10);
        }
      });

      return result;
    },
    {}
  );

export const parseDataFactory = (endpoint) =>
  ({
    [COVID_ENDPOINT.CURRENT_ESRI]: (data) => {
      const features = _.get(data, 'features') || [];
      let lastUpdatedAt = 0;
      const byCountry = _.fromPairs(
        features.map((feature) => {
          const { attributes } = feature;

          const countryCode =
            mapCovidCountryNameAsCode(attributes['Country_Region']) ||
            attributes['Country_Region'];

          const lastUpdatedAtCountry = attributes['Last_Update'];
          if (lastUpdatedAtCountry > lastUpdatedAt) {
            lastUpdatedAt = lastUpdatedAtCountry;
          }

          return [
            countryCode,
            {
              confirmed: attributes['Confirmed'],
              deaths: attributes['Deaths'],
              recovered: attributes['Recovered']
            }
          ];
        })
      );

      const total = calculateTotalAllCountry(_.toPairs(byCountry));

      return {
        meta: {
          total,
          lastUpdatedAt
        },
        byTab: byCountry
      };
    },
    [COVID_ENDPOINT.CURRENT]: (data) => {
      const countryPairs = _.toPairs(_.get(data, 'countries.0'));
      const byCountry = _.fromPairs(
        _.map(countryPairs, ([countryName, data]) => [
          mapCovidCountryNameAsCode(countryName),
          data
        ]).filter(([countryCode, data]) => !!countryCode)
      );

      const total = _.fromPairs(
        ['confirmed', 'deaths', 'recovered'].map((key) => [
          key,
          _.sum(countryPairs.map(([countryName, stats]) => stats[key]))
        ])
      );

      return {
        meta: {
          total,
          // dt is formatted
          lastUpdatedAt: (_.get(data, 'ts') || 0) * 1000
        },
        byTab: byCountry
      };
    },
    [COVID_ENDPOINT.TS_CONFIRMED]: (data) => parseCovidTS(data, 'confirmed'),
    [COVID_ENDPOINT.TS_DEATHS]: (data) => parseCovidTS(data, 'deaths')

    // const values = _.map(_.toPairs(data.confirmed), ([i, byDateWithCountry]) => {
    //   const countryName = byDateWithCountry["Country/Region"]
    //   const province = byDateWithCountry["Province/State"]
    //   if(province){
    //
    //   }
  }[endpoint]);

export const getDatesInScope = (
  daysAgoStart = 1,
  daysAgoEnd = 14,
  dateFormat = 'M/d/yy'
) => {
  const endDate = new Date();

  return _.map(_.range(daysAgoStart, daysAgoEnd), (d) =>
    format(subDays(endDate, d), dateFormat)
  );
};

export const parseCovidTS = (dataTs, key) => {
  const recordsByCountry = _.groupBy(
    _.map(_.toPairs(dataTs[key]), ([i, countryData]) => countryData),
    (countryData) => mapCovidCountryNameAsCode(countryData['Country/Region'])
  );

  const dates = getDatesInScope();

  // TODO handle undefined & others
  const byCountryStats = _.mapValues(
    recordsByCountry,
    (countryRecords, countryCode) => {
      const statsByDate = sumStatsOfCountry(countryRecords, dates);

      const incrementByDate = {};
      _.forEach(statsByDate, (count, date) => {
        const prevDateIndex = _.findIndex(dates, (d) => d === date) + 1;
        if (prevDateIndex < dates.length) {
          const prevDate = dates[prevDateIndex];
          incrementByDate[date] = statsByDate[date] - statsByDate[prevDate];
        }
      });

      return {
        [`${key}-ts`]: incrementByDate
      };
    }
  );

  return {
    byTab: byCountryStats,
    meta: {
      [`${key}LastUpdatedAt`]: (_.get(dataTs, 'ts') || 0) * 1000
    }
  };
};

export const fetchCovidStats = async (
  endpoint: COVID_ENDPOINT,
  headers = {}
) => {
  const data = await fetch(endpoint, {
    headers: {
      ...headers
    }
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('fetch covid err', err);
    });
  // console.log('data', data);

  const parseData = parseDataFactory(endpoint);

  return !parseData || parseData(data);
};

export type TermsContext = {
  terms: string[];
  termsTab: string[];
  termsCommon: string[];
  termsNegative: string[];
  filter?: object;
};

// We need ES here so we can reuse to load videos too
// ensure byTopic is plain js
export const createQueryContextByTopic = (byTopic, baseContext) =>
  _.mapValues(byTopic, (data, key) => {
    const { queryContext } = data;

    const quertyContextWithTermsTags = createQueryContextWithTermsTags(
      _.merge({}, queryContext, {
        termsNegative: (baseContext.termsNegative || []).concat(
          queryContext.termsNegative
        )
      })
    );

    return _.merge({}, baseContext, {
      ...quertyContextWithTermsTags,
      // queryContext override
      filter: concatQueryFilterObject(baseContext.filter, {
        [VIDEO_YT_CATEGORY_ID]: (quertyContextWithTermsTags.filter || {})[
          VIDEO_YT_CATEGORY_ID
        ] || [22, 25, 26, 27, 28]
      }),
      notFilter: concatQueryFilterObject(
        baseContext.notFilter,
        quertyContextWithTermsTags.notFilter
      )

      // console.log('queryContext.terms', queryContext.terms, queryContext.ytTags);
      // return _.merge({}, baseContext, {
      //   functions: baseContext.functions.concat(
      //     createFunctionContextsWithTopic({
      //       termsTopic: queryContext.terms,
      //       ytTagsTopic: queryContext.ytTags
      //     })
      //   )

      // });
    });
  });

// tabQueryContext

export const createContextByGroup = (tabQueryContext, activeLocales) => {
  const baseContext = {
    isRecentlyPublished: {
      weight: 5,
      scale: '30d',
      offset: '10d',
      decay: 0.5
    },
    exists: 'video.description',
    // subtitlesOfLocales: [Locale.ZH_TW],
    // score not updated so misleading
    // functions: createFunctionContextsByGroup(tabQueryContext, activeLocales),
    notFilter: {
      'video.language': createPotentialYtDefaultLocales([Locale.ES_ES]),
      [VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY]: createPotentialYtDefaultLocales([
        Locale.ES_ES
      ]),
      [VIDEO_YT_DEFAULT_LOCALE_KEY]: createPotentialYtDefaultLocales([
        Locale.ES_ES
      ]),
      [VIDEO_YT_CHANNEL_ID]: [
        'UCexpzYDEnfmAvPSfG4xbcjA',
        'UCmYGsV8iup49FYP_SrZBP8Q',
        'UCHQ19T--vsniKAlt6BWb5hg',
        'UC4I85B9TMD4zYcZ6s0La8Aw',
        'UCFOr61-ucw7zjZcBcbEZ9mg'
      ]
    },
    filter: {
      // TODO null
      isWithSubtitles: false
    },
    paging: {
      size: 8
    }
  };

  const { byTopic } = tabQueryContext;

  const queryContextByTopic = createQueryContextByTopic(byTopic, baseContext);
  logger.debug('queryContextByTopic', JSON.stringify(queryContextByTopic));

  logger.debug('tabQueryContext', tabQueryContext);

  const tabQueryContextWithNegative = createQueryContextWithTermsTags(
    tabQueryContext,
    activeLocales
  );

  return {
    'news-highlights': _.merge({}, baseContext, {
      isRecentlyPublished: {
        weight: 20,
        scale: '24h',
        offset: '18h',
        decay: 0.6
      },
      ...tabQueryContextWithNegative,
      // TODO use source by curator to boost
      // https://gist.github.com/dgp/1b24bf2961521bd75d6c
      // override
      filter: _.merge({}, baseContext.filter, {
        // 'video.videoMeta.isSourcedByCurator': true,
        // 'video.videoMeta.isSourcedByCurator': true,
        [VIDEO_YT_CATEGORY_ID]: [25]
      }),
      notFilter: concatQueryFilterObject(
        baseContext.notFilter,
        tabQueryContextWithNegative.notFilter
      ),
      paging: {
        size: 4
      }
    }),
    trending: _.merge({}, baseContext, {
      isRecentlyPublished: {
        weight: 10,
        scale: '24h',
        offset: '48h',
        decay: 0.8
      },
      ...tabQueryContextWithNegative,
      functions: [
        {
          script_score: {
            script: {
              source: `1+Math.log10(Math.max(1,doc['${VIDEO_YT_VIEWS}'].value))`
            }
          },
          weight: 10
        }
      ],
      filter: {
        [VIDEO_YT_CATEGORY_ID]: [22, 27, 28]
      },
      paging: {
        size: 8
      }
    }),
    ...queryContextByTopic
  };
};

// decouple context constructions
export const createQueryContextWithTermsTags = (context, activeLocales) => {
  const effectiveLocalesBoost = _.intersection(
    activeLocales,
    context.localesBoost || []
  );

  return {
    ...createVideoMultiMatchWithTerms(context, effectiveLocalesBoost),
    notFilter: {
      'video.title': context.termsNegative
    }
    // functions: [
    // ].filter(Boolean)
  };
};

export const createQueryContextByGroupWithTopicTab = (
  topicData,
  tabKey,
  activeLocales
) => {
  const tabData = _.get(topicData, `byTab.${tabKey}`);
  const { queryContext, byTopic } = topicData.common || {};
  if (!tabData) {
    return;
  }

  const tabQueryContext = _.merge(
    {},
    {
      byTopic: _.mapValues(
        _.merge(byTopic, tabData.byTopic),
        (data, topicKey) =>
          _.merge({}, data, {
            queryContext: {
              termsNegative: queryContext.termsNegative,
              termsCommon: queryContext.terms,
              ytTagsCommon: queryContext.ytTags || []
            }
          })
      )
    },
    tabData.queryContext,
    {
      // not concat them for easier boost
      termsCommon: queryContext.terms,
      ytTagsCommon: queryContext.ytTags
    }
  );

  return createContextByGroup(tabQueryContext, activeLocales);
};
