import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import { IntlProvider } from 'react-intl';
import { lazyLoadLocaleData } from '~/react-intl-config';
import { IS_NODE, IS_DEV, IS_AGENT } from '@wordquest/lib-iso/env';
import { resolveSupportedLocale } from '@wordquest/lib-iso/intl';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import logger from '@wordquest/lib-iso/app/logger';
import {
  getMessagesWithLocale,
  getReactLocale,
  createIntlProviderProps
} from './react-intl-config';
import getPageContext from './get-page-context';
import { resolveUserLocale } from './intl-util';
import { LocalStorageNamespace } from './localstorage-namespace';
import { LocaleContext } from './locale-context';

function withRoot(Component) {
  class WithRoot extends React.Component {
    pageContext = null;

    constructor(props, context) {
      super(props, context);
      this.state.toggleLocale = (targetLocale) => {
        const { currentLocale } = this.state;
        logger.debug(
          'withRoot toggleLocale',
          targetLocale,
          'currentLocale',
          currentLocale
        );
        this.setState({
          currentLocale: targetLocale
        });
        // https://github.com/wordquest/wordquest/issues/415
        localStorage.setItem(LocalStorageNamespace.Locale, targetLocale);
      };
      // query not in props in case of app
      const { query } = props;

      const effectiveLocale = resolveUserLocale(query);

      // before data available
      // TODO server only
      if (IS_NODE || IS_AGENT) {
        const { toggleLocale } = this.state;
        toggleLocale(Locale.ZH_TW);
        lazyLoadLocaleData(Locale.ZH_TW);
      }

      if (effectiveLocale) {
        // possibly migrate away to server-side
        lazyLoadLocaleData(effectiveLocale).then(() => {
          const { toggleLocale } = this.state;
          toggleLocale(effectiveLocale);
        });
      }

      this.pageContext = props.pageContext || getPageContext();
      // https://github.com/yahoo/react-intl/issues/1106
    }

    state = {
      currentLocale: null
    };

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      // for site, need default locale to work
      const { currentLocale, toggleLocale } = this.state;
      if (!currentLocale && !IS_NODE) {
        return <div />;
      }
      logger.debug('withRoot render');
      const effectiveLocale =
        IS_NODE || IS_AGENT ? Locale.ZH_TW : currentLocale || Locale.EN;
      const reactLocale = getReactLocale(effectiveLocale);

      // ThemeProvider makes the theme available down the React tree thanks to React context.
      return (
        <LocaleContext.Provider value={{ toggleLocale, currentLocale }}>
          <IntlProvider
            key={reactLocale}
            locale={reactLocale}
            messages={getMessagesWithLocale(effectiveLocale)}
          >
            <ThemeProvider
              theme={this.pageContext.theme}
              // sheetsManager={this.pageContext.sheetsManager}
            >
              <StyledComponentsThemeProvider theme={{}}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...this.props} />
              </StyledComponentsThemeProvider>
            </ThemeProvider>
          </IntlProvider>
        </LocaleContext.Provider>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
