import _ from 'lodash';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Locale, getTagI18nKeyWithLanguage } from '@wordquest/locales';
import styled from 'styled-components';
import SwipeableCardsContainer from '~/swipeable-cards-container';

const DEFAULT_VIDEO_GROUP_SIZE = 8;

const StyledGroupTitle = styled.div`
  padding-top: 1.5em;
  padding-bottom: 1em;
`;

// separate groupOrders as object props might be unstable, as some are likely empty
// @deprecate groupTitlePrefix
export default ({
  orderedCardsByGroup,
  groupOrders,
  isCarrousel,
  isShowGroupTitle = true,
  groupTitlePrefix = '',
  getGroupTitleWithGroupKey = _.noop,
  maxSlidesPerView = 4.3
}) => {
  if (_.isEmpty(orderedCardsByGroup)) {
    return <div />;
  }

  const effectiveGroupOrders = _.filter(
    _.intersection(groupOrders || _.keys(orderedCardsByGroup)),
    (key) => {
      const cards = orderedCardsByGroup[key];

      return cards && cards.length > 0;
    }
  );

  return (
    <>
      {_.map(effectiveGroupOrders, (groupKey) => {
        const cards = orderedCardsByGroup[groupKey];

        const groupI18nKey = getTagI18nKeyWithLanguage(groupKey);

        return (
          <div key={groupKey}>
            {isShowGroupTitle && (
              <StyledGroupTitle>
                <Typography gutterBottom variant="h4" component="h3">
                  {groupTitlePrefix}
                  {getGroupTitleWithGroupKey(groupKey) ||
                    (groupI18nKey && <FormattedMessage id={groupI18nKey} />)}
                </Typography>
              </StyledGroupTitle>
            )}
            {!isCarrousel ? (
              <Grid container spacing={4}>
                {cards.map((card) => (
                  <Grid item xs={3}>
                    {card}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <SwipeableCardsContainer
                isShownNavButtons
                spaceBetween={0}
                centeredSlides={false}
                maxSlidesPerView={maxSlidesPerView}
              >
                {cards}
              </SwipeableCardsContainer>
            )}
          </div>
        );
      })}
    </>
  );
};
