import styled from 'styled-components';

export const Headline = styled.div`
  > h2 {
    font-weight: 700;
  }
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

// ${media.phone`
//   width: 100%;
//   left: 0px;
// `}
export const BottomSticky = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 1300;
  width: 100%;
  left: 0px;
`;
