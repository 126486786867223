// @ts-nocheck
import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import { set, toJS } from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';

import { assign, createMachine, interpret, send, actions } from 'xstate';

const { pure } = actions;

export enum SubtitlesState {
  Loading = 'loading',
  Initialized = 'initialized'
}

export const SUBTITLES_CONFIG_OFF = 'off';

// we can't tell if it is source / target
export type SubtitlesConfig = typeof SUBTITLES_CONFIG_OFF | Locale;

export enum SubtitlesEvent {
  Reset = 'reset',
  // per e.g. course settings
  InitSubtitles = 'init-subtitles',
  ToggleSubtitlesControl = 'toggle-subtitles-control'
}

export enum SubtitlesControl {
  Source = 'source',
  Target = 'target'
}

export type ToggleSubtitlesEvent = {
  controls: ['source', 'target'];
  loadedSubtitles: Locale[];
  locale: Locale;
};

export enum SubtitlesLocaleState {
  Off = 'off',
  Toggling = 'toggling',
  Initialized = 'initialized'
}

export enum SubtitlesLocaleEvent {
  ToggleSubtitlesOff = 'toggle-subtitles-off',
  ToggleSubtitlesOn = 'toggle-subtitles-on'
}

// either model all locales as state or on/off
// by design, locale target must be pre-defined and explicit (we can only tansition by event not state target)
export const createSubtitleState = (locales) => ({
  initial: SubtitlesLocaleState.Off,
  on: {
    [SubtitlesLocaleEvent.ToggleSubtitlesOn]: {
      target: `.${SubtitlesLocaleState.Toggling}`,
      actions: assign((context, event) => {
        console.log('toggle subtitles at control', context, event);

        return { ...context, ...(event || {}) };
      })
    },
    [SubtitlesLocaleEvent.ToggleSubtitlesOff]: {
      target: `.${SubtitlesLocaleState.Off}`
    }
  },
  states: {
    [SubtitlesLocaleState.Off]: {
      entry: () => {
        console.log('off');
      }
    },
    ..._.fromPairs(
      locales.map((locale) => [
        locale,
        {
          on: {
            [SubtitlesLocaleEvent.ToggleSubtitlesOff]: {
              target: SubtitlesLocaleState.Off
            }
          }
        }
      ])
    ),
    // consider guard when unavailable and back
    [SubtitlesLocaleState.Toggling]: {
      always: [
        ...locales.map((locale) => ({
          target: locale,
          // https://github.com/statelyai/xstate/issues/890
          // reuse with explicit on
          cond: (context, event, meta) => {
            const originalEvent = meta.state.event;
            console.log(`toggle to locale: ${locale}:`, context, originalEvent);

            return (
              _.includes(locales, locale) && originalEvent.locale === locale
            );
          }
        })),
        {
          target: SubtitlesLocaleState.Off,
          cond: (context, event, meta) => {
            console.log('toggle locale not available', context, event);

            return true;
          }
        }
      ]
    }
  }
});

export const createSubtitlesStateMachine = (sourceLocale, targetLocales) =>
  createMachine(
    {
      id: 'subtitles-state',
      initial: SubtitlesState.Loading,
      context: {},
      // will be overriden by individual states
      on: {
        [SubtitlesEvent.Reset]: {
          target: SubtitlesState.Loading
        }
        //  trigger on/off
      },
      states: {
        [SubtitlesState.Loading]: {
          on: {
            [SubtitlesEvent.InitSubtitles]: {
              target: SubtitlesState.Initialized,
              actions: [
                'initSubtitles',
                'initSubtitlesSource',
                'initSubtitlesTarget'
              ]
            }
          }
        },
        [SubtitlesState.Initialized]: {
          type: 'parallel',
          // originally we want to pass the subtitles locale, but due to xstate limitaitons we pass along the config and let nested state decide
          on: {
            [SubtitlesEvent.ToggleSubtitlesControl]: [
              null,
              SubtitlesControl.Source,
              SubtitlesControl.Target
            ].map((control) => ({
              // unable to use send against nststed state, use separate target instead
              //  problem here: opnly one got executed, we do ugly hack of diff options list all
              target: [SubtitlesControl.Source, SubtitlesControl.Target]
                .filter((target) => control === null || target.match(control))
                .map(
                  (control) => `.${control}.${SubtitlesLocaleState.Toggling}`
                ),
              cond: (context, event) => {
                console.log(
                  '[ToggleSubtitlesControl] check',
                  control,
                  context,
                  event,
                  _.isEmpty(event.controls),
                  _.includes(event.controls, control)
                );
                if (control === null) {
                  return _.isEmpty(event.controls);
                }

                return _.includes(event.controls, control);
              }
            }))
          },
          states: {
            source: createSubtitleState([sourceLocale]),
            target: createSubtitleState(targetLocales)
          }
        }
      }
    },
    {
      actions: {
        // support undefined
        assignWithLocaleControls: assign((context, event) => ({
          ...context,
          ...(event || {})
        })),
        initSubtitlesTarget: pure((context, event) => {
          if (!!event.config && !_.includes(targetLocales, event.config)) {
            return;
          }
          console.log('initSubtitles target', context, event);

          return send({
            type: SubtitlesEvent.ToggleSubtitlesControl,
            controls: ['target'],
            loadedSubtitles: [Locale.EN, Locale.ZH_TW],
            locale: event.config || _.first(targetLocales)
          });
        }),
        initSubtitlesSource: pure((context, event) => {
          if (
            !!event.config &&
            event.config !== sourceLocale &&
            !_.includes(targetLocales, event.config)
          ) {
            return;
          }
          console.log('initSubtitles source', context, event, sourceLocale);

          return send({
            type: SubtitlesEvent.ToggleSubtitlesControl,
            controls: ['source'],
            loadedSubtitles: [Locale.EN, Locale.ZH_TW],
            locale: sourceLocale
          });
        })
      }
    }
  );

// simplify the nested state
export const mapStateMachineAsSubtitlesState = (state) => {
  const currentState = state.value;

  const subtitlesState = {
    lifecycle: SubtitlesState.Loading,
    source: null,
    target: null
  };
  if (currentState[SubtitlesState.Initialized]) {
    const { source, target } = currentState[SubtitlesState.Initialized];

    return {
      lifecycle: SubtitlesState.Initialized,
      source,
      target
    };
  }

  return subtitlesState;
};

export const initSubtitlesStateService = (pageVideoStore) => {
  const {
    defaultLocale: sourceLocale,
    currentSubtitlesSortedLocales: targetLocales = []
  } = pageVideoStore;

  console.log('initSubtitlesStateService', sourceLocale, targetLocales);

  return interpret(createSubtitlesStateMachine(sourceLocale, targetLocales))
    .onTransition((state) => {
      logger.debug('[subtitlesState]transition', state.value);
      // if merge state directly, error Attempt to read an array index (0) that is out of bounds (0)
      _.merge(
        pageVideoStore.currentSubtitles.subtitlesState,
        mapStateMachineAsSubtitlesState(state)
      );
      const {
        isSubtitlesOnByLocale,
        currentSubtitles,
        currentSubtitlesSortedLocales
      } = pageVideoStore;
      syncSubtitlesState(
        currentSubtitles.subtitlesState,
        isSubtitlesOnByLocale,
        currentSubtitlesSortedLocales
      );
    })
    .start();
};

// TODO check could we always get subtitlesLocales from isSubtitlesOnByLocale
export const createUpdateIsSubtitlesOnByLocale =
  (
    isSubtitlesOnByLocale: Map<Locale | 'off', boolean>,
    subtitlesLocales: Locale[]
  ) =>
  (selectedSourceOption, selectedTargetOption) =>
    isSubtitlesOnByLocale.merge(
      _.merge(
        _.fromPairs(subtitlesLocales.map((l) => [l, false])),
        selectedSourceOption !== SUBTITLES_CONFIG_OFF
          ? {
              [selectedSourceOption]: true
            }
          : {},
        selectedTargetOption !== SUBTITLES_CONFIG_OFF
          ? {
              [selectedTargetOption]: true
            }
          : {}
      )
    );

export const pickSelectedOptions = (currentState, isSubtitlesOnByLocale) => {
  const currentSourceLocale =
    _.get(currentState, 'source') || SUBTITLES_CONFIG_OFF;
  const currentTargetLocale =
    _.get(currentState, 'target') || SUBTITLES_CONFIG_OFF;
  const currentSourceOption = isSubtitlesOnByLocale.get(currentSourceLocale)
    ? currentSourceLocale
    : SUBTITLES_CONFIG_OFF;

  const currentTargetOption = isSubtitlesOnByLocale.get(currentTargetLocale)
    ? currentTargetLocale
    : SUBTITLES_CONFIG_OFF;
  // setSelectedTargetOption(currentTargetOption);

  return {
    currentSourceLocale,
    currentTargetLocale,
    currentSourceOption,
    currentTargetOption
  };
};
// export const createSyncSubtitlesStatePair =

// either do that at transition or react in mobx
// potentially could remove isSubtitlesOnByLocale
export const syncSubtitlesState = (state, isSubtitlesOnByLocale) => {
  const updateIsSubtitlesOnByLocale = createUpdateIsSubtitlesOnByLocale(
    isSubtitlesOnByLocale,
    _.keys(toJS(isSubtitlesOnByLocale))
  );

  const currentState = state;
  if (currentState === SubtitlesState.Loading) {
    return updateIsSubtitlesOnByLocale(
      SUBTITLES_CONFIG_OFF,
      SUBTITLES_CONFIG_OFF
    );
  }

  const { currentSourceLocale, currentTargetLocale } = pickSelectedOptions(
    currentState,
    isSubtitlesOnByLocale
  );

  // _.keys(_.get(currentState, SubtitlesState.Initialized) || {})
  // .map(
  //   (control)=>{
  //     const locale  = _.get(currentState, `${SubtitlesState.Initialized}.${control}`);
  //     const isOn = (_.includes(currentSubtitlesSortedLocales, locale));
  //     set(isSubtitlesOnByLocale, locale, isOn);
  //   }
  // );

  return updateIsSubtitlesOnByLocale(currentSourceLocale, currentTargetLocale);
};
