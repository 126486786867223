// import _ from 'lodash';
// import { AppEvent } from './app-event';
// circular deps but type-only
import { UserChatConfig } from '@wordquest/wq-chat/app/user-config/user-config';
import { Platform } from '~/domain/wordquest/platform';

import {
  Entity,
  Action,
  WordAction,
  ArticleAction,
  ProfileAction,
  QuestAction,
  MessageAction
} from '~/domain/wordquest/entity';

// What User do /being done. Naming convention follows
export type UserEvent<E extends Entity, A extends Action, P> = {
  userId: string;
  userIdToken: string;
  messageId?: string;
  anonymousId?: string;
  entity: E;
  action: A;
  actionAt?: Date;
  properties: P;
};

export function createUserEvent<
  T extends UserEvent<Entity, Action, object>
>(doc: {
  userId: string;
  entity: T['entity'];
  action: T['action'];
  actionAt?: T['actionAt'];
  properties: T['properties'];
}): T {
  return Object.assign(doc, {
    actionAt: doc?.actionAt || new Date()
  }) as T;
}
/**
 * intentionally coupled with other modules
 */

export type WordToggledInDeckUserEvent = UserEvent<
  Entity.Word,
  WordAction.ToggledInDeck,
  {}
>;

export type WordLookedUpUserEvent = UserEvent<
  Entity.Word,
  WordAction.LookedUp,
  {}
>;

export type MessageSentFbmUserEvent = UserEvent<
  Entity.Message,
  MessageAction.SentFbm,
  {
    rawMessageString: any;
    platformUserId: string;
  }
>;

export type MessageSentLineUserEvent = UserEvent<
  Entity.Message,
  MessageAction.SentLine,
  {
    rawMessageString: any;
    platformUserId: string;
  }
>;

export type ProfileTraitsUpdatedUserEvent = UserEvent<
  Entity.Profile,
  ProfileAction.TraitsUpdated,
  {
    chatConfig?: Record<Platform, UserChatConfig>;
  }
>;

export type ProfileCustomerIOSegmentsUpdatedUserEvent = UserEvent<
  Entity.Profile,
  ProfileAction.CustomerIOSegmentsUpdated,
  {}
>;

export type QuestCompletedUserEvent = UserEvent<
  Entity.Quest,
  QuestAction.Completed,
  {}
>;

export type QuestCheckedInUserEvent = UserEvent<
  Entity.Quest,
  QuestAction.Completed,
  {}
>;

export type ProfileQueriedUserEvent = UserEvent<
  Entity.Profile,
  ProfileAction.Queried,
  {}
>;
