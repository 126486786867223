import { EsConfig } from '~/adapters/elasticsearch';
import { ENTITES, ENTITY_META, Entity } from '~/domain/wordquest/entity';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import _ from 'lodash';
import logger from '~/app/logger';

export const ES_INDEX_APP_EVENTS = 'app-events';

// e.g. Word: word-v2-ja
// @ts-ignore

export const ES_INDEX_BY_ENTITY_LOCALE: Record<
  Entity,
  Record<Locale, string>
> = _.mapValues(ENTITY_META, (meta, entity) =>
  _.fromPairs(
    // Some locales might be captial case too e.g. zh-TW
    SUPPORTED_LOCALES.map((locale) => [
      locale,
      _.toLower([entity, meta.VERSION, locale].join('-'))
    ])
  )
);

logger.debug('ES_INDEX_BY_ENTITY_LOCALE', ES_INDEX_BY_ENTITY_LOCALE);
export const ES_CONFIG = {
  APP_CONTENT: {
    host: process.env.REACT_APP_ES_APP_CONTENT_HOST,
    auth: [
      process.env.REACT_APP_ES_APP_CONTENT_RO_USER,
      process.env.REACT_APP_ES_APP_CONTENT_RO_PASSWORD
    ].join(':'),
    port: process.env.REACT_APP_ES_APP_CONTENT_PORT
  },
  APP_EVENTS: {
    host: process.env.ES_APP_EVENTS_APP_HOST,
    auth: [
      process.env.ES_APP_EVENTS_APP_USER,
      process.env.ES_APP_EVENTS_APP_PASSWORD
    ].join(':'),
    port: process.env.ES_APP_EVENTS_APP_PORT
  }
};
ES_CONFIG.APP_CONTENT_ADMIN = _.merge({}, ES_CONFIG.APP_CONTENT, {
  auth: [
    process.env.ES_APP_CONTENT_ADMIN_USER,
    process.env.ES_APP_CONTENT_ADMIN_PASSWORD
  ].join(':')
});
