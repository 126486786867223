import _ from 'lodash';
import bodybuilder from 'bodybuilder';
import { format } from 'date-fns';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity, DataAction } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import Video from '~/domain/wordquest/video/video';
import { VideoSubtitlesEntry } from '~/domain/wordquest/video/video-subtitles';
import rootLogger from '~/app/logger';
import { isNotEmptyArray, asValidISODate } from '~/utils';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withPaging,
  withSort,
  withIncludeIdsFilter,
  withTermsFilter,
  createShouldFilter,
  createMultiMatchesQuery
} from '~/app/repo-es-util';
import {
  createDataEvent,
  VideoUpsertedDataEvent,
  VideoSubtitlesUpsertedDataEvent,
  VideoSubtitlesAnalyzedDataEvent,
  VideoTitleDescriptionUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';

import clean from 'lodash-clean';
import { bufferCount, tap, map, flatMap } from 'rxjs/operators';
import { createPotentialYtDefaultLocales } from '~/adapters/youtube-locale';
import {
  VIDEO_YT_TAGS_KEY,
  VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY,
  VIDEO_YT_DEFAULT_LOCALE_KEY
} from './video-yt';

const logger = rootLogger.child({ module: 'video-repo' });
const DEFAULT_SOURCE_FIELDS = ['video', 'indexedAt', 'lastIndexedAt'];
export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Video];

export const ES_MAPPING_VIDEO_BY_LOCALE = _.fromPairs(
  SUPPORTED_LOCALES.map((sourceLocale) => [
    sourceLocale,
    {
      // store cannot be specificed. in _source anyway
      // https://www.elastic.co/guide/en/elasticsearch/reference/6.3/object.html
      properties: {
        indexedAt: {
          type: 'date'
        },
        lastIndexedAt: {
          type: 'date'
        },
        video: {
          properties: {
            publishedAt: {
              type: 'date'
            },
            lastYtDownloadSuccessAt: {
              type: 'date'
            },
            // TODO when remap
            // tags: {
            //   'type': 'keyword'
            // },
            videoMeta: {
              properties: {
                isShortListed: {
                  type: 'boolean'
                },
                isAnalyzed: {
                  type: 'boolean'
                },
                isSourcedByCurator: {
                  type: 'boolean'
                },
                isHiddenByCurator: {
                  type: 'boolean'
                },
                analyedAt: {
                  type: 'date'
                }
              }
            }
          }
        },

        locales: {
          properties: _.fromPairs(
            SUPPORTED_LOCALES.map((targetLocale) => [
              targetLocale,
              {
                properties: {
                  description: {
                    properties: {
                      title: {
                        type: 'text'
                      },
                      description: {
                        type: 'text'
                      },
                      lastEditedAt: {
                        type: 'date'
                      },
                      locale: {
                        type: 'text',
                        fields: {
                          keyword: {
                            type: 'keyword',
                            ignore_above: 256
                          }
                        }
                      },
                      lastEditedBy: {
                        type: 'text',
                        fields: {
                          keyword: {
                            type: 'keyword',
                            ignore_above: 256
                          }
                        }
                      }
                    }
                  },
                  subtitles: {
                    properties: {
                      raw: {
                        type: 'text'
                      },
                      tagged: {
                        type: 'object',
                        enabled: false
                      },
                      format: {
                        type: 'keyword'
                      },
                      lastActionPriority: {
                        type: 'short'
                      },
                      actionPriority: {
                        type: 'short'
                      },
                      lastEditedAt: {
                        type: 'date',
                        ignore_malformed: true
                      },
                      lastEditedBy: {
                        type: 'keyword'
                      },
                      analyzedAt: {
                        type: 'date',
                        ignore_malformed: true
                      },
                      tokenInfoByToken: {
                        type: 'object',
                        enabled: false
                      },
                      cuesWithWqDom: {
                        type: 'object',
                        enabled: false
                      }
                    }
                  }
                }
              }
            ])
          )
        }
      }
    }
  ])
);

export const withSubtitlesOfLocalesOnly = (context, _body) => {
  let body = _body;
  (context.subtitlesOfLocales || [Locale.EN]).forEach(
    // eslint-disable-next-line
    (locale) => {
      body = body.query('exists', `locales.${locale}.subtitles.raw`);
    }
  );

  return body;
};

export const withSubtitlesOfLocalesUnanalyzedOnly = (context, _body) => {
  let body = _body;
  (context.subtitlesOfLocales || [Locale.EN]).forEach(
    // eslint-disable-next-line
    (locale) => {
      body = body.notFilter('exists', `locales.${locale}.subtitles.analyzedAt`);
    }
  );

  return body;
};

export type VideoQueryContext = BaseQueryContext & {
  fields?: {
    isIdOnly?: boolean;
    isWithLocales?: boolean;
    isWithSubtitlesLocales?: boolean;
    isWithSubtitlesCount?: boolean;
  };
  descriptionsOfLocales?: Locale[];
  subtitlesOfLocales?: Locale[];
  multiMatches?: {};
  terms?: {};
  notFilter?: {};
  filter?: {
    'video.videoMeta.isAnalyzed': boolean;
    'video.videoMeta.isShortListed': boolean;
    isWithSubtitles: boolean;
    isWithSubtitlesUnanalyzed: boolean;
    isAnalyzed: boolean;
    includeIds?: string[];
    excludeIds?: string[];
  };
  isRecentlyPublished?: boolean;
  isRecentlyIndexed?: boolean;
};

export type VideoDescriptionEntry = {
  title: string;
  description: string;
  locale: Locale;
  lastEditedBy: string;
  lastEditedAt: Date;
};

export type VideoEsDoc = {
  id: string;
  locales: Record<Locale, VideoSubtitlesEntry | VideoDescriptionEntry>;
  video: object;
};

export const buildVideoQuery = (
  locale: Locale,
  _context: VideoQueryContext = {}
): EsQuery => {
  const context = _.defaultsDeep(_context, {
    fields: {
      isWithSubtitlesLocales: true,
      isWithSubtitlesCount: true
    },
    filter: {
      'video.videoMeta.isHiddenByCurator': false,
      isWithSubtitles: true
    },
    // no filter as defaults as analyzer also rely on it
    paging: {
      size: 10
    }
  });
  // TODO after data ready
  // if (_.isEmpty(context.filter.includeIds)
  // && _.isEmpty((context.filter || {})['video.language'])
  // ) {
  //   context.filter['video.language'] = [Locale.EN, Locale.JA, Locale.KO];
  // }
  delete context.filter['video.language'];
  let body = bodybuilder();
  let functions = [];
  // TODO better currying
  body = withIncludeIdsFilter(context)(
    withTermsFilter(context)(withSort(context)(body))
  );

  // Limit only those with targeted subtitles in future
  // body = withLocaleSubtitlesOnly(context, body);

  // source skip subtitles
  let sourceFields = DEFAULT_SOURCE_FIELDS;
  const fields = context.fields || {};
  if (fields.isIdOnly) {
    sourceFields = ['id'];
  } else if (fields.isWithLocales) {
    if (_.isArray(context.subtitlesOfLocales)) {
      sourceFields = sourceFields.concat(
        context.subtitlesOfLocales.map((l) => `locales.${l}.subtitles`)
      );
    } else if (_.isArray(context.descriptionsOfLocales)) {
      sourceFields = sourceFields.concat(
        context.descriptionsOfLocales.map((l) => `locales.${l}.description`)
      );
    } else {
      sourceFields = sourceFields.concat(['locales']);
    }
  }

  body = body.rawOption('_source', sourceFields);

  // TODO refactor when to do default query
  // exists query so can't add
  // TODO not in use due to default filter
  let isUseDefaultQuery = _.isEmpty(_.get(context, 'terms'));
  // TODO use not as key probably
  _.mapValues(context.notFilter, (filterValue, filterKey) => {
    const notFilters =
      _.isArray(filterValue) && !_.isEmpty(filterValue)
        ? filterValue
        : [filterValue];
    _.forEach(notFilters, (filter) => {
      body = body.notFilter('match_phrase', filterKey, filter);
    });
  });

  // don't use nested which is more for array
  _.mapValues(context.filter, (filterValue, filterKey) => {
    if (filterKey === 'isWithSubtitles' && filterValue) {
      isUseDefaultQuery = false;
      body = withSubtitlesOfLocalesOnly(context, body);
    } else if (filterKey === 'isWithSubtitlesUnanalyzed' && !!filterValue) {
      isUseDefaultQuery = false;
      body = withSubtitlesOfLocalesUnanalyzedOnly(context, body);
    } else if (filterKey === 'script') {
      body = body.filter('script', 'script', filterValue);
    } else if (!_.includes(['includeIds', 'isWithSubtitles'], filterKey)) {
      // for or filter use terms:{} in context
      isUseDefaultQuery = false;
      // TODO multiple OR? use {abc: or:{}}
      if (filterKey === 'or' && _.isObject(filterValue)) {
        body = body.filter(
          'bool',
          createShouldFilter(filterValue, 'should', false)
        );
      } else if (_.isArray(filterValue) && !_.isEmpty(filterValue)) {
        body = body.filter(
          'bool',
          createShouldFilter({
            [filterKey]: filterValue
          })
        );

        // default to use or
        // we don't have a mechanism to combine AND (OR(A,B), OR(C,D)) here
        // _.forEach(filterValue, (v) => {
        //   // most cases are tag
        //   body = body
        //     .orFilter('match_phrase', filterKey, v);
        // });
      } else if (filterValue === false) {
        body = body.notFilter('match', filterKey, true);
      } else {
        body = body.filter('match', filterKey, filterValue);
      }
    }
  });

  if (context.functions) {
    functions = functions.concat(context.functions);
  }
  if (context.notexists) {
    const notexistsAll = _.isArray(context.notexists)
      ? context.notexists
      : [context.notexists];
    notexistsAll.forEach((notexists) => {
      body = body.notFilter('exists', notexists);
    });
  }

  if (context.exists) {
    const existsAll = _.isArray(context.exists)
      ? context.exists
      : [context.exists];
    existsAll.forEach((exists) => {
      body = body.query('exists', exists);
    });
  }

  if (isUseDefaultQuery) {
    // add here in case of empty or only exists query
    body = body.query('match_all');
  }

  const scriptFieldByKey = {};
  // Also, the doc[...] notation only allows for simple valued fields (you can’t return a json object from it) and makes sense only for non-analyzed or single term based fields. However, using doc is still the recommended way to access values from the document, if at all possible, because _source must be loaded and parsed every time it’s used. Using _source is very slow.

  if (fields.isWithSubtitlesCount) {
    scriptFieldByKey['subtitles_count'] = {
      script: {
        lang: 'painless',
        source:
          'if(params[\'_source\'].containsKey(\'locales\')) { def subtitles_locales=[]; def locales = params[\'_source\'].locales.keySet(); for (def l :locales){ if(params[\'_source\'].locales[l].containsKey(\'subtitles\')) {subtitles_locales.add(l)} } return  subtitles_locales.size();  } else {return 0}'
      }
    };
  }
  // note locale might contain description only
  if (fields.isWithSubtitlesLocales) {
    scriptFieldByKey['subtitles_locales'] = {
      script: {
        lang: 'painless',
        source:
          'if(params[\'_source\'].containsKey(\'locales\')) { def subtitles_locales=[]; def locales = params[\'_source\'].locales.keySet(); for (def l :locales){ if(params[\'_source\'].locales[l].containsKey(\'subtitles\')) {subtitles_locales.add(l)} } return  subtitles_locales;  } else {return null}'
      }
    };
  }
  if (!_.isEmpty(scriptFieldByKey)) {
    body = body.rawOption('script_fields', scriptFieldByKey);
  }

  // always with paging to make query structure consistent
  body = withPaging(context)(body);

  if (context.isRecentlyPublished) {
    const options = _.isObject(context.isRecentlyPublished)
      ? _.omit(context.isRecentlyPublished, 'weight')
      : {
          scale: '90d',
          offset: '10d',
          decay: 0.2
        };

    functions.push({
      weight: context.isRecentlyPublished.weight || 1,
      exp: {
        'video.publishedAt': {
          origin: format(new Date(), 'yyyy-MM-dd'),
          ...options
        }
      }
    });
  }

  if (context.isRecentlyIndexed) {
    functions.push({
      weight: 1,
      gauss: {
        lastIndexedAt: {
          origin: format(new Date(), 'yyyy-MM-dd'),
          scale: '1d',
          offset: '1d',
          decay: 0.2
        }
      }
    });
  }
  // funcitons boost the query inside , not the outer one
  // TODO more generic
  if (functions.length > 0) {
    let query = { match_all: {} };
    if (context.multiMatches && _.isArray(context.multiMatches)) {
      query = createMultiMatchesQuery(context.multiMatches).query;
      console.log('multiMatches query', query);
    }
    body = body.query('function_score', {
      query,
      functions: functions.filter(Boolean)
    });
  }

  return body.build();
};

export const docAsVideo = ({ _id, _source: { video, locales }, fields }) =>
  Video.create(
    _.merge(
      video,
      // when _source is id video got it empty
      {
        id: _id
      },
      {
        publishedAt: asValidISODate(video.publishedAt)
      },
      {
        videoMeta: {
          subtitlesCount: _.get(fields, 'subtitles_count.0') || 0,
          subtitlesLocales: _.get(fields, 'subtitles_locales') || []
        }
      },
      // potentially extract to separate query
      {
        titleByLocale: clean(
          _.mapValues(locales, (localeMeta, locale) =>
            _.get(localeMeta, 'description.title')
          )
        ),
        descriptionByLocale: clean(
          _.mapValues(locales, (localeMeta, locale) =>
            _.get(localeMeta, 'description.description')
          )
        )
      }
    )
  );

export const docAsVideoIdSubtitlesByLocalePair = ({
  _id,
  _source: { video, locales }
}) => [_id, _.mapValues(locales, (docOfLocale) => docOfLocale.subtitles)];

export const getPlatformId = (video: Video) =>
  _.get(video, `videoPlatformMeta.${video.platform}.id`);

export const createVideoId = (videoPlatform: string, id: string) =>
  `${videoPlatform}-${id}`;

export const mapAsVideoId = (video: {
  platform: string;
  videoPlatformMeta: Record<string, { id: string }>;
}) => `${video.platform}-${video.videoPlatformMeta[video.platform].id}`;

export const mapVideoIdAsPlatformIdPairs = (videoId: string) => {
  // some platform id got -
  const indexToSplit = videoId.indexOf('-');

  return [
    videoId.substring(0, indexToSplit),
    videoId.substring(indexToSplit + 1)
  ];
};

export const mapVideoAsDoc = (video: Video): VideoEsDoc => {
  const { subtitlesCreditByLocale } = video.videoMeta || {};
  const { titleByLocale, descriptionByLocale } = video;

  const keysToOmit = _.isEmpty(video.tags) ? ['tags'] : [];
  // TODO probably better to always have tags defined. after we convert this as es op
  const cleanVideo = _.omit(_.toPlainObject(video), keysToOmit);

  cleanVideo.videoMeta = _.omit(video.videoMeta, [
    'subtitlesCount',
    'subtitlesLocales',
    'subtitlesCreditByLocale'
  ]);
  const lastEditedAt = new Date();

  return {
    id: video.id,
    video: cleanVideo,
    locales: _.merge(
      {},
      _.mapValues(titleByLocale || {}, (title) => ({
        description: { title, lastEditedAt }
      })),
      _.mapValues(descriptionByLocale || {}, (description) => ({
        description: { description, lastEditedAt }
      })),
      _.mapValues(subtitlesCreditByLocale || {}, (credit) => ({
        subtitles: { credit }
      }))
    )
  };
};

export const mapVideoSubtitlesAnalyzedDataEventAsOp = (
  event: VideoSubtitlesAnalyzedDataEvent
) => {
  const {
    video: { id },
    locale,
    cuesWithWqDom,
    tagged,
    analyzedAt,
    tokenInfoByToken
  } = event.properties;

  if (tagged) {
    return {
      id,
      script: {
        inline:
          'if(ctx._source.locales != null && ctx._source.locales[params.locale]!=null && ctx._source.locales[params.locale].subtitles !=null ) { ctx._source.locales[params.locale].subtitles.tagged = params.tagged;      ctx._source.locales[params.locale].subtitles.analyzedAt = params.analyzedAt; ctx._source.locales[params.locale].subtitles.tokenInfoByToken = params.tokenInfoByToken;}',
        lang: 'painless',
        params: {
          locale,
          cuesWithWqDom,
          tagged,
          analyzedAt,
          tokenInfoByToken
        }
      }
    };
  }

  return {
    id,
    script: {
      inline:
        'if(ctx._source.locales != null && ctx._source.locales[params.locale]!=null && ctx._source.locales[params.locale].subtitles !=null ) {  ctx._source.locales[params.locale].subtitles.analyzedAt = params.analyzedAt; ctx._source.locales[params.locale].subtitles.cuesWithWqDom = params.cuesWithWqDom;}',
      lang: 'painless',
      params: {
        locale,
        cuesWithWqDom,
        tagged,
        analyzedAt,
        tokenInfoByToken
      }
    }
  };
};

// TODO refactor tgt with  mapVideoSubtitlesUpsertedDataEventAsOp
export const mapVideoTitleDescriptionsDataEventAsOp = (
  event: VideoTitleDescriptionUpsertedDataEvent
) => {
  const {
    video: { id },
    actionBy,
    description
  } = event.properties;

  const actionPriority = getActionPriority(actionBy);

  return {
    id,
    script: {
      inline:
        'if (ctx._source.locales == null) { ctx._source.locales =  params.locales;  } else if( ctx._source.locales[params.locale] == null){ ctx._source.locales[params.locale] = params.locales[params.locale] }  else if (params.actionBy == "delete" ){ ctx._source.locales.remove(params.locale);  } else { ctx._source.locales[params.locale].description = params.description  }',
      lang: 'painless',
      params: {
        actionBy,
        locale: description.locale,
        actionPriority,
        description,
        locales: {
          [description.locale]: {
            description
          }
        }
      }
    }
  };
};

export const mapVideoSubtitlesUpsertedDataEventAsOp = (
  event: VideoSubtitlesUpsertedDataEvent
) => {
  const {
    video: { id },
    actionBy,
    subtitles
  } = event.properties;
  const actionPriority = getActionPriority(actionBy, (subtitles || {}).format);

  return {
    id,
    // TODO refactor this after data cleaning
    // for independent doc, will create video too ctx._source -> create
    // allow same action priority override, regardless of lastEditedAt
    // ctx._source.locales[params.locale]?.subtitles?.lastEditedAt < params.subtitles.lastEditedAt
    script: {
      inline:
        'if (ctx._source.locales == null) { ctx._source.locales =  params.locales;  } else if( ctx._source.locales[params.locale] == null){ ctx._source.locales[params.locale] = params.locales[params.locale] }  else if (params.actionBy == "delete" ){ ctx._source.locales.remove(params.locale);  } else if (ctx._source.locales[params.locale]?.subtitles == null || ctx._source.locales[params.locale]?.subtitles?.lastActionPriority <= params.actionPriority) { ctx._source.locales[params.locale].subtitles = params.subtitles    } else  {ctx.op = \'none\'}',
      lang: 'painless',
      params: {
        actionBy,
        locale: subtitles.locale,
        actionPriority,
        subtitles: _.merge(subtitles, { lastActionPriority: actionPriority }),
        locales: {
          [subtitles.locale]: {
            subtitles: _.merge(subtitles, {
              lastActionPriority: actionPriority
            })
          }
        }
      }
    }
  };
};

// This version remove defaults values such as [] for upsert
export const mapVideoAsPartialDoc = (video: Video): VideoEsDoc => ({
  id: video.id,
  video: clean(_.toPlainObject(video))
});

export const withShortListedContext = (context) =>
  _.merge(context, {
    filter: {
      'video.videoMeta.isShortListed': true
    }
  });

// Case1: personalized recommendations
// - group = relevance + popular + potential tags
// Case2: multiple tag filters
// - group = tag filter
// Always use pre-query
// potentially separate requests for progressive
export const queryVideosWithContexts = async (
  locale: Locale,
  contexts: VideoQueryContext[]
) => {
  const index = ES_INDEX_BY_ENTITY_LOCALE[Entity.Video][locale];
  logger.debug('[Debug]queryVideosWithContexts query & context', contexts);

  return msearch(
    ES_CONFIG.APP_CONTENT,
    contexts.map((context) => [index, buildVideoQuery(locale, context)])
  ).then((videoLists) =>
    _.map(videoLists, (docs) => docs.map((doc) => docAsVideo(doc)))
  );
};

export const queryVideosWithLocaleContext = async (
  locale: Locale,
  context: VideoQueryContext
) => {
  const query = buildVideoQuery(locale, context);
  logger.debug(
    '[Debug]queryVideosWithLocaleContext query & context',
    query,
    '\n',
    context
  );

  // intentionally hardcoded locale for now https://github.com/wordquest/wordquest/issues/408
  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Video][Locale.EN],
    query
  ).then((res) => res.hits.hits.map(docAsVideo));
};

export const queryVideoSubtitlesWithLocaleContext = async (
  locale: Locale,
  context: VideoQueryContext
) => {
  const query = buildVideoQuery(
    locale,
    _.merge(context, {
      fields: {
        isWithLocales: true
      }
    })
  );
  logger.debug(
    '[Debug]queryVideoSubtitlesWithLocaleContext query & context',
    query,
    context
  );

  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Video][locale],
    query
  ).then((res) => res.hits.hits.map(docAsVideoIdSubtitlesByLocalePair));
};

export const getActionPriority = (actionBy, format = '') => {
  if (actionBy === 'override') {
    return 99;
  }
  if (actionBy === 'transifex') {
    return 10;
  }
  if (actionBy === 'youtube') {
    return 7;
  }
  if (actionBy === 'recognize') {
    return 5;
  }
  if (actionBy === 'youtube-auto' && format === 'srt') {
    // prefer srt back to vtt becoz youtube vtt has <c> which need further conversion
    return 2;
  }
  if (actionBy === 'youtube-auto') {
    return 1;
  }

  return 0;
};

export const mapVideoTitleDescriptionsDataEventsAsOps = (
  events: VideoTitleDescriptionUpsertedDataEvent[]
): object[] => {
  const videoEventsById = _.groupBy(events, (e) => e.properties.video.id);

  return _.flatMap(videoEventsById, (events, id) =>
    events
      .filter((e) => e.properties.description)
      .map((event) => mapVideoTitleDescriptionsDataEventAsOp(event))
  );
};

export const mapVideoSubstitlesDataEventsAsOps = (
  events: VideoSubtitlesUpsertedDataEvent[]
): object[] => {
  const videoEventsById = _.groupBy(events, (e) => e.properties.video.id);

  // either by actionBy or separate each locale 1by1
  // as non-perf critical do 1by1 now
  return _.flatMap(videoEventsById, (events, id) =>
    events.map((event) => mapVideoSubtitlesUpsertedDataEventAsOp(event))
  );
};

export const queryVideosWithIds = async (locale: Locale, ids, context) => {
  const effectiveContext = _.merge(
    {
      filter: {
        includeIds: ids,
        isWithSubtitles: false
      }
    },
    context
  );

  return queryVideosWithLocaleContext(locale, effectiveContext);
};

export const mapVideoDataEventAsDoc = (
  event: VideoUpsertedDataEvent
): VideoEsDoc => {
  // ignore the video descriptionByLocale / titleByLocale for nwo
  const { video } = event.properties;

  return mapVideoAsDoc(video);
};

// TODO default indexedAt with op
export const updateVideoByLocale =
  (locale: Locale) => (events: VideoUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.video.id,
          doc: mapVideoDataEventAsDoc(event)
        }),
        {
          timeout: '4m'
        }
      )
    );

export const indexVideoByLocale =
  (locale: Locale) => (events: VideoUpsertedDataEvent[]) =>
    indexDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map((e) =>
        _.merge(mapVideoDataEventAsDoc(e), { indexedAt: new Date() })
      ),
      {
        timeout: '4m'
      }
    );

export const upsertVideoTitleDescriptionWithLocale =
  (locale: Locale) => (events: VideoTitleDescriptionUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[Locale.EN],
      mapVideoTitleDescriptionsDataEventsAsOps(events),
      {
        timeout: '4m'
      }
    );

// override locale as we stick with EN index now issue#408
export const upsertVideoSubtitlesWithLocale =
  (locale: Locale) => (events: VideoSubtitlesUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[Locale.EN],
      mapVideoSubstitlesDataEventsAsOps(events),
      {
        timeout: '4m'
      }
    );

export const upsertVideoAnalyzedSubtitlesWithLocale =
  (locale: Locale) => (events: VideoSubtitlesAnalyzedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[Locale.EN],
      events.map(mapVideoSubtitlesAnalyzedDataEventAsOp),
      {
        timeout: '4m'
      }
    );

export const asVideoUpsertedDataEvent = (video) =>
  createDataEvent<VideoUpsertedDataEvent>({
    entity: Entity.Video,
    action: DataAction.Upserted,
    properties: {
      id: video.id,
      video
    }
  });

export const updateAtESByLocale = (locale: Locale) => (videoStream) =>
  videoStream.pipe(
    map(asVideoUpsertedDataEvent),
    bufferCount(10),
    flatMap((events) => updateVideoByLocale(locale)(events))
  );

//
// \
// export const createFunctionContextsWithTopic = tabQueryContext => [
//   createContextFunctionWithTerms('video.title', tabQueryContext.termsTopic, 20),
//   createContextFunctionWithTerms('video.description', tabQueryContext.termsTopic, 10),
//   createContextFunctionWithTerms(`${VIDEO_YT_TAGS_KEY}.keyword`, tabQueryContext.ytTagsTopic, 3)
// ];

export const createVideoMultiMatchWithTerms = (
  tabQueryContext,
  activeLocales
) => {
  // zh-TW should hv more boost then zh-CN
  const effectiveLocalesBoost = createPotentialYtDefaultLocales(
    _.intersection(activeLocales, tabQueryContext.localesBoost || [])
  ).filter(Boolean);
  const multiMatches = _.flatMap(
    [
      [tabQueryContext.terms, 40.0],
      [tabQueryContext.termsCommon, 3.0],
      [tabQueryContext.termsTab, 50.0]
    ],
    ([terms, boost]) =>
      _.map(terms, (term) => ({
        query: term,
        type: 'phrase',
        fields: ['video.title^5', 'video.description'],
        boost
      }))
  )
    .concat(
      _.flatMap(
        [
          [tabQueryContext.ytTags, 10.0],
          [tabQueryContext.ytTagsCommon, 1.0]
        ],
        ([terms, boost]) =>
          _.map(terms, (term) => ({
            query: term,
            type: 'phrase',
            fields: [`${VIDEO_YT_TAGS_KEY}.keyword`],
            boost
          }))
      )
    )
    .concat(
      _.flatMap(effectiveLocalesBoost, (term) => ({
        query: term,
        type: 'phrase',
        fields: [
          'video.language',
          VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY,
          VIDEO_YT_DEFAULT_LOCALE_KEY
        ],
        boost: 20.0
      }))
    );

  return {
    multiMatches
  };
};
