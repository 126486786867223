import { loadStripe } from '@stripe/stripe-js';
import logger from '@wordquest/lib-iso/app/logger';
import { lazyLoadAsyncFactory } from '@wordquest/lib-iso/adapters/lazy-load';

const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_IS_TEST_MODE === 'true'
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE ??
      'pk_live_1zsE7t1hkcoP9U94bjXujlID00HNsRpXEX';

const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE ??
  'pk_live_1zsE7t1hkcoP9U94bjXujlID00HNsRpXEX';

const REACT_APP_CREATE_STRIPE_SESSION_ENDPOINT =
  process.env.REACT_APP_CREATE_STRIPE_SESSION_ENDPOINT ??
  'https://carbwb72s1.execute-api.ap-southeast-1.amazonaws.com/prd/create-stripe-checkout-session';

const REACT_APP_FORM_SUBMISSION_ENDPOINT =
  process.env.REACT_APP_FORM_SUBMISSION_ENDPOINT ??
  'https://carbwb72s1.execute-api.ap-southeast-1.amazonaws.com/prd/course-form-submission';

export const getStripe = lazyLoadAsyncFactory(async () => {
  logger.debug('init client: stripe');
  if (!STRIPE_PUBLISHABLE_KEY) {
    throw new Error('missing stripe key');
  }

  return loadStripe(STRIPE_PUBLISHABLE_KEY);
});

export type CreateStripeSessionParams = {
  priceId: string;
  customerEmail: string;
  userLocale: string;
  courseKey: string;
  subscriptionTrialDays?: number;
  metadata?: Record<string, unknown>;
};

export type SessionResponse = {
  payload: CreateStripeSessionParams;
  sessionId: string;
  userId: string;
};

export const createStripeSession = async ({
  priceId,
  customerEmail,
  courseKey,
  metadata,
  subscriptionTrialDays,
  userLocale = 'auto'
}: CreateStripeSessionParams): SessionResponse => {
  const bodyJSON = {
    priceId,
    subscriptionTrialDays,
    customerEmail,
    userLocale,
    courseKey,
    metadata,
    referral: (window as any).Rewardful?.referral
  };

  const endpoint = REACT_APP_CREATE_STRIPE_SESSION_ENDPOINT;
  logger.debug('Stripe back-end request endpoint', endpoint);

  const data = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(bodyJSON)
  }).then((res) => res.json());

  logger.debug('Stripe back-end response data', data);

  return data;
};

export const createCheckoutSession = async ({
  priceId,
  customerEmail,
  courseKey,
  metadata,
  subscriptionTrialDays,
  userLocale = 'auto'
}: CreateStripeSessionParams) => {
  const stripe = await getStripe();

  logger.debug(
    `createAndRedirectCheckoutSession: priceId ${priceId}, customerEmail ${customerEmail}, userLocale ${userLocale}`
  );

  const sessionResponse = await createStripeSession({
    priceId,
    customerEmail,
    courseKey,
    metadata,
    subscriptionTrialDays,
    userLocale
  });

  logger.debug('sessionResponse', sessionResponse);

  return sessionResponse;
};

export const redirectToStripeCheckout =
  (stripeClient) => async (sessionId: string) => {
    const response = await stripeClient.redirectToCheckout({
      sessionId
    });
  };

export const redirectToCheckout = async (sessionId: string) => {
  const stripeClient = await getStripe();

  return redirectToStripeCheckout(stripeClient)(sessionId);
};