import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { furiganaAsRubyTags } from '@wordquest/lib-iso/domain/wordquest/ja-nlp/furigana';

// @deprecated, limited use for jmdict word only but not paragraphs

export const StyledWordWithFurigana = styled.span`
  * ruby {
    font-size: 1.3rem;
  }
  * ruby rt {
    font-size: 1rem;
  }
`;

const WordWithFurigana = (props) => {
  const { word } = props;

  return (
    <StyledWordWithFurigana>
      {_.get(word, 'langMeta.ja.furigana') ? (
        <span
          dangerouslySetInnerHTML={{
            __html: furiganaAsRubyTags(word.text, word.langMeta.ja.furigana)
          }}
        />
      ) : (
        <span>{word.text}</span>
      )}
    </StyledWordWithFurigana>
  );
};

export default WordWithFurigana;
