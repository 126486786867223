/* Simple interface for api endpoints typesafety
 * Similar to Firebase's https callable, but less dependency
 * Defined here to avoid circular dependency
 */
import { Locale } from '@wordquest/locales';
import {
  FIREBASE_FUNCTIONS_REGION,
  FIREBASE_CONFIG_PRD
} from '~/adapters/firebase';
import { UserAction } from '~/endpoints/https/handle-user-action-requested';
// import { LineMessageEvent } from '@wordquest/chat/app/df-fulfillment';

export enum HttpEndpointName {
  TextAnalyze = 'textAnalyze',
  PaperformSubmitted = 'paperformSubmitted',
  DialogflowIntegration = 'dialogflowIntegration',
  DialogflowFulfillment = 'dialogflowFulfillment',
  VideosFetch = 'videosFetch',
  PubsubPush = 'pubsubPush',
  CourseCfUpdated = 'courseCfUpdated',
  CustomerIOSearch = 'customerIOSearch',
  UserActionRequested = 'userActionRequested',
  CfUpdated = 'cfUpdated'
}

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST'
}

export type HttpEndpoint<
  N extends HttpEndpointName,
  M extends HttpMethod,
  T
> = {
  name: N;
  method: M;
  body: T;
};

export type FFHttpRequest<
  E extends HttpEndpoint<HttpEndpointName, HttpMethod, object>
> = {
  body: E['body'];
};

export type FFHttpResponse = object;

export type TextAnalyzeGET = HttpEndpoint<
  HttpEndpointName.TextAnalyze,
  HttpMethod.GET,
  {}
>;

export type TextAnalyzePOST = HttpEndpoint<
  HttpEndpointName.TextAnalyze,
  HttpMethod.POST,
  {
    locale: Locale;
  }
>;

// TODO future protect with token
export type VideosFetchPOST = HttpEndpoint<
  HttpEndpointName.VideosFetch,
  HttpMethod.POST,
  {
    url: URL;
  }
>;

export type PubsubPushPOST = HttpEndpoint<
  HttpEndpointName.PubsubPush,
  HttpMethod.POST,
  {
    topicName: string;
    event: Object;
    key?: string;
  }
>;

export type PaperformSubmittedPOST = HttpEndpoint<
  HttpEndpointName.PaperformSubmitted,
  HttpMethod.POST,
  {
    data: Object;
  }
>;

export type CustomerIOSearchPOST = HttpEndpoint<
  HttpEndpointName.CustomerIOSearch,
  HttpMethod.POST,
  {}
>;

export type DialogflowIntegrationPOST = HttpEndpoint<
  HttpEndpointName.DialogflowIntegration,
  HttpMethod.POST,
  {
    // LineMessageEvent
    events: any[];
  }
>;

export type UserActionRequestedPOST = HttpEndpoint<
  HttpEndpointName.UserActionRequested,
  HttpMethod.POST,
  {
    action: UserAction;
    user: Object;
    env?: string;
    continueUrl?: string;
  }
>;

// TODO future protect with token
export type CourseCfUpdatedPOST = HttpEndpoint<
  HttpEndpointName.CourseCfUpdated,
  HttpMethod.POST,
  {}
>;

export type CfUpdatedPOST = HttpEndpoint<
  HttpEndpointName.CfUpdated,
  HttpMethod.POST,
  {}
>;

// always use PRD now
export const getHttpEndpoint = (endpointName: HttpEndpointName) =>
  `https://${FIREBASE_FUNCTIONS_REGION}-${FIREBASE_CONFIG_PRD.projectId}.cloudfunctions.net/${endpointName}`;

export function createFetchParams<
  E extends HttpEndpoint<HttpEndpointName, HttpMethod, object>
>(
  endpointName: HttpEndpointName,
  body?: E['body']
): [
  string,
  {
    method: HttpMethod;
    body?: E['body'];
    headers: object;
  }
] {
  const method = body ? HttpMethod.POST : HttpMethod.GET;
  const url = getHttpEndpoint(endpointName);

  return [
    url,
    {
      method,
      body: JSON.stringify(body),
      headers: new Headers({
        // headers here impact CORS
        'Content-Type': 'application/json'
      })
    }
  ];
}
