import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import CreateIcon from '@material-ui/icons/Create';
import HearingIcon from '@material-ui/icons/Hearing';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AuthorTag from '~/author/author-tag';
// import { withRouterLink } from '~/with-link';
// hardcode anyway as we need the icon

const StyledInfographicBar = styled.div`
  overflow-x: hidden;
  max-width: 100%;
  .MuiChip-root {
    margin-top: 0.2rem;
    margin-down: 0.2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;
const renderIconByCourseType = (courseType: string = '') => {
  if (courseType.match(/speak/)) {
    return <RecordVoiceOverIcon />;
  }
  if (courseType.match(/write/)) {
    return <CreateIcon />;
  }
  if (courseType.match(/listen/)) {
    return <HearingIcon />;
  }
  if (courseType.match(/read/)) {
    return <VisibilityIcon />;
  }

  return <></>;
};
// TODO handle unknown types
// withLink={props => withRouterLink({ href: `/author/${author.semanticKey}` })}
export default ({ teachers = [], teacherAssistants = [], courseTypes }) => (
  <StyledInfographicBar>
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      {teachers.map(
        (teacher) =>
          teacher && (
            <AuthorTag
              key={teacher.semanticKey}
              author={teacher}
              label={
                <FormattedMessage id="common.course.teacher.description" />
              }
            />
          )
      )}
      {teacherAssistants.map(
        (teacherAssistant) =>
          teacherAssistant && (
            <AuthorTag
              key={teacherAssistant.semanticKey}
              author={teacherAssistant}
              label={
                <FormattedMessage id="common.course.teacher.assistant.description" />
              }
            />
          )
      )}
      {courseTypes.map((courseType) => (
        <Chip
          key={`course-type${courseType}`}
          color="secondary"
          label={<FormattedMessage id={`common.course.type.${courseType}`} />}
          icon={renderIconByCourseType(courseType)}
        />
      ))}
    </Box>
  </StyledInfographicBar>
);
