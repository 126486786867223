// Simplified version of Quote with no dependency on videos.
// potentially to share inner components
// probably not a card in future so exclude from naming first
import _ from 'lodash';
import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import AuthorTag from '~/author/author-tag';
import Link from 'next/link';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SITE_ROOT_UI_SITE } from '@wordquest/lib-iso/env';
import { formatWithLocale } from '@wordquest/lib-iso/intl';
import { formatSeconds } from '@wordquest/lib-iso/utils';
import RichText from '~/text/rich-text';
import { RichTextContainer } from '~/text/rich-text-container';
import { withRouterLink } from '~/with-link';
import Chip from '@material-ui/core/Chip';

const StyledExplanations = styled.div`
  .MuiChip-root {
    margin-bottom: 1.5rem;
  }
  padding-bottom: 1rem;
`;

const StyledAuthorNote = styled.div`
  > div {
    padding: 1rem;
  }
`;

export default ({
  author,
  isActive = false,
  renderData,
  // until we got all translations in subtitles
  isShowAuthor = true,
  descriptionRichText,
  withLink = withRouterLink
}) => (
  <StyledAuthorNote
    border={1}
    borderRadius={20}
    borderColor={isActive ? '#ffce2b' : '#cccccc'}
  >
    <Card>
      <StyledExplanations>
        {isShowAuthor && author && author.semanticKey && (
          <div>
            <AuthorTag
              withLink={(props) =>
                withLink({
                  href: `/author/${props.authorKey}`
                })
              }
              label={<FormattedMessage id="common.quotes.explanations" />}
              key={author.semanticKey}
              author={author}
            />
          </div>
        )}
        {descriptionRichText && (
          <div>
            <Typography variant="body1" component="div">
              {!_.isString(descriptionRichText) ? (
                <RichTextContainer
                  wqDom={descriptionRichText}
                  renderData={renderData}
                >
                  <RichText />
                </RichTextContainer>
              ) : (
                descriptionRichText
              )}
            </Typography>
          </div>
        )}
      </StyledExplanations>
    </Card>
  </StyledAuthorNote>
);
