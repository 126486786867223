// without async import() for simplictiy
export function lazyLoadFactory<T>(initializeClient) {
  // closure proetection
  let client = null;
  let lastArgs;

  // use sync & do not await to simplify
  return function getClient(args = undefined): T {
    if (client === null || lastArgs !== args) {
      client = initializeClient(args);
      lastArgs = args;
    }

    return client;
  };
}

export function lazyLoadAsyncFactory<T>(initializeClient, salt) {
  return lazyLoadFactory<T>(async (args) => initializeClient(args));
}
