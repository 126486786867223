import md5 from 'md5';
import _ from 'lodash';

import {
  createStoragePublicUrl,
  StorageProvider
} from '~/adapters/storage-url';

export const AUDIOGRAM_GCS_BUCKET = 'wordquest-app-hk-transloadit';

export const AUDIOGRAM_FILEPATH_ROOT = 'audiogram/';

export const getAudiogramFilename = (key, text) => [key, md5(text)].join('-');

// TODO migrate createGcsPublicUrl inside lib-iso over lib-node

export const getAudiogramUrlGcs = (fileName, ext = 'mp4') =>
  createStoragePublicUrl({
    bucketName: AUDIOGRAM_GCS_BUCKET,
    filePath: `${AUDIOGRAM_FILEPATH_ROOT}${fileName}.${ext}`,
    provider: StorageProvider.GCS
  });

export const getAudiogramUrlS3 = (fileName, ext = 'mp4') =>
  createStoragePublicUrl({
    bucketName: 'wordquest-media',
    filePath: `public/${AUDIOGRAM_FILEPATH_ROOT}${fileName}.${ext}`,
    provider: StorageProvider.S3
  });

export const getAudiogramUrl = getAudiogramUrlGcs;
