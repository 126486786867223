// TODO share
import { isNotEmptyArray } from '~/utils';
import _ from 'lodash';
import bodybuilder from 'bodybuilder';

export type BaseQueryContext = {
  filter?: {
    includeIds?: string[];
    excludeIds?: string[];
  };
  paging?: {
    page?: number;
    size?: number;
  };
  terms?: object;
};

// separated function for easier compositions

export const createMatchPhraseFilter = (
  _body,
  context,
  filterMethod = 'filter'
) => {
  let body = _body;
  _.mapValues(context[filterMethod], (filterValue, filterKey) => {
    const filters =
      _.isArray(filterValue) && !_.isEmpty(filterValue)
        ? filterValue
        : [filterValue];

    if (['includeIds', 'excludeIds'].includes(filterKey)) {
      return;
    }
    _.forEach(filters, (filter) => {
      body = body[filterMethod]('match_phrase', filterKey, filter);
    });
  });

  return body;
};

export const withNotFilter = (context: BaseQueryContext) => (_body) =>
  createMatchPhraseFilter(_body, context, 'notFilter');

export const withMustFilter = (context: BaseQueryContext) => (_body) =>
  createMatchPhraseFilter(_body, context, 'filter');

export const withIncludeIdsFilter = (context: BaseQueryContext) => (body) => {
  const includeIds = _.get(context, 'filter.includeIds');
  if (isNotEmptyArray(includeIds)) {
    return body.filter('ids', 'values', includeIds.filter(Boolean));
  }

  return body;
};

export const withExcludeIdsFilter = (context: BaseQueryContext) => (body) => {
  const excludeIds = _.get(context, 'filter.excludeIds');
  if (isNotEmptyArray(excludeIds)) {
    return body.notFilter('ids', 'values', excludeIds);
  }

  return body;
};

export const withSort = (context: BaseQueryContext) => (body) => {
  const sort = _.get(context, 'sort');
  if (isNotEmptyArray(sort)) {
    return body.sort(sort);
  }

  return body;
};

export const createMultiMatchesQuery = (multiMatches) => {
  let body = bodybuilder();
  _.forEach(multiMatches, (multiMatch) => {
    body = body.orQuery('multi_match', multiMatch);
  });

  return body.build();
};

// for functions
export const createShouldFilter = (
  termByKey = {},
  filter = 'should',
  isIncludeNotExists = false
) => {
  let body = bodybuilder();
  _.forEach(termByKey, (_term, key) => {
    const terms = _.isArray(_term) ? _term : [_term];
    terms.filter(Boolean).forEach((term) => {
      if (filter === 'should') {
        // using match_phrase = can't match like "Japan's"
        // TODO do only when CJK

        // term not working, no .keyword
        // keyword analyzer / cjk won't work too as field analyzed already
        // at query time use analyzer for CJK
        // https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-keyword-analyzer.html
        // https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-term-query.html
        // To search text field values, use the match query
        body = body.orFilter('match_phrase', key, {
          query: term
        });
      } else if (filter === 'must_not') {
        body = body.notFilter('match_phrase', key, {
          query: term
        });
      }
    });
    if (isIncludeNotExists) {
      body = body.orFilter('bool', 'must_not', {
        exists: {
          field: key
        }
      });
    }
  });

  const query = body.build();

  // case for empty
  return _.get(query, 'query.bool') || query;
};

// orFilter
export const withTermsFilter = (context: BaseQueryContext) => (_body) => {
  const terms = _.get(context, 'terms') || [];
  let body = _body;
  _.forEach(terms, (items, termKey) => {
    if (isNotEmptyArray(items)) {
      _.forEach(items, (item) => {
        body = body.orFilter('match', termKey, {
          query: item,
          boost: 3.0
        });
      });
    }
  });

  return body;
};

export const withPaging = (context: BaseQueryContext) => (_body) => {
  let body = _body;
  const _pagingSize = _.get(context, 'paging.size');
  // for case of 0
  const pagingSize = _.isNumber(_pagingSize) ? _pagingSize : 50;
  const page = _.get(context, 'paging.page');
  if (_.isNumber(page)) {
    body = body.from(page * pagingSize);
  }
  body = body.size(pagingSize);

  return body;
};

export const createMockHitsResponse = (hits) => ({
  took: 0,
  timed_out: false,
  hits: {
    total: 1,
    max_score: 0,
    hits
  }
});

// TODO test createContextFunctionWithTerms
export const createContextFunctionWithTerms = (
  key,
  _terms = [],
  weight = 1,
  isNegative = false,
  isIncludeNotExists = false
) => {
  const terms = _terms.filter(Boolean);
  if (_.isEmpty(terms)) {
    return;
  }
  const shouldFilter = createShouldFilter(
    {
      [key]: terms
    },
    isNegative ? 'must_not' : 'should',
    isIncludeNotExists
  );

  // don't merge into null / empty, ages to find this bug
  if (!shouldFilter) {
    return;
  }

  return _.merge(shouldFilter, {
    weight
  });
};

function customizer(objValue, srcValue) {
  if (!objValue) {
    return srcValue;
  }
  const value = _.isArray(objValue) ? objValue : [objValue];

  return _.uniq(value.concat(srcValue));
}

export const concatQueryFilterObject = (baseFilter, filter) =>
  _.mergeWith({}, baseFilter, filter, customizer);
