import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import TopBar from '~/layout/top-bar';
import { withRouterLink } from './with-link';
import AuthAvatarContainer from './auth-avatar-container';

const NavbarContainer = (props) => {
  // TODO injected userd
  const { classes, rootStore, history, depth } = props;
  const { uiConfig, app, sidebar } = rootStore.uiStateStore;
  // TODO observe
  const createLoginAvatar = (children) => (
    <AuthAvatarContainer>{children}</AuthAvatarContainer>
  );

  // TODO responsive already, refactor
  return uiConfig.isNavbarShown ? (
    <TopBar
      isSidebarOpen={app.isSidebarOpen}
      isSidebarShown={rootStore.uiStateStore.isSidebarAvailable}
      withLink={(props) => withRouterLink({ to: props.href })}
      title="PeraPera Blog"
      createLoginAvatar={createLoginAvatar}
    />
  ) : null;
};

export default inject('rootStore')(observer(NavbarContainer));
