import _ from 'lodash';
import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AuthorTag from '~/author/author-tag';
import Link from 'next/link';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SITE_ROOT_UI_SITE } from '@wordquest/lib-iso/env';
import { formatWithLocale } from '@wordquest/lib-iso/intl';
import { formatSeconds } from '@wordquest/lib-iso/utils';
import { RichTextContainer } from '~/text/rich-text-container';
import { withRouterLink } from '~/with-link';
import Chip from '@material-ui/core/Chip';
import { CueAnchor } from '~/video/cue-anchor';
import { Locale, MessagesModule } from '@wordquest/locales';
import RichText, { DEFAULT_VIDEO_STYLE } from './rich-text';
import { StyledFlippedIcon } from '~/util/icon';
import { mediaMui } from '~/style-utils';

const StyledExplanations = styled.div`
  .MuiChip-root {
    margin-bottom: 1.5rem;
  }
  padding-bottom: 1rem;
`;

const StyledQuoteBox = styled(Box)`
  .MuiCard-root {
    border-radius: 20px;
    padding: 0.8rem;
    ${mediaMui.smUp`
      padding: 1.5rem;

    `}
  }
  .MuiBox-root .MuiSvgIcon-root {
    margin-top: 0.2rem;
  }
`;

const StyledQuoteMeta = styled.div`
  padding-bottom: 1.5rem;
`;

const StyledQuote = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const StyledBlockQuote = styled.div`
  padding-bottom: 2rem;
  blockquote {
    text-align: left;
    background: #f9f9f9;
    border-left: 10px solid #113284;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #113284;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
`;

export const getQuoteI18nDetails = (
  originalLocale = Locale.EN,
  targetLocale,
  tagKey,
  count
) => {
  const language = formatWithLocale(targetLocale)(
    `common.language.${originalLocale}`
  );
  const figureTitle = formatWithLocale(targetLocale)(`tag.${tagKey}`);
  const title = formatWithLocale(targetLocale)('common.quotes.title', {
    figure: figureTitle,
    count,
    language
  });

  return {
    figureTitle,
    language,
    title
  };
};

const createQuoteUrl = (video, figureTagKey) =>
  `${SITE_ROOT_UI_SITE}/watch/${video.id}?startSeconds=${video.startSeconds}&endSeconds=${video.endSeconds}&topic=quotes&tab=${figureTagKey}`;

export default ({
  video = {},
  isActive = false,
  // until we got all translations in subtitles
  isShowOriginal = true,
  isBlockQuote = false,
  playbackProps,
  currentAudioKey,
  currentAudioPlaying,
  // TODO
  // isShowSpeaker = false,
  isShowAuthor = true,
  isShowTimestamp = true,
  isShowCueAnchor = true,
  isShowWatchAnchor = false,
  original,
  translation,
  speakerTagKey,
  explanationRichText,
  author,
  withLink = withRouterLink,
  onQuoteClick = _.noop
}) => {
  _.defaultsDeep(video, {
    startSeconds: video.startSeconds || 0,
    endSeconds: video.endSeconds || 0
  });

  const QuoteContainer = isBlockQuote ? StyledBlockQuote : StyledQuote;

  return (
    <StyledQuoteBox
      border={1}
      borderRadius={20}
      borderColor={isActive ? '#ffce2b' : '#cccccc'}
    >
      <Card>
        <StyledQuoteMeta>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {isShowTimestamp && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {isShowCueAnchor && (
                    <StyledFlippedIcon>
                      <CueAnchor
                        href="javascript:void(0)"
                        onClick={(props) => {
                          onQuoteClick(props);
                        }}
                      />
                    </StyledFlippedIcon>
                  )}
                  <Chip
                    label={
                      <div>
                        {formatSeconds(video.startSeconds)}-
                        {formatSeconds(video.endSeconds)}
                      </div>
                    }
                    color="primary"
                  />
                </Box>
              )}
            </Box>
            <Box>
              {isShowAuthor && author.semanticKey && (
                <div>
                  <AuthorTag
                    withLink={(props) =>
                      withLink({
                        href: `/author/${props.authorKey}`
                      })
                    }
                    label={<FormattedMessage id="common.quotes.explanations" />}
                    key={author.semanticKey}
                    author={author}
                  />
                </div>
              )}
            </Box>
          </Box>
        </StyledQuoteMeta>
        {isShowOriginal && (
          <QuoteContainer>
            <Typography variant="h6" component="p">
              {original}
            </Typography>
            {translation && (
              <Typography variant="subtitle1" component="p">
                {translation}
              </Typography>
            )}
          </QuoteContainer>
        )}
        {isShowWatchAnchor && video.id && (
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h5" component="h4">
              <a href={createQuoteUrl(video, speakerTagKey)}>
                ➡️
                <FormattedMessage id="quote.video.view" />
              </a>
            </Typography>
          </Box>
        )}
        <StyledExplanations>
          {explanationRichText && (
            <div>
              <Typography variant="body1" component="div">
                {!_.isString(explanationRichText) ? (
                  <RichTextContainer
                    wqDom={explanationRichText}
                    renderData={{
                      playbackProps,
                      currentAudioKey,
                      currentAudioPlaying,
                      videoStyle: DEFAULT_VIDEO_STYLE
                    }}
                  >
                    <RichText />
                  </RichTextContainer>
                ) : (
                  explanationRichText
                )}
              </Typography>
            </div>
          )}
        </StyledExplanations>
      </Card>
    </StyledQuoteBox>
  );
};
