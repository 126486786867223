// Reference
// https://github.com/webcaetano/shuffle-seed/blob/master/shuffle-seed.js

import _ from 'lodash';
import seedrandom from 'seedrandom';

export const seedRand = function (func, min, max) {
  return Math.floor(func() * (max - min + 1)) + min;
};

export const shuffleWithSeed = (arr: any[], seed: any) => {
  if (!_.isArray(arr)) return null;

  const size = arr.length;
  const rng = seedrandom(seed);
  const resp = [];
  const keys = [];

  for (let i = 0; i < size; i += 1) keys.push(i);
  for (let i = 0; i < size; i += 1) {
    const r = seedRand(rng, 0, keys.length - 1);
    const g = keys[r];
    keys.splice(r, 1);
    resp.push(arr[g]);
  }

  return resp;
};
