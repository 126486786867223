import _ from 'lodash';
import { JLPT_SCALE } from '~/domain/wordquest/ql/difficulty-scale';
import { furiganaIndexPair } from '../jmdict';
// instead of subkey ja, use diff class for diff lang?
export type LangMeta = {
  ja: {
    furigana?: furiganaIndexPair[];
    google?: {
      syntax: any;
    };
  };
  source: {
    jmdict: number;
    wiktionary: number;
  };
};

// TODO consider id as getter, temp id for non-firestore
// only if we got non-dict words
// For simplicity now put translations here directly
export default class Word {
  constructor(
    public text: string,
    public langMeta?: LangMeta,
    tags: any[] = [],
    id?: string
  ) {
    // the text is picked as entity that is easily conceptualize.
    // thus used as: semantic urls
    // for example in japanese, kanji 愛 is used instead of kana あい which is used as furigana
    // also suppose to use the more common form in case furigana/katakara is more common than kanji,
    // in which case kanji will be dropped
    // work quite similarly as the auto-redirects in wikipedia "Iron Man" -> アイアンマン entry
    // furigana is separated with an index approach(instead of duplicating),
    // to ease client-side rendering
    this.text = text;
    this.langMeta = langMeta || { ja: { google: { syntax: null } } };
    this.tags = tags;
    // this.defaultDefinitionByLocale
    // id is our application id
    // Not reallt dynamic
    this.id = id || this.text;
  }

  static create(obj: object) {
    const { text, langMeta, tags, id } = obj;

    return new Word(text, langMeta, tags, id);
  }

  // words can be in multiple levels. always take the lower.
  // now always use the wiktionary source
  // not modelling as difficultyTag (jlpt-n3) as it is user-facing
  getJlptLevel(): string {
    const tag = _.first(
      _.orderBy(
        this.tags.filter((t) => _.startsWith(t, 'wiktionary-en-jlpt-')),
        _.identity,
        ['desc']
      )
    );
    if (tag) {
      return tag.replace(/wiktionary-en-jlpt-/, '');
    }

    return 'unknown';
  }

  getDifficultyLevel(): number {
    const jlptLevel = this.getJlptLevel();
    if (jlptLevel) {
      return JLPT_SCALE[jlptLevel];
    }

    return 0;
  }

  getSourceDictKey(source: string) {
    const id = this.langMeta.source[source];

    return `source-${source}-${id}`;
  }

  // for edu purpose, drop uncommon stuf
  // took the first kanji, sorted by 'common=true'
  // took the first gana, client side can use as furigana if appliesToKanji includes that
  // a furigana index
  // alternative forms are useful for search indexing
  text: string;

  langMeta: LangMeta;

  tags: any[];

  id: string;
}
