// consider refactor with wq-ui-main/src/stores/domain/user.js
// but orders matter
import _ from 'lodash';
import { createCourseLessonsStatuses } from '~/domain/wordquest/course/course-user-status';
import { Entity, QuestAction, ProfileAction } from '~/domain/wordquest/entity';
import {
  createEntityAggregate,
  createEntityAggregateByKey
} from '~/domain/wordquest/event/aggregate';
import logger from '~/app/logger';

// might not be authorized e.g. trial

// depends on current lesson, regardless of progress
export const getNextLessonKey = (course, currentLessonKey) => {
  if (!course) {
    return;
  }
  const lessons = _.flatMap(
    course.courseSections,
    (section) => section.lessons
  );
  const nextIndex = _.findIndex(lessons, (l) => l.key === currentLessonKey) + 1;
  if (nextIndex > -1 && nextIndex < lessons.length) {
    return lessons[nextIndex].key;
  }
};

// next of last unlocked + incomplete
// c.f. createCourseAttributeByKey
// pre-filter the events
export const analyzeCourseProgress = ({
  course,
  userAuthorizedRoles,
  events = []
}: {
  course: Course;
}) => {
  // userAuthorizedRoles
  const courseLessonStatuses = createCourseLessonsStatuses({
    course,
    userAuthorizedRoles,
    currentLessonKey: null,
    checkedByKey: {},
    isAlwaysVisible: false
  });

  const questUserEventAggregateByKey = new Map(
    Object.entries(createEntityAggregate(Entity.Quest)(events))
  );
  const agg = questUserEventAggregateByKey.get(QuestAction.Completed);

  const completedByLessonKey = _.fromPairs(
    _.map(agg, (v, k) => [v.quest.lessonKey, v.quest.type === 'lesson'])
  );

  // now, ensure it is not course status. remove once all are lesson basesd
  // alternative algo for lastCompletedIndex
  const lastCompletedIndex = _.findLastIndex(
    courseLessonStatuses,
    (status) => completedByLessonKey[status.key]
  );

  const firstIncompletedIndex = _.findIndex(
    courseLessonStatuses,
    (status) => !completedByLessonKey[status.key]
  );

  logger.debug(
    'analyzeCourseProgress firstIncompletedIndex',
    firstIncompletedIndex
  );
  logger.trace(course, courseLessonStatuses);
  let nextLessonKey = null;
  let nextLessonTitle = null;
  if (firstIncompletedIndex > -1) {
    nextLessonKey = _.get(courseLessonStatuses, `${firstIncompletedIndex}.key`);
    nextLessonTitle = _.get(
      courseLessonStatuses,
      `${firstIncompletedIndex}.title`
    );
  }

  // assume completed if nextLessonKey = null
  return {
    courseKey: course.key,
    nextLessonKey,
    nextLessonTitle,
    completedByLessonKey
  };
};
