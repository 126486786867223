// deprecated. use SwipeableCardsContainer directly so as to support different cards
import React from 'react';
import _ from 'lodash';
import videos from '@wordquest/lib-iso/domain/wordquest/video/video.fixture.js';
import Grid from '@material-ui/core/Grid';
import VideoCard from './video-card';
import SwipeableCardsContainer from '~/swipeable-cards-container';

const VideoCardCarrousel: React.FC<{
  videos: any[];
  withLink: () => any;
  isShownNavButtons: boolean;
  maxTagCount: number;
  isShowDescription: boolean;
}> = (props: object) => {
  const {
    classes,
    videos,
    allVideoTags,
    maxSlidesPerView,
    withLink,
    isShownNavButtons,
    maxTagCount,
    isShowDescription
  } = props;
  if (!_.isArray(videos)) {
    return null;
  }
  const slides = videos.map((video) => (
    <Grid item key={video.id}>
      <VideoCard
        withLink={withLink}
        key={video.id}
        video={video}
        allVideoTags={allVideoTags}
        maxTagCount={maxTagCount}
        isShowDescription={isShowDescription}
      />
    </Grid>
  ));

  return (
    <SwipeableCardsContainer
      isShownNavButtons={isShownNavButtons}
      spaceBetween={0}
      centeredSlides={false}
      maxSlidesPerView={maxSlidesPerView}
    >
      {slides}
    </SwipeableCardsContainer>
  );
};

export default VideoCardCarrousel;
