// Why ES vs contentful directly?
// client library
// slow cdn?
// contentful delivery api  could beasier
// contenful cache diff feature?
// more for analysis, quest generations
import _ from 'lodash';
import bodybuilder from 'bodybuilder';
import { format, parseISO, isValid } from 'date-fns';
import Course from '~/domain/wordquest/course/course';
import { Lesson } from '~/domain/wordquest/course/lesson';
import CourseIntro from '~/domain/wordquest/course/course-intro';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity, DataAction } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withPaging,
  withNotFilter,
  withMustFilter,
  withSort,
  withExcludeIdsFilter,
  withIncludeIdsFilter,
  withTermsFilter
} from '~/app/repo-es-util';
import rootLogger from '~/app/logger';
import { asValidISODate, parseIfStringify } from '~/utils';

import {
  createDataEvent,
  CourseIntroUpsertedDataEvent,
  CourseUpsertedDataEvent,
  LessonUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';

export const createCoursePath = (courseKey) => `/course/${courseKey}`;

export const createCourseRoot = (isPreview, courseKey) => {
  const url = `${createCoursePath(courseKey)}/lesson/`;

  return (isPreview ? '/preview' : '') + url;
};

const logger = rootLogger.child({ module: 'course-repo' });
// const DEFAULT_SOURCE_FIELDS = ['materials', 'indexedAt'];
const DEFAULT_SOURCE_FIELDS = [];
export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Course];

export const ES_MAPPING_COURSE_BY_LOCALE = _.fromPairs(
  SUPPORTED_LOCALES.map((sourceLocale) => [
    sourceLocale,
    {
      // store cannot be specificed. in _source anyway
      // https://www.elastic.co/guide/en/elasticsearch/reference/6.3/object.html
      properties: {
        indexedAt: {
          type: 'date'
        },
        course: {
          properties: {
            coursePlans: {
              properties: {
                descriptionRichText: {
                  enabled: false
                }
              }
            },
            courseIntro: {
              properties: {
                sections: {
                  properties: {
                    descriptionRich: {
                      enabled: false
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  ])
);

export type CourseQueryContext = BaseQueryContext & {
  fields?: {
    isWithMaterials: boolean;
  };
  terms?: {};
  filter?: {
    includeIds?: string[];
  };
};

export const buildCourseQuery = (
  locale: Locale,
  _context: CourseQueryContext = {}
): EsQuery => {
  // to override e.g. isHidden
  // ignore instead of show true
  const context = _.defaultsDeep(_context, {
    paging: {
      size: 20
    }
  });
  let body = bodybuilder();

  // TODO better currying
  body = withMustFilter(context)(
    withNotFilter(context)(
      withExcludeIdsFilter(context)(
        withIncludeIdsFilter(context)(
          withTermsFilter(context)(withSort(context)(body))
        )
      )
    )
  );
  const sourceFields = DEFAULT_SOURCE_FIELDS;
  const fields = context.fields || {};
  // if (fields.isWithLocales) {
  //   sourceFields = sourceFields.concat([]);
  // }
  // body = body.rawOption(
  //   '_source', sourceFields
  // );

  let isUseDefaultQuery = _.isEmpty(_.get(context, 'terms'));
  if (!_.isEmpty(_.omit(context.filter, ['includeIds', 'excludeIds']))) {
    isUseDefaultQuery = false;
  }

  if (isUseDefaultQuery) {
    // add here in case of empty or only exists query
    body = body.query('match_all');
  }

  const scriptFieldByKey = {};

  if (!_.isEmpty(scriptFieldByKey)) {
    body = body.rawOption('script_fields', scriptFieldByKey);
  }

  // always with paging to make query structure consistent
  body = withPaging(context)(body);

  return body.build();
};

export const docAsCoursePlan = (docCoursePlan) =>
  _.merge(docCoursePlan, {
    descriptionRichText: parseIfStringify(docCoursePlan.descriptionRichText)
  });

export const docAsCourseSection = (courseSection) => {
  courseSection.lessons = (courseSection.lessons || []).map((lesson) =>
    _.merge(lesson, {
      readyAt: asValidISODate(lesson.readyAt),
      updatedAt: asValidISODate(lesson.updatedAt)
    })
  );

  return courseSection;
};

export const qualifciationWithDate = (q) =>
  _.merge(q, {
    from: _.isString(q.from) ? parseISO(q.from) : undefined,
    to: _.isString(q.to) ? parseISO(q.to) : undefined
  });

export const docAsCourse = ({ _id, _source: { course }, fields }) =>
  Course.create(
    _.merge(course, {
      updatedAt: _.isString(course.updatedAt)
        ? parseISO(course.updatedAt)
        : undefined,
      publishedAt: _.isString(course.publishedAt)
        ? parseISO(course.publishedAt)
        : undefined,
      courseSections: (course.courseSections || []).map(docAsCourseSection),
      coursePlans: (course.coursePlans || []).map(docAsCoursePlan),
      teachers: (course.teachers || []).map((t) =>
        _.merge(t, {
          academicQualifications: (t.academicQualifications || []).map(
            qualifciationWithDate
          ),
          workExperiences: (t.workExperiences || []).map(qualifciationWithDate)
        })
      )
    })
  );

export const queryCoursesWithContexts = async (
  locale: Locale,
  contexts: CourseQueryContext[]
) => {
  const index = ES_INDEX_BY_ENTITY_LOCALE[Entity.Course][locale];
  logger.debug('[Debug]queryCoursesWithContexts query & context', contexts);

  return msearch(
    ES_CONFIG.APP_CONTENT,
    contexts.map((context) => [index, buildCourseQuery(locale, context)])
  ).then((courses) =>
    _.map(courses, (docs) => docs.map((doc) => docAsCourse(doc)))
  );
};

export const queryCoursesWithLocaleContext = async (
  locale: Locale,
  context: CourseQueryContext
) => {
  const query = buildCourseQuery(locale, context);
  logger.debug(
    '[Debug]queryCoursesWithLocaleContext query & context',
    query,
    context
  );

  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Course][locale],
    query
  ).then((res) => res.hits.hits.map(docAsCourse));
};

// always separated requests.
export const queryMaterialsWithLocaleContext = async (
  locale: Locale,
  context: CourseQueryContext
) => {
  const query = buildCourseQuery(locale, context);
  logger.debug(
    '[Debug]queryCoursesWithLocaleContext query & context',
    query,
    context
  );

  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Course][locale],
    query
  ).then((res) => res.hits.hits.map(docAsCourse));
};

export type CourseEsDoc = {
  id: string;
  course: Course;
};

export type LessonEsDoc = {
  courseKey: string;
  lesson: Lesson;
};

export const mapCourseIntroAsDoc = (courseId, _courseIntro: CourseIntro) => {
  const courseIntro = _.toPlainObject(_.omit(_courseIntro, []));

  return {
    id: courseId,
    course: {
      courseIntro: _.merge(courseIntro, {
        sections: courseIntro.sections.map((s) =>
          _.omit(s, ['descriptionRichText'])
        )
      })
    }
  };
};

export const mapCourseAsDoc = (_course: Course): CourseEsDoc => {
  const course = _.toPlainObject(_.omit(_course, ['descriptionRichText']));
  if (course.key.match(/teacher/)) {
    course.tags.push('coach');
  }

  return {
    id: course.id,
    course
  };
};

export const mapLessonAsDoc = (_lesson: Lesson): LessonEsDoc => {
  const lesson = _.toPlainObject(_lesson);

  return {
    // courseKey: lesson.key,
    lesson
  };
};

// re-use course
export const upsertCourseIntrosWithLocale =
  (locale: Locale) => (events: CourseIntroUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.id,
          doc: mapCourseIntroAsDoc(
            event.properties.id,
            event.properties.courseIntro
          )
        }),
        {
          timeout: '4m'
        }
      )
    );

export const upsertCoursesWithLocale =
  (locale: Locale) => (events: CourseUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.course.key,
          doc: mapCourseAsDoc(event.properties.course)
        }),
        {
          timeout: '4m'
        }
      )
    );

// TODO not in use now
export const upsertLessonByLocale =
  (locale: Locale) => (events: LessonUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.video.id,
          doc: mapLessonAsDoc(event.properties.lesson)
        }),
        {
          timeout: '4m'
        }
      )
    );
