/* Used in both client-side & SSR */
import React from 'react';
// import Card, { Paper, CardContent } from '@material-ui/core/Card';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  injectIntl
} from 'react-intl';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer, action, inject } from 'mobx-react';

import WordDeck from '~/word/word-deck';
import VideoCardCarrousel from '~/video/video-card-carrousel';
import ArticleCarrousel from '~/article/article-carrousel';
import WordCardCarrousel from '~/word/word-card-carrousel';

import { withRouterLink } from '~/with-link';

const TextQueryResult = ({
  isShowThumbnail,
  isShowTags,
  withArticleLink = (props) =>
    withRouterLink({
      to: `/post/${props.articleId}`
    }),
  queryText,
  relatedWords,
  relatedArticles,
  relatedVideos,
  definitions,
  classes
}) => (
  <Paper
    elevation={0}
    className={_.has(classes, 'paper') ? classes.paper : null}
  >
    <div>
      {!_.isEmpty(relatedWords) && (
        <>
          <div>
            <h1>
              <FormattedMessage id="landing.related.words" />
            </h1>
          </div>
          {<WordDeck key="word-deck" words={relatedWords} isInSearch />}
        </>
      )}

      <div>
        <h1>
          <FormattedMessage id="landing.related.articles" />
        </h1>
        {
          <ArticleCarrousel
            isShowThumbnail={isShowThumbnail}
            isShowTags={isShowTags}
            withLink={withArticleLink}
            articles={relatedArticles}
            maxSlidesPerView={2.1}
          />
        }
      </div>
      {!_.isEmpty(relatedVideos) && (
        <>
          <div>
            <h1>
              <FormattedMessage id="landing.related.videos" />
            </h1>
            {
              <VideoCardCarrousel
                withLink={(props) =>
                  withRouterLink({
                    to: `/watch/${props.videoId}`
                  })
                }
                videos={relatedVideos}
                maxSlidesPerView={2.1}
              />
            }
          </div>
        </>
      )}
    </div>
  </Paper>
);
export default TextQueryResult;
