import _ from 'lodash';
import React from 'react';
import RichText from '~/text/rich-text';
import { RichTextContainer } from '~/text/rich-text-container';
import Grid from '@material-ui/core/Grid';
import {
  formatWithLocale,
  getMonthDates,
  getWeekdayDates,
  formatDateAsYear,
  formatDateAsWeekday
} from '@wordquest/lib-iso/intl';
import VerifiedBadge from '~/verified-badge';
import { isAfter } from 'date-fns';
import { FormattedMessage } from 'react-intl';

// const fixture = await loadCfQualificationWithId('2y7CrMJdnIXYSwGWpmWJU8');
export const Qualifications = ({ qualifications = [] }) => (
  <div>
    {qualifications.map((qualification) => {
      const { from, to, descriptionRich } = qualification;

      const isPresent = _.isDate(to) && isAfter(to, new Date());

      const futureDate = new Date('2089-12-31');
      const isTimePeriodHidden =
        !_.isDate(to) ||
        !_.isDate(from) ||
        isAfter(to, futureDate) ||
        isAfter(from, futureDate);

      return (
        <div>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs={2}
              spacing={0}
              alignContent="flex-start"
              justify="flex-start"
            >
              {isTimePeriodHidden ? (
                ''
              ) : (
                <div>
                  {_.isDate(from) && formatDateAsYear(from)}
                  {(_.isDate(from) || _.isDate(to)) && ' - '}
                  {isPresent ? (
                    <FormattedMessage id="common.course.teacher.qualification.present" />
                  ) : (
                    _.isDate(to) && formatDateAsYear(to)
                  )}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              spacing={0}
              alignContent="flex-start"
              justify="flex-start"
            >
              <RichTextContainer wqDom={descriptionRich}>
                <RichText
                  renderData={{
                    paragraphProps: {
                      gutterBottom: false
                    }
                  }}
                />
              </RichTextContainer>
            </Grid>
            <Grid item xs={2}>
              {qualification.isVerified ? <VerifiedBadge /> : null}
            </Grid>
          </Grid>
        </div>
      );
    })}
  </div>
);

export default Qualifications;
