import _ from 'lodash';
import { crawlNodes } from './dom-entity-extractor';

// consider extract these to avoid circular deps

export const deserializeHtmlAsWqDom = (markup: string): WqDocument => {
  // DOMParser.
};

// useful for both simple text / backward compatability
export const deserializePlainTextAsWqDom = (
  plainText: string
): WqDocument => {};

// pbm: might not normalized for slate to use
// TODO
export const serializeAsPlainText = (wqDom: WqDocument): string => {
  const texts = [];
  crawlNodes(wqDom, (node) => {
    if (
      _.includes(['blockquote', 'paragraph'], node.type) &&
      !_.isEmpty(texts)
    ) {
      texts.push('\n');
    }
    if (node.text) {
      texts.push(node.text);
    }
  });

  return texts.filter(Boolean).join('');
};

// we either use ReactDOMServer.renderToStaticMarkup (more consistent)
// or use domain logic like this
// https://docs.slatejs.org/concepts/09-serializing
export const serializeAsHtml = (wqDom: WqDocument): string => {};

// export const asSlateDom = (wqDom: WqDocument) => {
//   const editor = createEditor();
//   // https://github.com/ianstormtaylor/slate/issues/3465
//   editor.children.forEach((child, index) => {
//     Transforms.setNodes(editor, [child], { at: [index] });
//   });
//   Transforms.insertNodes(
//     editor,
//     wqDom.children
//   );
//
//   return editor;
// };
