/* eslint-disable global-require */
import _ from 'lodash';
import { addLocaleData } from 'react-intl';
import {
  Locale,
  MessagesModule,
  mapMessagesModuleAsPath,
  SUPPORTED_LOCALES
} from '@wordquest/locales';
import {
  loadMessagesWithLocaleModule,
  cachedMessagesByLocale,
  addMessagesWithLocale
} from '@wordquest/lib-iso/intl';

// TODO proper locale resolution instead of simply key lookup, react-intl has no proper support
export const getReactLocale = (locale: string) => {
  const [reactLocale] = locale.split('-');

  return reactLocale;
};

const UI_PRELOAD_MESSAGES_MODULES = [
  MessagesModule.Common,
  MessagesModule.CommonNames,
  MessagesModule.Landing,
  MessagesModule.Chat,
  MessagesModule.Article,
  MessagesModule.Tag,
  MessagesModule.Video,
  MessagesModule.Quest
];

const preloadMessagesMoudlesPaths = UI_PRELOAD_MESSAGES_MODULES.map(
  mapMessagesModuleAsPath
);

const addLocaleDataAndMessages = (locale, localeData, messagesOfLocale) => {
  addLocaleData(localeData);
  addMessagesWithLocale(locale, messagesOfLocale);
};

// Client's own reponsibility to load before use, since some env e.g. nextjs extra wrappers required
export const lazyLoadLocaleData = async (locale: Locale) => {
  const reactLocale = getReactLocale(locale);
  // Webpack failed to determine the variable to include only necessary locales in the build
  // https://stackoverflow.com/questions/52339114/how-to-generate-dynamic-import-chunk-name-in-webpack
  const localeData = await import(
    /* webpackInclude: /(en|ko|zh|ja|vi)\.js$/ */
    /* webpackChunkName: 'chunk-react-locale-[request]' */
    `react-intl/locale-data/${reactLocale}`
  ).then((m) => m.default);
  // TODO generate from SUPPORTED_LOCALE
  const messageLocale = _.includes(SUPPORTED_LOCALES, locale)
    ? locale
    : reactLocale || Locale.EN;

  const messsagesData = await Promise.all(
    preloadMessagesMoudlesPaths.map(async (path) =>
      loadMessagesWithLocaleModule(messageLocale, path).then((m) => m.default)
    )
  );

  return addLocaleDataAndMessages(
    locale,
    localeData,
    _.defaultsDeep(...messsagesData)
  );
};

// for webpack
// better code sharing could be done when given top-level await and if there is no limiation on variables inside require() or import() calls
const loadDefaultLocale = () => {
  const messages = preloadMessagesMoudlesPaths.map((modulePath) =>
    require(`@wordquest/locales-weblate/messages/${modulePath}/en.json`)
  );

  addLocaleDataAndMessages(
    Locale.EN,
    require('react-intl/locale-data/en'),
    _.defaultsDeep(...messages)
  );
};

// TODO we could skip this esp at site
loadDefaultLocale();

// https://github.com/yahoo/intl-relativeformat#locale-resolution
// https://github.com/yahoo/react-intl/blob/master/src/locale-data-registry.js#L22
// https://github.com/yahoo/react-intl/wiki/API#definemessages
// https://github.com/yahoo/react-intl/issues/213

// Note messages ! intlMessages
export const getMessagesWithLocale = (locale: Locale) =>
  cachedMessagesByLocale.get(locale);

export const createIntlProviderProps = (currentLocale: Locale) => ({
  defaultLocale: getReactLocale(Locale.EN),
  key: currentLocale,
  locale: getReactLocale(currentLocale),
  messages: getMessagesWithLocale(currentLocale)
});
