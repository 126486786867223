import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Tags from '~/tag/tags';
import { getTagI18nKeyWithLanguage } from '@wordquest/locales';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Author } from '@wordquest/lib-iso/domain/wordquest/author/author';
import { isWindowMuiBreakpointUp, mediaMui, isSizeUp } from '~/style-utils';
import { markedWithDefault } from '@wordquest/lib-iso/app/markdown-html-mapper';
import ExpandableSection from '~/expandable-section';
import RichText from '~/text/rich-text';
import { WqNodeTypeBlock } from '@wordquest/lib-iso/domain/wordquest/dom';
import {
  RichTextContainer,
  createRenderElement
} from '~/text/rich-text-container';
import { FormattedMessage } from 'react-intl';
import { LanguageProficiencyTag } from '~/tag/language-proficiency-tag';
// prefer rich text// because we might add student avatar inside review later
// and easier to use show more button

const StyledAuthorName = styled.div`
  margin-top: 1rem;
  h5 {
    /* font-size: 24pt; */
    color: black;
    text-decoration: 'none';
    font-weight: bold;
  }
`;
const StyledAvatarBox = styled(Box)`
  .MuiAvatar-circle {
    width: 7rem;
    height: 7rem;
    ${mediaMui.xs`
      max-width: 5rem;
      max-height: 5rem;
    `}
  }
`;

const StyledTagsBox = styled(Box)`
  > div {
    padding-right: 0.5rem;
  }
`;

// stimulate p as body1
const StyledAuthorDescription = styled.div`
  .MuiTypography-overline {
    /* font-size: 12pt; */
    font-weight: 100;
  }

  .MuiTypography-body1 {
    /* font-size: 16pt; */
  }

  /* font-size: 1rem; */
  /* we don't support overline at wqdom / contentful, do this like  with adj */
`;

const renderStrategyByNodeType = {
  [WqNodeTypeBlock.Heading6]: (props) => {
    const { attributes, children } = props;

    return <Typography variant="overline">{children}</Typography>;
  },
  [WqNodeTypeBlock.Paragraph]: (props) => {
    const { attributes, children } = props;

    return (
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    );
  }
};

// markdown generate p
const StyledMetaGrid = styled(Grid)`
  p {
    margin: 0;
  }
`;

export const AuthorLanguages = ({
  languagesTeaching,
  languagesOther,
  labelSize = 3
}) => (
  <>
    {!_.isEmpty(languagesTeaching) && (
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={labelSize} justify="center">
          <FormattedMessage id="common.course.teacher.languages.teach" />
        </Grid>
        <Grid item xs={12 - labelSize}>
          <StyledTagsBox display="flex" p={0} flexWrap="wrap">
            {languagesTeaching.map((languageProficiency) => (
              <LanguageProficiencyTag
                languageProficiency={languageProficiency}
              />
            ))}
          </StyledTagsBox>
        </Grid>
      </Grid>
    )}
    {!_.isEmpty(languagesOther) && (
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={labelSize} justify="center">
          <FormattedMessage id="common.course.teacher.languages.speak" />
        </Grid>
        <Grid item xs={12 - labelSize}>
          <Box display="flex" p={0} flexWrap="wrap">
            {languagesOther.map((languageProficiency) => (
              <LanguageProficiencyTag
                languageProficiency={languageProficiency}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    )}
  </>
);

export default (props: {
  author: Author;
  isShowAuthorLink: boolean;
  isShowTags: boolean;
  authorNameSize: 'sm' | 'xl';
  isShowSocialProfile: boolean;
  isShowAuthorDescription: boolean;
  withLink: any;
}) => {
  const {
    author,
    isShowAuthorLink = true,
    isShowTags = true,
    isShowSocialProfile = true,
    isShowAuthorDescription = true,
    authorNameSize = 'xl',
    withLink = (props) => _.identity,
    customDescription
  } = props;

  const {
    descriptionRich,
    externalProfileLinksHtml,
    tagline,
    tagKeys = []
  } = author;

  const wrapLink = isShowAuthorLink
    ? withLink({
        authorKey: author.semanticKey
      })
    : _.identity;

  return (
    <div>
      <Box display="flex">
        <StyledAvatarBox
          mr={isSizeUp('desktop') ? 2 : 0}
          display="flex"
          justifyContent="flex-start"
        >
          {wrapLink(<Avatar alt={author.name} src={author.avatarUrl} />)}
        </StyledAvatarBox>
        <StyledMetaGrid container item xs={9} md={10} direction="column">
          <Grid item>
            {isShowAuthorDescription && (
              <Typography
                variant="subtitle1"
                component="div"
                color="inherit"
                gutterBottom
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: externalProfileLinksHtml
                  }}
                />
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              component="div"
              color="inherit"
              gutterBottom
            >
              {authorNameSize === 'sm' && (author.shortName || author.name)}{' '}
              {tagline}
            </Typography>
          </Grid>
          <Grid item>
            {isShowTags && !_.isEmpty(tagKeys) && (
              <Tags
                tagKeys={tagKeys}
                variant="default"
                getCustomizedTagI18nKey={getTagI18nKeyWithLanguage}
              />
            )}
          </Grid>
          {customDescription && <Grid item>{customDescription}</Grid>}

          {/* ratings */}
        </StyledMetaGrid>
      </Box>

      <Grid
        container
        wrap="nowrap"
        justify="flex-start"
        alignContent="center"
        spacing={0}
        direction="column"
      >
        {authorNameSize === 'xl' && (
          <Grid item xs={12}>
            <StyledAuthorName>
              {wrapLink(
                <Typography variant="h5" color="inherit" gutterBottom>
                  {author.name}
                </Typography>
              )}
            </StyledAuthorName>
          </Grid>
        )}
        <Grid item xs={12}>
          <StyledAuthorDescription>
            {isShowAuthorDescription &&
              (descriptionRich ? (
                <ExpandableSection
                  maxHeight="8rem"
                  isDefaultExpanded={_.size(descriptionRich.children) <= 5}
                >
                  <RichTextContainer wqDom={descriptionRich}>
                    <RichText
                      renderElement={createRenderElement(
                        renderStrategyByNodeType
                      )}
                    />
                  </RichTextContainer>
                </ExpandableSection>
              ) : (
                <Typography variant="body1" component="div" color="inherit">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: author.description
                    }}
                  />
                </Typography>
              ))}
          </StyledAuthorDescription>
        </Grid>
      </Grid>
    </div>
  );
};
