import _ from 'lodash';
import {
  observable,
  reaction,
  runInAction,
  autorun,
  computed,
  decorate,
  toJS,
  set,
  values
} from 'mobx';
import { from, timer } from 'rxjs';
import { createTransformer } from 'mobx-utils';
import rootLogger from '@wordquest/lib-iso/app/logger';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import { RouterStore } from 'mobx-react-router';
import RootStore from '~/stores/root';
import { VideoPlayerType } from '@wordquest/lib-iso/domain/wordquest/video/video-player-meta';
import { Quote } from '@wordquest/lib-iso/domain/wordquest/quote/quote';
import {
  filter,
  map,
  toArray,
  shareReplay,
  delayWhen,
  bufferTime
} from 'rxjs/operators';
// generally ugly in transcript
import {
  parseForCues,
  isContainTimestampTags,
  postProcessCues
} from '@wordquest/lib-iso/domain/vtt';
import {
  VIDEO_YT_TAGS_KEY,
  getVideoYtTags,
  VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY,
  VIDEO_YT_DEFAULT_LOCALE_KEY
} from '@wordquest/lib-iso/app/video/video-yt';
import { createContextFunctionWithTerms } from '@wordquest/lib-iso/app/repo-es-util';
import { createPotentialYtDefaultLocales } from '@wordquest/lib-iso/adapters/youtube-locale';
import { format } from '@wordquest/lib-iso/intl';
import { createQueryContextByGroupWithTopicTab } from '@wordquest/lib-iso/app/topic/covid19';
import {
  PodcastPlatformType,
  asGooglePodcastUrl
} from '@wordquest/lib-iso/domain/wordquest/podcast/podcast';
import {
  createTagsBySegmentKey,
  keyAsSegment,
  segmentAsKey
} from '@wordquest/lib-iso/domain/wordquest/segment/segment';
import { VideoPlatform } from '@wordquest/lib-iso/domain/wordquest/video/video';
import { DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE } from '~/stores/domain/video';
import { QuestAction } from '@wordquest/lib-iso/domain/wordquest/entity';
import { cleanLinebreaks } from '@wordquest/lib-iso/domain/wordquest/video/video-subtitles';
import {
  initSubtitlesStateService,
  SubtitlesEvent
} from '~/services/subtitles-select';
import { collectMultipleRichTextsEntitiesByNodeType } from '@wordquest/lib-iso/domain/wordquest/dom-entity-extractor';
import {
  WqNodeTypeBlock,
  WqNodeTypeInline
} from '@wordquest/lib-iso/domain/wordquest/dom';
import { loadDataByTopicDataKey } from './page-video-topic';

const logger = rootLogger.child({ module: 'Store-PageVideo' });

const BUFFER_ADVANCED_S = 10;
// long enough to cover ongoing cue
const BUFFER_REWINDED_S = 5;

export const getKeyWithVideoIdLocale = (videoId: string, locale: Locale) =>
  [videoId, locale].join('-');
const subtitlesReactionsByIdLocale = {};

const isCueMatchPlayingTime = (cue, playingTime) =>
  cue && cue.startTime < playingTime && playingTime <= cue.endTime;

export const createTipsCardMetasWithQuotes = createTransformer(
  (quotes: Quote[] = []) =>
    quotes.map((quote, i) => {
      // extra 0.1 seconds to avoid end-start overlap
      const startSeconds = _.get(quote, 'video.startSeconds') || 0.01 + 0.1;
      const endSeconds = _.get(quote, 'video.endSeconds') || startSeconds + 10;

      return {
        startSeconds,
        endSeconds,
        properties: {
          quote
        },
        type: 'quote'
      };
    })
);

export const createMarksWithTipCards = createTransformer(
  ({
    tipsCardMetas = [],
    durationInS = 1000
  }: {
    tipsCardMetas: TipsCardMeta[];
    durationInS: number;
  }) =>
    _.orderBy(tipsCardMetas, 'startSeconds', 'asc').map((tipsCardMeta, i) => {
      const { startSeconds, endSeconds } = tipsCardMeta;

      return {
        timePercent: (endSeconds - startSeconds) / durationInS,
        value: Math.max((startSeconds + endSeconds) / 2, 0),
        type: 'quote',
        index: i
      };
    })
);
const loadReactingSubtitlesState =
  (rootStore) =>
  (
    isLoad,
    videoId: string,
    locale: Locale,
    isSubtitlesOnByLocale,
    isUseTextAnalyzedContext = true
  ) => {
    logger.debug('loadReactingSubtitlesState', videoId, locale);
    const idLocaleKey = getKeyWithVideoIdLocale(videoId, locale);
    if (!_.isEmpty(subtitlesReactionsByIdLocale[idLocaleKey])) {
      if (!isLoad) {
        subtitlesReactionsByIdLocale[idLocaleKey].map((r) => r());
        subtitlesReactionsByIdLocale[idLocaleKey] = [];
      }

      return;
    }
    if (!isLoad) {
      return;
    }

    const { getOrCreateVideoPlayback, subtitlesByLocaleById } =
      rootStore.videoStore;
    const {
      currentVideo,
      currentCueRefByLocale,
      parsedCuesByIdLocale,
      currentCuesWindowByLocale
    } = rootStore.pageVideoStore;

    const playback = getOrCreateVideoPlayback(videoId);

    const seekerByVideoIdLocale = {};
    let lastSeek = 0;
    const seekCue = (startTimeOffset) => {
      if (Math.abs(startTimeOffset - lastSeek) < 2 && lastSeek !== 0) {
        return;
      }
      // logger.debug('seekCue creating lastSeek', startTimeOffset, lastSeek, idLocaleKey);
      lastSeek = startTimeOffset;
      if (seekerByVideoIdLocale[idLocaleKey]) {
        seekerByVideoIdLocale[idLocaleKey].unsubscribe();
      }

      const cues = parsedCuesByIdLocale.get(idLocaleKey);
      // skip the delay except first
      // dump way to distinguish not exists vs not in window

      // auto killed when stop playing for a minute
      if (!cues) {
        return;
      }

      seekerByVideoIdLocale[idLocaleKey] = cues
        .pipe(
          filter(
            (cue) =>
              cue.endTime >= startTimeOffset - BUFFER_REWINDED_S * 1000 &&
              cue.startTime <= startTimeOffset + BUFFER_ADVANCED_S * 1000
          ),
          delayWhen((cue) =>
            timer(
              Math.max(
                (cue.startTime - startTimeOffset - BUFFER_ADVANCED_S) * 1000
              ),
              0
            )
          ),
          bufferTime(50),
          map((cuesLoaded) => {
            if (_.isEmpty(cuesLoaded)) {
              return [];
            }
            runInAction(() => {
              const currentCuesWindow = currentCuesWindowByLocale[locale];
              if (!currentCuesWindow) {
                return;
              }
              // instead of .filter
              currentCuesWindow.forEach((cue) => {
                if (
                  cue.endTime < startTimeOffset - BUFFER_REWINDED_S ||
                  cue.startTime - startTimeOffset > BUFFER_ADVANCED_S
                ) {
                  // ensure this won't trigger until finish
                  currentCuesWindow.remove(cue);
                }
              });
              cuesLoaded.map((cue) => currentCuesWindow.push(cue));
              // logger.debug('seek cue ready', cuesLoaded);
            });

            return cuesLoaded;
          })
        )
        .subscribe();
    };

    if (_.isEmpty(subtitlesReactionsByIdLocale[idLocaleKey])) {
      logger.debug('create subtitlesReactionsByIdLocale', idLocaleKey);
      subtitlesReactionsByIdLocale[idLocaleKey] = [];
      subtitlesReactionsByIdLocale[idLocaleKey].push(
        reaction(
          () => _.get(subtitlesByLocaleById.get(videoId), `${locale}.raw`),
          (raw) => {
            logger.debug('subtitlesByLocaleById raw', idLocaleKey, !!raw);
            let cues = null;
            if (raw) {
              // TODO source load only either raw/tagged for memory
              const subtitlesText = raw;
              const cuesWithWqDom = _.get(
                subtitlesByLocaleById.get(videoId),
                `${locale}.cuesWithWqDom`
              );
              logger.debug(
                'isUseTextAnalyzedContext',
                isUseTextAnalyzedContext,
                !!cuesWithWqDom
              );
              if (isUseTextAnalyzedContext && cuesWithWqDom) {
                cues = from(cuesWithWqDom);

                // never use .tagged for now
                // subtitlesText = _.get(subtitlesByLocaleById.get(videoId), `${locale}.tagged`) || raw;
                // logger.trace('isUseTextAnalyzedContext', isUseTextAnalyzedContext, subtitlesText);
              } else {
                // TODO check lastEditedBy is youtube too
                const isVttCaptionsFormat =
                  isContainTimestampTags(subtitlesText);
                cues = postProcessCues({ isVttCaptionsFormat })(
                  parseForCues(window, subtitlesText)
                ).pipe(shareReplay());
              }
              set(parsedCuesByIdLocale, {
                [getKeyWithVideoIdLocale(videoId, locale)]: cues
              });
              seekCue(0);
              delete subtitlesByLocaleById.get(videoId)[locale].raw;
            }
          },
          { fireImmediately: true }
        )
      );

      // Test Cases TODO
      // after seek in video, subtitles updated
      // both old / too further buffer removed
      // setup once by videoId-locale
      // TODO Pbm: not using memoization
      // we can't skip one locale and use index becoz data might not sync
      subtitlesReactionsByIdLocale[idLocaleKey].push(
        reaction(
          () => playback.playedSeconds,
          () => {
            // https://github.com/wordquest/wordquest/issues/521
            if (
              !playback.isRepeatingCurrentCue &&
              isCueMatchPlayingTime(
                currentCueRefByLocale[locale],
                playback.playedSeconds
              )
            ) {
              return;
            }
            // during repeat, search again perhaps inefficient but easier than clear setInterval
            const matchingCue = _.find(
              currentCuesWindowByLocale[locale],
              (cue) => isCueMatchPlayingTime(cue, playback.playedSeconds)
            );

            // pbm: already hv the replace array so in memory already?
            // future = scrolling indeed. but wanna search?

            // TODO replace with limited binary tree if needed
            // reset by start time when not available, unsubscribe
            if (!matchingCue) {
              // kill if interval between cues are small
              // trade off another find vs return directly
              const upcomingCUe = _.find(
                currentCuesWindowByLocale[locale],
                (cue) =>
                  isCueMatchPlayingTime(cue, playback.playedSeconds + 0.5)
              );
              if (!upcomingCUe) {
                // case no cue overlap
                seekCue(playback.playedSeconds);
              }
            } else {
              //         // TODO should do so even subtitles not turned on
              //         // loop features require >=1 subtitles to work anyway
              //         // possible to hack to have only first respond to this using _.values(subtitlesReactionsByIdLocale).length===0, but much better to use source locale in UX

              if (
                locale === currentVideo.primaryLocale &&
                playback.isRepeatingCurrentCue &&
                matchingCue.endTime - playback.playedSeconds < 0.3
              ) {
                // the last seconds depends progressInterval
                const offsetSeconds =
                  matchingCue.endTime - playback.playedSeconds;
                logger.debug('seek To', matchingCue.startTime, offsetSeconds);
                rootStore.pageVideoStore.seekTo(
                  matchingCue.startTime,
                  offsetSeconds
                );
              }

              if (!playback.isSeeking) {
                set(currentCueRefByLocale, { [locale]: matchingCue });
              }
            }
          },
          {
            fireImmediately: true,
            // 1)use minimum delay in order to keep sync with youtube captions in case it is on
            // 2) while, mobx could react to delayed playedSeconds, even after player.seekTo is called and player emit correct onProgress event
            // 3) smaller than onProgress interval itself is not meaningful
            delay: 100
            // using equals can't throttle as only compare close events
          }
        )
      );
    }
  };

const parseAllCues = async (
  parsedCuesByIdLocale,
  videoId,
  locales = [],
  isTranscriptTranslationOn,
  isUseTextAnalyzedContext
) => {
  logger.debug(
    'parseAllCues',
    parsedCuesByIdLocale,
    videoId,
    locales,
    isTranscriptTranslationOn
  );
  if (!videoId || _.isEmpty(locales)) {
    return;
  }

  let allCues = [];
  const [locale, secondLocale] = locales;
  const idLocalKey = getKeyWithVideoIdLocale(videoId, locale);
  const cues = parsedCuesByIdLocale.get(idLocalKey);
  if (cues) {
    allCues = await cues
      .pipe(
        map((cue, index) =>
          _.merge(cue, { text: cleanLinebreaks(cue.text), index })
        ),
        toArray()
      )
      .toPromise();
  }

  if (secondLocale && isTranscriptTranslationOn) {
    // ugly hack temp workaround
    const cuesUserLocale = parsedCuesByIdLocale.get(
      getKeyWithVideoIdLocale(videoId, secondLocale)
    );
    if (cuesUserLocale) {
      allCues = await cuesUserLocale
        .pipe(
          toArray(),
          map((cuesLoaded) =>
            _.zip(cuesLoaded, allCues)
              .map(([userLocaleCue, cue], index) => {
                if (cue && cue.text) {
                  if (!userLocaleCue || !userLocaleCue.text) {
                    return cue;
                  }
                  if (
                    isUseTextAnalyzedContext &&
                    !!_.get(cue, 'wqDom.children.0')
                  ) {
                    cue.wqDom.children = [
                      {
                        type: WqNodeTypeBlock.Div,
                        attributes: {},
                        children: cue.wqDom.children
                      },
                      {
                        type: WqNodeTypeBlock.Div,
                        attributes: {},
                        children: [
                          {
                            text: ` \n ${cleanLinebreaks(userLocaleCue.text)}`
                          }
                        ]
                      }
                    ];
                  }

                  return _.merge({}, cue, {
                    text: `${cue.text} \n ${cleanLinebreaks(
                      userLocaleCue.text
                    )}`,
                    index
                  });
                }

                return userLocaleCue;
              })
              .filter(Boolean)
          )
        )
        .toPromise();
    }
  }

  return allCues;
};

export type TipsCardMeta = {
  startSeconds: number;
  endSeconds: number;
  type: string;
};

class PageVideoStore {
  constructor(private rootStore: RootStore, public routerStore: RouterStore) {
    const { videoStore, uiStateStore, userStore } = rootStore;
    const { app, routeMatch } = uiStateStore;
    const { search } = routeMatch;
    this.shownTagsDefaultCount = 15;
    // video playseconds -> sync targetIndex using quote meta
    // manual input -> change current Index -> change play seconds
    // now we're using isSeeking as lock but ensure no delay/setTimeout as event loop might casuse race condition

    // belongs to pageVideo as show together. base on lesson need might inject additional card here
    this.tipCardsRef = observable({
      targetIndex: 0,
      currentIndex: 0,
      metas: []
    } as {
      targetIndex: number;
      currentIndex: number;
      metas: TipsCardMeta[];
    });
    // assume 1 player first
    // For current, We can't tell from location.pathname itself as multiple routes could match
    this.playerRef = observable({
      type: VideoPlayerType.Youtube,
      player: null,
      playingTracker: null,
      reactions: []
    });
    this.topicRef = observable({
      key: '',
      tabKey: '',
      title: ''
    });

    this.tipsCurrentVideo = observable([]);

    this.videoGroupTitlePrefix = observable.box('');

    this.currentVideoRef = observable({
      id: '',
      loadRecommendationCount: 0
    });

    this.currentPodcast = observable({
      title: '',
      podcastKey: '',
      isBarOn: false,
      videoIds: [],
      isShowSharingDialog: false,
      selected: {
        startSeconds: 0,
        endSeconds: 0,
        editableContent: ''
      }
    });
    this.currentSubtitles = observable({
      subtitlesStateService: null,
      subtitlesState: {}
    });

    this.dataByTopicKey = observable.map({});

    this.recommendContextByGroup = observable.box({});
    // TODO diff group?
    this.languageBaseQueryContext = observable.box({});
    this.isLoadSubtitlesInitiatedByLocale = observable.map({});
    this.tagsBySegmentKeyMemo = observable([]);
    this.marks = observable([]);
    this.markCurrent = observable.box(null);
    // disable link first course are non-quote links
    // TODO decuple UI & data,  tooltip or link

    this.reactionsWithVideoId = [];

    // stateful depends on entry point
    this.navTopicKey = observable.box('');
    this.navTabKey = observable.box('');

    const defaultWatchUiConfig = {
      initSubtitles: Locale.ZH_TW,
      isMediaSticky: false,
      isCallForSubscriptionOn: true,
      isRecommendationOn: false,
      isRecommendationCuratedOnly: true,
      isDescriptionOn: true,
      isTranscriptOn: true,
      isTranscriptTranslationOn: true,
      isShowYtVideoLanguage: false,
      isFilterTagsDirty: false
    };

    this.resetVideo = (params) => {
      logger.debug('reset Video');
      _.merge(this.watchUiConfig, defaultWatchUiConfig);
      this.isLoadSubtitlesInitiatedByLocale.replace({});
      _.merge(this.playbackRange, defaultPlaybackRange);

      if (params.videoTags) {
        const videoTagKeysToActive = params.videoTags.split(',');
        this.videoTagFiltersActiveKeys.replace(videoTagKeysToActive || []);
      }
      if (params.videoId) {
        this.currentVideoRef.id = params.videoId;
      }

      this.currentVideoRef.loadRecommendationCount = 0;
    };

    this.watchUiConfig = observable(defaultWatchUiConfig);
    this.playbackMetrics = observable({
      loadToPlaySeconds: 0
    });
    const defaultPlaybackRange = {
      startSeconds: 0,
      endSeconds: Number.MAX_SAFE_INTEGER
    };

    this.playbackRange = observable(defaultPlaybackRange);

    this.seekTo = (time, offsetSeconds = 0) => {
      const playback = videoStore.getOrCreateVideoPlayback(
        this.currentVideoRef.id
      );
      if (playback.pendingSeek) {
        clearTimeout(playback.pendingSeek);
        // TODO kill pending scroll too
      }
      // if offsetSeconds = 0, do it now to avoid next event loop which cause race conditions issue
      const _doSeek = () => {
        playback.isSeeking = true;
        playback.playedSeconds = time + 0.01;
      };
      if (offsetSeconds === 0) {
        _doSeek();
      } else {
        playback.pendingSeek = setTimeout(_doSeek, offsetSeconds * 1000);
      }
    };

    const trackWatchProgress = () => {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      const timeSincePageLoad = Math.round(performance.now() / 1000);
      if (this.playbackMetrics.loadToPlaySeconds === 0) {
        this.playbackMetrics.loadToPlaySeconds = timeSincePageLoad;
      }
      const watchedSeconds =
        timeSincePageLoad - this.playbackMetrics.loadToPlaySeconds;
      if (watchedSeconds < WATCH_EVENT_MAX_DURATION_MIN * 60 + 1) {
        return;
      }
      rootStore.eventStore.trackEvent({
        event: 'Watch-Progressed',
        properties: {
          currentVideoId: this.currentVideoRef.id,
          loadToPlaySeconds: this.playbackMetrics.loadToPlaySeconds,
          watchedSeconds
        }
      });

      if (window.ga) {
        // Sends the timing hit to Google Analytics.
        window.ga('send', 'timing', 'Watched Seconds', 'watch', watchedSeconds);
      }
    };

    // play video we don't auto stop audio (teacher)
    // as 1) either close some point 2) student might still want to navi
    reaction(
      () => rootStore.uiStateStore.currentAudio.playback.playing,
      (isPlaying) => {
        if (isPlaying) {
          const playback = videoStore.getOrCreateVideoPlayback(
            this.currentVideoRef.id
          );
          set(playback, 'playing', false);
        }
      }
    );

    reaction(
      () => `${this.tipsCurrentVideo.length}${this.quotesCurrentVideo.length}`,
      () => {
        // TODO consider negative
        // need to be greater than the check threshold (0.5)
        this.tipCardsRef.metas = toJS(this.tipsCurrentVideo)
          .map((tip, i) => ({
            startSeconds: 0 + i * 0.55,
            endSeconds: 0 + i * 0.55,
            ...tip
          }))
          .concat(createTipsCardMetasWithQuotes(this.quotesCurrentVideo));
      }
    );

    autorun(() => {
      const playbackMarks =
        this.playbackRange.endSeconds < Number.MAX_SAFE_INTEGER
          ? [
              {
                value: this.playbackRange.startSeconds,
                type: 'seconds'
              },
              {
                value: this.playbackRange.endSeconds,
                type: 'seconds'
              }
            ]
          : [];

      const tipCardsMarks = createMarksWithTipCards({
        tipsCardMetas: this.tipCardsRef.metas,
        durationInS: _.get(
          this.currentVideo,
          'videoPlatformMeta.youtube.durationInS'
        )
      });
      this.marks.replace(playbackMarks.concat(tipCardsMarks));
    });

    reaction(
      () =>
        (videoStore.getOrCreateVideoPlayback(this.currentVideoRef.id) || {})
          .playedSeconds,
      (updatedPlayedSeconds) => {
        const playback = videoStore.getOrCreateVideoPlayback(
          this.currentVideoRef.id
        );
        // the extra window will limit how close each quote, otherwise will switch back

        // Completely decouple the seek state and action
        // https://github.com/wordquest/wordquest/issues/645
        // we assume it takes some time for actual player(s) to seek
        if (playback.isSeeking) {
          setTimeout(() => {
            logger.debug('seekTo complete', updatedPlayedSeconds);
            playback.isSeeking = false;
          }, 110);
        }

        // last to avoid keep iterating back
        // TODO test case
        this.tipCardsRef.targetIndex = _.findLastIndex(
          this.tipCardsRef.metas,
          (tipsCard) =>
            playback.playedSeconds > tipsCard.startSeconds - 0.5 &&
            playback.playedSeconds < tipsCard.endSeconds + 0.5
        );
        // logger.debug('find targetIndex', this.tipCardsRef.targetIndex);
        // logger.trace('tipCardsRef playback->target', this.tipCardsRef.targetIndex, playback.playedSeconds);
        // this.markCurrent.set(this.tipCardsRef.targetIndex > -1 ? {
        //   type: 'quote',
        //   properties: {
        //     quote: _.get(this.quotesCurrentVideo, this.tipCardsRef.targetIndex)
        //   }
        // } : null);
      },
      {
        fireImmediately: true,
        delay: 100
      }
    );

    reaction(
      () => this.tipCardsRef.currentIndex,
      () => {
        if (this.tipCardsRef.currentIndex === -1) {
          return;
        }
        logger.debug('update currentIndex', this.tipCardsRef.currentIndex);
        const startSeconds = _.get(
          this.tipCardsRef.metas,
          `${this.tipCardsRef.currentIndex}.startSeconds`
        );
        const playback = videoStore.getOrCreateVideoPlayback(
          this.currentVideoRef.id
        );

        if (this.tipCardsRef.isSeekToCurrent) {
          logger.debug('isSeekToCurrent', startSeconds);
          this.seekTo(startSeconds);
          this.tipCardsRef.isSeekToCurrent = false;
        }
        // we do the seek only if trigger by touch

        // UX decision: scroll to intro note cards has no effect on playback, but make sense at pause only otherwise scroll back to other card
        // TODO fix playing control first
        // if (startSeconds >= 0 && playback.playing) {
        //   this.seekTo(startSeconds);
        // } else {
        //   logger.debug('intro cards, pause');
        //   playback.playing = false;
        // }
      }
    );

    // probably + isSeeking

    // past = compare equal directly, but missed when currentIndex updated multiple time
    reaction(
      () => this.tipCardsRef.targetIndex,
      () => {
        if (this.tipCardsRef.targetIndex === this.tipCardsRef.currentIndex) {
          return;
        }
        // trigger by playback, we should update currentIndex to slide
        // trigger by swiper event, we should sync playback+targetIndex
        // the seeking playedSeconds
        const { targetIndex, currentIndex } = this.tipCardsRef;
        // always wait seek complete
        // animating finished but currentIndex not yet updated
        const playback = videoStore.getOrCreateVideoPlayback(
          this.currentVideoRef.id
        );
        logger.debug(
          `tipCardsRef update t:${targetIndex} c:${currentIndex} isSeeking:${playback.isSeeking}`
        );
        // not seeking
        // targetIndex could be -1 as there are gaps between tipcards , skip update for stability
        if (!playback.isSeeking && targetIndex > -1) {
          this.tipCardsRef.currentIndex = targetIndex;
        }
      },
      {
        // slower than seekTo update
        delay: 300
      }
    );

    // Ignore: Paused, playing without watch
    // they can be considered the same anyway
    // some effort to track pause-then-play-again
    const WATCH_EVENT_INTERVAL_MIN = 5;
    const WATCH_EVENT_MAX_DURATION_MIN = 10;

    reaction(
      () =>
        videoStore.getOrCreateVideoPlayback(this.currentVideoRef.id)
          .playedSeconds > 0,
      () => {
        if (window.performance) {
          trackWatchProgress();
          if (!this.playerRef.playingTracker) {
            this.playerRef.playingTracker = setInterval(
              trackWatchProgress,
              WATCH_EVENT_INTERVAL_MIN * 60 * 1000
            );
          }
        }
      }
    );

    this.deepMergeDataByTopicKey = (data) => {
      const topicKey = uiStateStore.routeMatch.params.topicKey;
      logger.debug('deepMergeDataByTopicKey', topicKey, data);
      set(
        this.dataByTopicKey,
        topicKey,
        _.merge({}, this.dataByTopicKey.get(topicKey), data)
      );
    };

    // TODO trigger playback
    console.log('init isSubtitlesOnByLocale');
    this.isSubtitlesOnByLocale = observable.map({
      // pre-defining keys is necessary for mobx
      ..._.fromPairs(SUPPORTED_LOCALES.map((l) => [l, false])),
      [Locale.ZH_TW]: true
    });

    this.subtitlesByLocale = observable.map({});

    autorun(() => {
      if (!this.currentVideo) {
        return;
      }
      // TODO consider remove not active ones
      this.isSubtitlesOnByLocale.forEach((isOn, locale) => {
        loadReactingSubtitlesState(rootStore)(
          isOn,
          this.currentVideo.id,
          locale,
          this.isSubtitlesOnByLocale,
          rootStore.uiStateStore.uiConfig.isUseTextAnalyzedContext
        );
      });
    });

    // react to loaded subtitles should call only object replace
    // TODO separate init and changing logic
    // do not use this.currentVideoRef.id which could persist?
    reaction(
      () =>
        this.watchUiConfig.initSubtitles +
        this.defaultLocale +
        this.currentSubtitlesSortedLocales +
        this.currentVideoRef.id,
      () => {
        if (
          _.isEmpty(this.currentSubtitlesSortedLocales) ||
          !this.currentVideoRef.id
        ) {
          return;
        }
        console.log('refresh subs settings', this.currentVideoRef.id);

        // we rely on here to update subtitles settings instead of resetVideo
        this.currentSubtitles.subtitlesStateService =
          initSubtitlesStateService(this);
        console.log(
          'initSubtitles with:',
          this.watchUiConfig.initSubtitles,
          this.currentSubtitles.subtitlesState,
          this.defaultLocale,
          this.currentSubtitlesSortedLocales
        );

        this.currentSubtitles.subtitlesStateService.send({
          type: SubtitlesEvent.InitSubtitles,
          config: this.watchUiConfig.initSubtitles
        });

        // sync value
        console.log(
          'initSubtitles result:',
          toJS(this.currentSubtitles.subtitlesState)
        );
      }
    );

    // as default
    this.recommendedIdsByGroup = observable.map({});
    this.currentFilteredIdsByGroup = observable.map({});

    // let active components create instead of computed
    this.parsedCuesByIdLocale = observable.map({});

    this.currentCueRefByLocale = observable({});

    this.currentScrollReactionRef = observable({});
    this.allCuesTranscript = observable.box([]);
    // don't use for transcript. plain text for less react reconcil for the win
    this.currentCuesWindowByLocale = observable(
      _.fromPairs(SUPPORTED_LOCALES.map((l) => [l, []]))
    );

    autorun(
      // () => this.parsedCuesByIdLocale,
      async () => {
        const { isTranscriptTranslationOn } = this.watchUiConfig;
        const allCues = await parseAllCues(
          this.parsedCuesByIdLocale,
          this.currentVideoRef.id,
          [this.defaultLocale, this.targetLocale],
          isTranscriptTranslationOn,
          rootStore.uiStateStore.uiConfig.isUseTextAnalyzedContext
        );
        this.allCuesTranscript.set(allCues);
      }
      // { fireImmediately: true }
    );

    // could be more than tags in future
    this.videoTagFiltersActiveKeys = observable([]);

    this.videoActiveSourceLocales = observable([
      Locale.EN,
      Locale.ZH_TW,
      Locale.ZH_CN,
      Locale.YUE
    ]);
    // not reusing active keys for languages, as difference ES query
    // override the context instead
    reaction(
      () => this.videoActiveSourceLocales.length,
      async (length) => {
        logger.debug(
          'languageBaseQueryContext.set',
          this.videoActiveSourceLocales
        );
        if (length <= 0) {
          return;
        }
        this.languageBaseQueryContext.set({
          // some are null so hard to filter
          filter: {
            or: {
              [VIDEO_YT_DEFAULT_LOCALE_KEY]: createPotentialYtDefaultLocales(
                this.videoActiveSourceLocales
              ),
              [VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY]:
                createPotentialYtDefaultLocales(this.videoActiveSourceLocales),
              'video.language': createPotentialYtDefaultLocales(
                this.videoActiveSourceLocales
              )
            }

            // should instead of must
            // some are empty. pbm current setting will match when either one is empty. use must exists

            // Need both as audio less accurate
          }
        });
        // const videoIdsByGroup = await rootStore.videoStore.loadVideoIdsByGroupWithTags(this.videoTagFiltersActiveKeys);
        // this.currentFilteredIdsByGroup.replace(videoIdsByGroup);
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.videoTagFiltersActiveKeys.length,
      async () => {
        // watch should get this on
        const { path, params } = rootStore.uiStateStore.routeMatch;
        if (path !== '/watch') {
          return;
        }
        const videoIdsByGroup =
          await rootStore.videoStore.loadVideoIdsByGroupWithTags(
            this.videoTagFiltersActiveKeys
          );
        this.currentFilteredIdsByGroup.replace(videoIdsByGroup);
      },
      { fireImmediately: true }
    );

    reaction(
      () => (this.currentSubtitlesByLocale || {})[this.defaultLocale],
      async (subtitles) => {
        let matchedWordIds = [];
        if (!subtitles || !uiStateStore.uiConfig.isUseTextAnalyzedContext) {
          return;
        }
        if (subtitles.cuesWithWqDom) {
          const entitiesByNodeType = collectMultipleRichTextsEntitiesByNodeType(
            subtitles.cuesWithWqDom.map((c) => c.wqDom),
            { isInclude: false },
            [WqNodeTypeInline.Word]
          );
          matchedWordIds = matchedWordIds.concat(
            (entitiesByNodeType.word || []).map((w) => w.id)
          );
        } else if ((subtitles || {}).tagged) {
          // TODO
          if (subtitles.tokenInfoByToken) {
            matchedWordIds = matchedWordIds.concat(
              _.values(subtitles.tokenInfoByToken)
                .map((tokenInfo) => tokenInfo.matchedWordId)
                .filter(Boolean)
            );

            // load at once becoz our design rely on that for highlights
          }
        }
        if (!_.isEmpty(matchedWordIds)) {
          logger.debug(
            'load matchedWordIds',
            matchedWordIds.length,
            matchedWordIds
          );
          await rootStore.wordStore.lazyLoadWordByIds(matchedWordIds);
          await rootStore.wordStore.lazyLoadDefinitionsByWordIds(
            matchedWordIds
          );
        }
      }
    );

    reaction(
      () => this.currentVideoRef.id,
      (id) => {
        logger.debug('video id updated', id);

        this.resetVideo({});

        this.reactionsWithVideoId.push(
          reaction(
            () => !!_.get(this.currentVideo, 'videoPlatformMeta.podcast.id'),
            (isAudio) => {
              if (isAudio) {
                this.playerRef.type = VideoPlayerType.AudioThumbnail;
                // use fallback
                // this.playerRef.type = VideoPlayerType.File;
              } else {
                this.playerRef.type = VideoPlayerType.Youtube;
              }
            }
          ),
          reaction(
            () =>
              videoStore.getOrCreateVideoPlayback(this.currentVideoRef.id)
                .isReady || uiStateStore.app.isGenericDelayCompleted,
            () => {
              // TODO refactor decouple ready vs on
              if (!(uiStateStore.routeMatch.params || {}).courseKey) {
                logger.debug('isRecommendationOn on');
                this.watchUiConfig.isRecommendationOn = true;
              }
              rootStore.videoStore.loadQuotesWithVideoId(
                this.currentVideoRef.id
              );
            }
          )
        );

        // Cleanup
        if (this.playerRef.playingTracker) {
          clearInterval(this.playerRef.playingTracker);
        }

        if (id && !videoStore.videoById.has(id)) {
          videoStore.loadVideoWithIds([id]).then(() => {
            if (!videoStore.videoById.get(id)) {
              rootStore.uiStateStore.errorState.video = 404;
            }
          });
        }
      }
    );

    reaction(
      () => this.rootStore.videoStore.videoById.get(this.currentVideoRef.id),
      () => {
        if (!this.currentVideo) {
          return;
        }
        const { videoMeta } = this.currentVideo;
        // currentSubtitlesByLocale is loaded ones, not available ones
        const missedLocales = _.xor(
          videoMeta.subtitlesLocales,
          _.keys(toJS(this.currentSubtitlesByLocale))
        );
        logger.debug('missedLocales', missedLocales);

        // always load regardless as isSubtitlesOnByLocale rely on this instead
        // .filter(l => this.isSubtitlesOnByLocale.get(l)) as Locale[];
        const localesToLoad = _.difference(
          missedLocales,
          _.keys(toJS(this.isLoadSubtitlesInitiatedByLocale))
        ) as Locale[];
        if (!_.isEmpty(localesToLoad)) {
          // #412
          logger.debug('loadSubtitlesByLocaleById', localesToLoad);
          this.isLoadSubtitlesInitiatedByLocale.merge(
            _.fromPairs(localesToLoad.map((l) => [l, true]))
          );
          videoStore.loadSubtitlesByLocaleById(
            [this.currentVideoRef.id],
            localesToLoad
          );
        }

        // case of episode
        if (this.currentVideoRef.id.match(/podcast-/)) {
          const podcast = _.get(
            this.currentVideo,
            `videoPlatformMeta.${VideoPlatform.Podcast}`
          );
          _.merge(this.currentPodcast, podcast);
        }
      },
      {
        fireImmediately: true
      }
    );

    reaction(
      () => routeMatch.params.tabKey,
      (tabKey) => {
        if (tabKey || routeMatch.params.topicKey) {
          // e.g. step in video
          this.topicRef.tabKey = _.toUpper(tabKey) || 'TW';
          this.navTabKey.set(tabKey);
        }
      },
      { fireImmediately: true }
    );

    // recreate is required to lazy load subtitles

    // instead of @computed as potentialyl other entry path too

    // TODO migate to podcast store
    reaction(
      () => routeMatch.params,
      async (params) => {
        if (params.podcastKey) {
          if (params.episodeKey) {
            this.currentVideoRef.id = `podcast-n${params.episodeKey}`;
            // const { podcast } = _.get(this.currentVideo, 'videoPlatformMeta') || {};
          } else {
            // ignore podcastkey if in episodeKey
            const videos =
              await rootStore.videoStore.loadPodcastEpisodesWithPodcastKey(
                params.podcastKey
              );
            if (!_.isEmpty(videos)) {
              logger.debug('loaded podcasts', videos);
              const [video] = videos;
              const podcast = _.get(
                video,
                `videoPlatformMeta.${VideoPlatform.Podcast}`
              );
              _.merge(this.currentPodcast, podcast);

              podcast.platformMetas = podcast.platforms;
              if (podcast.rss) {
                podcast.platformMetas.push({
                  platform: PodcastPlatformType.GoogleCast,
                  url: asGooglePodcastUrl(podcast.rss)
                });
              }

              this.currentPodcast.videoIds.replace(videos.map((v) => v.id));
            }
          }
        }
      },
      { fireImmediately: true }
    );
    //  decouple ui params from video params & side which could be injected by course too
    reaction(
      () => routeMatch.params,
      (params) => {
        this.resetVideo(params);

        const search = routeMatch.search || {};

        const startSeconds = parseInt(search.startSeconds, 10);
        const endSeconds = parseInt(search.endSeconds, 10);
        if (startSeconds > 0) {
          this.playbackRange.startSeconds = startSeconds;
        }
        // TODO move to ui-state
        uiStateStore.uiConfig.isUseTextAnalyzedContext = !!search.analyzed;

        if (_.isFinite(endSeconds)) {
          this.playbackRange.endSeconds = endSeconds;
        }
      },
      { fireImmediately: true }
    );

    // important to apply search after params?
    reaction(
      () => routeMatch.search,
      (search = {}) => {
        // locale of learner's target languages
        const targetLocales = _.split(
          _.get(search, 't') || _.get(search, 'subtitles'),
          ','
        ).filter(Boolean);

        const topicKey = _.get(search, 'topic');
        if (topicKey) {
          this.navTopicKey.set(topicKey);
        }

        const tabKey = _.get(search, 'tab');
        if (tabKey) {
          this.navTabKey.set(tabKey);
        }

        // TODO fix to use array
        this.watchUiConfig.initSubtitles =
          _.first(targetLocales) || this.watchUiConfig.initSubtitles;

        if (!_.isEmpty(targetLocales)) {
          this.videoActiveSourceLocales.replace(
            _.intersection(targetLocales, SUPPORTED_LOCALES)
          );
        }

        const isTranscriptOn = _.get(search, 'transcript');
        this.watchUiConfig.isTranscriptOn = isTranscriptOn !== 'off';
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.topicRef.key + this.topicRef.tabKey,
      () => {
        if (this.topicRef.key === 'quotes') {
          // skip loading
          return;
        }
        // merge tags vs not
        // reuse existing infra?
        const targetLocales = _.uniq([
          this.defaultLocale,
          Locale.EN,
          Locale.ZH_TW
        ]).filter(Boolean);
        // const { history } = rootStore.routerStore;
        // if (this.topicRef.key && this.topicRef.tabKey) {
        //   history.push(`/watch/topic/${this.topicRef.key}/tab/${this.topicRef.tabKey}`);
        // }
        const topicData = this.dataByTopicKey.get(this.topicRef.key) || {};
        // update recommendContextByGroup directly form factory instead
        const queryContextByGroup = createQueryContextByGroupWithTopicTab(
          toJS(topicData),
          this.topicRef.tabKey,
          this.videoActiveSourceLocales
        );
        // TODO extract the get function

        this.videoGroupTitlePrefix.set(format('video.youtube-related'));

        this.recommendContextByGroup.set(queryContextByGroup);
      },
      { fireImmediately: true }
    );

    reaction(
      () => uiStateStore.routeMatch.params.topicKey,
      async (topicKey) => {
        if (!topicKey) {
          return;
        }
        // TODO refactor the structure into init
        this.topicRef.key = topicKey;
        this.navTopicKey.set(topicKey);
        this.watchUiConfig.isRecommendationCuratedOnly = false;
        logger.debug(`topic key udpated, load videos topic ${topicKey}`);
        // const tags = pickTagsByTopicKey(topicKey);
        // TODO tabs
        // TODO potentially put inside ES
        const data = loadDataByTopicDataKey(
          uiStateStore.routeMatch.params.topicKey,
          'init'
        )();
        // TODO refactor the structure into init
        this.topicRef.title = data.title;

        this.deepMergeDataByTopicKey(data);

        ['stats', 'stats-confirmed'].forEach((fetchKey) =>
          loadDataByTopicDataKey(topicKey, fetchKey)().then((data) => {
            logger.debug('fetch data', fetchKey, data);
            this.deepMergeDataByTopicKey(data);
          })
        );

        // make this persistent
        this.watchUiConfig.isRecommendationOn = false;
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.recommendContextByGroupEffective,
      async (contextByGroup) => {
        logger.debug('recommendContextByGroup updated', contextByGroup);
        if (_.isEmpty(contextByGroup)) {
          return;
        }
        const videoByGroup = await videoStore.loadVideosRecommendedByGroup(
          contextByGroup
        );
        this.currentVideoRef.loadRecommendationCount += 1;
        const idsByGroup = _.mapValues(videoByGroup, (videos) =>
          _.map(
            videos.filter(
              (v) =>
                !this.watchUiConfig.isRecommendationCuratedOnly ||
                !v.videoMeta.isHiddenByCurator
            ),
            'id'
          )
        );
        logger.debug('recommendedIdsByGroup merge', idsByGroup);
        this.recommendedIdsByGroup.merge(idsByGroup);
      },
      { fireImmediately: true }
    );

    // https://mobx.js.org/refguide/computed-decorator.html
    // at reactions it will keep compute  even computed keepAlive
    autorun(() => {
      this.tagsBySegmentKeyMemo.replace(this.tagsBySegment);
    });

    // TODO DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS is probably diff by active Locales
    // easier to control than filters?
    reaction(
      () => {
        const { profile } = this.rootStore.userStore.user;
        const defaultTagKeysOfLocales = _.values(
          _.pick(
            DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE,
            profile.activeLocales
          )
        );

        return (
          this.tagsBySegmentKeyMemo.length +
          defaultTagKeysOfLocales.length +
          profile.activeLocales.length
        );
      },
      () => {
        if (this.watchUiConfig.isFilterTagsDirty) {
          return;
        }
        const { profile } = this.rootStore.userStore.user;

        const initTagKeysActive = _.concat(
          _.take(
            this.tagsOfSegments.filter((t) => t.score >= 20),
            5
          ).map((t) => t.key),
          _.flatMap(profile.activeLocales, (l) =>
            _.take(DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE[l], 3)
          )
        );

        if (initTagKeysActive.length > 0) {
          // current design = filter reset tags
          this.videoTagFiltersActiveKeys.replace(initTagKeysActive);
          // suppose trigger reload by the effective tags
        }
      },
      { fireImmediately: true }
    );

    // // won't re-trigger when back to /read
    autorun(async () => {
      // from watch/:video to watch.
      if (
        this.currentVideoRef.loadRecommendationCount > 0 ||
        !(uiStateStore.routeMatch.path || '').startsWith('/watch') ||
        uiStateStore.routeMatch.params.topicKey
      ) {
        return;
      }

      if (
        ((this.currentVideosByGroup.related || []).length === 0 &&
          !_.isEmpty(this.currentVideoRef.id)) ||
        (_.isEmpty(this.currentVideoRef.id) &&
          (this.currentVideosByGroup.trending || []).length === 0)
      ) {
        // break at non watch/ e.g. test case or failing
        // load later on watch/

        const contextByGroup = {};
        if (this.currentVideoRef.id) {
          if (!this.currentVideo) {
            return;
          }
          const functions = [
            _.isEmpty(this.currentVideo.tags) &&
              createContextFunctionWithTerms(
                'video.tags.keyword',
                this.currentVideo.tags,
                2
              ),
            _.isEmpty(getVideoYtTags(this.currentVideo)) &&
              createContextFunctionWithTerms(
                `${VIDEO_YT_TAGS_KEY}.keyword`,
                getVideoYtTags(this.currentVideo),
                5
              )
          ].filter(Boolean);

          _.merge(contextByGroup, {
            related: {
              // isRecentlyPublished: true,
              // subtitlesOfLocales: [Locale.ZH_TW],
              // TODO use body build to generate
              filter: {
                excludeIds: [this.currentVideo.id],
                // 'video.tags.keyword': this.currentVideo.tags,
                isWithSubtitles: true,
                'video.language': [this.currentVideo.primaryLocale],
                // 'video.videoMeta.isShortListed': true,
                'video.videoMeta.isSourcedByCurator': true
              },
              paging: {
                size: 6
              }
            }
          });

          if (!_.isEmpty(functions)) {
            contextByGroup.functions = functions;
          }

          if (!this.watchUiConfig.isRecommendationOn) {
            return;
          }
        }
        this.recommendContextByGroup.set(contextByGroup);
      }
    });
  }

  get defaultLocale() {
    if (this.currentVideo) {
      return this.currentVideo.primaryLocale || Locale.EN;
    }

    // TODO test this
    return null;
  }

  get tagsOfSegments() {
    return _.flatten(_.values(this.tagsOfSegmentShownByLocale));
  }

  // TODO remove or refactor
  get tagsOfSegmentShownByLocale() {
    const { profile } = this.rootStore.userStore.user;

    return _.fromPairs(
      profile.activeLocales.map((locale) => {
        // consider group tags with copywriting
        const segmentKeys = this.effectiveSegmentKeysByLocale[locale];
        const tags = _.flatMap(
          _.pick(this.tagsBySegmentKey, segmentKeys),
          (tags, segmentKey) => tags
        );
        const defaultTagKeys =
          DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE[locale] ||
          DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE[Locale.EN] ||
          [];

        return [
          locale,
          _.concat(
            tags,
            defaultTagKeys.map((key) => ({
              key
            }))
          )
        ];
      })
    );
  }

  get tagsBySegmentKey() {
    const segments = _.flatMap(
      this.effectiveSegmentKeysByLocale,
      (segmentKey) => keyAsSegment(segmentKey)
    );

    // reload by the effective tags
    return createTagsBySegmentKey(segments);
  }

  // user profile + filtesr
  get effectiveSegmentKeysByLocale() {
    const activeLocales = _.get(
      this.rootStore.userStore,
      'user.profile.activeLocales'
    );

    const segments = this.rootStore.userStore.profileSegments || [];

    // TODO add fallback for locale?
    return _.mapValues(
      _.pick(
        _.groupBy(segments, (s) => s.locale),
        activeLocales
      ),
      segmentAsKey
    );
  }

  get targetLocale() {
    return (
      _.first(
        _.difference(this.currentSubtitlesSortedOnLocales, [this.defaultLocale])
      ) || this.rootStore.uiStateStore.app.userLocale
    );
  }

  get currentVideo() {
    return this.rootStore.videoStore.videoById.get(this.currentVideoRef.id);
  }

  get currentDescriptionOfUserLocale() {
    return _.get(this.currentVideo, `descriptionByLocale.${this.targetLocale}`);
  }

  get currentTitleOfTargetLocale() {
    return _.get(this.currentVideo, `titleByLocale.${this.targetLocale}`);
  }

  get currentVideosByGroup() {
    const videoIdsByGroup =
      this.videoTagFiltersActiveKeys.length > 0
        ? this.currentFilteredIdsByGroup
        : this.recommendedIdsByGroup;

    return _.mapValues(toJS(videoIdsByGroup), (videoIds) =>
      videoIds.map((videoId) =>
        this.rootStore.videoStore.videoById.get(videoId)
      )
    );
  }

  get currentSubtitlesByLocale() {
    return (
      this.rootStore.videoStore.subtitlesByLocaleById.get(
        this.currentVideoRef.id
      ) || {}
    );
  }

  get videoTagFiltersActive() {
    return _.intersection(
      this.rootStore.videoStore.videoTagKeys,
      this.videoTagFiltersActiveKeys.toJS()
    );
  }

  get videoTagFiltersInactive() {
    return _.difference(
      this.rootStore.videoStore.videoTagKeys,
      this.videoTagFiltersActiveKeys.toJS()
    );
  }

  get currentSubtitlesSortedLocales() {
    const currentLocales = _.intersection(
      _.keys(this.currentSubtitlesByLocale),
      SUPPORTED_LOCALES
    );
    const defaultLocaleIfExists = _.intersection(
      [this.defaultLocale],
      currentLocales
    );
    const otherLocales = _.difference(currentLocales, [this.defaultLocale]);

    return defaultLocaleIfExists.concat(otherLocales);
  }

  get currentSubtitlesSortedOnLocales() {
    return this.currentSubtitlesSortedLocales.filter((locale) =>
      this.isSubtitlesOnByLocale.get(locale)
    );
  }

  get recommendContextByGroupEffective() {
    return _.mapValues(
      toJS(this.recommendContextByGroup.get()),
      (context, key) =>
        _.merge({}, context, this.languageBaseQueryContext.get())
    );
  }

  // TODO should load in range only
  get quotesCurrentVideo() {
    return _.sortBy(
      _.values(toJS(this.rootStore.tipStore.quoteById)).filter(
        (quote) => quote.video.id === this.currentVideoRef.id
      ),
      (q) => _.get(q, 'video.startSeconds')
    );
  }

  get isTranscriptAvailable() {
    return (
      !!this.currentVideo &&
      [
        this.currentVideo.primaryLocale,
        Locale.EN,
        this.targetLocale,
        Locale.ZH_TW
      ].filter(
        (l) =>
          !!this.parsedCuesByIdLocale.get(
            getKeyWithVideoIdLocale(this.currentVideo.id, l)
          )
      ).length > 0
    );
  }

  get isTranscriptOnAndAvailable() {
    if (!this.currentVideo || !this.watchUiConfig.isTranscriptOn) {
      return false;
    }

    return this.isTranscriptAvailable;
  }
}

decorate(PageVideoStore, {
  currentVideo: computed,
  currentSubtitlesSortedLocales: computed,
  currentVideosByGroup: computed,
  recommendContextByGroupEffective: computed,
  tagsOfSegments: computed,
  tagsOfSegmentShownByLocale: computed,
  tagsBySegmentKey: computed,
  effectiveSegmentKeysByLocale: computed,
  videoTagFiltersActive: computed,
  videoTagFiltersInactive: computed
});

export default PageVideoStore;
