/**
 * Query sources: chatbot, UI
 *
 * Incremental index is possible
 *
 * one-off ajax nature with promise-based sdk, => not using observable interface
 *
 */
import _ from 'lodash';
import bodybuilder from 'bodybuilder';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { from, of, identity, Observable } from 'rxjs';
import Word from '~/domain/wordquest/word/word';
import Definition from '~/domain/wordquest/word/definition';
import striptags from 'striptags';
import rootLogger from '~/app/logger';
import { isNotEmptyArray } from '~/utils';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withIncludeIdsFilter,
  withPaging
} from '~/app/repo-es-util';
import {
  DataEvent,
  createDataEvent,
  WordUpsertedDataEvent,
  DefinitionUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';
import {
  catchError,
  flatMap,
  tap,
  map,
  bufferCount,
  mergeAll,
  toArray
} from 'rxjs/operators';

export type WordEsDoc = {
  id: string;
  word: object;
  locales?: Record<Locale, { definitions: Definition[] }>;
};

export type DefinitionEsDoc = {
  id: string;
  locales: Record<Locale, { definitions: Definition[] }>;
};

const logger = rootLogger.child({ module: 'word-repo' });
// TODO locale should be in index name
export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Word];

export const ES_MAPPING_WORD_BY_LOCALE = {
  [Locale.JA]: {
    // store cannot be specificed. in _source anyway
    // https://www.elastic.co/guide/en/elasticsearch/reference/6.3/object.html
    properties: {
      word: {
        properties: {
          langMeta: {
            enabled: true,
            properties: {
              ja: {
                properties: {
                  furiganaText: {
                    type: 'text'
                  }
                }
              }
            }
          }
        }
      },
      locales: {
        properties: {
          [Locale.EN]: {
            properties: {
              definitions: {
                properties: {
                  gloss: {
                    type: 'text'
                  },
                  source: {
                    type: 'text'
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export type WordQueryContext = BaseQueryContext & {
  fields?: {
    isIdOnly: boolean;
    definitionsLocale?: string;
  };
  terms?: {};
  difficultyLevel?: number;
};

// definitions should be discarded if already exists
export const mapWordAsDoc = (word: Word): WordEsDoc => ({
  id: word.id,
  word: {
    id: word.id,
    langMeta: _.toPlainObject(word.langMeta),
    text: word.text
  },
  // required otherwise .addAll in painless will fail
  locales: {
    [Locale.ZH_TW]: {
      definitions: []
    },
    [Locale.JA]: {
      definitions: []
    },
    [Locale.EN]: {
      definitions: []
    }
  }
});

//
// export const mapDefinitionsAsDocs = (definitions: Definition[]): DefinitionEsDoc => {
//   const [definition] = definitions;
//
//   return {
//     id: definition.wordId,
//     locales: _.mapValues(_.groupBy(definitions, d => d.locale), definitions => definitions.map(_.toPlainObject))
//   };
// };
export const mapDefinitionsAsOps = (definitions: Definition[]): object[] => {
  const definitionByWordId = _.groupBy(definitions, (d) => d.wordId);

  // must be same locale
  return _.flatMap(definitionByWordId, (definitions, wordId) => ({
    id: wordId,
    script: {
      source:
        'ctx._source.locales[params.locale].definitions.addAll(params.definitions)',
      lang: 'painless',
      params: {
        locale: _.first(definitions).locale,
        definitions
      }
    }
  }));
};

// export const mapWordDefinitionsAsDoc = (word: Word, definitions: Definition[], locale: Locale): object => ({
//   word: mapWordAsDoc(word),
//   locales: {
//     [locale]: definitions.map(mapDefinitionAsDoc)
//   }
// });

type WordDefinitionsDoc = {
  _id: string;
  _source: { word: object; locales: object };
};

export const asWord = (doc: WordDefinitionsDoc) =>
  Word.create(_.merge({ id: doc._id }, doc._source.word));

export const mapDocAsDefinitions = (targetLocale: Locale, doc: object) =>
  _.get(doc, `_source.locales.${targetLocale}.definitions`, []).map((d) =>
    Definition.create(
      _.merge(
        {
          wordId: doc._source.id,
          locale: targetLocale
        },
        d
      )
    )
  );

export const buildWordQuery = (
  locale: Locale,
  context: WordQueryContext
): EsQuery => {
  let body = bodybuilder();
  if (!_.isEmpty(context.fields)) {
    let sourceFields = [];
    if (context.fields.isIdOnly) {
      sourceFields = ['id'];
    }
    body = body.rawOption('_source', sourceFields);
  }

  // use same size as ids
  body = withPaging(
    _.defaultsDeep(context, {
      paging: {
        size: _.get(context, 'fields.isIdOnly') ? 500 : 50
      }
    })
  )(body);

  body = withIncludeIdsFilter(context)(body);

  const maxNormalizedFrequencyThreshold = _.get(
    context,
    'filter.maxNormalizedFrequencyThreshold'
  );
  if (_.isNumber(maxNormalizedFrequencyThreshold)) {
    body = body.notQuery('range', 'word.langMeta.normalizedFrequency', {
      gt: maxNormalizedFrequencyThreshold
    });
  }

  return body.build();
};

export const createContextByWordIds = (
  wordIds: string[],
  context?: WordQueryContext
) =>
  _.merge({}, context || {}, {
    filter: {
      includeIds: wordIds
    }
  });

// Array instead of map by id = easier to zip
// Intentional two function (requests) to suggest progressive loading
export const queryDefinitionsWithLocaleWordIds = (
  locale: Locale,
  wordIds: string[],
  context: WordQueryContext
) =>
  from(wordIds).pipe(
    bufferCount(50),
    map((wordIds) => {
      const effectiveContext = createContextByWordIds(wordIds, context);
      if (!locale) {
        throw new Error('queryWordsWithLocaleIds requires locale');
      }
      const query = buildWordQuery(locale, effectiveContext);
      logger.debug(
        '[Debug]queryDefinitionsWithLocaleWordIds query & context',
        query,
        effectiveContext
      );

      return query;
    }),
    toArray(),
    tap((queries) =>
      logger.debug('queryDefinitionsWithLocaleWordIds msearch', queries.length)
    ),
    flatMap((queries) =>
      msearch(
        ES_CONFIG.APP_CONTENT,
        // @ts-ignore
        queries.map((query) => [ES_INDEX_BY_LOCALE[locale], query])
      )
    ),
    // tap(results => logger.trace('queryDefinitionsWithLocaleWordIds', results)),
    mergeAll(1),
    mergeAll(1),
    flatMap((doc) => {
      const targetLocale =
        _.get(context, 'fields.definitionsLocale') || Locale.EN;

      return mapDocAsDefinitions(targetLocale, doc);
    })
  );

export const queryWordsWithLocaleIds = (
  locale: Locale,
  wordIds: string[],
  context?: WordQueryContext
): Observable<Record<string, Word>> =>
  from(wordIds).pipe(
    bufferCount(50),
    map((wordIds) => {
      const effectiveContext = createContextByWordIds(wordIds, context);
      if (!locale) {
        throw new Error('queryWordsWithLocaleIds requires locale');
      }
      const query = buildWordQuery(locale, effectiveContext);
      logger.debug(
        '[Debug]queryWordsWithLocaleIds query & context',
        query,
        effectiveContext
      );

      return query;
    }),
    toArray(),
    tap((queries) =>
      logger.debug('queryWordsWithLocaleIds msearch', queries.length)
    ),
    flatMap((queries) =>
      msearch(
        ES_CONFIG.APP_CONTENT,
        // @ts-ignore
        queries.map((query) => [ES_INDEX_BY_LOCALE[locale], query])
      )
    ),
    mergeAll(1),
    mergeAll(1),
    map(asWord)
  );

export const queryWordsWithTokens = (
  locale: Locale,
  wordTokens: string[],
  context?: WordQueryContext
) => {
  const effectiveContext = _.merge(context, {
    fields: {
      isIdOnly: true
    },
    filter: {
      maxNormalizedFrequencyThreshold: 500
    }
  });

  return queryWordsWithLocaleIds(locale, wordTokens, effectiveContext);
};

export const queryWordIdsWithTokens = (
  locale: Locale,
  wordTokens: string[],
  context?: WordQueryContext
) =>
  queryWordsWithTokens(locale, wordTokens, context).pipe(
    map((w) => w.id),
    toArray()
  );

export const createSimilarQueries = (
  locale: Locale,
  wordIds: string[],
  similarFields: string[],
  context?: WordQueryContext
): EsQuery[] =>
  wordIds.map((wordId) => {
    let body = bodybuilder().query('more_like_this', {
      fields: similarFields || ['word.text'],
      like: [
        {
          _index: ES_INDEX_BY_LOCALE[locale],
          _id: wordId
        }
      ],
      min_term_freq: 1,
      max_query_terms: 25,
      min_doc_freq: 0
    });

    body = withPaging(
      _.defaultsDeep(context, {
        paging: {
          size: 5
        }
      })
    )(body);

    return body.build();
  });

// Note: Potentially got exact match e.g. 日本,日本,日本
// mostly for finding distractor in MC
// MLT is more generic.
// 2 requests is fine. some chances original words cached alread. here only for similar by wordIds
export const querySimilarWords = (
  locale: Locale,
  wordIds: string[],
  similarFields: string[],
  context?: WordQueryContext
): Record<string, Word[]> => {
  const queries = createSimilarQueries(locale, wordIds, similarFields, context);
  logger.debug(queries);

  return msearch(
    ES_CONFIG.APP_CONTENT,
    // @ts-ignore
    queries.map((query) => [ES_INDEX_BY_LOCALE[locale], query])
  ).then((results) => _.zipObject(wordIds, results.map(asWord)));
};

// while ensure the gloss not exactly the same
export const querySimilarDefinitions = (
  locale: Locale,
  wordIds: string[],
  context?: WordQueryContext
): Promise<Definition[][]> => {
  const targetLocale =
    _.get(context, 'fields.definitionsLocale') || Locale.ZH_TW;

  const queries = createSimilarQueries(
    locale,
    wordIds,
    [`locales.${targetLocale}.definitions.gloss`],
    context
  );
  logger.debug(queries);

  return msearch(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_LOCALE[locale],
    queries
  ).then((results) =>
    results.map((docs) =>
      _.flatMap(docs.map(mapDocAsDefinitions.bind(null, targetLocale)))
    )
  );
};

export const upsertDefinitionsByLocale =
  (locale: Locale) => (definitions: Definition[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      mapDefinitionsAsOps(definitions),
      {
        timeout: '4m'
      }
    );

export const indexWordsByLocale = (locale: Locale) => (words: Word[]) =>
  indexDocBulk(
    ES_CONFIG.APP_CONTENT_ADMIN,
    ES_INDEX_BY_LOCALE[locale],
    words.map(mapWordAsDoc),
    {
      timeout: '4m'
    }
  );

// handy alias
export const indexWordUpsertedEventByLocale =
  (locale: Locale) => (events: WordUpsertedDataEvent[]) =>
    from(indexWordsByLocale(locale)(events.map((e) => e.properties.word)));

export const upsertDefinitionUpsertedDataEventsByLocale =
  (locale: Locale) => (events: DefinitionUpsertedDataEvent[]) =>
    from(
      upsertDefinitionsByLocale(locale)(
        events.map((e) => e.properties.definition)
      )
    );

// TODO deprecate
// extra mapping but useful when decouple
export const upsertWordsAndDefinitions = ({
  locale,
  words: wordEvents,
  definitions: definitionEvents
}) =>
  indexWordUpsertedEventByLocale(locale)(wordEvents).pipe(
    flatMap((result) =>
      upsertDefinitionUpsertedDataEventsByLocale(Locale.EN)(definitionEvents)
    ),
    catchError((err) => {
      console.log(
        'upsert definition err',
        err,
        JSON.stringify(definitionEvents, null, 4)
      );

      return of({});
    })
  );
