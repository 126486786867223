// For (multiple) consumer to upsert at db e.g. ElasticSearch / Firestore i.e. NOT eventstore
// Not expected to pass via app-event / segment, unless converted into AppEvent

// problem of entity / action type safety: potentially by config
// e.g. Elasticsearch when write at bulk WordWithDefinitions, Update
// Potential extensions: version
// such that replay with transform is possible  e.g. upstream unchanged but downstream schema changed

// Should be aggregate root e.g. word in case of ES, while can be definition in FS

import _ from 'lodash';
import { parse } from 'date-fns';
import { Entity, Action, DataAction } from '~/domain/wordquest/entity';
import Article, { AnalyzedArticle } from '~/domain/wordquest/article';
import Word from '~/domain/wordquest/word/word';
import Definition from '~/domain/wordquest/word/definition';
import Quest from '~/domain/wordquest/quest/quest';
import Video from '~/domain/wordquest/video/video';
import Course from '~/domain/wordquest/course/course';
import Material from '~/domain/wordquest/course/material';
import Quote from '~/domain/wordquest/quote/quote';
import CourseIntro from '~/domain/wordquest/course/course-intro';
import Lesson from '~/domain/wordquest/course/lesson';
import { VideoSubtitlesEntry } from '~/domain/wordquest/video/video-subtitles';
import { Locale } from '@wordquest/locales';
import Glossary from '../glossary';

export type DataEvent<E extends Entity, A extends DataAction, P> = {
  entity: E;
  action: A;
  actionAt: Date;
  properties: P;
};

export function createDataEvent<
  T extends DataEvent<Entity, DataAction, object>
>(doc: {
  entity: T['entity'];
  action: T['action'];
  properties: T['properties'];
}): T {
  return Object.assign(doc, {
    actionAt: new Date()
  }) as T;
}

export type ArticleUpsertedDataEvent = DataEvent<
  Entity.Article,
  DataAction.Upserted,
  {
    article: Article | AnalyzedArticle;
  }
>;

export type WordUpsertedDataEvent = DataEvent<
  Entity.Word,
  DataAction.Upserted,
  { word: Word }
>;

export type DefinitionUpsertedDataEvent = DataEvent<
  Entity.Definition,
  DataAction.Upserted,
  { definition: Definition }
>;

export type QuestUpsertedDataEvent = DataEvent<
  Entity.Quest,
  DataAction.Upserted,
  {
    quest: Quest;
  }
>;

export type VideoUpsertedDataEvent = DataEvent<
  Entity.Video,
  DataAction.Upserted,
  {
    video: Video;
  }
>;

export type QuoteUpsertedDataEvent = DataEvent<
  Entity.Quote,
  DataAction.Upserted,
  {
    quote: Quote;
  }
>;

export type GlossaryUpsertedDataEvent = DataEvent<
  Entity.Glossary,
  DataAction.Upserted,
  {
    glossary: Glossary;
  }
>;

// decoupled the event for easier typing & creation
export type VideoTitleDescriptionUpsertedDataEvent = DataEvent<
  Entity.Video,
  DataAction.Upserted,
  {
    video: {
      id: string;
    };
    actionBy: string;
    // actionPriority: number,
    description: {
      locale: Locale;
      title: string;
      description: string;
    };
    isSourceLocale: boolean;
  }
>;

// decoupled the event for easier typing & creation
export type VideoSubtitlesUpsertedDataEvent = DataEvent<
  Entity.Video,
  DataAction.Upserted,
  {
    video: {
      id: string;
    };
    // outside of subtitles as indepdent of locale
    // 1-10 to determine will that be overriden
    actionBy: string;
    actionPriority: number;
    isSourceLocale: boolean;
    subtitles: VideoSubtitlesEntry;
  }
>;

export type VideoSubtitlesAnalyzedDataEvent = DataEvent<
  Entity.Video,
  DataAction.Upserted,
  {
    video: {
      id: string;
    };
    locale: Locale;
    cuesWithWqDom?: any;
    tagged?: string;
    analyzedAt: Date;
    tokenInfoByToken?: Record<string, any>;
  }
>;

export type CourseUpsertedDataEvent = DataEvent<
  Entity.Course,
  DataAction.Upserted,
  {
    course: Course;
  }
>;

export type CourseIntroUpsertedDataEvent = DataEvent<
  Entity.Course,
  DataAction.Upserted,
  {
    courseIntro: CourseIntro;
  }
>;

export type LessonUpsertedDataEvent = DataEvent<
  Entity.Course,
  DataAction.Upserted,
  {
    courseKey: string;
    lesson: Lesson;
  }
>;

export type MaterialUpsertedDataEvent = DataEvent<
  Entity.Quest,
  DataAction.Upserted,
  {
    material: Material;
  }
>;
