/* global window */
import _ from 'lodash';
import { getFallbackUserId } from '@wordquest/lib-iso/adapters/analytics';
import { toJS, isObservableObject } from 'mobx';

const track = (data, options) => {
  // https://github.com/vercel/next.js/issues/5354#issuecomment-520305040/
  // using ref of window itself will create ReferenceError
  // global.window works on both browser & node
  let window;
  if (global.document) {
    window = global;
  } else if (global) {
    window = global.window;
  }

  if (!window || !window.analytics) {
    console.log('no analytics');

    return;
  }
  const doTrackWhenReady = (rawData, user, options) => {
    // null if anon
    const existingUserId = user.id();
    const data = isObservableObject(rawData) ? toJS(rawData) : rawData;
    console.log('doTrack', data, user.id(), options);
    window.events = window.events || [];
    // TODO debounce with rxJs
    window.events.push(data);

    const userId = existingUserId || getFallbackUserId();
    window.analytics.identify(userId);

    if (_.isArray(_.get(options, 'plugins'))) {
      options.plugins.forEach((plugin) => {
        if (!plugin) {
          return;
        }
        try {
          const traits = user.traits();
          const identifyResult = plugin.identify(userId, traits);
          const trackResult = plugin.track(data);
        } catch (err) {
          console.log('track err with plugin', (plugin || {}).name, err);
        }
      });
    }

    // firing just fbq instead of via segment (for large volume site)
    if (window.fbq) {
      window.fbq('track', data.event, data);
    }
    if (data.page && data.event === 'PageViewed') {
      console.log(`[Event]PageViewed ${new Date()}`, data);
      window.analytics.page(data.page, data);
    } else {
      console.log(`[Event]${data.event} ${new Date()}`, data);
      window.analytics.track(data);
    }
  };
  // .user & .group are available after ready i.e. not yet during pageView
  const createDoTrack = () => {
    const user = window.analytics.user();
    // const id = user.id();
    const traits = user.traits();
    // case of not yet identifyed, id is null & traits is {}
    // at identify, profile picked into traits
    _.merge(data.properties || {}, traits);
    console.log('track, after window.analytics ready', user);
    doTrackWhenReady(data, user, options);
  };

  const wrapper = window.analytics.initialized
    ? _.identity
    : (cb) => () => window.analytics.ready(cb);

  const trackOnReady = wrapper(createDoTrack);
  trackOnReady();

  // i.e. outside of segment traits not available

  if ((data.event || '').startsWith('Ops') && window.ga) {
    // temp hack
    window.ga('send', {
      hitType: 'timing',
      timingCategory: 'Render',
      timingVar: data.event,
      timingValue: (data.properties || {}).durationInMs || 0
    });
  }
};

export default track;
