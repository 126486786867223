import { createMachine, interpret, assign, send, actions } from 'xstate';
import _ from 'lodash';
// import firebase, { signInWithProfile } from '~/services/firebase';
import logger from '@wordquest/lib-iso/app/logger';

const { pure } = actions;
export enum AuthStepState {
  SignedOutWithMessageOnly = 'SignedOutWithMessageOnly',
  // we keep social enable model at mobx
  SignedOutWithOptions = 'SignedOutWithOptions',
  OpenedLoading = 'OpenedLoading',
  // LoggedIn = 'LoggedIn',
  EmailInput = 'EmailInput',
  LoggedInProfileDetails = 'LoggedInProfileDetails',
  LoggedInProfileSocialConnect = 'LoggedInProfileSocialConnect'

  // LOGOUT_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
}

const isUserLoaded = (context, event) => !!context.user;
const isUserNotLoaded = (context) => !context.user;

export enum AuthStepEvent {
  LoginWithEmail = 'LOGIN_WITH_EMAIL',
  EmailRequested = 'EMAIL_REQUESTED',
  // e.g. check your email again, contact support
  OpenProfileSocialConnect = 'OPEN_PROFILE_SOCIAL_CONNECT',
  OpenProfileDetails = 'OPEN_PROFILE_DETAILS',
  // let system decide
  LoginSuccess = 'LOGIN_SUCCESS',
  // LOGOUT_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
  FailWithMessageOnly = 'FailWithMessageOnly'
}

export const initAuthStepStateService = (rootStore, uiStateStore) =>
  interpret(authStepStateMachine)
    .onTransition((state) => {
      logger.debug(
        '[authStepState]transition from:',
        uiStateStore.app.authStepState.value,
        'to:',
        state.value
      );
      _.merge(uiStateStore.app.authStepState, state);
    })
    .start();

export const authStepStateMachine = createMachine(
  {
    id: 'auth-step-state',
    initial: AuthStepState.SignedOutWithOptions,
    context: {
      user: null
    },
    // will be overriden by individual states
    on: {
      [AuthStepEvent.LoginSuccess]: {
        target: AuthStepState.OpenedLoading,
        internal: true,
        actions: 'profileLoaded'
      },
      [AuthStepEvent.EmailRequested]: {
        internal: true,
        target: AuthStepState.EmailInput
      }
    },
    states: {
      [AuthStepState.SignedOutWithOptions]: {
        on: {
          // either close it or go to profile
          // no re-login options displayed
          [AuthStepEvent.FailWithMessageOnly]:
            AuthStepState.SignedOutWithMessageOnly,
          // [AuthStepEvent.LoginSuccess]: AuthStepState.OpenedLoading,
          [AuthStepEvent.EmailRequested]: AuthStepState.EmailInput
        }
      },
      [AuthStepState.SignedOutWithMessageOnly]: {
        on: {}
      },
      [AuthStepState.EmailInput]: {
        on: {
          [AuthStepEvent.LoginWithEmail]: AuthStepState.OpenedLoading
        }
      },
      [AuthStepState.LoggedInProfileDetails]: {
        on: {}
      },
      [AuthStepState.LoggedInProfileSocialConnect]: {
        on: {}
      },
      // To support explicit screen loading without going through first screen
      [AuthStepState.OpenedLoading]: {
        always: [
          {
            target: AuthStepState.LoggedInProfileSocialConnect,
            cond: isUserLoaded
          },
          { target: AuthStepState.SignedOutWithOptions, cond: isUserNotLoaded }
          // { target: 'lose', cond: 'didPlayerLose' }
        ],
        on: {
          // [AuthStepEvent.OpenProfileSocialConnect]: AuthStepState.LoggedInProfileSocialConnect,
          // [AuthStepEvent.OpenProfileDetails]: AuthStepState.LoggedInProfileDetails,
          // [AuthStepEvent.Complete]: [AuthStepState.ProfileSocialConnect],
          // [AuthStepEvent.EmailRequested]: AuthStepState.EmailInput
        },
        actions: (context, event) => {
          console.log('opened loading', context);
        }
      }
    }
  },
  {
    actions: {
      profileLoaded: assign({
        // translations if any
        user: (context, event) => event.payload.user
      })
    }
  }
);
