import _ from 'lodash';
import { ObservableMap, observable, toJS } from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';
import { loadWithIdsWithMobxCache } from '~/services/mobx-cache';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import RootStore from '~/stores/root';
import { queryQuotesWithLocaleContext } from '@wordquest/lib-iso/app/quote/quote-repo';

import Video, {
  VideoPlatform
} from '@wordquest/lib-iso/domain/wordquest/video/video';
import { parsePodcastKeyAsItunesId } from '@wordquest/lib-iso/domain/wordquest/podcast/podcast';

import {
  queryVideosWithContexts,
  queryVideosWithLocaleContext,
  queryVideoSubtitlesWithLocaleContext
} from '@wordquest/lib-iso/app/video/video-repo';

// always pre-fill filter to create segment is better than default active tags
// segment shoudl affect both 1) tag is active 2) tag is shown
// if we need default tags due to limited tags from role/proficiency/segments, always as selected and won't go away when user change filter

// should be smaller than limit
export const DEFAULT_RECOMMENDED_ACTIVE_TAG_KEYS_BY_LOCALE = {
  [Locale.EN]: [
    'reactjs',
    'mozilla',
    'series-rxjs',
    'machine-learning',
    'series-centered'
  ],

  [Locale.KO]: ['kbs-documentary'],
  [Locale.JA]: ['japanese-literature', 'japan-travel']
};

// Similar to video-fetch.js at site but not exactly
export const DEFAULT_RECOMMENED_CONTEXTS_BY_GROUP = {
  trending: {
    isRecentlyPublished: true,
    subtitlesOfLocales: [Locale.EN, Locale.ZH_TW],
    filter: {
      'video.tags.keyword': [
        'reactjs',
        'openup',
        'mozilla',
        'series-rxjs',
        'machine-learning'
      ],
      isWithSubtitles: true,
      'video.language': [Locale.EN],
      'video.videoMeta.isShortListed': true,
      'video.videoMeta.isSourcedByCurator': true
    },
    paging: {
      size: 4
    }
  },
  popular: {
    // TODO explict video creation time
    sort: [{ 'video.videoPlatformMeta.youtube.statistics.viewCount': 'desc' }],
    subtitlesOfLocales: [Locale.EN, Locale.ZH_TW],
    filter: {
      isWithSubtitles: true,
      'video.videoMeta.isShortListed': true,
      'video.videoMeta.isSourcedByCurator': true
    },
    paging: {
      size: 20
    }
  },
  openup: {
    filter: {
      'video.tags.keyword': 'openup'
    }
  },
  'series-centered': {
    filter: {
      'video.tags.keyword': 'series-centered'
    }
  },
  mozilla: {
    filter: {
      'video.tags.keyword': 'mozilla'
    }
  },
  reactjs: {
    filter: {
      'video.tags.keyword': 'reactjs'
    }
  },
  google: {
    filter: {
      'video.tags.keyword': 'google'
    }
  },
  design: {
    filter: {
      'video.tags.keyword': 'design'
    }
  }
};

class VideoStore {
  // decoupled & link by id
  videoById = observable.map({});

  quoteByVideoId = observable.map({});

  // used also at index to be shown in video cards
  videoTagKeys = observable([]);

  // playback as domain stores, as potentially sync across multiple players
  //  playback: youtubePlayback,
  videoPlaybackById: ObservableMap<string, VideoPlayback> = observable.map({});

  // decoupled becoz static (no playback) can also use subtitles
  subtitlesByLocaleById = observable.map({});

  constructor(private rootStore: RootStore) {
    const { uiStateStore, tipStore } = rootStore;

    this.loadVideoTags = async () => {
      const tagsModule = await import(
        '@wordquest/lib-static/dist/video-tags.json'
      );
      const tags = (tagsModule || {}).default || [];
      logger.trace('tags', tags);
      this.videoTagKeys.replace(tags);

      return tags;
    };

    this.loadVideoTags();

    this.getOrCreateVideoPlayback = (videoId) => {
      let playback = this.videoPlaybackById.get(videoId);
      if (!playback) {
        playback = observable({
          durationInSeconds: 0,
          playing: true,
          isStarted: false,
          playedSeconds: 0,
          pendingSeek: null,
          isSeeking: false,
          loaded: 0,
          controls: true,
          isRepeatingCurrentCue: false
        });
        this.videoPlaybackById.set(videoId, playback);
      }

      return playback;
    };

    // TODO generic data
    // itunes id
    this.loadPodcastEpisodesWithPodcastKey = async (key) => {
      if (!key) {
        return [];
      }
      const itunesId = parsePodcastKeyAsItunesId(key);
      const context = {
        filter: {
          // isHiddenByCurator: false
          isWithSubtitles: false,
          'video.platform': 'podcast',
          [`video.videoPlatformMeta.${VideoPlatform.Podcast}.itunesId`]:
            itunesId
        }
      };
      const videos = await queryVideosWithLocaleContext(Locale.EN, context);
      this.mergeVideoById(_.keyBy(videos, 'id'));

      return videos;
    };

    // TODO more context
    // potentially add ids to skip for performance (& re-use cache)
    this.loadVideoIdsByGroupWithTags = async (tags = []) => {
      if (_.isEmpty(tags)) {
        return {};
      }
      const context = {
        filter: {
          'video.tags.keyword': tags
        }
      };

      const videos = await queryVideosWithLocaleContext(Locale.EN, context);
      this.mergeVideoById(_.keyBy(videos, 'id'));

      // pbm which tag to use
      // since video: tags is 1:M, use desired tags to group
      return _.zipObject(
        tags,
        tags.map((tag) =>
          videos
            .filter((video) => _.includes(video.tags, tag))
            .map((video) => video.id)
        )
      );
    };

    this.loadRecommendedVideos = async (contextByGroup) => {
      logger.debug('loadRecommendedVideos', toJS(contextByGroup));
      const contextByGroupToLoad = _.isEmpty(contextByGroup)
        ? DEFAULT_RECOMMENED_CONTEXTS_BY_GROUP
        : contextByGroup;
      const videosByGroup = await queryVideosWithContexts(
        Locale.EN,
        _.values(contextByGroupToLoad)
        // disabled as many not yet analyzed
        // .map(withShortListedContext)
      ).then((videoGroups) =>
        _.zipObject(_.keys(contextByGroupToLoad), videoGroups)
      );

      // dirty trick to avoid duplication
      if (videosByGroup.popular) {
        videosByGroup.popular = videosByGroup.popular.filter((video) =>
          _.every(
            videosByGroup.trending,
            (trendingVideo) => trendingVideo.id !== video.id
          )
        );
      }

      return videosByGroup;
    };
    // deprecated
    this.loadRecommendedVideoGroups = async (locale, contextByGroup) => {
      const videosByGroup = await this.loadRecommendedVideos(contextByGroup);

      return videosByGroup;
    };

    this.loadVideosRecommendedByGroup = async (contextByGroup) => {
      logger.debug('loadVideosRecommendedByGroup', contextByGroup);
      let _doLoadVideoByGroup = () =>
        this.loadRecommendedVideoGroups(Locale.EN, contextByGroup);

      if (uiStateStore.uiConfig.mock.video) {
        console.log('ui mock video');
        _doLoadVideoByGroup = async () => {
          const { loadVideoByIdMock } = await import('~/debug-mock');
          const videoById = await loadVideoByIdMock();

          return _.groupBy(
            videoById,
            (video) => _.first(video.tags) || 'other'
          );
        };
      }
      // await this.loadSubtitlesByLocaleById();
      const videoByCategory = await _doLoadVideoByGroup();
      this.mergeVideoById(_.keyBy(_.flatten(_.values(videoByCategory)), 'id'));

      return videoByCategory;
    };

    this.mergeVideoById = (videoById: Record<string, Video>) => {
      // simple merge now. TBC
      logger.debug(
        'mergeVideoByIds',
        this.videoById.size,
        _.keys(videoById).length,
        _.keys(videoById)
      );
      // not supposed to change for now, omit existing
      this.videoById.merge(videoById);
      // this.videoById.merge(_.omit(videoById, _.keys(toJS(this.videoBsyId))));
      logger.debug(
        'mergeVideoByIds result',
        this.videoById,
        this.videoById.keys()
      );
    };

    this.loadSubtitlesByLocaleById = async (
      ids: string[],
      subtitlesOfLocales = SUPPORTED_LOCALES
    ) => {
      let _doLoadSubtitlesWithIds = (ids) =>
        queryVideoSubtitlesWithLocaleContext(Locale.EN, {
          subtitlesOfLocales,
          filter: {
            // load regardless
            isWithSubtitles: false,
            includeIds: ids
          }
        });

      if (uiStateStore.uiConfig.mock.video) {
        _doLoadSubtitlesWithIds = async () => {
          const { loadSubtitlesByLocaleByIdMock } = await import(
            '~/debug-mock'
          );

          return loadSubtitlesByLocaleByIdMock();
        };
      }
      const videoIdSubtitlesByLocalePairs = await _doLoadSubtitlesWithIds(ids);

      const updatedSubtitlesByLocaleById = _.fromPairs(
        _.map(videoIdSubtitlesByLocalePairs, ([id, videoSubtitlesByLocale]) => [
          id,
          _.merge(
            {},
            this.subtitlesByLocaleById.get(id),
            videoSubtitlesByLocale
          )
        ])
      );

      this.subtitlesByLocaleById.merge(updatedSubtitlesByLocaleById);

      return this.subtitlesByLocaleById;
    };

    this.loadVideoWithIds = async (
      ids: string[]
    ): Promise<Map<string, Video>> => {
      const loadVideoById = (ids: string[]) =>
        from(
          queryVideosWithLocaleContext(Locale.EN, {
            // pbm: TODO for recommendation need load it explicitly
            // TODO other locales
            descriptionsOfLocales: [Locale.EN, Locale.ZH_TW, Locale.JA],
            fields: {
              isWithLocales: true
            },
            filter: {
              includeIds: ids,
              isWithSubtitles: false
            }
          })
        ).pipe(map((videos) => _.keyBy(videos, (v) => v.id)));
      // Edge case here: we need manual mobx merge so disable auto cache merge
      let _doLoadVideosWithIds = async (ids: string[]) =>
        loadWithIdsWithMobxCache(
          ids,
          loadVideoById,
          this.videoById,
          false
        ).toPromise();

      if (uiStateStore.uiConfig.mock.video) {
        _doLoadVideosWithIds = async () => {
          const { loadVideoByIdMock } = await import('~/debug-mock');

          return loadVideoByIdMock();
        };
      }
      const videos = await _doLoadVideosWithIds(ids);
      const videoById = _.keyBy(videos, 'id');
      this.mergeVideoById(videoById);

      return this.videoById;
    };

    this.loadQuotesWithVideoId = async (videoId: string) => {
      if (!videoId) {
        return;
      }
      logger.debug('loadQuotesWithVideoId quotes', videoId);
      await tipStore.lazyLoadQuoteWithContext({
        filter: {
          quote: {
            video: {
              id: videoId
            }
          }
        }
      });
    };
  }
}

export default VideoStore;
