/**
 * Not in profile class for easier-extension pattern (esp from other packages)
 */
import _ from 'lodash';
import { buildCleaner } from 'lodash-clean';
import firebase from 'firebase';
import Profile from './profile';
import { Platform } from '~/domain/wordquest/platform';

export type FirebaseProfile = {
  uid?: string;
  displayName?: string;
  email?: string;
  photoURL?: string;
};

// https://developers.line.biz/en/reference/liff/
export type LineProfile = {
  userId?: string;
  displayName?: string;
  pictureUrl?: string;
  statusMessage?: string;
};

export const fromFirebase = ({
  uid,
  displayName,
  email,
  photoURL,
  providerData,
  ...user
}: FirebaseProfile) => {
  const fbProfile =
    _.find(
      providerData,
      (p) => p.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ) || {};
  const googleProfile =
    _.find(
      providerData,
      (p) => p.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ) || {};

  const idFb = fbProfile.uid;
  const nameFb = fbProfile.displayName;

  const idGoogle = googleProfile.uid;
  const nameGoogle = googleProfile.displayName;

  const photoURLFb = fbProfile.photoURL;
  const photoURLGoogle = googleProfile.photoURL;

  return Profile.create({
    id: uid,
    links: ['firebase'],
    name: displayName,
    email,
    idFb,
    idGoogle,
    nameFb,
    nameGoogle,
    photoURL,
    photoURLFb,
    photoURLGoogle,

    ..._.pick(user, [
      'emailVerified',
      'metadata',
      'multiFactor',
      'customClaims'
    ]),
    get idFirebase() {
      return uid;
    }
  });
};

// TODO double check linebot vs original login at v2
export const fromLine = ({
  userId,
  displayName,
  pictureUrl,
  statusMessage
}: LineProfile) =>
  Profile.create({
    id: `${Platform.Line}-${userId}`,
    links: [Platform.Line],
    name: displayName,
    photoURL: pictureUrl,
    photoURLLine: pictureUrl,
    get idLine() {
      return userId;
    }
  });

// https://developers.facebook.com/docs/messenger-platform/webview/context/
export type FbmThreadContext = {
  thread_type: string;
  tid: string;
  psid: string;
  signed_request: string;
};

// Fbm is by pagescope. we do single page now anyway
export const fromFbm = (doc: { id: string }) => {
  const { id } = doc;

  return Profile.create(
    _.merge(doc, {
      id: `temp-${Platform.Fbm}-${id}`,
      links: [Platform.Fbm],

      get idFbm() {
        return id;
      }
    })
  );
};

export const fromFbmThreadContext = ({ psid }: FbmThreadContext) =>
  fromFbm({ id: psid });

const clean = buildCleaner({
  isArray: _.identity,
  isNull: _.noop,
  isUndefined: _.noop
});

// accept empty values
// https://stackoverflow.com/questions/19839952/all-falsey-values-in-javascript/19839953#19839953
export const asPartialDoc = (profile: Profile) =>
  clean(_.omitBy(profile, _.isFunction));
