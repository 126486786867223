/**
 * Used by client side
 * Multiple resources in nature, not exactly a repo
 *
 *
 * Responses:
 * - ES representation of Word, Article, Videos etc
 * - Analyzed words (google api)
 *
 * Balance for reponse latency vs usability
 * - supports Progressive updates by just ids
 *
 */
import _ from 'lodash';
import striptags from 'striptags';
import rootLogger from '~/app/logger';
import { Entity } from '~/domain/wordquest/entity';
import { EsQuery, search, msearch } from '~/adapters/elasticsearch';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { Locale } from '@wordquest/locales';
import bodybuilder from 'bodybuilder';
import {
  FFHttpRequest,
  TextAnalyzePOST,
  createFetchParams,
  HttpEndpointName
} from '~/app/firebase-function-https';
import { from } from 'rxjs';
import fetch from 'isomorphic-fetch';
import {
  BaseQueryContext,
  withPaging,
  withIncludeIdsFilter,
  withTermsFilter
} from '~/app/repo-es-util';
import { buildWordQuery, asWord } from '~/app/word/word-repo';
import { buildArticleQuery, asArticle } from '~/app/article/article-repo';

const logger = rootLogger.child({ module: 'search' });

type SearchQueryContext = BaseQueryContext & {
  // TODO types e.g. words only
};

// Analyze w/ Google annotate: min. ~3.5 seconds
// too slow as primary results
// also could be diff from ES tokenize search

export const getIndexQueryPairs = (context, locale, text) => {
  const indexQueryPairsByEntity = {
    [Entity.Word]: [
      ES_INDEX_BY_ENTITY_LOCALE[Entity.Word][locale],
      buildWordQuery(locale, {
        terms: {
          text
        }
      })
    ],
    [Entity.Article]: [
      ES_INDEX_BY_ENTITY_LOCALE[Entity.Article][locale],
      buildArticleQuery(locale, {
        terms: {
          title: text,
          content: text
        }
      })
    ],
    [Entity.Video]: [
      ES_INDEX_BY_ENTITY_LOCALE[Entity.Video][locale],
      buildArticleQuery(locale, {
        terms: {
          title: text,
          description: text
        }
      })
    ]
  };
  let indexQueryPairs = _.values(indexQueryPairsByEntity);

  let parseResults = ([wordHits, articleHits]) => ({
    words: wordHits.map(asWord),
    articles: articleHits.map(asArticle)
  });

  if (context.isHelpdeskOnly) {
    indexQueryPairs = [
      [
        ES_INDEX_BY_ENTITY_LOCALE[Entity.Article][locale],
        buildArticleQuery(locale, {
          terms: {
            title: text,
            tagKeys: ['guide-teacher']
          },
          isIgnorePublishedAt: true,
          isTermsAMust: true
        })
      ]
    ];

    parseResults = ([articleHits]) => ({
      articles: articleHits.map(asArticle)
    });
  }

  return {
    indexQueryPairs,
    parseResults
  };
};

export const queryWithText = (
  text: string,
  context: SearchQueryContext = {}
) => {
  const locale = Locale.EN;

  // not using multi-index:
  // hard to tune single query fitting different index
  // less necessary to rank hetergrous results
  // TODO validate againast unsafe text
  // msearch vs multi-index
  // msearch: easier to decouple in future
  const { indexQueryPairs, parseResults } = getIndexQueryPairs(
    context,
    locale,
    text
  );
  logger.debug(text, indexQueryPairs);

  return from(
    msearch(ES_CONFIG.APP_CONTENT, indexQueryPairs)
      .then((results) => parseResults(results))
      .catch((err) => {
        // silent failure
        logger.error(err);
      })
  );
};

export const queryAnalyzedWithText = (text: string) => {
  const [url, params] = createFetchParams<TextAnalyzePOST>(
    HttpEndpointName.TextAnalyze,
    {
      locale: Locale.JA,
      text
    }
  );

  return from(
    fetch(url, params)
      .then((res) => res.json())
      .catch((err) => {
        // silent failure
        logger.error(err);
      })
  );
};
