/*
 * Designed to be a util around mobx to load resources for only uncached ids
 * pure functions taking in mobx Map observable
 * - unused but can reference:
 *   - https://github.com/mobxjs/mobx-utils#fromresource
 *   - https://github.com/mobxjs/mobx-state-tree
 *   - https://github.com/mdebbar/mobx-cache
 */

import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { toJS } from 'mobx';
import _ from 'lodash';
import logger from '@wordquest/lib-iso/app/logger';

import { loadWithCustomCacheBulk } from '@wordquest/lib-iso/adapters/cache';

// flow plugin not working well with generics, use any
// loadFn handles id already

export function loadWithIdsWithMobxCache<T>(
  keys: string[],
  loadFn: (keys: string[]) => Observable<Record<string, T>>,
  cache: ObservableMap,
  isMergeCache: boolean = true
): Observable<Record<string, T>> {
  const checkIsCacheHit = (keys) =>
    of(_.fromPairs(keys.map((key) => [key, cache.has(key)])));

  const loadCacheFn = (keys: string[]) =>
    of(_.fromPairs(keys.map((key) => [key, toJS(cache.get(key))])));
  const mergeCache = (resultById: Record<string, T>) =>
    of(resultById).pipe(
      map((resultById) => (isMergeCache ? cache.merge(resultById) : null)),
      map(() => resultById)
    );

  return loadWithCustomCacheBulk<T>(
    keys,
    loadFn,
    checkIsCacheHit,
    loadCacheFn,
    mergeCache
  );
}
