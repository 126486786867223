import _ from 'lodash';
import { observable, reaction, decorate, autorun, computed } from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';
import RootStore from '~/stores/root';

class PageReadStore {
  constructor(private rootStore: RootStore) {
    const { uiStateStore, articleStore } = rootStore;
    this.currentArticleRef = observable({
      id: ''
    });
    // instead of @computed as potentialyl other entry path too
    reaction(
      () => uiStateStore.routeMatch.params,
      (params) => {
        this.currentArticleRef.id = encodeURIComponent(
          _.get(params, 'articleId') || ''
        );
      },
      { fireImmediately: true }
    );
    this.recommendedIdsByCategory = observable.map({});

    this.recommendedIdsWithCurrentArticle = observable([]);

    // https://github.com/alisd23/mobx-react-router/issues/54
    reaction(
      () => this.currentArticleRef.id,
      async (articleId) => {
        if (!articleId) {
          return;
        }
        logger.debug(`[updated]${articleId}`);
        const articles = await articleStore.loadArticleWithIds([
          this.currentArticleRef.id
        ]);
        articleStore.mergeArticleById(_.keyBy(articles, 'id'));
        // Ensure loaded instead of currentArticle
        const recommendArticles =
          await articleStore.loadArticleRecommendedWithCurrentArticle(
            _.first(_.values(articles))
          );

        this.recommendedIdsWithCurrentArticle = recommendArticles.map(
          (article) => article.id
        );
        articleStore.mergeArticleById(_.keyBy(recommendArticles, 'id'));
      },
      { fireImmediately: true }
    );

    autorun(async () => {
      // simply disabled now. TODO better handling
      // we might not want to pre-load articles
      if (
        articleStore.articleById.size === 0 &&
        uiStateStore.routeMatch.path === 'read'
      ) {
        // won't re-trigger when back to /read
        // TODO potential limit to particular routes only
        logger.debug('load recommened articleById');
        const articlesByCategory = await articleStore.loadArticleRecommended();

        this.recommendedIdsByCategory.merge(
          _.mapValues(articlesByCategory, (articles) => _.map(articles, 'id'))
        );
      }
    });
  }
  // TODO ensure these computed won't re-use memory

  get currentArticle() {
    return this.rootStore.articleStore.articleById.get(
      this.currentArticleRef.id
    );
  }

  get recommenedArticlesByCategory() {
    return _.mapValues(this.recommendedIdsByCategory.toJSON(), (articleId) =>
      this.rootStore.articleStore.articleById.get(articleId)
    );
  }

  get recommendedArticlesWithCurrentArticle() {
    return _.map(this.recommendedIdsWithCurrentArticle, (id) =>
      this.rootStore.articleStore.articleById.get(id)
    );
  }
}

decorate(PageReadStore, {
  currentArticle: computed,
  recommenedArticlesByCategory: computed,
  recommendedArticlesWithCurrentArticle: computed
});

export default PageReadStore;
