import React, { useRef, useEffect } from 'react';
import _ from 'lodash';
import ChatBubbles from '~/chat/bubbles';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import iphoneBackground from './coach_demo.png';
import logo from './logo.png';
import { media, isSizeUp } from '~/style-utils';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const StyledIphone = styled.div`
  background-image: url(${iphoneBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  /* padding-top: 100%; */
  height: 40rem;
  width: 50rem;
  position: relative;
`;

const StyledPhoneContent = styled.div`
  position: absolute;
  left: ${(props) => (props.isContentOnly ? '2%' : '23%')};
  width: ${(props) => (props.isContentOnly ? '96%' : '60%')};
  top: 30%;
  margin: 0;
  bottom: 0;
  min-width: 20rem;
  /* overflow-y: hidden; */

  /* width: 100%; */

  .bubble-container {
    background-color: #dadde6;
    /* use margin instead of max-width to fit avatar */
    /* max-width: 47%; */
    /* margin-right: 33%; */

    height: ${(props) => (props.isContentOnly ? '100%' : '100%')};
    /* max-height: 65%; */
    .bubble-wrap {
      overflow-y: hidden;
      /* right: 0px; */

      .say {
        min-width: 30px;
      }
    }
  }
`;

// TODO use responsive calc instead of breakpoint size
export const DeviceChatContainer = ({
  convo,
  device = 'ios',
  messenger = 'line',
  onCourseQuestClicked = _.identity
}) => (
  <StyledContainer>
    <StyledIphone>
      <StyledPhoneContent isContentOnly={!isSizeUp('laptop')}>
        <ChatBubbles
          convo={convo}
          left={<Avatar style={{ cursor: 'pointer' }} src={logo} size={20} />}
          onCourseQuestClicked={onCourseQuestClicked}
        />
      </StyledPhoneContent>
    </StyledIphone>
  </StyledContainer>
);

export default DeviceChatContainer;
