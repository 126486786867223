import _ from 'lodash';
import React, { useMemo, useState, useEffect } from 'react';
import Quest, {
  QuestReadArticle,
  QuestReadArticleRecommended,
  QuestWatchVideoRecommended,
  QuestTypeName
} from '@wordquest/lib-iso/domain/wordquest/quest/quest';
import { from, concat } from 'rxjs';
import { FormattedMessage, FormattedDate } from 'react-intl';
import logger from '@wordquest/lib-iso/app/logger';
import styled from 'styled-components';
import LoaderGeneral from '~/loader-general';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Headline } from '~/layout/common';
import { PictureWithCaption } from '~/image/picture-with-caption';
import RichText, { DEFAULT_VIDEO_STYLE } from '~/text/rich-text';
import {
  RichTextContainer,
  createRenderElement,
  renderAsPlainText
} from '~/text/rich-text-container';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import CoursePlans from './course-plans';
import {
  WqNodeTypeBlock,
  WqNodeTypeInline
} from '@wordquest/lib-iso/domain/wordquest/dom';
import { createSrcSetByTypeContentfulWithSizeParams } from '@wordquest/lib-iso/app/contentful/rich-text-wq-dom-mapper';
import {
  media,
  mediaMui,
  isSizeUp,
  isWindowMuiBreakpointUp
} from '~/style-utils';
import { ScrollTo, ScrollArea } from 'react-scroll-to';
import ExpandableSection from '~/expandable-section';
import {
  Entity,
  CourseAction,
  asEventName
} from '@wordquest/lib-iso/domain/wordquest/entity';
import useScrollPosition from '@react-hook/window-scroll';
import CourseChallengeWidget from '~/course/challenge-widget';
import CourseInfographicBar from '~/course/infographic-bar';
import { HashLink } from 'react-router-hash-link';
import LocationCity from '~/jobs/location-city';
import {
  formatAsTargetGmt,
  formatWithLocale,
  loadAndAddMessagesWithLocaleModule
} from '@wordquest/lib-iso/intl';
import { Locale, MessagesModule } from '@wordquest/locales';
import DeviceChatContainer from '~/chat/device-chat-container';
import Qualifications from '~/qualification/qualifications';
import { collectMultipleRichTextsEntitiesByNodeType } from '@wordquest/lib-iso/domain/wordquest/dom-entity-extractor';
import { createConvo } from '~/chat/assistant-convo';
import { LanguageProficiencyTag } from '~/tag/language-proficiency-tag';
import { toArray, take } from 'rxjs/operators';
import FilePlayer from 'react-player/lib/players/FilePlayer';
import { asQuest } from '@wordquest/lib-iso/app/quest/quest-cf';
import {
  createAssistantMessages,
  createMcAnswerMessagePairs
} from '@wordquest/lib-iso/domain/wordquest/assistant/assistant-strategies';
import AuthorCard, { AuthorLanguages } from '~/author/author-card';
import VerifiedBadge from '~/verified-badge';
import CourseSidebar from './course-sidebar';
import CourseCTA, { createOnClickCTA, scrollToFrom } from './course-cta';
import CourseBottomCTA from './course-bottom-cta';
import CourseBreadcrumbs from '~/components/course-breadcrumbs';
import TestimonialSwiper from '~/testimonial/testimonial-swiper';

const StyleCourseSidebar = styled.div`
  position: sticky;
  top: 6rem;
  padding-left: 2rem;
`;

const StyledHeroVideo = styled.div`
  padding-bottom: 2rem;
`;

const StyledCenterImageBase = styled.div`
  display: inline-flex;
  height: 6rem;
  align-items: center;
  margin: auto;

  background-repeat: no-repeat;
  min-width: 65%;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  ${mediaMui.xs`
    width: 100%;
    background-size: contain;
  `}
  ${mediaMui.sm`
    width: 100%;
    background-size: contain;
  `}
`;
const StyledHeroImage = styled.div`
  /* overflow: hidden; */
  min-height: 10rem;
  width: 100%;

  ${mediaMui.xs`
    margin-top: 56px;
  `}

  ${mediaMui.smUp`
      margin-top: 1.2rem;
  `}
`;

const RichTextContent = styled.div`
  & figure {
    text-align: center;
  }
  /* always bold to match to contentful */
  h4,
  h5 {
    font-weight: 700;
  }
`;

const StyledCTATitle = styled(StyledCenterImageBase)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  > button,
  a {
    font-weight: 900;
    width: 100%;
    color: white;
    text-decoration: none;
  }
  background-image: url(${(props) => props.theme.ctaBackgroundUrl});
`;

const StyledTitle = styled(StyledCenterImageBase)`
  background-image: url(${(props) => props.theme.titleBackgroundUrl});
`;

const StyledSectionContent = styled.div`
  padding-bottom: 3rem;
`;
const DEFAULT_THEME = {
  ctaBackgroundUrl: '',
  titleBackgroundUrl: ''
};

// note not in courseIntro but course
export const CourseMetaDescription = (props) => {
  const {
    startAt,
    endAt,
    dateDescription,
    courseTypes = [],
    teachers = [],
    teacherAssistants = []
  } = props;

  const isChallenge = _.includes(courseTypes, 'challenge');

  return (
    <div>
      {isChallenge && (
        <CourseChallengeWidget
          startAt={startAt}
          endAt={endAt}
          dateDescription={dateDescription}
        />
      )}
      <Box py={1}>
        <CourseInfographicBar
          teachers={teachers}
          teacherAssistants={teacherAssistants}
          courseTypes={courseTypes}
        />
      </Box>
    </div>
  );
};

export const StyledLessonCountBox = styled(Box)`
  > div {
    /* padding-left: 2rem; */
    padding-right: 1rem;
  }
  /* justify-content: space-between; */
`;

export const CoachSection = (props) => {
  const { teacher, coachSection, heroVideoUrl, renderData } = props;
  const {
    studentsCount,
    isStudentsCountVerified,
    lessonSessionsCount,
    isLessonSessionsCountVerified,
    origin = {},
    currentLocation = {},
    languagesTeaching = [],
    languagesOther = [],
    timeZoneGmt
  } = teacher;

  const customDescription = (
    <Grid container spacing={1}>
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} spacing={2} justify="space-around">
          <StyledLessonCountBox display="flex">
            {studentsCount > 0 && (
              <>
                <div>
                  <FormattedMessage id="common.course.teacher.students-count" />
                </div>
                <div>{studentsCount}</div>{' '}
                {isStudentsCountVerified ? <VerifiedBadge /> : null}
              </>
            )}
            {lessonSessionsCount > 0 && (
              <>
                <div>
                  <FormattedMessage id="common.course.teacher.lessons-count" />
                </div>
                <div>{lessonSessionsCount}</div>{' '}
                {isLessonSessionsCountVerified ? <VerifiedBadge /> : null}
              </>
            )}
          </StyledLessonCountBox>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={2}>
          <FormattedMessage id="common.course.teacher.origin" />
        </Grid>
        <Grid item xs={10} md={5}>
          <LocationCity {...origin} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={2}>
          <FormattedMessage id="common.course.teacher.current-location" />
        </Grid>
        <Grid item xs={10} md={5}>
          <Box display="flex" flexWrap="wrap">
            <LocationCity {...currentLocation} />
            {_.isNumber(timeZoneGmt) && (
              <>
                {/*
                        <Grid item xs={3}>
                          <FormattedMessage id="common.course.teacher.current-timezone" />
                          </Grid>
                  */}{' '}
                ({formatAsTargetGmt(new Date(), timeZoneGmt)})
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <AuthorLanguages
        labelSize={2}
        languagesTeaching={languagesTeaching}
        languagesOther={languagesOther}
      />
    </Grid>
  );

  return (
    <div>
      <AuthorCard
        author={teacher}
        customDescription={customDescription}
        isShowAuthorDescription={false}
        isShowTags={false}
        isShowAuthorName={false}
        isShowSmallAuthorName
        authorNameSize="sm"
        // withLink={props => withNextLink({ href: `/author/${author.semanticKey}` })}
      />
      {!_.isEmpty(teacher.academicQualifications) && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} md={8}>
            <Typography color="inherit" variant="overline" component="h6">
              <FormattedMessage id="common.course.teacher.academic-qualifications" />
            </Typography>
            <Qualifications
              qualifications={teacher.academicQualifications || []}
            />
          </Grid>
        </Grid>
      )}
      {!_.isEmpty(teacher.workExperiences) && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} md={8}>
            <Typography color="inherit" variant="overline" component="h6">
              <FormattedMessage id="common.course.teacher.work-experiences" />
            </Typography>
            <Qualifications qualifications={teacher.workExperiences} />
          </Grid>
        </Grid>
      )}
      {coachSection && (
        <RichTextContent>
          <RichTextContainer
            wqDom={coachSection.descriptionRich}
            renderData={renderData}
          >
            <RichText
              renderElement={createRenderElement(renderStrategyByNodeType)}
            />
          </RichTextContainer>
        </RichTextContent>
      )}
      <RichTextContent>
        <RichTextContainer
          wqDom={teacher.descriptionRich}
          renderData={renderData}
        >
          <RichText
            renderElement={createRenderElement(renderStrategyByNodeType)}
          />
        </RichTextContainer>
      </RichTextContent>
    </div>
  );
};

export const SectionTitle = ({ section, courseTheme, onClickCTA }) => {
  if (section.key.match(/goto-form|cta/)) {
    return (
      <StyledCTATitle theme={courseTheme}>
        <Button className="course-cta" onClick={() => onClickCTA()}>
          <Typography
            color="inherit"
            variant="h5"
            component="h4"
            align="center"
          >
            {section.title}
          </Typography>
        </Button>
      </StyledCTATitle>
    );
  }
  if (section.key.match(/notitle/)) {
    return <></>;
  }

  return (
    <StyledTitle theme={courseTheme}>
      <Typography color="inherit" variant="h5" component="h4" align="center">
        {section.title}
      </Typography>
    </StyledTitle>
  );
};

const renderStrategyByNodeType = {
  [WqNodeTypeBlock.Heading5]: (props, context) => (
    <Typography variant="h5" component="h5">
      {renderAsPlainText(props)}
    </Typography>
  )
};
// should extend from the original ones
renderStrategyByNodeType[WqNodeTypeBlock.Heading1] =
  renderStrategyByNodeType[WqNodeTypeBlock.Heading5];
renderStrategyByNodeType[WqNodeTypeBlock.Heading2] =
  renderStrategyByNodeType[WqNodeTypeBlock.Heading5];
renderStrategyByNodeType[WqNodeTypeBlock.Heading3] =
  renderStrategyByNodeType[WqNodeTypeBlock.Heading5];
renderStrategyByNodeType[WqNodeTypeBlock.Heading4] =
  renderStrategyByNodeType[WqNodeTypeBlock.Heading5];

const renderConvo = async ({ descriptionRich, teacher = {} }) => {
  // pbm: mixed with messages, but possible to extract text
  const entitiesByNodeType = collectMultipleRichTextsEntitiesByNodeType(
    [descriptionRich],
    {
      isInclude: true
    },
    [WqNodeTypeBlock.Raw]
  );

  const questDataNode = _.first(entitiesByNodeType.raw);
  const quest = asQuest(questDataNode.target);

  const introMessage = formatWithLocale(Locale.EN)(
    'common.course.teacher.greetings',
    {
      coachName: _.get(teacher, 'shortName') || _.get(teacher, 'name') || ''
    }
  );

  // TODO we need to separate the welcome message. potential AuthorNote OR inside assistant message as optional
  const questIntroMessage = formatWithLocale(Locale.EN)('chat.quest.try', {});

  // TODO message
  const profile = {};
  const questMessages = await createAssistantMessages(profile, quest)
    .pipe(toArray())
    .toPromise();
  const answerMessagePairs = await createMcAnswerMessagePairs(
    profile,
    quest
  ).toPromise();
  const messagesByTrigger = {
    ice: [introMessage, questIntroMessage].concat(questMessages),
    ..._.fromPairs(answerMessagePairs)
  };

  return createConvo({ profile, messagesByTrigger });
};

export const AssistantDescription = ({
  descriptionRich,
  teacher,
  onCourseQuestClicked
}) => {
  const [convo, setConvo] = useState(null);

  useEffect(() => {
    renderConvo({ descriptionRich, teacher }).then((renderedConvo) => {
      setConvo(renderedConvo);
    });
  }, [descriptionRich]);

  return (
    <div>
      {convo && (
        <DeviceChatContainer
          onCourseQuestClicked={onCourseQuestClicked}
          convo={convo}
        />
      )}
    </div>
  );
};

export default ({
  courseIntro,
  courseUserStatus,
  onClickCTA,
  coursePlans,
  ctaTargetRef,
  trackEvent = _.identity,
  courseMeta = {},
  context = {},
  testimonials = []
}) => {
  const scrollY = useScrollPosition(5 /* fps */);
  let isAtForm = false;
  if (_.get(ctaTargetRef, 'current')) {
    isAtForm = scrollY + window.innerHeight > ctaTargetRef.current.offsetTop;
    // we need to know form height if we just use innerHeight
  }

  const isHiddenCTA =
    _.includes(['coach'], courseIntro.courseKey) ||
    _.includes(['disabled'], courseMeta.ctaLabel);
  const {
    key: courseKey,
    title,
    heroImageUrl,
    descriptionShort,
    sections,
    theme: courseTheme = DEFAULT_THEME,
    heroVideoUrl
  } = courseIntro;

  const { isCoach, targetLocale, bookingUrl = '' } = courseMeta;
  // TODO we should extract
  const coachSection = _.find(sections, (s) => s.key.match(/-teacher-/));

  let renderSections = sections;
  const teacher = _.get(courseMeta, 'teachers.0') || {};
  // alternative representation, dont use sections

  if (isCoach) {
    renderSections = _.difference(
      sections,
      [coachSection].filter(Boolean)
    ).concat([
      {
        key: 'lesson-method',
        title: <FormattedMessage id="common.course.lesson.method" />,
        descriptionRich: {
          type: WqNodeTypeBlock.Document,
          object: 'block',
          // data: getDataOfDefault(cfRichTextDoc.data),
          children: [
            {
              text: (
                <Typography variant="body1" component="p">
                  <FormattedMessage
                    id="common.course.lesson.tools"
                    values={{
                      coachName: teacher.shortName,
                      tools: ['zoom', 'skype'].join(' / ')
                    }}
                  />
                </Typography>
              )
            },
            {
              text: (
                <Typography
                  variant="body1"
                  component="p"
                  onClick={() => {
                    trackEvent({
                      event: asEventName(
                        Entity.Course,
                        CourseAction.CoachScheduleViewed
                      ),
                      properties: {
                        courseKey: courseIntro?.courseKey,
                        courseTitle: courseIntro?.title
                      }
                    });
                  }}
                >
                  <FormattedMessage id="common.course.lesson.schedule" />
                  &nbsp;
                  <a target="_blank" href={bookingUrl} rel="noreferrer">
                    時間表 Schedule
                  </a>
                </Typography>
              )
            }
          ]
        }
      }
    ]);
  }

  const theme = useTheme();

  const sizeParams = [
    {
      params: {
        w: '2688',
        h: '1152'
        // fit: 'pad'
      },
      size: '4096w'
    },
    {
      params: {
        w: '2688',
        h: '1152'
        // fit: 'pad'
      },
      size: '2048w'
    }
    // {
    //   params: {
    //     w: '1024',
    //     h: '768'
    //     // fit: 'crop'
    //   },
    //   size: '1024w'
    // }
  ];

  const heroImageComponent = (
    <StyledHeroImage>
      <Box display="flex" justifyContent="center">
        {heroImageUrl && (
          <PictureWithCaption
            src={heroImageUrl}
            style={{
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              maxHeight: '60rem',
              width: '100%'
            }}
            aspectRatio={isSizeUp('tablet') ? 21 / 9 : 4 / 3}
            srcsetByType={createSrcSetByTypeContentfulWithSizeParams(
              heroImageUrl,
              ['jpeg', 'webp'],
              sizeParams
            )}
            title={title}
            description={descriptionShort}
            isHideCaption
          />
        )}
      </Box>
    </StyledHeroImage>
  );

  return (
    <ScrollTo>
      {({ scrollTo }) => {
        // possible pre-create just bind the scrollTo

        const ctaButton = (
          <CourseCTA
            key={`${courseKey}-cta`}
            onClickCTA={() => onClickCTA({ ctaTargetRef, scrollTo })}
            paidLabel={courseMeta.ctaLabel}
            courseUserStatus={courseUserStatus}
          />
        );

        const renderData = {
          ...context,
          imageStyle: { minHeight: '5rem', maxWidth: '60rem', margin: 'auto' },
          videoStyle: DEFAULT_VIDEO_STYLE
        };

        return (
          <>
            {heroImageComponent}

            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Headline>
                  <Typography
                    color="inherit"
                    variant="h3"
                    component="h2"
                    align="center"
                  >
                    {title}
                  </Typography>
                </Headline>
              </Grid>
            </Grid>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12} md={isHiddenCTA ? 12 : 8}>
                  {!isCoach && (
                    <CourseMetaDescription
                      teachers={courseMeta.teachers}
                      teacherAssistants={courseMeta.teacherAssistants}
                      courseTypes={courseMeta.courseTypes}
                      startAt={courseMeta.startAt}
                      endAt={courseMeta.endAt}
                      dateDescription={courseMeta.challengeDateDescription}
                    />
                  )}
                  <Hidden mdUp>
                    <Typography color="inherit" variant="h5" component="h4">
                      <FormattedMessage id="common.course.learning-objectives" />
                    </Typography>
                    {courseMeta.ctaDescriptionRichText && (
                      <RichTextContainer
                        wqDom={courseMeta.ctaDescriptionRichText}
                      >
                        <RichText
                          renderElement={createRenderElement(
                            renderStrategyByNodeType
                          )}
                        />
                      </RichTextContainer>
                    )}
                  </Hidden>
                  {heroVideoUrl && (
                    <StyledHeroVideo>
                      <FilePlayer
                        height="600"
                        width="1200"
                        playing={false}
                        controls
                        config={{
                          file: {}
                        }}
                        url={heroVideoUrl}
                      />
                    </StyledHeroVideo>
                  )}
                  {isCoach && teacher && (
                    <CoachSection
                      heroVideoUrl={heroVideoUrl}
                      teacher={teacher}
                      coachSection={coachSection}
                      renderData={renderData}
                    />
                  )}
                  {(renderSections || []).map((section) => {
                    const isDefaultExpanded =
                      section.isDefaultExpanded !== false;

                    return (
                      <div key={section.key}>
                        <Grid container justify="center" alignItems="center">
                          <Grid item xs={12}>
                            <Box
                              id={section.key}
                              display="flex"
                              justifyContent="center"
                            >
                              <SectionTitle
                                courseTheme={courseTheme}
                                section={section}
                                onClickCTA={() => {
                                  onClickCTA({ ctaTargetRef, scrollTo });
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {!_.isEmpty(section.descriptionRich) && (
                          <Grid container justify="center" alignItems="center">
                            <Grid item xs={12}>
                              <StyledSectionContent>
                                <ExpandableSection
                                  maxHeight="20rem"
                                  onClickExpand={() => {
                                    trackEvent({
                                      event: asEventName(
                                        Entity.Course,
                                        CourseAction.IntroDetailsChecked
                                      ),
                                      properties: {
                                        courseKey: (courseIntro || {}).key
                                      },
                                      detailsKey: section.key
                                    });
                                  }}
                                  isDefaultExpanded={isDefaultExpanded}
                                >
                                  <RichTextContent>
                                    <RichTextContainer
                                      wqDom={section.descriptionRich}
                                      renderData={renderData}
                                    >
                                      <RichText
                                        renderElement={createRenderElement(
                                          renderStrategyByNodeType
                                        )}
                                      />
                                    </RichTextContainer>
                                  </RichTextContent>
                                  {section.isRenderAsAssistant && (
                                    <div>
                                      <AssistantDescription
                                        descriptionRich={
                                          section.descriptionRich
                                        }
                                        teacher={teacher}
                                        onCourseQuestClicked={() => {
                                          trackEvent({
                                            event: asEventName(
                                              Entity.Course,
                                              CourseAction.CourseQuestClicked
                                            ),
                                            properties: {
                                              courseKey: courseIntro?.courseKey,
                                              courseTitle: courseIntro?.title
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </ExpandableSection>
                              </StyledSectionContent>
                            </Grid>
                            <Divider />
                          </Grid>
                        )}
                      </div>
                    );
                  })}
                  <TestimonialSwiper testimonials={testimonials} />
                  <CoursePlans
                    coursePlans={coursePlans}
                    trackEvent={trackEvent}
                    onClickCTA={(trackEvent = _.identity) =>
                      scrollToFrom({
                        ctaTargetRef,
                        scrollTo,
                        trackEvent
                      })
                    }
                  />
                </Grid>
                <Hidden smDown>
                  {!isHiddenCTA && (
                    <Grid item sm={4}>
                      <Fade in={!isAtForm}>
                        <StyleCourseSidebar>
                          <CourseSidebar
                            courseIntro={courseIntro}
                            ctaButton={ctaButton}
                            courseMeta={courseMeta}
                            coursePlans={coursePlans}
                          />
                        </StyleCourseSidebar>
                      </Fade>
                    </Grid>
                  )}
                </Hidden>
              </Grid>
            </Container>
            <Hidden mdUp>
              <CourseBottomCTA
                isHidden={isHiddenCTA}
                originalPrice={courseMeta.originalPrice}
                offerPrice={courseMeta.offerPrice}
                ctaButton={ctaButton}
              />
            </Hidden>
          </>
        );
      }}
    </ScrollTo>
  );
};
