/**
 *  Target ES  v6+ deployment
 *
 * Note:
 * index type is removed at search & msearch, as only single type is expected & API still works
 * while type is still compulsory for _bulk API
 * use _doc for type  https://www.elastic.co/guide/en/elasticsearch/reference/current/removal-of-types.html
 *
 */
import _ from 'lodash';
import { IS_DEV } from '~/env';
import rootLogger from '~/app/logger';
import { lazyLoadFactory } from '~/adapters/lazy-load';
import fetch from 'isomorphic-fetch';

const logger = rootLogger.child({ module: 'elasticsearch' });

export type EsConfig = {
  host?: string;
  auth?: string;
  protocol?: 'https' | 'http';
  port?: string;
};

export type EsQuery = any;

export type EsOpOptions = {
  timeout?: string;
  type?: string;
  // eslint-disable-next-line
  detect_noop?: boolean;
};

export const esClientByKey = {};

//
// // We might have different account against same instance. no has due to perf
// const cacheKey = esConfig.host + esConfig.auth;
// if (esClientByKey[cacheKey]) {
//   return esClientByKey[cacheKey];
// }

const initClientFactory = () =>
  lazyLoadFactory((config: EsConfig) => {
    const { host, auth, port, protocol } = config;
    if (!host || typeof auth !== 'string' || !port) {
      logger.error('check ES config host', host);
      throw new Error('invalid ES Config');
    }
    logger.debug(`ES init Client ${config.host || ''}`);
    // eslint-disable-next-line
    const elasticsearch = require('elasticsearch');

    return new elasticsearch.Client({
      apiVersion: '7.x',
      host: [
        {
          host,
          auth,
          protocol: protocol || 'https',
          port
        }
      ],
      log: 'warning',
      // https://github.com/elastic/elasticsearch-js/issues/196
      requestTimeout: IS_DEV ? 10000 : Infinity
    });
  });

const lazLoadFactoryByKey = {};
export const getClient = async (config) => {
  const key = config.host + config.auth;
  if (!lazLoadFactoryByKey[key]) {
    lazLoadFactoryByKey[key] = initClientFactory();
  }

  return lazLoadFactoryByKey[key](config);
};

export const reindex = async (
  esConfig: EsConfig,
  reindexConfig
): Promise<any> => {
  logger.debug('[reindex]', esConfig, reindexConfig);
  const client = await getClient(esConfig);
  const result = await client.reindex({
    body: reindexConfig,
    wait_for_completion: false
  });
  if (result) {
    logger.debug(`[Query Took] ${result.took}ms`);
    logger.trace(result);
  }

  return result;
};

export const search = async (
  esConfig: EsConfig,
  index: string,
  query: EsQuery
): Promise<any> => {
  logger.debug('[Query]', index);
  logger.trace(query);
  const client = await getClient(esConfig);
  const result = await client.search({
    index,
    from: query.from || 0,
    // pbm: this override the size limit inside query
    size: query.size || 50,
    // Though from and size can be set as request parameters, they can also be set within the search body.
    body: query
  });
  if (result) {
    logger.debug(`[Query Took] ${result.took}ms`);
    logger.trace(result);
  }

  return result;
};

export const msearch = async (
  esConfig: EsConfig,
  indexQueryPairs: [string, EsQuery][],
  aggOptions = {}
): Promise<any[] | any> => {
  logger.debug('[Queries]', getClient, indexQueryPairs, aggOptions);
  const client = await getClient(esConfig);

  return client
    .msearch({
      body: _.flatMap(indexQueryPairs, ([index, query]) => [{ index }, query])
    })
    .then((results) => results.responses)
    .then((responses) =>
      _.map(responses, (response) => {
        if (response) {
          logger.debug(`[msearch][Query Took] ${response.took}ms`);
          logger.trace(response);
        }
        if (response.error) {
          logger.error('[msearch] error', response.error);

          return [];
        }
        if (aggOptions.agg) {
          return response.aggregations;
        }
        logger.debug('[msearch]', response.hits.hits.length);

        return response.hits.hits;
      })
    );
};

export const upsertDocBulk = async (
  esConfig: EsConfig,
  index: string,
  ops: any[],
  options: EsOpOptions = {}
) => {
  const type = options.type || '_doc';
  logger.debug('upsertDocBulk', index, type, ops.length);
  if (_.isEmpty(ops)) {
    return;
  }
  const client = await getClient(esConfig);
  const body = _.flatten(
    ops.map((op) => [
      {
        update: { _index: index, _type: type, _id: op.id }
      },
      _.merge(
        op.doc
          ? {
              doc_as_upsert: true,
              detect_noop: options.detect_noop === true,
              doc: { lastIndexedAt: new Date() }
            }
          : {},
        _.omit(op, 'id')
      )
    ])
  );
  logger.trace('body', body);
  const results = await client.bulk({
    timeout: options.timeout || '30s',
    body
  });

  if (results.errors) {
    logger.error('upsertDocBulk Error', JSON.stringify(results));
  }

  logger.debug('upsertDocBulk Completed');
  logger.trace(results);

  return results;
};

// upsert with script / create got diff api signature
export const indexDocBulk = async (
  esConfig: EsConfig,
  index: string,
  docs: any[],
  options: EsOpOptions = {}
) => {
  const type = options.type || '_doc';
  logger.debug('indexDocBulk', index, type, docs.length);
  logger.trace(docs);
  const body = _.flatten(
    docs.map((doc) => [
      {
        index: { _index: index, _type: type, _id: doc.id }
      },
      Object.assign(doc, {
        indexedAt: new Date()
      })
    ])
  );
  const client = await getClient(esConfig);
  const results = await client.bulk({
    // this is operation timeout, set client timeout to be larger than this
    // https://www.elastic.co/guide/en/elasticsearch/client/javascript-api/15.x/api-reference-1-7.html#api-bulk-1-7
    timeout: options.timeout || '30s',
    body
  });
  logger.debug('indexDocBulk Completed');
  logger.trace(results);

  return results;
};

export const createIndex = async (
  esConfig: EsConfig,
  index: string,
  indexConfig = {}
) => {
  const client = await getClient(esConfig);
  await client.indices.create({
    index,
    body: indexConfig || {}
  });
};

export const upsertMapping = async (
  esConfig: EsConfig,
  index: string,
  mapping: any
) => {
  const client = await getClient(esConfig);
  // Not possible to delete mapping
  await client.indices.putMapping({
    index,
    body: mapping
  });
};

export const getDoc = async (
  esConfig: EsConfig,
  index: string,
  docId: string
) => {
  const client = await getClient(esConfig);

  return client.get({
    index,
    id: docId
  });
};

export const indexDoc = async (
  esConfig: EsConfig,
  index: string,
  doc: any,
  options: EsOpOptions = {}
) => {
  const type = options.type || '_doc';
  const client = await getClient(esConfig);

  return client.index({
    index,
    type,
    id: doc.id,
    // op_type: 'create',
    body: doc
  });
};

// TODO unsafe, for cloudflare worker only
export const queryDocRest = async (esConfig, indexName, docId) => {
  const docUrl = `https://${process.env.REACT_APP_ES_APP_CONTENT_HOST}:${process.env.REACT_APP_ES_APP_CONTENT_PORT}/${indexName}/_doc/${docId}`;
  const buff = Buffer.from(
    [
      process.env.REACT_APP_ES_APP_CONTENT_RO_USER,
      process.env.REACT_APP_ES_APP_CONTENT_RO_PASSWORD
    ].join(':')
  );
  const auth = buff.toString('base64');

  return fetch(docUrl, {
    headers: new Headers({
      Authorization: `Basic ${auth}`
    })
  }).then((res) => res.json());
};
