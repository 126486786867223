export function parseUTMFromQueryString(queryString: string) {
  const searchParams = new URLSearchParams(queryString);

  return {
    utmSource: searchParams.get('utm_source') ?? '',
    utmMedium: searchParams.get('utm_medium') ?? '',
    utmCampaign: searchParams.get('utm_campaign') ?? '',
    utmTerm: searchParams.get('utm_term') ?? '',
    utmContent: searchParams.get('utm_content') ?? ''
  };
}

export function getUTMFromWindowLocation() {
  const queryString = window?.location?.search;

  if (!queryString) {
    return null;
  }

  return parseUTMFromQueryString(queryString);
}
