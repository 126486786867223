import React from 'react';
import {
  SITE_ROOT_UI_SITE_PRD,
  SITE_ROOT_UI_SITE
} from '@wordquest/lib-iso/env';
import { withRouterLink } from '~/with-link';

const LogoImage = () => (
  <img
    src={`${SITE_ROOT_UI_SITE_PRD}/static/index_v2/logo/PeraPera_logo.svg`}
    alt="logo"
  />
);

const LogoImageWithLink = () =>
  withRouterLink({ href: SITE_ROOT_UI_SITE })(<LogoImage />);

export default LogoImageWithLink;
