import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage, FormattedDate } from 'react-intl';
import differenceInSeconds from 'date-fns/difference_in_seconds';

const StyledCourseChallengeContent = styled.div`
  .MuiGrid-root.MuiGrid-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .MuiBox-root {
    > div {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

export default ({
  startAt,
  endAt,
  dateDescription
}: {
  startAt: Date;
  endAt: Date;
  dateDescription: string | React.FC;
}) => (
  <Card>
    <CardContent>
      <StyledCourseChallengeContent>
        <Grid container>
          <Grid item xs={12} spacing={5} alignContent="center" justify="center">
            <Box display="flex">
              <Chip
                color="secondary"
                label={<FormattedMessage id="common.course.type.challenge" />}
              />
              <Typography variant="subtitle1" component="div">
                <FormattedDate
                  value={startAt}
                  year="numeric"
                  month="numeric"
                  day="2-digit"
                />
                -
                <FormattedDate
                  value={endAt}
                  year="numeric"
                  month="numeric"
                  day="2-digit"
                />{' '}
                {dateDescription}
              </Typography>
            </Box>
          </Grid>
          {/*
            <Grid item xs={2} lg={1}>
              <Box>
                <Box display="flex" borderTop={10} borderColor="secondary.main">
                  <FormattedMessage id="common.course.challenge.countdown" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={10} lg={11} justify="center">
              <Typography variant="subtitle1" component="div">
                countdown
              </Typography>
            </Grid>
          */}
          <Grid item xs={12}>
            <Typography variant="body2" component="div">
              <FormattedMessage id="common.course.challenge.description" />
            </Typography>
          </Grid>
        </Grid>
      </StyledCourseChallengeContent>
    </CardContent>
  </Card>
);
