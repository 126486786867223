import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import LoaderGeneral from '~/loader-general';

const PleaseWait = ({ minHeight = '45vh' }) => (
  <Grid container justify="center" alignItems="center" style={{ minHeight }}>
    <Grid item container justify="center" xs={12} sm={12}>
      <LoaderGeneral minHeight="14vh" />
      <Typography component="p" variant="h5" align="center">
        <FormattedMessage id="common.error.handle.wait" />
      </Typography>
    </Grid>
  </Grid>
);

export default PleaseWait;
