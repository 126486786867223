import { asWqDom } from '~/app/contentful/rich-text-wq-dom-mapper';
import _ from 'lodash';
import { format, parseISO, isValid } from 'date-fns';

export const asQualification = (cfEntity) => {
  if (!cfEntity) {
    return {};
  }
  const { sys, fields } = cfEntity;
  const { key, from, to, isVerified, descriptionRich } = fields;

  // check to > from.  prsentation convert if > present.

  return {
    key,
    isVerified: isVerified === true,
    descriptionRich: asWqDom(descriptionRich),
    from: from ? parseISO(from) : new Date(),
    to: to ? parseISO(to) : new Date(),
    updatedAt: sys.updatedAt ? parseISO(sys.updatedAt) : new Date(),
    readyAt: fields.readyAt ? new Date(fields.readyAt) : undefined
  };
};
