import _ from 'lodash';
import { AuthStepEvent } from '@wordquest/ui-components/auth/auth-step';
import firebase from '~/services/firebase';
import logger from '@wordquest/lib-iso/app/logger';
import { createMachine, interpret, assign, send, actions } from 'xstate';
import {
  HttpEndpointName,
  UserActionRequestedPOST,
  createFetchParams
} from '@wordquest/lib-iso/app/firebase-function-https';
import { fromFirebase } from '@wordquest/lib-iso/domain/wordquest/profile/mappers';
import { cleanProfile } from '@wordquest/lib-iso/domain/wordquest/profile/profile';
import { getFallbackUserId } from '@wordquest/lib-iso/adapters/analytics';

const { pure } = actions;
// https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/error_auth.js#L123
// available in client side library, we clone the common set from firebase here
//  note we include auth/ for easier comparsion
// wq error are prefixed with auth/wq
// we should target to simulate all scenarios inside test cases
// Input = Scenario-based
// User Meta + Browser state + Login Mechanism (e.g. signin email link)
// Output (firebase.auth().onAuthStateChanged-> UserStore  )
// UI Auth State + Error Code
// UI reaction (UserStore -> AuthDialogContainer)
// UI behaviours (e.g. prompt / error message)

// We can skip the xstate and replace with just mobx based state
// But to enforce the transition and prepare for actor model
// sync with mobx using service to update observed state
// convention
// state = camelcase, transition = uppercase snake

// consider use keys of state directly for type
export enum AuthState {
  Initializing = 'initializing',
  SignedOut = 'SignedOut',
  SignInWithEmailInitiated = 'SignInWithEmailInitiated',
  SignedIn = 'signedIn',
  // Error = 'error',
  FirebaseValidating = 'FirebaseValidating',
  ProfileLoaded = 'profileLoaded'
}

// TODO auto ask email if social login
export enum AuthStateEvent {
  // by email/cookies etc

  // not logged in and auto-search next step
  Reset = 'RESET',
  SignInWithEmailLinkFail = 'SIGN_IN_WITH_EMAIL_LINK_FAIL',
  SignInWithEmailLink = 'SIGN_IN_WITH_EMAIL_LINK',
  // internal errors
  FirebaseAuthProcessStart = 'FIREBASE_AUTH_PROCESS_START',
  FirebaseLoadProfileComplete = 'FIREBSAE_LOAD_PROFILE_COMPLETE',
  FirebaseAuthProcessSuccess = 'FIREBSAE_AUTH_PROCESS_SUCCESS',
  FirebaseAuthProcessFail = 'FIREBSAE_AUTH_PROCESS_FAIL',
  SignOut = 'SIGN_OUT'
}

// use separate machine, assume only 1 message
export enum AuthMessageState {
  Basic = 'basic',
  Warning = 'warning',
  // context for data e.g. link expired
  Error = 'error'
  // LOGOUT_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
}

export enum AuthMessageEvent {
  Error = 'ERROR'
  // LOGOUT_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
}

const doSignInWithEmailLink = (context, event) => {
  logger.debug('doSignInWithEmailLink', context.emailForSignIn, event);
  context.emailForSignIn =
    _.get(event, 'payload.email') || context.emailForSignIn;

  // work with mobx = event, context ?
  // service = cant send
  // throw new Error('no device email signInWithEmailCurrentState');
  context.lastEmailSigninAttempted = context.emailForSignIn;
  if (!context.emailForSignIn) {
    // send ui step instead?

    context.rootStore.uiStateStore.app.authStepStateService.send(
      AuthStepEvent.EmailRequested
    );

    return Promise.reject(new Error('missing device email'));
  }
  // we need to re-entry here after email input
  // or we pending it

  // We might ask email to entry and invoke again so state note insufficient
  return firebase
    .auth()
    .signInWithEmailLink(context.emailForSignIn, window.location.href);
};

export const baseAuthStateMachine = createMachine(
  {
    id: 'auth-state',
    initial: AuthState.Initializing,
    on: {
      [AuthStateEvent.FirebaseAuthProcessSuccess]: {
        target: AuthState.SignedIn,
        actions: pure((context, event) => {
          logger.debug('success login');
        })
      },
      // [AuthStateEvent.FirebaseAuthProcessFail]: AuthState.SignedOut
      [AuthStateEvent.SignOut]: {
        target: AuthState.SignedOut,
        actions: ['doSignOut']
      },
      [AuthStateEvent.FirebaseLoadProfileComplete]: {
        target: AuthState.ProfileLoaded
      },
      [AuthStateEvent.FirebaseAuthProcessFail]: {
        target: AuthState.SignedOut,
        actions: pure((context, event) => {
          logger.debug('[FirebaseAuthProcessFail] send error', event);
          // keep code from firebase
          context.rootStore.uiStateStore.app.authMessageStateService.send({
            type: AuthMessageEvent.Error,
            payload: {
              error: event.payload.error
            }
          });
          // return send(AuthMessageEvent.Error, {
          //   to: 'auth-message'
          // });
        })
      }
    },
    states: {
      [AuthState.Initializing]: {
        always: [
          { target: AuthState.SignedOut, cond: 'isUserNotLoaded' }
          // { target: AuthState.ProfileLoaded, cond: 'isUserNotLoaded' }
        ],
        on: {}
      },
      [AuthState.SignedIn]: {
        on: {
          // Further connect
          [AuthStateEvent.FirebaseAuthProcessSuccess]: {
            target: AuthState.SignedIn,
            actions: pure((context, event) => {
              logger.debug('connect profile', event);
              updateProfileWithLineUser(context.rootStore, event.lineUser);
            })
          }
        },
        entry: ['signedInInit']
      },
      [AuthState.SignInWithEmailInitiated]: {
        invoke: {
          id: 'doSignInWithEmailLink',
          src: (context, event) => doSignInWithEmailLink(context, event),
          onDone: {
            target: AuthState.SignedIn,
            actions: pure((context, event) => {
              logger.debug('signin with email success');
              // callback will do this for us
              // return send(AuthStateEvent.FirebaseAuthProcessSuccess, {
              //   result: event.data
              // });
            })
            // actions: assign({ user: (context, event) => event.data })
          },
          onError: {
            actions: pure((context, event) => {
              logger.debug('login error', event);

              // send error message
              return send({
                type: AuthStateEvent.FirebaseAuthProcessFail,
                payload: {
                  error: event.data
                }
              });
            })
          }
        },
        on: {
          // self transition, re-invoke
          [AuthStateEvent.SignInWithEmailLink]: {
            target: AuthState.SignedOut,
            actions: assign({
              emailForSignIn: (context, event) => {
                console.log('trigger email');

                return _.get(event, 'payload.emailForSignin');
              }
            })
          }
        }
      },
      // [AuthState.Error]: {
      //   on: {
      //   }
      // },
      [AuthState.SignedOut]: {
        on: {
          [AuthStateEvent.FirebaseAuthProcessStart]:
            AuthState.FirebaseValidating,
          [AuthStateEvent.SignInWithEmailLink]:
            AuthState.SignInWithEmailInitiated
        },
        entry: ['checkSignInWithEmailLink']
      },
      [AuthState.FirebaseValidating]: {
        on: {
          // original callback at styledAuth
          // actions: 'doSignInWithEmailLink'
          [AuthStateEvent.FirebaseAuthProcessFail]: AuthState.SignedOut
        }
      },
      [AuthState.ProfileLoaded]: {
        on: {},
        entry: ['profileLoaded']
      }
    }
  },
  {
    guards: {
      isUserLoaded: (context, event) => firebase.auth && context.user,
      isUserNotLoaded: (context, event) => {
        // never loaded      // once a state is entered.
        // They are always checked when the state is first entered, before handling any other events. Eventless transitions are defined on the always property of the state node:
        logger.debug('isUserNotLoaded', context.user, !!firebase.auth);

        return firebase.auth && !context.user;
      }
    },
    actions: {
      profileLoaded: pure((context, event) => {
        logger.debug('profile loaded', context.rootStore.userStore.user);
        // app.authStepStateService.send(AuthStepEvent.LoginSuccess, {
        //   user: results
        // });
        logger.debug('send authStep LoginSuccess');
        context.rootStore.uiStateStore.app.authStepStateService.send({
          type: AuthStepEvent.LoginSuccess,
          payload: {
            user: context.rootStore.userStore.user
          }
        });
      }),
      doSignOut: async () => {
        // not at state
        logger.debug('doSignOut');
        try {
          await firebase.auth().signOut();
          logger.debug('doSignOut auth signout success');
        } catch (err) {
          logger.error('sign out error');
        }

        if (window.analytics) {
          window.analytics.reset();
        }
      },
      signedInInit: pure((context, event) => {
        logger.debug('signedInInit');
      }),
      checkSignInWithEmailLink: pure((context, event) => {
        const isSignInWithEmailLink = firebase
          .auth()
          .isSignInWithEmailLink(window.location.href);
        logger.debug(
          '[checkSignInWithEmailLink] isSignInWithEmailLink:',
          isSignInWithEmailLink,
          'lastEmailSigninAttempted',
          context.lastEmailSigninAttempted,
          'emailForSignIn',
          context.emailForSignIn
        );
        // awlays send even if empty email
        if (
          isSignInWithEmailLink &&
          context.lastEmailSigninAttempted !== context.emailForSignIn
        ) {
          logger.debug('send SignInWithEmailLink');

          return send(AuthStateEvent.SignInWithEmailLink);
        }
      })
    }
  }
);

export const createAuthStateMachine = (rootStore, emailForSignIn) =>
  baseAuthStateMachine.withContext({
    rootStore,
    emailForSignIn,
    lastEmailSigninAttempted: ''
  });

export const createAuthMessageMachine = (rootStore) =>
  baseAuthMessageMachine.withContext({
    rootStore,
    lastEmailSigninAttempted: ''
  });

// keyof requires type not object

export const baseAuthMessageMachine = createMachine({
  id: 'auth-message',
  initial: 'basic',
  context: {
    errorCode: '',
    errorMessage: ''
  },
  on: {
    [AuthMessageEvent.Error]: {
      target: AuthMessageState.Error,
      actions: assign({
        // translations if any
        errorCode: (context, event) => {
          const {
            payload: { error }
          } = event;
          logger.debug('error', event, error);
          if (error.message === 'missing device email') {
            return AuthErrorCode.MISSING_DEVICE_EMAIL;
          }

          return error.code;
        },
        errorMessage: (context, event) => {
          const {
            payload: { error }
          } = event;

          return error.message;
        }
      })
    }
  },
  states: {
    [AuthMessageState.Basic]: {},
    [AuthMessageState.Error]: {}
  }
});

export const lookupEmailForSignInWithEmail = ({ userStore, uiStateStore }) => {
  const profile = userStore.user.profile;
  const { app } = uiStateStore;

  return app.emailInputToValid || profile.email;
};

export const handleError = (app) => {
  // TODO use google message vs our own
  // TODO seems not go here but invalid action code
  // if (rootStore.uiStateStore.errorState.login === 'auth/invalid-email') {
  //   // already tried with request
  //   if (app.emailInputToValid) {
  //     errorMessageI18nKey = 'common.login.error.invalid-email';
  //     app.authStateService.send(AuthStateEvent.Reset);
  //   } else {
  //     // wrong state (local storage) email, clear both
  //     this.user.profile.email = null;
  //     // ask emaiil
  //     app.authUIStep1Service.send(AuthStateEvent.EmailRequest);
  //   }
  // }
};

export const initAuthStateService = (rootStore, uiStateStore) => {
  const { userStore } = rootStore;
  const emailForSignIn = lookupEmailForSignInWithEmail({
    userStore,
    uiStateStore
  });
  logger.debug('initAuthStateService emailForSignIn', emailForSignIn);

  return interpret(createAuthStateMachine(rootStore, emailForSignIn))
    .onTransition(async (state) => {
      const { userStore } = rootStore;
      const { app } = uiStateStore;
      logger.debug(
        `[authState]transition from:${uiStateStore.app.authState.value} to:${state.value}`
      );
      //  if (state.value === AuthState.Error) {
      //   // uiStateStore.errorState.login = (err || {}).code;
      //   logger.error('auth state show error if any', state.value);
      // }
      // check if init
      if (state.value === AuthState.Initializing) {
        // state.context.emailForSignIn = lookupEmailForSignInWithEmail({ userStore, uiStateStore });
        logger.debug(
          'Initializing, emailForSignIn',
          state.context.emailForSignIn
        );
      } else if (state.value === AuthState.SignInWithEmailInitiated) {
        logger.debug('SignInWithEmailInitiated');
      }

      app.authState = state;
    })
    .start();
};

// unlinked /  parallel is beter as we got error on connect too not only during first loggedin

export const initAuthMessageStateService = (rootStore, uiStateStore) =>
  interpret(createAuthMessageMachine(rootStore))
    .onTransition(async (state) => {
      logger.debug(
        `[authMessageState]transition from: ${uiStateStore.app.authMessageState.value} to: ${state.value}`
      );
      _.merge(uiStateStore.app.authMessageState, state);
      if (state.value === AuthMessageState.Error) {
        logger.debug(
          'error context',
          state.context.errorCode,
          state.context.errorMessage
        );
        state.context.rootStore.uiStateStore.toggleIsAuthDialogOpen(true);

        // uiStateStore.app.errorState.login = state.context.errorCode;
      }
    })
    .start();

// base on context we allow some explicit
// otherwise it will be traverse according to the state
// don't mark isCompleted but instead use guard

// TODO another authorization services for segments related
export enum AuthErrorCode {
  INVALID_EMAIL = 'auth/invalid-email',
  INVALID_PASSWORD = 'auth/invalid-password',
  INVALID_CUSTOM_TOKEN = 'auth/invalid-custom-token',
  INVALID_OOB_CODE = 'auth/invalid-action-code',
  // Google setup to avoid  session fixation attacks
  // https://firebase.google.com/docs/auth/web/email-link-auth
  MISSING_DEVICE_EMAIL = 'auth/wq-missing-device-email',
  INVALID_DEVICE_EMAIL = 'auth/wq-invalid-device-email',
  // For line
  CUSTOM_TOKEN_MISMATCH = 'auth/custom-token-mismatch'
}
// TODO ensure won't keep trying. clear existing errorCode before retry
// decouple ui state dependency (e.g. errorState) / shown error message
// export const checkEmailForSignInWithEmailLink = async (profileEmail, emailInputToValid, errorCode, signInWithEmailLink) => {
//   // ask user to entry
//   // prioritize, user entry
//   if (emailInputToValid) {
//     return signInWithEmailLink(emailInputToValid);
//     // if entered but incorrect, AuthErrorCode.INVALID_EMAIL
//   } if (profileEmail) {
//     // avoid keep signin at first load
//     return signInWithEmailLink(profileEmail);
//   } if (errorCode === AuthErrorCode.MISSING_DEVICE_EMAIL) {
//     // missing profileEmail
//
//
//     // original profile email (populated by cookies) could be incorrect for this link.
//     // we only know after fail to login
//   } else if (errorCode === AuthErrorCode.INVALID_EMAIL) {
//     // ask to entry
//     return Promise.reject(AuthErrorCode.INVALID_DEVICE_EMAIL);
//   }
// };

export const updateProfileWithLineUser = (rootStore, lineUser) => {
  const { userStore } = rootStore;

  if (lineUser) {
    _.merge(userStore.user.profile, {
      idLine: lineUser.lineId
    });
  }
};

export const setupUserProfile = async (rootStore, user) => {
  const { userStore } = rootStore;
  _.merge(userStore.user.profile, fromFirebase(user));
  logger.debug(
    'rootStore.userStore.user.profile',
    userStore.user.profile,
    user
  );

  // Custom claims can only be retrieved through the user's ID token. Access to these claims may be necessary to modify the client UI based on the user's role or access level.

  // firebase.auth().currentUser.getIdTokenResult()
  //   .then((idTokenResult) => {
  //
  // https://firebase.google.com/docs/reference/node/firebase.auth.IDTokenResult
  if (!firebase.auth().currentUser) {
    logger.error('no user in firebase');

    return;
  }
  // const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
  const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
  const { claims, token } = idTokenResult || {};
  logger.debug('idTokenResult', idTokenResult);

  _.merge(userStore.user.idTokenResult, idTokenResult);
  _.merge(userStore.user.profile.claims, claims);
};

export const parseLineUserResult = (lineUserResult) => {
  if (!lineUserResult) {
    return {};
  }
  // eslint-disable-next-line
  const { id_token, profile } = lineUserResult;

  const lineId = _.get(profile, 'sub');

  return {
    lineId,
    id_token
  };
};

// ensure we allow connecting line id to existing account
export const requestCustomTokenWithLine = (lineUser, profile) => {
  const [url, params] = createFetchParams<UserActionRequestedPOST>(
    HttpEndpointName.UserActionRequested,
    {
      action: 'line-login',
      user: {
        profile,
        ...(lineUser || {})
      }
      // continueUrl
    }
  );
  logger.debug('custom token params', params);

  return fetch(url, params).then((res) => res.json());
};

// hack for easier testing
export const createOnLineAuthSuccessCallback =
  (rootStore, requestCustomToken = requestCustomTokenWithLine) =>
  async (lineUserResult) => {
    logger.debug('signinRedirect ok', lineUserResult);
    const { userStore } = rootStore;

    const lineUser = parseLineUserResult(lineUserResult);

    const res = await requestCustomToken(lineUser, userStore.user.profile);
    const { token } = res || {};
    try {
      logger.debug('custom token res', res);
      if (!token) {
        throw new Error('Incorrect token', res);
      }
      // in case of create, need to either pre-create or enrich the profile with line id etc
      const signinResult = await firebase.auth().signInWithCustomToken(token);
      logger.debug('line signin result', signinResult);

      // avoid patching here but instead let statechart do so
      // in case different lineId then db due to created different user or not ready yet
      rootStore.uiStateStore.app.authStateService.send({
        type: AuthStateEvent.FirebaseAuthProcessSuccess,
        payload: {
          lineUser,
          signinResult,
          user: signinResult
        }
      });

      return signinResult;
    } catch (err) {
      logger.error('Error in LINE login', err);
      rootStore.uiStateStore.app.authStateService.send({
        type: AuthStateEvent.FirebaseAuthProcessFail,
        payload: {
          error: err
        }
      });
    }
  };

export const createOnAuthStateChangedCallback =
  ({ rootStore }) =>
  async (user) => {
    logger.debug('onAuthStateChanged', user);
    const { uiStateStore, userStore } = rootStore;
    const { app } = uiStateStore;

    if (!user) {
      const search = uiStateStore.routeMatch.search || {};
      if (search.b) {
        window.location.href = search.b;
      }
      userStore.resetProfile();
      app.authStateService.send(AuthStateEvent.Reset);
      // if (isSignInWithEmailLink) {
      //   // not yet logged in
      //   // TODO we should not set default errorState before login
      //   uiStateStore.errorState.login = AuthErrorCode.MISSING_DEVICE_EMAIL;
      //   app.isAuthDialogOpen = true;
      // }
    } else {
      // transition to loaded
      // distinguish new sign up
      await setupUserProfile(rootStore, user);
      if (window.analytics) {
        // avoid anoynmous for segment quota
        let identifyUserId = getFallbackUserId();

        if (userStore.user.profile.id) {
          identifyUserId = userStore.user.profile.id;
        }
        window.analytics.identify(
          identifyUserId,
          cleanProfile(userStore.user.profile)
        );
      }
      app.authStateService.send({
        type: AuthStateEvent.FirebaseLoadProfileComplete,
        payload: {
          user: rootStore.user
        }
      });
    }
  };
