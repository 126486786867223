import React, { useEffect, useRef, useMemo, useState } from 'react';
import _ from 'lodash';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import {
  MountOnce,
  WaterMark,
  DebugOnly,
  appendScriptInBody
} from '@wordquest/ui-components/page-util';
import logger from '@wordquest/lib-iso/app/logger';
import RichText from '@wordquest/ui-components/text/rich-text';
import { RichTextContainer } from '@wordquest/ui-components/text/rich-text-container';
import CourseOutline from '@wordquest/ui-components/course/outline';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { toJS } from 'mobx';
import querystring from 'qs';
import CourseIntro from '@wordquest/ui-components/course/course-intro';
import { observer } from 'mobx-react';
import { useRootStore } from '~/stores/root';
import isEqual from 'react-fast-compare'; //eslint-disable-line
import Container from '@material-ui/core/Container';
import { getCourseUid } from '@wordquest/lib-iso/domain/wordquest/course/course';
import SimplybookWidget from '@wordquest/ui-components/vendor/simplybook';
import { Locale } from '@wordquest/locales';
import {
  formatWithLocale,
  loadAndAddMessagesWithLocaleModule
} from '@wordquest/lib-iso/intl';
import { createOnClickCTA } from '@wordquest/ui-components/course/course-cta';
import CourseHead from './course-head';
import { CourseFormStrapi } from '../components/course-form-strapi';
import CarrouselGroups from '@wordquest/ui-components/carrousel-groups';
import { renderEmbededComponent } from '~/services/embeded-components';
import {
  getCourseTitleWithGroupKey,
  asOrderedCardsByGroup
} from '@wordquest/ui-components/course/course-card-groups';
import { filterRecommendedCoursesByGroup } from '~/stores/ui/page-course';

const StyledSignInNudge = styled.span`
  padding-right: 2rem;
`;
// offset top bar when needed
// ${media.phone`
//   padding-top: 0px;
//   `}
const StyledCourseContentForm = styled.div`
  /* padding-top: 64px; */
  background-color: ${(props) => props.backgroundColor};
  padding-bottom: 5rem;
`;
// important to pass attributeByKey with toJS
const CourseContentForm = React.memo((props) => {
  const {
    profileId,
    course,
    courseUserStatus,
    context,
    formProps,
    formSubmissionType,
    onClickLogin,
    onClickCTA,
    paperformSlug,
    errorState,
    attributeByKey,
    currentCourseRef,
    backgroundColor,
    courseFormMeta,
    courseFormBody,
    embededComponentMetas,
    locale,
    trackEvent,
    testimonials
  } = props;
  // useTheme();
  const courseMeta = _.pick(course, [
    'targetLocale',
    'bookingUrl',
    'startAt',
    'endAt',
    'courseTypes',
    'originalPrice',
    'offerPrice',
    'ctaLabel',
    'ctaDescriptionRichText',
    'challengeDateDescription',
    'teachers',
    'teacherAssistants'
  ]);

  courseMeta.isCoach = !!(_.get(course, 'key') || '').match(/-teacher-/);

  const ctaTargetRef = useRef();

  logger.debug('mount content form');

  return (
    <StyledCourseContentForm backgroundColor={backgroundColor}>
      <DebugOnly
        data={{
          course: _.pick(course, ['id', 'key']),
          formProps,
          courseUserStatus
        }}
      />
      {errorState.course ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ minHeight: '50vh' }}
        >
          <Grid item xs={12} justify="center" sm={12} lg={12}>
            <Typography variant="h5" align="center">
              <FormattedMessage id="course.missing" />
            </Typography>
          </Grid>
        </Grid>
      ) : course ? (
        <CourseContent
          courseIntro={course.courseIntro}
          coursePlans={course.coursePlans}
          courseUserStatus={courseUserStatus}
          onClickCTA={onClickCTA}
          courseMeta={courseMeta}
          heroImageUrl={course.heroImageUrl}
          descriptionRichText={course.descriptionRichText}
          context={context}
          ctaTargetRef={ctaTargetRef}
          testimonials={testimonials}
          trackEvent={trackEvent}
        />
      ) : (
        <LoaderGeneral />
      )}
      <Container maxWidth="lg">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} style={{ paddingBottom: '3rem' }}>
            {course ? (
              <CourseOutline
                title={currentCourseRef.title}
                courseKey={currentCourseRef.key}
                courseSections={course.courseSections}
                attributeByKey={attributeByKey}
                linkRoot={currentCourseRef.linkRoot}
                isExpanded={false}
              />
            ) : (
              <LoaderGeneral />
            )}
          </Grid>
        </Grid>
      </Container>
      <div id="simplybook" />
      {course && !(formSubmissionType === 'paid') && (
        <div key={profileId}>
          <Grid container justify="center" alignItems="center">
            {paperformSlug && !profileId && (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h4"
                  align="center"
                  gutterBottom
                >
                  <StyledSignInNudge>
                    <FormattedMessage id="common.course.form.signup" />
                  </StyledSignInNudge>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickLogin}
                  >
                    <FormattedMessage id="common.login" />
                  </Button>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <CourseForm
                key={paperformSlug}
                ref={ctaTargetRef}
                courseFormMeta={courseFormMeta}
                courseFormBody={courseFormBody}
                currentCourseKey={currentCourseRef?.key}
                embededComponentMetas={embededComponentMetas}
                onClickCTA={onClickCTA}
                paperformProps={formProps}
                paperformSlug={paperformSlug}
                formSubmissionType={formSubmissionType}
                trackEvent={trackEvent}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </StyledCourseContentForm>
  );
}, isEqual);

export const CourseContent = ({
  courseUserStatus,
  courseIntro,
  coursePlans,
  onClickCTA,
  ctaTargetRef,
  courseMeta,
  heroImageUrl,
  descriptionRichText,
  context,
  testimonials,
  trackEvent
}) => {
  const courseIntroWithFallback = _.merge({}, courseIntro, { heroImageUrl });

  return (
    <Grid container>
      <CourseIntro
        key={_.get(courseIntro, 'id')}
        courseMeta={courseMeta}
        courseIntro={courseIntroWithFallback}
        coursePlans={coursePlans}
        context={context}
        ctaTargetRef={ctaTargetRef}
        onClickCTA={onClickCTA}
        courseUserStatus={courseUserStatus}
        testimonials={testimonials}
        trackEvent={trackEvent}
      />
      <Grid item xs={12} lg={8}>
        <Typography color="inherit" variant="h5" component="div">
          <RichTextContainer wqDom={descriptionRichText} renderData={context}>
            <RichText />
          </RichTextContainer>
        </Typography>
      </Grid>
    </Grid>
  );
};

// TODO now workaround the chinese answer, as limitation at paperform
// not to use index as some options might not exists for some forms
const createSubmissionTypeAnswerProps = (submissionType) => {
  const paperformSubmissionTypeAnswer =
    {
      new: '新訂購',
      trial: '免費試閱',
      renewal: '讀訂'
    }[submissionType] || '';

  return { 'submission-type': paperformSubmissionTypeAnswer };
};

// Ensure when scroll paperform reach to top
const StyledCourseForm = styled.div`
  min-height: 50rem;
  > div:empty {
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const CourseForm = React.memo(
  React.forwardRef((props, ref) => {
    logger.debug('mount CourseForm', JSON.stringify(props));
    const {
      locale,
      paperformSlug,
      paperformProps,
      currentCourseKey,
      courseFormMeta,
      courseFormBody,
      formSubmissionType,
      trackEvent,
      embededComponentMetas = []
    } = props;

    return (
      <StyledCourseForm ref={ref}>
        {embededComponentMetas.map((meta) => renderEmbededComponent(meta))}
        {!_.isEmpty(courseFormMeta) ? (
          <CourseFormStrapi
            locale={locale}
            courseFormMeta={courseFormMeta}
            courseFormBody={courseFormBody}
            courseKey={currentCourseKey}
            trackEvent={trackEvent}
          />
        ) : (
          paperformSlug && (
            <div
              style={{ minHeight: '400px' }}
              id="paperform"
              data-paperform-id={paperformSlug}
              data-prefill={querystring.stringify(
                _.merge(
                  paperformProps,
                  createSubmissionTypeAnswerProps(formSubmissionType)
                )
              )}
              data-prefill-inherit="1"
            />
          )
        )}
      </StyledCourseForm>
    );
  })
);

export default observer(() => {
  const rootStore = useRootStore();
  const { tipStore } = rootStore;
  const { app } = rootStore.uiStateStore;
  const {
    recommendedCoursesByGroup,
    paperformSlug,
    currentCourseRef,
    currentCourse: course,
    currentCourseFormMeta,
    currentCourseFormBody,
    goNextLesson,
    courseUserStatus,
    paperformMeta,
    currentCourseIntroEntitiesByNodeType,
    attributeByKey,
    embededComponentMetas,
    currentTestimonials
  } = rootStore.pageCourseStore;

  const nonCoachRecommendedCoursesByGroup = filterRecommendedCoursesByGroup(
    recommendedCoursesByGroup,
    false
  );
  const nonCoachOrderedCardsByGroup = asOrderedCardsByGroup({
    coursesByGroup: nonCoachRecommendedCoursesByGroup,
    type: 'online'
  });

  const coachRecommendedCoursesByGroup = filterRecommendedCoursesByGroup(
    recommendedCoursesByGroup,
    true
  );
  const coachOrderedCardsByGroup = asOrderedCardsByGroup({
    coursesByGroup: coachRecommendedCoursesByGroup,
    type: 'coach'
  });

  const trackEvent = (event) => rootStore.eventStore.trackEvent(event);

  import(/* webpackPreload: true */ 'firebase/firestore');
  app.isSidebarOpen = false;
  const { profile } = rootStore.userStore.user;
  const onClickLogin = () => {
    rootStore.uiStateStore.app.isAuthDialogOpen = true;
  };
  const authorById = _.keyBy(currentCourseIntroEntitiesByNodeType.author, 'id');
  const context = {
    authorById,
    quoteById: toJS(tipStore.quoteById),
    glossaryById: toJS(tipStore.glossaryById),
    glossaryProps: {
      isExpanded: true
    }
  };

  // to avoid showing paperform 404
  // support "disable"
  // otherwise we tell paperform is 404 either by dom query OR another GET

  // note delay as we load FS first
  logger.debug('mount course', (course || {}).id, profile.id);

  const backgroundColor =
    _.get(course, 'course.courseIntro.theme.backgroundColor') || 'transparent';

  const { formSubmissionType } = rootStore.pageCourseStore;
  const markKey = getCourseUid(course);
  // for paid user
  // show your subscription status (start/end) here
  // show paperform only if re-subscribe?

  const courseKey = (course || {}).key;

  const onClickCTA = useMemo(
    () =>
      createOnClickCTA({
        courseKey,
        courseUserStatus,
        goNextLesson,
        trackEvent: (event) => rootStore.eventStore.trackEvent(event)
      }),
    [courseKey, courseUserStatus]
  );

  // use either offset or set top
  return (
    <Box width={1}>
      {course && (
        <CourseHead
          course={course}
          title={course.title}
          description={course.descriptionShort}
          locale={app.userLocale}
        />
      )}
      <CourseContentForm
        course={course}
        currentCourseRef={toJS(currentCourseRef)}
        context={context}
        attributeByKey={toJS(attributeByKey)}
        onClickLogin={onClickLogin}
        onClickCTA={onClickCTA}
        courseUserStatus={courseUserStatus}
        formSubmissionType={formSubmissionType}
        formProps={paperformMeta.formProps}
        paperformSlug={paperformSlug}
        errorState={rootStore.uiStateStore.errorState}
        profileId={profile.id}
        backgroundColor={backgroundColor}
        courseFormMeta={currentCourseFormMeta}
        courseFormBody={currentCourseFormBody}
        embededComponentMetas={embededComponentMetas}
        locale={app.userLocale}
        trackEvent={trackEvent}
        testimonials={currentTestimonials}
      />
      <MountOnce
        fn={() => {
          appendScriptInBody({ src: '//paperform.co/__embed' });
        }}
      />
      <MountOnce
        fn={() => {
          appendScriptInBody({
            src: '//wchat.freshchat.com/js/widget.js',
            onload: rootStore.uiStateStore.onLoadFreshChat,
            id: 'freshchat-jssdk'
          });
        }}
      />
      <MountOnce
        fn={() => {
          appendScriptInBody({
            src: '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5b3883823fc5f76d'
          });
        }}
      />
      <Container maxWidth="lg">
        <CarrouselGroups
          isCarrousel
          groupOrders={_.keys(nonCoachOrderedCardsByGroup)}
          orderedCardsByGroup={nonCoachOrderedCardsByGroup}
          getGroupTitleWithGroupKey={getCourseTitleWithGroupKey('online')}
        />
      </Container>
      <Container maxWidth="lg">
        <CarrouselGroups
          isCarrousel
          groupOrders={_.keys(coachOrderedCardsByGroup)}
          orderedCardsByGroup={coachOrderedCardsByGroup}
          getGroupTitleWithGroupKey={getCourseTitleWithGroupKey('coach')}
        />
      </Container>
      <WaterMark key={markKey} markKey={markKey} />
    </Box>
  );
});
