import React from 'react';
import _ from 'lodash';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { media } from '~/style-utils';
import TestimonialCard from './testimonial-card';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Autoplay, Pagination]);

const StyledSwiper = styled(Swiper)`
  & .swiper-slide {
    box-sizing: border-box;
    padding: 0.5rem;
  }
  & .swiper-pagination {
    bottom: 1.5rem;
  }
`;

const TestimonialSwiper = ({ testimonials = [] }) =>
  !_.isEmpty(testimonials) && (
    <StyledSwiper
      pagination={{
        clickable: true
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
    >
      {testimonials.map((testimonial) => {
        const { userAvatar, userName, userTitle, descriptionRichText } =
          testimonial;
        const avatarUrl = userAvatar?.[0]?.url ?? '';

        return (
          <SwiperSlide key={avatarUrl}>
            <TestimonialCard
              avatarUrl={avatarUrl.includes('https://') ? avatarUrl : undefined}
              userName={userName}
              userTitle={userTitle}
              descriptionRichText={descriptionRichText}
            />
          </SwiperSlide>
        );
      })}
    </StyledSwiper>
  );
export default TestimonialSwiper;
