import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { media } from '~/style-utils';
import { SITE_ROOT_UI_SITE } from '@wordquest/lib-iso/env';
import { Box } from '@material-ui/core';

const StyledFooter = styled.div`
  border-top: 1px solid #00000012;
  div {
    text-align: left !important;
  }
  ${media.desktop`
    padding: 32px 64px;
  `}
  ${media.phone`
    padding-bottom: 64px;
    .text-wrapper {
      margin: 1em 0;
    }
  `}
  ${media.laptop`
    .content-wrapper > div > :first-child {
      margin-bottom: 1rem;
    }
  `}
  ${media.tablet`
    .content-wrapper > div > :first-child {
      margin-bottom: 1rem;
    }
  `}
  .content-wrapper {
    padding: 1em;
  }
  .text-wrapper span {
    display: block;
    font-size: 1.5em;
  }
  img {
    ${media.desktop`
      max-width: 12em;
    `}
    margin-bottom: 1em;
  }
  background-color: #f5f5f5;
  color: #000000;
  & a {
    text-decoration: none;
    color: #000000;
    font-size: 0.8em;
  }
  margin-bottom: 56px;
  .contact-header {
    margin-top: 1rem;
  }
  address {
    font-style: normal;
    margin-top: 0.8rem;
  }
`;

const Footer = () => (
  <StyledFooter>
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={12} md={12}>
        <div className="content-wrapper">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={3}>
              <img
                src={`${SITE_ROOT_UI_SITE}/static/index_v2/logo/PeraPera_logo.svg`}
                alt="logo"
              />
              <Typography variant="body2" color="inherit">
                PeraPera © All Rights Reserved.
              </Typography>
              <Typography
                className="contact-header"
                variant="body2"
                color="inherit"
              >
                <FormattedMessage id="footer.contact" />
              </Typography>
              <Typography component="address" variant="body2" color="inherit">
                <FormattedMessage id="footer.office-address.tw" />
              </Typography>
              <Typography component="address" variant="body2" color="inherit">
                <FormattedMessage id="footer.office-address.sg" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <div className="text-wrapper">
                <Link href={`${SITE_ROOT_UI_SITE}/courses/`}>
                  <FormattedMessage id="landing.navigation.course" />
                </Link>
                <Link href={`${SITE_ROOT_UI_SITE}/watch/`}>
                  <FormattedMessage id="landing.navigation.explore" />
                </Link>
                <Link href={`${SITE_ROOT_UI_SITE}/posts/`}>
                  <FormattedMessage id="landing.navigation.blog" />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <div className="text-wrapper">
                <Link
                  href={`${SITE_ROOT_UI_SITE}/course/landing-page-for-coach`}
                >
                  <FormattedMessage id="footer.coach" />
                </Link>
                <Link href="https://www.cakeresume.com/companies/peraperaai/jobs">
                  <FormattedMessage id="footer.jobs" />
                </Link>
                <Link href="mailto:info@perapera.ai">
                  <FormattedMessage id="footer.contact" />
                </Link>
                <Link href={`${SITE_ROOT_UI_SITE}/post/privacy-policy/`}>
                  <FormattedMessage id="footer.privacy" />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <div className="text-wrapper">
                <Link href="https://www.facebook.com/peraperaai/">
                  <span>Facebook</span>
                </Link>
                <Link href="https://www.youtube.com/channel/UCkKxQS1aOui3TpbXe1XxOCA/">
                  <span>Youtube</span>
                </Link>
                <Link href="https://pse.is/3jxk5t">
                  <span>Instagram英文站 @en.perapera</span>
                </Link>
                <Link href="https://pse.is/3kb2ny">
                  <span>Instagram韓文站 @kr.perapera</span>
                </Link>
                <Link href="https://pse.is/3jlwrn">
                  <span>Instagram日文站 @ja.perapera</span>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={3} />
    </Grid>
    {/* <div className="fb-customerchat"
      greeting_dialog_display="show"
      page_id="160434001324160"
    /> */}
  </StyledFooter>
);

export default Footer;
