import _ from 'lodash';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import JobPost from '@wordquest/lib-iso/domain/wordquest/job/job-post';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import { asJoinedComponents } from '~/intl-util';
import { Locale } from '@wordquest/locales';
import { Typography } from '@material-ui/core';

const StyledChip = styled.div`
  > div {
    font-size: 1rem;
    margin: 0.3em;
  }
  .MuiChip-label {
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const StyledOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  vertical-align: bottom;
  > div {
  }
`;

const StyledFilterSelect = styled.div`
  > div {
    /* width: 100%; */
    float: left;
    /* margin-right: 1em; */
  }
  .MuiSelect-selectMenu.MuiSelect-selectMenu {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  li .MuiChip-label {
    font-size: 1rem;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  font-size: 1rem;
`;

const renderAsLabels = ({ getLabelByKey, selectedKeys }) =>
  asJoinedComponents(
    Locale.EN,
    selectedKeys.map((key) => (
      <Typography variant="h5" component="div">
        {getLabelByKey(key)}
      </Typography>
    )),
    <Typography variant="h5" component="div" style={{ 'white-space': 'pre' }}>
      {', '}
    </Typography>
  );

const renderAsChip = ({ getLabelByKey, selectedKeys }) => (
  <StyledChip>
    {selectedKeys.map((key) => (
      <Chip key={key} color="primary" label={getLabelByKey(key) || ''} />
    ))}
  </StyledChip>
);

const asArray = (value) => (_.isArray(value) ? value : [value]);

// TODO extract jobs
export const createFilterMultiSelect = ({
  multiple = true,
  fieldKey,
  filterByField,
  isShowInputLabel = true,
  isShrinkInputLabel = false,
  setFilterByField,
  options,
  getOptionKey,
  getOptionLabel = _.identity,
  isRenderAsChip = true,
  // TODO deprecate titleKey
  i18nTitleKey,
  i18nTitle
}: {
  fieldKey: string;
  filterByField: object;
  setFilterByField: () => {};
  options: any[];
  getOptionKey: (any) => string | FormattedMessage;
  getOptionLabel: (any) => string | FormattedMessage;
  i18nTitleKey: string;
}) => {
  // limitation at html/ material-ui, MenuItem value better as key instead of object. related: https://github.com/mui-org/material-ui/issues/14286
  const currentFilter = filterByField[fieldKey];

  const findOptionWithKey = (key) =>
    _.find(options, (o) => getOptionKey(o) === key) || {};

  const labelId = `select-multiple-${fieldKey}`;

  return (
    <StyledFilterSelect>
      <FormControl>
        {isShowInputLabel && (
          <StyledInputLabel
            shrink={isShrinkInputLabel}
            htmlFor={labelId}
            id={labelId}
          >
            {i18nTitle || <FormattedMessage id={i18nTitleKey} />}
          </StyledInputLabel>
        )}
        <Select
          multiple={multiple}
          labelId={labelId}
          value={asArray(currentFilter).map(getOptionKey)}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const { value } = event.target as HTMLSelectElement;
            // bug of material ui failed to deduplicate
            // TODO need manual care if use hooks
            setFilterByField(
              fieldKey,
              asArray(value).map(findOptionWithKey),
              value
            );
          }}
          input={<Input id={`select-multiple-${fieldKey}`} />}
          renderValue={(selectedKeys) => {
            const getLabelByKey = (key) =>
              getOptionLabel(findOptionWithKey(key));

            const render = isRenderAsChip ? renderAsChip : renderAsLabels;

            return (
              <StyledOptions>
                {render({
                  selectedKeys,
                  getLabelByKey
                })}
              </StyledOptions>
            );
          }}
        >
          {options.map((o) => (
            <MenuItem key={getOptionKey(o)} value={getOptionKey(o)}>
              <Checkbox
                checked={
                  _.findIndex(
                    currentFilter,
                    (selected) => getOptionKey(o) === getOptionKey(selected)
                  ) > -1
                }
              />
              <ListItemText primary={getOptionLabel(o) || ''} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledFilterSelect>
  );
};

export const createLanguageMultipleSelect = (options: {
  fieldKey: string;
  options: { locale: Locale }[];
  filterByField: any;
  setActiveLocales: () => any;
}) =>
  createFilterMultiSelect({
    i18nTitleKey: 'common.select.language',
    isRenderAsChip: false,
    isShrinkInputLabel:
      !_.isBoolean(options.isShrinkInputLabel) || options.isShrinkInputLabel,
    getOptionLabel: ({ locale }: { locale: Locale }) => (
      <FormattedMessage id={`common.language.${locale}`} />
    ),
    getOptionKey: ({ locale }) => locale,
    setFilterByField: (fieldKey, activeLocalesOptions, activeLocales) => {
      options.setActiveLocales(activeLocales);
    },
    ...options
  });
