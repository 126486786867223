import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import NotesIcon from '@material-ui/icons/Notes';
import { SITE_ROOT_UI_SITE, SITE_ROOT_UI_MAIN } from '@wordquest/lib-iso/env';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

// import clsx from 'clsx';
import useScrollPosition from '@react-hook/window-scroll';
import {
  media,
  mediaMui,
  isSizeUp,
  isWindowMuiBreakpointUp
} from '~/style-utils';

const StyledBottomCTA = styled.div`
  z-index: 999;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* for fade */
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  > div {
    padding: 1rem;
    ${mediaMui.sm`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    `}
    ${mediaMui.xs`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    `}
  }
  s {
    color: gray;
  }
`;

// there are cases for not active e.g. jobs
export default ({ ctaButton, originalPrice, offerPrice, isHidden }) => (
  <StyledBottomCTA isHidden={isHidden}>
    <Fade in={!isHidden}>
      <Paper elevation={8}>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item xs={6}>
            <Typography variant="h6" component="h4">
              {offerPrice}
            </Typography>
            <Typography variant="body2" component="div">
              <s>{originalPrice}</s>
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            {ctaButton}
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  </StyledBottomCTA>
);
