import { observable, toJS, isObservableObject } from 'mobx';
import _ from 'lodash';
/* For Events, & as the data layer to redistribute events for tracking */
import logger from '@wordquest/lib-iso/app/logger';
import track from '@wordquest/ui-components/util/track';
import { UserEvent } from '@wordquest/lib-iso/domain/wordquest/event/user-event';
import { mapAppEventAsUserEvent } from '@wordquest/lib-iso/domain/wordquest/event/mappers';

export default class EventStore {
  events = observable([]);

  plugins = observable([]);

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.events.observe((change) => {
      change.added.forEach((lastEvent) => {
        // Same appEvent->userEvent transformation is applied at CF to persist them
        // happy path before user login unless we always use analymousId, but let segment handle it
        const { profile, idToken } = this.rootStore.userStore.user;
        if (!profile.id) {
          // if (window.Raven) {
          //   window.Raven.captureException(new Error('No uid'), {
          //     extra: {
          //       profile,
          //       lastEvent
          //     }
          //   });
          // }
          logger.trace('[Event]track No uid', profile, lastEvent);
        }
        const extraMeta = {
          // segment already did it
          // userId:profile.id,

          userIdToken: idToken
        };

        const plugins = toJS(this.plugins);

        track(_.merge(lastEvent, extraMeta), {
          plugins
        });
        //
        const userEvent: UserEvent = mapAppEventAsUserEvent(lastEvent);

        this.rootStore.userStore.pushUserEventByEntity(userEvent);
      });
    });
  }

  /**
   * We could support a reactive context Object
   * e.g. enrich Wod-LookedUp with current Article id
   */
  trackEvent(event) {
    logger.debug('[Event]track', JSON.stringify(toJS(event)), this);
    this.events.push(toJS(event, { recurseEverything: true }));
  }

  // TODO https://github.com/wordquest/wordquest/issues/610
  // trackUserEvent with direct entity, action separation
}
