import { Locale } from '@wordquest/locales';
// wordId is dbId instead of text
export default class Definition {
  constructor(
    wordId?: string,
    locale: Locale,
    gloss: string,
    source: string = '',
    pos: string[] = [],
    langMeta = {},
    id: string
  ) {
    this.wordId = wordId;
    this.locale = locale;
    this.gloss = gloss;
    this.source = source;
    this.pos = pos;
    this.langMeta = langMeta;
    if (id) {
      this.id = id;
    }
  }

  wordId: string;

  locale: Locale;

  pos: string[];

  gloss: string;

  source: string;
  // id?: string;

  static create(obj: object): Definition {
    const { wordId, locale, pos, gloss, source, langMeta, id } = obj;

    return new Definition(wordId, locale, gloss, source, pos, langMeta, id);
  }
}
