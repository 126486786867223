import _ from 'lodash';
import React from 'react';
import { RenderLeafProps } from './rich-text';

// leaf is part of Text, avoid render <Text/> again
export const defaultRenderLeaf = (props: RenderLeafProps, key) => {
  const { text } = props;

  // '\xa0' is &nbsp;
  return (
    <DefaultLeaf key={key} leaf={props} {...props}>
      {text || '\xa0'}
    </DefaultLeaf>
  );
};

// string.bold is a function
export const DefaultLeaf = (props: RenderLeafProps) => {
  const { attributes, children, leaf } = props;
  // for now use nested children for multiple properties
  let leafChildren = children;
  if (leaf.bold) {
    leafChildren = <strong>{children}</strong>;
  }

  if (leaf.code) {
    leafChildren = <code>{children}</code>;
  }

  if (leaf.italic) {
    leafChildren = <em>{children}</em>;
  }

  if (leaf.underline) {
    leafChildren = <u>{children}</u>;
  }

  return <span {...attributes}>{leafChildren}</span>;
};

// Text = leaf-level
//  we use Text directly for render Leaf
// https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/components/text.tsx
// https://github.com/ianstormtaylor/slate/blob/master/site/examples/richtext.js#L118

// text properties https://github.com/ianstormtaylor/slate/pull/3235

const Text = (props) => {
  const { text, leaf, renderLeaf } = props;
  if (renderLeaf) {
    return renderLeaf(props);
  }

  return defaultRenderLeaf(props);
};

export default Text;
