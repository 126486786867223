import _ from 'lodash';
import {
  WqDocument,
  WqNodeType,
  WqNode,
  WqNodeTypeBlock,
  WqNodeTypeInline
} from './dom';
// the information of what entites to load e.g. glossary is inside the markup
// we prefer to completely decouple the extraction process with rendering process
// neglect the extra pass on Tree, as early loading might help progressive loading in practices
// Note unavoidably we need to iterate the tree a few passes (progressively, render without these entites, then with) anyway

// TODO extract traverse

const DEFAULT_COLLECT_TYPES = [
  WqNodeTypeBlock.Quote,
  WqNodeTypeBlock.Author,
  WqNodeTypeBlock.AuthorNote,
  WqNodeTypeInline.Glossary,
  WqNodeTypeInline.Word,
  WqNodeTypeInline.Audio,
  WqNodeTypeInline.Video
];

export const collectMultipleRichTextsEntitiesByNodeType = (
  documents: WqDocument[],
  options: any = {
    isInclude: false
  },
  types: WqNodeType[] = DEFAULT_COLLECT_TYPES
) => {
  const documentsEntityByNodeType = _.map(documents, (d) =>
    collectRichTextEntitiesByNodeType(d, options, types)
  );

  return _.mergeWith({}, ...documentsEntityByNodeType, (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return _.uniqBy(objValue.concat(srcValue), 'id');
    }

    return objValue;
  });
};

function isEntityToExtract(node: WqNode, types) {
  return _.isEmpty(types) || _.includes(types, node.type);
}

// we copy the skeleton from here https://github.com/contentful/rich-text/blob/master/packages/rich-text-links/src/index.ts
// include only if WqDom will support it
/**
 *  Extracts entity links from a Rich Text document.
 */
export function collectRichTextEntitiesByNodeType(
  /**
   *  An instance of a Rich Text Document.
   */
  document: WqDocument,
  options: any = {
    isInclude: false
  },
  /**
   * Node types to be extracted.
   */
  types: WqNodeType[] = DEFAULT_COLLECT_TYPES
): Record<WqNodeType, any[]> {
  const entityByNodeType = {};

  const content = (document && document.children) || ([] as WqNode[]);
  for (const node of content) {
    addEntitiesFromDom(node, entityByNodeType, types, options);
  }

  return _.mapValues(entityByNodeType, (entityMap) =>
    iteratorToArray(entityMap.values())
  );
}

// dfs works better with text
// enforce orders in children
export const crawlNodes = (rootNode: WqNode, handleNode = _.noop) => {
  const toCrawl: WqNode[] = [rootNode];
  let index = 0;

  while (toCrawl.length > 0) {
    const currentNode = toCrawl.pop() as WqNode;
    if (Array.isArray(currentNode.children)) {
      // reverse will mutate
      _.forEachRight(currentNode.children, (c) => toCrawl.push(c));
    }
    handleNode(currentNode, index);
    // not allow to mutate tree
    index += 1;
  }
};

function addEntitiesFromDom(
  node: WqNode,
  entityByNodeType,
  types: WqNodeType[] = [],
  options = {}
): void {
  const extractEntitresFromNode = (currentNode) => {
    const { attributes = {}, data, children, type } = currentNode;
    // prefer Map to deduplicate for stable order, but back to array for easier usage
    // might benefit progressive loading (think loading first part of article first )
    if (currentNode.type && isEntityToExtract(currentNode, types)) {
      if (currentNode.type === WqNodeTypeInline.Audio) {
        const url = _.get(attributes, 'url') || '';
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        attributes.id = fileName;
      }
      if (!entityByNodeType[currentNode.type]) {
        entityByNodeType[currentNode.type] = new Map();
      }
      if (attributes && attributes.id) {
        if (options.isInclude) {
          entityByNodeType[currentNode.type].set(
            attributes.id,
            data || attributes
          );
        } else {
          // TODO use {attributes}
          entityByNodeType[currentNode.type].set(attributes.id, attributes);
        }
      }
    }
  };

  crawlNodes(node, extractEntitresFromNode);
}

/**
 * Used to convert the EntityLink iterators stored by the EntityLinkMap values
 * into a client-friendly array form.
 *
 * Alternately we could do:
 * 1) Array.from(EntityLinkMap)
 * 2) [...EntityLinkMap]
 *
 * #1, although idiomatic, is about half as slow as #2.
 *
 * #2, while faster than #1, requires transpilation of the iterator protocol[1],
 * which in turn is still only about half as fast as the approach below.
 *
 * [1] See https://blog.mariusschulz.com/2017/06/30/typescript-2-3-downlevel-iteration-for-es3-es5.
 */
function iteratorToArray<T>(iterator: IterableIterator<T>): T[] {
  const result = [];

  while (true) {
    const { value, done } = iterator.next();
    if (done) {
      break;
    }
    result.push(value);
  }

  return result;
}
