import React from 'react';
import _ from 'lodash';
import articles from '@wordquest/lib-iso/domain/wordquest/article.fixture.js';
import ArticleCard from './article-card';
import SwipeableCardsContainer from '~/swipeable-cards-container';

const ArticleCarrousel: React.FC<{
  articles: any[];
  isShowThumbnail: boolean;
  isShowTags: boolean;
}> = (props: object) => {
  const {
    swiperKey = 'article-carrousel',
    classes,
    articles,
    maxSlidesPerView,
    withLink,
    isShowThumbnail = true,
    isShowTags = true
  } = props;
  if (!_.isArray(articles)) {
    return null;
  }
  const slides = articles.map((article) => (
    <div>
      <ArticleCard
        isShowThumbnail={isShowThumbnail}
        withLink={withLink}
        key={article.semanticKey}
        article={article}
        isShowTags={isShowTags}
      />
    </div>
  ));

  return (
    <SwipeableCardsContainer
      swiperKey={swiperKey}
      spaceBetween={0}
      centeredSlides={false}
      maxSlidesPerView={maxSlidesPerView}
    >
      {slides}
    </SwipeableCardsContainer>
  );
};

export default ArticleCarrousel;
