import _ from 'lodash';
// decouple roles computation with profile create time
// avoid confusion with jobRole

export type AuthorizedRole = string;

// already lkoaded
export const deriveAuthorizedRolesWithProfileClaims = (
  profile: Profile
): AuthorizedRole[] => {
  const claimByKey = profile.claims || {};
  // c for course, a for generic access control

  return _.filter(
    _.keys(claimByKey),
    (key) => _.isString(key) && key.match(/^(a|c)-/) && claimByKey[key]
  );
};

export const deriveAuthorizedRolesWithProfile = (
  profile: Profile
): AuthorizedRole[] => {
  const segments = profile.customerIOSegments || [];
  // c for course, a for generic access control

  return segments.filter((s) => _.isString(s) && s.match(/^(a|c)-/));
};

// @deprecated
export const checkIsProfileAuthorized = (profile, requiredRoles) => {
  const userAuthorizeedRoles = deriveAuthorizedRolesWithProfile(profile);

  // any match

  return checkIsAuthorized(userAuthorizeedRoles, requiredRoles);
};

export const checkIsAuthorized = (
  userAuthorizedRoles = [],
  requiredRoles = []
) =>
  _.isEmpty(requiredRoles) ||
  _.includes(requiredRoles, 'open') ||
  !_.isEmpty(
    _.intersection(userAuthorizedRoles.map(_.trim), requiredRoles.map(_.trim))
  );
