import _ from 'lodash';
import React, { lazy, Suspense, useContext } from 'react';
import './App.css';
import { Locale } from '@wordquest/locales';
import { IS_AGENT } from '@wordquest/lib-iso/env';
import NavbarContainer from '@wordquest/ui-components/navbar-container';
import NavbarSimpleContainer from '@wordquest/ui-components/navbar-simple-container';
// Not replacing Context.Provider in react currently https://github.com/mobxjs/mobx-react/issues/410
import { observer, inject } from 'mobx-react';
import withRoot from '@wordquest/ui-components/with-root';
import { Redirect, Route, Router, Switch } from 'react-router';
import ScrollToTopOnRouteChange from '@wordquest/ui-components/layout/scroll-to-top-on-route-change';
import Grid from '@material-ui/core/Grid';
import Footer from '@wordquest/ui-components/layout/footer';
import BottomNavigation from '@wordquest/ui-components/layout/bottom-navigation';
// import CurrentSnackBar from '~/components/snack-bar';
import styled from 'styled-components';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import { LocaleContext } from '@wordquest/ui-components/locale-context';
import Box from '@material-ui/core/Box';
import { ROUTES_CONFIG, ROUTES_CONFIG_SIDEBAR } from '~/services/route-matcher';
import PreviewBanner from '@wordquest/ui-components/preview-banner';
import logger from '@wordquest/lib-iso/app/logger';
import WordBox from '~/components/word-box';
import {
  MountOnce,
  DebugOnly,
  appendScriptInBody,
  StyledMainContainer
} from '@wordquest/ui-components/page-util';
// import Home from './routes/home';
// import Profile from './routes/profile';
// import ReadArticle from './routes/read-article';
import {
  StoreProvider,
  MobxReactProvider,
  useRootStore,
  useUiConfig,
  createRouterStoreWithHistory
} from './stores/root';
import Extensions from './extensions';

const { routerStore, history } = createRouterStoreWithHistory();

/*
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 * Browsers are starting to handle scroll restoration with history.pushState on
 * their own in the same manner they handle it with normal browser navigation. It already works in chrome and it's really great. Here's the Scroll Restoration Spec.
 */

const AppPreviewBanner = observer(() => {
  const rootStore = useRootStore();

  return rootStore.uiStateStore.routeMatch.isPreview ? <PreviewBanner /> : null;
});
const DefaultRedirect = observer(() => {
  const rootStore = useRootStore();
  const { uiConfig } = rootStore.uiStateStore;

  // <Redirect from="/liff/:path" to="/:path" /> not working
  return uiConfig.isLiffEnabled ? null : <Redirect from="/" to="/courses" />;
});

const AuthDialogContainer = lazy(() => import('./auth/auth-dialog-container'));
// Prefers verb > noun for routes (often mix of different entity, as in search)
// ignore onChange first as only 1 state
const BottomNavigationWithDefault = observer(() => {
  const rootStore = useRootStore();

  return (
    <BottomNavigation
      defaultTab={rootStore.uiStateStore.app.currentTab}
      history={rootStore.routerStore.history}
    />
  );
});

const StyledMain = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    width: 100%;
  }
  // justify-content: flex-end;
  // padding: theme.spacing(0, 1),
`;

// If the styled target is a simple element (e.g. styled.div), styled-components passes through any known HTML attribute to the DOM. If it is a custom React component (e.g. styled(MyComponent)), styled-components passes through all props.
// responsive to offset sidebar since we do that only at desktop
const StyledMainWithSidebarBox = styled(Box).withConfig({
  shouldForwardProp: (prop) =>
    !['isSidebarLeft', 'isSidebarOpen'].includes(prop)
})`
  display: flex;
  flex-direction: ${(props) =>
    props.isSidebarLeft ? 'inherit' : 'column-reverse'};
  .styledMain {
    width: calc(
      100% -
        ${(props) =>
          props.isSidebarLeft
            ? props.isSidebarOpen
              ? '239px'
              : '57px'
            : '0px'}
    );
  }
`;

// <main> is for https://web.dev/bypass/
const MainWithSidebarBox = observer(({ children }) => {
  const rootStore = useRootStore();
  const uiConfig = useUiConfig();
  const { isSidebarLeft } = uiConfig;

  return (
    <main>
      <StyledMainWithSidebarBox
        isSidebarLeft={isSidebarLeft}
        isSidebarOpen={rootStore.uiStateStore.app.isSidebarOpen}
      >
        {children}
      </StyledMainWithSidebarBox>
    </main>
  );
});

const DebugContainer = observer(() => {
  const rootStore = useRootStore();
  const { uiStateStore } = rootStore;

  return (
    <DebugOnly
      data={{
        test: '123',
        authState: _.get(uiStateStore, 'app.authState.value'),
        logs: uiStateStore.app.logs
      }}
    />
  );
});

// temp workaround before we remove provider, to avoid multiple rootStore
const LocaleContextContainer = ({ children }) => {
  // setup
  const { currentLocale, toggleLocale } = useContext(LocaleContext);

  const rootStore = useRootStore();
  rootStore.uiStateStore.app.userLocale = IS_AGENT
    ? Locale.ZH_TW
    : currentLocale;
  rootStore.uiStateStore.app.toggleLocale = toggleLocale;

  return children;
};

logger.debug('mount App');
export const App = () => (
  <StoreProvider routerStore={routerStore} storeConfig={{}}>
    <MobxReactProvider>
      <LocaleContextContainer>
        <Router history={history}>
          <ScrollToTopOnRouteChange>
            <div>
              <MountOnce
                fn={() => {
                  appendScriptInBody({
                    src: '//cdn.embedly.com/widgets/platform.js'
                  });
                }}
              />
              <Suspense fallback={<></>}>
                <AuthDialogContainer />
              </Suspense>
              <Extensions />
              {/* <Switch>
                <Route path="/course/:any" render={() => <></>} />
                <Route render={props => (<CurrentSnackBar {...props} />)} />
              </Switch> */}
              <AppPreviewBanner />
              <Switch>
                <Route
                  path="/read/:articleId"
                  render={(props) => <NavbarContainer depth={1} {...props} />}
                />
                <Route
                  path="/preview/course/:any"
                  render={(props) => (
                    <NavbarSimpleContainer depth={1} {...props} />
                  )}
                />
                <Route
                  path="/course/:any"
                  render={(props) => (
                    <NavbarSimpleContainer depth={1} {...props} />
                  )}
                />
                <Route
                  render={(props) => <NavbarContainer depth={0} {...props} />}
                />
              </Switch>
              <MainWithSidebarBox>
                <Switch>
                  {ROUTES_CONFIG_SIDEBAR.map((route) => (
                    <Route key={route.path} {...route} />
                  ))}
                  <Route render={(props) => null} />
                </Switch>
                <StyledMain className="styledMain">
                  <DebugContainer />
                  <Suspense fallback={<LoaderGeneral />}>
                    <StyledMainContainer maxWidth="xl" disableGutters>
                      <Grid container justify="center">
                        <Grid item xs={12} sm={12}>
                          <Switch>
                            {ROUTES_CONFIG.map((route) => (
                              <Route key={route.path} {...route} />
                            ))}
                            <DefaultRedirect />
                          </Switch>
                        </Grid>
                      </Grid>
                    </StyledMainContainer>
                  </Suspense>
                  <Switch>
                    <Route
                      path="/course/:courseKey/lesson/:lessonKey"
                      render={() => null}
                    />
                    <Route
                      path="/course/:courseKey"
                      render={() => <Footer key="footer" />}
                    />
                    <Route path="/preview/:anything" render={() => null} />
                    <Route
                      render={() => (
                        <>
                          <Footer key="footer" />
                          <BottomNavigationWithDefault />
                        </>
                      )}
                    />
                  </Switch>
                </StyledMain>
                <WordBox />
              </MainWithSidebarBox>
            </div>
          </ScrollToTopOnRouteChange>
        </Router>
      </LocaleContextContainer>
    </MobxReactProvider>
  </StoreProvider>
);

export default withRoot(App);
