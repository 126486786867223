import _ from 'lodash';
import React from 'react';
import { toJS } from 'mobx';
import { mediaMui } from '@wordquest/ui-components/style-utils';
import { observer } from 'mobx-react';
import MiniSidebar from '@wordquest/ui-components/layout/mini-variant-sidebar';
import styled from 'styled-components';
import Outline from '@wordquest/ui-components/course/outline';
import RootStore, { useRootStore } from '~/stores/root';

// won't work as not stick with topbar
const StyledSidebar = styled.div`
  > div > div {
    top: 64.5px;
    padding-bottom: 64.5px;
    background-color: #f2f4f8;
  }
`;

// const sidebarBottom =
// (
//   <>
//     <SidebarContent
//       isSidebarOpen={app.isSidebarOpen}
//       isSidebarLeft={uiConfig.isSidebarLeft}
//       toggleSidebar={null}
//       // toggleSidebarPosition={toggleSidebarPosition}
//     >
//       {sidebarContent}
//     </SidebarContent>
//     <Divider />
//   </>
// );

// TODO memo
// we can either hide before it has content -- useful when we flip the direction
// or show (look more progressive)
export default observer(() => {
  const rootStore: RootStore = useRootStore();
  const { uiConfig, app } = rootStore.uiStateStore;

  const { attributeByKey, currentCourseRef, currentCourse } =
    rootStore.pageCourseStore;
  const { type } = rootStore.uiStateStore.sidebar;
  // const { attributeByKey } = props;

  let sidebarContent = null;
  if (type === 'outline') {
    sidebarContent = (
      <Outline
        isScrollIntoView
        title={currentCourseRef.title}
        courseKey={currentCourseRef.key}
        courseSections={currentCourse.courseSections}
        attributeByKey={toJS(attributeByKey)}
        linkRoot={currentCourseRef.linkRoot}
      />
    );
  }

  if (_.isEmpty(sidebarContent)) {
    return <></>;
  }

  const toggleSidebar = (isSidebarOpen) => {
    rootStore.uiStateStore.app.isSidebarOpen = !!isSidebarOpen;
  };

  // we disabled this feature
  // const toggleSidebarPosition = (position:string) => {
  //   uiConfig.isSidebarLeft = position !== 'bottom';
  // };

  return (
    uiConfig.isSidebarLeft && (
      <StyledSidebar>
        <MiniSidebar
          isSidebarOpen={app.isSidebarOpen}
          isSidebarLeft={uiConfig.isSidebarLeft}
          toggleSidebar={toggleSidebar}
        >
          {sidebarContent}
        </MiniSidebar>
      </StyledSidebar>
    )
  );
});
