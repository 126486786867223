import _ from 'lodash';
import logger from '~/app/logger';
import { parse, toSeconds } from 'iso8601-duration';
import { format, parseISO, isValid } from 'date-fns';

export const isNotEmptyArray = (arr: any[] = []) =>
  _.isArray(arr) && !_.isEmpty(arr);

export const withPrintingProcessTime =
  (fn) =>
  async (...args) => {
    const startTime = new Date();
    const result = await fn(...args);
    const endTime = new Date();
    logger.debug(`process time:${endTime.getTime() - startTime.getTime()}ms`);

    return result;
  };

// TODO check against date-fns
// https://en.wikipedia.org/wiki/ISO_8601#Durations
export const parseDurationAsS = (duration) =>
  toSeconds(parse(duration || 'PT0M0S') || {});

export const formatSeconds = (seconds, isShowHours = false) => {
  // TODO use PTMS easier?
  const date = new Date(
    2099,
    0,
    0,
    Math.floor((seconds / 3600) % 24),
    Math.floor((seconds % 3600) / 60),
    seconds % 60
  );
  const dateFormat = isShowHours ? 'HH:mm:ss' : 'mm:ss';

  return format(date, dateFormat);
};

// workaround for https://github.com/wordquest/wordquest/issues/628
export const asValidISODate = (date) => {
  if (!date) {
    return;
  }
  const isoDate = parseISO(date);
  if (isValid(isoDate)) {
    return isoDate;
  }
};

export const parseIfStringify = (s) => (_.isString(s) ? JSON.parse(s) : s);
