import _ from 'lodash';
import { AuthorNote } from '~/domain/wordquest/tip/tip';
import {
  buildQuestQuery,
  queryQuestsWithLocaleContext
} from '~/app/quest/quest-repo';
import { collectRichTextEntitiesByNodeType } from '~/domain/wordquest/dom-entity-extractor';
import {
  upsertQuotesWithLocale,
  queryQuotesWithLocaleContext,
  ES_MAPPING_QUOTE_BY_LOCALE
} from '~/app/quote/quote-repo';
import { serializeAsPlainText } from '~/domain/wordquest/dom-util';
import { asAudiogramOrAudioMessage } from '~/domain/wordquest/audio/audio';
import { createQuickReply, createText } from '~/adapters/message-mappers';
import { of, from, Observable } from 'rxjs';
import { map, flatMap, first, toArray, mergeAll } from 'rxjs/operators';
import { Locale } from '@wordquest/locales';

export const createMessagesWithAuthorNote = async (authorNote: AuthorNote) => {
  if (!_.get(authorNote, 'authorNote.descriptionRichText')) {
    return [];
  }
  const { author, descriptionRichText } = authorNote.authorNote;

  const descriptionEntityByNodeType =
    collectRichTextEntitiesByNodeType(descriptionRichText);
  const audio = _.first(descriptionEntityByNodeType.audio);

  const explanationDescription = serializeAsPlainText(descriptionRichText);

  const { videoTemplateUrl } = author || {};

  const messages = [];

  const audioMessage = await asAudiogramOrAudioMessage({
    key: authorNote.id,
    videoTemplateUrl,
    audio
  });
  messages.push({
    message: explanationDescription
  });

  messages.push(audioMessage);

  return messages;
};

export const createMessagesWithAuthorNoteIds = async (authorNoteIds) => {
  if (_.isEmpty(authorNoteIds)) {
    return [];
  }
  const authorNotes = await queryQuotesWithLocaleContext(Locale.EN, {
    filter: {
      includeIds: authorNoteIds
    }
  });

  const authorNoteMessages = await from(authorNotes)
    .pipe(
      flatMap((authorNote) => createMessagesWithAuthorNote(authorNote)),
      mergeAll(1),
      toArray()
    )
    .toPromise();

  return authorNoteMessages;
};
