import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { RichTextContainer } from '~/text/rich-text-container';
import RichText from '~/text/rich-text';
import { CourseUserStatus } from '@wordquest/lib-iso/domain/wordquest/course/course-user-status';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import {
  Entity,
  CourseAction,
  asEventName
} from '@wordquest/lib-iso/domain/wordquest/entity';
import CourseCTA from './course-cta';

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
const StyledRecommendedChip = styled.div`
  .MuiChip-colorSecondary {
    font-weight: bold;
    color: #3f51b5;
    background-color: white;
    margin-right: 1rem;
  }
`;
const StyldCoursePlan = styled.div`
  width: 100%;
  text-align: center;
  h4,
  h5,
  h6 {
    color: gray;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1rem;
  }
`;

const StyledCoursePlanAccordion = styled.div`
  scroll-behavior: smooth;
  cursor: pointer;
  .MuiAccordion-root {
    border-radius: 1rem 1rem 1rem 1rem;
    overflow: hidden;
  }
  .MuiAccordionDetails-root {
    padding: 14px 16px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0.5rem 0;
  }
  .MuiAccordionSummary-content {
    /* text-align: center; */
    flex-grow: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 2rem;
  }
  padding-top: 1rem;
  padding-bottom: 2rem;
  &:target {
    padding-top: 6rem;
    margin-top: -6rem;
  }
  a {
    text-decoration: none;
  }
`;

export const CoursePlan = ({ coursePlan, isPriceOnly = false }) => {
  const { descriptionRichText, ctaLabel, originalprice, title } = coursePlan;

  return (
    <StyldCoursePlan>
      {!isPriceOnly && (
        <RichTextContainer wqDom={descriptionRichText}>
          <RichText />
        </RichTextContainer>
      )}
      <Box style={{ fontSize: '1.2rem' }}>
        <b>{coursePlan.offerPrice || coursePlan.originalPrice}</b>
      </Box>
    </StyldCoursePlan>
  );
};

export const DEFAULT_PALETTE = ['#F5BD00', '#DE3F65', '#F58400'];

const CoursePlanSectionContent = ({
  palette,
  coursePlan,
  onClickCTA,
  isPriceOnly = false
}) => (
  <Accordion expanded>
    <StyledAccordionSummary
      style={{
        color: 'white',
        backgroundColor: palette || ''
      }}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {coursePlan.isRecommended && (
        <StyledRecommendedChip>
          <Chip
            color="secondary"
            label={<FormattedMessage id="tag.recommended" />}
          />
        </StyledRecommendedChip>
      )}
      <Typography>
        {coursePlan.title || <FormattedMessage id="common.course.plan" />}
      </Typography>
    </StyledAccordionSummary>
    <AccordionDetails>
      <CoursePlan
        coursePlan={coursePlan}
        onClickCTA={onClickCTA}
        isPriceOnly={isPriceOnly}
      />
    </AccordionDetails>
  </Accordion>
);

const CoursePlanSection = ({ palette, coursePlan, onClickCTA, trackEvent }) => (
  <StyledCoursePlanAccordion
    id={coursePlan.id}
    onClick={() => {
      console.log('trackEvent', trackEvent);
      // scrollTo form and filled in course
      onClickCTA(() =>
        trackEvent({
          event: asEventName(Entity.Course, CourseAction.CoursePlanClicked),
          properties: {
            coursePlan: coursePlan?.title
          }
        })
      );
    }}
  >
    <CoursePlanSectionContent palette={palette} coursePlan={coursePlan} />
  </StyledCoursePlanAccordion>
);

const SidebarCoursePlanSection = ({ coursePlan, palette }) => (
  <StyledCoursePlanAccordion>
    <a href={`#${coursePlan.id}`}>
      <CoursePlanSectionContent
        palette={palette}
        coursePlan={coursePlan}
        isPriceOnly
      />
    </a>
  </StyledCoursePlanAccordion>
);

// For now it's shared CTA
export default (props) => {
  const { coursePlans = [], onClickCTA, trackEvent, isSidebar = false } = props;

  return (
    <div>
      {coursePlans.map((coursePlan, i) => {
        const palette = DEFAULT_PALETTE[i % DEFAULT_PALETTE.length];

        return isSidebar ? (
          <SidebarCoursePlanSection palette={palette} coursePlan={coursePlan} />
        ) : (
          <CoursePlanSection
            palette={palette}
            coursePlan={coursePlan}
            onClickCTA={onClickCTA}
            trackEvent={trackEvent}
          />
        );
      })}
    </div>
  );
};
