// For both UI & domain checking

// simplified status from segments
// further decide form submisison type
//  no "end" as that is challenge

// Scenarios
// 1) paid & forever 2) paid & active
import _ from 'lodash';
import { SITE_ROOT_UI_SITE } from '~/env';
import { checkIsAuthorized } from '~/domain/wordquest/profile/profile-authorized-roles';
import { createTransformer } from 'mobx-utils';
import { isAfter, isBefore } from 'date-fns';
import Course from './course';
import { Lesson } from './lesson';

export enum CourseUserStatus {
  New = 'new',
  Trial = 'trial',
  // paid and course ready
  PaidActive = 'paid-active',
  // paid but course not ready
  PaidPending = 'paid-pending',
  PaidRenewal = 'paid-renewal-upcoming'
}
// always with root

export const checkRoleAuthorizationsWithSegments = (
  courseAuthorizedSegments,
  lessonAuthorizedSegments
) => {
  // override (when present, ignore course)
  if (!_.isEmpty(lessonAuthorizedSegments)) {
    return lessonAuthorizedSegments || [];
  }

  return courseAuthorizedSegments || [];
};

export const checkRoleAuthorizations = (course: Course, lesson: Lesson) =>
  checkRoleAuthorizationsWithSegments(
    course.authorizedSegments,
    (lesson || {}).authorizedSegments
  ).map(_.trim);

export const createCourseLessonsStatuses = ({
  course,
  currentLessonKey,
  checkedByKey,
  userAuthorizedRoles,
  isAlwaysVisible = false
}: {
  course: Course;
  currentLessonKey: string;
  checkedByKey: Record<string, boolean>;
  userAuthorizedRoles: string[];
  isAlwaysVisible: boolean;
}) =>
  _.flatMap(course.courseSections, ({ key: sectionKey, lessons }) =>
    lessons.map((lesson) =>
      mapLessonAsAttributes({
        key: lesson.key,
        courseAuthorizedSegments: course.authorizedSegments,
        sectionKey,
        lesson,
        currentLessonKey,
        checkedByKey,
        isAlwaysVisible,
        userAuthorizedRoles
      })
    )
  );

export const createCourseStatuses = ({
  course,
  currentLessonKey,
  checkedByKey,
  userAuthorizedRoles,
  isAlwaysVisible = false
}: {
  course: Course;
  currentLessonKey: string;
  checkedByKey: Record<string, boolean>;
  userAuthorizedRoles: string[];
  isAlwaysVisible: boolean;
}) => {
  if (!_.get(course, 'courseSections')) {
    return [];
  }

  return [
    {
      title: course.title,
      key: course.key,
      url: `${SITE_ROOT_UI_SITE}/course/${course.key}`,
      isCurrent: _.isEmpty(currentLessonKey)
    }
  ].concat(
    createCourseLessonsStatuses({
      course,
      currentLessonKey,
      checkedByKey,
      userAuthorizedRoles,
      isAlwaysVisible
    })
  );
};

export const createCourseAttributeByKey = (params) =>
  _.keyBy(createCourseStatuses(params), 'key');

export const mapLessonAsAttributes = createTransformer(
  ({
    courseAuthorizedSegments,
    sectionKey,
    lesson,
    currentLessonKey,
    checkedByKey,
    isAlwaysVisible = false,
    userAuthorizedRoles
  }) => {
    const {
      title,
      key,
      readyAt,
      authorizedSegments: lessonAuthorizedSegments
    } = lesson || {};
    const isHidden =
      !isAlwaysVisible &&
      (lesson.isHidden || (_.isDate(readyAt) && isAfter(readyAt, new Date())));
    const requiredRoles = checkRoleAuthorizationsWithSegments(
      courseAuthorizedSegments,
      lessonAuthorizedSegments
    );
    const isLocked =
      !isAlwaysVisible &&
      !checkIsAuthorized(userAuthorizedRoles, requiredRoles);

    return {
      title,
      sectionKey,
      key,
      isHidden,
      isChecked: !!checkedByKey[key],
      isLocked,
      isCurrent: key === currentLessonKey,
      tags: lesson.tags
    };
  }
);
