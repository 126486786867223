import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import Glossary from '~/domain/wordquest/glossary/glossary';
import { markedWithDefault } from '~/app/markdown-html-mapper';

export const asGlossary = (entry): Glossary => {
  const { sys, fields } = entry;
  const glossaryId = sys.id;
  const { id, linkType } = sys.contentType.sys;
  const description = fields.description || '';

  return Glossary.create({
    id: glossaryId,
    detailsLocale: Locale.ZH_TW,
    // for legacy
    detailsHtml: markedWithDefault(description),
    ...fields
  });
};
