import React from 'react';
import { withRouter } from 'react-router';

class ScrollToTopOnRouteChange extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return React.Children.only(children);
  }
}

export default withRouter(ScrollToTopOnRouteChange);
