import React from 'react';
import styled, { css } from 'styled-components';

export const StyledPreviewBanner = styled.div`
  background-color: #103284;
  color: white;
  text-align: center;
  font-size: 2rem;

  z-index: 1120;
  /* height: 5rem; */
  position: fixed;
  width: 50%;
  top: 0;
  display: block;
  margin-left: 25%;
`;

export default () => <StyledPreviewBanner>PREVIEW MODE</StyledPreviewBanner>;
