/**
 * Multiple attempt of the same Quest is possible
 *
 * Only single Quest at a time
 *
 *
 * Not a very dry way to define types
 * but we need to
 * 1) determine quest types at runtime
 * 2) type check for properties
 */
import _ from 'lodash';
import { Category } from '~/domain/wordquest/category';
import { shuffleWithSeed } from '~/domain/random-util';
import querystring from 'qs';

// TODO potentially rename as questItem / subquest?
export enum QuestTypeName {
  CompleteCourseIncompleted = 'CompleteCourseIncompleted',
  ReadArticleRecommended = 'ReadArticleRecommended',
  ReadArticle = 'ReadArticle',
  WatchVideoRecommended = 'WatchVideoRecommended',
  WatchVideoLesson = 'WatchVideoLesson',
  WordReview = 'WordReview',
  GrammarQuiz = 'GrammarQuiz',
  VisitUrl = 'VisitUrl',
  Dictation = 'Dictation',
  OpenQA = 'OpenQA',
  CheckTip = 'CheckTip',
  TeacherMessage = 'TeacherMessage',
  LessonNotes = 'LessonNotes',
  // just instruction, smart block
  General = 'General',
  Mc = 'Mc',
  // RateVideoDifficulty = 'RateVideoDifficulty',
  // generic across rating against video/challenge
  RateDifficulty = 'RateDifficulty',
  Checkin = 'Checkin'
}

export type QuestType<QuestTypeName, P> = {
  type: QuestTypeName;
  properties: P;
};

// Can be considered as QuestItem if quest is more generic
// A meta object, the quest need to be constructed
export default class Quest<T extends QuestType<QuestTypeName, object>> {
  constructor(
    // unique id generated by creator.
    public id: string,
    public type: T['type'],
    public priority: number,
    public properties: T['properties']
  ) {}

  static create<T extends QuestType<QuestTypeName, object>>(
    doc: object
  ): Quest<T> {
    const { id, type, priority, properties } = doc;

    return new Quest<T>(id, type, priority, properties);
  }
}

export type QuestReadArticleRecommended = QuestType<
  QuestTypeName.ReadArticleRecommended,
  {
    categories: Category[];
  }
>;

export type QuestReadArticle = QuestType<
  QuestTypeName.ReadArticle,
  {
    articleId: string;
  }
>;

export type QuestWatchVideoRecommended = QuestType<
  QuestTypeName.WatchVideoRecommended,
  {
    videoId: string;
  }
>;

export type QuestWatchVideoLesson = QuestType<
  QuestTypeName.WatchVideoLesson,
  {
    videoId: string;
  }
>;

export const getQuestWatchVideoLessonKey = (lessonKey, videoId) =>
  [QuestTypeName.WatchVideoLesson, lessonKey, videoId].join('-');

export type QuestCompleteCourseIncompleted = QuestType<
  QuestTypeName.CompleteCourseIncompleted,
  {
    courseKey: string;
    nextLessonKey: string;
    nextLessonTitle: string;
    // completedByLessonKey
  }
>;

export type QuestWordReview = QuestType<
  QuestTypeName.WordReview,
  {
    wordIds: string[];
  }
>;

export type QuestGrammarQuiz = QuestType<
  QuestTypeName.GrammarQuiz,
  {
    articleId: string;
  }
>;

export type QuestVisitUrl = QuestType<
  QuestTypeName.VisitUrl,
  {
    url: URL;
  }
>;

export type QuestDictation = QuestType<
  QuestTypeName.Dictation,
  {
    answer: string;
    startSeconds: number;
    endSeconds: number;
  }
>;

export type QuestRateDifficulty = QuestType<
  QuestTypeName.RateDifficulty,
  {
    min: number;
    max: number;
  }
>;

export type QuestOpenQA = QuestType<
  QuestTypeName.OpenQA,
  {
    question: string;
  }
>;

export type QuestLessonNotes = QuestType<
  QuestTypeName.LessonNotes,
  {
    courseKey: string;
    lessonKey: string;
    messageText: string;
  }
>;

export type QuestTeacherMessage = QuestType<
  QuestTypeName.TeacherMessage,
  {
    teacher: string;
    message: any;
  }
>;

// TODO ref to word review
export type QuestMC = QuestType<
  QuestTypeName.Mc,
  {
    seedKey?: string;
    question: string;
    answer: string;
    distractors: string[];
    explanationRichText: WqDocument;
    // TODO
    // explanationRichTextByLocale?: Record<Locale, WqDocument>;
  }
>;

// editor to determine exact order will be overkill, but we are better off when we stablize choices across e.g. lesson
export const createQuestMCChoices = (quest: QuestMC, seed = '') => {
  const { distractors = [], answer } = quest.properties;
  const choices = distractors.concat(answer);

  return shuffleWithSeed(choices, choices.join('') + seed);
};

// Not to couple with postback serialize / deserialize which is just simple query string
// must use quest seed
export const mapQuestAsPostbackData = (quest: QuestMC) => {
  const { distractors = [], answer, seedKey } = quest.properties;

  // sort can be added
  // https://www.npmjs.com/package/query-string#sort-1
  return {
    action: 'mc-answered',
    'quest-id': quest.id,
    seedKey: seedKey || quest.id,
    answer,
    distractors
  };
};

export const questFromPostbackPayload = (postbackPayload) =>
  Quest.create({
    id: postbackPayload['quest-id'],
    type: QuestTypeName.Mc,
    properties: {
      ..._.omit(postbackPayload, 'quest-id')
    }
  });
