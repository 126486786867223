// TODO deprecate this and most to migrate richText instead
// and consider using this https://github.com/rexxars/react-markdown
import marked from 'marked';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return `<a target="_blank" target="_blank" rel="noopener noreferrer" href="${href}">${text}</a>`;
};

// TODO support override
export const markedWithDefault = (source, overrideRenderer) =>
  marked(source, { renderer });
