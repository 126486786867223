import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  WqNodeType,
  WqNodeTypeInline,
  WqNodeTypeBlock,
  WqDocument
} from '@wordquest/lib-iso/domain/wordquest/dom';
import { Locale } from '@wordquest/locales';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import logger from '@wordquest/lib-iso/app/logger';
import Hidden from '@material-ui/core/Hidden';
import { mediaMui } from '~/style-utils';

const StyledSmartUserBlock = styled.div`
  > iframe {
    ${mediaMui.lgUp`
      min-height: 80vh;
    `}
    width: 100%;
  }
  width: 100%;
`;

export default ({ url = '', type }) => {
  if (!url) {
    return (
      <div>Document not found. Chat with us or email support@perapera.ai.</div>
    );
  }

  return (
    <StyledSmartUserBlock>
      <Typography gutterBottom variant="h6" component="div">
        <a href={url} target="_blank" rel="noreferrer">
          <FormattedMessage id="common.user-block.mobile" />
        </a>
      </Typography>
      <Hidden smDown>
        <iframe className="smart-user-block-iframe" src={url} title={type} />
      </Hidden>
    </StyledSmartUserBlock>
  );
};
