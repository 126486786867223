import _ from 'lodash';

export const fetchTestimonials = async () => {
  const req =
    'https://wordquest-strapi.s3.ap-southeast-1.amazonaws.com/testimonials-latest.json';

  try {
    return fetch(req).then((res) => res.json());
  } catch (err) {
    logger.error(err);

    return [];
  }
};

export const asTestimonialsByCourseKeyWithLocale = (
  strapiTestimonials: unknown[]
) =>
  _.mapValues(
    _.groupBy(
      strapiTestimonials,
      (strapiTestimonial) => strapiTestimonial.coursesRegisteredKey
    ),
    (strapiTestimonialsByCourseKey) =>
      _.groupBy(
        strapiTestimonialsByCourseKey,
        (strapiTestimonial) => strapiTestimonial.locale
      )
  );
