import _ from 'lodash';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import { mapYtDefaultLocaleAsLocale } from '@wordquest/lib-iso/adapters/youtube-locale';
import {
  getVideoYtTags,
  getVideoYtDefaultAudioLocale
} from '@wordquest/lib-iso/app/video/video-yt';
import Course from '@wordquest/lib-iso/domain/wordquest/course/course';
import { createSrcSetByTypeContentfulWithSizeParams } from '@wordquest/lib-iso/app/contentful/rich-text-wq-dom-mapper';
import { PictureWithCaption } from '~/image/picture-with-caption';
import AuthorTag from '~/author/author-tag';
import AuthorCard, { AuthorLanguages } from '~/author/author-card';
// import Tags from '~/tag/tags';

const StyledCardActionArea = styled.div`
  .MuiButtonBase-root.MuiCardActionArea-root:hover {
    img {
      transform: scale(1.1);
    }
  }
`;
const StyledCardContent = styled.div`
  overflow: hidden;
  font-size: 0.9rem;

  min-height: 8rem;

  .MuiAvatar-circle {
    width: 3rem;
    height: 3rem;
  }

  & h3 {
    color: gray;
  }
  .description {
    color: #00000075;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;
//
const sizeParams = [
  {
    params: {
      w: '500',
      h: '282'
    },
    size: '500w'
  }
];

// pbm: import carrousel, sys link
export default (props: {
  course: Course;
  // allVideoTags: [],
  withLink: any;
  isShowTitle: boolean;
  isShowTeacherTag: boolean;
  isShowTeacherDetails: boolean;
  // maxTagCount: number,
  isShowDescription: boolean;
}) => {
  const {
    course,
    withLink,
    isShowTitle = true,
    isShowTeacherTag = true,
    isShowTeacherDetails = false,
    // allCourseTags = [],
    // maxTagCount = 2,
    isShowDescription = true
  } = props;
  const teacher = _.get(course, 'teachers.0');

  return withLink({
    courseKey: course.key
  })(
    <StyledCardActionArea>
      <CardActionArea>
        <Card>
          <CardMedia component="div" title={`${course.title}`}>
            <PictureWithCaption
              aspectRatio={16 / 9}
              src={course.heroImageUrl}
              title={course.title}
              description={course.descriptionShort}
              srcsetByType={createSrcSetByTypeContentfulWithSizeParams(
                course.heroImageUrl,
                ['webp', 'jpeg'],
                sizeParams
              )}
              isHideCaption
            />
          </CardMedia>
          <CardContent>
            <StyledCardContent>
              {isShowTitle && (
                <Typography variant="h6" component="h6">
                  {course.title}
                </Typography>
              )}
              {isShowDescription && (
                <Typography gutterBottom variant="body1" component="div">
                  <div className="description">{course.descriptionShort}</div>
                </Typography>
              )}
              {isShowTeacherTag && teacher && (
                <div>
                  <AuthorTag
                    withLink={(props) =>
                      withLink({
                        href: `/author/${props.authorKey}`
                      })
                    }
                    label={<FormattedMessage id="common.quotes.explanations" />}
                    key={teacher.semanticKey}
                    author={teacher}
                  />
                </div>
              )}
              {isShowTeacherDetails && teacher && (
                <div>
                  <AuthorCard
                    authorNameSize="sm"
                    isShowTags={false}
                    isShowAuthorDescription={false}
                    author={teacher}
                    withLink={(props) =>
                      withLink({ href: `/author/${teacher.semanticKey}` })
                    }
                  />
                  <AuthorLanguages
                    languagesTeaching={teacher.languagesTeaching}
                    languagesOther={teacher.languagesOther}
                  />
                </div>
              )}
            </StyledCardContent>
          </CardContent>
        </Card>
      </CardActionArea>
    </StyledCardActionArea>
  );
};
