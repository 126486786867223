import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import { Author } from '~/domain/wordquest/author/author';
import { Quote } from '~/domain/wordquest/quote/quote';
import {
  WqDocument,
  WqNode,
  WqNodeTypeInline,
  WqNodeTypeBlock,
  WqNodeBlock,
  WqNodeText,
  WqNodeBlockImage,
  WqTextLeaf
} from '~/domain/wordquest/dom';

export enum TipType {
  AuthorNote = 'authorNote',
  Quote = 'quote'
}

export type AuthorNote = {
  key: string;
  author: Author;
  descriptionRichText: WqDocument;
};

// potentially merge quote inside this
// now weak structure as we likly have different analyzer for each field
export type Tip = {
  id: string;
  author: string;
  type: TipType;
  properties: {
    quote: Omit<Quote, 'author'>;
    authorNote: {
      descriptionRichText: WqDocument;
    };
  };
};

export const authorNoteAsTip = (authorNote) => ({
  id: authorNote.key,
  type: 'authorNote',
  author: authorNote.author,
  properties: {
    authorNote: _.omit(authorNote, 'author')
  }
});
