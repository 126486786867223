import _ from 'lodash';
import { css } from 'styled-components';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import getPageContext from './get-page-context';

export const MEDIA_SIZES = {
  // giant: 1170,
  desktop: [1025, 9999],
  laptop: [768, 1024],
  tablet: [376, 768],
  phone: [0, 376]
};

export const createMediaMuiSizes = () => {
  const context = getPageContext();
  if (!context) {
    // non-mui env
    return;
  }
  const breakpoints = context.theme.breakpoints;

  return _.fromPairs(
    breakpoints.keys.map((key, i) => {
      const min = breakpoints.values[key];
      const maxKey = _.get(breakpoints, `keys.${i + 1}`);
      const max = maxKey ? breakpoints.values[maxKey] : 9999;

      return [key, [min, max]];
    })
  );
};

export const MEDIA_MUI_SIZES = createMediaMuiSizes();

export const isSizeUp = (sizeKey) => {
  const innerWidth = _.get(global, 'window.innerWidth') || 1025;
  // calculate rightawway. responsive but perf sensitive
  const [minEmSize, maxEmSize] = MEDIA_SIZES[sizeKey];

  return innerWidth >= minEmSize;
};

const createMediaQueries = (sizes) =>
  Object.keys(sizes).reduce((accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const [minEmSize, maxEmSize] = sizes[label].map((size) => size / 16);
    accumulator[label] = (...args) => css`
      @media (min-width: ${minEmSize}em) and (max-width: ${maxEmSize}em) {
        ${css(...args)}
      }
    `;
    // quick hack
    accumulator[`${label}Up`] = (...args) => css`
      @media (min-width: ${minEmSize}em) and (max-width: ${9999}em) {
        ${css(...args)}
      }
    `;

    return accumulator;
  }, {});

// iterate through the sizes and create a media template
export const media = createMediaQueries(MEDIA_SIZES);

export const createMediaMui = (): Record<keyof MEDIA_MUI_SIZES, any> =>
  createMediaQueries(MEDIA_MUI_SIZES);
// https://material-ui.com/customization/breakpoints/

// this is also possible
// const theme = useTheme();
// const matches = useMediaQuery(theme.breakpoints.up('lg'));
// isWidthUp requires Breakpoint to compare
export const isWindowMuiBreakpointUp = (sizeKey) =>
  (_.get(MEDIA_MUI_SIZES, `${sizeKey}.0`) || 0) <=
  (_.get(global, 'window.innerWidth') || 0);

// must be sorted
export const findCurrentBreakpoint = () =>
  _.findLast(_.toPairs(MEDIA_MUI_SIZES), ([sizeKey, size]) =>
    isWindowMuiBreakpointUp(sizeKey)
  );

// // iterate through the sizes and create a media template
export const mediaMui = createMediaMui();
