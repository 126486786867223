// Modified from contentful-slatejs-adapter, as cannot find matching defintions, probably slate v0.33.x
// original thought = contentful RichText->Slate->WqDOM
// concept & code style looks okay. major change:
// use "children" instead of "nodes"

// https://github.com/contentful/rich-text/blob/master/packages/contentful-slatejs-adapter/src/contentful-to-slatejs-adapter.ts

import _ from 'lodash';
import * as Contentful from '@contentful/rich-text-types';
import {
  WqDocument,
  WqNode,
  WqNodeTypeInline,
  WqNodeTypeBlock,
  WqNodeBlock,
  WqNodeText,
  WqNodeBlockImage,
  WqTextLeaf
} from '~/domain/wordquest/dom';
import { AuthorNote } from '~/domain/wordquest/tip/tip';
import { CfTypes } from '~/adapters/contentful';
import { asGlossary } from '~/app/glossary/glossary-cf';
import { asQuote } from '~/app/quote/quote-cf';
import { asCourse } from '~/app/course/course-cf';

import qs from 'qs';
import rootLogger from '~/app/logger';
import {
  DEFAULT_SCHEMA,
  SchemaJSON,
  fromJSON,
  Schema,
  ContentfulNonTextNodes,
  ContentfulNode
} from './schema';
import { asAuthor } from '~/app/author/author-cf';
import { parseVideoUrl } from '~/app/video-url-util';

const logger = rootLogger.child({ module: 'cf-rich-text-wq-dom-mapper' });

const getDataOfDefault = (value?: Record<string, any>) => value || {};

export const asAuthorNote: AuthorNote = (entry) => {
  const { sys, fields } = entry;
  const glossaryId = sys.id;
  const { id, linkType } = sys.contentType.sys;
  const { key, author, descriptionRichText } = fields;

  return {
    key,
    author: asAuthor(author),
    descriptionRichText: asWqDom(descriptionRichText)
  };
};

export const limitSrcSize = (url) => `${url}?w=1200`;

export const createSrcSetByTypeContentfulWithSizeParams = (
  url,
  formats = ['jpeg', 'webp'],
  sizeParams
) =>
  _.fromPairs(
    formats.map((format) => [
      format,
      createSrcSetsContentfulWithSizeParams(url, format, sizeParams)
    ])
  );

export const createSrcSetsContentfulWithSizeParams = (
  url,
  format,
  sizeParams = [
    {
      params: {
        w: '2048'
      },
      size: '4096w'
    },
    {
      params: {
        w: '1024'
      },
      size: '2048w'
    },
    {
      params: {
        w: '1024'
      },
      size: '1024w'
    }
  ]
) =>
  _.cloneDeep(sizeParams)
    .map((meta) => {
      if (format === 'jpeg') {
        meta.params.fl = 'progressive';
      }
      if (_.includes(['png', 'webp'], format)) {
        meta.params.fm = format;
      }
      if (_.includes(['jpeg'], format)) {
        meta.params.fm = 'jpg';
      }

      return meta;
    })
    .map((meta) => `${url}?${qs.stringify(meta.params)} ${meta.size}`);

// not simple mapping but strategy by nodeType

// note they often containts node inside
// e.g. Quote contains Author which contains Image
// currently for rich text we will map it but for other types we will only retain the id
// and it's client responsibility to collect those types, load data & render with proper component
// since
// - It's possible only the type meta is available (include param at contnetful)
// so we prefer progressive loading
// - to ease seralization. hard to inject the object as HTML attributes
// - let client decide how to render it
// in future, possible to add optionsfor include-mechanism like contentful

export const mapperEntryTypeStrategy = {
  [CfTypes.Glossary]: (cfEntry) => ({
    type: WqNodeTypeInline.Glossary,
    attributes: {
      id: asGlossary(cfEntry).id
    }
  }),
  [CfTypes.Author]: (cfEntry, schema, options = {}) => {
    const author = asAuthor(cfEntry);

    const result = {
      type: WqNodeTypeBlock.Author,
      attributes: {
        id: author.semanticKey
      }
    };
    if (_.includes(options.isIncludeRawTypes || [], CfTypes.Author)) {
      result.data = _.merge(author, { id: author.semanticKey });
    }

    // TODO potential future descriptionRichText
    return result;
  },
  [CfTypes.Quote]: (cfEntry) => {
    const quote = asQuote(cfEntry);

    return {
      type: WqNodeTypeBlock.Quote,
      attributes: {
        id: (quote || {}).id
      }
    };
  },
  [CfTypes.AuthorNote]: (cfEntry, schema, options = {}) => {
    const authorNote = asAuthorNote(cfEntry);
    const id = (authorNote || {}).key;
    const result = {
      type: WqNodeTypeBlock.AuthorNote,
      attributes: {
        id
      }
    };
    if (_.includes(options.isIncludeRawTypes || [], CfTypes.AuthorNote)) {
      result.data = _.merge(authorNote, { id });
    }

    return result;
  },
  [CfTypes.Course]: (cfEntry) => ({
    type: WqNodeTypeBlock.Course,
    attributes: {
      id: (asCourse(cfEntry) || {}).key
    }
  }),
  [CfTypes.SmartUserBlock]: (cfEntry) => {
    const fields = _.get(cfEntry, 'fields') || {};

    return {
      type: WqNodeTypeBlock.SmartUserBlock,
      attributes: {
        ...fields
        // id: (asCourse(cfEntry) || {}).key
      }
    };
  }
};

const mapAsTextLeaf = (node) => ({
  attributes: {},
  children: (node.content || []).map(convertTextNode)
});

export const CF_MAP_STRATEGY_BY_TYPE = {
  [Contentful.BLOCKS.HEADING_1]: (node) => ({
    type: WqNodeTypeBlock.Heading1,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.HEADING_2]: (node) => ({
    type: WqNodeTypeBlock.Heading2,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.HEADING_3]: (node) => ({
    type: WqNodeTypeBlock.Heading3,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.HEADING_4]: (node) => ({
    type: WqNodeTypeBlock.Heading4,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.HEADING_5]: (node) => ({
    type: WqNodeTypeBlock.Heading5,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.HEADING_6]: (node) => ({
    type: WqNodeTypeBlock.Heading6,
    ...mapAsTextLeaf(node)
  }),
  [Contentful.BLOCKS.UL_LIST]: (node) => ({
    type: WqNodeTypeBlock.UlList
  }),
  [Contentful.BLOCKS.OL_LIST]: (node) => ({
    type: WqNodeTypeBlock.OlList
  }),
  [Contentful.BLOCKS.LIST_ITEM]: (node) => ({
    type: WqNodeTypeBlock.ListItem
  }),
  [Contentful.BLOCKS.HR]: (node) => ({
    type: WqNodeTypeBlock.Hr
  }),
  [Contentful.BLOCKS.PARAGRAPH]: (node) => {
    const isParagraph = _.every(
      node.content,
      (c) =>
        !_.includes(
          [Contentful.INLINES.EMBEDDED_ENTRY, Contentful.BLOCKS.EMBEDDED_ENTRY],
          c.nodeType
        )
    );

    return {
      type: isParagraph ? WqNodeTypeBlock.Paragraph : WqNodeTypeBlock.Div
    };
  },
  [Contentful.INLINES.HYPERLINK]: (node, schema) => {
    // for our hack to support different types
    // TODO support nextjs / reactjs router link at render
    const url = _.get(node, 'data.uri');
    const urlText = _.get(node, 'content.0.value');

    return {
      type: WqNodeTypeInline.Link,
      attributes: {
        url
      },
      children: [
        {
          text: urlText
        }
      ]
    };
    // return <a target="_blank" href={url}>{urlText}</a>;
  },
  // <blockquote>, not to confuse with wq quote
  [Contentful.BLOCKS.QUOTE]: (node, schema, options) => {
    // for our hack to support different types
    const { content } = node;
    const contentNodes = _.get(content, '0.content');

    const url = _.get(
      _.find(contentNodes || [], { nodeType: 'hyperlink' }),
      'data.uri'
    );
    // hack
    if (url) {
      const info = parseVideoUrl(url);
      if (!_.isEmpty(info)) {
        // our own video impl in future
        return {
          type: WqNodeTypeBlock.EmbededLink,
          attributes: {
            embededType: 'video',
            url
          }
        };
      }
    }

    return {
      type: WqNodeTypeBlock.BlockQuote,
      attributes: {},
      // TODO extract text children
      children: _.flatMap(content, (n) => convertNode(n, schema, options))
    };
  },
  [Contentful.BLOCKS.EMBEDDED_ASSET]: (node) => {
    const { sys, fields } = node.data.target;
    if (!fields) {
      logger.debug('empty asset', JSON.stringify(node));

      return;
    }
    const { title, description } = fields;
    const { url, contentType, details } = fields.file;

    // TODO refactor with renderOptionsWithLinksAsNewTarget, but now we need html due to text-annoated only supports it.
    // https://github.com/wordquest/wordquest/issues/456
    if (_.includes(contentType, 'image/')) {
      const formats = contentType.match(/gif/) ? ['gif'] : ['webp', 'jpeg'];
      const attributes: WqNodeBlockImage['attributes'] = {
        src: limitSrcSize(url),
        title,
        description,
        ..._.get(details, 'image'),
        srcsetByType: createSrcSetByTypeContentfulWithSizeParams(url, formats)
      };

      return {
        type: WqNodeTypeInline.Image,
        attributes
      };
    }
    if (_.includes(contentType, 'audio/')) {
      return {
        type: WqNodeTypeInline.Audio,
        attributes: {
          url: `https:${url}`
        }
      };
    }
    if (_.includes(contentType, 'video/')) {
      return {
        type: WqNodeTypeInline.Video,
        attributes: {
          url: `https:${url}`
        }
      };
    }
  },
  [Contentful.INLINES.EMBEDDED_ENTRY]: (node, schema, options = {}) => {
    const { nodeEntity, contentTypeId } = parseNode(node);
    logger.trace('embedded entry', contentTypeId, JSON.stringify(node));
    const strategy = mapperEntryTypeStrategy[contentTypeId];

    if (!strategy) {
      // consider return raw data
      logger.error('unknown contentful entry', contentTypeId, node);
      const { sys, fields } = node.data.target;

      return {
        type: WqNodeTypeBlock.Raw,
        attributes: {
          id: sys.id,
          target: node.data.target
        }
      };
    }

    return strategy(nodeEntity, schema, options);
  }
};

// fail safe for inline, but generally prefer blocks
CF_MAP_STRATEGY_BY_TYPE[Contentful.BLOCKS.EMBEDDED_ENTRY] =
  CF_MAP_STRATEGY_BY_TYPE[Contentful.INLINES.EMBEDDED_ENTRY];

const createWqNode = (
  cfNode: ContentfulNonTextNodes,
  childNodes: WqNode[],
  schema: Schema,
  options: any
): WqNodeType => {
  const object = mapAsNodeObjectValue(cfNode.nodeType);
  if (object !== 'inline' && object !== 'block') {
    throw new Error(`Unexpected cf object '${object}'`);
  }
  let strategy = CF_MAP_STRATEGY_BY_TYPE[cfNode.nodeType];
  if (!strategy) {
    logger.error('node strategy missing', cfNode.nodeType);
    strategy = _.noop;
  }
  logger.trace('createWqNode', cfNode, strategy(cfNode, schema, options));

  return {
    object,
    // TODO remove default
    type: WqNodeTypeBlock.Div,
    children: childNodes,
    isVoid: schema.isVoid(cfNode),
    // avoid extract data.target for embeded entry?
    // attributes: getDataOfDefault(cfNode.data),s
    ...strategy(cfNode, schema, options)
  };
};
// exact same for now
export const CF_MAP_STRATEGY_BY_MARK = {
  ..._.fromPairs(_.values(Contentful.MARKS).map((m) => [m, m]))
};

function convertTextNode(node: ContentfulNode): WqNodeText {
  const { marks = [], value, data } = node as Contentful.Text;
  const textProps = _.fromPairs(
    marks.map((m) => [CF_MAP_STRATEGY_BY_MARK[m.type], true])
  );

  return { text: _.trim(value) || '', ...textProps };
}

function mapAsNodeObjectValue(nodeType: string): 'inline' | 'block' {
  if (Object.values(Contentful.BLOCKS).includes(nodeType)) {
    return 'block';
  }
  if (Object.values(Contentful.INLINES).includes(nodeType)) {
    return 'inline';
  }
  throw new Error(`Unexpected contentful nodeType '${nodeType}'`);
}

export const parseNode = (node) => {
  logger.trace('parseNode', node);
  const nodeEntity = _.get(node, 'data.target');
  // 'type': 'Asset',
  const { id } =
    _.get(nodeEntity, 'sys.contentType.sys') || _.get(nodeEntity, 'sys');

  return {
    nodeEntity,
    contentTypeId: id
  };
};

function convertNode(
  node: ContentfulNode,
  schema: Schema,
  options: { isIncludeRawTypes: string[] } = {}
): WqNode[] {
  const nodes: WqNode[] = [];
  if (node.nodeType === 'text') {
    const textNode = convertTextNode(node);
    nodes.push(textNode);
  } else {
    const contentfulNode = node as ContentfulNonTextNodes;
    const childNodes = _.flatMap(contentfulNode.content, (childNode) =>
      convertNode(childNode, schema, options)
    );

    const elementNode = createWqNode(
      contentfulNode,
      childNodes,
      schema,
      options
    );
    nodes.push(elementNode);
  }

  return nodes;
}

// in app/ as coupled with wq domain
// keep the contentful rich text structure to enable with wq plugin
export const asWqDom = (
  cfRichText: object,
  options = {}
): WqDocument | null => {
  if (!cfRichText) {
    logger.trace('empty cfRichText');

    return null;
  }
  // schema is mostly useful to mark void
  // https://docs.slatejs.org/concepts/02-nodes#voids

  // since input unlikely casted to proper type e.g. INLINES.
  const cfRichTextDoc = Object.assign({}, cfRichText) as Contentful.Document;

  return {
    type: WqNodeTypeBlock.Document,
    object: 'block',
    // data: getDataOfDefault(cfRichTextDoc.data),
    children: _.flatMap(cfRichTextDoc.content, (node) =>
      convertNode(node, fromJSON(DEFAULT_SCHEMA), options)
    ) as WqNodeBlock[]
  };
};
