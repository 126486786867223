import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { BlueWhiteButton } from '~/button/buttons';
import { ErrorImage } from './index';

const ContactUs = ({ minHeight = '45vh', onClickRetry }) => (
  <Grid container justify="center" alignItems="center" style={{ minHeight }}>
    <Grid item container justify="center" xs={12} sm={12}>
      <ErrorImage />
    </Grid>
    <Grid item container justify="center" xs={12} sm={12}>
      <Typography component="p" variant="h5" align="center">
        <FormattedMessage id="common.error.handle.contact-us" />
      </Typography>
    </Grid>
    {onClickRetry && (
      <Grid item container justify="center" xs={5} sm={3}>
        <BlueWhiteButton variant="contained" onClick={onClickRetry}>
          <FormattedMessage id="common.error.handle.try-again" />
        </BlueWhiteButton>
      </Grid>
    )}
  </Grid>
);

export default ContactUs;
