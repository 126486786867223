import _ from 'lodash';
import { VideoPlatform } from '~/domain/wordquest/video/video';
import { Locale } from '@wordquest/locales';
import Video from '../video/video';
// For Podcast and podcast Episode metadata
// used with Video, which this will be flatten as platform meta
export const parsePodcastKeyAsItunesId = (key) => (key || '').replace(/^i/, '');

export enum PodcastPlatformType {
  Itunes = 'itunes',
  Spotify = 'spotify',
  GoogleCast = 'googlecast',
  Rss = 'rss',
  SoundCloud = 'soundcloud'
}

export type SocialProfileMeta = {
  platfrom: string;
  handle?: string;
  url?: string;
};

export type PodcastPlatformMeta = {
  platform: PodcastPlatformType;
  url: string;
};

export class Podcast {
  constructor(
    public itunesId: string,
    public title: string,
    public websiteUrl: string,
    public thumbnailUrl: string,
    public thumbnailUrlMaxRes: string,
    public language: Locale,
    public socialProfiles: SocialProfileMeta[],
    public platformMetas: PodcastPlatformMeta[],
    public email: string,
    public id?: string
  ) {}

  static create(obj: object): Podcast {
    const {
      itunesId,
      title,
      websiteUrl,
      thumbnailUrl,
      thumbnailUrlMaxRes,
      language,
      socialProfiles = [],
      platformMetas = [],
      email,
      id
    } = obj;

    return _.defaultsDeep(
      new Podcast(
        itunesId,
        title,
        websiteUrl,
        thumbnailUrl,
        thumbnailUrlMaxRes,
        language,
        socialProfiles,
        platformMetas,
        email,
        id
      ),
      obj
    );
  }
}

export class PodcastEpisode {
  constructor(
    public itunesId: string,
    public podcastId: string,
    public title: string,
    public websiteUrl: string,
    public description: string,
    public thumbnailUrl: string,
    public thumbnailUrlMaxRes: string,
    public platformMetas: PodcastPlatformMeta[],
    public audioUrl: string,
    public durationInS: number,
    public publishedAt: Date,
    public id?: string
  ) {}

  static create(obj: object): PodcastEpisode {
    const {
      itunesId,
      podcastId,
      title,
      websiteUrl,
      description,
      thumbnailUrl,
      thumbnailUrlMaxRes,
      platformMetas = [],
      audioUrl,
      durationInS,
      publishedAt,
      id
    } = obj;

    return _.defaultsDeep(
      new PodcastEpisode(
        itunesId,
        podcastId,
        title,
        websiteUrl,
        description,
        thumbnailUrl,
        thumbnailUrlMaxRes,
        platformMetas,
        audioUrl,
        durationInS,
        publishedAt,
        id
      ),
      obj
    );
  }
}

const asThumbnails = (url, urlMaxRes) => ({
  default: {
    height: 300,
    url
  },
  maxres: { height: 720, url: urlMaxRes }
});

const mapEpisodeAsVideo = (episode: PodcastEpisode, podcast: Podcast) =>
  Video.create({
    // No itunes id for episode, we use listennotes id
    ...episode,
    videoMeta: {
      isHiddenByCurator: false
    },
    thumbnails: asThumbnails(
      episode.thumbnailUrl || podcast.thumbnailUrl,
      episode.thumbnailUrlMaxRes || podcast.thumbnailUrlMaxRes
    ),
    // n for listennotes.
    // hack for now, client facing we should get itunes id
    id: `podcast-n${episode.id}`,
    language: podcast.language,
    platform: 'podcast',
    videoPlatformMeta: {
      // url strategy take from here
      [VideoPlatform.Podcast]: {
        ..._.toPlainObject(_.omit(podcast, 'platformMetas')),
        listennotesId: episode.id,
        audioUrl: episode.audioUrl,
        // override due to index bug
        platforms: podcast.platformMetas,
        thumbnails: asThumbnails(
          podcast.thumbnailUrl,
          podcast.thumbnailUrlMaxRes
        )
      }
    }
  });

export const mapPodcastEpiosdesAsVideos = ({
  podcast,
  episodes = []
}: {
  podcast: Podcast;
  episodes: PodcastEpisode[];
}) => _.map(episodes, (episode) => mapEpisodeAsVideo(episode, podcast));

// override with episode
export const mergePlatformMetas = (
  podcastPlatformMetas = [],
  episodePlatformMetas = []
) =>
  _.unionBy(episodePlatformMetas, podcastPlatformMetas, (meta) =>
    _.get(meta, 'platform')
  );

// omit the key is fine
export const asItunesPodcastUrl = (podcastId, episodeId) =>
  `https://podcasts.apple.com/us/podcast/id${podcastId}` +
  `${episodeId ? `?i=${episodeId}}` : ''}`;

export const asGooglePodcastUrl = (rss, episodeGuid) => {
  if (!rss) {
    return '';
  }
  const rssBase64 = Buffer.from(rss).toString('base64');
  let episodeGuidBase64 = '';
  if (episodeGuid) {
    episodeGuidBase64 = Buffer.from(episodeGuid).toString('base64');
  }

  return (
    `https://podcasts.google.com/feed/${rssBase64}` +
    `${episodeGuidBase64 ? `/episode/${episodeGuidBase64}` : ''}`
  );
};
