import _ from 'lodash';
import React from 'react';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import { FormattedMessage } from 'react-intl';
import logger from '@wordquest/lib-iso/app/logger';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useRootStore } from '~/stores/root';
import isEqual from 'react-fast-compare'; //eslint-disable-line
import CarrouselGroups from '@wordquest/ui-components/carrousel-groups';
import { withRouterLink } from '@wordquest/ui-components/with-link';
import CourseCard from '@wordquest/ui-components/course/course-card';
import { isWindowMuiBreakpointUp } from '@wordquest/ui-components/style-utils';
import {
  MountOnce,
  WaterMark,
  DebugOnly,
  appendScriptInBody
} from '@wordquest/ui-components/page-util';
import Container from '@material-ui/core/Container';
import { getCourseUid } from '@wordquest/lib-iso/domain/wordquest/course/course';
import { createUrlWithSearch } from '@wordquest/lib-iso/app/url-context';
import { Helmet } from 'react-helmet';
// withLink={withRouterLink}
import DiscoverFilters from '~/components/discover-filters';
import Grid from '@material-ui/core/Grid';
import coachBanner from '~/components/1-1@2x.png';
import courseBanner from '~/components/course@2x.png';
import Hidden from '@material-ui/core/Hidden';

import {
  getCourseTitleWithGroupKey,
  asOrderedCardsByGroup
} from '@wordquest/ui-components/course/course-card-groups';

import { filterRecommendedCoursesByGroup } from '~/stores/ui/page-course';

// workaround swiper bug
// but not work for both video /course

const StyledBannerActionArea = styled.div`
  height: 100%;
  min-height: 7rem;
  min-width: 45%;
  cursor: pointer;
  background-image: url(${(props) =>
    props.type === 'coach' ? coachBanner : courseBanner});
  background-size: cover;
  background-position-x: center;

  &:hover {
    transform: scale(1.1);
  }
`;
const StyledDiscoverFilters = styled.div`
  background-color: #eceef4;
`;

const StyledCourseCardCotaniner = styled.div`
  .swiper-wrapper {
    margin-bottom: -3rem;
  }
`;

export default observer(() => {
  const rootStore = useRootStore();
  // use top-left course updatedAt

  const isMuiMdUp = isWindowMuiBreakpointUp('md');

  const { pageCourseStore } = rootStore;
  const { recommendedCoursesByGroup } = pageCourseStore;

  // tag not yet ready
  const coachRecommendedCoursesByGroup = filterRecommendedCoursesByGroup(
    recommendedCoursesByGroup,
    true
  );
  const nonCoachRecommendedCoursesByGroup = filterRecommendedCoursesByGroup(
    recommendedCoursesByGroup,
    false
  );

  const onClickCourseType = (courseType) => {
    pageCourseStore.coursesFilter.type.replace([courseType]);
  };

  const coachOrderedCardsByGroup = asOrderedCardsByGroup({
    coursesByGroup: coachRecommendedCoursesByGroup,
    type: 'coach'
  });
  const nonCoachOrderedCardsByGroup = asOrderedCardsByGroup({
    coursesByGroup: nonCoachRecommendedCoursesByGroup,
    type: 'online'
  });
  const sampleCourse = _.first(_.first(_.values(recommendedCoursesByGroup)));
  const markKey = `courses-${getCourseUid(sampleCourse || {})}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={createUrlWithSearch('courses/')} />
        <meta property="og:url" content={createUrlWithSearch('courses/')} />
        <meta
          property="og:image"
          content="https://www.perapera.ai/static/meta/landing_og_image.jpg"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container maxWidth="lg">
        <Hidden mdDown>
          {/* <CourseDialog /> */}
          <Grid
            container
            wrap="nowrap"
            justify="center"
            alignContent="center"
            spacing={2}
            direction="row"
            my={2}
          >
            <Grid item xs={6}>
              <StyledBannerActionArea
                type="coach"
                onClick={() => onClickCourseType('coach')}
              >
                <div />
              </StyledBannerActionArea>
            </Grid>
            <Grid item xs={6}>
              <StyledBannerActionArea
                type="online"
                onClick={() => onClickCourseType('online')}
              >
                <div />
              </StyledBannerActionArea>
            </Grid>
          </Grid>

          <StyledDiscoverFilters>
            <DiscoverFilters isShowTags={false} />
          </StyledDiscoverFilters>
        </Hidden>
        {
          // TODO distinguish empty vs loading
          _.includes(pageCourseStore.coursesFilter.type, 'coach') &&
            (_.isEmpty(coachOrderedCardsByGroup) ? (
              <LoaderGeneral />
            ) : (
              <StyledCourseCardCotaniner>
                <CarrouselGroups
                  isCarrousel={!isMuiMdUp}
                  groupOrders={_.keys(coachOrderedCardsByGroup)}
                  orderedCardsByGroup={coachOrderedCardsByGroup}
                  getGroupTitleWithGroupKey={getCourseTitleWithGroupKey(
                    'coach'
                  )}
                />
              </StyledCourseCardCotaniner>
            ))
        }
        <Container maxWidth="lg">
          {_.includes(pageCourseStore.coursesFilter.type, 'online') &&
            (_.isEmpty(nonCoachOrderedCardsByGroup) ? (
              <LoaderGeneral />
            ) : (
              <StyledCourseCardCotaniner>
                <CarrouselGroups
                  isCarrousel={!isMuiMdUp}
                  groupOrders={_.keys(nonCoachOrderedCardsByGroup)}
                  orderedCardsByGroup={nonCoachOrderedCardsByGroup}
                  getGroupTitleWithGroupKey={getCourseTitleWithGroupKey(
                    'online'
                  )}
                />
              </StyledCourseCardCotaniner>
            ))}
        </Container>
        <MountOnce
          fn={() => {
            appendScriptInBody({
              src: '//wchat.freshchat.com/js/widget.js',
              onload: rootStore.uiStateStore.onLoadFreshChat,
              id: 'freshchat-jssdk'
            });
          }}
        />
        <WaterMark key={markKey} markKey={markKey} />
      </Container>
    </>
  );
});
