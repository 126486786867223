/**
 * Models with reference to Firebase & Segment
 *
 * Profile is about user data of all platforms, not just current session
 * Use a separate state of currentPlatform and retrieve corresponding platform specific accordingly.
 *
 * Refers to user.md
 *
 *   Most of the use case we need a progressive user state
 * (e.g. show avatar when words of user not available)
 * e.g. user might have a simple profile without social media profile merging in
 * => Avoid dependencys on events & mobx
 * => allow merging with mixed orders
 *
 * For platform specific
 * https://firebase.google.com/docs/auth/web/manage-users#get_a_users_provider-specific_profile_information
 * we extend with getters (null check expected)
 */
import _ from 'lodash';
import { Category } from '~/domain/wordquest/category';
import { Platform } from '~/domain/wordquest/platform';
import { Locale } from '@wordquest/locales';
import { ProficiencyLevel } from '~/domain/wordquest/ql/difficulty-scale';
import { buildCleaner, cleanString } from 'lodash-clean';
import logger from '~/app/logger';

const clean = buildCleaner({
  // isArray: _.identity,
  isNull: _.noop,
  isUndefined: _.noop,
  isString: cleanString
});

export const cleanProfile = (profile) => clean(profile);

export default class Profile {
  constructor(
    // id should be always available, when db record is not created, it is assigned a tempId as `temp-{platform}-${id}`
    public id: string,
    public links: string[],
    public name: string,
    public email: string,
    public gender: string,
    public photoURL: URL,
    // chatConfig: now open as generic config object to support platform specific
    public platformConfig?: Record<Platform, object>,
    public role?: string,
    // duplicated but useful in scenarios we're not able to collect proficiency
    public targetLanguages?: Locale[],
    // @deprecated
    public interests?: Category[],
    public interestsByLocale?: Record<Locale, Category[]>,
    public goalsByLocale?: Record<Locale, string>,
    // TODO level vs target
    public proficiencyByLocale?: Record<Locale, ProficiencyLevel>,
    // potentially possible to ask user to config multiple targets e.g. jlpt, business, speaking etcs
    // but unncessarily confusing to use model across different mechnaism, thus use universal scale as in ql/difficulty-scale
    public difficultyTarget?: number
  ) {}

  create(doc: object) {
    return Object.assign(this, { ...doc });
  }

  mergeProfile(profile: Profile): Profile {
    logger.debug('mergeProfile', profile, this);

    // getters are retained
    return _.mergeWith(
      this,
      _.toPlainObject(_.omit(profile, [])),
      (objVal, srcVal, key, src) => {
        if (key === 'name') {
          // TODO cases
          return objVal;
        }

        if (_.isArray(objVal)) {
          return _.union(objVal.concat(srcVal));
        }
      }
    );
  }

  static create(doc: object) {
    // No default values for array types
    const {
      id,
      links,
      name,
      email,
      gender,
      photoURL,
      platformConfig,
      role,
      targetLanguages,
      interests,
      interestsByLocale,
      goalsByLocale,
      proficiencyByLocale,
      difficultyTarget
    } = doc;

    return _.defaultsDeep(
      new Profile(
        id,
        links,
        name,
        email,
        gender,
        photoURL,
        platformConfig,
        role,
        targetLanguages,
        interests,
        interestsByLocale,
        goalsByLocale,
        proficiencyByLocale,
        difficultyTarget
      ),
      doc
    );
  }
}
