// https://github.com/wordquest/wordquest/issues/742
import _ from 'lodash';
import {
  QuestType,
  QuestTypeName,
  QuestLessonNotes,
  QuestMC,
  createQuestMCChoices
} from '~/domain/wordquest/quest/quest';
import { of, from, Observable } from 'rxjs';
import { map, flatMap, tap, toArray, mergeAll, filter } from 'rxjs/operators';
import Profile from '~/domain/wordquest/profile/profile';
import { Locale } from '@wordquest/locales';
import { formatWithLocale, loadAndAddMessagesWithLocaleModule } from '~/intl';

import { getLessonEntitiesByNodeType } from '~/app/course/course-utils';
import { queryLessonWithKey } from '~/app/course/course-repo-fs';
import { queryCoursesWithLocaleContext } from '~/app/course/course-repo';
import logger from '~/app/logger';
// import { getAudiogramFilename, getAudiogramUrl } from '~/app/audiogram/audiogram';
import qs from 'qs';
import { asAudiogramOrAudioMessage } from '~/domain/wordquest/audio/audio';
import {
  upsertQuotesWithLocale,
  queryQuotesWithLocaleContext,
  ES_MAPPING_QUOTE_BY_LOCALE
} from '~/app/quote/quote-repo';
import {
  collectMultipleRichTextsEntitiesByNodeType,
  collectRichTextEntitiesByNodeType
} from '~/domain/wordquest/dom-entity-extractor';
import {
  ES_MAPPING_COURSE_BY_LOCALE,
  queryQuestsWithLocaleContext
} from '~/app/quest/quest-repo';
import { serializeAsPlainText } from '~/domain/wordquest/dom-util';
import { createMessagesWithAuthorNoteIds } from './author-note';
import { AssistantMessageFormat } from './message';

// matrix sample
// src/components/views/rooms/EventTile.js

export const queryLessonNotes = async ({ lessonKey }) => {
  const lesson = await queryLessonWithKey(lessonKey).toPromise();

  // audio inside quote might not be available as in wqdom

  const byNodeType = getLessonEntitiesByNodeType(lesson);
  let lessonAudios = _.take(byNodeType.audio, 2) || [];

  // supposed stable order but seems not true at multiple audio
  // do first quote only first
  if (_.isEmpty(lessonAudios) && !_.isEmpty(byNodeType.quote)) {
    const quote = _.first(byNodeType.quote);
    const quotesLoaded = await queryQuotesWithLocaleContext(Locale.EN, {
      filter: {
        includeIds: [quote.id]
      }
    });

    const quoteEntityByNodeType = collectMultipleRichTextsEntitiesByNodeType([
      _.get(quotesLoaded, '0.explanationRichTextByLocale.zh-TW')
    ]);
    lessonAudios = _.take(quoteEntityByNodeType.audio, 1) || [];
  }

  // TODO handle auth related later
  return {
    lesson,
    byNodeType,
    lessonAudios
  };
};

export const queryLessonWithPrimaryAuthor = async ({ courseKey }) => {
  const courses = await queryCoursesWithLocaleContext(Locale.EN, {
    filter: {
      includeIds: [courseKey]
    },
    paging: {
      size: 1
    }
  });
  // TODO lesson can override
  const course = _.first(courses) || {};
  const primaryAuthor = _.first(course.teachers);

  return {
    course,
    primaryAuthor
  };
};

// from mc-answered

export const createMcAnswerMessagePairs = (profile, quest) => {
  const { seedKey } = quest.properties;

  // order should be fixed already, either by seed or editorial
  // use index instead of abcd
  const choices = createQuestMCChoices(quest, seedKey) || [];

  return from(choices).pipe(
    flatMap((distractor, i) =>
      createAssistantMessages(profile, quest, {
        isAnswer: true,
        answered: distractor
      }).pipe(
        toArray(),
        map((messages) => [i, messages])
      )
    ),
    toArray()
  );
};

const strategies = {
  [QuestTypeName.Mc]: (profile: Profile, quest: QuestMC, context = {}) => {
    const { isAnswer = false, answered } = context;
    const choices = createQuestMCChoices(quest);
    if (isAnswer) {
      const messages = [];

      return from(
        queryQuestsWithLocaleContext(Locale.EN, {
          filter: {
            includeIds: [quest.id]
          }
        })
      ).pipe(
        flatMap(async (quests) => {
          const questLoaded = _.first(quests);
          const isCorrect = answered === quest.properties.answer;

          messages.push({
            type: 'text',
            message: formatWithLocale(Locale.ZH_TW)(
              isCorrect
                ? 'chat.quest.mc.feedback.correct'
                : 'chat.quest.mc.feedback.incorrect',
              {
                correct: quest.properties.answer
              }
            )
          });

          if (questLoaded) {
            const explanationRichText = _.get(
              questLoaded,
              'properties.explanationRichText'
            );
            const explanationEntityByNodeType =
              collectRichTextEntitiesByNodeType(explanationRichText, {
                isInclude: true
              });
            const authorNoteIds = (
              explanationEntityByNodeType.authorNote || []
            ).map(({ id }) => id);
            const audios = explanationEntityByNodeType.audio || [];

            const authorNoteMessages = await createMessagesWithAuthorNoteIds(
              authorNoteIds
            );
            const audioMessages = audios.map(({ id, url }) => ({
              audioUrl: url
            }));
            if (!_.isEmpty(explanationRichText)) {
              messages.push({
                message: serializeAsPlainText(explanationRichText)
              });
            }
            messages.push(...(authorNoteMessages || []));
            messages.push(...(audioMessages || []));
          }

          return messages;
        }),
        mergeAll(1)
      );
    }

    return of({
      type: 'text',
      message: quest.properties.question,
      quickReplies: choices
    });
  },
  [QuestTypeName.LessonNotes]: (profile: Profile, quest: QuestLessonNotes) => {
    const { properties } = quest;
    const { courseKey, lessonKey } = properties;
    logger.debug('create lesson notes messages', lessonKey);

    return from(queryLessonNotes({ lessonKey })).pipe(
      flatMap(async ({ lesson, byNodeType, lessonAudios = [] }) => {
        if (!lesson) {
          return [];
        }
        const lessonUrl = `https://perapera.ai/course/${courseKey}/lesson/${lesson.key}`;
        const { primaryAuthor } = await queryLessonWithPrimaryAuthor({
          courseKey
        });
        // TODO order should be stable
        // TODO decrease latency
        // ensure generated

        if (!primaryAuthor) {
          return [];
        }
        // TODO use async method to generate?
        // preview is good enough?

        // https://github.com/wordquest/wordquest/issues/709
        const messages = await from(lessonAudios)
          .pipe(
            map((audio) => {
              const { videoTemplateUrl, avatarUrl } = primaryAuthor;

              return asAudiogramOrAudioMessage({
                key: lessonKey,
                videoTemplateUrl,
                audio
              });
            }),
            filter((message) => !!message),
            toArray()
          )
          .toPromise();

        logger.debug('messages', messages);

        return [
          {
            type: 'text',
            message: quest.properties.messageText
          },
          {
            type: 'url',
            message: lessonUrl
          }
        ].concat(messages);
      }),
      mergeAll(1)
    );
  }
};

export const createAssistantMessages = (
  profile: Profile,
  quest: QuestType<QuestTypeName, object>,
  context
): Observable<AssistantMessageFormat> => {
  const createQuestAssistantMessages = strategies[quest.type];
  if (!createQuestAssistantMessages) {
    throw new Error(`Quest Not available: ${quest.type}`);
  }

  return createQuestAssistantMessages(profile, quest, context);
};
