import React from 'react';
import SwipeableCardsContainer from '~/swipeable-cards-container';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import WordCardContainer from './word-card-container';

const StyledFlipContainer = styled.div`
  /* entire container, keeps perspective */
  .flip-container {
    perspective: 1000px;
  }
  /* flip the pane when hovered */
  .flip-container:hover .flipper,
  .flip-container.hover .flipper {
    transform: rotateY(180deg);
  }

  .flip-container,
  .front,
  .back {
    width: 320px;
    height: 480px;
  }

  /* flip speed goes here */
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
  }

  /* hide back of pane during swap */
  .front,
  .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
  }

  /* front pane, placed above back */
  .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }

  /* back, initially hidden pane */
  .back {
    transform: rotateY(180deg);
  }

  .vertical.flip-container {
    position: relative;
  }

  .vertical .back {
    transform: rotateX(180deg);
  }

  .vertical.flip-container .flipper {
    transform-origin: 100% 213.5px; /* half of height */
  }

  .vertical.flip-container:hover .flipper {
    transform: rotateX(-180deg);
  }
`;

const WordDeck = (props: object) => {
  const {
    classes,
    words,
    isShowDefinitions,
    isShowTranslations,
    isInSearch,
    trackEvent
  } = props;
  if (words.length === 0) {
    return (
      <div>
        {isInSearch ? (
          <FormattedMessage id="common.word.deck.not-found" />
        ) : (
          <FormattedMessage id="common.word.deck" />
        )}
      </div>
    );
  }
  const slides = words.map((word) => (
    <div>
      <StyledFlipContainer>
        {/* <WordCardContainer key={word.id} word={word} /> */}
        <WordCardContainer
          key={word.id}
          word={word}
          isShowDefinitions={isShowDefinitions}
          isShowTranslations={isShowTranslations}
          trackEvent={(event) => trackEvent(event)}
        />
      </StyledFlipContainer>
    </div>
  ));

  return <SwipeableCardsContainer>{slides}</SwipeableCardsContainer>;
};

export default WordDeck;
