import React from 'react';
import _ from 'lodash';
import 'lazysizes';
import { PictureWithCaptionProps } from '@wordquest/lib-iso/domain/wordquest/dom';
import styled from 'styled-components';

// Quick workaround before we merge in <Image> with figure

// we deprecated material-ui-image as it do not support figure / srcSets
// meanwhile we should learn the API + mateiral ui specs

// Ensure figure normalized without browser default padding

// https://github.com/TeamWertarbyte/material-ui-image/blob/43c67fcc3b8eb1f5c91bcc04906b3e88e9019fe1/src/components/Image/Image.js
// https://css-tricks.com/aspect-ratio-boxes/
// css aspect-ratio is unready
// https://caniuse.com/#feat=mdn-css_properties_aspect-ratio
const StyledFigure = styled.figure`
  margin: 0em;
  height: 0;
  overflow: hidden;
  object-position: center;
  padding-top: ${(props) => `calc((1 / ${props.aspectRatio})  * 100%)`};
  position: relative;
  width: 100%;
  & img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
`;

export const PictureWithCaption = ({
  src,
  title,
  description,
  srcsetByType,
  isHideCaption = false,
  style = { width: '100%', maxWidth: '40rem', margin: 'auto' },
  aspectRatio = 16 / 9
}: PictureWithCaptionProps) => {
  const sourceTags = _.map(srcsetByType, (srcset, type) => (
    <source
      key={`source-${type}`}
      type={`image/${type}`}
      data-srcset={srcset.join(',')}
    />
  ));

  // https://web.dev/native-lazy-loading/
  // https://caniuse.com/#search=lazyload
  // set width & height anyway https://web.dev/optimize-cls/#images-without-dimensions
  return (
    <div style={style}>
      <StyledFigure aspectRatio={aspectRatio}>
        <picture>
          {sourceTags}
          <img
            className="lazyload"
            data-src={src}
            loading="lazy"
            alt={description || title || ''}
          />
        </picture>
        {!isHideCaption && <figcaption>{title || ''}</figcaption>}
      </StyledFigure>
    </div>
  );
};

// https://github.com/TeamWertarbyte/material-ui-image#readme

// http://afarkas.github.io/lazysizes/#examples
// https://stackoverflow.com/questions/12899691/use-of-picture-inside-figure-element-in-html5

export default PictureWithCaption;
