import _ from 'lodash';
import { createTransformer } from 'mobx-utils';
// in groupBy, use getLast vs
export const createEntityAggregateByKey = (
  entity: Entity,
  getEventKey = (event) => event.properties[_.toLower(entity)].id,
  eventsProcessor = (events) =>
    _.first(_.orderBy(events, (e) => e.actionAt, 'desc'))
) =>
  createTransformer((events) => {
    const entityKey = _.toLower(entity);

    // existing is observable
    // suppose recursive already
    return _.mapValues(
      _.groupBy(
        _.filter(events, _.isObject)
          .filter((event) => event && !!event.properties)
          .map((event) => ({
            // can extend with last event properties e.g. below
            // firstActionAt: eventPrev.actionAt,
            // totalCount:
            key: getEventKey(event),
            actionAt: event.actionAt,
            // lastActionAt: event.actionAt,
            [entityKey]: event.properties[entityKey],
            ...event.properties
          })),
        (obs) => obs.key
      ),
      // eventsProcessor
      (events) => eventsProcessor(events)
    );
  });

export const createEntityAggregate = (entity: Entity) =>
  createTransformer((log) =>
    _.reduce(
      _.groupBy(log, 'action'),
      (agg, events, action) => {
        // assume obj is good enough don't use Map
        // unable to extend with recursive?
        // logger.debug('agg[action]', agg, events, agg[action]);
        const actionAgg = createEntityAggregateByKey(entity)(events);
        // if (!agg[action]) {
        //   return extendObservable(agg, { [action]: actionAgg });
        // }
        // set(agg, action, actionAgg);
        agg[action] = actionAgg;

        return agg;
      },
      {}
    )
  );
