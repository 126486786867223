import _ from 'lodash';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import logger from '@wordquest/lib-iso/app/logger';

const STRAPI_ENDPOINT =
  process.env.REACT_APP_STRAPI_ENDPOINT ?? 'https://strapi.perapera.ai';

export type FormBody = {
  inputType: string;
  name: string;
  label: string;
  isRequired: boolean;
  isHidden: boolean;
};

export enum FormBodyPosition {
  Above = 'above',
  Below = 'below'
}

export type SharedFormFieldResponse = {
  position: FormBodyPosition;
  title: string;
  shared_field: FormBody[];
};

export type StrapiCourseFormResponse = {
  formBody: FormBody[];
  id: number;
  key: string;
  title: string;
  locale: Locale;
  shared_form_fields: SharedFormFieldResponse[];
};

export type CourseFormMeta = Record<
  string,
  Record<Locale, StrapiCourseFormResponse>
>;

export type SharedFormFieldsMeta = Record<FormBodyPosition, FormBody[]>;

export const fetchCourseForms = async (): Promise<
  StrapiCourseFormResponse[]
> => {
  const req =
    'https://wordquest-strapi.s3.ap-southeast-1.amazonaws.com/teacher-course-forms-latest.json';

  try {
    return fetch(req).then((res) => res.json());
  } catch (err) {
    logger.error(err);

    return [];
  }
};

/**
 * We will always mounted  subset of wq locales SUPPORTED_LOCALES on strapi
 * so here is to fallback on error
 */
export const asWqLocale = (localeString?: string): Locale => {
  if (!localeString || !_.includes(SUPPORTED_LOCALES, localeString)) {
    return Locale.ZH_TW;
  }

  return localeString;
};

export const fetchCourseFormBody = async (
  courseKey: string,
  locale?: Locale
): Promise<FormBody[]> => {
  try {
    const req = `${STRAPI_ENDPOINT}/teacher-course-forms/${courseKey}?_locale=${locale}`;
    logger.debug('Strapi request:', req);
    const data = await fetch(req).then((res) => res.json());
    logger.debug('Strapi response data', data);

    return data.formBody;
  } catch (err) {
    logger.error(err);

    return [];
  }
};

export const asCourseFormsMeta = (
  courseFormResponses: StrapiCourseFormResponse[]
): CourseFormMeta =>
  _.mapValues(
    _.groupBy(courseFormResponses, (res) => res?.key),
    (courseFormResponses) =>
      _.fromPairs(
        courseFormResponses.map((courseFormResponse) => [
          asWqLocale(courseFormResponse?.locale),
          courseFormResponse
        ])
      )
  );

export const asSharedFormFieldsMeta = (
  sharedFormFieldsResponse: SharedFormFieldResponse[]
): SharedFormFieldsMeta =>
  _.mapValues(
    _.groupBy(sharedFormFieldsResponse, 'position'),
    (sharedFormFields) =>
      sharedFormFields.flatMap((response) => response?.shared_field)
  );

export const getCourseFormBody = (courseFormMeta: CourseFormMeta) => {
  const specificBody = courseFormMeta?.formBody;

  const sharedFormFieldsMeta = asSharedFormFieldsMeta(
    courseFormMeta?.shared_form_fields
  );

  return _.concat(
    sharedFormFieldsMeta?.above ?? [],
    specificBody,
    sharedFormFieldsMeta?.below ?? []
  );
};

export const asPlanByPriceId = (courseFormBody: FormBody[]) => {
  const plansInput = courseFormBody.find((input) => input.name === 'plans');
  const planOptions = plansInput?.options;

  return _.mapValues(_.groupBy(planOptions, 'priceId'), (optionArray) =>
    _.head(optionArray)
  );
};