import React from 'react';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import TextAnnoated from '~/text/text-annotated';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import logger from '@wordquest/lib-iso/app/logger';
import styled from 'styled-components';
import SkipNextIcon from '@material-ui/icons/SkipNext';

const StyledCueAnchor = styled.a`
  color: #113284;
  text-decoration: none;
  cursor: pointer;
  > span {
    padding: 0em 0.25em;
    text-decoration: none;
    font-size: 1em;
  }
`;

export const CueAnchor = ({ onClick }) => (
  <StyledCueAnchor
    onClick={() => {
      onClick();
    }}
  >
    <SkipNextIcon />
  </StyledCueAnchor>
);
