import _ from 'lodash';
import Profile from '~/domain/wordquest/profile/profile';
import { Locale } from '@wordquest/locales';
import { mapLevelAsProficiencyOption } from '~/domain/wordquest/ql/difficulty-scale';
import rawTags from '@wordquest/lib-static/dist/tags-raw.json';
import logger from '~/app/logger';
import {
  asTagsWithGeneratedSegmentKeys,
  generateSegmentsWithLocales
} from './tag';

export type Category = 'others' | 'profession' | 'company' | 'work';

export type Segment = {
  key: string;
  semanticKey: string;
  type: 'proficiency' | 'goal' | 'interest' | 'role' | 'core';
  locale: Locale;
  // category: Category
};

// don't use snake case directly

export const segmentAsKey = (segment: Segment) =>
  [
    segment.type,
    segment.locale,
    (segment.semanticKey || '').replace('-', '_')
  ].join('-');

export const keyAsSegment = (key: string): Segment => {
  const keySubstrings = _.split(key, '-');
  if (keySubstrings.length !== 3) {
    return null;
  }
  const [_type, locale, ..._semanticKey] = keySubstrings;

  const type = _.toLower(_type);
  const semanticKey = _.join(_semanticKey, '_');

  return {
    key,
    type,
    locale,
    semanticKey
  };
};

// For now we don't check against "standard" segments but will just assume they always exists.
// limited subset of Profile for memo
export const matchProfileSegments = (
  {
    analyzedSegments,
    proficiencyByLocale,
    interestsByLocale,
    goalsByLocale,
    targetLanguages,
    role
  } = {
    analyzedSegments: [],
    proficiencyByLocale: {},
    interestsByLocale: {},
    goalsByLocale: {},
    targetLanguages: [],
    role: ''
  }
): Segment[] => {
  const interestSegments = _.flatMap(interestsByLocale, (interests, locale) =>
    _.map(interests, (interest) => ({
      locale,
      type: 'interest',
      semanticKey: interest
    }))
  );
  const goalSegments = _.flatMap(goalsByLocale, (goals, locale) =>
    _.map(goals, (goal) => ({
      locale,
      type: 'goal',
      semanticKey: goal
    }))
  );
  const proficiencySegments = _.flatMap(
    proficiencyByLocale,
    (proficiency, locale) => {
      const option = mapLevelAsProficiencyOption(locale, proficiency);
      if (!option) {
        return;
      }

      return {
        locale,
        type: 'proficiency',
        semanticKey: option.key
      };
    }
  );

  const profileSegments = (targetLanguages || []).map((locale) => ({
    locale,
    type: 'role',
    semanticKey: role
  }));

  const roleSegments = generateSegmentsWithLocales({ type: 'role', key: role });

  return _.concat(
    interestSegments,
    goalSegments,
    proficiencySegments,
    profileSegments,
    roleSegments,
    _.keys(analyzedSegments).map(keyAsSegment)
  ).filter(Boolean);
};

// score based
// manual priority = weight 10
// videos count = weight 1
export const pickSegmentTags = (
  segment: Segment,
  allTags,
  videoCountsBoost
) => {
  const allWeightBoosts = [
    (t) => (_.includes(t.segmentKeys, segment.key) ? 100 : 0.1),
    (t) => (t.priority || 1) * 10,
    videoCountsBoost || ((t) => (t.videoCount || 0) * 2)
  ];

  const tagWithScores = allTags.map((t) =>
    _.merge(t, {
      score: _.reduce(allWeightBoosts, (acc, current) => acc * current(t), 1.0)
    })
  );

  return tagWithScores.filter((t) => t.score > 0);
  // return _.orderBy(tagWithScores.filter(t => t.score > 0), ['score'], ['desc']);
};

export const createTagsBySegmentKey = (
  segments: Segment[],
  limit = 15,
  filter = (t) => t.score >= 50,
  allTags = rawTags
): Record<string, string[]> =>
  _.fromPairs(
    segments.map((segment) => [
      segmentAsKey(segment),
      _.take(
        pickSegmentTags(
          segment,
          asTagsWithGeneratedSegmentKeys(allTags),
          null
        ).filter(filter),
        limit
      )
    ])
  );
