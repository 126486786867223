import _ from 'lodash';
// import covidData from '@wordquest/lib-static/dist/covid19.json';
// import logger from '@wordquest/lib-iso/app/logger';
import {
  fetchCovidStats,
  COVID_ENDPOINT
} from '@wordquest/lib-iso/app/topic/covid19';

const covidData = {};

export const findColorScale = (value) => {
  if (value > 500) {
    return 'color-case-500';
  }
  if (value > 250) {
    return 'color-case-250';
  }

  if (value > 100) {
    return 'color-case-100';
  }
  if (value > 10) {
    return 'color-case-10';
  }
  if (value > 0) {
    return 'color-case-1';
  }

  return 'color-github-0';
};

export const extendWithOtherCountriesTerms = (byTabData) => {
  const allCountryCodes = _.keys(byTabData);

  return _.mapValues(byTabData, (data, countryCode) => {
    const { links, queryContext } = data;
    // unintutive & error prone, deprecated including terms
    const otherCountriesTerms = _.flatMap(
      _.difference(allCountryCodes, [countryCode]),
      (otherCountryCode) => byTabData[otherCountryCode].queryContext.termsTab
    );
    queryContext.termsNegative =
      queryContext.termsNegative.concat(otherCountriesTerms);

    return data;
  });
};

export const loadDataByTopicDataKey = (topicKey, dataKey) => {
  const key = [topicKey, dataKey].join('-');
  const corsHeaders = {
    // 'x-requested-with': 'XMLHttpRequest'
  };

  return (
    {
      'covid19-init': () =>
        _.merge(
          {
            title: 'video.topic.covid19.title'
          },
          _.merge(
            // for a hardcode order
            {
              byTab: _.fromPairs(
                ['TW', 'CN', 'KR', 'HK', 'JP', 'SG', 'IT', 'IR', 'DE'].map(
                  (k) => [k, {}]
                )
              )
            },
            covidData,
            {
              byTab: extendWithOtherCountriesTerms(covidData.byTab)
            }
          )
        ),
      'covid19-stats': () =>
        fetchCovidStats(COVID_ENDPOINT.CURRENT_ESRI, corsHeaders),
      'covid19-stats-confirmed': () =>
        fetchCovidStats(COVID_ENDPOINT.TS_CONFIRMED, corsHeaders)
    }[key] || _.noop
  );
};
// TODO use common init

// tags too?
// better use at transcript with min match
// make more sense when more docs / score min
export const termsAsVideoTitleDescriptionContext = (termFilter) => {
  console.log('termFilter', termFilter);

  return {
    filter: {
      'video.title': termFilter,
      'video.description': termFilter
    }
  };
};
