import qs from 'qs';
import _ from 'lodash';
import Profile from '~/domain/wordquest/profile/profile';
import logger from '~/app/logger';
import { SITE_ROOT_UI_SITE } from '~/env';

export const decodeMToken = (m) => {
  if (global.window && global.window.atob) {
    const token = global.window.atob(m);
    if (token.match(/-/)) {
      return true;
    }
  }

  return false;
};

/**
 * Map Context to/from query params
 * Shared across backend (e.g. which create urls) & UI modules
 */
export type UrlContext = object;

export const defaultContext = {
  uiConfig: {
    isMessengerKitEnabled: false,
    isLiffEnabled: false,
    isUseLiffUrl: process.env.WQ_LINE_URL_USE_LIFF,
    isNavbarShown: true,
    // so it will persist
    // isDebug: false,
    // isMagic: false,
    mock: {
      search: false,
      article: false,
      video: false
    }
  },
  // limited to debug only
  // In chatbot webview, sdk to get proper idToken & load the auth account
  profile: null
};

// qs.stringify(effectiveSearch)
// UI only
export const parseUrlSearchForContext = (search: object) => {
  console.log('search', search);
  const context = _.merge({}, defaultContext);

  if (search.m) {
    if (global.window && global.window.atob) {
      // don't override existing
      const isMagic = decodeMToken(search.m);
      if (isMagic) {
        _.merge(context, {
          uiConfig: {
            isMagic
          }
        });
      }
    }
  }

  if (search.mode === 'fbm') {
    // fpsid
    _.merge(context, {
      uiConfig: {
        isMessengerKitEnabled: true,
        isNavbarShown: false
      }
    });
  }
  if (search.mode === 'liff') {
    _.merge(context, {
      uiConfig: {
        isLiffEnabled: true,
        isLiffLoaded: false,
        isLiffReady: false,
        isNavbarShown: false
      }
    });
  }

  if (process.env.REACT_APP_MOCK === 'all' || search.mock === 'all') {
    _.merge(context, {
      uiConfig: {
        mock: _.mapValues(context.uiConfig.mock, (isMock) => true)
      }
    });
  }

  logger.debug(context);

  context.profile = Profile.create({});

  // a more generic profile fixture
  if (search.jlpt) {
    // add jlpt scale
  }
  // fixture profiles

  // use default to persist
  const isSidebarLeft = _.isString(search.sidebar)
    ? search.sidebar !== 'off'
    : undefined;

  return _.merge(context, {
    uiConfig: {
      isDebug: _.isString(search.debug) || undefined,
      isDebugWithPrintedData: search.debug === '2',
      isSidebarLeft
    }
  });
};

export const createSearchWithContext = (context: UrlContext) => {
  const searchObj = {};
  if (_.get(context, 'uiConfig.isDebug')) {
    searchObj.debug = '1';
  }
  if (_.get(context, 'uiConfig.isMessengerKitEnabled')) {
    searchObj.mode = 'fbm';
  }

  return searchObj;
};

export const createUrlWithSearch = (appPath: string, search: object) =>
  `${SITE_ROOT_UI_SITE}/${appPath}${
    _.isEmpty(search) ? '' : '/?'
  }${qs.stringify(search)}`;

export const createUrlWithContext = (
  appPath: string,
  search: object,
  context?: UrlContext
) => {
  const effectiveSearch = _.merge({}, search, createSearchWithContext(context));

  return createUrlWithSearch(appPath, effectiveSearch);
};

// existing params continue
