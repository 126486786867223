import _ from 'lodash';
import { collectMultipleRichTextsEntitiesByNodeType } from '~/domain/wordquest/dom-entity-extractor';

export const getLessonQuestsEntitiesByNodeType = (lesson) => {
  // each quest could have different field, make it simple now
  const richTextsInQuests = (_.get(lesson, 'questItems') || []).map(
    (questItem) => _.get(questItem, 'properties.explanationRichText')
  );

  return collectMultipleRichTextsEntitiesByNodeType(
    richTextsInQuests.filter(Boolean),
    { isInclude: true }
  );
};

export const getLessonEntitiesByNodeType = (lesson) => {
  const richText = _.get(lesson, 'material.contentRichText');
  const remarksRichText = _.get(lesson, 'remarksRichText');

  return collectMultipleRichTextsEntitiesByNodeType(
    [richText, remarksRichText].filter(Boolean),
    { isInclude: true }
  );
};

export const getCourseIntroEntitiesByNodeType = (courseIntro) => {
  const sections = _.get(courseIntro, 'sections') || [];

  // TODO extract generic merge array mechanism
  return collectMultipleRichTextsEntitiesByNodeType(
    sections.map((s) => s.descriptionRich),
    { isInclude: true }
  );
};
