import React from 'react';
import { observer, inject, action } from 'mobx-react';
import logger from '@wordquest/lib-iso/app/logger';
import _ from 'lodash';
import {
  MountOnce,
  appendScriptInBody,
  DebugOnly
} from '@wordquest/ui-components/page-util';
import RootStore, { useRootStore } from '~/stores/root';
import {
  isWindowMuiBreakpointUp,
  findCurrentBreakpoint
} from '@wordquest/ui-components/style-utils';
import {
  requestCustomTokenWithLine,
  createOnAuthStateChangedCallback,
  AuthStateEvent,
  initAuthStateService,
  AuthState,
  AuthMessageState,
  createOnLineAuthSuccessCallback
} from '~/services/auth';
import { IS_DEV } from '@wordquest/lib-iso/env';

// Note we use custom hooks instead of sdk onload e.g.  window.extAsyncInit
export default observer((props) => {
  // const { classes, history } = props;
  const rootStore: RootStore = useRootStore();
  const { routerStore, uiStateStore } = rootStore;
  const { uiConfig, debugLog, app } = rootStore.uiStateStore;

  if (uiConfig.isDebug) {
    (async () => {
      // @ts-ignore
      const VConsole = (await import('vconsole/dist/vconsole.min')).default;
      const vConsole = new VConsole();
      logger.debug('vConsole init', vConsole);
    })();
  }

  const toggleDebugMode = () => {
    uiConfig.isDebug = !uiConfig.isDebug;
  };

  if (uiConfig.isLiffEnabled && uiConfig.isLiffLoaded) {
    const liff = window.liff;
    // this.routeMatch.path = '/';
    app.logLine('init liff');

    if (liff) {
      liff
        .init({
          liffId: process.env.REACT_APP_LINE_LIFF_ID
        })
        .then(async () => {
          // Start to use liff's api

          const { hash, search, pathname = '' } = routerStore.location || {};

          routerStore.history.push(
            pathname.replace('/liff', '') + search || ''
          );

          uiConfig.isLiffReady = true;
        })
        .catch((err) => {
          app.logLine('err', err);
        });
    }
  }

  return (
    <div>
      <div id="fb-root" />
      <div>
        <DebugOnly data={{ uiConfig }}>
          <p>
            {findCurrentBreakpoint()}
            <a href="#" onClick={() => toggleDebugMode()}>
              [Debug]
            </a>
            {`${window.location.href}`}
          </p>
        </DebugOnly>
        {uiConfig.isMessengerKitEnabled && app.isGenericDelayCompleted && (
          <MountOnce
            fn={() => {
              appendScriptInBody({
                src: '//cdn.embedly.com/widgets/platform.js',
                onload: rootStore.uiStateStore.onLoadFbm
              });
            }}
          />
        )}
        {/* uiConfig.isChatBubbleEnabled && app.isGenericDelayCompleted && (
          <MountOnce
            fn={() => {
              appendScriptInBody({
                src: '//connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js',
                onload: rootStore.uiStateStore.onLoadCustomerChat,
                id: 'facebook-jssdk'
              });
            }}
          />
        ) */}
        {uiConfig.isLiffEnabled && (
          <MountOnce
            fn={() => {
              appendScriptInBody({
                src: '//static.line-scdn.net/liff/edge/2/sdk.js',
                charset: 'utf-8',
                onload: () => {
                  uiConfig.isLiffLoaded = true;
                },
                id: 'liff'
              });
            }}
          />
        )}
      </div>
    </div>
  );
});
