import React from 'react';
import logger from '@wordquest/lib-iso/app/logger';
// TODO in future we should also encapsulate paperform, simplybook etc
// can think about how it play aroundasync scriptts
import TallyForm from '@wordquest/ui-components/form/tally';

export enum EmbededKeyService {
  Tally = 'tally',
  Paperform = 'paperform'
}

export type EmbededComponentMeta = {
  key: EmbededKeyService;
};

const RENDER_STRATEGIES_BY_SERVICE = {
  [EmbededKeyService.Tally]: (props = {}) => {
    console.log('props', props);

    return <TallyForm formId={props.formId} />;
  }
  // [EmbededKeyService.Paperform]: () => {
  //     return <div />
  // }
};

export const renderEmbededComponent = (meta: EmbededComponentMeta) => {
  const strategy = RENDER_STRATEGIES_BY_SERVICE[meta?.key];
  if (!strategy) {
    logger.error('Incorrect EmbededComponentMeta', meta);

    return null;
  }

  return strategy(meta);
};

export const parseEmbededComponentMetas = (
  embededKeys: EmbededComponentMeta[] = []
) =>
  embededKeys.map((embededKey) => {
    if (typeof embededKey !== 'string') {
      return;
    }
    if (embededKey.startsWith(EmbededKeyService.Tally)) {
      const formId = embededKey.replace(`${EmbededKeyService.Tally}-`, '');

      return { key: EmbededKeyService.Tally, formId };
    }

    return {};
  });
