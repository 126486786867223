import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button
} from '@material-ui/core';
import logger from '@wordquest/lib-iso/app/logger';
import { timer } from 'rxjs';
import { Locale } from '@wordquest/locales';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import {
  ContactUs,
  PleaseWait,
  formStatusReducer,
  formInitialStatus,
  FormStatus,
  baseComponentStrategies
} from '@wordquest/ui-components/error-handle';
import { BlueWhiteButton } from '@wordquest/ui-components/button/buttons';
import { handleCourseFormWithTimeout } from '~/services/course-form';
import { handleCourseForm } from '~/services/stripe';
import { getCourseFormBody, asPlanByPriceId } from '~/services/strapi';
import { getUTMFromWindowLocation } from '~/services/utm';

export type TextInput = {
  inputType: 'text' | 'email';
  id: number;
  isRequired: boolean;
  label: string;
  name: string;
};

export type Option = {
  label: string;
  value: string;
};

export type SelectInput = {
  inputType: 'select';
  options: Option[];
  id: number;
  label: string;
  name: string;
  isRequired: boolean;
};

export type Plan = {
  id: number;
  label: string;
  priceId: string;
  subscriptionTrailDays: number;
  ticketType: string;
};

export type PlansInput = SelectInput & {
  options: Plan[];
};

export type SupportedInput = TextInput | SelectInput;

export enum SupportedInputType {
  Text = 'text',
  Email = 'email',
  Select = 'select'
}

type inputComponentCreator = (inputData: SupportedInput) => JSX.Element;

export const inputComponentFactoryCreator = (
  formRegister: UseFormReturn['register']
) => {
  const strategies: Record<SupportedInputType, inputComponentCreator> = {
    [SupportedInputType.Text]: ({ name, label, isRequired }: TextInput) => (
      <Grid item key={name} sm={12}>
        <TextField
          fullWidth
          label={label}
          required={isRequired}
          {...formRegister(name, { required: isRequired })}
        />
      </Grid>
    ),
    [SupportedInputType.Email]: ({ name, label, isRequired }: TextInput) => (
      <Grid item key={name} sm={12}>
        <TextField
          fullWidth
          type="email"
          label={label}
          required={isRequired}
          {...formRegister(name, { required: isRequired })}
        />
      </Grid>
    ),
    [SupportedInputType.Select]: ({
      name,
      label,
      options,
      isRequired
    }: SelectInput) => (
      <Grid item key={name} sm={12}>
        <FormControl fullWidth>
          <InputLabel id={name} required={isRequired}>
            {label}
          </InputLabel>
          <Select
            defaultValue={
              options.length === 1
                ? options[0]?.value || options[0]?.priceId
                : null
            }
            labelId={name}
            id={name}
            {...formRegister(name, { required: isRequired })}
          >
            {options.map(({ value, label, priceId, ticketType }) => {
              if (priceId && ticketType) {
                return (
                  <MenuItem key={priceId} value={priceId}>
                    {label}
                  </MenuItem>
                );
              }

              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    )
  };

  return (inputData: SupportedInput) => {
    if (!inputData.inputType) return <></>;

    return strategies[inputData.inputType](inputData);
  };
};

export type CourseFormMeta = {
  formBody: SupportedInput[];
  key: string;
  locale: Locale;
  title: string;
};

export type CourseFormStrapiParameters = {
  courseFormMeta: CourseFormMeta;
  courseFormBody: SupportedInput[];
  locale: Locale;
  courseKey: string;
  trackEvent: (event: {
    event: string;
    properties: Record<string, unknown>;
  }) => void;
};

export const CourseFormStrapi = React.memo(
  ({
    courseFormMeta,
    courseFormBody,
    locale,
    courseKey,
    trackEvent
  }: CourseFormStrapiParameters) => {
    const [formState, dispatch] = React.useReducer(
      formStatusReducer,
      formInitialStatus
    );
    const { register, handleSubmit, watch, setValue, getValues } = useForm();
    const componentFactory = inputComponentFactoryCreator(register);

    if (_.isEmpty(courseFormMeta)) {
      return null;
    }
    const courseTitle = courseFormMeta?.title;

    const WAIT_TOO_LONG_MS = 15 * 1000;
    const TIMEOUT_MS = 60 * 1000;

    const submitHandler = async (props) => {
      dispatch({ type: FormStatus.IsSubmitted });

      timer(WAIT_TOO_LONG_MS).subscribe(() => {
        dispatch({ type: FormStatus.WaitTooLong });
      });

      try {
        await handleCourseFormWithTimeout(TIMEOUT_MS)(props);
      } catch (error) {
        dispatch({ type: FormStatus.ErrorCaught });
        trackEvent({
          event: 'Course-FormSubmissionFailed',
          properties: {
            submittedFormData: getValues()
          }
        });
      }
    };

    const planByPriceId = React.useMemo(
      () => asPlanByPriceId(courseFormBody),
      [courseFormBody]
    );

    const watchPlans = watch('plans');
    React.useEffect(() => {
      if (watchPlans) {
        const { label, priceId, subscriptionTrailDays, ticketType } =
          planByPriceId[watchPlans] as Plan;

        setValue('plan-title', label);
        setValue('ticket-type', ticketType);
        setValue('price-id', priceId);

        if (subscriptionTrailDays) {
          setValue(
            'subscription-trial-days',
            parseInt(subscriptionTrailDays, 10) || undefined
          );
        }
      }
    }, [watchPlans]);

    React.useEffect(() => {
      const utm = getUTMFromWindowLocation();
      setValue('utm-source', utm?.utmSource ?? '');
      setValue('utm-medium', utm?.utmMedium ?? '');
      setValue('utm-campaign', utm?.utmCampaign ?? '');
      setValue('utm-term', utm?.utmTerm ?? '');
      setValue('utm-content', utm?.utmContent ?? '');
    }, [getUTMFromWindowLocation, setValue]);

    const strategies = {
      [FormStatus.IsSubmitted]: <LoaderGeneral />,
      [FormStatus.WaitTooLong]: <PleaseWait />,
      [FormStatus.ErrorCaught]: (
        <ContactUs onClickRetry={handleSubmit(submitHandler)} />
      ),
      [FormStatus.Default]: (
        <Grid container alignItems="center" justify="center">
          <Grid
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            container
            spacing={4}
            direction="column"
            xs={12}
            md={6}
            lg={4}
          >
            {courseFormBody?.map((formItem) => componentFactory(formItem))}
            <input
              type="hidden"
              {...register('course-title')}
              value={courseTitle}
            />
            <input
              type="hidden"
              {...register('course-key')}
              value={courseKey}
            />
            <input
              type="hidden"
              {...register('user-locale')}
              value={locale || 'auto'}
            />
            <Grid container item justify="center">
              <Grid item>
                <BlueWhiteButton type="submit">
                  <FormattedMessage id="common.course.form.checkout" />
                </BlueWhiteButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    };

    return strategies[formState.status];
  }
);
