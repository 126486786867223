import _ from 'lodash';
import { loadDbRefs } from '@wordquest/lib-iso/adapters/firestore';
import {
  map,
  mergeAll,
  flatMap,
  toArray,
  first,
  bufferCount
} from 'rxjs/operators';
import { from, of, identity } from 'rxjs';
import Course from '@wordquest/lib-iso/domain/wordquest/course/course';
import { observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import logger from '@wordquest/lib-iso/app/logger';
import {
  queryCourseWithKey,
  queryLessonsWithLessonIds
} from '@wordquest/lib-iso/app/course/course-repo-fs';
import RootStore from '~/stores/root';
import { queryCoursesWithContexts } from '@wordquest/lib-iso/app/course/course-repo';
import { Locale } from '@wordquest/locales';

export default class CourseStore {
  constructor(private rootStore: RootStore) {
    this.cacheCoursesByKey = (courses) => {
      const courseByKey = _.keyBy(courses, 'key');
      this.mergeCourseByKey(courseByKey);

      return this.courseByKey;
    };

    // this.loadCoursesWithIds = (ids) => {
    //
    // };

    this.loadCoursesWithContextByGroup = (
      contextByGroup,
      excludeCourseKeys = []
    ) => {
      if (_.isEmpty(contextByGroup)) {
        return of({});
      }

      const excludeIds = _.filter(excludeCourseKeys, Boolean);
      console.log('loadCoursesWithContextByGroup', excludeIds);
      const contexts = _.values(contextByGroup).map((context) =>
        _.merge(context, {
          filter: {
            excludeIds,
            'course.isHidden': false
          }
        })
      );

      return from(queryCoursesWithContexts(Locale.EN, contexts)).pipe(
        map((coursesGroups) => {
          this.cacheCoursesByKey(_.flatten(coursesGroups));

          return _.zipObject(_.keys(contextByGroup), coursesGroups);
        })
      );
    };

    this.loadCoursesWithKeys = (keys) =>
      from(keys).pipe(
        flatMap((key) => queryCourseWithKey(key)),
        toArray(),
        map((courses) => this.cacheCoursesByKey(courses)),
        first()
      );

    // not too much share of material
    // lesson = materialized view of material + questItems?
    // often used tgt

    // Deprecate as misleading at duplicated key
    // this.loadLessonWithKey = lessonKey => queryLessonWithKey(lessonKey)
    //   .pipe(
    //     map(
    //       lesson => ({
    //         [lessonKey]: lesson
    //       })
    //     ),
    //     first(),
    //     map((lessonByKey) => {
    //       this.mergeLessonByKey(lessonByKey);
    //       return this.lessonByKey;
    //     })
    //   );

    this.loadLessonWithLessonIds = (lessonIds) =>
      from(lessonIds).pipe(
        bufferCount(20),
        flatMap((lessonIds) =>
          queryLessonsWithLessonIds(lessonIds).pipe(
            map((lessons) => {
              const lessonByKey = _.keyBy(lessons, 'key');
              this.mergeLessonByKey(lessonByKey);

              return this.lessonByKey;
            }),
            first()
          )
        )
      );
    this.loadLessonsWithCourseKey = (courseKey) => {
      const course = this.courseByKey.get(courseKey);

      if (!course) {
        return of(null);
      }
      const lessonIds = _.flatMap(course.courseSections, (courseSection) =>
        courseSection.lessons
          .filter((l) => !l.isHidden)
          .map((lesson) => lesson.id)
      );

      return this.loadLessonWithLessonIds(lessonIds);
    };

    this.mergeCourseByKey = (courseByKey: Record<string, Course>) => {
      logger.debug('mergeCourseByKey', courseByKey);
      this.courseByKey.merge(courseByKey);
    };

    this.mergeLessonByKey = (lessonByKey: Record<string, object>) => {
      logger.debug(
        'mergeLessonByKey',
        _.mapValues(lessonByKey, (lesson) =>
          _.merge(lesson, { isCurrent: true })
        )
      );
      this.lessonByKey.merge(
        _.mapValues(lessonByKey, (lesson) =>
          _.merge(lesson, { isCurrent: true })
        )
      );
    };
  }

  courseByKey = observable(new Map());

  lessonByKey = observable(new Map());
}
