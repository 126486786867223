import _ from 'lodash';
import bodybuilder from 'bodybuilder';
import { format } from 'date-fns';
import Course from '~/domain/wordquest/course/course';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity, DataAction } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withPaging,
  withSort,
  withIncludeIdsFilter,
  withTermsFilter
} from '~/app/repo-es-util';
import rootLogger from '~/app/logger';
import {
  createDataEvent,
  GlossaryUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';
import { asQuote } from '~/app/quote/quote-cf';
import Glossary from '~/domain/wordquest/glossary';

const logger = rootLogger.child({ module: 'course-repo' });

export type GlossaryEsDoc = {
  _id: string;
  glossary: {
    description: string;
  };
};

export const ES_MAPPING_GLOSSARY_BY_LOCALE = _.fromPairs(
  SUPPORTED_LOCALES.map((sourceLocale) => [
    sourceLocale,
    {
      properties: {
        glossary: {
          properties: {
            description: {
              type: 'string'
            },
            // we don't index the richText but original markdown
            indexedAt: {
              type: 'date'
            }
          }
        }
      }
    }
  ])
);

type GlossaryQueryContext = BaseQueryContext &
  Glossary & {
    glossary: {
      id: string;
    };
  };

export const docAsGlossary = ({ _id, _source: { glossary }, fields }) =>
  glossary;

export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Glossary];

export const mapGlossaryAsDoc = (_glossary: Glossary): GlossaryEsDoc => {
  const glossary = _.omit(_.toPlainObject(_glossary), [
    'descriptionRichText',
    'detailsHtml'
  ]) as GlossaryEsDoc['glossary'];

  return {
    id: glossary.id,
    glossary
  };
};

export const buildGlossaryQuery = (context) => {
  let body = bodybuilder();

  // use same size as ids
  body = withPaging(
    _.defaultsDeep(context, {
      paging: {
        page: 0,
        size: 50
      }
    })
  )(body);

  body = withIncludeIdsFilter(context)(body);

  return body.build();
};

export const queryGlossariesWithLocaleContext = async (
  locale: Locale,
  context: GlossaryQueryContext
) => {
  const query = buildGlossaryQuery(context);
  logger.debug(
    'queryGlossaryWithLocaleContext query & context',
    query,
    context
  );

  return search(ES_CONFIG.APP_CONTENT, ES_INDEX_BY_LOCALE[locale], query).then(
    (res) => res.hits.hits.map(docAsGlossary)
  );
};

export const upsertGlossariesWithLocale =
  (locale: Locale) => (events: GlossaryUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.glossary.id,
          doc: mapGlossaryAsDoc(event.properties.glossary)
        }),
        {
          timeout: '4m'
        }
      )
    );
