import React, { Component, useState } from 'react';
import Box from '@material-ui/core/Box';
import styled, { keyframes } from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';
import { AudioPlayer } from '~/audio/audio-player';
import { defaultRenderElement } from '~/text/rich-text-container';
import { WqNodeTypeBlock } from '@wordquest/lib-iso/domain/wordquest/dom';
import Children from './rich-text-children';

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const StyledMatchedGlossary = styled(Grid)`
  color: #113284;
  cursor: pointer;
  font-weight: normal;
  padding-left: 1px;
  padding-right: 1px;
  display: inline;
  span {
    border-bottom: 1px solid #113284;
  }
`;

const StyledGlossaryDetails = styled(Grid)`
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  background-color: #eaeaea;
  color: #113284;
  padding: 0.5em;
  margin: 0.5em;
  animation: ${(props) => (props.isExpanded ? fadeInDown : fadeInUp)} 0.5s
    linear;
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  font-size: 1.2em !important;
  position: relative;
  top: 9px;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  font-size: 1.2em !important;
  position: relative;
  top: 9px;
`;

// Not using hooks, use an injected state

// simple inject result html now. could restrict to markdown/contentful markup
const GlossaryDetails = ({
  isExpanded,
  detailsHtml,
  detailsRichText,
  audioUrl,
  audioGuide = '',
  renderElement,
  renderLeaf
}) => {
  const audioTag = (
    <Box display="flex">
      {audioUrl && <AudioPlayer src={audioUrl} />}
      {audioGuide}
    </Box>
  );

  return (
    <StyledGlossaryDetails isExpanded={isExpanded}>
      <div>{audioTag}</div>
      {(detailsRichText && (
        <Children
          node={detailsRichText}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      )) || <div dangerouslySetInnerHTML={{ __html: detailsHtml }} />}
    </StyledGlossaryDetails>
  );
};
const ExpandableGlossary = (props) => {
  const {
    glossary,
    children,
    handleKeyDown,
    onClick,
    isExpanded: defaultGlossaryIsExpanded,
    renderElement = defaultRenderElement,
    renderLeaf
  } = props;
  const [isExpanded, setIsExpanded] = useState(!!defaultGlossaryIsExpanded);

  const handleClickToExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
    props.onClick && onClick();
  };

  const detailsRichText = {
    type: WqNodeTypeBlock.Paragraph,
    children: [
      {
        type: WqNodeTypeBlock.Markdown,
        attributes: {},
        children: [
          {
            text: (glossary || {}).description || ''
          }
        ]
      }
    ]
  };

  return (
    <StyledMatchedGlossary>
      {glossary ? (
        <>
          <span onClick={handleClickToExpand} onKeyDown={handleKeyDown}>
            {children}
            {isExpanded ? (
              <StyledArrowDropUpIcon />
            ) : (
              <StyledArrowDropDownIcon />
            )}
          </span>
          <GlossaryDetails
            isExpanded={isExpanded}
            detailsRichText={detailsRichText}
            detailsHtml={glossary.detailsHtml}
            audioUrl={glossary.audioUrl}
            audioGuide={glossary.audioGuide}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </>
      ) : (
        children
      )}
    </StyledMatchedGlossary>
  );
};

export default ExpandableGlossary;
