import _ from 'lodash';
import React, { useRef, useEffect } from 'react';

import { Bubbles, prepHTML } from 'chat-bubble/component/Bubbles';

import styled from 'styled-components';

import ReactDOMServer from 'react-dom/server';

// simplify import

const StyledBubble = styled.div`
  background-color: red;
`;

const StyledSpeaker = styled.div`
  margin-left: 7%;
`;
const StyledDialog = styled.div`
  width: 90%;
  /* margin-left: 23%; */
  display: flex;
  flex-direction: row;
`;

const StyledBubblesContainer = styled.div`
  display: flex;
  height: 100%;
  /* justify-content: center; */

  /* setup container styles */
  .bubble-container {
    background: #dcdde0;
    height: 520px;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
  .bubble-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
    padding: 10px calc(17px + 10px) 30px 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
  }

  /* style user input field */
  .bubble-container .input-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    color: #2c2c2c;
  }
  .bubble-container .input-wrap textarea {
    width: calc(100% - 20px);
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    color: #2c2c2c;
    background: rgba(250, 250, 250, 0.95);
    font-size: 1em;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin: 10px;
    border-radius: 15px;
    border: none;
    padding: 10px 15px;
    outline: none;
    box-shadow: 0 0 0 1px #d0d0d0 inset;
    line-height: 1.25em;
  }
  .bubble.reply-freeform {
    margin: 0;
  }
  .bubble.reply.reply-freeform.say .bubble-content .bubble-button {
    margin-top: 1px;
    text-align: left;
  }
  .bubble.reply.say.bubble-hidden {
    margin: 0;
    transform: scale(0);
    height: 0;
  }

  /* style user response reply */
  .bubble.reply {
    background: transparent;
    box-shadow: none;
    float: right;
    position: relative;
    transform-origin: right top;
    margin: 8px 0 10px;
    padding: 0;
    max-width: 65%;
  }
  .bubble.reply.history {
    margin: 0 0 2px 0; /* remembered bubbles do not need to stand out via margin */
  }
  .bubble.reply.say {
    /*
    min-width: 350px;
   */
  }
  .bubble.reply .bubble-content {
    transition: all 200ms;
  }
  .bubble.reply .bubble-content .bubble-button {
    background: rgba(44, 44, 44, 0.67);
    color: #fff;
    padding: 8px 16px;
    border-radius: 15px 15px 5px 5px;
    margin-left: 2px;
    text-align: center;
    display: inline-block;
    float: right;
    cursor: pointer;
    transition: all 200ms;
    text-decoration: none;
    word-break: normal;
    box-sizing: content-box;
    /* animation-duration: 1s; */
    animation-name: 'animate-reply';
    animation-play-state: paused;
    animation-fill-mode: forwards;
    /* opacity: 0; */
    transform: translate3d(0px, 0px, 0px);
    animation-delay: -3s;
    -ms-animation-delay: -3;
    -webkit-animation-delay: -3s;
  }
  @keyframes animate-reply {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .bubble.reply.say .bubble-content .bubble-button {
    animation-play-state: running;
    margin-top: 3px;
    min-height: 24px;
    overflow: hidden;
  }

  .bubble.reply .bubble-content .bubble-button:first-child {
    border-radius: 15px 15px 15px 5px;
    margin-left: 2px;
  }
  .bubble.reply .bubble-content .bubble-button:last-child,
  .bubble.reply .bubble-content .bubble-button.bubble-pick {
    border-radius: 15px 15px 5px 15px;
  }
  .bubble.reply.bubble-picked .bubble-content .bubble-button {
    transform: scale(0) translate3d(0px, 0px, 0px);
    padding: 0;
  }
  .bubble.reply:not(.bubble-picked) .bubble-content .bubble-button:hover,
  .bubble.reply .bubble-content .bubble-button.bubble-pick {
    background: rgba(44, 44, 44, 1);
    transform: scale(1) translate3d(0px, 0px, 0px);
    padding: 8px 16px;
    height: auto;
  }

  /* interaction recall styles */
  .bubble.history .bubble-content .bubble-button,
  .bubble.history.reply:not(.bubble-picked)
    .bubble-content
    .bubble-button:hover,
  .bubble.history.reply .bubble-content .bubble-button.bubble-pick {
    background: rgba(44, 44, 44, 0.67);
    cursor: default;
  }

  /* input fields for bubbles */
  .bubble .bubble-content input {
    background: linear-gradient(193deg, #1faced, #5592dc 100%) !important;
    box-shadow: 0 0px 1px 0px #000, 0 -1px 0 0px rgba(255, 255, 255, 0.38) inset;
    text-shadow: 0 1px rgba(0, 0, 0, 0.35);
    border: 0;
    outline: 0;
  }
  .bubble .bubble-content input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
    text-shadow: none;
  }
  .bubble .bubble-content input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
    text-shadow: none;
  }
  .bubble .bubble-content input:read-only {
    background: linear-gradient(166deg, #48121d, #0d4058 100%) !important;
  }

  /* style bubbles */
  .bubble,
  .bubble-typing {
    color: #212121;
    background: rgba(255, 255, 255, 0.84);
    padding: 8px 16px;
    border-radius: 5px 15px 15px 15px;
    font-weight: 400;
    text-transform: none;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 0 2px 0;
    max-width: 65%;
    float: none;
    clear: both;
    line-height: 1.5em;
    word-break: break-word;
    transform-origin: left top;
    transition: all 200ms;
    box-sizing: content-box;
  }
  .bubble .bubble-content {
    transition: opacity 150ms;
  }
  .bubble:not(.say) .bubble-content {
    opacity: 0;
  }
  .bubble-typing.imagine,
  .bubble.imagine {
    transform: scale(0);
    transition: all 200ms, height 200ms 1s, padding 200ms 1s;
  }
  .bubble.imagine {
    margin: 0;
    height: 0;
    padding: 0;
  }

  /* style media that's inside bubbles */
  .bubble .bubble-content img {
    width: calc(100% + 32px);
    margin: -8px -16px;
    overflow: hidden;
    display: block;
    border-radius: 5px 15px 15px 15px;
  }

  /* interaction recall styles */
  .bubble.history,
  .bubble.history .bubble-content,
  .bubble.history .bubble-content .bubble-button,
  .bubble.history .bubble-content .bubble-button:hover {
    transition: all 0ms !important;
  }
  .bubble.history {
    opacity: 0.25;
  }

  /* style "loading" or "typing" stae */

  .bubble-typing {
    width: 38px;
    padding: 12px 16px;
    height: 8px;
  }
  .dot {
    background-color: rgb(255, 255, 255);
    float: left;
    height: 7px;
    margin-left: 4px;
    width: 7px;
    animation-name: bounce_dot;
    animation-duration: 2.24s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    border-radius: 5px;
  }
  .dot_1 {
    animation-delay: 0.45s;
  }
  .dot_2 {
    animation-delay: 1.05s;
  }
  .dot_3 {
    animation-delay: 1.35s;
  }
  @keyframes bounce_dot {
    0% {
    }
    50% {
      background-color: rgb(0, 0, 0);
    }
    100% {
    }
  }
`;

// TODO rewrite the implof chat-bubble esp localStorage part
// make it work with matrix-react-sdk, here mostly for quick integration

// non react

// this is a convenience script that builds all necessary HTML,
// imports all scripts and stylesheets; your container DIV will
// have a default `id="chat"`;
// you can specify a different ID with:
// `container: "my_chatbox_id"` option
// prepHTML({ relative_path: './node_modules/chat-bubble/' });

const ChatBubbles = ({ convo, left, onCourseQuestClicked = _.identity }) => {
  const chatWidgetRef = useRef(null);

  let chatWindow;

  // _.forEach(script, (meta, by) => {
  //   console.log(meta);
  //
  //   script[by].says = _.map(meta.says, (say) => {
  //     console.log(say);
  //     return say;
  //   });
  // });
  //
  //
  // const html = ReactDOMServer.renderToStaticMarkup(
  //   <StyledBubble>
  //     test
  //   </StyledBubble>
  // );

  useEffect(() => {
    const ele = _.get(chatWidgetRef, 'current');

    // workaround on component mounted twice
    // workaround

    if (
      ele &&
      (document.getElementsByClassName('bubble-wrap') || []).length === 0
    ) {
      chatWindow = new Bubbles(
        ele, // ...passing HTML container element...
        'chatWindow' // ...and name of the function as a parameter
      );
      global.chatWindow = chatWindow;

      // `.talk()` will get your bot to begin the conversation
      chatWindow.talk(convo);
    }
  }, [convo, left]);

  // initialize by constructing a named function...

  return (
    <StyledBubblesContainer>
      <StyledDialog onClick={() => onCourseQuestClicked()}>
        <StyledSpeaker>{left}</StyledSpeaker>
        <div ref={chatWidgetRef} />
      </StyledDialog>
    </StyledBubblesContainer>
  );
};

export default ChatBubbles;
