import _ from 'lodash';
import {
  observable,
  reaction,
  toJS,
  set,
  transaction,
  decorate,
  computed
} from 'mobx';
import { createTransformer } from 'mobx-utils';
import { toArray } from 'rxjs/operators';
import { createEntityAggregate } from '@wordquest/lib-iso/domain/wordquest/event/aggregate';
import { UserEvent } from '@wordquest/lib-iso/domain/wordquest/event/user-event';
import { Entity, WordAction } from '@wordquest/lib-iso/domain/wordquest/entity';
import firebase from '~/services/firebase';
import {
  loadEventsWithProfileEntityFilter,
  loadAndEnrichUserProfile
} from '@wordquest/lib-iso/app/user/user-repo-fs';
import rootLogger from '@wordquest/lib-iso/app/logger';
import { Locale } from '@wordquest/locales';
import {
  deriveAuthorizedRolesWithProfile,
  deriveAuthorizedRolesWithProfileClaims
} from '@wordquest/lib-iso/domain/wordquest/profile/profile-authorized-roles';
import { LocalStorageNamespace } from '@wordquest/ui-components/localstorage-namespace';
import { matchProfileSegments } from '@wordquest/lib-iso/domain/wordquest/segment/segment';
import RootStore from '~/stores/root';
import { AuthStateEvent } from '~/services/auth';
import {
  AuthStepState,
  AuthStepEvent,
  authStepStateMachine
} from '@wordquest/ui-components/auth/auth-step';

const logger = rootLogger.child({ module: 'UserStore' });

const DEFAULT_AUTHORIZED_ROLES = [];
// TODO more generic rather than assuming propKey

// only 1 event for . should be sorted
// single vs multiple
//
// export const createEntityAggregateByKey = (entity:Entity) => createTransformer((events) => {
//   const entityKey = _.toLower(entity);
//   return _.fromPairs(
//     _.filter(events, _.isObject).map(
//       event => [event.properties[entityKey].id,
//         extendObservable({}, {
//           // can extend with last event properties e.g. below
//           // firstActionAt: eventPrev.actionAt,
//           // totalCount:
//           lastActionAt: event.actionAt,
//           [entityKey]: event.properties[entityKey]
//         }, {
//           [entityKey]: observable.ref
//         })
//       ]
//     )
//   );

export const defaultProfile = {
  id: null,
  displayName: '',
  claims: {},
  // TODO potentially shuffle
  role: 'software-engineer',
  difficultyTarget: 50,
  photoURL: null,
  metadata: {
    emailSubmitted: ''
  },
  proficiencyByLocale: {
    [Locale.EN]: 50
  },
  activeLocales: [Locale.EN],
  goalsByLocale: {
    [Locale.EN]: ['professions', 'exam']
  },
  customerIOSegments: []
};

// firebase.auth().currentUser.metadata lastSignInTime

class UserStore {
  constructor(rootStore: RootStore) {
    /* eslint-disable-line */
    const snackbarRead = JSON.parse(
      localStorage.getItem('user.notifications.read') || null
    );
    this.user.config.snackbarRead.replace(snackbarRead || []);
    this.user.config.snackbarRead.observe((change) => {
      if (change.added) {
        this.cacheUserConfig(
          'notifications.read',
          this.user.config.snackbarRead || []
        );
      }
    });

    // mostly for email sign
    const email = localStorage.getItem(LocalStorageNamespace.UserEmail);
    logger.debug('email at cookies', email, this.user.profile);
    if (email && !_.get(this, 'user.profile.email')) {
      logger.debug('update user email');
      _.merge(this.user.profile, {
        email
      });
    }

    this.questUserEventAggregateByKey = observable(new Map());
    this.wordUserEventAggregateByKey = observable(new Map());

    // seems bug in mobx not really react to deep array lenght change

    const entityLogReactions = _.map(
      [Entity.Article, Entity.Quest, Entity.Word],
      (entity) => {
        const log = this.getLogByEntity(entity);

        return reaction(
          () => (log || []).length,
          () => {
            this[`${_.toLower(entity)}UserEventAggregateByKey`].merge(
              createEntityAggregate(entity)(log.map((e) => toJS(e)))
            );
          }
        );
      }
    );

    // init
    reaction(
      () => this.user.idTokenResult.token,
      async (idToken) => {
        if (!idToken) {
          return;
        }
        const fsProfile = await loadAndEnrichUserProfile(this.user.profile);

        // assume we don't save to firebase, profile at FS
        _.merge(this.user.profile, fsProfile);
        logger.debug(
          'loadAndEnrichUserProfile',
          fsProfile,
          this.user,
          this.user.profile
        );

        this.pullEventsByEntity(Entity.Quest);
        this.pullEventsByEntity(Entity.Word);
        // only after events actually loaded
        this.user.isProfileReady = true;
      },
      { fireImmediately: true }
    );

    reaction(
      () =>
        _.keys(this.wordUserEventAggregateByKey.get(WordAction.ToggledInDeck)),
      (wordIds) => {
        rootStore.wordStore.lazyLoadWordByIds(wordIds);
      }
    );

    reaction(
      () => this.user.profile.email,
      (updatedEmail) => {
        if (updatedEmail) {
          logger.debug('user email updated', updatedEmail);
          localStorage.setItem(LocalStorageNamespace.UserEmail, updatedEmail);
        }
      },
      { fireImmediately: true }
    );
  }
  // TODO Ensure when loaded from firestore cache/subscribe,
  // won't overlap

  // possible to be ref to WordStore, not firestore ref
  // while we use Map so that ref not observable
  user = observable({
    profile: defaultProfile,
    idTokenResult: {
      token: null
    },
    isProfileReady: false,
    questLog: [],
    wordLog: [],
    config: {
      snackbarRead: observable([])
    },
    eventLoaded: observable(new Map())
  });

  getLogByEntity = (entity) => this.user[`${entity.toLowerCase()}Log`];

  pushUserEventByEntity = (userEvent) => {
    const log = this.getLogByEntity(userEvent.entity);
    if (!userEvent || !this.user.profile || !log) {
      logger.debug('skip pushUserEventByEntity', userEvent, this.user, log);

      return;
    }
    // if (_.includes(['Word', 'Article'], userEvent.entity)) {
    //   // TODO filter event properties, later, optimistic now
    log.push(userEvent);
  };

  pullEventsByEntity = (entity: Entity) => {
    if (!_.get(this.user, 'idTokenResult.token')) {
      return;
    }
    const key = entity;
    if (this.user.eventLoaded.get(key)) {
      return;
    }

    // Note that transaction runs completely synchronously.
    transaction(async () => {
      const events = await loadEventsWithProfileEntityFilter(
        this.user.profile,
        entity
      )
        .pipe(toArray())
        .toPromise();
      // nested is necessary
      logger.debug('push events', entity, events.length);
      transaction(() => {
        events.forEach((event) => this.pushUserEventByEntity(event));
      });
      set(this.user.eventLoaded, entity, true);
    });
  };

  cacheUserConfig = (k, v) => {
    localStorage.setItem(`user.${k}`, JSON.stringify(v));
  };

  resetProfile = () => {
    _.merge(
      this.user.profile,
      _.pick(this.user.profile, _.keys(defaultProfile)),
      defaultProfile
    );
  };

  onLogout = async () => {
    await firebase.auth().signOut();
    if (window.analytics) {
      window.analytics.reset();
    }
  };

  wordUserEventAggregateByKey: Record<string, Record<string, UserEvent>>;

  get profileSegments() {
    return matchProfileSegments(this.user.profile);
  }

  get isLoggedIn() {
    return !!this.user.profile.id;
  }

  get isFirstSignIn() {
    return this.user.profile.metadata.lastSignInTime === 0;
  }

  get authorizedRoles() {
    // force update when customerIOSeagments updated
    const length = this.user.profile.customerIOSegments.length;
    const derivedAuthoriedRoles = _.uniq(
      [].concat(
        length > 0 ? deriveAuthorizedRolesWithProfile(this.user.profile) : [],
        deriveAuthorizedRolesWithProfileClaims(this.user.profile)
      )
    );

    return _.isEmpty(derivedAuthoriedRoles)
      ? DEFAULT_AUTHORIZED_ROLES
      : derivedAuthoriedRoles;
  }

  checkIsInDeckWithWordId = (wordId) => {
    const wordAgg = this.wordUserEventAggregateByKey;

    return (
      !_.isEmpty(wordAgg) &&
      !!(
        wordAgg.has('ToggledInDeck') &&
        _.get(wordAgg.get('ToggledInDeck'), `${wordId}.isInDeck`)
      )
    ); //eslint-disable-line
  };
}

decorate(UserStore, {
  profileSegments: computed({ keepAlive: true })
});

export default UserStore;
