// @ts-ignore
import _ from 'lodash';

export const IS_NODE =
  global.window === undefined ||
  global.navigator.userAgent.indexOf('jsdom') > -1;

// @ts-ignore
export const IS_HTTPS =
  global.window && global.window.location.href.startsWith('https');

export const DOMAIN = 'perapera.ai';

export const IS_UI_DEV =
  global.window && global.window.location.href.indexOf(DOMAIN) === -1;

export const IS_DEV = process.env.NODE_ENV !== 'production';

export const IS_CF = !!process.env.FUNCTION_TARGET && process.env.CI !== 'true';

export const IS_FORCE_HTTPS_ASSET =
  IS_HTTPS || process.env.IS_FORCE_HTTPS_ASSET;

export const SITE_ROOT_UI_MAIN = IS_UI_DEV ? '' : 'https://app.perapera.ai';

export const SITE_ROOT_UI_SITE_PRD = 'https://www.perapera.ai';

export const SITE_ROOT_UI_SITE = IS_UI_DEV ? '' : SITE_ROOT_UI_SITE_PRD;

export const CF_KV_NAMESPACE_ID = process.env.CF_KV_NAMESPACE_ID;

export const AWS_REGION = 'us-west-2';

export const AWS_ENDPOINT = process.env.AWS_ENDPOINT || 'http://localhost:4566';
const agents = [
  'GoogleStackdriverMonitoring',
  'googlebot',
  'bingbot',
  'yandex',
  'baiduspider',
  'facebookexternalhit',
  'twitterbot',
  'rogerbot',
  'linkedinbot',
  'embedly',
  'bufferbot',
  'quora link preview',
  'showyoubot',
  'outbrain',
  'pinterest/0.',
  'developers.google.com/+/web/snippet',
  'www.google.com/webmasters/tools/richsnippets',
  'slackbot',
  'vkShare',
  'W3C_Validator',
  'redditbot',
  'Applebot',
  'WhatsApp',
  'flipboard',
  'tumblr',
  'bitlybot',
  'SkypeUriPreview',
  'nuzzel',
  'Discordbot',
  'Google Page Speed',
  'Qwantify'
].map(_.toLower);

// from request.headers.get('User-Agent') or navigator.userAgent
export const checkIsBotAgent = (userAgentString) =>
  !!_.find(agents, (agent) =>
    _.toLower(userAgentString).includes(_.toLower(agent))
  );

export const IS_AGENT = checkIsBotAgent(
  global.navigator ? global.navigator.userAgent : ''
);

// localStorage not available at site deploy, while we should not override node modules e.g.  firebase
if (
  !global.localStorage &&
  !IS_HTTPS &&
  (!global.firebase || IS_UI_DEV || process.env.CI)
) {
  try {
    global.localStorage = {
      _data: {},
      setItem(id, val) {
        return (this._data[id] = String(val));
      },

      getItem(id) {
        // eslint-disable-next-line
        return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
      },
      removeItem(id) {
        return delete this._data[id];
      },
      clear() {
        return (this._data = {});
      }
    };
  } catch (err) {
    console.log('mock localStorage error');
  }
}
