import _ from 'lodash';
import React, { useState } from 'react';
// looks unstable, https://github.com/justinmc/react-audio-player/issues/61
// import ReactAudioPlayer from 'react-audio-player';

import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import styled from 'styled-components';

const StyledPlayerControls = styled.div`
  cursor: pointer;
`;
// quick workaround
const CDN_PATH = 'https://cors.perapera.workers.dev/?';

const AudioTag = React.forwardRef((props, ref) => {
  const {
    src = '',
    controls = false,
    autoPlay = false,
    isPlaying = false,
    onClick,
    onPlay = _.noop,
    onPause = _.noop,
    onEnded = _.noop
  } = props;
  const httpsSrc = src.startsWith('https:') ? src : CDN_PATH + src;

  return (
    <div>
      <StyledPlayerControls onClick={onClick}>
        {isPlaying ? <VolumeUpIcon /> : <VolumeDownIcon />}
      </StyledPlayerControls>
      {React.createElement('audio', {
        ref,
        src: httpsSrc,
        controls,
        autoPlay,
        onPlay: () => {
          onPlay();
        },
        onPause: () => {
          onPause();
        },
        onEnded: () => {
          onEnded();
        }
      })}
    </div>
  );
});

export const AudioPlayer = (props) => {
  const [playback, setPlayback] = useState({
    isPlaying: false
  });
  const ref = React.createRef();

  return (
    <AudioTag
      onClick={() => {
        setPlayback({
          isPlaying: true
        });
        if (ref.current) {
          ref.current.play();
          ref.current.onended = () => {
            setPlayback({
              isPlaying: false
            });
          };
        }
      }}
      ref={ref}
      isPlaying={playback.isPlaying}
      {...props}
    />
  );
};
