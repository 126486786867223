import _ from 'lodash';
import { observable, reaction, autorun } from 'mobx';
// import logger from '@wordquest/lib-iso/app/logger';
import { from } from 'rxjs';
// import { Locale } from '@wordquest/locales';
import { RouterStore } from 'mobx-react-router';
import {
  queryWithText,
  queryAnalyzedWithText
} from '@wordquest/lib-iso/app/search/search';
import RootStore from '~/stores/root';
import { fromStream } from 'mobx-utils';

export default class PageSearchStore {
  constructor(private rootStore: RootStore, public routerStore: RouterStore) {
    this.currentSearch = observable({
      queryText: ''
    });
    this.relatedArticles = observable([]);
    this.relatedVideos = observable([]);
    this.relatedWords = observable([] as string[]);

    this.searchContext = {
      isHelpdeskOnly: false
    };
    this.analyzedResult = observable({
      text: ''
    });

    const {
      uiStateStore: { uiConfig }
    } = rootStore;
    reaction(
      () => this.currentSearch.queryText,
      async (queryText) => {
        let analyzedResult = {};
        if (!queryText) {
          return;
        }
        const context = {};
        if (this.searchContext.isHelpdeskOnly) {
          context.isHelpdeskOnly = true;
        }
        let _doQueryWithText = (queryText) => queryWithText(queryText, context);
        let _doQueryAnalyzedWithText = (queryText) =>
          queryAnalyzedWithText(queryText);
        if (uiConfig.mock.search) {
          _doQueryWithText = () =>
            from(import('~/debug-mock').then((m) => m.queryWithTextMock()));
          _doQueryAnalyzedWithText = () =>
            from(
              import('~/debug-mock').then((m) => m.queryAnalyzedWithTextMock())
            );
        }

        const searchResult = fromStream(_doQueryWithText(queryText));
        if (!this.searchContext.isHelpdeskOnly) {
          analyzedResult = fromStream(_doQueryAnalyzedWithText(queryText));
        }
        autorun(() => {
          console.log('result updated', searchResult);
          const currentSearchResult = searchResult.current;
          if (!_.isEmpty(currentSearchResult)) {
            const {
              articles = [],
              words = [],
              videos = []
            } = currentSearchResult;
            this.relatedWords.replace(words);
            this.relatedArticles.replace(articles);
            this.relatedVideos.replace(videos);
            this.analyzedResult.text = _.get(analyzedResult, 'current.text');
          }
        });
      },
      {
        delay: 1000,
        fireImmediately: true
      }
    );
  }
}
