/**
 * Most storage unification sdk are not reliable due to difference in nature
 * e.g. pkgcloud is not maintained
 * we only handle the url part, up to client to
 * - handle the bucket name
 * - use lib-node/adapters for actual read
 */
export enum StorageProvider {
  S3 = 's3',
  GCS = 'gcs'
}

export const createGcsPublicUrl = (bucketName, filePath) =>
  `https://storage.googleapis.com/${bucketName}/${filePath}`;

export const createAwsPublicUrl = (bucketName, filePath) => {
  const awsRegion = process.env.AWS_REGION || 'ap-southeast-1';

  return `https://${bucketName}.s3.${awsRegion}.amazonaws.com/${filePath}`;
};

/**
 * Assume authentication already set (for each subfolder in filePath)
 */
export const createStoragePublicUrl = ({
  bucketName,
  filePath,
  provider = StorageProvider.S3
}) => {
  if (provider === StorageProvider.GCS) {
    return createGcsPublicUrl(bucketName, filePath);
  }

  return createAwsPublicUrl(bucketName, filePath);
};

export const createStoragePath = ({
  bucketName,
  filePath,
  provider = StorageProvider.S3
}) => {
  if (provider === StorageProvider.GCS) {
    return `gs://${bucketName}/${filePath}`;
  }

  return `s3://${bucketName}/${filePath}`;
};

// cant use node path. basename here
export const FILE_VERSION_LIMITER = '_';

export const attachVersion = (filePath, version) => {
  const extRegex = filePath.match(/(.*)\.([0-9a-z]+)$/i);
  if (extRegex && extRegex[2]) {
    return `${[extRegex[1], version].join(FILE_VERSION_LIMITER)}.${
      extRegex[2]
    }`;
  }

  return [filePath, version].join(FILE_VERSION_LIMITER);
};

// we could simplify things with unix convention but some system might not support it well
export const createStorageParamsWithVersion = ({
  bucketName,
  filePath,
  version = 'latest',
  provider = StorageProvider.S3
}) => ({
  bucketName,
  filePath: attachVersion(filePath, version),
  provider
});
