import _ from 'lodash';
import { observable, reaction, autorun, toJS } from 'mobx';
// import logger from '@wordquest/lib-iso/app/logger';
import { from } from 'rxjs';
// import { Locale } from '@wordquest/locales';
import { RouterStore } from 'mobx-react-router';
import {
  queryWithText,
  queryAnalyzedWithText
} from '@wordquest/lib-iso/app/search/search';
import RootStore from '~/stores/root';
import { fromStream } from 'mobx-utils';

import { Locale, MessagesModule } from '@wordquest/locales';
import { queryArticles } from '@wordquest/lib-iso/app/article/article-repo';
import {
  PREDEFINED_CATEGORIES_TEACHER,
  PREDEFINED_CATEGORIES_STUDENT
} from '~/stores/domain/support-teacher-categories';

export const loadSupportArticlesByCategory = async ({ type, locale }) => {
  const tagKey = `guide-${type}`;
  const tagKeyOthers = `guide-${type}-others`;
  const categories =
    type === 'teacher'
      ? PREDEFINED_CATEGORIES_TEACHER
      : PREDEFINED_CATEGORIES_STUDENT;
  // userLocale

  const articles = await queryArticles(Locale.EN, {
    terms: {
      locale: locale || Locale.EN
    },
    filter: {
      tagKeys: [tagKey],
      locale: locale || Locale.EN
      // isHidden: false
    },
    isIgnorePublishedAt: true,
    // isTermsAMust: true,
    paging: {
      size: 40
    }
  });

  // similar to posts.js
  // better as predefined category & orders

  return _.fromPairs(
    _.filter(
      _.map(categories, (category) => [
        category,
        _.filter(articles, (article) => {
          if (
            category === tagKeyOthers &&
            _.isEmpty(_.intersection(categories, article.tagKeys))
          ) {
            return article;
          }
          // guard although query should skip this
          if (!_.includes(article.tagKeys, tagKey)) {
            return false;
          }
          if (_.includes(article.tagKeys, category)) {
            return article;
          }
        })
      ]),
      ([category, articles]) => !_.isEmpty(articles)
    )
  );
};

export default class PageSupportStore {
  constructor(private rootStore: RootStore, public routerStore: RouterStore) {
    this.currentSearch = observable({
      queryText: ''
    });

    const { uiStateStore } = rootStore;
    this.articlesByCategory = observable.map({});

    this.loadArticlesByCategoryWithTypeLocale = async () => {
      const { routeMatch, app } = uiStateStore;
      const supportPathPattern = /^\/support/;
      const path = _.get(routeMatch, 'path');
      if (!supportPathPattern.test(path)) {
        return;
      }
      const type = _.get(routeMatch, 'params.type');

      const articlesByCategory = await loadSupportArticlesByCategory({
        type,
        locale: app.userLocale
      });
      this.articlesByCategory.replace(articlesByCategory);
    };

    reaction(
      () => ({
        userLocale: uiStateStore.app.userLocale,
        routeMatch: toJS(uiStateStore.routeMatch.params)
      }),
      async () => {
        this.loadArticlesByCategoryWithTypeLocale();
      },
      {
        delay: 1000,
        fireImmediately: true
      }
    );
  }
}
