import _ from 'lodash';

export enum SegmentEcommerce {
  OrderCompleted = 'Order Completed',
  ProductViewed = 'Product Viewed'
}

export enum SegmentB2BSaaS {
  TrialStarted = 'Trial Started',
  TrialEnded = 'Trial Ended'
}

export type SegmentOrderCompleted = {
  event: SegmentEcommerce.OrderCompleted;
};

// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/#object-properties
export enum FacebookStandard {
  Purchase = 'purchase'
}

export const asAppEventName = (eventName) => {
  const segments = eventName.match(/([^\s]+)\s*(.*)/).filter(Boolean);
  let appEventName = eventName;
  if (segments && segments.length > 2) {
    const entity = segments[1];
    appEventName = [entity, _.upperFirst(_.camelCase(segments[2]))].join('-');
  }

  return appEventName;
};

// TODO we might want to just remove all User-
export const APP_EVENT_BY_STANDARD_EVENT = {
  ..._.fromPairs(
    _.map(_.values(SegmentEcommerce), (event) => [event, asAppEventName(event)])
  ),
  ..._.fromPairs(
    _.map(
      [
        SegmentB2BSaaS.TrialStarted,
        SegmentB2BSaaS.TrialEnded,
        'Subscription-Started',
        'Subscription-Ended'
      ],
      (event) => [
        event,
        asAppEventName(`User-${_.upperFirst(_.camelCase(event))}`)
      ]
    )
  )
};

export const STANDARD_EVENT_BY_APP_EVENT = _.invert(
  APP_EVENT_BY_STANDARD_EVENT
);
