import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Children from './rich-text-children';
import { RenderElementProps, RenderLeafProps } from './rich-text';

export const DefaultElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  // const Tag = editor.isInline(element) ? 'span' : 'div';
  const Tag = 'div';

  return (
    <Tag {...attributes} style={{ position: 'relative' }}>
      {children}
    </Tag>
  );
};

// Text node should not be considered as children
interface Element {
  children: [];
}
const Element = (props: {
  element: Element;
  renderElement?: (props: RenderElementProps) => JSX.Element;
  renderLeaf?: (props: RenderLeafProps) => JSX.Element;
}) => {
  const {
    // decorate,
    // decorations,
    element,
    renderElement = (p: RenderElementProps) => <DefaultElement {...p} />,
    renderLeaf
    // selection
  } = props;

  // circular dependency
  if (!element.children) {
    console.log('element without children', element);
  }
  const children = (
    <Children
      node={element}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
    />
  );

  return renderElement({ attributes: element.attributes, children, element });
};

export default Element;
