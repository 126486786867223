import _ from 'lodash';

export const asLocationCity = (cfEntity) => {
  if (!cfEntity) {
    return null;
  }
  const { sys, fields } = cfEntity;
  const { city, countryCode } = fields || {};

  return {
    city,
    // use lower for better compatability with i18n messages while source should be always upper
    countryCode: _.toLower(countryCode)
  };
};
