import React from 'react';

const ErrorImage = () => (
  <svg
    width="490"
    height="293"
    viewBox="0 0 490 293"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M377.24 15.0863V175.096C377.24 180.737 372.68 185.297 367.04 185.297H162.38C156.74 185.297 152.179 180.737 152.179 175.096V15.0863C152.179 9.44587 156.74 4.88521 162.38 4.88521H367.024C372.664 4.88521 377.24 9.44587 377.24 15.0863Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M367.037 185.456H162.378C156.657 185.456 152.016 180.815 152.016 175.094V15.084C152.016 9.36307 156.657 4.72183 162.378 4.72183H367.021C372.742 4.72183 377.383 9.36307 377.383 15.084V175.094C377.399 180.799 372.742 185.456 367.037 185.456ZM162.379 5.04546C156.835 5.04546 152.339 9.54166 152.339 15.0854V175.095C152.339 180.639 156.835 185.135 162.379 185.135H367.023C372.566 185.135 377.062 180.639 377.062 175.095V15.0854C377.062 9.54166 372.566 5.04546 367.023 5.04546H162.379Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M372.519 10.3648V170.375C372.519 176.015 367.958 180.576 362.318 180.576H157.675C152.035 180.576 147.474 176.015 147.474 170.375V10.3648C147.474 4.72437 152.035 0.163712 157.675 0.163712H362.318C367.958 0.163712 372.519 4.72437 372.519 10.3648Z"
      fill="#FFE1E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M362.316 180.734H157.672C151.952 180.734 147.31 176.093 147.31 170.372V10.3622C147.31 4.64124 151.952 0 157.672 0H362.316C368.037 0 372.678 4.64124 372.678 10.3622V170.372C372.678 176.077 368.037 180.734 362.316 180.734ZM157.673 0.323639C152.13 0.323639 147.634 4.81984 147.634 10.3635V170.373C147.634 175.917 152.13 180.413 157.673 180.413H362.317C367.861 180.413 372.357 175.917 372.357 170.373V10.3635C372.357 4.81984 367.861 0.323639 362.317 0.323639H157.673Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M372.519 10.3648V18.213H147.474V10.3648C147.474 4.72437 152.035 0.163712 157.675 0.163712H362.318C367.958 0.163712 372.519 4.72437 372.519 10.3648Z"
      fill="#2252B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M372.678 18.3716H147.31V10.3622C147.31 4.64124 151.952 0 157.672 0H362.316C368.037 0 372.678 4.64124 372.678 10.3622V18.3716ZM147.634 18.0506H372.357V10.3635C372.357 4.81984 367.861 0.323639 362.317 0.323639H157.673C152.13 0.323639 147.634 4.81984 147.634 10.3635V18.0506Z"
      fill="black"
    />
    <rect
      x="179.027"
      y="35.8429"
      width="161.923"
      height="104.46"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M341.125 140.446H178.864V35.6795H341.125V140.446ZM179.187 140.125H340.804V36.0032H179.187V140.125Z"
      fill="black"
    />
    <ellipse
      cx="259.988"
      cy="75.4865"
      rx="25.8967"
      ry="25.8975"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.986 101.543C245.628 101.543 233.928 89.8596 233.928 75.4846C233.928 61.1097 245.611 49.426 259.986 49.426C274.36 49.426 286.044 61.1097 286.044 75.4846C286.044 89.8596 274.36 101.543 259.986 101.543ZM259.987 49.7496C245.806 49.7496 234.252 61.2882 234.252 75.4859C234.252 89.6675 245.79 101.222 259.987 101.222C274.169 101.222 285.723 89.6836 285.723 75.4859C285.723 61.3044 274.185 49.7496 259.987 49.7496Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M276.393 64.5606L270.93 59.0974L259.988 70.0237L249.062 59.0974L243.599 64.5606L254.525 75.4868L243.599 86.4131L249.062 91.8762L259.988 80.9499L270.93 91.8762L276.393 86.4131L265.451 75.4868L276.393 64.5606Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M270.928 92.0996L260.002 81.1733L249.076 92.0996L243.388 86.4109L254.314 75.4846L243.388 64.5584L249.076 58.8696L260.002 69.7959L270.928 58.8696L276.617 64.5584L265.691 75.4846L276.617 86.4109L270.928 92.0996ZM259.988 80.724L270.914 91.6502L276.151 86.4127L265.225 75.4865L276.151 64.5602L270.914 59.3227L259.988 70.2489L249.062 59.3227L243.824 64.5602L254.75 75.4865L243.824 86.4127L249.062 91.6502L259.988 80.724Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.485 122.35H236.121V107.346H244.485V108.893H237.862V113.728H244.082V115.259H237.862V120.77H244.485V122.35Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.645 122.509H235.959V107.184H244.645V109.053H238.021V113.565H244.242V115.419H238.021V120.608H244.645V122.509ZM236.282 122.188H244.324V120.948H237.701V115.114H243.921V113.905H237.701V108.748H244.324V107.523H236.282V122.188Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M252.688 110.892C253.187 110.892 253.638 110.94 254.025 111.021L253.783 112.6C253.316 112.503 252.913 112.439 252.559 112.439C251.656 112.439 250.867 112.81 250.222 113.551C249.577 114.292 249.255 115.211 249.255 116.307V122.334H247.547V111.101H248.949L249.142 113.18H249.223C249.642 112.455 250.141 111.891 250.738 111.488C251.334 111.101 251.978 110.892 252.688 110.892Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.415 122.509H247.384V110.939H249.092L249.27 112.792C249.656 112.179 250.124 111.696 250.639 111.341C251.252 110.922 251.929 110.713 252.67 110.713C253.186 110.713 253.637 110.761 254.04 110.842L254.185 110.874L253.895 112.776L253.733 112.743C253.282 112.647 252.879 112.598 252.541 112.598C251.671 112.598 250.946 112.937 250.333 113.646C249.721 114.355 249.399 115.241 249.399 116.305V122.509H249.415ZM247.708 122.189H249.094V116.323C249.094 115.194 249.432 114.228 250.109 113.454C250.786 112.68 251.608 112.294 252.558 112.294C252.881 112.294 253.251 112.342 253.654 112.423L253.848 111.166C253.493 111.101 253.106 111.069 252.687 111.069C252.027 111.069 251.398 111.262 250.834 111.633C250.27 112.02 249.787 112.568 249.384 113.277L249.335 113.357H249.013L248.82 111.278H247.724V122.189H247.708Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M261.261 110.892C261.76 110.892 262.212 110.94 262.598 111.021L262.357 112.6C261.889 112.503 261.486 112.439 261.132 112.439C260.229 112.439 259.44 112.81 258.795 113.551C258.151 114.292 257.828 115.211 257.828 116.307V122.334H256.12V111.101H257.522L257.715 113.18H257.796C258.215 112.455 258.715 111.891 259.311 111.488C259.907 111.101 260.552 110.892 261.261 110.892Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.988 122.509H255.957V110.939H257.666L257.843 112.792C258.23 112.179 258.697 111.696 259.213 111.341C259.825 110.922 260.502 110.713 261.243 110.713C261.759 110.713 262.21 110.761 262.613 110.842L262.758 110.874L262.468 112.776L262.307 112.743C261.855 112.647 261.453 112.598 261.114 112.598C260.244 112.598 259.519 112.937 258.906 113.646C258.294 114.355 257.972 115.241 257.972 116.305V122.509H257.988ZM256.297 122.189H257.683V116.323C257.683 115.194 258.021 114.228 258.698 113.454C259.375 112.68 260.197 112.294 261.148 112.294C261.47 112.294 261.841 112.342 262.243 112.423L262.437 111.166C262.082 111.101 261.696 111.069 261.277 111.069C260.616 111.069 259.987 111.262 259.423 111.633C258.859 112.02 258.376 112.568 257.973 113.277L257.925 113.357H257.602L257.409 111.278H256.313V122.189H256.297Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.411 116.709C274.411 118.546 273.943 119.981 273.025 120.996C272.106 122.027 270.833 122.543 269.206 122.543C268.206 122.543 267.304 122.301 266.531 121.834C265.757 121.367 265.145 120.69 264.726 119.803C264.307 118.917 264.097 117.886 264.097 116.709C264.097 114.872 264.548 113.454 265.467 112.423C266.385 111.407 267.659 110.892 269.286 110.892C270.865 110.892 272.106 111.407 273.041 112.455C273.943 113.518 274.411 114.92 274.411 116.709ZM265.836 116.708C265.836 118.142 266.126 119.238 266.69 119.995C267.27 120.753 268.108 121.123 269.22 121.123C270.332 121.123 271.186 120.753 271.766 119.995C272.346 119.254 272.636 118.158 272.636 116.708C272.636 115.274 272.346 114.194 271.766 113.453C271.186 112.711 270.332 112.341 269.204 112.341C268.092 112.341 267.254 112.711 266.674 113.436C266.126 114.178 265.836 115.257 265.836 116.708Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M269.188 122.703C268.156 122.703 267.222 122.461 266.432 121.978C265.626 121.494 264.998 120.785 264.563 119.883C264.128 118.98 263.918 117.916 263.918 116.708C263.918 114.838 264.402 113.372 265.336 112.324C266.287 111.277 267.609 110.745 269.268 110.745C270.88 110.745 272.185 111.293 273.136 112.373C274.087 113.453 274.57 114.903 274.57 116.724C274.57 118.593 274.087 120.076 273.152 121.123C272.185 122.171 270.864 122.703 269.188 122.703ZM269.27 111.053C267.69 111.053 266.45 111.552 265.579 112.535C264.693 113.518 264.242 114.92 264.242 116.709C264.242 117.869 264.451 118.885 264.854 119.739C265.257 120.593 265.853 121.254 266.595 121.705C267.352 122.156 268.222 122.382 269.189 122.382C270.768 122.382 272.009 121.882 272.896 120.899C273.782 119.9 274.233 118.498 274.233 116.709C274.233 114.969 273.782 113.583 272.879 112.567C272.009 111.568 270.784 111.053 269.27 111.053ZM269.236 121.285C268.076 121.285 267.173 120.882 266.577 120.092C265.981 119.319 265.675 118.174 265.675 116.708C265.675 115.225 265.965 114.097 266.561 113.34C267.157 112.566 268.043 112.179 269.204 112.179C270.38 112.179 271.283 112.582 271.895 113.356C272.491 114.129 272.797 115.257 272.797 116.708C272.797 118.174 272.491 119.319 271.895 120.092C271.299 120.882 270.396 121.285 269.236 121.285ZM269.22 112.502C268.14 112.502 267.367 112.84 266.819 113.533C266.271 114.226 265.997 115.306 265.997 116.708C265.997 118.094 266.271 119.173 266.819 119.899C267.367 120.608 268.156 120.962 269.22 120.962C270.3 120.962 271.073 120.608 271.637 119.899C272.185 119.19 272.475 118.11 272.475 116.708C272.475 115.322 272.201 114.258 271.637 113.549C271.105 112.84 270.3 112.502 269.22 112.502Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282.516 110.892C283.015 110.892 283.467 110.94 283.853 111.021L283.612 112.6C283.144 112.503 282.741 112.439 282.387 112.439C281.484 112.439 280.695 112.81 280.05 113.551C279.406 114.292 279.083 115.211 279.083 116.307V122.334H277.375V111.101H278.777L278.971 113.18H279.051C279.47 112.455 279.97 111.891 280.566 111.488C281.178 111.101 281.823 110.892 282.516 110.892Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M279.259 122.509H277.229V110.939H278.937L279.114 112.792C279.501 112.179 279.968 111.696 280.484 111.341C281.096 110.922 281.773 110.713 282.515 110.713C283.03 110.713 283.481 110.761 283.884 110.842L284.029 110.874L283.739 112.776L283.578 112.743C283.127 112.647 282.724 112.598 282.386 112.598C281.515 112.598 280.79 112.937 280.178 113.646C279.566 114.355 279.243 115.241 279.243 116.305V122.509H279.259ZM277.553 122.189H278.938V116.323C278.938 115.194 279.277 114.228 279.954 113.454C280.63 112.68 281.452 112.294 282.403 112.294C282.725 112.294 283.096 112.342 283.499 112.423L283.692 111.166C283.338 111.101 282.951 111.069 282.532 111.069C281.871 111.069 281.243 111.262 280.679 111.633C280.115 112.02 279.631 112.568 279.228 113.277L279.18 113.357H278.858L278.664 111.278H277.569V122.189H277.553Z"
      fill="black"
    />
    <ellipse
      cx="159.268"
      cy="9.91098"
      rx="4.02873"
      ry="4.02885"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.268 14.101C156.963 14.101 155.078 12.2155 155.078 9.91098C155.078 7.60648 156.963 5.72098 159.268 5.72098C161.572 5.72098 163.458 7.60648 163.458 9.91098C163.458 12.2155 161.588 14.101 159.268 14.101ZM159.269 6.0446C157.142 6.0446 155.402 7.78506 155.402 9.91229C155.402 12.0395 157.142 13.78 159.269 13.78C161.396 13.78 163.137 12.0395 163.137 9.91229C163.137 7.78506 161.396 6.0446 159.269 6.0446Z"
      fill="black"
    />
    <ellipse
      cx="171.451"
      cy="9.91098"
      rx="4.02873"
      ry="4.02885"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.451 14.101C169.146 14.101 167.261 12.2155 167.261 9.91098C167.261 7.60648 169.146 5.72098 171.451 5.72098C173.755 5.72098 175.64 7.60648 175.64 9.91098C175.64 12.2155 173.771 14.101 171.451 14.101ZM171.452 6.0446C169.325 6.0446 167.584 7.78506 167.584 9.91229C167.584 12.0395 169.325 13.78 171.452 13.78C173.579 13.78 175.319 12.0395 175.319 9.91229C175.319 7.78506 173.595 6.0446 171.452 6.0446Z"
      fill="black"
    />
    <ellipse cx="183.633" cy="9.91098" rx="4.02873" ry="4.02885" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M183.633 14.101C181.329 14.101 179.444 12.2155 179.444 9.91098C179.444 7.60648 181.329 5.72098 183.633 5.72098C185.938 5.72098 187.823 7.60648 187.823 9.91098C187.823 12.2155 185.954 14.101 183.633 14.101ZM183.635 6.0446C181.508 6.0446 179.767 7.78506 179.767 9.91229C179.767 12.0395 181.508 13.78 183.635 13.78C185.762 13.78 187.502 12.0395 187.502 9.91229C187.502 7.78506 185.778 6.0446 183.635 6.0446Z"
      fill="black"
    />
    <rect
      x="79.3112"
      y="289.442"
      width="131.597"
      height="3.14211"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.069 292.744H79.1498V289.282H211.069V292.744ZM79.4736 292.426H210.748V289.603H79.4736V292.426Z"
      fill="black"
    />
    <rect y="289.713" width="490" height="0.524064" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.1181 60.5834C86.1181 60.3093 86.0697 60.0513 85.9729 59.7934L83.6499 49.5393C83.3434 48.2173 82.0366 47.395 80.7138 47.6852C79.3909 47.9916 78.5682 49.2975 78.8586 50.6196L80.4557 57.6813C78.2294 55.6337 75.003 54.7953 71.9056 55.7304C67.211 57.1492 64.5654 62.0989 65.985 66.7745L69.5664 78.6247C70.986 83.3164 75.9386 85.9605 80.617 84.5417C85.3115 83.1229 87.9572 78.1732 86.5376 73.4976L86.1504 72.2401C86.231 71.9821 86.2794 71.7241 86.2794 71.4339L86.1181 60.5834Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.0359 85.106C76.5679 85.106 75.1159 84.7513 73.7931 84.0258C71.6636 82.8811 70.1149 80.9947 69.4212 78.6892L65.8399 66.839C64.4041 62.0828 67.0982 57.0364 71.8734 55.5853C74.7449 54.7147 77.8907 55.3596 80.2138 57.246L78.7296 50.6679C78.5683 49.9908 78.6973 49.2814 79.0684 48.701C79.4394 48.1044 80.0202 47.7014 80.6977 47.5401C81.3753 47.3789 82.0851 47.5079 82.6659 47.8787C83.2628 48.2495 83.6661 48.8299 83.8274 49.5071L86.1504 59.745C86.2472 60.0191 86.2956 60.2932 86.2956 60.5673L86.457 71.4339C86.457 71.708 86.4247 71.966 86.344 72.2401L86.7151 73.4493C87.4088 75.7548 87.1668 78.1894 86.0375 80.3175C84.8921 82.4457 83.0046 83.9935 80.6977 84.6868C79.8105 84.977 78.9232 85.106 78.0359 85.106ZM74.4395 55.5384C73.6006 55.5384 72.7618 55.6512 71.939 55.9092C67.3413 57.2958 64.744 62.1648 66.1314 66.7598L69.7128 78.6099C70.3903 80.8349 71.8906 82.6567 73.9394 83.7531C75.9882 84.8494 78.3435 85.0913 80.5698 84.4141C82.7961 83.737 84.619 82.2375 85.716 80.19C86.813 78.1424 87.055 75.7885 86.3774 73.5635L85.9741 72.2576L85.9903 72.2092C86.0709 71.9674 86.1032 71.7094 86.1032 71.4676L85.9419 60.6009C85.9419 60.3591 85.8935 60.1011 85.8128 59.8754V59.8593L83.4897 49.6052C83.3607 49.0087 82.9896 48.4928 82.4734 48.1703C81.9572 47.8479 81.3442 47.735 80.7473 47.8801C79.5212 48.1542 78.7307 49.3956 79.0211 50.621L80.7311 58.1825L80.3439 57.8278C78.7146 56.3284 76.6013 55.5384 74.4395 55.5384Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.654 268.663L105.477 277.804H94.8456C91.5546 277.804 88.8605 280.497 88.8605 283.786C88.8605 287.075 91.5546 289.767 94.8456 289.767H124.529C127.82 289.767 130.514 287.075 130.514 283.786L131.256 271.807L106.654 268.663Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.529 289.929H94.8458C91.458 289.929 88.6993 287.172 88.6993 283.786C88.6993 280.4 91.458 277.643 94.8458 277.643H105.332L106.509 268.469L106.671 268.485L131.418 271.645L130.676 283.77C130.676 287.172 127.917 289.929 124.529 289.929ZM94.847 277.967C91.6366 277.967 89.0232 280.579 89.0232 283.787C89.0232 286.996 91.6366 289.608 94.847 289.608H124.53C127.741 289.608 130.354 286.996 130.354 283.787L131.08 271.953L106.785 268.842L105.607 277.967H94.847Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M193.317 277.998H182.686L181.508 268.856L156.923 272L157.665 283.979C157.665 287.268 160.359 289.961 163.65 289.961H193.333C196.624 289.961 199.318 287.268 199.318 283.979C199.302 280.69 196.608 277.998 193.317 277.998Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M193.317 290.106H163.634C160.246 290.106 157.488 287.349 157.488 283.963L156.745 271.839L156.891 271.823L181.638 268.647L182.815 277.82H193.301C196.689 277.82 199.448 280.577 199.448 283.963C199.448 287.349 196.705 290.106 193.317 290.106ZM157.086 272.131L157.812 283.948C157.812 287.173 160.425 289.769 163.635 289.769H193.319C196.529 289.769 199.143 287.157 199.143 283.948C199.143 280.74 196.529 278.128 193.319 278.128H182.542L181.365 269.003L157.086 272.131Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.908 86.2023V144.937H122.222V86.2023C122.222 77.8508 127.143 70.6439 134.257 67.3227C136.935 66.0651 139.919 65.3718 143.065 65.3718C146.469 65.3718 149.679 66.1941 152.519 67.629C159.278 71.0793 163.908 78.0926 163.908 86.2023Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.069 145.082H122.061V86.2023C122.061 82.1394 123.222 78.1894 125.433 74.7875C127.578 71.4823 130.611 68.8543 134.176 67.1776C136.967 65.8716 139.952 65.2106 143.049 65.2106C146.405 65.2106 149.615 65.9845 152.583 67.4839C159.665 71.0954 164.053 78.2539 164.053 86.1862V145.082H164.069ZM122.385 144.761H163.732V86.2037C163.732 78.4003 159.408 71.3386 152.439 67.7916C149.519 66.3083 146.357 65.5506 143.066 65.5506C140.017 65.5506 137.065 66.1955 134.323 67.4853C130.806 69.1298 127.837 71.7256 125.708 74.9823C123.53 78.3359 122.385 82.2214 122.385 86.2198V144.761Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.654 274.29H156.374L143.436 178.376L131.547 274.29H105.3L121.561 143.099L122.222 137.714L163.908 137.811L164.634 143.099L182.654 274.29Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.847 274.451H156.229L143.452 179.617L131.692 274.451H105.122L105.138 274.273L122.077 137.553H122.222L164.037 137.666L164.779 143.083L182.847 274.451ZM105.494 274.13H131.419L143.438 177.103L156.521 274.13H182.478L163.764 137.973L122.369 137.861L105.494 274.13Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.007 156.4L165.07 168.992C159.423 164.978 155.713 160.157 153.293 155.772L163.569 142.648C164.053 142.035 164.779 141.713 165.505 141.713C166.038 141.713 166.57 141.89 167.022 142.229C168.086 143.067 168.28 144.599 167.441 145.679L166.586 146.759C166.796 146.791 167.022 146.84 167.215 146.92C167.425 147.001 167.619 147.114 167.796 147.259C168.812 148.065 169.038 149.516 168.312 150.564C168.361 150.596 168.409 150.612 168.457 150.645C169.296 151.177 169.7 152.112 169.587 153.031C170.361 152.531 171.394 152.483 172.232 153.015C173.378 153.74 173.733 155.256 173.007 156.4Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.118 169.218L164.973 169.121C160.052 165.639 156.084 161.173 153.148 155.836L153.099 155.739L163.44 142.535C163.94 141.89 164.698 141.535 165.505 141.535C166.086 141.535 166.651 141.729 167.118 142.084C167.667 142.519 168.022 143.132 168.102 143.825C168.183 144.518 167.989 145.195 167.57 145.76L166.892 146.63C167.022 146.662 167.167 146.711 167.296 146.759C167.522 146.856 167.731 146.969 167.925 147.114C168.957 147.92 169.216 149.371 168.57 150.5C168.57 150.5 168.57 150.5 168.586 150.5C169.361 150.983 169.812 151.838 169.812 152.741C170.619 152.354 171.587 152.386 172.361 152.87C173.587 153.643 173.942 155.256 173.184 156.481L165.118 169.218ZM153.488 155.789C156.36 160.981 160.248 165.35 165.023 168.768L172.863 156.321C173.541 155.257 173.218 153.838 172.137 153.161C171.395 152.694 170.427 152.694 169.669 153.177L169.379 153.371L169.427 153.032C169.54 152.146 169.12 151.275 168.378 150.807C168.33 150.775 168.282 150.759 168.233 150.727L168.072 150.646L168.169 150.501C168.846 149.501 168.636 148.147 167.685 147.405C167.523 147.276 167.33 147.164 167.136 147.083C166.943 147.002 166.749 146.954 166.539 146.938L166.249 146.906L167.281 145.6C167.652 145.116 167.83 144.52 167.749 143.907C167.669 143.294 167.362 142.762 166.878 142.375C166.475 142.053 165.975 141.892 165.458 141.892C164.749 141.892 164.087 142.214 163.652 142.778L153.488 155.789Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.754 39.932L120.724 31.8706L118.788 22.7774L111.641 29.9037L108.124 26.3889L115.368 19.1498L106.737 16.3445L97.7673 24.4219L99.5257 32.709L44.5468 82.1895L36.4807 79.5615L27.5111 87.639L29.447 96.7322L36.5775 89.5898L40.1104 93.1207L32.867 100.36L41.4978 103.165L50.4674 95.0877L48.6928 86.7845L103.672 37.3201L111.754 39.932Z"
      fill="#FFE1E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5285 103.341L32.5589 100.423L39.883 93.1028L36.592 89.8138L29.3486 97.0529L27.332 87.5727L36.4307 79.3824L44.4968 82.0104L99.3305 32.6589L97.5882 24.3557L106.687 16.1654L115.656 19.0836L108.332 26.4033L111.623 29.6923L118.851 22.4694L120.867 31.9495L111.785 40.1237L103.719 37.4957L48.8687 86.8472L50.6432 95.1504L41.5285 103.341ZM33.1575 100.279L41.4495 102.971L50.2739 95.0229L48.5155 86.7198L48.58 86.6553L103.623 37.1264L111.69 39.7544L120.514 31.8059L118.659 23.0997L111.609 30.1453L107.866 26.4048L115.045 19.2302L106.753 16.5377L97.9287 24.4862L99.6871 32.7894L99.6226 32.8539L44.5953 82.3666L36.5292 79.7386L27.7048 87.6871L29.56 96.3934L36.6098 89.3478L40.3525 93.0882L33.1575 100.279Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.1161 66.4843C75.2288 67.5 73.6801 67.6129 72.6476 66.7261L64.4201 59.5838C63.4038 58.697 63.2909 57.1492 64.1782 56.1174C65.0654 55.1017 66.6141 54.9888 67.6466 55.8756L75.8741 63.0179C76.8904 63.9046 77.0033 65.4685 76.1161 66.4843Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.2608 67.4839C73.6478 67.4839 73.0348 67.2743 72.5508 66.839L64.3233 59.6966C63.2263 58.7454 63.1134 57.1009 64.0652 56.0045C65.017 54.9082 66.6625 54.7953 67.7595 55.7466L75.987 62.8889C77.084 63.8402 77.1969 65.4847 76.2451 66.581C75.7127 67.1937 74.9868 67.4839 74.2608 67.4839ZM66.0348 55.4578C65.3895 55.4578 64.7603 55.7319 64.3086 56.2478C63.4859 57.1991 63.5827 58.6501 64.5345 59.4885L72.762 66.6308C73.7138 67.4531 75.1657 67.3563 76.0046 66.4051C76.8273 65.4539 76.7305 64.0028 75.7787 63.1645L67.5512 56.0221C67.0995 55.6352 66.5672 55.4578 66.0348 55.4578Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.567 69.6927C71.7281 70.6601 70.2762 70.7568 69.3083 69.9184L62.2262 63.7757C61.2582 62.9373 61.1614 61.4862 62.0003 60.5189C62.8392 59.5515 64.2911 59.4548 65.2591 60.2932L72.3411 66.4359C73.2929 67.2904 73.3897 68.7415 72.567 69.6927Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.8246 70.6601C70.2438 70.6601 69.6792 70.4666 69.2114 70.0635L62.1293 63.9208C61.6292 63.4855 61.3388 62.8889 61.2904 62.2279C61.242 61.5669 61.4517 60.9381 61.8873 60.4383C62.7746 59.4064 64.3394 59.3097 65.3719 60.1964L72.454 66.3392C72.9541 66.7745 73.2444 67.371 73.2928 68.0321C73.3412 68.6931 73.1315 69.3219 72.6959 69.8217C72.1958 70.3698 71.5022 70.6601 70.8246 70.6601ZM63.7438 59.9075C63.1469 59.9075 62.55 60.1494 62.1306 60.6492C61.7595 61.0845 61.5659 61.6326 61.6143 62.1969C61.6466 62.7612 61.9208 63.2933 62.3403 63.6641L69.4224 69.8068C70.3096 70.5807 71.6648 70.484 72.4391 69.5972C72.8102 69.1619 73.0037 68.6138 72.9553 68.0495C72.9231 67.4852 72.6488 66.9531 72.2294 66.5823L65.1473 60.4396C64.7279 60.0687 64.2278 59.9075 63.7438 59.9075Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.2781 63.5177C79.3909 64.5334 77.8422 64.6463 76.8097 63.7596L68.195 56.2948C67.1787 55.408 67.0658 53.8602 67.9531 52.8284C68.8403 51.8126 70.389 51.6998 71.4215 52.5865L80.0362 60.0675C81.0525 60.9542 81.1654 62.502 80.2781 63.5177Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.423 64.5334C77.7939 64.5334 77.197 64.3077 76.713 63.8885L68.0984 56.4076C67.0014 55.4564 66.8884 53.8119 67.8402 52.7155C68.2919 52.1835 68.9372 51.8771 69.6309 51.8288C70.3246 51.7804 71.0022 52.0061 71.5345 52.4575L80.1492 59.9385C81.2462 60.8897 81.3591 62.5342 80.4073 63.6306C79.9556 64.1626 79.3103 64.4689 78.6166 64.5173C78.5521 64.5173 78.4876 64.5334 78.423 64.5334ZM69.8098 52.1364C69.7614 52.1364 69.6969 52.1364 69.6485 52.1364C69.0355 52.1847 68.487 52.4588 68.0837 52.9264C67.2609 53.8776 67.3577 55.3286 68.3095 56.167L76.9242 63.6479C77.392 64.051 77.9728 64.2445 78.5858 64.1961C79.1988 64.1477 79.7473 63.8737 80.1506 63.4061L80.2797 63.519L80.1506 63.4061C80.9734 62.4549 80.8766 61.0038 79.9248 60.1654L71.3101 52.6845C70.8907 52.3459 70.3583 52.1364 69.8098 52.1364Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.3274 59.5515C82.4401 60.5672 80.8914 60.6801 79.8589 59.7934L71.6476 52.6671C70.6312 51.7804 70.5183 50.2326 71.4056 49.2008C72.2929 48.185 73.8416 48.0722 74.874 48.9589L83.0854 56.0851C84.1017 56.9719 84.2146 58.5197 83.3274 59.5515Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.472 60.5511C80.859 60.5511 80.2459 60.3415 79.762 59.9062L71.5506 52.78C70.4536 51.8288 70.3407 50.1842 71.2925 49.0879C71.7442 48.5559 72.3895 48.2495 73.0832 48.2012C73.7769 48.1528 74.4544 48.3785 74.9868 48.8299L83.1982 55.9562C84.2951 56.9074 84.4081 58.5519 83.4563 59.6483C82.9239 60.2448 82.1979 60.5511 81.472 60.5511ZM73.2621 48.5249C73.2137 48.5249 73.1492 48.5249 73.1008 48.5249C72.4878 48.5733 71.9393 48.8474 71.536 49.315C70.7132 50.2662 70.81 51.7172 71.7618 52.5556L79.9732 59.6818C80.925 60.5041 82.3769 60.4074 83.2157 59.4561C84.0385 58.5049 83.9417 57.0538 82.9899 56.2155L74.7786 49.0892C74.343 48.7184 73.8106 48.5249 73.2621 48.5249Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.634 143.099H121.561L122.222 137.714L163.908 137.811L164.634 143.099Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.811 143.26H121.383L122.093 137.553H122.238L164.053 137.666L164.811 143.26ZM121.74 142.939H164.442L163.765 137.973L122.369 137.861L121.74 142.939Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M204.449 118.754C204.287 119.028 204.094 119.286 203.9 119.544L174.475 157.142L159.536 145.453L183.348 115.014L173.216 107.339L163.892 100.294L157.988 95.8276L152.519 91.684V70.6117C152.632 70.6117 152.761 70.6117 152.874 70.6117C154.874 70.6117 156.874 71.2405 158.584 72.5303L159.117 72.9333L202.448 105.711C206.562 108.806 207.417 114.594 204.449 118.754Z"
      fill="#2252B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.507 157.352L159.327 145.485L183.138 115.046L152.357 91.7647V70.4505H152.519C152.632 70.4505 152.761 70.4505 152.874 70.4505C154.987 70.4505 157.004 71.1276 158.681 72.4013L202.545 105.582C204.562 107.097 205.884 109.322 206.255 111.821C206.627 114.304 206.03 116.803 204.578 118.851C204.4 119.125 204.207 119.399 204.013 119.641L174.507 157.352ZM159.764 145.438L174.444 156.918L203.772 119.449C203.966 119.207 204.143 118.949 204.321 118.675C205.741 116.692 206.305 114.273 205.95 111.871C205.579 109.453 204.305 107.324 202.369 105.841L158.505 72.6606C156.876 71.4191 154.924 70.7742 152.891 70.7742C152.827 70.7742 152.762 70.7742 152.698 70.7742V91.6209L183.607 114.999L159.764 145.438Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.748 105.485L163.908 100.31L157.988 95.8275L162.279 79.2857L170.748 105.485Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.055 105.92L157.81 95.8921L162.263 78.7053L171.055 105.92ZM158.165 95.7631L170.426 105.034L162.295 79.85L158.165 95.7631Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.726 70.9341L137.871 117.625L142.597 129.347L147.34 117.255L143.678 70.9341H141.726Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.613 129.798L137.709 117.658V117.625L141.581 70.789H143.84L147.518 117.287L142.613 129.798ZM138.033 117.594L142.599 128.913L147.164 117.224L143.518 71.0966H141.857L138.033 117.594Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.145 69.5637L141.936 72.9011C142.323 73.6428 143.388 73.6428 143.791 72.9172L145.759 69.4025C146.114 68.7737 145.743 67.9676 145.017 67.8386L141.371 67.1937C140.774 67.0969 140.226 67.5 140.145 68.0966L140.032 68.9027C140 69.1445 140.048 69.3702 140.145 69.5637Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.871 73.6266C142.871 73.6266 142.855 73.6266 142.871 73.6266C142.404 73.6266 142 73.3848 141.791 72.9817L140 69.6443C139.871 69.4186 139.839 69.1606 139.871 68.9027L139.984 68.0965C140.032 67.758 140.21 67.4678 140.468 67.2743C140.742 67.0808 141.065 67.0002 141.403 67.0647L145.049 67.7096C145.437 67.7741 145.775 68.0321 145.937 68.3868C146.114 68.7415 146.098 69.1606 145.904 69.4992L143.936 73.014C143.71 73.4009 143.323 73.6266 142.871 73.6266ZM140.292 69.5005L142.082 72.8379C142.244 73.1281 142.534 73.3054 142.857 73.3054C143.195 73.3054 143.486 73.1442 143.647 72.854L145.615 69.3393C145.761 69.0813 145.777 68.775 145.648 68.517C145.519 68.2429 145.277 68.0656 144.986 68.0172L141.34 67.3723C141.098 67.3239 140.856 67.3884 140.663 67.5335C140.469 67.6786 140.34 67.8882 140.308 68.1301L140.195 68.9362C140.163 69.1458 140.211 69.3231 140.292 69.5005Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.32 74.0781L98.3303 103.002L86.715 71.8209L68.9534 78.4312L85.618 123.155C85.7955 123.639 86.0214 124.107 86.2633 124.542C86.7634 125.638 87.4894 126.654 88.4089 127.525C92.2323 131.088 98.2335 130.878 101.815 127.057L122.238 105.163V138.907C122.238 139.859 123.013 140.632 123.965 140.632H132.547C133.499 140.632 134.273 139.859 134.273 138.907V92.2806V71.2888L135.273 66.8873C131.305 68.29 125.981 73.3848 125.32 74.0781Z"
      fill="#2252B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.531 140.794H123.948C122.916 140.794 122.061 139.955 122.061 138.907V105.566L101.912 127.154C100.153 129.04 97.7657 130.12 95.2007 130.217C92.6195 130.314 90.1674 129.395 88.296 127.638C87.3765 126.767 86.6344 125.751 86.102 124.607C85.8439 124.155 85.6181 123.688 85.4406 123.204L68.7275 78.3344L86.7957 71.6113L86.8441 71.7564L98.3626 102.696L125.174 73.9652C125.981 73.1107 131.208 68.1288 135.177 66.7261L135.451 66.6294L134.386 71.305V138.923C134.418 139.955 133.579 140.794 132.531 140.794ZM122.384 104.76V138.907C122.384 139.762 123.077 140.471 123.948 140.471H132.531C133.386 140.471 134.096 139.778 134.096 138.907V71.2727L135.031 67.1453C131.176 68.6608 126.078 73.4976 125.433 74.1909L98.2658 103.308L86.6183 72.0305L69.147 78.5279L85.7471 123.107C85.9246 123.575 86.1343 124.026 86.3924 124.478C86.9087 125.606 87.6185 126.59 88.5219 127.412C92.2807 130.91 98.1852 130.701 101.686 126.96L122.384 104.76Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.908 86.2023V140.262C163.618 140.504 163.247 140.649 162.827 140.649H154.245C153.293 140.649 152.519 139.875 152.519 138.923V67.629C155.068 68.9349 157.31 70.7407 159.117 72.9172C162.101 76.5287 163.908 81.1559 163.908 86.2023Z"
      fill="#2252B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.827 140.794H154.245C153.212 140.794 152.357 139.955 152.357 138.907V67.371L152.599 67.4839C155.164 68.7898 157.407 70.5794 159.246 72.8205C162.359 76.5771 164.069 81.3172 164.069 86.2023V140.342L164.005 140.391C163.666 140.649 163.247 140.794 162.827 140.794ZM152.68 67.9031V138.923C152.68 139.778 153.374 140.487 154.245 140.487H162.827C163.166 140.487 163.473 140.391 163.747 140.197V86.2023C163.747 81.3978 162.069 76.7222 159.004 73.0301C157.229 70.9019 155.116 69.1768 152.68 67.9031Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.409 171.314C167.215 170.637 166.07 169.895 164.989 169.137C160.069 165.655 156.1 161.189 153.164 155.852C150.325 151.032 151.083 142.696 151.083 142.616L151.406 142.648C151.406 142.728 150.663 150.951 153.438 155.691C156.342 160.979 160.294 165.413 165.166 168.863C166.247 169.621 167.393 170.363 168.57 171.024L168.409 171.314Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.141 171.314L117.979 171.04C119.157 170.363 120.303 169.637 121.383 168.879C127.143 164.8 128.998 162.882 131.337 158.642C134.128 153.885 135.144 142.745 135.16 142.632L135.483 142.664C135.483 142.696 135.225 145.518 134.628 148.903C133.821 153.45 132.805 156.787 131.627 158.803C129.272 163.091 127.385 165.026 121.593 169.137C120.48 169.895 119.335 170.637 118.141 171.314Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.534 55.1017C149.534 55.8433 149.534 56.585 149.518 57.3105C149.518 57.5846 149.502 57.8425 149.502 58.1166C149.486 59.1323 149.47 60.1319 149.437 61.1315C149.405 62.1312 149.389 63.1469 149.357 64.1465C149.341 64.7108 149.324 65.2751 149.308 65.8394C149.292 66.2747 149.276 66.7261 149.26 67.1614L149.244 67.4355H135.757L136.257 65.3718C136.483 64.3883 136.741 63.421 136.967 62.4375C137.209 61.454 137.451 60.4866 137.709 59.5032C137.951 58.5197 138.225 57.5523 138.484 56.585C138.742 55.6176 139 54.6341 139.274 53.6667C139.774 51.9094 141.855 50.6841 144.34 50.8292C146.679 50.9743 148.663 52.2641 149.308 53.9247C149.454 54.3117 149.534 54.6986 149.534 55.1017Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.405 67.6129H135.564L136.112 65.3396C136.274 64.6947 136.419 64.0498 136.58 63.3887C136.661 63.0663 136.741 62.7277 136.822 62.4053C137.016 61.6475 137.209 60.8897 137.403 60.1158L137.564 59.4709C137.758 58.697 137.967 57.9231 138.161 57.1654L138.322 56.5527C138.597 55.5209 138.855 54.5858 139.113 53.6345C139.629 51.7804 141.839 50.539 144.34 50.6841C146.711 50.8292 148.76 52.1029 149.454 53.8764C149.615 54.2633 149.696 54.6825 149.696 55.1017C149.696 55.9078 149.679 56.7139 149.679 57.3266C149.679 57.4556 149.679 57.6007 149.679 57.7297C149.679 57.8587 149.679 58.0038 149.679 58.1327C149.679 58.4552 149.663 58.7776 149.663 59.1001C149.647 59.7773 149.647 60.4705 149.615 61.1638L149.599 61.5024C149.583 62.373 149.55 63.292 149.518 64.1787L149.47 65.8878C149.454 66.3231 149.438 66.7584 149.421 67.1937L149.405 67.6129ZM135.969 67.2918H149.1V67.1789C149.116 66.7436 149.133 66.3083 149.149 65.873L149.197 64.164C149.229 63.2773 149.245 62.3583 149.278 61.4876L149.294 61.1491C149.31 60.4719 149.326 59.7625 149.342 59.1015C149.342 58.779 149.358 58.4566 149.358 58.1341C149.358 58.0051 149.358 57.86 149.358 57.7311C149.358 57.6021 149.358 57.457 149.358 57.328C149.375 56.7153 149.375 55.9253 149.375 55.1192C149.375 54.7484 149.294 54.3775 149.165 54.0067C148.536 52.3783 146.551 51.153 144.341 51.024C141.986 50.895 139.921 52.0236 139.437 53.7487C139.163 54.7 138.921 55.6351 138.647 56.667L138.485 57.2796C138.292 58.0374 138.082 58.8113 137.888 59.5852L137.727 60.2301C137.533 60.9878 137.34 61.7456 137.146 62.5034C137.066 62.8258 136.985 63.1644 136.904 63.4868C136.743 64.1318 136.582 64.7767 136.436 65.4216L135.969 67.2918Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.984 53.6345C139.984 53.6345 141.129 56.8913 143.582 60.3093C145.017 62.3085 146.921 64.3722 149.308 65.8716C149.325 65.3073 149.341 64.743 149.357 64.1787C149.389 63.1791 149.421 62.1634 149.438 61.1638C149.47 60.1642 149.47 59.1485 149.502 58.1489C149.502 57.8748 149.518 57.6007 149.518 57.3427C149.534 56.6011 149.534 55.8594 149.534 55.1339C149.534 54.7308 149.454 54.3439 149.308 53.9731L139.984 53.6345Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.454 66.1457L149.212 66.0006C147.114 64.6785 145.162 62.7922 143.452 60.3899C141 56.988 139.839 53.7151 139.839 53.6829L139.758 53.4572L149.421 53.7635L149.454 53.8602C149.615 54.2633 149.696 54.6664 149.696 55.0855C149.696 55.7949 149.696 56.4721 149.679 57.2944L149.663 58.1005C149.663 58.3907 149.647 58.697 149.647 58.9872C149.631 59.6805 149.631 60.406 149.599 61.1154C149.567 62.115 149.55 63.1308 149.518 64.1304L149.454 66.1457ZM140.227 53.7964C140.533 54.5864 141.662 57.3434 143.727 60.1971C145.357 62.4704 147.196 64.2762 149.164 65.566L149.212 64.1633C149.245 63.1637 149.277 62.148 149.293 61.1484C149.309 60.4228 149.325 59.7134 149.341 59.004C149.341 58.7138 149.358 58.4075 149.358 58.1173L149.374 57.3112C149.39 56.4889 149.39 55.7956 149.39 55.1024C149.39 54.7638 149.325 54.4252 149.212 54.1027L140.227 53.7964Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.5 32.4171C141.049 29.7729 145.727 29.5956 149.405 31.5948L149.454 31.627C149.728 31.7722 150.002 31.9495 150.276 32.1269L159.859 37.931C160.617 38.3663 160.843 39.3659 160.327 40.0753L158.617 42.4454C161.214 47.1854 159.988 53.2798 155.536 56.6011C150.664 60.2287 143.711 59.213 140.065 54.3439L135.241 47.8948C131.611 43.0097 132.628 36.0447 137.5 32.4171Z"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.937 58.9389C145.517 58.9389 142.13 57.375 139.936 54.4406L135.112 47.9916C133.337 45.6054 132.579 42.655 133.015 39.6723C133.45 36.7057 134.999 34.0777 137.403 32.3042L137.5 32.4332L137.403 32.3042C140.694 29.8535 145.098 29.4182 148.824 31.1272L148.728 30.9983L149.534 31.4819C149.825 31.6432 150.099 31.8044 150.357 31.9817L159.94 37.7859C160.343 38.0116 160.633 38.3986 160.73 38.85C160.827 39.3014 160.73 39.7851 160.456 40.1559L158.81 42.4454C161.424 47.2983 160.069 53.4249 155.632 56.7301C153.616 58.2133 151.26 58.9227 148.937 58.9389ZM144.099 30.403C141.792 30.403 139.501 31.1285 137.598 32.5473C135.275 34.2724 133.758 36.8198 133.339 39.7058C132.919 42.5917 133.645 45.4616 135.371 47.7832L140.195 54.2323C141.921 56.554 144.47 58.0695 147.358 58.4887C150.245 58.9079 153.117 58.1824 155.44 56.4572C159.763 53.2327 161.07 47.2351 158.473 42.495L158.424 42.3982L160.199 39.9476C160.409 39.6413 160.489 39.2704 160.409 38.8996C160.328 38.5449 160.102 38.2225 159.78 38.0451L150.197 32.241C149.939 32.0636 149.665 31.9024 149.39 31.7412L149.342 31.7089C147.68 30.8544 145.89 30.403 144.099 30.403Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.146 41.6876L143.936 40.9943C143.565 39.8012 143.952 38.4953 144.904 37.6892C147.792 35.2546 153.341 29.7407 150.857 25.0006C147.469 18.5354 144.372 27.9349 139.839 25.0329C136.322 22.7757 130.821 32.014 129.724 34.9483C128.562 38.0277 126.433 47.7658 138.774 52.6188L139.016 52.3124C140.048 51.0065 139.822 50.152 139.742 48.3463L142.855 44.9444C144.001 44.2833 144.533 42.9452 144.146 41.6876Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.823 52.8122L138.71 52.7639C125.562 47.5885 128.788 36.9636 129.563 34.8838C130.45 32.5299 134.289 25.8068 137.693 24.6782C138.516 24.4041 139.258 24.4847 139.903 24.8878C141.872 26.1453 143.549 25.0006 145.179 23.8882C146.372 23.0659 147.502 22.292 148.663 22.5822C149.502 22.7918 150.276 23.5657 150.986 24.92C152.099 27.0321 151.761 29.515 150.018 32.2881C148.583 34.5614 146.485 36.5445 144.985 37.802C144.066 38.5759 143.711 39.8012 144.066 40.9459L144.275 41.6392C144.679 42.9452 144.114 44.364 142.92 45.0411L139.871 48.3785C139.887 48.6042 139.887 48.8138 139.903 49.0073C139.984 50.3938 140.032 51.2322 139.113 52.3931L138.823 52.8122ZM138.646 24.8408C138.388 24.8408 138.098 24.8891 137.807 24.9859C134.662 26.0338 130.822 32.4829 129.87 34.9981C129.112 37.0134 125.983 47.3319 138.711 52.4267L138.888 52.2171C139.743 51.1369 139.695 50.3952 139.614 49.057C139.598 48.8313 139.582 48.6056 139.582 48.3638V48.2993L142.76 44.8168L142.776 44.8006C143.841 44.2041 144.357 42.9304 144.002 41.7535L143.793 41.0602C143.405 39.8026 143.809 38.4322 144.825 37.5777C147.422 35.385 153.198 29.7904 150.746 25.0826C150.068 23.8089 149.39 23.0834 148.632 22.906C147.616 22.6481 146.535 23.3897 145.406 24.1636C143.776 25.2761 141.921 26.5498 139.776 25.1632C139.421 24.9375 139.05 24.8408 138.646 24.8408Z"
      fill="black"
    />
    <ellipse
      cx="138.855"
      cy="45.4764"
      rx="4.03308"
      ry="4.03067"
      fill="#E5657B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.21 45.3797C141.21 45.3797 141.113 44.7993 140.742 44.235C140.274 43.4933 139.597 43.1064 138.742 43.0742C136.693 42.9935 136.354 44.9121 136.338 44.9928L136.015 44.9444C136.015 44.9283 136.419 42.6711 138.742 42.7678C141.065 42.8484 141.516 45.3152 141.516 45.3313L141.21 45.3797Z"
      fill="black"
    />
    <ellipse cx="151.002" cy="38.8016" rx="1.0002" ry="0.999606" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.002 39.9625C150.712 39.9625 150.438 39.8496 150.212 39.6561C149.986 39.4465 149.841 39.1563 149.841 38.85C149.841 38.5437 149.938 38.2373 150.147 38.0116C150.357 37.7859 150.647 37.6408 150.954 37.6408C151.26 37.6247 151.567 37.7375 151.793 37.9471C152.019 38.1567 152.164 38.4469 152.164 38.7533C152.164 39.0596 152.067 39.3659 151.857 39.5916C151.648 39.8174 151.357 39.9625 151.051 39.9625C151.035 39.9625 151.018 39.9625 151.002 39.9625ZM151.004 37.9646C150.987 37.9646 150.971 37.9646 150.971 37.9646C150.745 37.9807 150.536 38.0775 150.391 38.2387C150.245 38.3999 150.165 38.6256 150.165 38.8513C150.181 39.0771 150.278 39.2867 150.439 39.4318C150.6 39.5769 150.826 39.6575 151.052 39.6575C151.278 39.6414 151.488 39.5446 151.633 39.3834C151.778 39.2222 151.859 38.9964 151.859 38.7707C151.842 38.545 151.746 38.3354 151.584 38.1903C151.423 38.0452 151.213 37.9646 151.004 37.9646Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.81 48.5397C153.696 48.5397 152.438 48.1044 151.228 46.6856L151.47 46.476C154.342 49.8618 157.536 47.3144 157.665 47.2015L157.875 47.4434C157.875 47.4756 156.552 48.5397 154.81 48.5397Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.612 65.2428L142.775 67.5645L134.144 77.6895C133.692 78.2216 132.87 77.7863 132.999 77.0608L135.612 65.2428Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.66 78.0926C133.547 78.0926 133.434 78.0604 133.321 78.012C133.015 77.8669 132.757 77.5122 132.837 77.0446L135.499 65.0332L143.049 67.4839L134.273 77.8024C134.112 77.9959 133.886 78.0926 133.66 78.0926ZM135.742 65.4541L133.161 77.1108C133.113 77.4172 133.274 77.6429 133.468 77.7235C133.661 77.8202 133.871 77.7719 134.032 77.5945L142.502 67.6468L135.742 65.4541Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.954 65.7104L142.775 67.5L150.196 78.0765C150.663 78.7375 151.648 78.3344 151.583 77.4961L149.954 65.7104Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.792 78.5763C150.502 78.5763 150.228 78.4312 150.05 78.1732L142.5 67.4033L150.083 65.5169L151.728 77.48C151.777 78.0281 151.438 78.399 151.099 78.5279C151.002 78.5602 150.905 78.5763 150.792 78.5763ZM143.049 67.5968L150.325 77.9798C150.518 78.2539 150.793 78.2861 150.986 78.2055C151.196 78.1249 151.438 77.8992 151.406 77.4961L149.808 65.8878L143.049 67.5968Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.58 159.558L159.115 154.337L159.382 154.518L155.847 159.738L155.58 159.558Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.331 163.036L161.585 157.73L161.86 157.899L158.606 163.205L158.331 163.036Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.804 166.439L164.823 161.088L165.104 161.246L162.085 166.597L161.804 166.439Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.658 93.1028L110.93 117.287L122.222 105.179L122.658 93.1028Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.317 118.174L122.835 92.3451L122.383 105.243L110.317 118.174ZM122.464 93.8606L111.543 116.4L122.077 105.114L122.464 93.8606Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.195 144.131H139.806C138.742 144.131 137.871 143.26 137.871 142.196V138.601C137.871 137.537 138.742 136.666 139.806 136.666H146.195C147.26 136.666 148.131 137.537 148.131 138.601V142.196C148.131 143.26 147.26 144.131 146.195 144.131Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.195 144.292H139.806C138.645 144.292 137.709 143.357 137.709 142.196V138.601C137.709 137.44 138.645 136.505 139.806 136.505H146.195C147.356 136.505 148.292 137.44 148.292 138.601V142.196C148.292 143.357 147.356 144.292 146.195 144.292ZM139.808 136.829C138.824 136.829 138.033 137.619 138.033 138.602V142.198C138.033 143.181 138.824 143.971 139.808 143.971H146.196C147.18 143.971 147.971 143.181 147.971 142.198V138.602C147.971 137.619 147.18 136.829 146.196 136.829H139.808Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.662 142.632H141.355C140.581 142.632 139.952 142.003 139.952 141.229V139.601C139.952 138.827 140.581 138.198 141.355 138.198H144.662C145.437 138.198 146.066 138.827 146.066 139.601V141.229C146.066 142.003 145.437 142.632 144.662 142.632Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.662 142.793H141.355C140.484 142.793 139.79 142.084 139.79 141.229V139.601C139.79 138.73 140.5 138.037 141.355 138.037H144.662C145.533 138.037 146.227 138.746 146.227 139.601V141.229C146.227 142.084 145.517 142.793 144.662 142.793ZM141.356 138.344C140.663 138.344 140.114 138.909 140.114 139.586V141.214C140.114 141.907 140.679 142.456 141.356 142.456H144.664C145.357 142.456 145.906 141.891 145.906 141.214V139.586C145.906 138.892 145.341 138.344 144.664 138.344H141.356Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M440.681 81.4634H411.656C403.99 81.4634 397.754 75.2105 397.754 67.4872V38.3068C397.754 30.5997 403.974 24.3306 411.656 24.3306H440.697C448.363 24.3306 454.598 30.5835 454.598 38.3068V67.5034C454.582 75.2105 448.363 81.4634 440.681 81.4634Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M440.681 81.625H411.656C403.91 81.625 397.594 75.2913 397.594 67.4872V38.3068C397.594 30.5189 403.893 24.169 411.656 24.169H440.697C448.443 24.169 454.759 30.5028 454.759 38.3068V67.5034C454.743 75.2913 448.427 81.625 440.681 81.625ZM411.657 24.5096C404.088 24.5096 397.916 30.698 397.916 38.3243V67.5208C397.916 75.131 404.072 81.3355 411.657 81.3355H440.698C448.268 81.3355 454.439 75.1472 454.439 67.5208V38.3081C454.439 30.698 448.284 24.4935 440.698 24.4935H411.657V24.5096Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M437.789 78.7505H408.765C401.099 78.7505 394.863 72.4976 394.863 64.7743V35.5777C394.863 27.8706 401.083 21.6015 408.765 21.6015H437.805C445.471 21.6015 451.707 27.8545 451.707 35.5777V64.7743C451.691 72.4976 445.471 78.7505 437.789 78.7505Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M437.788 78.9105H408.763C401.017 78.9105 394.701 72.5768 394.701 64.7727V35.5762C394.701 27.7883 401.001 21.4384 408.763 21.4384H437.804C445.55 21.4384 451.866 27.7721 451.866 35.5762V64.7727C451.85 72.5606 445.55 78.9105 437.788 78.9105ZM408.764 21.779C401.195 21.779 395.023 27.9673 395.023 35.5937V64.7902C395.023 72.4004 401.179 78.6049 408.764 78.6049H437.805C445.375 78.6049 451.546 72.4165 451.546 64.7902V35.5775C451.546 27.9673 445.391 21.7629 437.805 21.7629H408.764V21.779Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M419.852 66.954C419.852 64.3849 420.977 63.1085 423.227 63.1085C424.32 63.1085 425.156 63.4317 425.75 64.0941C426.345 64.7566 426.634 65.7099 426.634 66.954C426.634 68.1658 426.329 69.1029 425.734 69.7654C425.14 70.4278 424.304 70.7672 423.227 70.7672C422.247 70.7672 421.443 70.4763 420.8 69.8785C420.174 69.2807 419.852 68.3112 419.852 66.954ZM424.755 58.536H421.798L420.367 28.5639H426.201L424.755 58.536Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M423.243 70.9288C422.231 70.9288 421.379 70.6218 420.72 69.9916C420.045 69.3615 419.708 68.3436 419.708 66.9379C419.708 64.2719 420.897 62.9308 423.243 62.9308C424.368 62.9308 425.268 63.2863 425.879 63.981C426.49 64.6758 426.811 65.6614 426.811 66.954C426.811 68.1981 426.49 69.1837 425.863 69.8785C425.236 70.5733 424.352 70.9288 423.243 70.9288ZM423.243 63.2701C421.073 63.2701 420.029 64.4819 420.029 66.954C420.029 68.2628 420.334 69.1999 420.929 69.7654C421.54 70.3309 422.311 70.6218 423.243 70.6218C424.256 70.6218 425.059 70.2986 425.638 69.6685C426.2 69.0383 426.49 68.1173 426.49 66.954C426.49 65.7584 426.2 64.8374 425.638 64.2072C425.075 63.5771 424.272 63.2701 423.243 63.2701ZM424.917 58.6975H421.654L420.208 28.4023H426.379L424.917 58.6975ZM421.958 58.3744H424.609L426.024 28.7254H420.527L421.958 58.3744Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M422.871 196.773H348.073C338.578 196.773 330.892 189.07 330.892 179.553V174.326C330.892 164.809 338.578 157.106 348.073 157.106H422.871C432.366 157.106 440.052 164.809 440.052 174.326V179.553C440.052 189.053 432.35 196.773 422.871 196.773Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M422.871 196.935H348.073C338.513 196.935 330.73 189.134 330.73 179.553V174.326C330.73 164.745 338.513 156.944 348.073 156.944H422.871C432.431 156.944 440.214 164.745 440.214 174.326V179.553C440.214 189.134 432.431 196.935 422.871 196.935ZM348.074 157.269C338.692 157.269 331.054 164.924 331.054 174.327V179.555C331.054 188.958 338.692 196.613 348.074 196.613H422.872C432.254 196.613 439.892 188.958 439.892 179.555V174.327C439.892 164.924 432.254 157.269 422.872 157.269H348.074Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M419.191 192.907H344.393C334.898 192.907 327.211 185.203 327.211 175.687V170.459C327.211 160.943 334.898 153.239 344.393 153.239H419.191C428.686 153.239 436.372 160.943 436.372 170.459V175.687C436.372 185.187 428.67 192.907 419.191 192.907Z"
      fill="#2252B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M419.189 193.067H344.391C334.832 193.067 327.048 185.266 327.048 175.685V170.458C327.048 160.877 334.832 153.076 344.391 153.076H419.189C428.749 153.076 436.532 160.877 436.532 170.458V175.685C436.532 185.266 428.749 193.067 419.189 193.067ZM344.393 153.401C335.011 153.401 327.373 161.056 327.373 170.459V175.687C327.373 185.09 335.011 192.745 344.393 192.745H419.191C428.573 192.745 436.211 185.09 436.211 175.687V170.459C436.211 161.056 428.573 153.401 419.191 153.401H344.393Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M422.42 165.685H341.179C339.888 165.685 338.838 164.633 338.838 163.338C338.838 162.043 339.888 160.991 341.179 160.991H422.42C423.712 160.991 424.762 162.043 424.762 163.338C424.762 164.617 423.712 165.685 422.42 165.685Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M422.419 165.845H341.178C339.789 165.845 338.675 164.712 338.675 163.337C338.675 161.961 339.805 160.828 341.178 160.828H422.419C423.807 160.828 424.922 161.961 424.922 163.337C424.922 164.712 423.791 165.845 422.419 165.845ZM341.163 161.137C339.952 161.137 338.983 162.124 338.983 163.322C338.983 164.535 339.968 165.507 341.163 165.507H422.404C423.615 165.507 424.584 164.519 424.584 163.322C424.584 162.108 423.599 161.137 422.404 161.137H341.163Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M399.749 185.171H341.164C339.872 185.171 338.823 184.12 338.823 182.825C338.823 181.53 339.872 180.478 341.164 180.478H399.749C401.041 180.478 402.091 181.53 402.091 182.825C402.107 184.12 401.057 185.171 399.749 185.171Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M399.747 185.331H341.162C339.773 185.331 338.659 184.198 338.659 182.823C338.659 181.447 339.789 180.314 341.162 180.314H399.747C401.136 180.314 402.25 181.447 402.25 182.823C402.25 184.198 401.136 185.331 399.747 185.331ZM341.163 180.623C339.952 180.623 338.983 181.61 338.983 182.808C338.983 184.021 339.968 184.992 341.163 184.992H399.748C400.959 184.992 401.928 184.005 401.928 182.808C401.928 181.594 400.943 180.623 399.748 180.623H341.163Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M368.114 175.428H341.163C339.872 175.428 338.822 174.376 338.822 173.081C338.822 171.786 339.872 170.734 341.163 170.734H368.114C369.406 170.734 370.456 171.786 370.456 173.081C370.456 174.376 369.406 175.428 368.114 175.428Z"
      fill="#EAB34B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M368.113 175.588H341.162C339.773 175.588 338.659 174.455 338.659 173.08C338.659 171.704 339.789 170.571 341.162 170.571H368.113C369.501 170.571 370.616 171.704 370.616 173.08C370.616 174.455 369.501 175.588 368.113 175.588ZM341.163 170.88C339.952 170.88 338.983 171.867 338.983 173.065C338.983 174.262 339.968 175.25 341.163 175.25H368.114C369.325 175.25 370.294 174.262 370.294 173.065C370.294 171.867 369.309 170.88 368.114 170.88H341.163Z"
      fill="black"
    />
  </svg>
);

export default ErrorImage;
