import _ from 'lodash';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  formatWithLocale,
  loadAndAddMessagesWithLocaleModule
} from '@wordquest/lib-iso/intl';

// only for last as using chat-bubbles
export const renderMessages = (messages = []) => {
  const lastMessage = _.last(messages);

  const convoMessage = {
    says: messages.filter(Boolean).map((message) => {
      if (message.message) {
        return message.message;
      }
      if (message.audioUrl) {
        // click to play will not work
        return `
          <audio controls style="width: 200px" src="${message.audioUrl}" autoplay="" />`;
        // return ReactDOMServer.renderToStaticMarkup((
        //   <AudioPlayer isPlaying controls={false} autoPlay src={message.audioUrl} />
        // ));
      }

      return message;
    })
  };
  convoMessage.reply = convoMessage.reply || [];

  if (lastMessage.quickReplies) {
    _.map(lastMessage.quickReplies, (reply, i) => {
      convoMessage.reply.push({
        question: reply,
        answer: i.toString()
      });
    });
  }

  return convoMessage;
};

export const createConvo = async ({ profile, messagesByTrigger }) =>
  _.mapValues(
    messagesByTrigger,
    (messages, trigger) => renderMessages(messages) || []
  );
