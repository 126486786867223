// Why ES vs contentful directly?
// client library
// slow cdn?
// contentful delivery api  could beasier
// contenful cache diff feature?
// more for analysis, quest generations
import _ from 'lodash';
import Quest from '~/domain/wordquest/quest/quest';
import bodybuilder from 'bodybuilder';
import { format, parseISO, isValid } from 'date-fns';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity, DataAction } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withPaging,
  withSort,
  withIncludeIdsFilter,
  withTermsFilter
} from '~/app/repo-es-util';
import rootLogger from '~/app/logger';
import { asValidISODate } from '~/utils';

import {
  createDataEvent,
  QuestIntroUpsertedDataEvent,
  QuestUpsertedDataEvent,
  LessonUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';

// TODO generate type from mapping

// type QuestDoc ={_id: string, _source: {word: object, locales: object}}
//
// export const docAsWord = (doc: WordDefinitionsDoc) => Word.create(_.merge({ id: doc._id }, doc._source.word));
export const asQuest = (doc: object) => Quest.create(doc);

export const mapQuestAsDoc = (quest: Quest) => {
  const doc = _.toPlainObject(quest);

  return doc;
};

const logger = rootLogger.child({ module: 'quest-repo' });
const DEFAULT_SOURCE_FIELDS = [];
export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Quest];

export const ES_MAPPING_QUEST_BY_LOCALE = _.fromPairs(
  SUPPORTED_LOCALES.map((sourceLocale) => [
    sourceLocale,
    {
      // store cannot be specificed. in _source anyway
      // https://www.elastic.co/guide/en/elasticsearch/reference/6.3/object.html
      properties: {
        indexedAt: {
          type: 'date'
        },
        quest: {
          properties: {
            author: {
              properties: {
                explanationRich: {
                  enabled: false
                }
              }
            },
            properties: {
              properties: {
                explanationRichText: {
                  enabled: false
                }
              }
            }
          }
        }
      }
    }
  ])
);

export type QuestQueryContext = BaseQueryContext & {
  fields?: {
    isWithMaterials: boolean;
  };
  terms?: {};
  filter?: {
    includeIds?: string[];
  };
};

export const buildQuestQuery = (
  locale: Locale,
  _context: QuestQueryContext = {}
): EsQuery => {
  // to override e.g. isHidden
  // ignore instead of show true
  const context = _.defaultsDeep(_context, {
    paging: {
      size: 10
    }
  });
  let body = bodybuilder();

  // TODO better currying
  body = withIncludeIdsFilter(context)(
    withTermsFilter(context)(withSort(context)(body))
  );

  const sourceFields = DEFAULT_SOURCE_FIELDS;
  const fields = context.fields || {};
  // if (fields.isWithLocales) {
  //   sourceFields = sourceFields.concat([]);
  // }
  // body = body.rawOption(
  //   '_source', sourceFields
  // );

  let isUseDefaultQuery = _.isEmpty(_.get(context, 'terms'));
  _.mapValues(
    _.omit(context.filter, ['includeIds']),
    (filterValue, filterKey) => {
      isUseDefaultQuery = false;
      body = body.filter('match', filterKey, filterValue);
    }
  );

  if (isUseDefaultQuery) {
    // add here in case of empty or only exists query
    body = body.query('match_all');
  }

  const scriptFieldByKey = {};

  if (!_.isEmpty(scriptFieldByKey)) {
    body = body.rawOption('script_fields', scriptFieldByKey);
  }

  // always with paging to make query structure consistent
  body = withPaging(context)(body);

  return body.build();
};

export const docAsQuest = ({ _id, _source: quest, fields }) =>
  Quest.create(
    _.merge(quest, {
      updatedAt: _.isString(quest.updatedAt)
        ? parseISO(quest.updatedAt)
        : undefined
      // publishedAt: _.isString(quest.publishedAt) ? parseISO(quest.publishedAt) : undefined
    })
  );

export const queryQuestsWithContexts = async (
  locale: Locale,
  contexts: QuestQueryContext[]
) => {
  const index = ES_INDEX_BY_ENTITY_LOCALE[Entity.Quest][locale];
  logger.debug('[Debug]queryQuestsWithContexts query & context', contexts);

  return msearch(
    ES_CONFIG.APP_CONTENT,
    contexts.map((context) => [index, buildQuestQuery(locale, context)])
  ).then((quests) =>
    _.map(quests, (docs) => docs.map((doc) => docAsQuest(doc)))
  );
};

export const queryQuestsWithLocaleContext = async (
  locale: Locale,
  context: QuestQueryContext
) => {
  const query = buildQuestQuery(locale, context);
  logger.debug(
    '[Debug]queryQuestsWithLocaleContext query & context',
    query,
    context
  );

  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Quest][locale],
    query
  ).then((res) => res.hits.hits.map(docAsQuest));
};

export const upsertQuestsWithLocale =
  (locale: Locale) => (events: QuestUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.quest.id,
          doc: mapQuestAsDoc(event.properties.quest)
        }),
        {
          timeout: '4m'
        }
      )
    );
