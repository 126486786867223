import _ from 'lodash';
import logger from '~/app/logger';
import _firebaseIso from '~/adapters/firebase';

import { Locale } from '@wordquest/locales';
import {
  DocumentReference,
  FirebaseFirestore,
  Timestamp
} from '@firebase/firestore-types';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

let firebase = _firebaseIso;
// Will be overriden
export const loadFirebase = () => firebase;

export const overrideFirebase = (_firebase: any) => {
  firebase = _firebase;
};

export const loadDb = async ({
  isEnablePersistence = false
}: {
  isEnablePersistence: boolean;
} = {}) => {
  const firebase = loadFirebase();
  let db = null;
  if (!firebase.firestore) {
    // if firebase is not overriden, auto load isomorphic sdk at node
    await import(/* webpackPreload: true */ 'firebase/firestore');
  }
  // supposingly use sdk with less right
  if (process.env.IS_FIREBASE_ADMIN === 'true' || !process.env.IS_WEBPACK) {
    // TODO assume firestore-admin called already
    db = firebase.firestore();

    return db;
  }
  // TODO dynamic import
  db = firebase.firestore();
  logger.debug('firestore init isEnablePersistence', isEnablePersistence);
  if (isEnablePersistence) {
    return firebase
      .firestore()
      .enablePersistence()
      .catch((err) => {
        console.error('Error in Firestore:');
        console.error(err.code);
      })
      .then(() => db);
  }

  return db;
};

// TODO check if webpack4 got simpler
// const loadFireStoreAtBrowser = () => new Promise((resolve, reject) => {
//   require.ensure(['firebase/firestore'], (require) => { //eslint-disable-line
//     console.log('arguments');
//     const firestore = require('firebase/firestore');
//     console.log(firestore);
//
//     resolve(firestore);
//   });
// }).then(
//   (firestore) => {
//     firestore.registerFirestore(firebase);
//   }
// );

export const asDbRefs = (db: FirebaseFirestore) => {
  const users = db.collection('users');
  const userById = (id: string) => users.doc(id);

  return {
    wordsByLocale: (locale: Locale) =>
      db.collection(`dict-${locale}`).doc('v2')
.collection('words'),
    // jaDictWords: db.collection('dict-ja').doc('v2').collection('words'),
    // jaDictWordsWithSyntax: db.collection('dict-ja').doc('v1').collection('words').where('langMeta.ja.google.syntax', '==', null),
    articles: db.collection('articles').doc('v2')
.collection('ja'),
    cache: db.collection('cache'),
    cacheMediaMetadata: db
      .collection('cache')
      .doc('media')
      .collection('metadata'),
    cacheMicrolinkApi: db
      .collection('cache')
      .doc('microlink')
      .collection('api'),
    cachePubsubMessageKey: db
      .collection('cache')
      .doc('pubsubMessage')
      .collection('key'),
    cacheGoogleAnnotate: db
      .collection('cache')
      .doc('google')
      .collection('annotate'),
    cacheGoogleSyntax: db
      .collection('cache')
      .doc('google')
      .collection('syntax'),
    cacheGoogleTranslate: db
      .collection('cache')
      .doc('google')
      .collection('translate'),
    cacheYoutubeApi: db.collection('cache').doc('youtube')
.collection('api'),
    cacheListennotes: db
      .collection('cache')
      .doc('listennotes')
      .collection('api'),
    users,
    userById,
    courses: db.collection('courses').doc('v1')
.collection('zh-TW'),
    courseIntros: db.collection('courseIntros').doc('v1')
.collection('zh-TW'),
    lessons: db.collection('lessons').doc('v1')
.collection('zh-TW'),
    userEvents: (userId: string, entity: string, version: string) =>
      userById(userId)
        .collection('events')
        .doc(entity.toLowerCase())
        .collection(version),
    userQuestsQueue: (userId: string) =>
      userById(userId).collection('quests-queue'),
    db
  };
};

export type DbRefs = ReturnType<typeof asDbRefs>;

// make this async isntead of inner one
export const loadDbRefsAsync = async () => {
  const db = await loadDb();

  return asDbRefs(db);
};

export const loadDbRefs = () => from(loadDb()).pipe(map((db) => asDbRefs(db)));

export const isTimestamp = (v) => _.get(v, 'constructor.name') === 'Timestamp';

/**
 * Mainly for dev/test as affect performance.
 * TODO use monkey patch in setup script instead of injecting
 * Failing batch requests fails earlier & Firestore error don't provide details of
 * object of error
 * TODO prd check
 * we do this instead of just changing all undefined to null, as it is likely an error cause
 * Since we aim for batch processing, not failing but logging the error
 */

export const isValidFirestoreDocumentObj = (doc: any) =>
  _.every(_.values(doc), (v) => {
    if (_.isPlainObject(v) || _.isArray(v)) {
      return isValidFirestoreDocumentObj(v);
    }
    const isValid =
      _.isDate(v) || (!_.isObject(v) && !_.isUndefined(v)) || isTimestamp(v);
    if (!isValid) {
      console.log('Invalid Firestore sub-document');
      console.log(JSON.stringify(v, null, 4));
      console.log(v.constructor.name);
    }

    // no class instance (obj but not plain) & undefined
    return isValid;
  });

export const asValidFirestoreDocumentObj = (doc: any) => {
  if (_.isDate(doc)) {
    return firebase.firestore.Timestamp.fromDate(doc);
  }
  if (_.isArray(doc)) {
    return doc.map(asValidFirestoreDocumentObj);
  }
  if (_.isObject(doc)) {
    return _.mapValues(
      _.pickBy(doc, (v) => v !== undefined),
      (v, k) => asValidFirestoreDocumentObj(v)
    );
  }
  if (_.isUndefined(doc)) {
    return null;
  }

  return doc;
};

export const isValidFirestoreDocument = (doc: any) => {
  const isValid = isValidFirestoreDocumentObj(doc);
  if (!isValid) {
    console.error('Invalid firestore document:');
    console.error(
      JSON.stringify(doc, (k, v) => (_.isUndefined(v) ? 'undefined' : v), 4)
    );
  }

  return isValid;
};

// to handle both FS timestamp / ES
export const fieldAsDate = (field: any) =>
  _.isEmpty(field) || !field.toDate ? new Date(field) : field.toDate();

// Trying to stick with original batch api, only thing we do is to intercept for
// type-based handy colleciton logic
// always merge & union
export type FsOp = ['set' | 'update', any, Object];

/**
 * Currently just failure with error for invalid docs
 * TODO rename since now not necessarily merge
 * https://stackoverflow.com/questions/46597327/difference-between-set-with-merge-true-and-update
 */
export const writeBatchMergeUnion = async (
  operations: FsOp[],
  option = { merge: true },
  batchOption = { arrayOpType: 'union' }
): Promise<any> => {
  const dbRefs = await loadDbRefsAsync();
  const { db } = dbRefs;
  // unable to get from dbRefs in firebase-admin
  const firestoreNamespace = db.app
    ? db.app.firebase_.firestore
    : firebase.firestore;

  const batch = db.batch();
  logger.debug(`Start Batch write ${operations.length}`);
  operations.forEach(([op, ref, doc]) => {
    logger.trace('writeBatchMergeUnion', op, ref.path, doc);
    let docToWrite = doc;
    if (!isValidFirestoreDocument(docToWrite)) {
      logger.error('invalid docToWrite in batch');
      logger.error(docToWrite);

      return;
    }
    if (_.isObject(doc)) {
      docToWrite = _.mapValues(doc, (v, k) => {
        if (_.isArray(v) && v.length > 0) {
          if (batchOption.arrayOpType === 'union') {
            return firestoreNamespace.FieldValue.arrayUnion(...v);
          }
          if (batchOption.arrayOpType === 'remove') {
            return firestoreNamespace.FieldValue.arrayRemove(...v);
          }
        }

        // type === 'replace'
        return v;
      });
    }
    if (op === 'set') {
      batch.set(ref, docToWrite, option);
    } else {
      batch.update(ref, docToWrite);
    }
  });

  return batch.commit();
};
