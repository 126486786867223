import _ from 'lodash';
import { matchPath } from 'react-router';
import React, { lazy } from 'react';
import SideBar from '~/routes/side-bar';
import logger from '@wordquest/lib-iso/app/logger';
import Support from '~/routes/support';
// always optimize course
import Course from '~/routes/course';
import Courses from '~/routes/courses';
// const Course = lazy(() => import(/* webpackPrefetch: true */ '~/routes/course'));

const Watch = lazy(() => import('~/routes/watch'));
// const Courses = lazy(() => import(/* webpackPrefetch: true */ '~/routes/courses'));
const WatchVideo = lazy(
  () => import(/* webpackPrefetch: true */ '~/routes/watch-video')
);
// const WatchTopic = lazy(() => import(/* webpackPrefetch: true */ '~/routes/watch-topic'));

const WatchTopicCovid19 = lazy(
  () => import(/* webpackPrefetch: true */ '~/routes/watch-topic-covid19')
);
const Search = lazy(() => import('~/routes/search'));
const Profile = lazy(() => import('~/routes/profile'));

const Lesson = lazy(() => import('~/routes/lesson'));
const AuthCallback = lazy(() => import('~/routes/auth-callback'));

const Progress = lazy(() => import('~/routes/progress'));
const Classroom = lazy(() => import('~/routes/classroom'));
// const Read = lazy(() => import('~/routes/read'));

const Podcast = lazy(() => import('~/routes/podcast'));
const PodcastEpisode = lazy(() => import('~/routes/podcast-episode'));

// disable as merged inside course for now
// const Challenge = lazy(() => import('~/routes/challenge'));

// order matters
export const ROUTES_CONFIG_SIDEBAR = [
  {
    path: '/course/:courseKey/lesson/:lessonKey',
    render: (props) => <SideBar />
  },
  // {
  //   path: '/course/:courseKey',
  //   render: props => <SideBar />
  // },
  {
    path: '/preview/course/:courseKey/lesson/:lessonKey',
    render: (props) => <SideBar />
  }
  //  {
  //   path: '/preview/course/:courseKey',
  //   render: props => <SideBar />
  // }
];

export const ROUTES_CONFIG_FM = [
  {
    path: '/podcast/:podcastKey/e/:episodeKey',
    exact: true,
    strict: false,
    component: PodcastEpisode
  },
  {
    path: '/podcast/:podcastKey',
    exact: true,
    strict: false,
    component: Podcast
  }
];

export const ROUTES_CONFIG = [
  // {
  //   path: '/read/',
  //   exact: true,
  //   strict: false,
  // component: Read
  // },
  // {
  //   path: '/read/:articleId',
  //   exact: true,
  //   strict: false,
  // component: ReadArticle
  // },
  {
    path: '/auth/callback',
    exact: false,
    strict: false,
    component: AuthCallback
  },
  {
    path: '/course/:courseKey/lesson/:lessonKey',
    exact: false,
    strict: false,
    component: Lesson
  },
  {
    path: '/course/:courseKey',
    exact: false,
    strict: false,
    component: Course
  },
  {
    path: '/preview/course/:courseKey/lesson/:lessonKey',
    exact: true,
    strict: false,
    component: Lesson
  },
  {
    path: '/preview/course/:courseKey',
    exact: true,
    strict: false,
    component: Course
  },
  {
    path: '/watch/topic/:topicKey/tab/:tabKey',
    exact: true,
    strict: false,
    component: WatchTopicCovid19
  },
  {
    path: '/watch/topic/:topicKey',
    exact: true,
    strict: false,
    component: WatchTopicCovid19
  },
  {
    path: '/watch/tag/:videoTags',
    exact: true,
    strict: false,
    component: Watch
  },
  {
    path: '/courses',
    exact: true,
    strict: false,
    component: Courses
  },
  {
    path: '/support',
    exact: true,
    strict: false,
    component: Support
  },
  {
    path: '/support/:type',
    exact: true,
    strict: false,
    component: Support
  },
  {
    path: '/watch',
    exact: true,
    strict: false,
    component: Watch
  },
  {
    path: '/watch/:videoId',
    exact: true,
    strict: false,
    component: WatchVideo
  },
  {
    path: '/search',
    exact: true,
    strict: false,
    component: Search
  },
  {
    path: '/profile',
    exact: true,
    strict: false,
    component: Profile
  },
  {
    path: '/progress',
    exact: true,
    strict: false,
    component: Progress
  },
  {
    path: '/classroom',
    exact: true,
    strict: false,
    component: Classroom
  },
  {
    path: '/liff',
    exact: true,
    strict: false,
    component: <div />
  }
  // {
  //   path: '/home',
  //   exact: true,
  //   strict: false,
  //   component: Home
  // }
];

// bad design that we need to maintain 2 places for route
export const matchRoute = (pathname) => {
  logger.debug(
    'process.env.REACT_APP_MODULE_NAME',
    process.env.REACT_APP_MODULE_NAME
  );
  const routeConfigs =
    process.env.REACT_APP_MODULE_NAME === 'fm'
      ? ROUTES_CONFIG_FM
      : ROUTES_CONFIG;
  const route = _.find(routeConfigs, (routeConfig) =>
    matchPath(pathname, routeConfig)
  );
  if (route) {
    return matchPath(pathname, route);
  }
};
