/**
 *
 * Used cross-system (e.g. elasticsearch, event bus)
 *
 * Captial case preferred, for type & variable name as not to confuse with class
 * Camel case for value as to align event convention
 *
 *  Some quirks at flow so we define entity type separately
 *  - Cannot use const value as flow literal
 *  - $KeysOf will be captialized (re-declared here, not very dry)
 *  https://github.com/facebook/flow/issues/2377#issuecomment-286270861
 *
 */
import _ from 'lodash';

export enum Entity {
  Article = 'Article',
  Word = 'Word',
  User = 'User',
  Definition = 'Definition',
  Profile = 'Profile',
  Course = 'Course',
  Quest = 'Quest',
  Video = 'Video',
  Material = 'Material',
  Order = 'Order',
  Quote = 'Quote',
  Tip = 'Tip',
  // Generic conversation in e.g. bots
  Message = 'Message',
  // Consolidate both Quote, AuthorNote and others in future
  Glossary = 'Glossary'
}

// TODO now we coupled ES with entities. Fix that before we add more

export const ENTITIES = _.values(Entity) as Entity[];

export enum WordAction {
  LookedUp = 'LookedUp',
  ToggledInDeck = 'ToggledInDeck'
}

export enum DefinitionAction {
  LookedUp = 'LookedUp'
}

export enum DataAction {
  Created = 'Created',
  Upserted = 'Upserted'
}

export enum ArticleAction {}

export enum VideoAction {
  Progressed = 'Progressed',
  ToggledBookmark = 'ToggledBookmark',
  DifficultyRated = '',
  NavigateWithTranscript = 'NavigateWithTranscript',
  NavigateWithTipCard = 'NavigateWithTipCard'
}

export enum OrderAction {
  Completed = 'Completed'
}

export enum UserAction {
  Created = 'Created',
  SignedUp = 'SignedUp',
  SignedIn = 'SignedIn',
  SignedOut = 'SignedOut',
  // separate from profile
  ClaimsUpdated = 'ClaimsUpdated',
  LINESignedIn = 'LineSignedIn',
  ChatLinkRequested = 'ChatLinkRequested',
  ChatLinkCompleted = 'ChatLinkCompleted',

  TrialWillBeEnded = 'TrialWillBeEnded',
  TrialStarted = 'TrialStarted',
  TrialEnded = 'TrailEnded',

  SubscriptionCreated = 'SubscriptionCreated',
  SubscriptionUpdateed = 'SubscriptionUpdateed',
  SubscriptionEnded = 'SubscriptionEnded'
}

// We might have some complex handlings on some field changes e.g. email
// separated them instead of a big ProfileUpdated event now.
export enum ProfileAction {
  CustomerIOSegmentsUpdated = 'CustomerIOSegmentsUpdated',
  ChatConfigUpdated = 'ChatConfigUpdated',
  Queried = 'Queried',
  CustomerPortalOpened = 'CustomerPortal-Opened',
  // for general / explicit
  TraitsUpdated = 'TraitsUpdated',
  LINEUpsert = 'LineUpserted'
}

export enum AccountAction {
  SubscriptionCreated = 'SubscriptionCreated',
  SubscriptionUpdated = 'SubscriptionUpdated'
}

export enum QuestAction {
  Added = 'Added',
  Assigned = 'Assigned',
  Started = 'Started',
  Progressed = 'Progressed',
  Completed = 'Completed'
}

export const GlossaryAction = DataAction;

export enum CourseAction {
  CourseQuestClicked = 'CourseQuest-Clicked',
  CoachScheduleViewed = 'CoachSchedule-Viewed',
  CoursePlanClicked = 'CoursePlan-Clicked',
  IntroDetailsChecked = 'IntroDetailsChecked'
}

export const QuoteAction = DataAction;

export const getQuestKey = (courseKey, lessonKey, questType) =>
  `${courseKey}-${lessonKey}-${questType}`;

// user-oriented i.e. sent = sent by user to system
// TODO handle-email-sent should be user-oriented instead
export enum MessageAction {
  SentEmail = 'SentEmail',
  SentLine = 'SentLine',
  SentFbm = 'SentFbm',
  ReceivedLine = 'ReceivedLine'
}

export type Action =
  | UserAction
  | AccountAction
  | WordAction
  | ArticleAction
  | DataAction
  | ProfileAction
  | VideoAction
  | QuestAction
  | CourseAction
  | MessageAction;

export const ENTITY_META = {
  [Entity.Article]: {
    VERSION: 'v2',
    ACTIONS: ArticleAction
  },
  [Entity.Word]: {
    VERSION: 'v2',
    ACTIONS: WordAction
  },
  [Entity.Video]: {
    VERSION: 'v1',
    ACTIONS: VideoAction
  },
  [Entity.Definition]: {
    VERSION: 'v2',
    ACTIONS: DefinitionAction
  },
  [Entity.Profile]: {
    VERSION: 'v1',
    ACTIONS: ProfileAction
  },
  [Entity.Quest]: {
    VERSION: 'v1',
    ACTIONS: QuestAction
  },
  [Entity.Glossary]: {
    VERSION: 'v1',
    ACTIONS: GlossaryAction
  },
  [Entity.Course]: {
    VERSION: 'v1',
    ACTIONS: CourseAction
  },
  [Entity.Quote]: {
    VERSION: 'v1',
    ACTIONS: QuoteAction
  }
  // [Entity.Order]: {
  //   VERSION: 'v1',
  //   ACTIONS: OrderAction
  // }
};

// runtime e.g. jsonschema might be more beneficial

export const VERSION_META = {
  // before we figure out better version mechanism we push this version meta to all events
  appVersion: 'v1' // todo release by git tag
};

export const asEventName = (entity: Entity, action: Action) =>
  [entity, action].join('-');
