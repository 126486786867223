import React from 'react';
import {
  createLanguageMultipleSelect,
  createFilterMultiSelect
} from '~/filters';

import styled from 'styled-components';
import { observer, action, inject } from 'mobx-react';
import LanguageSelect from '~/select/languages';

import { Locale, TARGET_LOCALES } from '@wordquest/locales';

// Support other languages + fields only later
export const LANGUAGE_OPTIONS = TARGET_LOCALES.map((locale) => ({ locale }));

const StyledLanguageSelect = styled.div`
  text-align: left;
  font-size: 0.5rem;
  min-width: 10rem;
  > div {
    width: 100%;
  }
`;

export default ({
  activeLocales,
  isShowInputLabel,
  setActiveLocales,
  isRenderAsChip
}) => (
  <StyledLanguageSelect>
    {createLanguageMultipleSelect({
      fieldKey: 'sourceLocales',
      isShrinkInputLabel: true,
      isShowInputLabel,
      filterByField: {
        sourceLocales: activeLocales.map((locale) => ({ locale }))
      },
      i18nTitleKey: 'common.profile.target.languages',
      options: LANGUAGE_OPTIONS,
      setActiveLocales,
      isRenderAsChip
    })}
  </StyledLanguageSelect>
);
