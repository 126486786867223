import _ from 'lodash';
import React from 'react';
import { WqNodeBlockImage } from '@wordquest/lib-iso/domain/wordquest/dom';
import logger from '@wordquest/lib-iso/app/logger';
import { useRichText } from './use-rich-text';
import Element from './rich-text-element';
import Children from './rich-text-children';
import { media, isSizeUp, isWindowMuiBreakpointUp } from '~/style-utils';
// try to mimic this Editable interface, responsible for rendering but not ui state & events https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/components/editable.tsx#L87

// important support a plugin first architecture so customized render can be inject with composition
// meanwhile to support reasonable default, we have a decoupled wrapper which depends on those actual plugins

// slate use a DefaultElement like this https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/components/element.tsx#L151

export interface RenderElementProps {
  children: any;
  element: Element;
  attributes: {
    // 'data-slate-node': 'element'
    // 'data-slate-inline'?: true
    // 'data-slate-void'?: true
    dir?: 'rtl';
    ref: any;
  };
}

export interface RenderLeafProps {
  children: any;
  leaf: Text;
  text: Text;
  attributes: {
    // 'data-slate-leaf': true
  };
}

export type RenderAudioProps = RenderElementProps & {
  attributes: {
    url: URL;
  };
};

export type RenderLinkProps = RenderElementProps & {
  attributes: {
    url: URL;
  };
};

export type RenderImageProps = RenderElementProps & {
  attributes: {
    url: URL;
  };
};

export type RenderSmartUserBlockProps = RenderElementProps & {
  attributes: {
    userAttributeKey: string;
    type: 'gdoc';
  };
};

export type PictureWithCaptionProps = WqNodeBlockImage['attributes'] & {
  isHideCaption?: boolean;
};

export type RenderPictureWithCaptionProps = RenderElementProps & {
  attributes: PictureWithCaptionProps;
};

// we expose here instead of context to align Editable
// https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/components/editable.tsx#L87
export type RichTextProps = {
  renderElement?: (props: RenderElementProps) => JSX.Element;
  renderLeaf?: (props: RenderLeafProps) => JSX.Element;
};

// we allow composition
// no default renderer assume container will provider

export const DEFAULT_VIDEO_STYLE = {
  margin: 'auto',
  minHeight: isWindowMuiBreakpointUp('lg') ? '40rem' : '15em',
  maxWidth: '60rem'
};

export default React.memo((props: RichTextProps) => {
  const { renderElement, renderLeaf } = props;
  const context = useRichText();
  const {
    wqDom,
    defaultRenderElement,
    defaultRenderLeaf,
    glossaryById,
    quoteById,
    authorById,
    wordById
  } = context;

  if (!wqDom || !wqDom.children) {
    logger.trace('empty wqDom');

    return <></>;
  }

  const doRenderElement = (props) =>
    (renderElement || defaultRenderElement)(props, context);
  const doRenderLeaf = renderLeaf || defaultRenderLeaf;
  const rendered = (
    <Children
      node={wqDom}
      renderElement={doRenderElement}
      renderLeaf={doRenderLeaf}
    />
  );
  //
  // const rendered = wqDom.children.map(node => (
  //
  //   // TODO fix here render the node type too
  //   <Children
  //     node={node}
  //     renderElement={doRenderElement}
  //     renderLeaf={renderLeaf}
  //   />
  // )
  //   //
  // const { children, type } = node;
  // return doRenderElement({ element: node, children, type });
  // );

  return <div>{rendered}</div>;
});
