import _ from 'lodash';
import logger from '~/app/logger';
import { Entity, Action } from '~/domain/wordquest/entity';
import { UserEvent } from './user-event';
import { AppEvent, createAppEvent } from './app-event';

//   // _.toPlainObject not recursive, toJS at mobx not working
// keep as-is for now, instead of app-events
// e.g. firestore
// const toDocument = event => _.merge(
//   _.toPlainObject(_.omit(event, ['entity', 'action'])), {
//     event: [event.entity, event.action].join('-')
//   });

//   static fromAppEvent(appEvent: AppEvent): UserEvent {
//     const {
//       userId, timestamp, event, properties
//     } = appEvent;
//     const [entity, action] = event.split('-');
//
//     return createUserEvent({
//       userId,
//       entity,
//       action,
//       actionAt: timestamp,
//       properties
//     });
// //   }

// Resrved not too necessaary if app event use domain too
const ENTITY_EVENT_USER_APP_MAPPING = {
  [Entity.Video]: 'Watch'
};

export function mapAppEventAsUserEvent<
  U extends UserEvent<Entity, Action, object>
>(appEvent: AppEvent<object>) {
  // we are transform after send
  //
  //   if (appEvent.type === 'page' && appEvent.name === 'ReadArticle') {
  //     appEvent.event = 'Article-Read';
  //   }
  const { userId, event, anonymousId, properties } = appEvent;
  const { userIdToken } = properties || {};
  const [_entity, action] = event.split('-');
  let entity = _entity;
  // workaround legacy issue
  if (_entity === 'Watch') {
    entity = Entity.Video;
  }

  return {
    userId,
    anonymousId,
    userIdToken,
    entity,
    action,
    properties
  } as U;
}

// mostly for convenience at logging
export const mapUserEventAsAppEvent = (userEvent: UserEvent) =>
  createAppEvent({
    ...userEvent
  });
