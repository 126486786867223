import { Locale } from '@wordquest/locales';
import _ from 'lodash';
import { formatWithLocale } from '~/intl';

// format original - translations
// rely on caller to input direct order
// wordings tag
// alternatives: 英語中字 日語中字

export const pickEffectiveDisplayedLocale = (l, locale) => {
  if ((l === Locale.ZH_TW || l === Locale.ZH_CN) && locale === Locale.ZH_TW) {
    return 'zh';
  }

  return l;
};

export const getSubtitlesI18nTag = (
  locales: Locale[],
  locale: Locale = Locale.ZH_TW
) => {
  const delimiter = _.includes([Locale.ZH_TW], locale) ? '' : '/';
  let localesDeduplicated = locales;
  if (_.intersection(locales, [Locale.ZH_TW, Locale.ZH_CN]).length === 2) {
    localesDeduplicated = _.difference(locales, [Locale.ZH_CN]);
  }

  const getLocalesI18n = (l) => {
    if (
      localesDeduplicated.length === 1 &&
      l === Locale.ZH_CN &&
      locale === Locale.ZH_TW
    ) {
      return '簡中';
    }
    if (
      localesDeduplicated.length === 1 &&
      l === Locale.ZH_TW &&
      locale === Locale.ZH_TW
    ) {
      return '中文';
    }

    const effectiveLocale = pickEffectiveDisplayedLocale(l, locale);

    const key =
      locales.length > 1
        ? `common.language.short.${effectiveLocale}`
        : `common.language.${effectiveLocale}`;

    return formatWithLocale(locale)(key);
  };

  // ensure use original order
  const shownLocales = _.intersection(localesDeduplicated, [
    Locale.ZH_TW,
    Locale.ZH_CN,
    Locale.EN,
    Locale.JA,
    Locale.KO,
    Locale.VI
  ]);
  const localesInShort = shownLocales
    .map((l) => getLocalesI18n(l))
    .join(delimiter);

  return formatWithLocale(locale)('common.language.subtitles-tag', {
    locales: localesInShort
  });
};
