// not using typical user events path but map to profile instead
// want a full list and fine grain control of these events
import _ from 'lodash';
import { Entity, Action, ProfileAction } from '~/domain/wordquest/entity';
import { UserEvent } from '~/domain/wordquest/event/user-event';
import Profile from '~/domain/wordquest/profile/profile';

export const mapProfileUpdatedUserEventAsPartialProfile = (
  event: UserEvent<Entity.Profile, Action, object>
) => {
  // always use fallback now
  const createPartialProfile =
    createPartialProfileStartegies[event.action] ||
    createPartialProfileStartegies.fallback;

  return createPartialProfile<typeof event.properties>(
    event.userId,
    event.properties
  );
};

/**
 * For Array, always provide all final items when field is specified
 * i.e. no support for union / pull
 * (given profile is relative simple)
 */

export const mapChatConfigByPlatformAsPartialProfile = (
  chatConfigByPlatform
) => {
  if (!_.isEmpty(chatConfigByPlatform)) {
    const platform = _.first(_.keys(chatConfigByPlatform));

    const {
      replyToken,
      schedule,
      interests,
      difficultyTarget,
      jlptExamDate,
      mainQuestFrequency
    } = chatConfigByPlatform[platform];

    return {
      interests,
      difficultyTarget,
      platformConfig: {
        [platform]: {
          replyToken,
          // currently no platform specific intersts etc , manual pick here instead of all configs
          schedule,
          jlptExamDate,
          mainQuestFrequency
        }
      }
    };
  }
};

export const createPartialProfileStartegies = {
  fallback: <P extends {}>(id: string, properties: P): Profile =>
    Profile.create({
      id,
      ...properties
    })
};

createPartialProfileStartegies[ProfileAction.TraitsUpdated] =
  createPartialProfileStartegies.fallback;
