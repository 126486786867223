import _ from 'lodash';
import Material from '~/domain/wordquest/course/material';
import { Lesson } from '~/domain/wordquest/course/lesson';
import Course from '~/domain/wordquest/course/course';
import { CourseIntro } from '~/domain/wordquest/course/course-intro';
import {
  loadContentfulClient,
  CfTypes,
  RichTextSection
} from '~/adapters/contentful';
import { asQuest } from '~/app/quest/quest-cf';
import { asTags } from '~/app/tag/tag-cf';
import { parseISO } from 'date-fns';
import { asWqDom } from '~/app/contentful/rich-text-wq-dom-mapper';
import logger from '~/app/logger';
import { asAuthor } from '../author/author-cf';
import { WqNodeTypeBlock } from '~/domain/wordquest/dom';

export const asTheme = (themeEntry = {}) => {
  const { sys, fields } = themeEntry;

  return {
    backgroundColor: _.get(fields, 'backgroundColor'),
    ctaBackgroundUrl: _.get(fields, 'ctaBackground.fields.file.url', ''),
    titleBackgroundUrl: _.get(fields, 'titleBackground.fields.file.url', '')
  };
};

// we will decouple course vs coach. Now we leverage the author card inside course section
// so it's possible to
// 1) put teacher description in middle
// 2) display course card with teachers when needed

export const asRichTextSection = (entry) => {
  const { sys, fields } = entry;

  const {
    key,
    title,
    descriptionRich,
    isDefaultExpanded,
    isRenderAsAssistant
  } = fields || {};

  return {
    title,
    key,
    isRenderAsAssistant: isRenderAsAssistant === true,
    isDefaultExpanded: isDefaultExpanded !== false,
    descriptionRich: asWqDom(descriptionRich, { isIncludeRawTypes: ['author'] })
  };
};

export const asCoursePlan = (entry) => {
  const { sys, fields } = entry;
  const {
    title,
    isRecommended,
    originalPrice,
    offerPrice,
    descriptionRich,
    isMembershipBundled,
    ctaLabel
  } = fields || {};

  return {
    id: sys.id,
    title,
    descriptionRichText: asWqDom(fields.descriptionRich),
    isRecommended,
    originalPrice,
    isMembershipBundled,
    offerPrice,
    ctaLabel
  };
};

// https://www.contentful.com/developers/docs/references/content-preview-api/#/reference/links/links-to-a-specific-item
// potential to extract this component based rich text
// allow empty so to improve performance loading course with depth not including the content
export const asCourseIntro = (entry) => {
  const { sys, fields } = entry;
  const {
    title,
    heroImage,
    theme,
    descriptionShort,
    isDefaultExpanded,
    heroVideo,
    // description,
    // learningObjectivesDescription,
    // targetAudienceDescription,
    formats,
    tags
  }: CourseIntro['fields'] = fields || {};

  // override with different aspect ratio. optional
  const heroImageUrl = _.get(heroImage, 'fields.file.url', '');
  const heroVideoUrl = _.get(heroVideo, 'fields.file.url', '');
  // reserve backref to course for easier data mgirations
  const sections = (fields.sections || []).map(asRichTextSection);

  const tools = (_.get(fields, 'tools') || []).map(_.toLower);

  return {
    id: sys.id,
    title,
    heroImageUrl,
    heroVideoUrl,
    sections,
    tools,
    theme: asTheme(theme)
  };
};

export const asCourseOutlineStepRichText = (lesson) => {
  const { key, title, tags } = lesson;

  return {
    type: WqNodeTypeBlock.ListItem,
    attributes: {
      level: 1,
      key,
      tags
      // url: `https:${url}`
    },
    children: [{ text: title }]
  };
};

// https://github.com/wordquest/wordquest/issues/553
// we will only do 1-level of nesting
export const asCourseOutlineRichText = ({
  courseSections,
  key,
  title,
  expandedSectionsLimit = 0
}) => {
  if (_.isEmpty(courseSections)) {
    return;
  }
  const introStep = {
    type: WqNodeTypeBlock.ListItem,
    attributes: {
      key
    },
    children: [
      {
        text: title
      }
    ]
  };

  const courseList = {
    type: WqNodeTypeBlock.UlList,
    attributes: {},
    children: [introStep]
  };

  const limit = expandedSectionsLimit || courseSections.length;
  courseList.children = courseList.children.concat(
    _.take(courseSections, limit).map((courseSection) => {
      const lessonItems = (courseSection.lessons || []).map(
        asCourseOutlineStepRichText
      );

      return {
        type: WqNodeTypeBlock.ListItem,
        attributes: {
          level: 0,
          key: `${courseSection.title}list`
        },
        // list header
        children: [
          {
            type: WqNodeTypeBlock.ListHeader,
            attributes: {
              key: `${courseSection.title}-header`
            },
            children: [{ text: courseSection.title }]
          },
          {
            type: WqNodeTypeBlock.UlList,
            attributes: {
              key: `${courseSection.title}-ul`
            },
            children: lessonItems
          }
        ]
      };
    })
  );

  return courseList;
};

export const asMaterial = (item): Material => {
  const { sys, fields } = item;
  if (!fields) {
    return;
  }
  const {
    startSeconds,
    endSeconds,
    isTranscriptOff,
    videoTargetSubtitlesLocale
  } = fields;

  const isHideAnnotations = !!fields.isHideAnnotations;
  // duplicated field for backwards compatability for now
  const contentRichText = asWqDom(fields.content);
  const meta = {
    isTranscriptOff,
    videoTargetSubtitlesLocale,
    startSeconds,
    endSeconds
  };

  return Material.create({
    id: sys.id,
    meta,
    isHideAnnotations,
    contentRichText,
    ..._.omit(fields, ['startSeconds', 'endSeconds'])
  });
};

// without the includes, useful for Course
// TODO unify with the new AST
// avoid all fields as some are nested
export const asLessonPartial = ({ sys, fields }) => ({
  id: sys.id,
  title: fields.title,
  key: fields.key,
  isHidden: !!fields.isHidden,
  tags: asTags(fields.tags),
  authorizedSegments: fields.authorizedSegments || [],
  updatedAt: sys.updatedAt ? parseISO(sys.updatedAt) : new Date(),
  readyAt: fields.readyAt ? new Date(fields.readyAt) : undefined
});

export const asLesson = (item = {}): Lesson => {
  const { sys, fields } = item;
  if (!fields) {
    return;
  }
  const material =
    fields.material && fields.material.fields
      ? asMaterial(fields.material)
      : null;
  // TODO push the meta of lesson onto material?

  const remarksRichText = asWqDom(fields.remarks);
  // hack to ease entry

  const questItems = (fields.questItems || [])
    .map((q, i) =>
      asQuest(q, {
        isReview: !!fields[`isReviewQuestItem${i}`]
      })
    )
    .filter(Boolean);

  return {
    ...asLessonPartial({ sys, fields }),
    material,
    questItems,
    remarksRichText
  };
};

export const asCourse = (
  item,
  options: { isCourseIntroPartial: boolean } = { isCourseIntroPartial: true }
) => {
  const { isCourseIntroPartial } = options;
  const { sys, fields } = item;
  if (!fields) {
    return;
  }
  const {
    key,
    image,
    bookingUrl,
    targetLocale,
    isHidden,
    paperformSlug,
    embededKeys = '',
    publishedAt,
    authorizedSegments = [],
    startAt,
    endAt,
    originalPrice,
    offerPrice,
    courseTypes = [],
    challengeDateDescription,
    teachers = [],
    teacherAssistants = [],
    coursePlans = []
  } = fields;
  const heroImageUrl = _.get(image, 'fields.file.url', '');

  // const heroImageMeta = {};
  // we need source specific e.g. contentful title,description, srcSets
  const courseSections = _.map(fields.courseSections, (courseSection) => {
    const { sys, fields } = courseSection;
    if (!fields || _.isEmpty(fields.courseLessons)) {
      return;
    }
    const { key, title, courseLessons, isHidden } = fields;

    // material title are not available since we take 2 layers
    // insufficient data, just take the lesson id
    return {
      title,
      key,
      embededKeys,
      paperformSlug,
      isHidden: !!isHidden,
      ..._.omit(fields, 'courseLessons'),
      // TODO refactor, not the same as linked lesson
      lessons: courseLessons
        .filter((courseLesson) => !!_.get(courseLesson, 'fields'))
        .map(asLessonPartial)
    };
  }).filter(Boolean);

  const tags = asTags(fields.tags);

  const courseIntroId = _.get(fields.courseIntro, 'sys.id');

  return Course.create({
    id: sys.id,
    key,
    tags,
    ..._.omit(fields, 'key', 'image', 'tags', 'courseSections', 'courseIntro'),
    courseIntro:
      isCourseIntroPartial || _.isEmpty(fields.courseIntro)
        ? {
            id: courseIntroId
          }
        : asCourseIntro(fields.courseIntro),
    courseTypes,
    bookingUrl,
    targetLocale,
    coursePlans: coursePlans.map(asCoursePlan),
    startAt: startAt ? parseISO(startAt) : null,
    endAt: endAt ? parseISO(endAt) : null,
    updatedAt: sys.updatedAt ? parseISO(sys.updatedAt) : new Date(),
    // TODO migrate test cases
    descriptionShort: fields.descriptionShort || '',
    challengeDateDescription: fields.challengeDateDescription || '',
    descriptionRichText: asWqDom(fields.descriptionRich),
    ctaDescriptionRichText: asWqDom(fields.ctaDescriptionRichText),
    publishedAt: parseISO(publishedAt || '2020-01-01T11:23:09.078Z'),
    teachers: teachers.map(asAuthor),
    teacherAssistants: teacherAssistants.map(asAuthor),
    isHidden: !!isHidden,
    heroImageUrl,
    authorizedSegments,
    courseSections
  });
};

export const queryCourseWithLessonId = async (id) => {
  const courseSectionRes = await loadContentfulClient().getEntries({
    'fields.courseLessons.sys.id': id,
    content_type: CfTypes.CourseSection,
    include: 1,
    limit: 1
  });
  const courseSection = _.first(courseSectionRes.items);

  if (!courseSection) {
    logger.debug('queryCourseWithLessonId courseSection fail', id);

    return;
  }
  const courseRes = await loadContentfulClient().getEntries({
    'fields.courseSections.sys.id': _.get(courseSection, 'sys.id'),
    content_type: CfTypes.Course,
    include: 3,
    limit: 1
  });
  const courseItem = _.first(courseRes.items);
  if (!courseItem) {
    logger.debug(
      'queryCourseWithLessonId courseItem fail',
      _.get(courseSection, 'sys.id')
    );

    return;
  }

  // if we use  we need include 3
  return asCourse(_.first(courseRes.items), { isCourseIntroPartial: false });
};
