import _ from 'lodash';
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { StyledFlippedIcon } from '~/util/icon';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

const StyledScrollableDrawer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* need to make it work when collapsed */
  .MuiListItemText-root {
    white-space: ${(props) =>
      props.isSidebarOpen ? 'normal !important' : 'pre !important'};
  }
`;

// never work well with mobile esp hidden
const StyledSidebarHeader = styled(Box)`
  min-height: 2.5rem;
  .MuiBox-root .MuiButtonBase-root {
    padding: 0.5rem;
  }
`;

export const SidebarContent = ({
  isSidebarOpen,
  isSidebarLeft,
  toggleSidebar,
  toggleSidebarPosition,
  children
}) => {
  const dotoggleSidebar = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    toggleSidebar(!isSidebarOpen);
  };

  // const sidebarPositionButton = toggleSidebarPosition && (!isSidebarLeft || isSidebarOpen) && (
  //   <Box>
  //     <IconButton onClick={() => toggleSidebarPosition(isSidebarLeft ? 'bottom' : 'left')}>
  //       <StyledFlippedIcon>
  //         <VerticalSplitIcon />
  //       </StyledFlippedIcon>
  //     </IconButton>
  //   </Box>
  // );

  // stick with same current icon until we got chrome like svg
  // if we pull to left
  // flexDirection: isSidebarLeft ? 'row' : 'row-reverse'

  return (
    <StyledScrollableDrawer isSidebarOpen={isSidebarOpen}>
      <StyledSidebarHeader display="flex" justifyContent="spaced-between">
        <Box alignItems="center" flexGrow={1} margin="auto">
          {isSidebarOpen && <ListItem>&nbsp;</ListItem>}
        </Box>
        {toggleSidebar && (
          <Box>
            <IconButton onClick={dotoggleSidebar}>
              {isSidebarOpen ? (
                <MenuOpenIcon />
              ) : (
                <StyledFlippedIcon>
                  <MenuOpenIcon />
                </StyledFlippedIcon>
              )}
            </IconButton>
          </Box>
        )}
      </StyledSidebarHeader>
      <Divider />
      <Box style={{ overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    </StyledScrollableDrawer>
  );
};

export default ({
  isSidebarOpen,
  isSidebarLeft,
  toggleSidebar,
  toggleSidebarPosition,
  children
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen
        })
      }}
      style={{
        overflowY: 'hidden'
      }}
      open={isSidebarOpen}
    >
      <SidebarContent
        isSidebarLeft={isSidebarLeft}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        // toggleSidebarPosition={toggleSidebarPosition}
      >
        {children}
      </SidebarContent>
    </Drawer>
  );
};
