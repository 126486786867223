import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CourseCard from '~/course/course-card';
import { getTagI18nKeyWithLanguage } from '@wordquest/locales';
import { withRouterLink } from '~/with-link';

export const getCourseTitleWithGroupKey = (type) => (groupKey) => {
  const groupI18nKey = getTagI18nKeyWithLanguage(
    groupKey.replace(`${type}-`, '')
  );

  return (
    <>
      <FormattedMessage id={groupI18nKey} />
      {' - '}
      <FormattedMessage id={`common.course.type.${type}`} />
    </>
  );
};

export const asOrderedCardsByGroup = ({ coursesByGroup, type }) =>
  _.mapValues(coursesByGroup, (courses, key) =>
    _.map(_.take(courses, 8), (course) => {
      const isCoach = type === 'coach';

      return (
        <CourseCard
          key={`course-card-${course.key}`}
          isShowTitle={!isCoach}
          isShowTeacherTag={!isCoach}
          isShowDescription={!isCoach}
          isShowTeacherDetails={isCoach}
          withLink={(props) =>
            withRouterLink({
              href: `/course/${props.courseKey}`
            })
          }
          course={course}
        />
      );
    })
  );
