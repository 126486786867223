import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { observer, action, inject } from 'mobx-react';
import { findUserDifficultyColor } from '@wordquest/lib-iso/domain/wordquest/ql/difficulty-scale';
import WordCard from './word-card';
import WordBar from './word-bar';
// TODO organize structure for ui helpers

const WordCardContainer = (props) => {
  const LOCALE = 'zh-TW';
  const {
    word,
    rootStore,
    isShowDefinitions,
    isShowTranslations,
    isShowExperience,
    type,
    onClickCardContent,
    trackEvent
  } = props;

  const toggleWordInDeck = (word, isInDeck = true) => {
    trackEvent({ event: 'Word-ToggledInDeck', properties: { word, isInDeck } });
  };

  let dictionaryDefinitions = [];
  let translation = '';
  let experience = 0;
  let isInDeck = false;
  let userDifficultyColor = 'primary';

  if (word) {
    const definitions = rootStore.wordStore.definitionsById.get(word.id) || [];
    if (isShowDefinitions) {
      dictionaryDefinitions = _.filter(
        definitions,
        (d) => d.source !== 'google-translate'
      );
    }
    if (isShowTranslations) {
      translation = (_.find(definitions, { source: 'google-translate' }) || {})
        .gloss;
    }
    if (isShowExperience) {
      experience = rootStore.userStore.experienceByWord;
    }

    userDifficultyColor = findUserDifficultyColor(
      rootStore.userStore.user.profile.difficultyTarget,
      word.getDifficultyLevel()
    );
    // TODO refactor with better nested path access
    const wordAgg = rootStore.userStore.wordUserEventAggregateByKey;
    isInDeck = rootStore.userStore.checkIsInDeckWithWordId(word.id);
  }

  if (type === 'bar') {
    return (
      <WordBar
        key={word.id}
        word={word}
        userDifficultyColor={userDifficultyColor}
        definitions={dictionaryDefinitions}
        translation={translation}
        onClickCardContent={onClickCardContent}
        toggleIsWordInDeck={() => toggleWordInDeck(word, !isInDeck)}
        isInDeck={isInDeck}
      />
    );
  }

  return (
    <WordCard
      key={word.id}
      word={word}
      definitions={dictionaryDefinitions}
      translation={translation}
      onClickCardContent={onClickCardContent}
      toggleIsWordInDeck={() => toggleWordInDeck(word, !isInDeck)}
      isInDeck={isInDeck}
    />
  );
};

export default inject('rootStore')(observer(WordCardContainer));
