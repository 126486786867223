import _ from 'lodash';
import * as Contentful from '@contentful/rich-text-types';

export type ContentfulNode =
  | Contentful.Block
  | Contentful.Inline
  | Contentful.Text;

export type ContentfulNonTextNodes = Contentful.Block | Contentful.Inline;

export const DEFAULT_SCHEMA: SchemaJSON = {
  blocks: { [Contentful.BLOCKS.EMBEDDED_ENTRY]: { isVoid: true } }
};

/**
 * SlateJS Schema definition v0.33.x
 *
 * @export
 * @interface SchemaJSON
 */
export interface SchemaJSON {
  blocks?: Record<string, SchemaValue>;
  inlines?: Record<string, SchemaValue>;
}

export interface Schema extends SchemaJSON {
  isVoid(node: ContentfulNonTextNodes): boolean;
}

export interface SchemaValue {
  isVoid?: boolean;
  // tslint:disable-next-line:no-any
  [k: string]: any;
}

/**
 * Creates an instance of Schema from json.
 *
 * @export
 * @param {SchemaJSON} [schema=defaultSchema]
 * @returns {Schema}
 */
export function fromJSON(schema: SchemaJSON = DEFAULT_SCHEMA): Schema {
  return {
    /**
     * Check if a `node` is void based on the schema rules.
     *
     * @param {ContentfulNonTextNodes} node
     * @returns
     */
    isVoid(node: ContentfulNonTextNodes) {
      const root = Object.values(Contentful.BLOCKS).includes(node.nodeType)
        ? 'blocks'
        : 'inlines';

      return _.get(schema, [root, node.nodeType as string, 'isVoid'], false);
    }
  };
}
