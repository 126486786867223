import _ from 'lodash';
import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';

// 2 ways
// 1)css to hide extra content (seems easier with RichTextContent)
// 2)separate visible vs hidden content
// For 1) require a clear cut on content
// For 2), use Collapse for animation
// Demo at https://material-ui.com/components/cards/

// vs expandable panel (Material ui)
// = always show partial content
// common usage = only hide if content exceed certain length.
// defaultIsExpanded should factor in this
// or we handle texts excerpt, with css  too

const StyledExpandableContent = styled.div`
  max-height: ${(props) => (props.isExpanded ? '100%' : props.maxHeight)};
  ${(props) =>
    props.isExpanded
      ? ''
      : '-webkit-mask-image: linear-gradient(#fff,#fff,rgba(255,255,255,0))'};
  overflow-y: hidden;
  mask-position: bottom;
  .MuiCollapse-entered {
    min-height: 100% !important;
  }
`;

const StyledExpandIcon = styled.div`
  /* transform: ${(props) =>
    props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}; */
`;

export default (props) => {
  const {
    isDefaultExpanded = false,
    isCollapseSupported = false,
    maxHeight = '15rem',
    minHeight = '5rem',
    onClickExpand,
    children
  } = props;
  const [isExpanded, setIsExpanded] = React.useState(!!isDefaultExpanded);
  const doOnClickExpand = () => {
    if (_.isFunction(onClickExpand)) {
      onClickExpand();
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <StyledExpandableContent
        maxHeight={maxHeight}
        isExpanded={isExpanded}
        data-testid="expandable-content"
      >
        <Collapse in={isExpanded} collapsedHeight={maxHeight} timeout={1}>
          {children}
        </Collapse>
      </StyledExpandableContent>
      {(!isExpanded || isCollapseSupported) && (
        <Button onClick={doOnClickExpand} color="primary">
          {isExpanded ? (
            <FormattedMessage id="common.collapse" />
          ) : (
            <FormattedMessage id="common.show-more" />
          )}
          <StyledExpandIcon isExpanded={isExpanded}>
            <ExpandMoreIcon />
          </StyledExpandIcon>
        </Button>
      )}
    </div>
  );
};
