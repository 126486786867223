// Alternative implementation at react-player
// https://github.com/CookPete/react-player/blob/e4450bc1873d14ffaed359c2dede2828c57e4a84/src/players/YouTube.js#L9
import _ from 'lodash';
import videoUrlParser from 'js-video-url-parser/lib/base';
import 'js-video-url-parser/lib/provider/youtube';
import 'js-video-url-parser/lib/provider/vimeo';
import Video, { VideoPlatform } from '~/domain/wordquest/video/video';
import { createVideoId } from '~/app/video/video-repo';
import logger from '~/app/logger';
// interface for potenitally switch to our own implementation
export const parseVideoUrl = (url) => videoUrlParser.parse(url);

export const parseVideoOrPodcastUrl = (url, isPodcast = false, record = {}) => {
  if (isPodcast) {
    // non-soundcloud
    // TODO parse podcast apple url etc
    const episodeKey = record['listennotes_id_episode'];

    const rss = record['podcast_rss_url'];
    logger.debug('parseVideoOrPodcastUrl', record);

    return {
      id: episodeKey ? `n${episodeKey}` : `i${record.itunesId}`,
      mediaType: 'podcast',
      provider: 'podcast',
      platformMeta: {
        ..._.pick(record, ['itunesId', 'audioUrl', 'rss']),
        rss
      }
    };
  }

  return parseVideoUrl(url);
};

export const mapVideoInfoAsVideo = (videoInfo): Video => {
  const { id, mediaType, provider: platform, platformMeta = {} } = videoInfo;

  return Video.create({
    id: createVideoId(platform, id),
    platform,
    type: mediaType,
    videoPlatformMeta: {
      [platform as VideoPlatform]: {
        id,
        ...platformMeta
      }
    }
  });
};

// always use long as others not always supported
// https://github.com/Zod-/jsVideoUrlParser#creating-urls-with-different-media-types
export const createVideoUrl = (meta) =>
  videoUrlParser.create(
    _.defaultsDeep(meta, {
      videoInfo: {
        mediaType: 'video'
      },
      format: 'long'
    })
  );
