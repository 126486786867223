import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mapLevelAsProficiencyOptionIndex } from '@wordquest/lib-iso/domain/wordquest/ql/difficulty-scale';
import logger from '@wordquest/lib-iso/app/logger';
import { mediaMui } from '~/style-utils';
import { Locale } from '@wordquest/locales';
import Box from '@material-ui/core/Box';
import { observer, MobXProviderContext } from 'mobx-react';
import Container from '@material-ui/core/Container';
import { formatDateFns } from '@wordquest/lib-iso/intl';

// here instead of root as need by others
export const useMobxProviderStores = () =>
  React.useContext(MobXProviderContext);

export const _redirectNotFound = (res) => {
  if (res && res.writeHead) {
    res.writeHead(302, {
      Location: '/not-found'
    });
    res.end();
    res.finished = true;
  }

  return {};
};

export const appendScriptInBody = ({
  src,
  delayInS = 0,
  onload,
  id,
  charset
}: {
  src: string;
  charset?: string;
  delayInS?: number;
  onload?: (any) => any;
  id?: string;
}) => {
  setTimeout(() => {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.setAttribute('src', src);
      if (onload) {
        script.onload = () => onload();
      }
      script.charset = charset || 'utf-8';
      if (id) {
        script.setAttribute('id', id);
      }

      document.body.appendChild(script);

      logger.debug('mount appendScriptInBody', src, id);
    }
  }, delayInS);
};

export const MountOnce = ({ fn }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (count < 1) {
      fn();
      setCount(count + 1);
    }
  });

  return <span />;
};

/**
 * deprecated
 * interface for loading external scripts async accroding to config
 */
// const AsyncScriptLoader = props => (
// /* eslint-disable */
// // @ts-ignore
//   <div>
// {
//   (function(d, s, id){
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) {return;}
//   js = d.createElement(s); js.id = id;
//   // @ts-ignore
//   js.src = props.scriptUrl;
//   js.onload = props.onload;
//   // @ts-ignore
//   fjs.parentNode.insertBefore(js, fjs);
// }(document, 'script', props.id))
// }
// </div>
// )

// https://help.paperform.co/en/articles/2072974-what-is-pre-filling-and-how-does-it-work
// assume proficiemcy order
export const createPaperformProps = (profile) => {
  if (!_.get(profile, 'id')) {
    return {};
  }

  const fieldsToPrefill = {
    'profile-id': profile.id,
    'profile-name': profile.name || '',
    'profile-email': profile.email || ''
  };

  _.map(profile.proficiencyByLocale || {}, (proficiency, locale) => {
    const proficiencyIndex = mapLevelAsProficiencyOptionIndex(
      locale as Locale,
      proficiency
    );
    if (proficiencyIndex > -1) {
      fieldsToPrefill[
        `profile-proficiency-${locale}`
      ] = `--${proficiencyIndex}--`;
    }
  });

  return fieldsToPrefill;
};

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

const StyledDebugDataContainer = styled.div`
  background-color: green;
  color: white;
  ${mediaMui.xs} {
    max-width: 5rem;
  }
  ${mediaMui.lg} {
    max-width: 60rem;
  }

  z-index: 900;
  word-break: break-all;
`;

// the only one in App with padding and avoid CLS problem
// https://web.dev/cls/?utm_source=devtools
export const StyledMainContainer = styled(Container)`
  min-height: 80vh;
`;

export const DebugOnly = observer(
  ({ data, children }: { data: any; children?: any }) => {
    const { rootStore } = useMobxProviderStores();
    const { isDebug, isDebugWithPrintedData } = rootStore.uiStateStore.uiConfig;

    return (
      isDebug &&
      isDebugWithPrintedData && (
        <StyledDebugDataContainer>
          {JSON.stringify(data)}
          {children}
        </StyledDebugDataContainer>
      )
    );
  }
);

// hidden but rendered
// for uptime monitoring and lighthouse etc
export const WaterMark = React.memo(({ markKey }) => {
  const markString = ['perapera', 'ready', markKey].join('-');

  return <Box display="none">{markString}</Box>;
});
