import _ from 'lodash';
import React, { useMemo } from 'react';
import { asCourseOutlineRichText } from '@wordquest/lib-iso/app/course/course-cf';
// input has computed effective state for lesson already
import ProgressList from '~/list/progress-list';
import { withRouterLink } from '~/with-link';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import logger from '@wordquest/lib-iso/app/logger';
import isEqual from 'react-fast-compare';
// We don't further decouple simple List use case vs course specific for now
// as not hard to reproduce with similar functions

// client could use createTransformer to memoize enriching particular lesson attributes
// we use richText as not to reinvent another data structure, but this should be limited to these kind of genric types (like list)
// pull out logic to benefit from client side caching & memoizeation
// we need to ensure attributeByKey is deeply compared (not ref)
// alternatively we can use mobx to ensure. perf loss convert back and forth
export default React.memo<object>((props): JSX.Element => {
  const {
    isScrollIntoView = false,
    title,
    courseKey,
    courseSections,
    attributeByKey = {},
    linkRoot,
    withLink = withRouterLink,
    setCurrentItemRef,
    disableSticky = false,
    isExpanded = true
  } = props;
  logger.debug('mount outline', JSON.stringify(courseKey));

  const [isOutlineExpanded, setIsOutlineExpanded] = React.useState(
    isExpanded || _.size(courseSections) <= 3
  );
  // here assume 1-level nesting we skip the tree walk
  const outlineDom = useMemo(
    () =>
      asCourseOutlineRichText({
        title,
        key: courseKey,
        courseSections,
        expandedSectionsLimit: isOutlineExpanded ? 0 : 3
      }),
    [isOutlineExpanded, props, courseSections]
  );

  if (_.isEmpty(outlineDom)) {
    return <></>;
  }
  const ulNodes = _.flatMap(outlineDom.children, (n) => n.children).filter(
    (c) => c.type === 'ul'
  );
  const introNode = _.get(outlineDom, 'children.0');
  _.merge(introNode.attributes, attributeByKey[courseKey]);
  const lessonNodes = _.flatMap(ulNodes, (n) => n.children);

  _.forEach(lessonNodes, (lessonNode) => {
    const { key } = lessonNode.attributes;
    _.merge(lessonNode.attributes, attributeByKey[key]);
  });

  // TODO seems not updated

  return (
    <>
      <ProgressList
        isScrollIntoView={isScrollIntoView}
        setCurrentItemRef={setCurrentItemRef}
        progressListDom={outlineDom}
        disableSticky={disableSticky}
        linkRoot={linkRoot}
        withLink={withLink}
      />
      {!isOutlineExpanded && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            onClick={() => {
              setIsOutlineExpanded(true);
            }}
            variant="contained"
            type="button"
            color="primary"
          >
            <ArrowDropDown />
            <FormattedMessage id="common.course.outline.expand" />
          </Button>
        </Box>
      )}
    </>
  );
}, isEqual);
