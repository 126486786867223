import _ from 'lodash';
import { Locale } from '@wordquest/locales';

export default class Glossary {
  constructor(
    public id: string,
    public title: string,
    public detailsLocale: Locale,
    public description: string,
    public detailsHtml: string,
    public audioUrl: string,
    public audioGuide: string
  ) {}

  static create(obj: object): Glossary {
    const {
      id,
      title,
      detailsLocale,
      description,
      detailsHtml,
      audioUrl,
      audioGuide
    } = obj;

    return _.defaultsDeep(
      new Glossary(
        id,
        title,
        detailsLocale,
        description,
        detailsHtml,
        audioUrl,
        audioGuide
      ),
      obj
    );
  }
}
