import _ from 'lodash';
import pino from 'pino';
import { IS_NODE, IS_DEV, IS_CF } from '~/env';
import safeStringify from 'fast-safe-stringify';

const asErrorContext = (err: Error, log) => {
  const baseErrorConext = {
    error: {
      // TODO support using code for kind if there is one from error
      kind: 'APPLICATION_ERROR',
      message: err.message,
      stack: err.stack
    }
  };
  // if there is error with it
  if (log?.evt) {
    return {
      ...log,
      ...baseErrorConext,
      evt: {
        ...log?.evt,
        outcome: 'failure'
      }
    };
  }

  return { ...log, ...baseErrorConext };
};

// pino error context will be changed to datadog(err->error with kind)
export const withLogErrorContext = (log) => {
  // baseContext might carry the arg from earlier processor

  if (log instanceof Error) {
    return asErrorContext(log, log);
  }
  if (log?.err) {
    return asErrorContext(log?.err, log);
  }

  return log;
};

export const withAppEventContext = (log) => {
  const { event: appEvent, outcome = 'success' } = log || {};
  if (!appEvent?.event) {
    return log;
  }

  const { event, userId = '', properties = {} } = appEvent;

  const userProperties = properties['user'] || {};

  return {
    ..._.omit(log, 'event'),
    user: {
      id: userId,
      ...(userProperties as Record<string, unknown>)
    },
    evt: {
      name: event,
      userId,
      properties,
      outcome
    }
  };
};

export const withLogContext = (log) => {
  const context = _.flow(withAppEventContext, withLogErrorContext)(log);

  return { ..._.omit(log, ['event', 'err']), ...context };
};

// https://stackoverflow.com/questions/13815640/a-proper-wrapper-for-console-log-with-correct-line-number
const logConfig = {
  // minor perf hit figure out later
  formatters: {
    log(log) {
      return withLogContext(log);
    }
  },
  browser: {
    // once enabled, forced asObject
    // TODO more testing
    // write: {
    //   // chrome only
    //   info(o) {
    //     const log = global.window.console.log.bind(global.window.console);
    //     log('[info]', o.msg);
    //     // process info log object
    //   },
    //   debug(o) {
    //     const log = global.window.console.debug.bind(global.window.console);
    //     log('[debug]', o.msg);
    //   },
    //   error(o) {
    //     const log = global.window.console.error.bind(global.window.console);
    //     log(o);
    //     // process error log object
    //   },
    //   trace(o) {
    //     console.log(o);
    //   }
    // },
    // //   // logger.trace become console.trace which printed the trace
    // asObject: false
  },
  prettyPrint: IS_DEV,
  level: IS_NODE || IS_DEV ? process.env.LOG_LEVEL || 'info' : 'error'
};

console.log(
  `logger setup, config:${JSON.stringify(logConfig)} CF function: ${
    process.env.FUNCTION_TARGET
  } IS_DEV: ${IS_DEV} isCF: ${IS_CF}`
);

const SEVERITY_TAGS = [
  'info',
  'fatal',
  'critical',
  'warning',
  'debug',
  'error',
  'trace'
];
const logName = 'cloud-functions';
// Now we simply fallback & overload the pino logger API
// https://getpino.io/#/docs/api

// Knonwn issue of having pino as singleton
// https://github.com/pinojs/pino/issues/347#issuecomment-360057406

// for e.g. datadog spec which use evt.name etc
// https://docs.datadoghq.com/logs/log_configuration/attributes_naming_convention/#events

export const createLogger = (isCF) => {
  // WARNING! Node access. put here as no better way to overload from node module for now
  if (isCF) {
    console.log('createLogger isCF:', isCF);
    // TODO from level
    const consoleLogger = _.fromPairs(
      SEVERITY_TAGS.map((tag) => [
        tag,
        (...args) => {
          if (['trace'].includes(tag)) {
            return;
          }
          console.log(`[${tag}]`, ...args.map((arg) => safeStringify(arg)));
        }
      ])
    );
    // default webpack will show warning: require function is used in a way in which dependencies cannot be statically extracted
    // disabled by unknownContextCritical:false
    // @ts-ignore
    // const { createLoggerWithStackDriver } = (global.__non_webpack_require__ || require)('@wordquest/lib-node/app/logger-stackdriver'); //eslint-disable-line
    // return createLoggerWithStackDriver(logConfig);

    // never get Logging working, fallback to console log first
    return _.merge(pino(logConfig), consoleLogger, {
      child: () => consoleLogger
    });
  }

  return pino(logConfig);
};

export default createLogger(IS_CF);
