import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({ minHeight = '80vh' }) => (
  <Grid container justify="center" alignItems="center" style={{ minHeight }}>
    <Grid item container justify="center" xs={12} sm={12}>
      <CircularProgress />
    </Grid>
  </Grid>
);
