import _ from 'lodash';
import { observable } from 'mobx';
import {
  queryArticles,
  queryArticleGroupedByCategory
} from '@wordquest/lib-iso/app/article/article-repo';
import Article, {
  AnalyzedArticle
} from '@wordquest/lib-iso/domain/wordquest/article';
// import logger from '@wordquest/lib-iso/app/logger';
import { loadWithIdsWithMobxCache } from '~/services/mobx-cache';
import { from } from 'rxjs';
import { Locale } from '@wordquest/locales';
import RootStore from '~/stores/root';

export default class ArticleStore {
  articleById = observable.map({}, { deep: false });

  constructor(private rootStore: RootStore) {
    // shallow as we will do custom observer
    // don't watch deep tokenInfoByToken for perf reason
    // https://mobx.js.org/refguide/modifiers.html

    const {
      uiStateStore: { uiConfig }
    } = rootStore;

    this.mergeArticleById = (articleById: Record<string, AnalyzedArticle>) => {
      this.articleById.merge(articleById);
      // we might consider use decorator for this, but no longer Article class instance {
      //   tokenInfoByToken: observable.ref
      // }
    };

    this.loadArticleRecommended = async () => {
      let _doLoadArticlesByCategory = async () =>
        queryArticleGroupedByCategory(Locale.JA, {
          fields: {
            isWithTokenInfoByToken: true
          }
        });

      if (uiConfig.mock.article) {
        _doLoadArticlesByCategory = async () =>
          import('~/debug-mock')
            .then((m) => m.loadArticleByIdMock())
            .then((articleById) => _.groupBy(articleById, 'category')); // eslint-disable-line
      }
      const articlesByCategory = await _doLoadArticlesByCategory();

      this.mergeArticleById(
        _.keyBy(_.flatten(_.values(articlesByCategory)), 'id')
      );

      return articlesByCategory;
    };

    this.loadArticleRecommendedWithCurrentArticle = async (article) =>
      queryArticles(Locale.JA, {
        similarArticle: _.pick(article, ['category', 'sourceName', 'id']),
        paging: {
          size: 10
        }
      });

    this.loadArticleWithIds = async (
      articleIds: string[]
    ): Promise<Record<string, Article>> => {
      const loadArticleById = (ids: string[]) =>
        from(
          queryArticles(Locale.JA, {
            fields: {
              isWithTokenInfoByToken: true
            },
            filter: {
              includeIds: ids
            }
          }).then((articles) => _.keyBy(articles, 'id'))
        );
      // Edge case here: we need manual mobx merge so disable auto cache merge
      let _doLoadArticlesWithIds = async (articleIds: string[]) =>
        loadWithIdsWithMobxCache<Article>(
          articleIds,
          loadArticleById,
          this.articleById,
          false
        ).toPromise();

      if (uiConfig.mock.article) {
        _doLoadArticlesWithIds = async () =>
          import('~/debug-mock').then((m) => m.loadArticleByIdMock());
      }
      const articleById = await _doLoadArticlesWithIds(articleIds);
      this.mergeArticleById(articleById);

      return articleById;
    };
  }
}
