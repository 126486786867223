import _ from 'lodash';
import {
  YoutubeLocale,
  Locale,
  YoutubeDefaultLocale
} from '@wordquest/locales';
import { VideoPlatform } from '~/domain/wordquest/video/video';

export const mapYoutubeLocaleAsLocale = (ytLocale: YoutubeLocale) => {
  // TODO proper similarity resolutions
  if (!_.includes([YoutubeLocale.ZH_TW, YoutubeLocale.ZH_CN], ytLocale)) {
    return (ytLocale || '').substring(0, 2);
  }

  return ytLocale;
};

const localeByYtDefaultLocale = _.invert(YoutubeDefaultLocale);
const localeKeyByLocale = _.invert(Locale);

// example "youtube-i2I84411Mzw",
// "defaultLanguage": "zh-HK",
// "defaultAudioLanguage": "yue-HK",
// TODO use resolution for en, zh etc
const ytExtraMapping = {
  'en-GB': Locale.EN,
  'en-US': Locale.EN,
  'en-CA': Locale.EN,
  'en-AU': Locale.EN,
  yue: Locale.YUE,
  'zh-HK': Locale.YUE,
  'zh-TW': Locale.ZH_TW,
  zh: Locale.ZH_CN,
  'zh-CN': Locale.ZH_CN
};
const invertYtExtraMapping = _.invertBy(ytExtraMapping);
invertYtExtraMapping[Locale.ZH_CN] = invertYtExtraMapping[Locale.ZH_CN].concat([
  Locale.ZH_TW,
  'zh',
  'zh-Hant'
]);
invertYtExtraMapping[Locale.ZH_TW] = invertYtExtraMapping[Locale.ZH_TW].concat([
  Locale.ZH_CN,
  'zh',
  'zh-Hans'
]);
export const mapYtDefaultLocaleAsLocale = (
  ytDefaultLocale: YoutubeDefaultLocale
) =>
  ytExtraMapping[ytDefaultLocale] ||
  Locale[localeByYtDefaultLocale[ytDefaultLocale]];

// locale used is not YoutubeLocale & undocumented
// defaultLanguage
// defaultAudioLanguage
export const mapLocaleAsYtDefaultLocale = (locale: Locale) =>
  YoutubeDefaultLocale[localeKeyByLocale[locale]] || locale;

export const createPotentialYtDefaultLocales = (locales: Locale[]) =>
  locales
    .map(mapLocaleAsYtDefaultLocale)
    .concat(_.flatMap(locales, (l) => invertYtExtraMapping[l]))
    .filter(Boolean);
