import _ from 'lodash';
import bodybuilder from 'bodybuilder';
import { format } from 'date-fns';
import Course from '~/domain/wordquest/course/course';
import {
  EsQuery,
  search,
  msearch,
  indexDocBulk,
  upsertDocBulk
} from '~/adapters/elasticsearch';
import { Entity, DataAction } from '~/domain/wordquest/entity';
import {
  ES_INDEX_BY_ENTITY_LOCALE,
  ES_CONFIG
} from '~/app/elasticsearch.config';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import {
  BaseQueryContext,
  withPaging,
  withSort,
  withIncludeIdsFilter,
  withTermsFilter
} from '~/app/repo-es-util';
import rootLogger from '~/app/logger';
import {
  createDataEvent,
  QuoteUpsertedDataEvent
} from '~/domain/wordquest/event/data-event';
import { asQuote } from '~/app/quote/quote-cf';
import Quote from '~/domain/wordquest/quote/quote';

const logger = rootLogger.child({ module: 'course-repo' });

export const ES_MAPPING_QUOTE_BY_LOCALE = _.fromPairs(
  SUPPORTED_LOCALES.map((sourceLocale) => [
    sourceLocale,
    {
      properties: {
        quote: {
          properties: {
            authorNote: {
              descriptionRichText: {
                enabled: false
              }
            },
            // id: string
            // translationByLocale: {
            // },
            explanationRichTextByLocale: {
              enabled: false
            },
            indexedAt: {
              type: 'date'
            },
            video: {
              type: 'keyword'
            }
          }
        }
      }
    }
  ])
);

// TODO
type QuoteQueryContext = BaseQueryContext &
  Quote & {
    video: {
      id: string;
    };
  };

export const docAsQuote = ({ _id, _source: { quote }, fields }) => quote;

export const ES_INDEX_BY_LOCALE = ES_INDEX_BY_ENTITY_LOCALE[Entity.Quote];

export const mapQuoteAsDoc = (_quote: Quote): QuoteEsDoc => {
  const quote = _.toPlainObject(_quote);

  return {
    id: quote.id,
    quote
  };
};

export const buildQuoteQuery = (context) => {
  let body = bodybuilder();

  // use same size as ids
  body = withPaging(
    _.defaultsDeep(context, {
      paging: {
        page: 0,
        size: 50
      }
    })
  )(body);

  body = withIncludeIdsFilter(context)(body);

  const videoId = _.get(context, 'filter.quote.video.id');

  // always filter for now
  // we should have better es util for not filter
  body = body.notFilter('match', 'isHiddenInVideo', true);

  // TODO should include youtube-
  if (videoId) {
    body = body.filter('match_phrase', 'quote.video.id', videoId);
  }

  return body.build();
};

export const queryQuotesWithLocaleContext = async (
  locale: Locale,
  context: QuoteQueryContext
) => {
  const query = buildQuoteQuery(context);
  logger.debug('queryQuotesWithLocaleContext query & context', query, context);

  return search(
    ES_CONFIG.APP_CONTENT,
    ES_INDEX_BY_ENTITY_LOCALE[Entity.Quote][locale],
    query
  ).then((res) => res.hits.hits.map(docAsQuote));
};

export const upsertQuotesWithLocale =
  (locale: Locale) => (events: QuoteUpsertedDataEvent[]) =>
    upsertDocBulk(
      ES_CONFIG.APP_CONTENT_ADMIN,
      ES_INDEX_BY_LOCALE[locale],
      events.map(
        (event) => ({
          id: event.properties.quote.id,
          doc: mapQuoteAsDoc(event.properties.quote)
        }),
        {
          timeout: '4m'
        }
      )
    );
