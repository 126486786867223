import { Locale } from '@wordquest/locales';

// 1:M
// duplicated those "common fields" e.g. sync time,
// https://developers.google.com/youtube/v3/docs/captions#snippet.videoId

// videoId
// language: Locale

// original  vs translated
// editor reviewed vs not

// tags
// source

// https://developers.google.com/youtube/v3/docs/captions/download
// Decouple meta & actual caption to Support Progressive loading
// the name Entry for easier distinction of 1 subtitles vs many subtitles
export type VideoSubtitlesEntry = {
  locale: Locale;
  format: 'vtt' | 'srt';
  raw: string;
  tagged: string;
  lastEditedBy: string;
  lastEditedAt: string;
};

// Actual downloaded format varies, e.g. youtube use a binary streams of specified format

// stream itself
// also cached window (or player has such cache)
// take care cache

export const cleanLinebreaks = (s) => (s || '').replace(/(\r|\n)/g, ' ');
