import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import Divider from '@material-ui/core/Divider';
import logger from '@wordquest/lib-iso/app/logger';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import LockIcon from '@material-ui/icons/Lock';
import { withRouterLink } from '~/with-link';
import { WqNodeTypeBlock } from '@wordquest/lib-iso/domain/wordquest/dom';
import RichText from '~/text/rich-text';
import {
  RichTextContainer,
  createRenderElement
} from '~/text/rich-text-container';
import Chip from '@material-ui/core/Chip';
// List based on material ui with optional navigation, progress / lock
// useful as table of content / as sidebar
const StyledItemText = styled.div`
  > div {
    padding-right: 1.5rem;
  }
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  .MuiChip-root {
    margin: 0.2rem;
  }
`;

// reference impl https://github.com/storybookjs/storybook/blob/next/lib/ui/src/components/sidebar/Menu.tsx
const StyledListItem = styled.div`
  & a {
    color: black;
    text-decoration: none;
    /* > div{
      overflow-wrap: anywhere;
    } */
  }
  .MuiListItemIcon-root {
    min-width: 2rem;
  }
  .MuiListItem-gutters {
    padding-left: 12px;
    padding-right: 6px;
  }
  .MuiListItemText-root {
    white-space: normal;
  }
`;

// as we got text-wrap
const StyledListSubheader = styled(ListSubheader)`
  padding-top: 0.5rem;
  line-height: 1.5rem;
`;

export const ListItemIconByStatus = React.memo(({ status }) => {
  if (status.isLocked === true) {
    return <LockIcon />;
  }
  if (!_.isUndefined(status.isChecked) && status.isChecked) {
    return <CheckCircleRoundedIcon />;
  }

  return <RadioButtonUncheckedRoundedIcon />;
});

// isHidden = already hidden
// both list item and the parent section list item
const ProgressListItem = React.memo(
  React.forwardRef((props, ref) => {
    const style = {};
    const {
      isScrollIntoView = false,
      attributes,
      children,
      linkRoot,
      withLink = withRouterLink,
      isDisableNestedGutters = true,
      isLinkDisabled = false,
      tags = []
    } = props;
    const { isCurrent, isLocked, isChecked, key } = attributes;

    // disable parent cause children disabled too
    useEffect(() => {
      if (isScrollIntoView && isCurrent) {
        // Update the document title using the browser API
        if (document.getElementById(key)) {
          document.getElementById(key).scrollIntoView(true);
        }
      }
    }, [isCurrent]);
    if (isCurrent) {
      style.color = 'white';
      style.backgroundColor = '#113285';
    }
    const withLinkWithRoot = (props, linkRoot) =>
      withLink({
        to: attributes.url || (key ? `${linkRoot}${key}` : ''),
        ...props
      });

    const isNestedTop =
      children && !_.get(children, 'props.node.children.0.text');
    const withLinkNotLocked =
      isLocked !== true && !isLinkDisabled
        ? withLinkWithRoot
        : () => _.identity;

    return (
      <StyledListItem ref={ref}>
        {withLinkNotLocked(
          {
            attributes
          },
          linkRoot
        )(
          <ListItem
            id={key}
            button={!isLocked}
            key={key}
            style={style}
            disableGutters={isNestedTop && isDisableNestedGutters}
          >
            {!isNestedTop && (
              <ListItemIcon style={style}>
                <ListItemIconByStatus status={attributes} />
              </ListItemIcon>
            )}
            <ListItemText key={`${key}-text`} disableTypography>
              <StyledItemText>
                <div>{children}</div>
                <div>
                  {tags.map((tag) => (
                    <Chip label={tag} color="primary" />
                  ))}
                </div>
              </StyledItemText>
            </ListItemText>
          </ListItem>
        )}
      </StyledListItem>
    );
  })
);

// Note here we ensure stable order
// TODO Support formatting the menu title, and handle case like ruby tags too

// hard to decouple richText with isLocked/hidden state

// allow external to scroll
export default React.memo((props) => {
  const {
    isScrollIntoView,
    progressListDom,
    linkRoot = '',
    withLink = withRouterLink,
    setCurrentItemRef = _.noop,
    disableSticky = false,
    showLinkLevel = 1
  } = props;
  const renderStrategyByNodeType = {
    [WqNodeTypeBlock.Hr]: (props, context) => <Divider />,
    [WqNodeTypeBlock.UlList]: (props, context) => {
      const { attributes, children } = props;

      return <List>{children}</List>;
    },
    [WqNodeTypeBlock.ListHeader]: (props, context) => {
      const { attributes = {}, children } = props;

      return (
        <StyledListSubheader
          key={attributes.key}
          style={{ backgroundColor: '#f2f4f8' }}
          disableGutters={false}
          disableSticky={disableSticky}
        >
          {children}
        </StyledListSubheader>
      );
    },
    [WqNodeTypeBlock.ListItem]: (props, context) => {
      const { attributes = {}, children } = props;
      if (attributes.isHidden) {
        return <></>;
      }
      const isParentLinkItem =
        _.isNumber(attributes.level) && attributes.level < showLinkLevel;
      // sectionKey not a good way to differentiate parent nested level
      // only for lesson, TODO should use nested instaed

      if (attributes.key && !isParentLinkItem) {
        const currentItemRef = useRef();
        if (attributes.isCurrent) {
          setCurrentItemRef(currentItemRef);
        }

        return (
          <ProgressListItem
            isScrollIntoView={isScrollIntoView}
            isLinkDisabled={isParentLinkItem}
            ref={currentItemRef}
            attributes={attributes}
            withLink={withLink}
            linkRoot={linkRoot}
            tags={attributes.tags}
          >
            {children}
          </ProgressListItem>
        );
      }

      return <>{children}</>;
    }
  };

  renderStrategyByNodeType[WqNodeTypeBlock.OlList] =
    renderStrategyByNodeType[WqNodeTypeBlock.UlList];

  // we will need to assign level before hand
  // do not flatten the list data structure but do so for render
  // we don't mark icon for CourseSection
  return (
    <div>
      <RichTextContainer wqDom={progressListDom}>
        <RichText
          renderElement={createRenderElement(renderStrategyByNodeType)}
        />
      </RichTextContainer>
    </div>
  );
});
