import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { mediaMui, isSizeUp } from '~/style-utils';
import SelectableTags from '~/tag/selectable-tags';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LanguageSelect from '~/select/languages';
import GoalSelect from '~/select/goals';
import ProficiencySelect from '~/select/proficiency';
import { createFilterMultiSelect } from '~/filters';
import { toJS } from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';
import isEqual from 'react-fast-compare'; //eslint-disable-line
import RootStore, { useRootStore } from '~/stores/root';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CoursePlans from '~/course/course-plans';
import useScrollPosition from '@react-hook/window-scroll';
import RichText from '~/text/rich-text';
import { RichTextContainer } from '~/text/rich-text-container';

const StyledOriginalPrice = styled.span`
  color: gray;
`;
const StyledOfferPrice = styled.span`
  font-style: bold;
  font-weight: 500;
`;

const StyledCTA = styled.div`
  > button {
    width: 100%;
  }
`;

// /* display: ${props => (props.isHidden ? 'none' : 'block')} */

const StyledSidebarContent = styled.div``;

export default (props) => {
  const { courseIntro, courseMeta, ctaButton, coursePlans, onClickCTA } = props;

  return (
    <StyledSidebarContent>
      <Card>
        {/*
          scrollY > 300 && (
            <CardMedia
              style={{
                height: '10rem'
              }}
              image={`https:${courseIntro.heroImageUrl}`}
              title={courseIntro.title}
            />
          )
          */}

        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            <StyledOfferPrice>{courseMeta.offerPrice}</StyledOfferPrice>{' '}
            <StyledOriginalPrice>
              <s>{courseMeta.originalPrice}</s>
            </StyledOriginalPrice>
          </Typography>

          {!_.isEmpty(courseMeta.ctaDescriptionRichText) && (
            <RichTextContainer wqDom={courseMeta.ctaDescriptionRichText}>
              <RichText />
            </RichTextContainer>
          )}
          {/* <Typography variant="body2" color="textSecondary" component="p">
                  some description
            </Typography> */}
          {ctaButton}
        </CardContent>

        {/* <CardActionArea>  <CardActions>
          <Button size="small" color="primary">
              Share
          </Button>
          <Button size="small" color="primary">
              Learn More
          </Button>
        </CardActions>  </CardActionArea> */}
      </Card>
      <CoursePlans
        coursePlans={coursePlans}
        onClickCTA={onClickCTA}
        isSidebar
      />
    </StyledSidebarContent>
  );
};
