import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
// import ExploreIcon from '@material-ui/icons/Explore';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotesIcon from '@material-ui/icons/Notes';
import { SITE_ROOT_UI_SITE, SITE_ROOT_UI_MAIN } from '@wordquest/lib-iso/env';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
// import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    zIndex: '1',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));

const StyledBottomNavigation = styled(BottomNavigation)`
  .MuiBottomNavigationAction-root {
  }
`;

// there are cases for not active e.g. jobs
export default ({ defaultTab = '', history }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultTab);

  // hrefs work by default
  const onChange = (event, newValue) => {
    setValue(newValue);
    // (history || global.window.history).push()
  };

  const ButtonWrapperWithLink = (href) =>
    React.forwardRef(({ className, children }, ref) => (
      <Link href={href} passHref>
        <a ref={ref} className={className}>
          {children}
        </a>
      </Link>
    ));

  // broken again after upgrade. use simple link instead

  // onChange & selected, showLabels etc worked only if BottomNavigationAction is immediate child
  return (
    <StyledBottomNavigation
      onChange={onChange}
      value={value}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        value="home"
        icon={<HomeIcon />}
        label={<FormattedMessage id="common.navigation.home" />}
        href={`${SITE_ROOT_UI_SITE}/`}
      />
      <BottomNavigationAction
        value="courses"
        label={<FormattedMessage id="landing.navigation.course" />}
        icon={<MenuBookIcon />}
        href={`${SITE_ROOT_UI_SITE}/courses`}
      />
      <BottomNavigationAction
        value="blog"
        label={<FormattedMessage id="common.navigation.blog" />}
        icon={<NotesIcon />}
        href={`${SITE_ROOT_UI_SITE}/posts`}
      />
    </StyledBottomNavigation>
  );
};
