import _ from 'lodash';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Video from '@wordquest/lib-iso/domain/wordquest/video/video';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Locale, SUPPORTED_LOCALES } from '@wordquest/locales';
import { mapYtDefaultLocaleAsLocale } from '@wordquest/lib-iso/adapters/youtube-locale';
import {
  getVideoYtTags,
  getVideoYtDefaultAudioLocale
} from '@wordquest/lib-iso/app/video/video-yt';
import Tags from '~/tag/tags';
import { pickEffectiveDisplayedLocale } from '@wordquest/lib-iso/domain/wordquest/video/video-subtitles-i18n-tag';

// getSubtitlesI18nTag not working as not refresh to mesage
// <FormattedMessage
//   id="placeholder"
//   values={{ text: getSubtitlesI18nTag(otherSubtitlesLocales, Locale.ZH_TW) }}
// />

const CardMediaOverlay = styled.div`
  background-color: #1e5397;
  color: white;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  padding: 0.2rem;
  position: absolute;
  margin: 0.8rem;
`;
const StyledCC = styled.span`
  background-color: whitesmoke;
  padding: 0em 0.5em;
  margin-left: 0.5em;
`;
const StyledTags = styled.div`
  font-size: 1em;
  div[class*='MuiChip-root'] {
    ${({ maxTagCount }) => `
      max-width: ${100 / maxTagCount - 5}%;
    `}
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const styles = (theme) => ({
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary
  },
  definitions: {
    marginBottom: 12
  },
  excerpt: {
    paddingLeft: 40
  },
  furigana: {
    minHeight: '1.5em'
  },
  cardTop: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row'
  },
  cardMeta: {
    // width: '100%',
    textAlign: 'right'
  },
  cardTitle: {
    minHeight: '9em',
    '& h1': {
      fontSize: '1em'
    }
  },
  cardContent: {
    backgroundColor: '#ffffff'
  },
  media: {
    // workaroun force recal
    // paddingTop: 'calc(20%)',
    // paddingLeft: 'calc(35%)'
    width: '100%',
    height: '250px',
    backgroundSize: 'cover'
  },
  cardLink: {
    textDecoration: 'none'
  }
});
const StyledVideoTitle = styled.h6`
  color: #000000;
  font-size: 1.3rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.5em 0;
  min-height: 6.2rem;
`;

const StyledCardContent = styled.div`
  overflow: hidden;
  font-size: 0.9rem;
  & h3 {
    color: gray;
  }
  .published-on {
    color: #00000075;
  }
  .video-desc {
    color: #00000075;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const VideoSubtitlesKeys = styled.div`
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

// pbm: import carrousel, sys link
const VideoCard = (props: {
  classes: object;
  video: Video;
  allVideoTags?: [];
  withLink: any;
  maxTagCount?: number;
  isShowSubtitlesTags?: boolean;
  isShowDescription?: boolean;
}) => {
  const {
    classes,
    video,
    allVideoTags = [],
    withLink,
    maxTagCount = 2,
    isShowSubtitlesTags = true,
    isShowDescription = true
  } = props;

  // override to use youtube before data fixed
  const sourceLocale =
    mapYtDefaultLocaleAsLocale(getVideoYtDefaultAudioLocale(video)) ||
    video.language;

  const otherSubtitlesLocales = _.intersection(
    _.difference(_.get(video, 'videoMeta.subtitlesLocales') || [], [Locale.EN]),
    SUPPORTED_LOCALES
  );
  const targetLocale = Locale.ZH_TW;
  const isShowLocaleTag =
    isShowSubtitlesTags && _.includes(otherSubtitlesLocales, targetLocale);

  // https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
  return withLink({
    videoId: video.id,
    className: classes.cardLink
  })(
    <CardActionArea>
      <Card className={classes.card}>
        <div className={classes.cardTop}>
          <CardMedia
            className={classes.media}
            image={video.thumbnailUrlSafe}
            title={`${video.title}`}
          />
          {isShowLocaleTag && (
            <CardMediaOverlay>
              <FormattedMessage
                id="common.language.subtitles-tag"
                values={{
                  locales: (
                    <FormattedMessage
                      id={`common.language.${pickEffectiveDisplayedLocale(
                        targetLocale,
                        targetLocale
                      )}`}
                    />
                  )
                }}
              />
            </CardMediaOverlay>
          )}
        </div>
        <div className={classes.cardTitle}>
          <CardContent className={classes.cardContent}>
            <StyledCardContent>
              <StyledVideoTitle>{video.title}</StyledVideoTitle>
              {isShowSubtitlesTags && (
                <VideoSubtitlesKeys>
                  {sourceLocale && (
                    <FormattedMessage id={`common.language.${sourceLocale}`} />
                  )}

                  {otherSubtitlesLocales.length > 0 ? (
                    <StyledCC>
                      <FormattedMessage id="video.subtitles" />
                    </StyledCC>
                  ) : (
                    ''
                  )}
                  {'  '}
                  {otherSubtitlesLocales.map((locale, i) => [
                    i > 0 && ', ',
                    <FormattedMessage
                      key={`video-subtitles-locale-${locale}`}
                      id={`common.language.native.short.${locale}`}
                    />
                  ])}
                </VideoSubtitlesKeys>
              )}
              {_.isDate(video.publishedAt) && (
                <div className="published-on">
                  <FormattedMessage id="video.published-on" />{' '}
                  <span>
                    <FormattedDate
                      value={video.publishedAt}
                      year="numeric"
                      month="numeric"
                      day="2-digit"
                    />
                  </span>
                </div>
              )}
              {isShowDescription && (
                <Typography gutterBottom variant="h5" component="h1">
                  <div className="video-desc">{video.description}</div>
                </Typography>
              )}
              <StyledTags maxTagCount={maxTagCount}>
                <Tags
                  tagKeys={_.take(video.tags, maxTagCount)}
                  allTags={allVideoTags}
                  entity="video"
                />
              </StyledTags>
            </StyledCardContent>
          </CardContent>
        </div>
      </Card>
    </CardActionArea>
  );
};
export default withStyles(styles)(VideoCard);
