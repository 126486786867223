import _ from 'lodash';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Tags from './tags';

// use order of activeKeys instead of tagKeys
export default ({
  tagKeys,
  activeKeys = [],
  onClick,
  onDelete,
  entity,
  limit,
  variant
}) => (
  <Box>
    <Tags
      color="primary"
      variant="default"
      tagKeys={_.intersection(activeKeys, tagKeys)}
      allTags={tagKeys}
      onDelete={onDelete}
      entity={entity}
    />
    <Tags
      color="default"
      variant="default"
      tagKeys={_.difference(tagKeys, activeKeys)}
      allTags={tagKeys}
      onClick={onClick}
      entity={entity}
      limit={limit}
    />
  </Box>
);
