import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage } from 'react-intl';
import { Locale, SUPPORTED_LOCALES, MessagesModule } from '@wordquest/locales';
import { asLanguageProficiencyI18nKey } from '@wordquest/lib-iso/domain/wordquest/ql/difficulty-scale';

const StyledProficiencyTag = styled.div`
  > div {
    max-width: 9rem;
  }
`;
const StyledProficiency = styled.span`
  padding-left: 0.5rem;
  font-size: 0.75rem;
  color: black;
`;

export const LanguageProficiencyTag = ({ languageProficiency }) => {
  const { title, key, locale, proficiency, isVerified } = languageProficiency;

  const proficiencyKey = asLanguageProficiencyI18nKey({ locale, proficiency });

  return (
    <StyledProficiencyTag>
      <Chip
        label={
          <Box
            display="flex"
            alignItems="center"
            p={1}
            justifyContent="space-around"
          >
            <FormattedMessage id={`common.language.${locale}`} />{' '}
            <StyledProficiency>
              <FormattedMessage id={proficiencyKey} />
            </StyledProficiency>
          </Box>
        }
        color="primary"
        variant="outlined"
      />
    </StyledProficiencyTag>
  );
};

export default LanguageProficiencyTag;
