// https://github.com/wordquest/wordquest/issues/456
// replace <TextAnnoated />
// Wq // to Slate interfaces (which // to DOM too) with our own types
// While we simplify for non-editable usages
// WqDoc should be able to be rendered/serialized without using Slate's Editable

// Transforms are potentially useful https://docs.slatejs.org/api/transforms
// while add that in future when we need beyond loop

// We try to stick with structure of DOM, Node, so easier for (de)serialization
// https://docs.slatejs.org/concepts/01-interfaces

// html+wq markup -> WqDoc by DOMParser
// WqDoc -> html by Document.body.innerHTML

// https://developer.mozilla.org/en-US/docs/Web/API/Document
// https://developer.mozilla.org/en-US/docs/Web/API/Node
// https://developer.mozilla.org/en-US/docs/Web/API/DOMParser

// render function takes in slate-specific props
//  at wq: renderGlossary = (props)=> JSX.Element
//  at slate: renderElement={props=>slateSpecificEditableLogic(renderGlossary(fromSlateProps(props)))}
//  where RenderElementProps = RenderElementProps=> object
// https://github.com/ianstormtaylor/slate/blob/master/packages/slate-react/src/components/editable.tsx#L35
import _ from 'lodash';

// seems slate don't differentiate
export interface WqDocument extends WqNode {
  children: WqNode[];
}

// use image instead of picture although we render <figure><picture>. https://stackoverflow.com/questions/2402761/is-img-element-block-level-or-inline-level
// since Picture can't have children
export enum WqNodeTypeInline {
  Link = 'link',
  Image = 'image',
  Audio = 'audio',
  Word = 'word',
  Glossary = 'glossary',
  Text = 'text',
  Video = 'video'
  // [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node as Inline),
  // [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node as Inline),
  // [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri}>{children}</a>,
}

// slate has no "recommended types"
export enum WqNodeTypeBlock {
  Author = 'author',
  AuthorNote = 'authorNote',
  Course = 'course',
  Lesson = 'lesson',
  // not to confuse the two and potentially namespace with Wq
  Quote = 'quote',
  Hr = 'hr',
  BlockQuote = 'blockquote',

  Document = 'document',
  // hack as we distinguish root document vs block
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/div
  Div = 'div',
  Paragraph = 'paragraph',

  Heading1 = 'h1',
  Heading2 = 'h2',
  Heading3 = 'h3',
  Heading4 = 'h4',
  Heading5 = 'h5',
  Heading6 = 'h6',

  Markdown = 'markdown',

  // unlike contentful we don't have a "general" embeded entry except this
  // to use embedly
  EmbededLink = 'embeded-link',

  // base on user data. may or may not be embeded iframe
  SmartUserBlock = 'smart-user-block',
  UlList = 'ul',
  OlList = 'ol',
  // extra field easier to work with Material UI
  ListHeader = 'li-header',
  ListItem = 'li',

  // newly allowed
  Raw = 'raw'
}

export type WqNodeType = WqNodeTypeInline | WqNodeTypeBlock;

export interface WqNode {
  object: 'block' | 'inline' | 'text';
  children: WqNode[];
  type: WqNodeType;
}

export interface WqNodeBlock extends WqNode {
  object: 'block';
  type: WqNodeType;
}

export interface WqNodeInline extends WqNode {
  object: 'inline';
  type: WqNodeType;
}

export interface WqNodeText extends WqNode {
  text: string;
  // [string] ?: any
}

export interface WqTextLeaf {}

export type ImageType = 'webp' | 'jpeg' | 'jpg';

export interface WqNodeBlockImage extends WqNodeBlock {
  type: WqNodeTypeInline.Image;
  attributes: {
    src: URL;
    title?: string;
    description?: string;
    srcsetByType?: Record<ImageType, string>;
    // isHideCaption?: boolean
  };
}
