import _ from 'lodash';
import {
  Locale,
  asLocaleWeeklyEqualValue,
  SUPPORTED_LOCALES,
  MessagesModule
} from '@wordquest/locales';

export const DEFAULT_DIFFICULTY_LEVEL = 30;

export const JLPT_SCALE = {
  n1plus: 90,
  n1: 80,
  n2: 60,
  n3: 30,
  n4: 20,
  n5: 10,
  n5minus: 0
};

export const TOPIK_SCALE = {
  l6plus: 90,
  l6: 70,
  l5: 60,
  l4: 50,
  l3: 30,
  l2: 20,
  l1: 10,
  l1minus: 0
};

export const JLPT_BY_LEVEL = _.invert(JLPT_SCALE);

export const CEFR_SCALE = {
  c2: 90,
  c1: 80,
  b2: 70,
  b1: 50,
  a2: 40,
  a1: 30
};

export enum FallbackOption {
  Fluent = 'fluent',
  Advanced = 'advacned',
  Intermediate = 'intermediate',
  Beginner = 'beginner'
}

export enum CEFROption {
  C2 = 'c2',
  B2C1 = 'b2c1',
  B1 = 'b1',
  A1A2 = 'a1a2'
}

export enum JLPTOption {
  N1plus = 'n1plus',
  // N1 = 'n1',
  N2N1 = 'n2n1',
  N4N3 = 'n4n3',
  N5N5minus = 'n5n5minus'
}

export enum TOPIKOption {
  L6plus = 'l6plus',
  L3L6 = 'l3l6',
  // L4L3 = 'l4l3',
  L1L2 = 'l1l2',
  L1minus = 'l1minus'
}

const CEFR_OPTIONS = [
  {
    key: CEFROption.C2,
    min: CEFR_SCALE.c2,
    max: CEFR_SCALE.c2
  },
  {
    key: CEFROption.B2C1,
    min: CEFR_SCALE.b2,
    max: CEFR_SCALE.c1
  },
  {
    key: CEFROption.B1,
    min: CEFR_SCALE.b1,
    max: CEFR_SCALE.b1
  },
  {
    key: CEFROption.A1A2,
    min: CEFR_SCALE.a1,
    max: CEFR_SCALE.a2
  }
];

const FALLBACK_OPTIONS = [
  {
    key: FallbackOption.Fluent,
    min: 80,
    max: 100
  },
  {
    key: FallbackOption.Advanced,
    min: 60,
    max: 79
  },
  {
    key: FallbackOption.Intermediate,
    min: 30,
    max: 59
  },
  {
    key: FallbackOption.Beginner,
    min: 0,
    max: 29
  }
];

export const LOCALES_WITH_CEFR = [
  Locale.EN,
  Locale.DE,
  Locale.FR,
  Locale.ES_ES,
  Locale.VI,
  Locale.TH
];

// must be fluent desc to beginner
export const PROFICIENCY_OPTIONS_BY_LOCALE = {
  ..._.fromPairs(LOCALES_WITH_CEFR.map((l) => [l, CEFR_OPTIONS])),
  [Locale.ZH_TW]: FALLBACK_OPTIONS,
  [Locale.YUE]: FALLBACK_OPTIONS,
  [Locale.JA]: [
    {
      key: JLPTOption.N1plus,
      min: JLPT_SCALE.n1plus,
      max: JLPT_SCALE.n1plus
    },
    {
      key: JLPTOption.N2N1,
      min: JLPT_SCALE.n2,
      max: JLPT_SCALE.n1
    },
    {
      key: JLPTOption.N4N3,
      min: JLPT_SCALE.n3,
      max: JLPT_SCALE.n4
    },
    {
      key: JLPTOption.N5N5minus,
      min: JLPT_SCALE.n5minus,
      max: JLPT_SCALE.n5
    }
  ],
  [Locale.KO]: [
    {
      key: TOPIKOption.L6plus,
      min: TOPIK_SCALE.l6plus,
      max: TOPIK_SCALE.l6plus
    },
    {
      key: TOPIKOption.L3L6,
      min: TOPIK_SCALE.l6,
      max: TOPIK_SCALE.l3
    },
    // {
    //   key: 'l3l4',
    //   min: TOPIK_SCALE.l3,
    //   max: TOPIK_SCALE.l4
    // },
    {
      key: TOPIKOption.L1L2,
      min: TOPIK_SCALE.l1,
      max: TOPIK_SCALE.l2
    },
    {
      key: TOPIKOption.L1minus,
      min: TOPIK_SCALE.L1minus,
      max: TOPIK_SCALE.l1
    }
  ]
};

const mapAsFallbackOption = (proficiency) =>
  ({
    [CEFROption.C2]: FallbackOption.Fluent,
    [CEFROption.B2C1]: FallbackOption.Intermediate,
    [CEFROption.B1]: FallbackOption.Beginner,
    [CEFROption.A1A2]: FallbackOption.Beginner
  }[proficiency]);

// TODO refactor below
export const LOCALES_WITH_PROFICIENCY_TAGS_SHORT = [
  Locale.EN,
  Locale.JA,
  Locale.KO
];

export const asLanguageProficiencyI18nKey = ({
  locale,
  proficiency,
  isDefaultShort = true
}) => {
  let proficiencyLocale = _.includes(
    LOCALES_WITH_CEFR.map(asLocaleWeeklyEqualValue),
    asLocaleWeeklyEqualValue(locale)
  )
    ? Locale.EN
    : locale;

  let effectiveProficiency = proficiency;

  if (_.includes([Locale.ZH_TW, 'zh', Locale.YUE], proficiencyLocale)) {
    proficiencyLocale = Locale.YUE;
    effectiveProficiency = mapAsFallbackOption(proficiency) || proficiency;
  }
  let proficiencyKey = `common.user.profile.proficiency.${proficiencyLocale}.${effectiveProficiency}${
    isDefaultShort &&
    _.includes(LOCALES_WITH_PROFICIENCY_TAGS_SHORT, proficiencyLocale)
      ? '.short'
      : ''
  }`;

  if (proficiency === 'native') {
    proficiencyKey = 'common.user.profile.proficiency.en.native';
  }

  return proficiencyKey;
};

export const PROFICIENCY_LEVEL_BY_LOCALE = {
  ..._.fromPairs(LOCALES_WITH_CEFR.map((l) => [l, CEFR_SCALE])),
  [Locale.JA]: JLPT_SCALE,
  [Locale.KO]: TOPIK_SCALE
};

export const mapKeyAsProficiencyOption = (locale: Locale, key) => {
  const options = PROFICIENCY_OPTIONS_BY_LOCALE[locale];

  return _.find(options, { key });
};

// TODO there are gaps in the scale. find way to match closest
export const mapLevelAsProficiencyOption = (locale: Locale, level) => {
  const options = PROFICIENCY_OPTIONS_BY_LOCALE[locale];

  return _.find(options, (o) => o.max >= level && o.min <= level);
};

export const mapLevelAsProficiencyOptionIndex = (locale: Locale, level) => {
  const options = PROFICIENCY_OPTIONS_BY_LOCALE[locale];

  return _.findIndex(options, (o) => o.max >= level && o.min <= level);
};

export const PROFICIENCY_REFERENCE_BY_LOCALE = {
  [Locale.JA]: {
    key: 'jlpt',
    linkByLocale: {
      [Locale.EN]:
        'https://en.wikipedia.org/wiki/Japanese-Language_Proficiency_Test',
      [Locale.ZH_TW]: 'https://www.jlpt.tw/Info/ExamIntro.aspx'
    }
  },
  [Locale.KO]: {
    key: 'topik',
    linkByLocale: {
      [Locale.EN]:
        'https://en.wikipedia.org/wiki/Test_of_Proficiency_in_Korean',
      [Locale.ZH_TW]: 'https://www.topik.com.tw/about.html?cID=1'
    }
  },
  [Locale.EN]: {
    key: 'cefr',
    linkByLocale: {
      [Locale.EN]:
        'https://en.wikipedia.org/wiki/Common_European_Framework_of_Reference_for_Languages',
      [Locale.ZH_TW]:
        'https://zh.wikipedia.org/zh-mo/%E6%AD%90%E6%B4%B2%E5%85%B1%E5%90%8C%E8%AA%9E%E8%A8%80%E5%8F%83%E8%80%83%E6%A8%99%E6%BA%96'
    }
  },
  [Locale.VI]: {
    key: 'ivpt',
    linkByLocale: {
      [Locale.EN]:
        'https://en.wikipedia.org/wiki/List_of_language_proficiency_tests#Vietnamese',
      [Locale.ZH_TW]: 'http://cvs.twl.ncku.edu.tw/ivpt/'
    }
  }
};

// TODO generate from below scales. use <T> for locale
export type ProficiencyLevel = string;

// Note we decouple option & level, store avg at db
// assume option (ui) is more likely to change

export function isDifficultyTag(tag) {
  return tag.startsWith('jlpt');
}

// temp workaround for both profile & questItem
export function findDifficultyTag(tags) {
  return _.find(tags, isDifficultyTag);
}

export function asDifficultyScalePair(tag) {
  const [key, level] = tag.split('-');
  let scale = null;
  if (key === 'jlpt') {
    scale = JLPT_SCALE[_.toLower((level || '').substr(0, 2))];
  }

  return [key, scale || 50];
}

// i.e. 400 words should be between n2 to n3 level
export const READING_LENGTH_DIFFICULTY_ORIGIN = [400, 50];

export const READING_LENGTH_DIFFICULTY_SCALE = 200;

// Given we're fitting the JLPT scale to simple difficulty scale [0,100],
// we do similar for length. Not the other way round & curve should be asymetric
// https://i.stack.imgur.com/KzmmU.png
export function asDifficultyLevelByReadingLength(length: number): number {
  const [xO, yO] = READING_LENGTH_DIFFICULTY_ORIGIN;
  const x = (length - xO) / READING_LENGTH_DIFFICULTY_SCALE;

  return Math.trunc((x * 50) / (1 + Math.abs(x)) + yO);
}

export const findUserDifficultyColor = (
  difficultyTarget: number,
  wordDifficultyLevel
) => {
  if (!_.isNumber(difficultyTarget)) {
    return '#fcd966';
  }
  const diff = wordDifficultyLevel - difficultyTarget;
  if (diff < -20) {
    return 'ignore';
  }
  if (diff < 0) {
    return 'secondary';
  }
  if (diff > 0) {
    return 'alert';
  }
};
