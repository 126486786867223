import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { relative } from 'path';
import { media, isSizeUp, isWindowMuiBreakpointUp } from '~/style-utils';

const styles = (theme) => ({
  nav: {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: 'none'
    }
  },
  navPrev: {
    left: '-0.5em',
    backgroundColor: 'white',
    color: '#113284'
  },
  navNext: {
    right: '-0.5em',
    backgroundColor: 'white',
    color: '#113284'
  },
  button: {
    margin: theme.spacing(1)
  },
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  swiperSlideContent: {
    margin: 5,
    minHeight: '3em'
  }
});
const StyledSwipeableCards = styled.div`
  position: relative;
`;

// as container non functional, can't extract the media query yet
const SwipeableCards = ({
  swiperKey,
  activeIndex,
  containerRef,
  navButtons,
  navButtonsSizeUp = 'phone',
  classes,
  children = [],
  onClickNavPrev = _.noop,
  onClickNavNext = _.noop
}) => {
  const theme = useTheme();

  return (
    <StyledSwipeableCards>
      <div className="swiper-container" ref={containerRef}>
        <div className="swiper-wrapper">
          {children.map((child, i) => (
            // eslint-disable-next-line
            <div key={`swiper-slider-${i}`} className="swiper-slide">
              <div className={classes.swiperSlideContent}>{child}</div>
            </div>
          ))}
        </div>
        <div className="swiper-pagination" />
      </div>
      {navButtons && isSizeUp(navButtonsSizeUp)
        ? [
            activeIndex !== 0 && (
              <Fab
                onClick={() => onClickNavPrev()}
                size="small"
                color="primary"
                aria-label="add"
                className={`swiper-custom-prev swiper-${swiperKey} ${classes.nav} ${classes.navPrev}`}
              >
                <KeyboardArrowLeft />
              </Fab>
            ),
            activeIndex !== children.length - 1 && (
              <Fab
                onClick={() => onClickNavNext()}
                size="small"
                color="primary"
                aria-label="add"
                className={`swiper-custom-next swiper-${swiperKey} ${classes.nav} ${classes.navNext}`}
              >
                <KeyboardArrowRight />
              </Fab>
            )
          ]
        : null}
    </StyledSwipeableCards>
  );
};
export default withStyles(styles)(SwipeableCards);
