import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Observer, observer, inject, MobXProviderContext } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';
import TopBar from '~/layout/top-bar';
import {
  SITE_ROOT_UI_SITE,
  SITE_ROOT_UI_SITE_PRD
} from '@wordquest/lib-iso/env';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import AuthAvatar from './auth-avatar';
import { withRouterLink } from './with-link';
import AuthAvatarContainer from './auth-avatar-container';
import { media, isSizeUp, mediaMui } from '~/style-utils';
import LogoImageWithLink from '~/button/logo-image-with-link';

const Logo = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-right: 0.1em solid black;
  padding-right: 2em;
  img {
    height: 100%;
  }
  ${mediaMui.md`
    border-right: none;
  `}
  ${mediaMui.sm`
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    height: 2rem;
    border-right: none;
  `}
  ${mediaMui.xs`
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    height: 2rem;
    border-right: none;
  `}
`;
const Tagline = styled.div`
  padding-left: 2em;
  ${mediaMui.xs`
    padding-left: 0.5rem;
  `}
`;

const ToolbarContent = styled.div`
  width: 100%;
  padding: 1em;
  padding-top: 0rem;
  padding-bottom: 0rem;
  border-color: gray;
  ${mediaMui.xs`
    padding: 0rem;
    padding-bottom: 0.2rem;
  `}
`;

const StyledAvatarBox = styled(Box)`
  ${mediaMui.lg`
    min-height: 2.5rem;
  `}
  ${mediaMui.xs`
    & button{
    padding: 0rem;
    }
  `}
  ${mediaMui.sm`
    & button{
      padding: 0rem;
    }
  `}
`;

const NavbarSimpleContainer = (props) => {
  // TODO injected userd
  const { classes, rootStore } = props;
  const { uiConfig, app, topbar } = rootStore.uiStateStore;

  // TODO responsive already, refactor
  return (
    <AppBar position="fixed" color="default" elevation={1}>
      <Toolbar disableGutters>
        <ToolbarContent>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={10} sm={11} style={{ minHeight: '0.8rem' }}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box display={{ xs: 'none', md: 'block' }}>
                  <Logo>
                    <LogoImageWithLink />
                  </Logo>
                </Box>
                {topbar.title && (
                  <Box display="block">
                    <Tagline>
                      <Typography variant="h6" color="inherit" component="h5">
                        {topbar.title}
                      </Typography>
                    </Tagline>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={2} sm={1}>
              <StyledAvatarBox display="flex" justifyContent="center">
                <AuthAvatarContainer>
                  <FormattedMessage id="common.login" />
                </AuthAvatarContainer>
              </StyledAvatarBox>
            </Grid>
          </Grid>
        </ToolbarContent>
      </Toolbar>
    </AppBar>
  );
};

// TODO limit rootstore at ui-main, use external observer to map
export default inject('rootStore')(observer(NavbarSimpleContainer));
