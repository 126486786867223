export const getFallbackUserId = () => {
  const now = new Date();

  return [
    'fallback-user',
    now.getFullYear(),
    now.getMonth(),
    now.getDay()
  ].join('-');
};
