// TODO model tokenInfoByToken
import _ from 'lodash';
import { Locale } from '@wordquest/locales';
import asThumbnailUrlSafe from '~/domain/url-util';
import { parseISO } from 'date-fns';
import { contentRichText } from './dom';
import { asDifficultyLevelByReadingLength } from '~/domain/wordquest/ql/difficulty-scale';
/*
 * We don't use url hash as id, as we might extend to url-less articles
 * semantic concern  (used in url)
 */
export default class Article {
  // Ensure defaults & no extra keys & ignore arguments order
  // Defaults work for undefined only, not null
  constructor(
    public id: string,
    public semanticKey: string,
    public title: string,
    public locale: Locale,
    public sourceName: string,
    public author: {
      name: string;
      description: string;
      avatarUrl: string;
      semanticKey: string;
    },
    // TODO enum
    public category: string,
    public content: string,
    public contentRichText: WqDocument,
    public contentDescriptionExplicit: string,
    public publishedAt: Date,
    public analyzedAt: Date,
    public indexedAt: Date,
    public meta: object,
    public originalUrl: string,
    public thumbnailUrl: string
  ) {}

  static create(doc: object): Article {
    const {
      id,
      semanticKey,
      title = '',
      locale = Locale.ZH_TW,
      sourceName = '',
      author = {
        name: ''
      },
      category = 'others',
      content = '',
      contentRichText = {},
      contentDescriptionExplicit = '',
      meta = {},
      originalUrl = '',
      thumbnailUrl = '',
      publishedAt = new Date('2019'),
      analyzedAt = new Date(),
      indexedAt = new Date()
    } = doc;

    // allow extra props but not overriding (esp getters)
    return _.defaultsDeep(
      new Article(
        id ||
          encodeURIComponent(
            _.truncate(`${sourceName}-${title}`, {
              length: 20,
              omission: ''
            })
          ),
        semanticKey,
        title,
        locale,
        sourceName,
        author,
        category,
        content,
        contentRichText,
        contentDescriptionExplicit,
        _.isString(publishedAt) ? parseISO(publishedAt) : publishedAt,
        analyzedAt,
        indexedAt,
        meta,
        originalUrl,
        thumbnailUrl
      ),
      doc
    );
  }

  get contentDescription() {
    return (
      this.contentDescriptionExplicit ||
      _.truncate(this.content, {
        length: 100,
        separator: ' '
      })
    );
  }

  /**
   * Avoid mixed content error esp under Chat webview
   * https://developers.google.com/web/fundamentals/security/prevent-mixed-content/what-is-mixed-* content
   * Do it conditionally as https version might not be available
   *
   * Better solution: proxy those assets
   */
  get thumbnailUrlSafe() {
    return asThumbnailUrlSafe(this.thumbnailUrl);
  }

  // pbm: dependency on microlink
  // essentially pre-process instead of script for ES. (meta are not stored). possible to put at analyzer
  get qualityScore(): number {
    const imageResolution =
      _.get(this, 'meta.thumbnailImageHeight', 0) +
      _.get(this, 'meta.thumbnailImageHeight', 0);
    // ensure if no effect if failed to fetch microlink. if 0 i.e. microlink cannot find image
    const imagePunishment =
      !this.thumbnailUrl ||
      this.meta.isUsingDefaultThumbnailUrl ||
      (_.get(this, 'meta.isMicroLinkEnriched', false) && imageResolution < 600)
        ? 50
        : 0;
    const contentPunishment = this.content.length < 50 ? 20 : 0;
    // could be positive
    const sourceAuthority = _.isNumber(this.meta.sourceAuthority)
      ? this.meta.sourceAuthority
      : 50;
    const authorityAdjust = (50 - sourceAuthority) * 0.5;

    return Math.max(
      0,
      Math.min(100, 100 - imagePunishment - contentPunishment - authorityAdjust)
    );
  }

  get difficultyLevel(): number {
    return asDifficultyLevelByReadingLength(this.content.length);
  }

  // overriding
  toObject(): object {
    return Object.assign({}, this, {
      qualityScore: this.qualityScore,
      difficultyLevel: this.difficultyLevel,
      meta: _.pickBy(this.meta || {}, (v, k: string) => !_.isUndefined(v))
    });
  }
}

export type AnalyzedArticle = Article & {
  isAnalyzed: boolean;
  analyzedAt: Date;
  contentTagged: string;
  contentSantized: string;
  tokenInfoByToken: {};
};
