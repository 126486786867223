import _ from 'lodash';
import { format, formatISO } from 'date-fns';
import { toUTCString } from '~/intl';
import { Lesson } from './lesson';
import { WqDocument } from '~/domain/wordquest/dom';
import { CourseIntro } from './course-intro';

export const getCourseUid = (course: Course) => {
  if (!course) {
    return '';
  }
  const { updatedAt } = course;

  return `${course.key}-${_.get(course, 'courseIntro.id')}-${toUTCString(
    updatedAt
  )}`;
};

export const checkCourseIsCoach = (course) =>
  !!(_.get(course, 'key') || '').match(/-teacher-/);

// list of materials need to be pre-created
// to avoid
// only single locale
export default class Course {
  constructor(
    public id: string,
    public key: string,
    public title: string,
    public publishedAt: Date,
    public updatedAt: Date,
    public isHidden: boolean,
    public targetLocale: Locale,
    public courseTypes: string[],
    public courseIntro: CourseIntro,
    public bookingUrl: string,
    public coursePlans: any[],
    // Meta only not full lesson
    // potentially use array instead & make this gettter, to be same as in firestore
    // order inside course section is also important
    public courseSections: { title: string; lesson: Lesson[] }[],
    // private contentRichText: string,
    public heroImageUrl: string,
    public ctaDescriptionRichText: WqDocument,
    public descriptionShort: string,
    public descriptionRichText: WqDocument,

    public tags: string[],
    public courseMeta: Record<string, any>,
    public authorizedSegments: string[]
  ) {}

  static create(doc: object): Course {
    const {
      id,
      key,
      title = '',
      updatedAt = new Date(),
      publishedAt,
      isHidden = false,
      targetLocale,
      courseTypes,
      courseIntro,
      bookingUrl,
      coursePlans,
      courseSections,
      // contentRichText,
      heroImageUrl,
      ctaDescriptionRichText,
      descriptionShort,
      descriptionRichText,
      tags = [],
      courseMeta,
      authorizedSegments = []
    } = doc;

    return _.defaultsDeep(
      new Course(
        id,
        key,
        title,
        updatedAt,
        publishedAt,
        isHidden,
        targetLocale,
        courseTypes,
        courseIntro,
        bookingUrl,
        coursePlans,
        courseSections,
        // contentRichText,
        heroImageUrl,
        ctaDescriptionRichText,
        descriptionShort,
        descriptionRichText,
        tags,
        courseMeta,
        authorizedSegments
      ),
      doc
    );
  }

  // not much point as unable to ensure order
  // get lessonsBySectionKey() {
  // const lessonsBySectionKey = _.fromPairs(_.map(courseSections, (courseSection) => {
  //     lessons: courseLessons.map(({ sys, fields }) => ({
  //       id: sys.id,
  //       title: fields.title,
  //       key: fields.key
  //     }))
  //   }];
  // }));
  // }
}
