// Currently as single entry point of firebase at front-end
import firebase from '@wordquest/lib-iso/adapters/firebase';
import Profile from '@wordquest/lib-iso/domain/wordquest/profile/profile';
import logger from '@wordquest/lib-iso/app/logger';

export default firebase;

const signInOrCreateAnonymousByPsId = async (psId: string) =>
  Promise.resolve({
    uid: '0OFqxh6RwBdbmwzNS1LsxB1pq2j1'
  });
export const signInWithProfile = async (profile: Profile) => {
  const user = await signInOrCreateAnonymousByPsId(profile.idFbm);
  firebase.auth().updateCurrentUser(user);
};
