import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import { LocaleContext } from '~/locale-context';
import { Locale } from '@wordquest/locales';
import { lazyLoadLocaleData } from '~/react-intl-config';
import LanguageDropdown from '~/language-dropdown';
import { FormattedMessage } from 'react-intl';
import grey from '@material-ui/core/colors/grey';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {
  SITE_ROOT_UI_SITE,
  SITE_ROOT_UI_SITE_PRD
} from '@wordquest/lib-iso/env';
import _ from 'lodash';
import {
  media,
  isSizeUp,
  mediaMui,
  isWindowMuiBreakpointUp
} from '~/style-utils';
import Container from '@material-ui/core/Container';
import { withRouter } from 'next/router';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { withNextLink } from '../with-link';

const Logo = styled.img`
  cursor: pointer;
  ${media.desktop`
    width: 124px;
    height: 32px;
    margin: 2em 1.5em;
  `}
  ${media.phone`
    max-width: 8em;
    position: relative;
    top: 4px;
  `}
  margin-left: 1em;
`;

const LogoAnchor = styled.span`
  cursor: pointer;
  text-decoration: none;
`;

// not to duplicate the a withLink got
const MenuAnchor = styled.span`
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  ${(props) =>
    props.isWhiteTheme &&
    css`
      color: white;
      font-weight: 500;
    `}
`;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    }
  },
  logoBar: {
    [theme.breakpoints.up('sm')]: {
      // paddingLeft: '24px',
    }
    // marginBottom: '-5em'
  },
  dropdown: {
    '& span': {
      fontSize: '14px',
      color: '#000000'
    },
    '& fill': {
      fill: '#113284'
    },
    fontWeight: 'bold'
  },
  logoWords: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  menuRightSide: {
    width: '100%'
  }
});

const ToolbarCompensate = styled.div`
  ${(props) =>
    props.compensate &&
    css`
      /* margin-bottom: -5em; */
    `}
`;

const StyledFormattedMessage = styled.span`
  color: #000000;
  font-weight: bold;
  font-size: 1em;
  ${media.desktop`
    font-size: 14px;
    margin: 0em 0;
  `}
`;

const StyledDropdown = styled.div`
  width: 100%;
  max-width: 3rem;
  float: left;
  .MuiButton-root {
    padding: 0rem;
  }
  color: #113284;
  font-size: 1.2em;
`;

const StyledGridContainer = styled(Container)`
  div {
    word-break: keep-all;
    text-align: center;
    align-items: center;
  }
  > div {
    flex-direction: row;
  }

  // border-bottom: 1px solid #00000012;
  position: relative;
  ${media.desktop`
    height: 6rem;
  `}
  ${media.phone`
    border-bottom: 1px solid #00000012;
  `}
  ${media.tablet`
    border-bottom: 1px solid #00000012;
  `}
  button {
    text-transform: none;
  }
  & a {
    text-decoration: none;
  }
  ${mediaMui.smUp`
  padding: 1em;
  `}

  .box-menu {
    justify-content: flex-end;
    display: flex;
  }
  .spacing {
    padding: 0px 30px;
    justify-content: flex-end;
    display: flex;
    ${media.tablet`
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `}
    ${media.phone`
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `}
  }
  .spacing-button {
    padding: 0.6em 4em 1.4em 1.4em;
    ${media.phone`
      padding: 2px 0;
    `}
    ${media.tablet`
      padding: 1.4em 4em 1.4em 0.4em;
    `}
  }
`;

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const StyledLogoTagline = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: baseline;
  ${media.phone`
  padding-top: 1em;
  padding-bottom: 1em;
`}
`;

function TopBar(props) {
  const {
    classes,
    title,
    isTopBarAffix,
    isAffixCompensate,
    router,
    withLink = (props) => withNextLink(props),
    isSidebarOpen = false,
    isSidebarShown = false,
    createLoginAvatar = _.identity
  } = props;
  const excludeRoutes = ['/', '/translation-services'];
  const borderStyle =
    !!router && excludeRoutes.indexOf(router.pathname) !== -1
      ? {}
      : { borderBottom: '1px solid #00000021' };
  const isWhiteTheme = false;

  const LogoAndBar = () => (
    <LocaleContext.Consumer>
      {({ toggleLocale, currentLocale }) => {
        // TODO move this up when refactor with hooks
        const toggleLocaleWithLazyLoad = async (locale) => {
          await lazyLoadLocaleData(locale);

          return toggleLocale(locale);
        };
        const createHrefWithLocale = (url) =>
          `${url}?locale=${currentLocale || Locale.EN}`;
        const isLgUp = isWindowMuiBreakpointUp('lg');

        return (
          <StyledGridContainer
            maxWidth="lg"
            style={{
              paddingLeft: !isSidebarShown
                ? '12px'
                : !isLgUp && isSidebarOpen
                ? '20em'
                : '57px'
            }}
          >
            <Grid
              container
              direction="row"
              justify={isLgUp ? 'space-between' : 'center'}
              wrap={isLgUp ? 'nowrap' : 'wrap'}
            >
              <Grid
                xs={12}
                sm={6}
                md={6}
                lg={5}
                item
                style={{ textAlign: 'center' }}
              >
                {withLink({ href: SITE_ROOT_UI_SITE })(
                  <LogoAnchor>
                    <StyledLogoTagline>
                      <Logo
                        src={`${SITE_ROOT_UI_SITE}/static/index_v2/logo/PeraPera_logo.svg`}
                        alt="logo"
                      />
                      <Hidden mdDown>
                        <Box className={`${classes.logoWords} logoWords`}>
                          <span>&nbsp;|&nbsp;&nbsp;</span>
                          <FormattedMessage id="landing.navigation.logo" />
                        </Box>
                      </Hidden>
                    </StyledLogoTagline>
                  </LogoAnchor>
                )}
              </Grid>
              <Grid
                item
                wrap="nowrap"
                display="flex"
                container
                xs={12}
                sm={7}
                md={6}
                lg={5}
                direction="row"
                justify="space-between"
                alignItems="baseline"
                style={{ marginLeft: '1rem' }}
              >
                {/* withLink(
              { 'href': `${SITE_ROOT_UI_SITE}/jobs/` }
            )(
              <MenuAnchor isWhiteTheme={isWhiteTheme}>
                <StyledFormattedMessage>
                  <FormattedMessage id="landing.navigation.jobs" />
                </StyledFormattedMessage>
              </MenuAnchor>
            ) */}
                <Hidden mdDown>
                  {withLink({
                    isExternal: true,
                    href: createHrefWithLocale(`${SITE_ROOT_UI_SITE}/courses/`)
                  })(
                    <MenuAnchor isWhiteTheme={isWhiteTheme}>
                      <StyledFormattedMessage>
                        <FormattedMessage id="landing.navigation.course" />
                      </StyledFormattedMessage>
                    </MenuAnchor>
                  )}
                </Hidden>
                <Hidden mdDown>
                  <Box>
                    {withLink({
                      isExternal: true,
                      href: createHrefWithLocale(`${SITE_ROOT_UI_SITE}/watch/`)
                    })(
                      <MenuAnchor isWhiteTheme={isWhiteTheme}>
                        <StyledFormattedMessage>
                          <FormattedMessage id="landing.navigation.explore" />
                        </StyledFormattedMessage>
                      </MenuAnchor>
                    )}
                  </Box>
                </Hidden>
                <Hidden mdDown>
                  <Box>
                    {withLink({
                      href: createHrefWithLocale(`${SITE_ROOT_UI_SITE}/posts/`)
                    })(
                      <MenuAnchor isWhiteTheme={isWhiteTheme}>
                        <StyledFormattedMessage>
                          <FormattedMessage id="landing.navigation.blog" />
                        </StyledFormattedMessage>
                      </MenuAnchor>
                    )}
                  </Box>
                </Hidden>
                <Hidden mdDown>
                  {withLink({
                    href: `${SITE_ROOT_UI_SITE}/course/landing-page-for-coach`
                  })(
                    <MenuAnchor isWhiteTheme={isWhiteTheme}>
                      <StyledFormattedMessage>
                        <FormattedMessage id="landing.navigation.coach" />
                      </StyledFormattedMessage>
                    </MenuAnchor>
                  )}
                </Hidden>
                <StyledDropdown>
                  <LanguageDropdown
                    isWhiteTheme={isWhiteTheme}
                    toggleLocaleWithLazyLoad={toggleLocaleWithLazyLoad}
                    classes={classes}
                  />
                </StyledDropdown>
                <Box
                  display={{ xs: 'flex', md: 'flex' }}
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginLeft: '0.5rem' }}
                >
                  {createLoginAvatar(
                    <MenuAnchor>
                      <StyledFormattedMessage>
                        <FormattedMessage id="common.login" />
                      </StyledFormattedMessage>
                    </MenuAnchor>
                  )}
                </Box>
              </Grid>
            </Grid>
          </StyledGridContainer>
        );
      }}
    </LocaleContext.Consumer>
  );

  // TODO redu
  return (
    <div className={classes.root} style={borderStyle}>
      {isTopBarAffix ? (
        <AppBar position="fixed" color="default">
          <Toolbar disableGutters className={classes.toolBar}>
            <LogoAndBar isWhiteTheme={isWhiteTheme} />
          </Toolbar>
        </AppBar>
      ) : (
        <HideOnScroll {...props}>
          <ToolbarCompensate compensate={isAffixCompensate}>
            <LogoAndBar isWhiteTheme={isWhiteTheme} />
          </ToolbarCompensate>
        </HideOnScroll>
      )}
    </div>
  );
}

export default withRouter(withStyles(styles)(TopBar));
