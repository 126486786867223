// https://github.com/wordquest/wordquest/issues/733s
// With Context e.g. helpdesk only

// 1) open new tab to main (no automcompelte etc unless we do ajax)

// 2) direct query to mobx
import _ from 'lodash';
import Input from '@material-ui/core/Input';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import {
  format,
  formatWithLocale,
  loadAndAddMessagesWithLocaleModule
} from '@wordquest/lib-iso/intl';
import styled from 'styled-components';

const StyledSearchBar = styled.div`
  width: 100%;
  .MuiInput-root {
    width: 100%;
  }
`;
const SearchBar = ({ search, context, contextSettings, onQueryChange }) => (
  <StyledSearchBar>
    <Input
      type="text"
      placeholder={format('landing.support.search')}
      onChange={(event) => {
        _.merge(context, contextSettings);
        search.queryText = event.target.value;
        onQueryChange(search);
      }}
    />
  </StyledSearchBar>
);

// bad design to resuse page-search oon query
export default ({
  search,
  context,
  contextSettings = {
    isHelpdeskOnly: true
  },
  onQueryChange = _.identity
}) => {
  const { queryText } = search;

  // TODO use mobx to fix bug not react to array changes
  return (
    <Grid container alignItems="center">
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid item xs={8}>
        <SearchBar
          search={search}
          context={context}
          contextSettings={contextSettings}
          onQueryChange={onQueryChange}
        />
      </Grid>
    </Grid>
  );
};
