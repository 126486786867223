import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from 'next/link';
import rootLogger from '@wordquest/lib-iso/app/logger';
import styled from 'styled-components';

const logger = rootLogger.child({ module: 'link' });
const StyledHref = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

// TODO better unify the props interface for both routerLink /  withNextLink otherwise hard to handle nested scenarios e.g. Quote

export const withRouterLink = (props) => (component) => {
  let to = '/';
  const href = props.href || props.to; //eslint-disable-line
  // guard against incorrect encoded links due to data corruption
  try {
    decodeURIComponent(href);
    if (href) {
      to = href;
    }
  } catch (e) {
    // URI malformed
    logger.error('incorrect uri', e);
  }

  // workaround this silly issue
  // https://github.com/ReactTraining/react-router/issues/1147
  if (/^https?:\/\//.test(to))
    return (
      <StyledHref href={to} {...props}>
        {component}
      </StyledHref>
    );

  return (
    <StyledRouterLink {...props} to={to}>
      {component}
    </StyledRouterLink>
  );
};

// inside ui-components, we can't tell unless check against current nextjs routes. simple hack now
// external in terms of non-site
// External URLs, and any links that don't require a route navigation using /pages, don't need to be handled with Link; use the anchor tag for such cases instead.
// https://nextjs.org/docs/api-reference/next/link
export const withNextLink = (props) => (component) => {
  const { href, prefetch, isExternal = false } = props;
  if (isExternal) {
    return <a href={href}>{component}</a>;
  }

  return (
    <Link href={href} prefetch={prefetch} passHref>
      <StyledHref>{component}</StyledHref>
    </Link>
  );
};
