/* global window */
/**
 * We put repos/data-loading logic here + at Component lifecycle
 * Will be mirgated to hooks
 *
 * TODO break down into smaller stores & here is cache only
 *
 */
import _ from 'lodash';
import qs from 'qs';
import { observable, reaction, computed, decorate } from 'mobx';
import { Machine } from 'xstate';
import { parseUrlSearchForContext } from '@wordquest/lib-iso/app/url-context';
import { fromFbmThreadContext } from '@wordquest/lib-iso/domain/wordquest/profile/mappers';
import firebase, { signInWithProfile } from '~/services/firebase';
import logger from '@wordquest/lib-iso/app/logger';
import { Locale } from '@wordquest/locales';
import { RouterStore } from 'mobx-react-router';
import { matchRoute } from '~/services/route-matcher';
import {
  AuthStepState,
  AuthStepEvent,
  authStepStateMachine,
  initAuthStepStateService
} from '@wordquest/ui-components/auth/auth-step';
import RootStore from '~/stores/root';
import { SegmentEcommerce } from '@wordquest/lib-iso/domain/wordquest/event/standard';
import {
  initAuthMessageStateService,
  AuthStateEvent,
  initAuthStateService,
  AuthState,
  AuthMessageState,
  createOnLineAuthSuccessCallback
} from '~/services/auth';

import { IS_DEV } from '@wordquest/lib-iso/env';
import {
  isSizeUp,
  isWindowMuiBreakpointUp
} from '@wordquest/ui-components/style-utils';

export const matchPathCourse = (pathname) => {
  const match = (pathname || '').match(/course\/([^/]+)\/?/);
  if (match) {
    return {
      courseKey: match[1]
    };
  }
};

// kitchen sink now. TODO rename to uiConfigStore after further breakdown
export default class UiStateStore {
  match = observable({});

  uiConfig = observable({
    isChatBubbleEnabled: false,
    isMessengerKitEnabled: false,
    isNavbarShown: true,
    isSimplybookEnabled: true,
    isMagic: false,
    isDebug: false,
    isDebugWithPrintedData: false,
    // consider reponsive BUT for  good enough to have bottom tabs
    isSidebarLeft: isWindowMuiBreakpointUp('md'),
    isUseTextAnalyzedContext: false,
    mock: {}
  });

  topbar = observable({
    title: ''
  });

  sidebar = observable({
    title: '',
    type: '',
    props: {},
    linkRoot: ''
  });

  updateUiConfig = (search: object) => {
    const urlContext = parseUrlSearchForContext(search);
    logger.debug('urlContext', urlContext);
    this.uiConfig = _.merge(this.uiConfig, urlContext.uiConfig);
  };

  // Note not to overlap firestore cache with mobx cache
  // Articles, Word is fine as loaded from Elasticsearch

  // TODO currentWordId for multiple pages

  // null will make non-empty
  errorState = observable({
    login: 0,
    lesson: 0,
    course: 0,
    video: 0
  });

  toggleIsAuthDialogOpen = (isAuthDialogOpen) => {
    console.log('toggleIsAuthDialogOpen', isAuthDialogOpen);
    const target = _.isBoolean(isAuthDialogOpen)
      ? isAuthDialogOpen
      : !this.app.isAuthDialogOpen;
    this.app.isAuthDialogOpen = target;
  };

  deck = observable({
    isLoadDeckCompleted: false,
    wordIds: observable([]),
    words: []
  });

  onLoadFreshChat = () => {
    window.fcWidget.init({
      token: process.env.REACT_APP_FRESHCHAT_TOKEN || '',
      host: 'https://wchat.freshchat.com',
      locale: 'zh-HANT'
    });

    const fcPlugin = {
      name: 'freshchat-analytics',
      identify: (userId, traits) => {
        if (!window.fcWidget) {
          return;
        }
        console.log('freshchat identify', userId);
        // analytics.plugins
        window.fcWidget.setExternalId(userId);

        const { name, email } = traits || {};
        if (name) {
          window.fcWidget.user.setFirstName(name);
        }
        if (email) {
          window.fcWidget.user.setEmail(email);
        }

        // available
        // setLastName, setLocale,setPhone,setPhoneCountryCode,setProperties

        // if(!_.isEmpty(traits)){
        //   window.fcWidget.user.setProperties(traits);
        // }
        // let backend to enrich properties
      },
      track: (data) => {
        console.log('freshchat track', data);
        const { event, properties } = data || {};
        window.fcWidget.track(event, properties);
      }
    };

    this.rootStore.eventStore.plugins.push(fcPlugin);
  };

  onLoadCustomerChat = () => {
    console.log('onLoadCustomerChat', global.FB);
    global.FB.CustomerChat.show(false);
  };

  onLoadFbm = () => {
    const MessengerExtensions = window.MessengerExtensions || {};
    logger.debug('onloadFbm', MessengerExtensions);
    MessengerExtensions.getContext(
      process.env.REACT_APP_FB_APP_ID,
      (threadContext) => {
        logger.debug(threadContext);
        signInWithProfile(fromFbmThreadContext(threadContext)).then(
          (profile) => {
            this.rootStore.userStore.user.profile = profile;
          }
        );
      },
      (errCode, errMsg) => {
        logger.error(errCode, errMsg);
      }
    );
  };

  onLoadLine = () => {
    // TODO
    // signInWithProfile(fromLine({id}))
    //   .then(profile=>{
    //     this.rootStore.userStore.user.profile = profile;
    //   });
  };

  constructor(private rootStore: RootStore, public routerStore: RouterStore) {
    const { wordStore, pageReadStore } = rootStore;
    // For current, We can't tell from location.pathname itself as multiple routes could match
    // TODO  we might have multiples
    this.currentWordRef = observable({
      id: ''
    });
    if (isSizeUp('desktop')) {
      this.uiConfig.isChatBubbleEnabled = true;
    }

    this.app = observable({
      logs: [],
      title: '',
      userLocale: Locale.ZH_TW,
      toggleLocale: _.identity,
      isDrawerOpen: false,
      isSidebarOpen: true,
      isSigninInProgress: false,
      isSignInEmailOnly: true,
      // ugly hack as a fallback way to delay stuff e.g. recommendations for loading
      isGenericDelayCompleted: false,
      isAuthDialogLoaded: false,
      isAuthDialogOpen: false,
      emailInputToValid: '',
      // authDialogSteps: DEFAULT_AUTH_STEPS,
      assignedStepKey: '',

      authState: {
        value: null,
        context: {}
      },
      authStepState: {
        value: null,
        context: {}
      },
      authMessageState: {
        value: null,
        context: {}
      },
      // authStateService: null,
      // authStepStateService: null,
      // TODO more generic sign up steps
      // TODO not every time
      snackbarMessages: [],
      currentTab: 'watch',
      depth: 0
    });

    this.onAuthStateChanged = null;
    // 3 cases: 1)already login 2)not logged in w/ email found 3) not logged in w/ email not found

    this.app.onLogout = () => {
      const { userStore, uiStateStore } = rootStore;
      this.app.authStateService.send(AuthStateEvent.SignOut);
      // this.app.authStepStateService.send(AuthStepEvent.Complete);
      // userStore.onLogout();
    };

    this.app.logLine = (...message) => {
      logger.debug(message);
      this.app.logs.push(message);
    };
    // done before firebase ready
    this.app.initAuth = () => {
      logger.debug('initAuth');
      // we make authStep control by authState
      if (!this.app.authStepStateService) {
        logger.debug('initAuthStepStateService');
        this.app.authStepStateService = initAuthStepStateService(
          rootStore,
          this
        );
      }

      if (!this.app.authMessageStateService) {
        this.app.authMessageStateService = initAuthMessageStateService(
          rootStore,
          this
        );
      }
      if (!this.app.authStateService) {
        logger.debug('initAuthStateService');
        this.app.authStateService = initAuthStateService(rootStore, this);
      }
    };
    this.onAuthDialogSubmit = ({ currentAuthStep, emailSubmitted }) => {
      logger.debug('onAuthDialogSubmit', currentAuthStep);
      // decouple with ui-component
      if (currentAuthStep === AuthStepState.EmailInput) {
        this.app.authStateService.send({
          type: AuthStateEvent.SignInWithEmailLink,
          payload: {
            email: emailSubmitted
          }
        });
      }
      this.toggleIsAuthDialogOpen(false);
    };

    // for firebase delay & compt is lazy
    setTimeout(async () => {
      this.app.isAuthDialogLoaded = true;
    }, 2000);
    setTimeout(() => {
      this.app.isGenericDelayCompleted = true;
    }, 5000);
    this.widgets = observable({
      isDrawerOpened: false,
      isNeverWordTouched: false
    });

    // Defined here for type safety

    // location is observable inside routereStore, so don't deference
    reaction(
      () => this.app.currentTab,
      (newTab) => {
        routerStore.push(`/${newTab}`);
      }
    );

    this.currentAudio = observable({
      queue: [],
      playback: {
        isStarted: false,
        isReady: false,
        durationInSeconds: 0,
        key: '',
        playing: false
      }
    });

    this.updateBySearchString = (searchString: string) => {
      const search = qs.parse((searchString || '').replace(/^\?/, ''));
      Object.assign(this.routeMatch.search, search);
      // don't handle page-specific params here
      this.updateUiConfig(search);
    };

    const routeMatch = observable({
      params: {},
      isPreview: false,
      path: '',
      url: '',
      search: {}
    });

    const trackCurrentView = (location) => {
      const event = {
        page: location.pathname + qs.stringify(location.search),
        event: 'PageViewed'
      };

      rootStore.eventStore.trackEvent(event);
      // TODO reuse route match
      const courseMatch = matchPathCourse(location.pathname);
      if (courseMatch) {
        // https://segment.com/docs/connections/destinations/catalog/facebook-pixel/
        rootStore.eventStore.trackEvent({
          event: SegmentEcommerce.ProductViewed,
          // fb direct schema
          // event: 'ViewContent'
          // content_type: 'product',
          // content_ids: [courseMatch.courseKey]

          // erly on catalog to do the rest
          properties: {
            product_id: courseMatch.courseKey,
            // variant,
            sku: courseMatch.courseKey
          }
        });
      }
      // window.ga('send', 'pageview');
    };

    routerStore.history.listen(trackCurrentView);

    this.routeMatch = routeMatch;
    // init routerStore location not captured

    reaction(
      () => routerStore.location.pathname,
      (pathname) => {
        const match = matchRoute(pathname);
        const { hash, search } = routerStore.location;
        logger.debug(`[updated]location.pathname${pathname}`, match, hash);
        // always override
        const { params, path } = match || {};
        let isPreview = false;

        // https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
        if (hash) {
          logger.debug('scrollTo hash', hash);
          const id = hash.replace('#', '');
          setTimeout(() => {
            const element = document.getElementById(id);
            if (window && element) {
              const y =
                element.getBoundingClientRect().top + window.pageYOffset - 40;
              window.scrollTo({ top: y, behavior: 'smooth' });
            }
          }, 1000 * 3);
        }
        if (path && path.match(/\/preview\//)) {
          isPreview = true;
        }
        Object.assign(routeMatch, { isPreview, params: params || {}, path });
        trackCurrentView(routerStore.location);
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.uiConfig.isLiffReady + routerStore.location.pathname,
      async () => {
        if (!this.uiConfig.isLiffReady) {
          return;
        }
        const { hash, search, pathname } = routerStore.location;
        if ((pathname || '').match(/\/liff\//)) {
          routerStore.history.push(
            pathname.replace('/liff', '') + search || ''
          );
          // To complete the initialization correctly, don't change the URL during server or front-end processing before completing liff.init().
        } else {
          const liff = window.liff;
          this.app.logLine(
            'liff init success',
            liff.isInClient(),
            liff.getOS(),
            liff.getLanguage(),
            liff.getVersion()
          );
          const isInClient = liff.isInClient() || IS_DEV;
          const context = liff.getContext();
          this.app.logLine('liff context', context, 'inClient', isInClient);

          //  If the user starts the LIFF app in an externa l browser, the LIFF SDK will get an ID token when these steps are satisfied:
          // You call liff.login().
          // The user logs in to LINE.
          // You call liff.init().
          const onLineAuthSuccessCallback = createOnLineAuthSuccessCallback(
            rootStore
          );

          if (isInClient) {
            this.app.logLine('in liff');

            const profile = liff.getDecodedIDToken() || {};
            const idToken = liff.getIDToken();
            if (!idToken) {
              this.app.logLine('liff idToken empty', profile);

              return;
            }
            this.app.logLine(
              'liff to confirm access',
              context,
              profile,
              idToken
            );
            const result = await onLineAuthSuccessCallback({
              profile,
              id_token: idToken
            });
            // same as https://developers.line.biz/en/docs/line-login/integrate-line-login/#verify-id-token
            // no uid as in message
          } else {
            this.app.logLine('in external, login required');
            // liff.login();
          }
        }
      }
    );

    reaction(
      () => routerStore.location.search,
      (updated) => {
        // don't handle page-specific params here
        this.updateBySearchString(updated);
      },
      { fireImmediately: true }
    );
    // TODO by priority
    this.loadNotifications = async () => {
      // disable first as using addthis. timestamp error try catch is a must
      // const challengesModule = await import('@wordquest/lib-static/dist/challenges.json');
      // const challenges = _.take(((challengesModule || {}).default || []), 2);
      // this.app.snackbarMessages.replace(challenges.map(
      //   c => (
      //     {
      //       isOpen: true,
      //       messageKey: 'common.notification.challenge',
      //       ...c
      //     }
      //   )
      // ));
    };

    reaction(
      () => this.app.isGenericDelayCompleted,
      () => {
        this.loadNotifications();
      }
    );

    reaction(
      () => isWindowMuiBreakpointUp('md'),
      (isBreakpointUp) => {
        this.uiConfig.isSidebarLeft = isBreakpointUp;
      }
    );
  }

  onWordClick = (wordId) => {
    this.currentWordRef.id = wordId;
  };

  get currentWord() {
    return this.rootStore.wordStore.wordById.get(this.currentWordRef.id);
  }

  get isSidebarAvailable() {
    return !_.isEmpty(this.sidebar.content);
  }
}

decorate(UiStateStore, {
  currentWord: computed,
  isSidebarAvailable: computed
});
