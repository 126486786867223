import _ from 'lodash';
import Quest, { QuestTypeName } from '~/domain/wordquest/quest/quest';
import { CfTypes } from '~/adapters/contentful';
import { asWqDom } from '~/app/contentful/rich-text-wq-dom-mapper';
import { asAuthor } from '~/app/author/author-cf';

const asQuestStrategies = {
  [CfTypes.QuestItemMc]: (contentType, fields) => {
    const {
      question,
      answer,
      distractor1,
      distractor2,
      distractor3,
      distractor4,
      explanationRichText,
      author
    } = fields;
    const properties = {
      question,
      answer,
      author: asAuthor(author),
      distractors: [distractor1, distractor2, distractor3, distractor4].filter(
        Boolean
      ),
      explanationRichText: asWqDom(explanationRichText)
    };

    return {
      type: QuestTypeName.Mc,
      properties
    };
  },
  [CfTypes.QuestItemMcJa]: (contentType, fields) => {
    const { title } = fields;
    const properties = {
      // TODO
      title
    };

    return {
      type: QuestTypeName.Mc,
      properties
    };
  },
  [CfTypes.QuestItemCheckin]: (contentType, fields) => {
    const { title, instructions } = fields;
    const properties = {
      title,
      instructions
    };

    return {
      type: QuestTypeName.Checkin,
      properties
    };
  },
  [CfTypes.QuestItemRating]: (contentType, fields) => {
    const { title, min, max } = fields;
    const properties = {
      title,
      min,
      max
    };

    return {
      type: QuestTypeName.RateDifficulty,
      properties
    };
  },
  [CfTypes.QuestItemOpenQa]: (contentType, fields) => {
    const { question1 } = fields;
    const properties = {
      question: question1
    };

    return {
      type: QuestTypeName.OpenQA,
      properties
    };
  },
  [CfTypes.QuestItemDictation]: (contentType, fields) => {
    const { answer, startSeconds, endSeconds } = fields;
    const properties = {
      answer,
      startSeconds,
      endSeconds
    };

    return {
      type: QuestTypeName.Dictation,
      properties
    };
  },
  [CfTypes.QuestItemGeneral]: (contentType, fields) => {
    const { key, instructionRichText } = fields;

    const properties = {
      key,
      instructionRichText
    };

    // we should at contnetful decide the subtype e.g. translations
    return {
      type: QuestTypeName.General,
      properties
    };
  }
};
export const asQuest = (item, questProperties) => {
  const { sys, fields } = item;
  if (!fields) {
    // TODO generic guard against draft at contentful
    return;
  }
  const cfType = _.get(sys, 'contentType.sys.id');

  const parentQuest = fields.quest;

  const strategy = asQuestStrategies[cfType];
  if (!strategy) {
    console.log('quest item type not matched', sys, cfType);

    return;
  }
  const { type, properties } = strategy(cfType, fields);

  return Quest.create({
    id: sys.id,
    parentQuestId: _.get(parentQuest, 'sys.id'),
    type,
    priority: 0,
    properties: _.merge(properties, questProperties)
  });
};
