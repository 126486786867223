import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// import FormattedDuration from 'react-intl-formatted-duration';
import Article from '@wordquest/lib-iso/domain/wordquest/article';
import Tags from '~/tag/tags';
import { createSrcSetByTypeContentfulWithSizeParams } from '@wordquest/lib-iso/app/contentful/rich-text-wq-dom-mapper';
import { PictureWithCaption } from '~/image/picture-with-caption';

const StyledCardMedia = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  > figure {
    margin: auto;
    overflow-y: hidden;
    height: 100%;
    max-width: 500px;
    & img{
      width: 100%;
    }
  },
`;
const styles = (theme) => ({
  cardTop: {
    display: 'flex',
    flexDirection: 'row',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    fontSize: '40px',
    height: '293px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: '250px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '352px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '154px'
    }
  },
  cardTitle: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    minHeight: '9em',
    overflow: 'auto',
    '& h1': {
      fontSize: '1em'
    },
    '& h2': {
      color: '#000000',
      fontSize: '28px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      margin: '15px 0',
      lineHeight: '41px'
      // height: '80px'
    },
    '& h3': {
      color: '#000000',
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      margin: '15px 0',
      lineHeight: '1.8em'
    }
  },
  media: {
    width: '100%'
  },
  cardLink: {
    textDecoration: 'none'
  }
});
// I have no idea why cant use `styled-components` to styling, it cant work, it tooks many time to research, use makestyle first.
// pbm: import carrousel, sys link
const ArticleCard = (props: {
  classes: object;
  article: Article;
  allArticleTags: [];
  withLink: any;
  isShowThumbnail: boolean;
  isShowTags: boolean;
}) => {
  // tags are not clickable as link cover whole card
  const {
    classes,
    article,
    allArticleTags = [],
    withLink,
    isShowThumbnail = true,
    isShowTags = true
  } = props;
  // workaround before we fix to use webp in createSrcSetsContentful
  // src set size not meaningful in thumbnail but we need that for safari

  const sizeParams = [
    {
      params: {
        w: '500',
        h: '282'
      },
      size: '500w'
    }
  ];

  return withLink({
    articleId: article.id,
    articleKey: article.semanticKey,
    className: classes.cardLink
  })(
    <div className={classes.card}>
      {isShowThumbnail && article.thumbnailUrlSafe && (
        <div className={classes.cardTop} title={article.title}>
          <StyledCardMedia>
            <PictureWithCaption
              src={article.thumbnailUrlSafe}
              title={article.title}
              description={article.contentDescription}
              srcsetByType={createSrcSetByTypeContentfulWithSizeParams(
                article.thumbnailUrl,
                ['webp', 'jpeg'],
                sizeParams
              )}
              isHideCaption
            />
          </StyledCardMedia>
        </div>
      )}

      <div className={classes.cardTitle}>
        <div style={{ width: '100%' }}>
          <Typography gutterBottom variant="h6" component="h2">
            {article.title}
          </Typography>
          <Typography gutterBottom variant="h6" component="h3">
            {article.content}
          </Typography>
          {isShowTags && (
            <Tags
              tagKeys={article.tagKeys}
              allTags={allArticleTags}
              entity="blog"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(ArticleCard);
