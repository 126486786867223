import _ from 'lodash';
import firebase from 'firebase/app';
import { IS_CF, IS_DEV } from '~/env';
import rootLogger from '~/app/logger';
import { FirebaseUiLocale } from '@wordquest/locales';
import { SITE_ROOT_UI_SITE_PRD, DOMAIN } from '../env';

const logger = rootLogger.child({ module: 'firebase' });

// possible to use hosting domain as authDomain
// https://stackoverflow.com/questions/40856179/custom-authdomain-in-firebase
export const FIREBASE_CONFIG_DEV = {
  apiKey: 'AIzaSyBJAmQcXeJ6cadeSFmnkAMsdqJgZ8QnqSI',
  authDomain: 'wordquest-dev.firebaseapp.com',
  databaseURL: 'https://wordquest-dev.firebaseio.com',
  projectId: 'wordquest-dev',
  storageBucket: 'wordquest-dev.appspot.com',
  messagingSenderId: '632945727923'
};

// admin app
export const FIREBASE_CONFIG_PRD = {
  apiKey: 'AIzaSyCXC6YzUDHX85xj6nK8VTiJd18vro69hdc',
  authDomain: DOMAIN || 'wordquest-app-hk.firebaseapp.com',
  databaseURL: 'https://wordquest-app-hk.firebaseio.com',
  projectId: 'wordquest-app-hk',
  storageBucket: 'wordquest-app-hk.appspot.com',
  messagingSenderId: '176199217884',
  appId: '1:176199217884:web:e6b26aaf6607b87341ba19'
};

export const DEFAULT_ACTION_CODE_SETTINGS_PRD = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.

  // no url = use current
  // query params work
  // url: `${SITE_ROOT_UI_SITE_PRD}/watch`,
  // Custom FDL domain.
  dynamicLinkDomain: 'be.perapera.ai',
  // Always true for email link sign-in.
  handleCodeInApp: true

  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
};

export const DEFAULT_ACTION_CODE_SETTINGS_DEV = _.merge(
  {},
  DEFAULT_ACTION_CODE_SETTINGS_PRD,
  {
    // '/' wont work, must include protocol
    // url: 'http://localhost:3000/watch?logged=1',
    dynamicLinkDomain: 'devwq.page.link'
  }
);

export const DEFAULT_ACTION_CODE_SETTINGS = IS_DEV
  ? DEFAULT_ACTION_CODE_SETTINGS_DEV
  : DEFAULT_ACTION_CODE_SETTINGS_PRD;

export const loadFirebaseAuth = async () => {
  await import('firebase/auth');
  if (firebase.auth) {
    firebase.auth().languageCode = FirebaseUiLocale.ZH_TW;

    return firebase.auth();
  }
};

export const FIREBASE_FUNCTIONS_REGION = 'asia-northeast1';

export const FIREBASE_CONFIG_EFFECTIVE = IS_DEV
  ? FIREBASE_CONFIG_DEV
  : FIREBASE_CONFIG_PRD;
// TODO lazy initialize, mostly for test
console.log(
  'GOOGLE_APPLICATION_CREDENTIALS',
  process.env.GOOGLE_APPLICATION_CREDENTIALS
);
console.log(
  'FIREBASE_CONFIG_EFFECTIVE',
  FIREBASE_CONFIG_EFFECTIVE,
  IS_CF,
  firebase.apps.length
);

// seems after nodejs12 even at CF we need the firebase.initializeApp so no longer an else clause
if (IS_CF) {
  logger.debug('=== Firebase App Init is CF');
  process.env.FIREBASE_CONFIG = JSON.stringify(FIREBASE_CONFIG_EFFECTIVE);
}

if (!firebase.apps.length) {
  logger.debug('=== Firebase App Init', new Date(), IS_DEV);
  firebase.initializeApp(FIREBASE_CONFIG_EFFECTIVE);
  logger.debug('=== Firebase App Init Completed', new Date());
  logger.debug('=== Firebase Auth Init', new Date());
  loadFirebaseAuth();
  //   .then(() => {
  //     if (process.env.NODE_ENV === 'test') {
  //       logger.debug('off persistence');
  //       firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
  //     }
  //   });
}
export default firebase;
