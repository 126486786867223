import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useState, useEffect } from 'react';
import { toJS, observable, action } from 'mobx';
import { timer } from 'rxjs';
import LoaderGeneral from '@wordquest/ui-components/loader-general';
import { withStyles } from '@material-ui/core/styles';
import TextQueryResult from '@wordquest/ui-components/search/text-query-result';
import { observer, inject } from 'mobx-react';
import Input from '@material-ui/core/Input';
import TextAnnoated from '@wordquest/ui-components/text/text-annotated';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  injectIntl
} from 'react-intl';
import SearchBar from '@wordquest/ui-components/search/searchbar';
import ArticleCarrousel from '@wordquest/ui-components/article/article-carrousel';
import {
  withRouterLink,
  withNextLink
} from '@wordquest/ui-components/with-link';
import { Locale, MessagesModule } from '@wordquest/locales';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { SITE_ROOT_UI_SITE_PRD } from '@wordquest/lib-iso/env';
import { Tabs, Tab } from '@material-ui/core';
import { mediaMui } from '@wordquest/ui-components/style-utils';
// Share pagesearch

const StyledSupportContainer = styled.div`
  margin-bottom: 5rem;
`;

const StyledSearchBar = styled.div`
  > div > div {
    width: 70%;
    border: 1px solid #ffffff;
    padding: 0.7rem 1rem;
    border-radius: 4px;
  }
  margin-top: 1rem;
  margin-bottom: 3rem;
  input::placeholder {
    color: #ffffff;
  }
  .MuiInputBase-root {
    margin-left: 0.5rem;
  }
  .MuiInput-underline:before {
    content: none;
  }
  .MuiSvgIcon-root {
    margin-top: 3px;
    display: flex;
  }
`;

const StyledHelpdeskHeader = styled.div`
  background-color: #4071ea;
  color: #ffffff;
  h1 {
    margin-top: 3rem;
  }
`;

const StyledTabContainer = styled(Tabs)`
  margin-top: 4.75rem;
  ${mediaMui.sm`
    margin-top: 2rem;
  `}
  ${mediaMui.xs`
    margin-top: 2rem;
  `}

  > div {
    display: flex;
    justify-content: center;
    > span {
      background-color: transparent;
    }
  }
  .MuiTabs-flexContainer {
    width: 70%;
    ${mediaMui.xs`
      width: 90%;
    `}
    > button {
      flex: 1 1 0;
      max-width: none;
    }
  }
  .MuiTab-textColorInherit {
    color: #4071ea;
    opacity: 1;
  }
  .MuiTab-textColorInherit.Mui-selected {
    background-color: #4071ea;
    color: #ffffff;
  }
`;

const StyledTab = styled(Tab)`
  .MuiTouchRipple-root {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  }
`;

const StyledCategoryBlock = styled(Grid)`
  margin-top: 4.5rem;
  ${mediaMui.sm`
    margin-top: 2rem;
  `}
  ${mediaMui.xs`
    margin-top: 2rem;
  `}

  & ul {
    padding-left: 1.5rem;
  }
  & li {
    color: #113284;
  }
`;

const SupportArticleList = ({ withLink, articles }) => (
  <ul>
    {_.map(articles, (article) =>
      withLink({
        articleKey: article.semanticKey
      })(
        <li key={articles.id}>
          <Typography variant="body1">{article.title}</Typography>
        </li>
      )
    )}
  </ul>
);

export enum SupportPaths {
  Teacher = 'teacher',
  Student = 'student'
}

const Support = ({
  classes,
  rootStore: { uiStateStore, pageSearchStore, pageSupportStore, routerStore }
}) => {
  const { routeMatch, app } = uiStateStore;
  const { push } = routerStore;
  const {
    currentSearch,
    analyzedResult,
    relatedWords,
    relatedArticles,
    relatedVideos,
    searchContext
  } = pageSearchStore;
  const { articlesByCategory } = pageSupportStore;

  const { queryText } = currentSearch;

  const type = _.get(routeMatch, 'params.type');

  const withArticleLink = (props) =>
    withRouterLink({
      to: `${SITE_ROOT_UI_SITE_PRD}/support/${type}/${props.articleKey}`
    });

  const [isLoading, setIsLoading] = React.useState(true);

  const waitWithLoadingAnimation = React.useCallback(() => {
    timer(2 * 1000).subscribe(() => {
      setIsLoading(false);
    });
  }, [timer, setIsLoading]);

  const tabOnChangeHandler = React.useCallback(
    (event, value) => {
      if (type !== value) {
        push(`/support/${value}`);
        setIsLoading(true);
      }
    },
    [push, type, setIsLoading]
  );

  waitWithLoadingAnimation();

  // TODO use mobx to fix bug not react to array changes
  return (
    <StyledSupportContainer>
      <StyledHelpdeskHeader>
        <Grid maxWidth="lg" container alignItems="center" justify="center">
          <Grid item xs={12} align="center">
            <h1>
              <FormattedMessage id="landing.support.title" />
            </h1>
          </Grid>
          <Grid item xs={12}>
            <StyledSearchBar>
              <Grid container alignItems="center" justify="center">
                <div>
                  <SearchBar
                    search={currentSearch}
                    context={searchContext}
                    onQueryChange={_.identity}
                  />
                </div>
              </Grid>
            </StyledSearchBar>
          </Grid>
        </Grid>
      </StyledHelpdeskHeader>
      {queryText && (
        <TextQueryResult
          queryText={queryText}
          isShowThumbnail={false}
          isShowTags={false}
          withArticleLink={withArticleLink}
          relatedWords={toJS(relatedWords)}
          relatedArticles={toJS(relatedArticles)}
          relatedVideos={toJS(relatedVideos)}
          classes={classes}
        />
      )}
      <StyledTabContainer centered value={type} onChange={tabOnChangeHandler}>
        <StyledTab
          disable={type === SupportPaths.Student}
          label={
            <Typography variant="body1">
              <FormattedMessage id="landing.support.tab.student" />
            </Typography>
          }
          value={SupportPaths.Student}
        />
        <StyledTab
          disable={type === SupportPaths.Teacher}
          label={
            <Typography variant="body1">
              <FormattedMessage id="landing.support.tab.teacher" />
            </Typography>
          }
          value={SupportPaths.Teacher}
        />
      </StyledTabContainer>
      {isLoading ? (
        <LoaderGeneral />
      ) : (
        <Container maxWidth="lg">
          <Grid
            maxWidth="lg"
            container
            alignItems="flex-start"
            justify="flex-start"
          >
            {_.map(toJS(articlesByCategory), (articles, category) => (
              <StyledCategoryBlock item md={6} xs={12}>
                <Typography color="inherit" variant="h5">
                  <FormattedMessage id={`tag.${category}`} />
                </Typography>
                <SupportArticleList
                  withLink={withArticleLink}
                  articles={articles}
                />
              </StyledCategoryBlock>
            ))}
          </Grid>
        </Container>
      )}
    </StyledSupportContainer>
  );
};

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    fontSize: '2em'
  },
  paper: {
    backgroundColor: '#fafafa'
  }
});

export default withStyles(styles)(inject('rootStore')(observer(Support)));
// this.search.queryText
