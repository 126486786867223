export const DEFAULT_ROLE_OPTIONS = [
  {
    key: ''
  },
  {
    key: 'software-engineer'
  },
  {
    key: 'ux-designer'
  },
  {
    key: 'biz-developer'
  },
  {
    key: 'marketer'
  },
  {
    key: 'sales'
  },
  {
    key: 'hr-manager'
  }
  // {
  //   key: 'Finance Analyst'
  // },
  // {
  //   key: 'Accountant'
  // }
];
