import _ from 'lodash';

export const asLanguageProficiency = (cfEntity) => {
  if (!_.get(cfEntity, 'fields')) {
    return {};
  }
  const { sys, fields } = cfEntity;
  const { locale } = fields;

  return fields;
};
