import _ from 'lodash';
import Video, { VideoPlatform } from '~/domain/wordquest/video/video';
// easier as path use at object merge

export const VIDEO_YT_TAGS_KEY = `video.videoPlatformMeta.${VideoPlatform.Youtube}.tags`;

export const VIDEO_YT_DEFAULT_LOCALE_KEY = `video.videoPlatformMeta.${VideoPlatform.Youtube}.defaultLanguage`;

export const VIDEO_YT_DEFAULT_AUDIO_LOCALE_KEY = `video.videoPlatformMeta.${VideoPlatform.Youtube}.defaultAudioLanguage`;

export const getVideoYtDefaultAudioLocale = (video) =>
  _.get(
    video,
    `video.videoPlatformMeta.${VideoPlatform.Youtube}.defaultAudioLanguage`
  );

export const YT_PLAYLIST_ITEM_ID = `videoPlatformMeta.${VideoPlatform.Youtube}.playlistItemId`;

export const YT_PLAYLIST_ITEM_ETAG = `videoPlatformMeta.${VideoPlatform.Youtube}.playlistItemEtag`;

export const getVideoPlaylistItemId = (video) =>
  _.get(video, YT_PLAYLIST_ITEM_ID);

export const VIDEO_YT_PLAYLIST_ITEM_ID = `video.${YT_PLAYLIST_ITEM_ID}`;

export const VIDEO_YT_VIEWS = `video.videoPlatformMeta.${VideoPlatform.Youtube}.statistics.viewCount`;

export const getVideoYtTags = (video: Video) =>
  _.get(video, `videoPlatformMeta.${VideoPlatform.Youtube}.tags`) || [];

export const VIDEO_YT_CHANNEL_ID = `video.videoPlatformMeta.${VideoPlatform.Youtube}.channelId`;

export const YT_CHANNEL_ID = `videoPlatformMeta.${VideoPlatform.Youtube}.channelId`;

export const VIDEO_YT_CATEGORY_ID = `video.videoPlatformMeta.${VideoPlatform.Youtube}.categoryId`;

export const partitionTagsByYt = (tags) =>
  _.partition(
    (tags || []).filter((t) => _.isString(t)),
    (t) => !/^yt-/.test(t)
  ).map((tags) => tags.map((t) => t.replace(/^yt-/, '')));
