import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { getTagI18nKeyWithLanguage } from '@wordquest/locales';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Author } from '@wordquest/lib-iso/domain/wordquest/author/author';
import { isWindowMuiBreakpointUp, mediaMui } from '~/style-utils';
import { markedWithDefault } from '@wordquest/lib-iso/app/markdown-html-mapper';
import ExpandableSection from '~/expandable-section';
import { FormattedMessage } from 'react-intl';

const StyledAuthorTag = styled.div`
  .MuiAvatar-root.MuiAvatar-circle {
    width: 1.5rem;
    height: 1.5rem;
  }
  .MuiChip-root {
    background-color: #ffce2b;
  }
`;

export default (props) => {
  const { author, label } = props;

  return (
    <StyledAuthorTag>
      <Chip
        label={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar alt={author.name} src={author.avatarUrl} />
            <Typography
              style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              variant="subtitle1"
              component="div"
            >
              {author.shortName || author.name}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {label}
            </Typography>
          </Box>
        }
      />
    </StyledAuthorTag>
  );
};
