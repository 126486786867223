import _ from 'lodash';
import { observable, action } from 'mobx';
import Word from '@wordquest/lib-iso/domain/wordquest/word/word';
import { loadWithIdsWithMobxCache } from '~/services/mobx-cache';
import { queryGlossariesWithLocaleContext } from '@wordquest/lib-iso/app/glossary/glossary-repo';
import { queryQuotesWithLocaleContext } from '@wordquest/lib-iso/app/quote/quote-repo';
import {
  queryWordsWithLocaleIds,
  queryDefinitionsWithLocaleWordIds
} from '@wordquest/lib-iso/app/word/word-repo';
import Definition from '@wordquest/lib-iso/domain/wordquest/word/definition';
import Glossary from '@wordquest/lib-iso/domain/wordquest/glossary/glossary';
import { Quote } from '@wordquest/lib-iso/domain/wordquest/quote/quote';

import { from } from 'rxjs';
import { map, tap, first, toArray } from 'rxjs/operators';
import { Locale } from '@wordquest/locales';
import logger from '@wordquest/lib-iso/app/logger';
import RootStore from '~/stores/root';
// We're building our own cache as data comes from Elasticsearch (instead of firestore)

const asKeyById = (entites) => _.keyBy(entites, 'id');

// TODO inject app instead

// TODO https://github.com/wordquest/wordquest/issues/405
const queryWordsWithLocaleIdsAsWordById = (wordIds) =>
  from(queryWordsWithLocaleIds(Locale.EN, wordIds)).pipe(
    toArray(),
    map(asKeyById)
  );

// alternatively at video store, use loadQuotesWithVideoId
const queryQuotesWithLocaleIdsAsQuoteById = (quoteIds) =>
  from(
    queryQuotesWithLocaleContext(Locale.EN, {
      filter: {
        includeIds: quoteIds
      }
    })
  ).pipe(map(asKeyById), first());

const queryGlossariesWithLocaleIdsAsGlossaryById = (glossaryIds) =>
  from(
    queryGlossariesWithLocaleContext(Locale.EN, {
      filter: {
        includeIds: glossaryIds
      }
    })
  ).pipe(map(asKeyById), first());

const queryDefinitionsWithLocaleWordIdsAsDefinitionsById = (wordIds) =>
  queryDefinitionsWithLocaleWordIds(Locale.EN, wordIds, {
    fields: {
      definitionsLocale: Locale.EN
    }
  }).pipe(
    toArray(),
    tap((definitionsOfWords) =>
      logger.trace(
        'queryDefinitionsWithLocaleWordIdsAsDefinitionsById results:',
        _.size(definitionsOfWords),
        definitionsOfWords
      )
    ),
    map((definitionOfWords) => _.groupBy(definitionOfWords, (d) => d.wordId)),
    first()
  );
export default class WordStore {
  constructor(rootStore: RootStore) {
    /* eslint-disable-line */
  }

  // TODO wordstore by locale
  wordById = observable.map({});

  // only current locales
  definitionsById = observable.map({});

  glossaryById = observable.map({});

  // bulk is pointless now as not supported by firestore
  lazyLoadWordByIds = action(
    (wordIds: string[]): Promise<Word[]> =>
      loadWithIdsWithMobxCache(
        wordIds,
        queryWordsWithLocaleIdsAsWordById,
        this.wordById,
        true
      ).toPromise()
  );

  lazyLoadDefinitionsByWordIds = action(
    (wordIds: string[]): Promise<Definition[]> =>
      loadWithIdsWithMobxCache(
        wordIds,
        queryDefinitionsWithLocaleWordIdsAsDefinitionsById,
        this.definitionsById,
        true
      ).toPromise()
  );

  lazyLoadGlossariesWithIds = action(
    (glossaryIds: string[]): Promise<Glossary[]> =>
      loadWithIdsWithMobxCache(
        glossaryIds,
        queryGlossariesWithLocaleIdsAsGlossaryById,
        this.glossaryById,
        true
      ).toPromise()
  );
}
