// TODO potential further push in
export const PREDEFINED_CATEGORIES_TEACHER = [
  'line',
  'payment',
  'others',
  'perapera-intro',
  'onboarding',
  'telegram',
  'whatsapp',
  'scheduling'
].map((category) => `guide-teacher-${category}`);

export const PREDEFINED_CATEGORIES_STUDENT = [
  'line',
  'payment',
  'others',
  'perapera-intro',
  'onboarding',
  'telegram',
  'whatsapp',
  'scheduling'
].map((category) => `guide-student-${category}`);
