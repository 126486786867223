import _ from 'lodash';
import {
  observable,
  reaction,
  autorun,
  observe,
  intercept,
  toJS,
  decorate,
  computed,
  transaction
} from 'mobx';
import logger from '@wordquest/lib-iso/app/logger';
import RootStore from '~/stores/root';
import { Lesson } from '@wordquest/lib-iso/domain/wordquest/course/lesson';
import Material, {
  MaterialType
} from '@wordquest/lib-iso/domain/wordquest/course/material';
import Course from '@wordquest/lib-iso/domain/wordquest/course/course';
import { createVideoId } from '@wordquest/lib-iso/app/video/video-repo';
import { parseVideoUrl } from '@wordquest/lib-iso/app/video-url-util';
import { SITE_ROOT_UI_SITE } from '@wordquest/lib-iso/env';
import {
  QuestTypeName,
  getQuestWatchVideoLessonKey
} from '@wordquest/lib-iso/domain/wordquest/quest/quest';
import {
  Entity,
  QuestAction,
  getQuestKey
} from '@wordquest/lib-iso/domain/wordquest/entity';
import { Locale } from '@wordquest/locales';
import { lazyLoadLocaleData } from '@wordquest/ui-components/react-intl-config';
import {
  loadContentfulClient,
  CfTypes
} from '@wordquest/lib-iso/adapters/contentful';
import { asCourse, asLesson } from '@wordquest/lib-iso/app/course/course-cf';
import {
  createCourseRoot,
  queryCoursesWithLocaleContext
} from '@wordquest/lib-iso/app/course/course-repo';
import { isAfter, isBefore } from 'date-fns';
import { checkIsAuthorized } from '@wordquest/lib-iso/domain/wordquest/profile/profile-authorized-roles';
import { createTransformer } from 'mobx-utils';
import { collectMultipleRichTextsEntitiesByNodeType } from '@wordquest/lib-iso/domain/wordquest/dom-entity-extractor';
import { resolveSupportedLocale } from '@wordquest/lib-iso/intl';
import { createPaperformProps } from '@wordquest/ui-components/page-util';
import {
  CourseUserStatus,
  checkRoleAuthorizationsWithSegments,
  checkRoleAuthorizations
} from '@wordquest/lib-iso/domain/wordquest/course/course-user-status';
import { analyzeCourseProgress } from '@wordquest/lib-iso/app/quest/quest-course-analyzer';
import {
  getLessonEntitiesByNodeType,
  getLessonQuestsEntitiesByNodeType,
  getCourseIntroEntitiesByNodeType
} from '@wordquest/lib-iso/app/course/course-utils';
import {
  WqDocument,
  WqNodeBlock,
  WqNodeTypeBlock,
  WqNodeTypeInline
} from '@wordquest/lib-iso/domain/wordquest/dom';
import { parseEmbededComponentMetas } from '~/services/embeded-components';
import {
  fetchCourseForms,
  asCourseFormsMeta,
  getCourseFormBody
} from '~/services/strapi';
import {
  fetchTestimonials,
  asTestimonialsByCourseKeyWithLocale
} from '~/services/testimonial';

// override (when present, ignore course)
// if (lesson && !_.isEmpty(lesson.authorizedSegments)) {
//   return lesson.authorizedSegments || [];
// }
// return course.authorizedSegments || [];

// export const createCourseAttributeByKeySection = ()=>{}

export const createCourseAttributeByKey = ({
  course,
  currentLessonKey,
  checkedByKey,
  userAuthorizedRoles,
  isAlwaysVisible = false
}: {
  course: Course;
  currentLessonKey: string;
  checkedByKey: Record<string, boolean>;
  userAuthorizedRoles: string[];
  isAlwaysVisible: boolean;
}) => {
  if (!_.get(course, 'courseSections')) {
    return;
  }

  return _.fromPairs(
    [
      [
        course.key,
        {
          title: course.title,
          key: course.key,
          url: `${SITE_ROOT_UI_SITE}/course/${course.key}`,
          isCurrent: _.isEmpty(currentLessonKey)
        }
      ]
    ].concat(
      _.flatMap(course.courseSections, ({ key: sectionKey, lessons }) =>
        lessons.map((lesson) => [
          lesson.key,
          mapLessonAsAttributes({
            courseAuthorizedSegments: course.authorizedSegments,
            sectionKey,
            lesson,
            currentLessonKey,
            checkedByKey,
            isAlwaysVisible,
            userAuthorizedRoles
          })
        ])
      )
    )
  );
};

export const filterRecommendedCoursesByGroup = (
  recommendedCoursesByGroup,
  isCoach
) => {
  const wrapper = isCoach ? _.identity : _.negate;

  const isMatchGroupKey = wrapper((groupKey) => groupKey.match(/coach/));
  const isMatchCourseKey = wrapper((courseKey) => courseKey.match(/teacher/));

  return _.mapValues(
    _.pickBy(recommendedCoursesByGroup, (courses, groupKey) =>
      isMatchGroupKey(groupKey)
    ),
    (courses, groupKey) => courses.filter((c) => isMatchCourseKey(c.key))
  );
};

export const mapLessonAsAttributes = createTransformer(
  ({
    courseAuthorizedSegments,
    sectionKey,
    lesson,
    currentLessonKey,
    checkedByKey,
    isAlwaysVisible = false,
    userAuthorizedRoles
  }) => {
    const {
      title,
      key,
      readyAt,
      authorizedSegments: lessonAuthorizedSegments
    } = lesson || {};
    const isHidden =
      !isAlwaysVisible &&
      (lesson.isHidden || (_.isDate(readyAt) && isAfter(readyAt, new Date())));
    const requiredRoles = checkRoleAuthorizationsWithSegments(
      courseAuthorizedSegments,
      lessonAuthorizedSegments
    );
    const isLocked =
      !isAlwaysVisible &&
      !checkIsAuthorized(userAuthorizedRoles, requiredRoles);

    return {
      title,
      sectionKey,
      key,
      isHidden,
      isChecked: !!checkedByKey[key],
      isLocked,
      isCurrent: key === currentLessonKey
    };
  }
);

export const CURATED_TAGS = ['trending'];

const COURSE_LOCALES = [Locale.EN, Locale.JA, Locale.DE, Locale.KO, Locale.VI];
// should use filter instead of terms after ready
const DEFAULT_RECOMMEND_COACH_CONTEXT_BY_GROUP = {
  'coach-trending': {
    isLoaded: false,
    context: {
      filter: {
        'course.tags.keyword': ['coach']
      },
      sort: [{ 'course.publishedAt': 'desc' }]
    }
  },
  ..._.fromPairs(
    COURSE_LOCALES.map((locale) => [
      `coach-${locale}`,
      {
        isLoaded: false,
        context: {
          filter: {
            'course.targetLocale': locale,
            'course.tags.keyword': ['coach', locale]
          }
        }
      }
    ])
  )
};
const DEFAULT_RECOMMEND_COURSE_CONTEXT_BY_GROUP = {
  'online-trending': {
    isLoaded: false,
    context: {
      // notFilter: {
      //   'course.tags.keyword': ['coach']
      // },
      sort: [{ 'course.publishedAt': 'desc' }]
    }
  },
  ..._.fromPairs(
    COURSE_LOCALES.map((locale) => [
      `online-${locale}`,
      {
        isLoaded: false,
        context: {
          filter: {
            'course.tags.keyword': locale
          }
        }
      }
    ])
  )
};

class PageCourseStore {
  constructor(private rootStore: RootStore) {
    const {
      courseStore,
      uiStateStore,
      wordStore,
      tipStore,
      pageVideoStore,
      videoStore,
      userStore
    } = rootStore;
    const { routeMatch } = uiStateStore;

    const { user } = this.rootStore.userStore;
    this.currentCourseRef = observable({
      key: '',
      lessonKey: '',
      title: '',
      materialKey: '',
      materialType: '',
      linkRoot: ''
    });

    this.testimonials = observable({});

    this.courseForms = observable({});

    this.coursesFilter = observable({
      type: ['coach', 'online'],
      locales: [],
      goalsByLocale: {},
      proficiencyByLocale: {}
      // locales: [Locale.EN, Locale.DE]
    });

    this.nextLessonAnchor = observable({
      isEnabled: true
    });
    this.paperformMeta = observable({
      formProps: {}
    });
    // TODO consider flatten if useful for other places too
    this.attributeByKey = observable({});

    this.reactionsWithLessonKey = observable([]);

    this.completedByLessonKey = observable.map({});

    this.recommendedKeysByGroup = observable.map({});
    // allow complete decouple as some other entry points might not need it
    // e.g. 2 cases for recommendation
    // at courses/ page
    // always reset commendations
    // at watch/ page recommend related courses
    // derive recommendations context using ui-state instead of keeping stateful context

    reaction(
      () => toJS(user.profile.activeLocales),
      (activeLocales) => {
        this.coursesFilter.locales.replace(activeLocales);
      }
    );

    reaction(
      () => user.profile.goalsByLocale,
      (goalsByLocale) => {
        this.coursesFilter.goalsByLocale.merge(goalsByLocale);
      }
    );

    reaction(
      () => user.profile.proficiencyByLocale,
      (proficiencyByLocale) => {
        this.coursesFilter.proficiencyByLocale.merge(proficiencyByLocale);
      }
    );

    this.recommendContextMetaByGroup = observable.map({});

    reaction(
      () => this.currentCourseRef.key + (this.currentCourse || {}).targetLocale,
      () => {
        const { path, params } = rootStore.uiStateStore.routeMatch;
        const contextByGroup = {};
        const coursesFilter = toJS(this.coursesFilter);

        let locales = toJS(coursesFilter.locales);

        if (_.isEmpty(locales)) {
          if (coursesFilter.type.length >= 2) {
            locales = COURSE_LOCALES;
          } else {
            locales = [Locale.EN];
          }
        }

        /**
         * we etiehr filter at ES query or avoid dding if equal to current course after load
         */
        if ((path || '').match('/course')) {
          // avoid flash where key is not ready
          if ((path || '').match('/course/') || this.currentCourseRef.key) {
            const { targetLocale } = this.currentCourse || {};
            _.merge(
              contextByGroup,
              _.pickBy(
                DEFAULT_RECOMMEND_COURSE_CONTEXT_BY_GROUP,
                (value, key) => _.find(CURATED_TAGS, (t) => key.match(t))
              ),
              _.pickBy(
                DEFAULT_RECOMMEND_COACH_CONTEXT_BY_GROUP,
                (context, key) => key.match(`-${targetLocale}`)
              )
            );
          } else {
            if (_.includes(coursesFilter.type, 'coach')) {
              _.merge(
                contextByGroup,
                _.pickBy(
                  DEFAULT_RECOMMEND_COACH_CONTEXT_BY_GROUP,
                  (value, key) => _.find(CURATED_TAGS, (t) => key.match(t))
                ),
                _.pickBy(
                  DEFAULT_RECOMMEND_COACH_CONTEXT_BY_GROUP,
                  (context, key) => _.find(locales, (l) => key.match(`-${l}`))
                )
              );
            }
            if (_.includes(coursesFilter.type, 'online')) {
              _.merge(
                contextByGroup,
                _.pickBy(
                  DEFAULT_RECOMMEND_COURSE_CONTEXT_BY_GROUP,
                  (value, key) => _.find(CURATED_TAGS, (t) => key.match(t))
                ),
                _.pickBy(
                  DEFAULT_RECOMMEND_COURSE_CONTEXT_BY_GROUP,
                  (context, key) => _.find(locales, (l) => key.match(`-${l}`))
                )
              );
            }
          }
          // avoid inifinite loop but targetLocale get stuck
          if (
            _.xor(
              _.keys(toJS(this.recommendContextMetaByGroup)),
              _.keys(contextByGroup)
            )
          ) {
            this.recommendContextMetaByGroup.replace(contextByGroup);
          }
        }
      },
      { fireImmediately: true }
    );

    autorun(async () => {
      const contextByGroup = {};
      this.recommendContextMetaByGroup.forEach(
        ({ context, isLoaded }, groupKey) => {
          if (!isLoaded) {
            logger.debug('load course', context);
            contextByGroup[groupKey] = context;
          } else {
            logger.debug('skip load ');
          }
        }
      );

      const excludeCourseKeys = [this.currentCourseRef.key].filter(Boolean);
      const { path } = rootStore.uiStateStore.routeMatch;
      // Quick fix, take time to init the key
      if (!path || path.match('/course/')) {
        if (_.isEmpty(excludeCourseKeys)) {
          return;
        }
      }

      const coursesByGroup = await courseStore
        .loadCoursesWithContextByGroup(contextByGroup, excludeCourseKeys)
        .toPromise();
      _.map(contextByGroup, (meta) => {
        meta.isLoaded = true;
      });

      this.recommendedKeysByGroup.replace(
        _.mapValues(coursesByGroup, (courses) => courses.map((c) => c.key))
      );
    });

    this.trackLessonQuestCompleted = () => {
      const questKey = getQuestKey(
        this.currentCourseRef.key,
        this.currentCourseRef.lessonKey,
        'lesson'
      );
      rootStore.eventStore.trackEvent({
        event: `${Entity.Quest}-${QuestAction.Completed}`,
        properties: {
          quest: {
            id: questKey,
            key: questKey,
            courseKey: this.currentCourseRef.key,
            lessonKey: this.currentCourseRef.lessonKey,
            type: 'lesson'
          }
        }
      });
    };

    this.goNextLesson = ({ isCompleteLessonQuest = false } = {}) => {
      const { eventStore, routerStore } = rootStore;
      const { history } = routerStore;

      // New tab also counts in history, always back to /app/read now
      if (isCompleteLessonQuest) {
        this.trackLessonQuestCompleted();
      }

      history.push(
        createCourseRoot(routeMatch.isPreview, this.currentCourseRef.key) +
          this.nextLessonKey
      );
    };

    autorun(() => {
      this.rootStore.uiStateStore.topbar.title =
        (this.currentLesson || {}).title || this.currentCourseRef.title;
    });

    reaction(
      () =>
        this.currentCourse &&
        this.currentCourse.title +
          this.currentCourseRef.key +
          this.currentCourse.id +
          this.currentCourseRef.lessonKey +
          this.rootStore.userStore.user.profile.id +
          this.rootStore.userStore.user.isProfileReady +
          this.completedByLessonKey.size,
      (k) => {
        if (this.paperformSlug && !(routeMatch.params || {}).lessonKey) {
          logger.debug(
            'mount course paperform check',
            this.paperformSlug,
            this.currentCourseRef.lessonKey
          );
          // do this only if we're on the paperform page. don't rely on currentCourseRef.lessonKey which is delayed
        }
        if (!this.currentCourse) {
          logger.debug('mount course non-ready');

          return;
        }

        // const agg = this.rootStore.userStore.questUserEventAggregateByKey.get(QuestAction.Completed);
        // transaction(() => {
        //   if (agg) {
        //     const completedByLessonKey = _.fromPairs(_.map(agg,
        //       (v, k) => [v.quest.lessonKey, v.quest.type === 'lesson']
        //     ));
        //
        //     this.completedByLessonKey.replace(completedByLessonKey);
        //   }
        // });

        logger.debug(
          'mount course ready',
          this.completedByLessonKey.toJSON(),
          this.rootStore.userStore.authorizedRoles
        );
        this.currentCourseRef.title = (this.currentCourse || {}).title;
        // TODO memo this datagraph
        _.merge(
          this.attributeByKey,
          createCourseAttributeByKey({
            course: this.currentCourse,
            currentLessonKey: this.currentCourseRef.lessonKey,
            checkedByKey: this.completedByLessonKey.toJSON(),
            userAuthorizedRoles: this.rootStore.userStore.authorizedRoles,
            isAlwaysVisible: this.isAlwaysVisible
          })
        );
        uiStateStore.sidebar.type = 'outline';
        this.currentCourseRef.linkRoot = createCourseRoot(
          routeMatch.isPreview,
          this.currentCourseRef.key
        );
      },
      { fireImmediately: true }
    );

    // autorun(
    // TODO for recommendations
    // async () => {
    //   if (courseStore.courseByKey.size === 0) {
    //     await courseStore.loadCoursesWithKeys([this.currentCourseRef.key]).toPromise();
    //   }
    // });

    reaction(
      () => this.currentCourseRef.key,
      async (courseKey) => {
        logger.debug('course key updated', courseKey);
        if (!courseKey) {
          return;
        }

        userStore.pullEventsByEntity(Entity.Quest);
        if (routeMatch.isPreview) {
          const contentful = await loadContentfulClient(true);
          const res = await contentful.getEntries({
            'fields.key': courseKey,
            content_type: CfTypes.Course,
            include: 10,
            limit: 1
          });
          const course = asCourse(_.first(res.items), {
            isCourseIntroPartial: false
          });

          courseStore.mergeCourseByKey({ [courseKey]: course });
        }
        if (courseKey && !courseStore.courseByKey.has(courseKey)) {
          logger.debug('Course init load ES');
          // query both for now. ES for courseIntro only. FS for access
          // await courseStore.loadCoursesWithKeys([courseKey]).toPromise();
          // might not exists
          const courses = await queryCoursesWithLocaleContext(Locale.EN, {
            filter: {
              includeIds: [courseKey]
            }
          });

          const course = _.first(courses);
          if (course) {
            courseStore.mergeCourseByKey({
              [course.key]: course
            });
          }

          if (!courseStore.courseByKey.has(courseKey)) {
            uiStateStore.errorState.course = 404;
          }
          if (!this.currentCourse) {
            return;
          }
          const instructionLocale =
            (this.currentCourse || {}).instructionLocale || Locale.ZH_TW;

          if (instructionLocale !== uiStateStore.app.userLocale) {
            await lazyLoadLocaleData(resolveSupportedLocale(instructionLocale));
            uiStateStore.app.toggleLocale(instructionLocale);
          }
        }
      }
    );

    // autorun(
    //   () => {
    //     if (this.currentCourseRef.lessonKey && this.currentLesson) {
    //       this.reactionsWithLessonKey.map(r => r && r.dispose());
    //       this.reactionsWithLessonKey.replace([] );
    //     }
    //   }
    // );

    reaction(
      () => (uiStateStore.routeMatch.params || {}).courseKey,
      async (courseKey) => {
        if (!courseKey) {
          return;
        }
        const { uiStateStore, userStore } = this.rootStore;
        const { routeMatch } = uiStateStore;
        this.currentCourseRef.key = courseKey;
      },
      { fireImmediately: true }
    );

    reaction(
      () => userStore.user.questLog.length,
      () => {
        transaction(() => {
          const course = this.currentCourse;
          if (!course) {
            return;
          }
          const { nextLessonKey, nextLessonTitle, completedByLessonKey } =
            analyzeCourseProgress({
              course,
              userAuthorizedRoles: [],
              events: userStore.user.questLog
            });
          this.completedByLessonKey.replace(completedByLessonKey);
        });
      }
    );

    reaction(
      // @ts-ignore
      () => user.profile.id + user.isProfileReady,
      () => {
        // TODO potentially delay
        if (!user.profile.id || user.isProfileReady) {
          _.merge(
            this.paperformMeta.formProps,
            createPaperformProps(user.profile)
          );
        }
      }
    );

    reaction(
      () =>
        (_.get(this, 'currentLessonNonQuestsEntitiesByNodeType.authorNote') ||
          []) &&
        this.currentNonQuestTipIds.concat(this.currentQuestTipIds),
      async (allTipIds) => {
        const { tipStore } = rootStore;
        let tipsCurrentVideo = [];
        if (!_.isEmpty(allTipIds)) {
          await tipStore.lazyLoadTipsWithIds(allTipIds);
          tipsCurrentVideo = _.values(
            _.pick(
              toJS(this.rootStore.tipStore.tipById),
              this.currentNonQuestTipIds
            )
          );
          // all tips for now, but hack
        }
        pageVideoStore.tipsCurrentVideo.replace(tipsCurrentVideo);
      }
    );
    reaction(
      () =>
        courseStore.courseByKey.has(this.currentCourseRef.key) &&
        (routeMatch.params || {}).lessonKey +
          userStore.user.isProfileReady +
          userStore.isLoggedIn,
      async () => {
        const lessonKey = (routeMatch.params || {}).lessonKey;
        uiStateStore.errorState.lesson = 0;
        if (!lessonKey) {
          return;
        }
        const course = courseStore.courseByKey.get(this.currentCourseRef.key);

        this.currentCourseRef.lessonKey = lessonKey;
        if (!courseStore.lessonByKey.has(lessonKey)) {
          if (this.currentLesssonMeta) {
            // not yet ready
            if (!this.isAlwaysVisible && !this.isUserCurrentLessonAuthorized) {
              logger.debug('Unauthorized for lesson');
              // TODO not login in progress
              if (
                uiStateStore.app.isAuthDialogLoaded &&
                (!userStore.isLoggedIn || userStore.user.isProfileReady)
              ) {
                rootStore.uiStateStore.errorState.lesson = 401;
              }

              return;
            }
            logger.debug('lesson authorized, loading...');
            // for free course / old courses without setting access, we always provide relevant paperform if not disabled
            await courseStore
              .loadLessonWithLessonIds([this.currentLesssonMeta.id])
              .toPromise();
            logger.debug(
              'load glossaries, quotes, authoNotes',
              this.currentLessonEntitiesByNodeType
            );

            if (courseStore.lessonByKey.has(lessonKey)) {
              rootStore.uiStateStore.errorState.lesson = 0;
            }
          } else {
            rootStore.uiStateStore.errorState.lesson = 404;
          }
        }

        if (routeMatch.isPreview) {
          const contentful = await loadContentfulClient(true);
          const res = await contentful.getEntries({
            'fields.key': lessonKey,
            content_type: CfTypes.CourseLesson,
            include: 10,
            limit: 1
          });
          const lesson = asLesson(_.first(res.items));
          courseStore.mergeLessonByKey({ [lessonKey]: lesson });
        }

        // ensure reset. quotes follow
        pageVideoStore.currentVideoRef.id = '';

        if (lessonKey && this.currentLesson) {
          logger.debug(
            'load lesson glossaries, tips',
            this.currentLessonEntitiesByNodeType
          );

          // TIDI avoid sequential
          await wordStore.lazyLoadGlossariesWithIds(
            (this.currentLessonEntitiesByNodeType.glossary || []).map(
              (g) => g.id
            )
          );
          await tipStore.lazyLoadQuotesWithIds(
            (this.currentLessonEntitiesByNodeType.quote || []).map((g) => g.id)
          );

          // instead of PageViewd, cater forloading-fail
          const questKey = getQuestKey(
            this.currentCourseRef.key,
            this.currentCourseRef.lessonKey,
            'lesson'
          );

          const { material, questItems } = this.currentLesson || {};

          // always reset to original. reset by params change failed
          _.merge(pageVideoStore.watchUiConfig, {
            isRecommendationOn: false,
            isDescriptionOn: false,
            initSubtitles: Locale.ZH_TW
          });

          if (material) {
            this.currentCourseRef.materialType = material.type;
            let matchedWordIds = [];
            if (uiStateStore.uiConfig.isUseTextAnalyzedContext) {
              logger.debug(
                'use isUseTextAnalyzedContext',
                this.materialContentRichText,
                this.notesRichText
              );
              // if (!_.isEmpty(this.materialContentRichText) || !_.isEmpty(this.notesRichText)) {
              const entitiesByNodeType =
                collectMultipleRichTextsEntitiesByNodeType(
                  [this.notesRichText, this.materialContentRichText],
                  [WqNodeTypeInline.Word]
                );
              logger.debug('collected', entitiesByNodeType);
              matchedWordIds = matchedWordIds.concat(
                (entitiesByNodeType.word || []).map((w) => w.id)
              );
              // }
              logger.debug(
                'load matchedWordIds',
                matchedWordIds.length,
                matchedWordIds
              );
              if (!_.isEmpty(matchedWordIds)) {
                await rootStore.wordStore.lazyLoadWordByIds(matchedWordIds);
                await rootStore.wordStore.lazyLoadDefinitionsByWordIds(
                  matchedWordIds
                );
              }
            }

            if (_.isEqual(material.type, MaterialType.Video)) {
              const videoInfo = parseVideoUrl(material.url);

              const { provider: platform, id } = videoInfo;
              pageVideoStore.currentVideoRef.id = createVideoId(platform, id);

              // only if this has no other quest e.g. dictation
              logger.debug('inject questItems', questItems);
              if (_.isEmpty(questItems)) {
                questItems.push({
                  id: [
                    QuestTypeName.WatchVideoLesson,
                    this.currentCourseRef.lessonKey,
                    pageVideoStore.currentVideoRef.id
                  ].join('-'),
                  type: QuestTypeName.WatchVideoLesson
                });
              }

              const {
                startSeconds,
                endSeconds,
                isTranscriptOff,
                videoTargetSubtitlesLocale
              } = material.meta || {};
              if (_.isFinite(startSeconds)) {
                pageVideoStore.playbackRange.startSeconds = startSeconds;
              }
              if (_.isFinite(endSeconds)) {
                pageVideoStore.playbackRange.endSeconds = endSeconds;
              }
              if (videoTargetSubtitlesLocale) {
                logger.debug(
                  'initSubtitles material',
                  videoTargetSubtitlesLocale
                );
                pageVideoStore.watchUiConfig.initSubtitles =
                  videoTargetSubtitlesLocale;
              }
              if (_.isBoolean(isTranscriptOff)) {
                pageVideoStore.watchUiConfig.isTranscriptOn = !isTranscriptOff;
              }
              const dictationQuest = _.find(
                questItems,
                (q) => q && q.type === QuestTypeName.Dictation
              );
              if (dictationQuest) {
                // TODO decouple from here
                this.nextLessonAnchor.isEnabled = false;

                const {
                  startSeconds: startSecondsDictation,
                  endSeconds: endSecondsDictation
                } = dictationQuest.properties;
                if (_.isFinite(startSecondsDictation)) {
                  pageVideoStore.playbackRange.startSeconds =
                    startSecondsDictation;
                }
                if (_.isFinite(endSecondsDictation)) {
                  pageVideoStore.playbackRange.endSeconds = endSecondsDictation;
                }
                logger.debug('initSubtitles off');
                pageVideoStore.watchUiConfig.initSubtitles = 'off';

                pageVideoStore.watchUiConfig.isTranscriptOn = false;
              }
              logger.debug(
                'updated playback',
                toJS(pageVideoStore.playbackRange)
              );

              // we either inject callback into videostore or put it here
              reaction(
                () =>
                  videoStore.getOrCreateVideoPlayback(
                    pageVideoStore.currentVideoRef.id
                  ).playedSeconds,
                (playedSeconds) => {
                  // we either do the calculation here or dervie by events emitted
                  // we don't want to do caclulation all the time while we want to prevent sending multiple times too

                  // percentage is essential since we got both 10s to 1hr videos to watch
                  // Note defulat max is MaxValue

                  if (!pageVideoStore.currentVideo) {
                    return;
                  }
                  const durationInS =
                    Math.min(
                      _.get(
                        pageVideoStore.currentVideo,
                        'videoPlatformMeta.youtube.durationInS'
                      ),
                      pageVideoStore.playbackRange.endSeconds
                    ) || Number.MAX_VALUE;
                  logger.trace('durationInS', durationInS, playedSeconds);
                  // if we can't tell, send right away
                  if (
                    durationInS >= 18000 ||
                    playedSeconds / durationInS >= 0.7
                  ) {
                    const questKey = getQuestWatchVideoLessonKey(
                      lessonKey,
                      pageVideoStore.currentVideoRef.id
                    );
                    const agg =
                      (this.rootStore.userStore.questUserEventAggregateByKey.get(
                        QuestAction.Completed
                      ) || {})[questKey];
                    if (!_.get(agg || {}, 'key')) {
                      // we have 2 choices 1) complete the course directly 2) mark video progress
                      // 1 is better if we don't wait until actual "finish"
                      // 2 is better if we want to remind specifc progress, and align user expectations (complete = sync at lesson)
                      rootStore.eventStore.trackEvent({
                        event: `${Entity.Quest}-${QuestAction.Completed}`,
                        properties: {
                          quest: {
                            id: questKey,
                            key: questKey,
                            courseKey: this.currentCourseRef.key,
                            lessonKey: this.currentCourseRef.lessonKey,
                            type: 'lesson'
                          }
                        }
                      });
                    }
                  }
                },
                {
                  delay: 3000
                }
              );
            }
          }
        }
      },
      { fireImmediately: true }
    );

    autorun(async () => {
      const rawCourseForms = await fetchCourseForms();
      this.courseForms = asCourseFormsMeta(rawCourseForms);
    });

    autorun(async () => {
      const strapiTestimonials = await fetchTestimonials();
      console.log('strapiTestimonials', strapiTestimonials);
      this.testimonials =
        asTestimonialsByCourseKeyWithLocale(strapiTestimonials);
      console.log('this.testimonials', this.testimonials);
    });
  }

  // use questitem of lesson instead of quest to identify
  // for e.g. watchvideo, either hidden quest
  get isNextLessonEnabled() {
    const questItemKeys = (_.get(this.currentLesson, 'questItems') || [])
      .filter((q) => q && q.type)
      .map((q) => q.id);

    return _.every(
      questItemKeys,
      (k) =>
        !!(
          (this.rootStore.userStore.questUserEventAggregateByKey.get(
            QuestAction.Completed
          ) || {})[k] || {}
        ).key
    );
  }

  get currentCourse() {
    return this.rootStore.courseStore.courseByKey.get(
      this.currentCourseRef.key
    );
  }

  get currentLesson() {
    return this.rootStore.courseStore.lessonByKey.get(
      this.currentCourseRef.lessonKey
    );
  }

  // from course, before load
  get currentLesssonMeta() {
    if (!this.currentCourse) {
      return;
    }
    const lessons = _.flatMap(
      this.currentCourse.courseSections,
      (section) => section.lessons
    );

    return _.find(lessons, (l) => l.key === this.currentCourseRef.lessonKey);
  }

  get currentCourseIntroEntitiesByNodeType() {
    return getCourseIntroEntitiesByNodeType(
      _.get(this.currentCourse, 'courseIntro')
    );
  }

  // Hide those explanations for answers

  get currentQuestTipIds() {
    return (this.currentLessonQuestsEntitiesByNodeType.authorNote || []).map(
      (g) => g.id
    );
  }

  get currentNonQuestTipIds() {
    const lessonTipIds = (
      this.currentLessonNonQuestsEntitiesByNodeType.authorNote || []
    ).map((g) => g.id);

    if (
      _.find(_.get(this.currentLesson, 'questItems'), (q) =>
        _.isEqual(q.type, QuestTypeName.WatchVideoLesson)
      )
    ) {
      const checkTipQuestKey = 'feature-watch-lesson-quest';

      return [checkTipQuestKey].concat(lessonTipIds);
    }

    return lessonTipIds;
  }

  get currentLessonEntitiesByNodeType() {
    return _.merge(
      this.currentLessonNonQuestsEntitiesByNodeType,
      this.currentLessonQuestsEntitiesByNodeType
    );
  }

  get currentLessonNonQuestsEntitiesByNodeType() {
    return getLessonEntitiesByNodeType(this.currentLesson);
  }

  get currentLessonQuestsEntitiesByNodeType() {
    return getLessonQuestsEntitiesByNodeType(this.currentLesson);
  }

  get nextLessonKey() {
    if (!this.currentCourse) {
      return;
    }
    const lessons = _.flatMap(
      this.currentCourse.courseSections,
      (section) => section.lessons
    );
    const nextIndex =
      _.findIndex(lessons, (l) => l.key === this.currentCourseRef.lessonKey) +
      1;
    if (nextIndex > -1 && nextIndex < lessons.length) {
      return lessons[nextIndex].key;
    }
  }

  // handle case of pure open course too
  get courseUserStatus() {
    const { authorizedRoles } = this.rootStore.userStore;
    const courseRequiredRoles = checkRoleAuthorizations(
      this.currentCourse || {},
      null
    );
    // [] -> open ->
    // stripe:active, upcoming, trialing

    const userCourseAuthorizedRoles = _.intersection(
      authorizedRoles,
      courseRequiredRoles
    );
    logger.debug(
      'userCourseAuthorizedRoles',
      userCourseAuthorizedRoles,
      courseRequiredRoles
    );
    // not even trial. can't differentiate for all users
    const isOpenCourse = _.isEmpty(courseRequiredRoles);

    if (
      isOpenCourse ||
      _.find(_.intersection(authorizedRoles, courseRequiredRoles), (r) =>
        r.match(/-upcoming/)
      )
    ) {
      // TODO renew
      return CourseUserStatus.PaidActive;
    }
    if (
      _.find(_.intersection(authorizedRoles, courseRequiredRoles), (r) =>
        r.match(/-paid/)
      )
    ) {
      // course start date
      const { startAt } = this.currentCourse;
      if (startAt && startAt < isBefore(startAt, new Date())) {
        return CourseUserStatus.PaidPending;
      }

      return CourseUserStatus.PaidActive;
    }
    if (_.find(userCourseAuthorizedRoles, (r) => r.match(/-trial/))) {
      return CourseUserStatus.Trial;
    }

    return CourseUserStatus.New;
  }

  // TODO deprecate this
  // for free course / old courses without setting access, we always provide relevant paperform if not disabled
  get formSubmissionType() {
    const { authorizedRoles } = this.rootStore.userStore;
    const courseRequiredRoles = checkRoleAuthorizations(
      this.currentCourse || {},
      null
    );
    // [] -> open ->
    // stripe:active, upcoming, trialing

    const userCourseAuthorizedRoles = _.intersection(
      authorizedRoles,
      courseRequiredRoles
    );
    if (
      _.find(_.intersection(authorizedRoles, courseRequiredRoles), (r) =>
        r.match(/-upcoming/)
      )
    ) {
      return 'renew';
    }
    if (
      _.find(_.intersection(authorizedRoles, courseRequiredRoles), (r) =>
        r.match(/-paid/)
      )
    ) {
      return 'paid';
    }
    if (_.find(userCourseAuthorizedRoles, (r) => r.match(/-trial/))) {
      return 'new';
    }

    return 'trial';
  }

  get isUserCurrentLessonAuthorized() {
    const { authorizedRoles } = this.rootStore.userStore;

    // for free course / old courses without setting access, we always provide relevant paperform if not disabled

    const lessonRequiredRoles = checkRoleAuthorizationsWithSegments(
      (this.currentCourse || {}).authorizedSegments,
      (this.currentLesssonMeta || {}).authorizedSegments
    );

    return checkIsAuthorized(authorizedRoles, lessonRequiredRoles);
  }

  get embededComponentMetas() {
    const { embededKeys } = this.currentCourse || {};
    const embededComponentMetas = parseEmbededComponentMetas(embededKeys);
    // console.log('embededKeys', embededKeys);

    return embededComponentMetas;
  }

  get paperformSlug() {
    const paperformSlug = (this.currentCourse || {}).paperformSlug;
    // to speed up loading
    // paperform is deprecated so new default is to disable it
    if (!paperformSlug || paperformSlug === 'disable') {
      return;
    }

    return paperformSlug || this.currentCourseRef.key;
  }

  get isAlwaysVisible() {
    return (
      this.rootStore.uiStateStore.uiConfig.isDebug ||
      this.rootStore.uiStateStore.uiConfig.isMagic
    );
  }

  get materialContentRichText() {
    const { contentRichTextAnalyzed, contentRichText } =
      (this.currentLesson || {}).material || {};

    return !_.isEmpty(contentRichTextAnalyzed) &&
      this.rootStore.uiStateStore.uiConfig.isUseTextAnalyzedContext
      ? contentRichTextAnalyzed
      : contentRichText;
  }

  get notesRichText() {
    const { remarksRichTextAnalyzed, remarksRichText } =
      this.currentLesson || {};

    return !_.isEmpty(remarksRichTextAnalyzed) &&
      this.rootStore.uiStateStore.uiConfig.isUseTextAnalyzedContext
      ? remarksRichTextAnalyzed
      : remarksRichText;
  }

  get recommendedCoursesByGroup() {
    const coursesByGroup = {};
    this.recommendedKeysByGroup.forEach((keys, groupKey) => {
      coursesByGroup[groupKey] = keys.map((key) =>
        this.rootStore.courseStore.courseByKey.get(key)
      );
    });

    return coursesByGroup;
  }

  get currentCourseFormMeta() {
    const courseForms = toJS(this.courseForms);
    const courseKey = this.currentCourseRef.key;
    const userLocale = this.rootStore.uiStateStore.app.userLocale;
    const courseForm = courseForms[courseKey]
      ? courseForms[courseKey][userLocale]
      : null;

    return courseForm;
  }

  get currentCourseFormBody() {
    return getCourseFormBody(this.currentCourseFormMeta);
  }

  get currentTestimonials() {
    const testimonials = toJS(this.testimonials);
    const courseKey = this.currentCourseRef.key;
    const userLocale = this.rootStore.uiStateStore.app.userLocale;
    const currentTestimonials = testimonials?.[courseKey]?.[userLocale];

    return currentTestimonials;
  }
}

decorate(PageCourseStore, {
  isNextLessonEnabled: computed,
  currentCourse: computed
});
export default PageCourseStore;
