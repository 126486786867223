// Element = either "blocks" or "inlines" depending on the Slate editor's configuration. (use div vs span)
// Leaf = character level / characters are grouped into "leaves" of text that each contain the same formatting applied to them.
import _ from 'lodash';
import React from 'react';
import logger from '@wordquest/lib-iso/app/logger';
import Text, { defaultRenderLeaf } from './rich-text-text';
import Element from './rich-text-element';
// https://github.com/ianstormtaylor/slate/blob/master/packages/slate/src/interfaces/element.ts#L20
// image has no children
// avoid matching value empty
export const isElement = (value) =>
  _.isObject(value) && (_.isUndefined(value.text) || _.isNull(value.text));

// we will use our legacy highlights instead of Range/Decorations for now
const Children = (props) => {
  const {
    // decorate,
    // decorations,
    node,
    renderElement,
    renderLeaf
    // selection
  } = props;

  const children = [];
  // const isLeafBlock
  //    = Element.isElement(node);
  //
  // && !editor.isInline(node)
  // && Editor.hasInlines(editor, node);
  if (!node.children) {
    logger.debug('Children render fail', node);

    return <></>;
  }
  const doRenderLeaf = renderLeaf || defaultRenderLeaf;
  for (let i = 0; i < node.children.length; i += 1) {
    // const p = path.concat(i);
    const n = node.children[i];
    const key = _.get(n, 'attributes.key') || i;
    // const key = ReactEditor.findKey(editor, n);
    // const range = Editor.range(editor, p);
    // const sel = selection && Range.intersection(range, selection);
    // const ds = decorate([n, p]);

    // for (const dec of decorations) {
    //   const d = Range.intersection(dec, range);
    //
    //   if (d) {
    //     ds.push(d);
    //   }
    // }
    if (isElement(n)) {
      children.push(
        <Element
          key={key}
          // decorate={decorate}
          // decorations={ds}
          element={n}
          // key={key.id}
          renderElement={renderElement}
          renderLeaf={doRenderLeaf}
          // selection={sel}
        />
      );
    } else {
      children.push(
        <Text
          key={key}
          text={n.text}
          leaf={n}
          // use default
          renderLeaf={doRenderLeaf}
        />
      );
    }
  }

  return children;
};

export default Children;
