import _ from 'lodash';
import Material from '~/domain/wordquest/course/material';
import Quote from '~/domain/wordquest/quote/quote';
import { asTag, asTags } from '~/app/tag/tag-cf';
import marked from 'marked';
import { Locale } from '@wordquest/locales';
import { asAuthor } from '../author/author-cf';
import { parseVideoUrl } from '~/app/video-url-util';
import { createVideoId } from '~/app/video/video-repo';
import { asWqDom } from '~/app/contentful/rich-text-wq-dom-mapper';

export const asQuote = (entry): Quote => {
  const { sys, fields } = entry;
  const {
    original,
    originalLong,
    originalLocale,
    translationsZh,
    translationsZhLong,
    explanationsZh,
    figureTag,
    tags,
    explanationsAuthor,
    videoUrl,
    startSeconds,
    endSeconds,
    isHiddenInVideo
  } = fields || {};
  const author = asAuthor(explanationsAuthor);

  const figureImageUrl = _.get(
    figureTag,
    'fields.imageUrl.fields.file.url',
    ''
  );
  const figureTagKey = asTag(figureTag);

  const videoInfo = parseVideoUrl(videoUrl);
  const video = {};
  if (videoInfo) {
    _.merge(video, videoInfo, {
      id: createVideoId(videoInfo.provider, videoInfo.id),
      startSeconds: startSeconds || 0,
      endSeconds: endSeconds || 0
    });
  }

  // use key to pick description
  // figureTag
  return {
    id: sys.id,
    original: originalLong || original,
    originalLocale,
    translationByLocale: {
      [Locale.ZH_TW]: translationsZhLong || translationsZh
    },
    explanationRichTextByLocale: {
      [Locale.ZH_TW]: asWqDom(explanationsZh)
    },
    video,
    tags: asTags(tags),
    isHiddenInVideo: isHiddenInVideo === true,
    // title,
    // shortDescription,
    // description: marked(description),
    // heroImageUrl,
    // // learningObjectivesDescription: marked(learningObjectivesDescription),
    // // targetAudienceDescription: marked(targetAudienceDescription),
    // sections,
    author: asAuthor(explanationsAuthor),
    figureTagKey,
    figureImageUrl
    // teacherDescription: marked(teacherDescription)
  };
};
